import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplSearchComponent } from './search.component';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';

@NgModule({
  imports: [
    CommonModule,
    PplInputModule,
    PplIconModule
  ],
  declarations: [
    PplSearchComponent
  ],
  exports: [
    PplSearchComponent
  ]
})
export class PplSearchModule {
}

export * from './search.component';
