<div class="title"
     [class.hidden-title]="!hasHeader"
     *ngIf="hasHeader || files.length > 0">
  <ng-container>
    <span class="title-label"
          *ngIf="hasHeader">
      <ppl-input-container [label]="title"
                           [hasChanges]="hasChanges"
                           (undoChanges)="undoChanges.emit()"></ppl-input-container>
    </span>
    <div class="title-controls"
         *ngIf="files.length > 0">
      <button type="button"
              *ngIf="!readonly"
              pplButton
              pplButton1
              pplButtonSmall
              (click)="onAddClick()">
        {{addLang}}
      </button>
    </div>
  </ng-container>
</div>
<div *ngIf="!readonly && files.length > 0 && inlineAddButton"
     class="title-inline-add">
  <button type="button"
          pplButton
          pplButton1
          pplButtonSmall
          (click)="onAddClick()">{{addLang}}</button>
</div>
<div class="attachments">
  <div *ngIf="files && !!files.length"
       class="attachments-content"
       pplDropZone
       (pplDropZoneOver)="setDragOver($event)"
       (pplDropZoneDrop)="onDrop($event)">

    <div *ngIf="dragOver"
         class="overlay">
      <ppl-icon svgIcon="color-upload"
                width="30px"
                height="30px"></ppl-icon>
    </div>

    <div *ngFor="let file of files; let index = index"
         class="attachments-content-row"
         [class.attachments-content-row-changed]="changedItemIds?.includes(file.id)"
         (click)="onClickAttachment(file)">
      <div class="attachments-content-row-icon">
        <ppl-icon [svgIcon]="getAttachmentIconFromUrl(file.name)"
                  width="30px"
                  height="30px"></ppl-icon>
      </div>
      <div class="attachments-content-row-name">
        <div class="attachments-content-row-name-label"
             [attr.title]="file.name">
          {{file.name}}
        </div>
      </div>
      <div *ngIf="file.uploadStatus === PplAttachmentUploadStatusEnum.Uploading"
           class="attachments-content-row-loading">
        <ppl-icon class="loading"
                  svgIcon="pending-circle-gray"
                  width="20px"
                  height="20px"></ppl-icon>
      </div>
      <div class="attachments-content-row-action"
           *ngIf="!readonly && file.uploadStatus !== PplAttachmentUploadStatusEnum.Uploading">
        <ppl-icon svgIcon="closering-red-white"
                  class="remove"
                  width="16px"
                  height="17px"
                  (click)="onRemoveAttachment(index);$event.stopPropagation()"></ppl-icon>
      </div>
    </div>
  </div>
  <div class="attachments-footer"
       *ngIf="!readonly && files.length === 0"
       pplDropZone
       (pplDropZoneOver)="setDragOver($event)"
       (pplDropZoneDrop)="onDrop($event)">

    <div class="attachments-footer-add"
         *ngIf="!readonly">

      <div *ngIf="dragOver"
           class="overlay">
        <ppl-icon svgIcon="color-upload"
                  width="30px"
                  height="30px"></ppl-icon>
      </div>

      <span class="attachments-footer-add-label">
        <span class="attachments-footer-add-label-click"
              (click)="onAddClick()">{{ upload }}
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="readonly && files.length === 0"
       class="attachments-footer"
       style="height: 73px;">
  </div>
</div>

<input class="hidden-input"
       #fileInput
       type="file"
       [attr.accept]="accept"
       [attr.multiple]="!singleSelection"
       (change)="onAddAttachment($event);fileInput.value=''">
