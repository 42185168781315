<div *ngIf="position.direction === 'down'"
     class="content-space"></div>

<div class="content">
  <svg [class.connector]="true"
       [class.direction-up]="position.direction === 'up'"
       [class.direction-down]="position.direction === 'down'"
       [class.align-start]="position.alignStart"
       [class.align-end]="position.alignEnd"
       [ngStyle]="connectorStyle">
    <path *ngIf="position.direction === 'up'"
          d="M0,0 l10,0 l-5,5 Z"></path>
    <path *ngIf="position.direction === 'down'"
          d="M5,0 l5,5 l-10,0 Z"></path>
  </svg>

  <div class="content-inner">
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="position.direction === 'up'"
     class="content-space"></div>