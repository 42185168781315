<div class="container demo-content">
  <h2>Calling Conversation Item</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-calling-conversation-item</h4>
  <div>@Input() <b>data</b>: CallingConversationItemData</div>
  <div>@Input() <b>direction</b>: CallingConversationItemDirectionEnum</div>
  <br>

  <h3>Example</h3>
  <div>
    <ppl-calling-conversation-item [data]="dataOutgoing"></ppl-calling-conversation-item>
    <br>
    <ppl-calling-conversation-item [data]="dataIncoming"></ppl-calling-conversation-item>
    <br>
    <ppl-calling-conversation-item [data]="dataOutgoingError"></ppl-calling-conversation-item>
    <br>
    <ppl-calling-conversation-item [data]="dataIncomingError"></ppl-calling-conversation-item>
    <br>
    <ppl-calling-conversation-item [data]="dataIncomingSending"></ppl-calling-conversation-item>
    <br>
    <ppl-calling-conversation-item [data]="dataOutgoingSending"></ppl-calling-conversation-item>
  </div>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
