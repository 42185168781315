import { Component } from '@angular/core';
import { TIMEZONE_IANA_LIST } from '@ppl/utils';
import type { PplSelectOption } from '@ppl/ui/select';

@Component({
  selector: 'ppl-datepicker-demo',
  templateUrl: './datepicker-demo.component.html',
  styleUrls: ['./datepicker-demo.component.scss']
})
export class DatepickerDemoComponent {

  code = `
  <ppl-input-container>
    <ppl-date-input></ppl-date-input>
  </ppl-input-container>`;

  value = '';
  disabled = false;
  displayRemove = true;
  disableRemove = false;
  timezone = null;

  timezones: PplSelectOption[] = TIMEZONE_IANA_LIST;
}
