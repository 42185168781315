<ppl-input-container>
  <input #searchInput
         [disabled]="disabled"
         pplInput
         type="text"
         [value]="value"
         (input)="onChange($event)"
         autocomplete="off"
         [placeholder]="placeholder"
         (keyup.escape)="onKeyEscapePressed()"
         (focus)="valueFocus.emit()"
         (blur)="valueBlur.emit()" />

  <ng-container *ngIf="ngUiTheme === 'pipeliner'">
    <ppl-icon *ngIf="!disabled && inputValue.length === 0 && !alwaysDisplayRemoveButton"
              svgIcon="search"
              width="14px"
              height="14px"></ppl-icon>

    <ppl-icon *ngIf="!disabled && inputValue.length !== 0 || alwaysDisplayRemoveButton"
              class="clear-icon"
              svgIcon="closering-red2"
              width="14px"
              height="14px"
              (click)="clearValue()"></ppl-icon>
  </ng-container>

  <ng-container *ngIf="ngUiTheme === 'fabric'">
    <ppl-icon svgIcon="search-sm"
              width="16px"
              height="16px"></ppl-icon>

    <ppl-icon *ngIf="!disabled && inputValue.length !== 0 || alwaysDisplayRemoveButton"
              class="clear-icon"
              svgIcon="close-gray-sm"
              width="12px"
              height="12px"
              (click)="clearValue()"></ppl-icon>
  </ng-container>

  <ng-container *ngIf="ngUiTheme === 'material'">
    <div class="clear-icon-container"
         (click)="clearValue()">
      <ppl-icon *ngIf="!disabled && inputValue.length !== 0 || alwaysDisplayRemoveButton"
                class="clear-icon"
                svgIcon="close-24px"
                width="12px"
                height="12px"></ppl-icon>
    </div>
  </ng-container>
</ppl-input-container>
