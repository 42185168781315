<div class="container demo-content">
  <h2>Ranking</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-ranking</h4>
  <div>@Input() <b>value</b>: 1 | 2 | 3 | 4 | 5</div>
  <div>@Input() <b>disabled</b>: boolean</div>
  <div>@Output() <b>change</b>: 1 | 2 | 3 | 4 | 5</div>

  <br>

  <h3>Example</h3>

  <ppl-input-container>
    <ppl-ranking [value]="value" (valueChange)="change($event)"></ppl-ranking>
  </ppl-input-container>

  Selected value: {{value}}

  <br>

  <h5>Disabled</h5>

  <ppl-input-container>
    <ppl-ranking [value]="value" [disabled]="true" (valueChange)="change($event)"></ppl-ranking>
  </ppl-input-container>

  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
