import { AuthStore } from '../store/auth.state';
import {
  FormatWidth,
  getLocaleDateFormat,
  getLocaleDateTimeFormat,
  getLocaleNumberSymbol,
  getLocaleTimeFormat,
  NumberSymbol,
  registerLocaleData
} from '@angular/common';
import { Inject, Injectable, ɵLocaleDataIndex as LocaleDataIndex } from '@angular/core';
import type { DataRegion} from '@ppl/i18n';
import { dataRegionToAngularLocaleMap } from '@ppl/i18n';
import { PplDatePickerIntl } from '@ppl/ui/date-picker';
import { PplInputIntl } from '@ppl/ui/input';
import { DEPLOY_URL } from '@ppl/utils';
import { from, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LocaleService {
  constructor(
    @Inject(DEPLOY_URL) private deployUrl: string,
    private datePickerIntl: PplDatePickerIntl,
    private inputIntl: PplInputIntl,
    private store: AuthStore
  ) { }

  loadBootstrapDatepickerLocale(locale: string) {
    const result$ = new Subject();
    const bootstrapDatepickerLocale = dataRegionToDatepickerLocaleMap[locale];
    // // 1. set intl locale & firstWeekDay to be used while date picker is being initialized
    this.datePickerIntl.bootstrapDatepickerLocale = bootstrapDatepickerLocale;
    // // 2. load locale script
    const documentRef = document;
    const script = documentRef.createElement('script');
    script.type = 'text/javascript';
    script.src = `${this.deployUrl}/assets/bootstrap-datepicker.${bootstrapDatepickerLocale}.min.js`;

    if (!document.querySelector(`script[src="${script.src}"]`)) {
      // optimistic load, even if error occured, continue
      (script as any).onload = function () { result$.next(null); result$.complete(); };
      (script as any).onerror = function () { result$.next(null); result$.complete(); };

      documentRef.body.appendChild(script);
    } else {
      setTimeout(() => {
        result$.next(null);
        result$.complete();
      });
    }

    return result$;
  }

  registerLocale(locale: string) {
    const promise = dataRegionToAngularLocaleMap[locale]();
    if (promise) {
      return from(promise as Promise<any>).pipe(
        map(res => {
          const contents = res.default;
          registerLocaleData(contents);
          // further datePickerIntl stuff here
          const angularLocaleId = contents[LocaleDataIndex.LocaleId];
          // en-US-POSIX is not internally recognized by angular -> has to be converted to en-US
          this.setAngularLocaleId(angularLocaleId === 'en-US-POSIX' ? 'en-US' : angularLocaleId);

          const formatWidth = FormatWidth.Short;
          const timeFormat = getLocaleTimeFormat(angularLocaleId, formatWidth);
          const dateFormat = getLocaleDateFormat(angularLocaleId, formatWidth);
          const dateTimeFormat = formatDateTime(
            getLocaleDateTimeFormat(angularLocaleId, FormatWidth.Short),
            [
              timeFormat,
              dateFormat
            ]
          );
          const group = getLocaleNumberSymbol(angularLocaleId, NumberSymbol.Group);
          const decimal = getLocaleNumberSymbol(angularLocaleId, NumberSymbol.Decimal);

          this.datePickerIntl.dateTimeFormat = dateTimeFormat;
          this.datePickerIntl.dateFormat = dateFormat;
          this.inputIntl.group = group === ' ' ? ' ' : group;
          this.inputIntl.decimal = decimal;

          return angularLocaleId;
        })
      );
    } else {
      return of(null);
    }
  }

  private setAngularLocaleId(angularLocaleId: string) {
    this.store.dispatch('Auth_UpdateAngularLocaleId', angularLocaleId);
    this.datePickerIntl.angularLocaleId = angularLocaleId;
  }
}

// based on implementation of https://github.com/angular/angular/blob/4c2ce4e8ba4c5ac5ce8754d67bc6603eaad4564a/packages/common/src/i18n/format_date.ts#L137
function formatDateTime(str: string, opt_values: string[]) {
  if (opt_values) {
    str = str.replace(/\{([^}]+)}/g, function (match, key) {
      return (opt_values != null && key in opt_values) ? opt_values[key] : match;
    });
  }
  return str;
}

const dataRegionToDatepickerLocaleMap: { [K in DataRegion]: string } = {
  'et-EE': 'en-GB',
  'ms-MY': 'ms',
  'is-IS': 'is',
  'nl-NL': 'nl',
  'tn-ZA': 'en-GB',
  'en-US': 'en-GB',
  'sq-AL': 'sq',
  'uz-Cyrl-UZ': 'uz-cyrl',
  'fo-FO': 'fo',
  'gbz-AF': 'en-GB',
  'iu-Latn-CA': 'en-GB',
  'en-NZ': 'en-nz',
  'sr-Cyrl-CS': 'sr-latin',
  'sw-KE': 'sw',
  'bo-BT': 'en-GB',
  'fr-CA': 'fr',
  'quz-EC': 'en-GB',
  'mn-Mong-CN': 'en-GB',
  'ar-LB': 'ar',
  'zh-CN': 'zh-CN',
  'eu-ES': 'es',
  'tk-TM': 'tk',
  'ko-KR': 'ko',
  'zh-SG': 'zh-cn',
  'de-LU': 'de',
  'fi-FI': 'fi',
  'kk-KZ': 'kk',
  'yo-NG': 'en-GB',
  'sr-Latn-BA': 'sr-latin',
  'zh-MO': 'zh-cn',
  'ar-LY': 'ar',
  'ba-RU': 'en-GB',
  'ns-ZA': 'en-GB',
  'ky-KG': 'en-GB',
  'sr-Cyrl-BA': 'sr',
  'ar-YE': 'en-GB',
  'sma-SE': 'en-GB',
  'es-PY': 'es',
  'zu-ZA': 'en-GB',
  'ar-TN': 'ar-tn',
  'ru-RU': 'ru',
  'uz-Latn-UZ': 'en-GB',
  'en-CA': 'en-GB',
  'se-SE': 'en-GB',
  'es-HN': 'es',
  'es-PR': 'es',
  'am-ET': 'et',
  'kok-IN': 'en-GB',
  'en-PH': 'en-GB',
  'tr-TR': 'tr',
  'sl-SI': 'sl',
  'pl-PL': 'pl',
  'nn-NO': 'no',
  'ka-GE': 'ka',
  'sv-SE': 'sv',
  'ar-QA': 'ar',
  'en-ZA': 'en-ZA',
  'sah-RU': 'ru',
  'nl-BE': 'nl-BE',
  'bs-Cyrl-BA': 'bs',
  'mi-NZ': 'en-GB',
  'el-GR': 'el',
  'vi-VN': 'vi',
  'rw-RW': 'en-GB',
  'ar-IQ': 'ar',
  'es-GT': 'es',
  'es-DO': 'es',
  'cy-GB': 'cy',
  'br-FR': 'br',
  'de-AT': 'de',
  'iu-Cans-CA': 'en-GB',
  'fr-MC': 'fr',
  'lt-LT': 'lt',
  'da-DK': 'da',
  'hi-IN': 'hi',
  'sa-IN': 'en-GB',
  'quz-PE': 'en-GB',
  'ar-JO': 'ar',
  'it-CH': 'it-CH',
  'syr-SY': 'ar',
  'tmz-Latn-DZ': 'en-GB',
  'es-SV': 'es',
  'en-MY': 'en-GB',
  'se-NO': 'en-GB',
  'smn-FI': 'fi',
  'arn-CL': 'en-GB',
  'he-IL': 'he',
  'ms-BN': 'ms',
  'tg-Cyrl-TJ': 'en-GB',
  'es-EC': 'es',
  'ha-Latn-NG': 'en-GB',
  'pt-BR': 'pt-BR',
  'fr-FR': 'fr',
  'ur-PK': 'en-GB',
  'es-ES': 'es',
  'tt-RU': 'ru',
  'gsw-FR': 'fr',
  'ca-ES': 'ca',
  'ar-AE': 'ar',
  'es-CR': 'es',
  'quz-BO': 'en-GB',
  'gu-IN': 'en-GB',
  'ar-BH': 'ar',
  'be-BY': 'en-GB',
  'sr-Latn-CS': 'sr-latin',
  'es-AR': 'es',
  'wo-SN': 'en-GB',
  'id-ID': 'id',
  'tr-IN': 'tr',
  'es-CL': 'es',
  'es-UY': 'es',
  'ml-IN': 'en-GB',
  'sk-SK': 'sk',
  'ne-NP': 'nl',
  'pt-PT': 'pt',
  'ro-RO': 'ro',
  'pa-IN': 'en-GB',
  'en-IN': 'en-GB',
  'gl-ES': 'es',
  'si-LK': 'en-GB',
  'mt-MT': 'en-GB',
  'ar-MA': 'ar-ma',
  'sv-FI': 'sv',
  'sma-NO': 'en-GB',
  'mk-MK': 'mk',
  'wen-DE': 'de',
  'ar-SY': 'ar',
  'ar-OM': 'ar',
  'hr-HR': 'hr',
  'smj-NO': 'en-GB',
  'hu-HU': 'hu',
  'zh-HK': 'zh-cn',
  'es-US': 'es',
  'ar-DZ': 'ar',
  'as-IN': 'en-GB',
  'en-BZ': 'en-GB',
  'mr-IN': 'en-GB',
  'af-ZA': 'en-GB',
  'hy-AM': 'hy',
  'kh-KH': 'kh',
  'es-CO': 'es',
  'qut-GT': 'en-GB',
  'ta-IN': 'en-GB',
  'cs-CZ': 'cs',
  'rm-CH': 'en-GB',
  'fr-CH': 'fr-CH',
  'fil-PH': 'en-GB',
  'es-MX': 'es',
  'ne-IN': 'en-GB',
  'ar-KW': 'ar',
  'bn-IN': 'bn',
  'xh-ZA': 'en-GB',
  'fy-NL': 'nl',
  'nb-NO': 'en-GB',
  'es-PA': 'es',
  'en-GB': 'en-GB',
  'de-LI': 'de',
  'lv-LV': 'lv',
  'en-TT': 'en-GB',
  'en-AU': 'en-AU',
  'es-BO': 'es',
  'sms-FI': 'en-GB',
  'hr-BA': 'hr',
  'it-IT': 'it',
  'de-DE': 'de',
  'lb-LU': 'en-GB',
  'kl-GL': 'gl',
  'ig-NG': 'en-GB',
  'es-VE': 'es',
  'ga-IE': 'en-GB',
  'en-ZW': 'en-GB',
  'en-IE': 'en-IE',
  'bo-CN': 'zh-CN',
  'az-Latn-AZ': 'az',
  'fr-LU': 'fr',
  'oc-FR': 'oc',
  'ar-EG': 'ar',
  'ug-CN': 'zh-CN',
  'es-NI': 'es',
  'mn-Cyrl-MN': 'mn',
  'or-IN': 'en-GB',
  'es-ES_tradnl': 'es',
  'zh-TW': 'zh-TW',
  'se-FI': 'fi',
  'moh-CA': 'en-GB',
  'en-029': 'en-GB',
  'co-FR': 'fr',
  'ja-JP': 'en-GB',
  'te-IN': 'en-GB',
  'kn-IN': 'en-GB',
  'smj-SE': 'en-GB',
  'bg-BG': 'bg',
  'en-JM': 'en-GB',
  'fr-BE': 'fr',
  'en-SG': 'en-sg',
  'uk-UA': 'uk',
  'de-CH': 'de',
  'bs-Latn-BA': 'bs',
  'az-Cyrl-AZ': 'en-GB',
  'es-PE': 'es'
};
