export function stripHTML(text: string) {
  const tmp = document.createElement('div');
  tmp.innerHTML = text;
  const res = tmp.textContent || tmp.innerText || '';
  tmp.remove();
  return res;
}

export function createCtrlPressedListener(callback: (ctrlPressed: boolean) => void) {
  let ctrlPressed = false;

  function onKeyDown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && !ctrlPressed) {
      ctrlPressed = true;
      callback.call(null, true);
    }
  }

  function onKeyUp(event: KeyboardEvent) {
    if (!event.ctrlKey && !event.metaKey && ctrlPressed) {
      ctrlPressed = false;
      callback.call(null, false);
    }
  }

  document.addEventListener('keydown', onKeyDown);
  document.addEventListener('keyup', onKeyUp);
  window.addEventListener('blur', onKeyUp);

  return function () {
    document.removeEventListener('keydown', onKeyDown);
    document.removeEventListener('keyup', onKeyUp);
    window.removeEventListener('blur', onKeyUp);
  };
}

export function createShiftPressedListener(callback: (shiftPressed: boolean) => void) {
  let shiftPressed = false;

  function onKeyDown(event: KeyboardEvent) {
    if (event.shiftKey && !shiftPressed) {
      shiftPressed = true;
      callback.call(null, true);
    }
  }

  function onKeyUp(event: KeyboardEvent) {
    if (!event.shiftKey && shiftPressed) {
      shiftPressed = false;
      callback.call(null, false);
    }
  }

  document.addEventListener('keydown', onKeyDown);
  document.addEventListener('keyup', onKeyUp);
  window.addEventListener('blur', onKeyUp);

  return function () {
    document.removeEventListener('keydown', onKeyDown);
    document.removeEventListener('keyup', onKeyUp);
    window.removeEventListener('blur', onKeyUp);
  };
}
