import { jwtEncode } from '../../utils/jwt.utils';

export function createLoginToken(options: CreateLoginTokenOptions) {
  return jwtEncode({
    type: 'browser_session',
    msg: 'LoginRequest',
    extra: {
      description: options.appDescription,
      disable_google_sign_in: options.disableGoogleSignIn,
      disable_microsoft_sign_in: options.disableMicrosoftSignIn
    },
    app_name: '',
    iss: options.appUrl,
    original_action: '',
    session_change_listener: '/',
    identity_change_listener: '/',
    return_url: options.returnUrl,
    return_url_type: 'query',
    generate_ppl_token: true,
    generate_ppl_refresh_token: true,
    select_team_space: options.queryForSpace,
    iat: Math.floor(Date.now() / 1000)
  });
}

export function createLogoutToken(options: CreateLogoutTokenOptions) {
  return jwtEncode({
    msg: 'LogoutRequest',
    iss: options.appUrl,
    app_session_id: options.appSessionId || '',
    redirect_url: options.appSessionId ? options.returnUrl : ''
  });
}

export interface CreateLoginTokenOptions {
  appDescription?: string;
  appUrl: string;
  disableGoogleSignIn?: boolean;
  disableMicrosoftSignIn?: boolean;
  queryForSpace?: boolean;
  returnUrl: string;
}

export interface CreateLogoutTokenOptions {
  appSessionId: string;
  appUrl: string;
  returnUrl: string;
}
