import { ToastContentComponent } from './toast-content/toast-content.component';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { ToastService } from './toast.service';
import { ToastComponent } from './toast/toast.component';
import { PplButtonModule } from '../button';
import { PplIconModule } from '../icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        CommonModule,
        PplButtonModule,
        PplIconModule
    ],
    declarations: [
        ToastContentComponent,
        ToastMessageComponent,
        ToastComponent
    ],
    exports: [
        ToastContentComponent,
        ToastMessageComponent,
        ToastComponent
    ],
    providers: [
        ToastService
    ]
})
export class PplToastModule {
}

export * from './toast';
export * from './toast.service';
export * from './toast-content/toast-content.component';
export * from './toast-message/toast-message.component';
export * from './toast/toast.component';
