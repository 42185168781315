import { PplButtonComponent } from './button.component';
import {
  PplBlockButtonDirective,
  PplButton1Directive,
  PplButton2Directive,
  PplButton3Directive,
  PplButton4Directive,
  PplButton5Directive,
  PplButton6Directive,
  PplButton7Directive,
  PplButtonActiveDirective,
  PplButtonAlertDirective,
  PplButtonBlockDirective,
  PplButtonBoldDirective,
  PplButtonCancelDirective,
  PplButtonDialogDirective,
  PplButtonFocusableDirective,
  PplButtonLargeDirective,
  PplButtonPressedDirective,
  PplButtonSaveDirective,
  PplButtonSelectedDirective,
  PplButtonSmallDirective,
  PplButtonTypeDirective
} from './button.directive';
import { PplButtonsGroupComponent } from './buttons-group/buttons-group.component';
import { NgModule } from '@angular/core';

const BUTTON_DIRECTIVES = [
  PplButtonSmallDirective,
  PplButtonLargeDirective,
  PplButtonBoldDirective,
  PplButtonBlockDirective,
  PplButtonActiveDirective,
  PplButtonSelectedDirective,
  PplButton1Directive,
  PplButton2Directive,
  PplButton3Directive,
  PplButton4Directive,
  PplButton5Directive,
  PplButton6Directive,
  PplButton7Directive,
  PplButtonTypeDirective,
  PplButtonDialogDirective,
  PplButtonAlertDirective,
  PplButtonFocusableDirective,
  PplButtonSaveDirective,
  PplButtonCancelDirective,
  PplBlockButtonDirective,
  PplButtonPressedDirective
];

@NgModule({
  imports: [],
  declarations: [
    PplButtonComponent,
    PplButtonsGroupComponent,
    ...BUTTON_DIRECTIVES
  ],
  exports: [
    PplButtonComponent,
    PplButtonsGroupComponent,
    ...BUTTON_DIRECTIVES
  ],
})
export class PplButtonModule {
}

export * from './button.component';
export * from './button.directive';
export * from './buttons-group/buttons-group.component';

