<div class="container demo-content">
  <h2>Editable Component</h2>

  <br>

  <h3>Module</h3>
  <div>PplEditableModule</div>

  <br>

  <h3>Component</h3>
  <h4>ppl-editable</h4>
  <div>@Input <b>value</b>: string</div>
  <div>@Input <b>placeholder</b>: string</div>
  <div>@Input <b>disabled</b>: boolean</div>
  <div>@Input <b>iconSize</b>: string = 16px</div>
  <div>@HostBinding('style.fontSize') @Input <b>fontSize</b>: string = 16px</div>
  <br>
  <div>@Output <b>valueChange</b>: EventEmitter &lt;string&gt;</div>

  <br>
  <br>

  <h3>Example</h3>

  <ppl-input-container label="Inline Edit">
    <ppl-editable [value]="value" (valueChange)="setValue($event)"></ppl-editable>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-editable [value]="value" disabled="true" (valueChange)="setValue($event)"></ppl-editable>
  </ppl-input-container>

  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>

</div>
