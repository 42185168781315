import { TemplateRef , ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ppl-ui-toolbar',
  templateUrl: 'ui-toolbar.component.html',
  styleUrls: ['ui-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplUiToolbarComponent {

  @HostBinding('class.empty') @Input() empty = false;
  @HostBinding('class.no-content') @Input() noContent = false;

  @ContentChild('toolbarActions', { static: true }) toolbarActions?: TemplateRef<any>;
  @ContentChild('toolbarControls', { static: true }) toolbarControls?: TemplateRef<any>;

}
