import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {
  createGridDefaultSortFunction,
  getGridSortOrder,
  GridSelection,
  sortGridData
} from '@ppl/ui/grid';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import type {
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import type {
  GridAction,
  GridAvailableColumn,
  GridColumn,
  GridSort,
  GridFooterItem
} from '@ppl/ui/grid';
import type { Observable } from 'rxjs';

export interface CommonGridColumn {
  id: string;
  name: string;
  hiddenOnInit?: boolean;
  type?: string;
  width?: number;
  sortable?: boolean;
  draggable?: boolean;
  stretch?: boolean;
  headerTemplate?: TemplateRef<any>;
  removeDisabled?: boolean;
  frozen?: boolean;
  pixels?: boolean;
}

export interface CommonGridSortFunctions<T> {
  [columnId: string]: (a: T, b: T) => number;
}

export type CommonGridData = any & { invalid?: boolean };


@Component({
  selector: 'ppl-common-grid',
  templateUrl: './common-grid.component.html',
  styleUrls: ['./common-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonGridComponent implements OnInit, OnChanges {

  @Input() columns: CommonGridColumn[];
  @Input() columnSort?: GridSort[];
  @Input() useDefaultColumnSortFunction = false;
  @Input() columnSortFunctions?: CommonGridSortFunctions<any>;
  @Input() columnTemplates: { [id: string]: TemplateRef<any> } = {};
  @Input() data: CommonGridData[];
  @Input() actions: GridAction[] = [];
  @Input() actionsTemplate: TemplateRef<any>;
  @Input() selection: GridSelection;
  @Input() selectionMode: 'single' | 'multiple' = 'multiple';
  @Input() rowHeight = 40;
  @Input() singleAction = true;
  @Input() displayColumnSelector = true;
  @Input() footerItems?: GridFooterItem[];

  @Output() selectionChange = new EventEmitter<GridSelection>();
  @Output() actionClick = new EventEmitter<{ record: any, action: GridAction }>();
  @Output() recordDoubleClick = new EventEmitter<any>();
  @Output() sort = new EventEmitter<GridSort[]>();
  @Output() columnsChange = new EventEmitter<GridColumn[]>();

  gridAvailableColumns: GridAvailableColumn[];
  gridColumns$: BehaviorSubject<GridColumn[]> = new BehaviorSubject([]);
  gridSort$: BehaviorSubject<GridSort[]> = new BehaviorSubject([
    { id: '', direction: 0 }
  ]);
  gridData$: Observable<CommonGridData[]>;
  data$: BehaviorSubject<CommonGridData[]> = new BehaviorSubject([]);

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columns) {
      this.updateGridColumns(changes.columns.currentValue);
    }

    if (changes.data) {
      this.updateGridData(changes.data.currentValue);
    }

    if (changes.columnSort && !changes.columnSort.firstChange) {
      if (changes.columnSort.previousValue && changes.columnSort.currentValue) {
        this.gridSort$.next(changes.columnSort.currentValue);
      } else {
        this.setupSortHandling(changes.columnSort.currentValue);
      }
    }

    if (changes.columnSortFunctions && changes.columnSortFunctions.currentValue && this.columnSort) {
      console.warn('common-grid: Using `columnSortFunctions` will have no effect when also using `columnSort`.');
    }
  }

  ngOnInit() {
    this.setupSortHandling(this.columnSort, this.useDefaultColumnSortFunction);
  }

  getColumnSortFunction(columnId: string) {
    const columnSortFunction = this.columnSortFunctions && this.columnSortFunctions[columnId];
    return columnSortFunction || createGridDefaultSortFunction(columnId);
  }

  setupSortHandling(sort: GridSort[], useDefaultColumnSort?: boolean) {
    if (sort && !useDefaultColumnSort) {
      this.gridData$ = this.data$.asObservable();
      this.gridSort$.next(sort);
    } else {
      this.gridSort$.next(sort ? sort : [
        getGridSortOrder(this.gridAvailableColumns)
      ]);
      this.gridData$ = combineLatest([
        this.data$,
        this.gridSort$.asObservable()
      ]).pipe(
        map(([gridData, gridSort]) => {
          return sortGridData(
            gridData,
            gridSort,
            this.getColumnSortFunction(gridSort[0].id)
          );
        })
      );
    }
  }

  updateGridData(data: CommonGridData) {
    this.data$.next(data);
  }

  updateGridColumns(columns: CommonGridColumn[]) {
    const createGridAvailableColumn = (column: CommonGridColumn): GridAvailableColumn => {
      const { type } = column;
      const availableColumn: GridAvailableColumn = {
        id: column.id,
        name: column.name,
        sortable: column.hasOwnProperty('sortable') ? column.sortable : true,
        draggable: column.hasOwnProperty('draggable') ? column.draggable : true,
        removeDisabled: column.hasOwnProperty('removeDisabled') ? column.removeDisabled : false,
        stretch: column.hasOwnProperty('stretch') ? column.stretch : false,
        headerTemplate: column.hasOwnProperty('headerTemplate') ? column.headerTemplate : undefined,
        ...(type ? { type } : {})
      };

      return availableColumn;
    };

    this.gridAvailableColumns = columns.map(
      column => createGridAvailableColumn(column)
    );
    this.gridColumns$.next(
      columns.filter(column => !column.hiddenOnInit).map(column => {
        return {
          id: column.id, width: column.width || 1, frozen: column.frozen, pixels: column.pixels
        };
      })
    );
  }

  onGridSortChange(sort: GridSort[]) {
    if (this.columnSort && !this.useDefaultColumnSortFunction) {
      this.sort.emit(sort);
    } else {
      this.gridSort$.next(sort);
    }
  }

  onGridColumnsChange(columns: GridColumn[]) {
    this.columnsChange.emit(columns);
    this.gridColumns$.next(columns);
  }

}
