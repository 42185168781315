<input pplInput
       [disabled]="disabled"
       [value]="value"
       [autocomplete]="autocompleteId"
       [id]="null"
       (blur)="inputBlur.emit()"
       (input)="onValueChange($event.target.value)">
<button *ngIf="showInitiateCall"
        type="button"
        [disabled]="value === '' ? true : null"
        pplButton
        pplButton1
        pplButtonFocusable
        [pplPopover]="popoverTemplate"
        pplPopoverActiveClass="ppl-button--active"
        pplPopoverAlignEnd>
  <ppl-icon svgIcon="make-call"
            width="18px"
            height="18px"></ppl-icon>
</button>
