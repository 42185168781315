import type {
  AfterContentInit,
  OnChanges,
  OnInit,
  SimpleChanges

} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output
,
  ChangeDetectorRef,
  ElementRef,
  QueryList,
  Renderer2
} from '@angular/core';

/**
 * Behaves much like radio button
 */

@Component({
  selector: 'ppl-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplMenuComponent implements OnInit, AfterContentInit {

  /** Child menu items */
  @ContentChildren(forwardRef(() => PplMenuItemComponent)) menuItems: QueryList<PplMenuItemComponent>;

  /** Currently selected value */
  @Input() value: string;

  /** Put a check in front of a selected option */
  @Input() markSelected = false;

  /** Creates space for icons */
  @Input() hasIcons = false;

  @Input() hasChildren = false;

  /** Setting the exact width of container */
  @Input() @HostBinding('style.width.px') width: boolean | number = false;

  @Input() autoFocus = true;

  /** Emits changed value */
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterContentInit(): void {
    this.reselectMenuItem();
    this.menuItems.changes.subscribe(() => this.reselectMenuItem());
  }

  emitValueChanged(value: string) {
    if (this.markSelected && value === this.value) {
      return;
    }
    this.value = value;
    this.reselectMenuItem();
    this.valueChanged.emit(value);
  }

  private reselectMenuItem() {
    this.menuItems.forEach(menuItem => {
      menuItem.selected = (menuItem.value === this.value);
      // console.log(menuItem.selected, menuItem.value, this.value);
      menuItem.changeDetectorRef.markForCheck();
    });
  }
}

@Component({
  selector: 'ppl-menu-item',
  templateUrl: './menu-item/menu-item.component.html',
  styleUrls: ['./menu-item/menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplMenuItemComponent implements OnInit, OnChanges {

  /** is selected */
  @Input() selected: boolean;

  /** menu item value */
  @Input() value: string;

  @Input() @HostBinding('class.hover') hover = false;

  @Input() hasChildren = false;

  /** whether the menu item is disabled */
  @Input() @HostBinding('class.disabled') disabled: boolean;

  @Input() @HostBinding('tabindex') tabindex = 0;

  constructor(
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    public changeDetectorRef: ChangeDetectorRef,
    public parentMenuComponent: PplMenuComponent
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && !changes['value'].isFirstChange() && changes['value'].currentValue !== changes['value'].previousValue) {
      this.selected = this.value === this.parentMenuComponent.value;
      if (this.selected) {
        this.parentMenuComponent.emitValueChanged(this.value);
      }
    }
  }

  ngOnInit() {
  }

  @HostListener('click')
  @HostListener('keyup.enter')
  onClick() {
    if (!this.disabled) {
      this.parentMenuComponent.emitValueChanged(this.value);
    }
  }
}
