import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { PplDecimalPipe } from './decimal.pipe';

@Pipe({
  name: 'pplFileSize'
})
export class PplFileSizePipe implements PipeTransform {
  constructor(private decimalPipe: PplDecimalPipe) { }

  transform(value: number) {
    if (value < 1048576) {
      return `${this.decimalPipe.transform(value / 1024, '1.0-0')} KB`;
    }

    return `${this.decimalPipe.transform(value / 1048576, '1.0-1')} MB`;
  }
}
