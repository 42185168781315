import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '@ppl/ui/icon';
import { StepsComponent } from './steps.component';


@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    StepsComponent
  ],
  exports: [
    StepsComponent
  ]
})
export class PplComponentsStepsModule { }
