<div class="container demo-content">
  <h2>Form Table</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-form-table</h4>
  <div>@Input() <b>title</b>: string</div>
  <div>@Input() <b>hasSubItemsSlider</b>: boolean</div>
  <div>@Input() <b>items</b>: PplFormTableItem[]</div>
  <div>@Input() <b>disabled</b>: boolean</div>
  <div>@Input() <b>openedByDefault</b>: boolean</div>
  <div>@Input() <b>includeSubunitsLabel</b>: string</div>
  <div>@Input() <b>displaySearch</b>: boolean</div>
  <br>
  <br>
  <div>@Output() <b>changeItems</b>: PplFormTableItem[]</div>
  <br>
  <br>

  <h4>Example</h4>
  <ppl-form-table [items]="items"
                  (changeItems)="onSimpleChange($event)"
                  title="example"></ppl-form-table>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>