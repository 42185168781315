import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplLoadingComponent {
  @Input() inverse = false;
  @Input() large = false;
  @Input() withLogo = false;
}
