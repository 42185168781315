import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-bottom-bar-demo',
  templateUrl: './bottom-bar-demo.component.html',
  styleUrls: ['./bottom-bar-demo.component.scss']
})
export class BottomBarDemoComponent implements OnInit {
  code = `
    <ppl-bottom-bar>
      <button pplBottomBarButton>Oppty</button>
      <button pplBottomBarButton>Contact</button>
      <button pplBottomBarButton>Account</button>
    </ppl-bottom-bar>
  `;

  constructor() { }

  ngOnInit() {
  }

}
