<div class="item"
     [attr.data-itemId]="item.id"
     [class.item-unavailable]="item.unavailable || item.deleted">
  <div class="item-overlay"
       (click)="onItemClick()"
       [class.selected]="item.checked"></div>

  <div class="item-content">
    <ng-container *ngIf="collapsible && item.children.length">
      <span *ngIf="isOpened"
            class="toggle-icon toggle-icon--minus"
            (click)="toggleOpened($event)"></span>
      <span *ngIf="!isOpened"
            class="toggle-icon toggle-icon--plus"
            (click)="toggleOpened($event)"></span>
    </ng-container>
    <ng-container *ngIf="item.avatarEntity || item.picture">
      <ppl-avatar [entity]="item.avatarEntity"
                  [picture]="item.picture"
                  size="24"
                  [forceRound]="true"
                  [class.not-collapsible]="!collapsible"></ppl-avatar>
    </ng-container>
    <div *ngIf="!hideUnavailableLabels; else unavailable"
         class="item-label">
      <ppl-highlight [text]="item.name"
                     [class.highlighted]="highlighted"
                     [class.unavailable-label]="item.unavailable"
                     [highlight]="searchValue"></ppl-highlight>
      <span *ngIf="item.deleted && !item.unavailable"
            i18n="@@(Deleted)"
            class="unavailable">(Deleted)</span>
      <span *ngIf="!item.deleted && item.unavailable"
            i18n="@@(Unavailable)"
            class="unavailable">(Unavailable)</span>
    </div>
    <ppl-icon *ngIf="item.checked"
              svgIcon="checkmark"
              width="12px"
              height="12px"></ppl-icon>
  </div>
</div>
<ng-container *ngIf="isOpened && item.children.length">
  <!-- child -->
  <ppl-tree-select-items [isRoot]="false"
                         [searchValue]="searchValue"
                         [collapsible]="collapsible"
                         [items]="item.children"
                         [openedByDefault]="openedByDefault"
                         [highlightCategories]="highlightCategories"
                         (toggle)="toggle.emit($event)">
  </ppl-tree-select-items>

</ng-container>

<ng-template #unavailable>
  <span i18n="@@(Unavailable)"
        class="unavailable">(Unavailable)</span>
</ng-template>
