import type { AuthActions } from '@ppl/auth';
import type { AsyncValue} from '@ppl/store';
import { Store } from '@ppl/store';
import type { Acl, Space, SubscriptionEntityConnection, UserProfileEntity, UserSettings } from '@ppl/graphql-user-api';

export class AuthUser {
  profile: UserProfileEntity;
  settings: UserSettings;
  spaces: Space[];
  accessControl: Acl;
  entities: {
    subscription: {
      getAdministeredByMe: SubscriptionEntityConnection
    }
  };
}

export interface AuthState {
  user: AsyncValue<AuthUser>;
  angularLocaleId: string;
  ssoToken: string | null;
  ssoSpaceId: string | null;
}

export interface AuthStateInCoreState {
  auth: AuthState;
}

export class AuthStore extends Store<AuthStateInCoreState, AuthActions> {}


