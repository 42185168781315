<input *ngIf="displayInput"
       #dateInput
       pplInput
       class="form-control date-input"
       type="text"
       autocomplete="off"
       [attr.readonly]="readonly ? true : null"
       [disabled]="disabled || disabledValue"
       [value]="dateValue"
       [placeholder]="placeholder"
       (input)="onDateInput()"
       (keyup)="onDateKeyUp($event)"
       (blur)="onDateBlur()" />

<button #pickerButton
        [disabled]="disabled || readonly"
        [class.ppl-button--1]="!buttonLabel"
        [class.ppl-button--2]="buttonLabel"
        class="date-input-button"
        pplButton
        pplButtonSmall
        pplButtonFocusable
        [pplPopover]="pickerPopover"
        pplPopoverAlignEnd
        type="button"
        (pplPopoverToggle)="onPopoverOpened($event.visible)">
  <ppl-icon *ngIf="!buttonLabel"
            svgIcon="calendar"
            width="15px"
            height="15px"></ppl-icon>
  {{buttonLabel}}
</button>

<ng-template #pickerPopover>
  <div class="container"
       cdkTrapFocus>
    <div #pickerHiddenFocus
         tabindex="0"></div>
    <div *ngIf="presets"
         class="presets">
      <ppl-date-presets [presets]="presets"
                        [value]="value"
                        (valueChange)="onPresetValueChange($event)"></ppl-date-presets>
    </div>
    <div class="picker">
      <ppl-date-picker [displayTime]="displayTime"
                       [displayRemove]="displayRemove"
                       [displayPlanner]="displayPlanner"
                       [displayToday]="displayToday"
                       [firstWeekDay]="firstWeekDay"
                       [format]="format"
                       [locale]="locale"
                       [minViewMode]="minViewMode"
                       [value]="popoverValue"
                       [fullWidthButton]="fullWidthButton"
                       [defaultValue]="popoverDefaultValue"
                       [highlightDate]="popoverHighlightDate"
                       (valueChange)="onPopoverValueChange($event)"
                       (plannerClick)="onPlannerClick($event)"
                       (popoverClose)="onPopoverClose($event)"
                       (todayClick)="onTodayClick($event)"></ppl-date-picker>
    </div>
  </div>
</ng-template>
