import { PplMenuDividerComponent } from './menu-divider/menu-divider.component';
import { PplMenuComponent, PplMenuItemComponent } from './menu.component';
import { PplIconModule } from '../icon';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

const components = [
  PplMenuComponent,
  PplMenuItemComponent,
  PplMenuDividerComponent
];

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    A11yModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class PplMenuModule {
}

export * from './menu.component';
