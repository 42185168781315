import moment from 'moment';
import type { Moment } from 'moment';

// don't modify
const today = moment();

export function getDaysOverdue(isoDate: string) {
  // Returns negative number if isoDate is in future
  return today.diff(isoDate, 'days');
}

export function isOverdue(isoDate: string) {
  // Returns if current date is over specified date (YYYY-MM-DD)
  // Returns false if the specified date is today
  return today.format('YYYY-MM-DD') > isoDate;
}

export function getDaysBetween(isoDate1: string, isoDate2: string) {
  return Math.abs(moment(isoDate1).diff(isoDate2, 'days'));
}

export function momentToIsoDateString(momentDate: Moment) {
  return momentDate.format('YYYY-MM-DD');
}

export function momentToIsoDateTimeString(momentDate: Moment) {
  return momentDate.format();
}

export function extractLocalDate(date: Date) {
  return moment(date).format('YYYY-MM-DD');
}

export function getTodayDate() {
  return momentToIsoDateString(moment());
}

export const TIMEZONE_NAME_OR_OFFSET = (() => {
  return (new Intl.DateTimeFormat()).resolvedOptions().timeZone || (-(new Date()).getTimezoneOffset()).toString();
})();

export function addBusinessDays(momentDate: Moment, freeDays: number[], days: number) {
  let daysToAdd = days;
  let currentDay = momentDate.clone();

  while (daysToAdd > 0) {
    currentDay = currentDay.add(1, 'day');

    if (!freeDays.includes(currentDay.day())) {
      daysToAdd--;
    }
  }

  return currentDay;
}

export function getPreviousBusinessDay(momentDate: Moment, freeDays: number[]) {
  let currentDay = momentDate.clone();

  while (freeDays.includes(currentDay.day())) {
    currentDay = currentDay.subtract(1, 'day');
  }

  return currentDay;
}

export function isValidDate(date: string) {
  return new Date(date).toString() !== 'Invalid Date' && !isNaN(+new Date(date));
}

export function getCurrentYear() {
  return new Date().getFullYear();
}
