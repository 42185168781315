<ppl-content-title [label]="label"
                   [icon]="icon"
                   [infoMessage]="infoMessage"
                   [labelTemplate]="labelTemplate"
                   [actionTemplate]="actionTemplate"
                   [flex]="flex"></ppl-content-title>

<div *ngIf="displayContent"
     class="content">
  <ng-content></ng-content>
</div>
