<div class="container demo-content">
  <h2>Text Dropdown Component</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-text-dropdown</h4>
  <div>@Input() <b>options</b>: {{ 'PplTextDropdownOption[]' }}</div>
  <div>@Input() <b>value</b>: PplTextDropdownOption</div>

  <br>
  <div>@Output() <b>valueChange</b></div>

  <br>
  <br>

  <h3>Example</h3>

    <ppl-text-dropdown [value]="value"
                       [options]="options"
                       (valueChange)="value = $event"></ppl-text-dropdown>
    <br>
    <br>

    <h4>Code Example</h4>
    <ppl-prism [code]="code"></ppl-prism>
</div>
