<div class="container demo-content">
  <div>TODO: image scaling, image button</div>
  <h2>Addressbook Picture</h2>

  <br>

  <h3>Used Directive</h3>
  <h4>pplDropZone</h4>

  <br>

  <h3>Components</h3>
  <h4>ppl-addressbook-picture</h4>
  <div>@Input() <b>image</b>: string | null; <i>(default null)</i></div>
  <div>@Input() <b>entity</b>: 'contact' | 'account'; <i>(default 'contact')</i></div>
  <div>@Input() <b>isEditable</b>: boolean <i>(default false)</i></div>
  <div>@Input() <b>forceRound</b>: boolean <i>(default false)</i></div>
  <br>
  <br>

  <h3>Example</h3>
  <div class="row">
    <div class="picture-demo col-12">
      <h4>Account with image</h4>
      <ppl-addressbook-picture entity="account"
                               [picture]="netflixImage"></ppl-addressbook-picture>
    </div>
    <div class="picture-demo col-12">
      <h4>Contact with image</h4>
      <ppl-addressbook-picture entity="contact"
                               [picture]="personImage"></ppl-addressbook-picture>
    </div>

    <div class="picture-demo col-12">
      <h4>Contact</h4>
      <ppl-addressbook-picture [isEditable]="true"></ppl-addressbook-picture>
    </div>
    <div class="picture-demo col-12">
      <h4>Account</h4>
      <ppl-addressbook-picture entity="account"
                               [isEditable]="true"></ppl-addressbook-picture>
    </div>
    <div class="picture-demo col-12">
      <h4>Account Round</h4>
      <ppl-addressbook-picture entity="account"
                               [isEditable]="true"
                               [forceRound]="true"></ppl-addressbook-picture>
    </div>
  </div>
  <br>
  <br>

  <h3>Code</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
