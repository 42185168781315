import type { Toast } from './toast';
import { ToastContentComponent } from './toast-content/toast-content.component';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import type {
  ComponentRef
} from '@angular/core';
import {
  Injectable
,
  ApplicationRef,
  ComponentFactoryResolver,
  Injector
} from '@angular/core';
import { isSubscribed, unsubscribe } from '@ppl/utils';
import type { Subscription} from 'rxjs';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ToastService {
  private bodyPortalHost: DomPortalOutlet;
  private componentRef: ComponentRef<ToastContentComponent>;

  private showToastsSubscription: Subscription;
  private closeSubscription: Subscription;

  private toastsToShow: Toast[] = [];

  get isVisible() {
    return !!this.componentRef;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.bodyPortalHost = new DomPortalOutlet(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }

  show<D = any>(toast: Toast<D>) {
    toast.options ??= {};
    (toast.data as any) ??= {};

    this.toastsToShow.push(toast);
    this.checkNextToast();
  }

  showMessage(message: string, actionText?: string, options?: { actionClicked?: () => void }) {
    return this.show({
      component: ToastMessageComponent,
      data: { message, actionText, actionClicked: options?.actionClicked }
    });
  }

  hide(toast?: Toast) {
    if (toast) {
      this.detachToast(toast);
    } else {
      this.toastsToShow = [];
      this.detach();
    }
  }

  private checkNextToast(forceQueue = false) {
    if (forceQueue || !isSubscribed(this.showToastsSubscription)) {
      unsubscribe(this.showToastsSubscription);
      this.showToastsSubscription = timer(SHOW_TOAST_DELAY_MS).pipe(
        tap(() => {
          const toast = this.toastsToShow.shift();

          if (toast) {
            this.showNextToast(toast);
          }
        })
      ).subscribe();
    }
  }

  private detach() {
    unsubscribe(this.closeSubscription);
    unsubscribe(this.showToastsSubscription);

    this.componentRef = null;
    this.bodyPortalHost.detach();
  }

  private detachToast(toast: Toast) {
    this.componentRef?.instance?.onCloseSingle(toast);
  }

  private showNextToast(toast: Toast) {
    if (this.componentRef) {
      this.componentRef.instance.addToast(toast);
    } else {
      // attach toast content
      const contentPortal = new ComponentPortal(ToastContentComponent, null, this.injector);
      this.componentRef = this.bodyPortalHost.attachComponentPortal(contentPortal);

      // bootstrap content variables
      const componentInstance = this.componentRef.instance;
      this.closeSubscription = componentInstance.close.subscribe(() => this.hide());
      this.componentRef.instance.addToast(toast);
    }

    this.checkNextToast(true);
  }
}

const SHOW_TOAST_DELAY_MS = 100;
