// sync with file from https://bitbucket.org/pipeliner/pipeliner-api/src/HEAD/pipeliner_core/pipeliner_core/errors.py

export enum ErrorCode {
  NO_ERROR = 0,
  ERROR_CONFLICT_DETECTED = 1, // A conflict is detected.
  ERROR_INCORRECT_LOGIN = 2, // Incorrect login.
  ERROR_TEAM_SPACE_NOT_ACTIVATED = 3, // The team space is not activated.
  ERROR_DATA_COULD_NOT_BE_CONVERTED = 4, // The data could not be converted.
  ERROR_INVALID_MESSAGE = 5, // Invalid message.
  ERROR_INVALID_TEAM_SPACE_VERSION = 6, // Invalid version of team pipeline.
  ERROR_UNKNOWN_VERSION = 7, // Unknown version.
  ERROR_ENGINE_FOR_VERSION_NOT_EXISTS = 8, // Synchronization engine for this version doesn't exist.
  ERROR_DB_FOR_VERSION_NOT_EXISTS = 9, // The database for this version doesn't exist.
  ERROR_USING_HIGHER_VERSION_TO_CS = 10, // The client uses higher version than version of team pipeline.
  ERROR_USING_OLDER_VERSION_TO_CS = 11, // The client uses older version than team pipeline.
  ERROR_VERSION_UPGRADE_FAILED = 12, // Upgrade of team space to higher version failed.
  ERROR_VERSION_UPGRADE_ALREADY_DONE = 13, // Upgrade of team space has been already done to selected version
  ERROR_REVISION_ENGINE_HAS_LOCK = 14,
  ERROR_REVISION_ENGINE_HAS_NOT_LOCK = 15,
  ERROR_UNKNOWN_USER = 17, // The account doesn't exist. You need an invitation or you can create a new account
  ERROR_UNEXPECTED_USER = 18, // Login was successful (using 3rd party to verify account), but we expected different user.

  ERROR_NOT_ENOUGH_PERMISSIONS = 50,
  ERROR_FEATURE_NOT_ALLOWED = 51,
  ERROR_FIELD_ACCESS_NOT_ALLOWED = 52,
  ERROR_FIELD_ACCESS_INVALID_CONDITION = 53,
  ERROR_SHARE_MODE_NOT_ALLOWED = 54,

  ERROR_TEAM_SPACE_NOT_UNIQUE = 100, // Name of team pipeline is not unique.
  ERROR_INCORRECT_VERSION_NUMBER = 101, // Unsupported version number.
  ERROR_DATABASE_QUERY = 102, // Invalid request. The database is corrupted.
  ERROR_USER_OWNS_OPPTY = 103,
  ERROR_CLIENTS_LIMIT_REACHED = 104, // Maximum clients connected to team pipeline reached.
  ERROR_SN_ALREADY_OCCUPIED = 105, // The serial number is already occupied.
  ERROR_NO_MORE_LICENSE_SEATS_AVAILABLE = 106, // No more license seats are available.
  ERROR_LICENSE_DOES_NOT_EXISTS = 107, // License doesn't exist.
  ERROR_SN_NOT_OCCUPYING_LICENSE = 108, // Serial number is not occupying license.
  ERROR_LICENSE_ALREADY_EXPIRED = 109, // License already expired.
  ERROR_READ_PIPELINE_INIT_FILE = 110,
  ERROR_INVALID_SN = 111, // Serial number is invalid.
  ERROR_READING_TEMPLATE_FILE = 112,
  ERROR_CANNOT_CONVERT_USERSPACE_USED = 113,
  ERROR_TRANSACTION_NOT_OPEN = 114, // Transaction is not open.
  ERROR_TEAM_SPACE_NOT_EXISTS = 115, // Team pipeline doesn't exist.
  ERROR_NOT_IMPLEMENTED = 116, // The method is not implemented yet.
  ERROR_PARAMETER_INVALID = 117, // Invalid parameter. Type or the value of this parameter is wrong.
  ERROR_PARAMETER_UNKNOWN = 118, // Unknown parameter.
  ERROR_PARAMETER_MISSING = 119, // Required parameter is missing.
  ERROR_RELATIONS_DOESNT_EXIST = 120, // The related object doesn't exist.
  ERROR_CYCLE = 121, // You want to set a cycle, which is not allowed.
  ERROR_BATCH = 122, // The class cannot be updated or inserted.
  // (This exception is generated if is set flag<span class=rvts8>FLAG_CHANGE_2_INSERT</span>).

  ERROR_LEAD_NOT_FOUND = 123,
  ERROR_LEAD_ALREADY_ASSIGNED = 124,
  ERROR_SALES_UNIT_DOESNT_EXISTS = 125,
  ERROR_CLIENT_DOESNT_EXISTS = 126,
  ERROR_CLIENT_DOESNT_EXISTS_IN_SALES_UNIT = 127,
  ERROR_UNAUTHORIZED_CALL = 128, // Authentication failed. The username or password is wrong.
  // Or the user doesn't have access to requested team’s space.

  ERROR_NO_LONGER_SUPPORTED = 129, // Entity is no longer supported for actual Server API version.
  ERROR_SN_DISABLED = 130,
  ERROR_CONTRACT_DISABLED = 131,
  ERROR_ACCOUNT_DISABLED = 132,
  ERROR_TEAM_PIPELINE_DOESNT_EXISTS_OR_ACCESS_DENIED = 133,
  ERROR_TEAM_PIPELINE_IS_COMMERCIAL = 134,
  ERROR_SN_DOESNT_EXISTS_OR_ACCESS_DENIED = 135,
  ERROR_INVALID_CONTRACT = 136,
  ERROR_ACCOUNT_DOESNT_EXISTS = 137,
  ERROR_SERIAL_IS_NOT_TEAM = 138,
  ERROR_SIZE_CANT_BE_DETERMINED = 139,
  ERROR_TEAM_SPACE_NO_MORE_SPACE_LEFT = 140,
  ERROR_TIME_OVERLAPPED = 141, // The time period overlaps with other period.
  ERROR_CLIENT_EXISTS = 142, // The client exists already. You cannot store client with this same username.
  ERROR_NO_ENTITY = 143, // The entity doesn't exist. You use method set with wrong<span class=rvts8>ID</span>.
  ERROR_AUTHORIZATION_ENGINE_NOT_AVAILABLE = 144,
  ERROR_CANNOT_CONVERT_USERSPACE_INTEGRATED = 145,
  // ERROR_SINGLETON_ERROR = 146,
  ERROR_USER_ACCOUNT_DISABLED = 147,
  ERROR_USER_IN_TEAM_PIPELINE_DISABLED = 148,
  ERROR_SUBSCRIPTION_HAS_EXPIRED = 149,
  ERROR_CLIENT_SPACE_RESET = 2,
  ERROR_AUTO_LOGOUT = 2,

  ERROR_UNEXPECTED_RESPONSE = 190,

  ERROR_NO_PRIMARY_EMAIL = 150, // Obsoleted from version 5
  ERROR_NO_PRIMARY_PHONE = 151, // Obsoleted from version 5

  ERROR_ACC_NO_OWNER = 152,
  ERROR_SCHEMA_CHANGED = 153,
  ERROR_REVISION_ENGINE_IN_READONLY_MODE = 154,
  ERROR_MULTIPLE_RECORDS_FOUND = 155,
  ERROR_DOCUMENT_IS_DELETED_ALREADY = 158,
  ERROR_FORMUPDATE_COMMAND_NOT_RECOGNIZED = 159,
  ERROR_FORGET_PASSWORD_EMAIL_SEND_FAILED = 160,
  ERROR_FORGET_PASSWORD_EMAIL_INVALID = 161,
  ERROR_FORGET_PASSWORD_EMAIL_MISMATCH = 162,
  ERROR_SENDING_MAIL = 163,
  ERROR_TRIAL_EMAIL_EXISTS = 164,
  ERROR_INVALID_EMAIL = 165,
  ERROR_TEAM_SPACE_TRANSFER = 166,
  ERROR_RELOAD_TOKEN = 167,
  ERROR_CHANGES_ARE_BG_SYNC_NOT_COMPATIBLE = 168,
  ERROR_CLEANING_DUMP = 169,
  ERROR_DUMPING_DB = 170,
  ERROR_NOT_DELETABLE = 171, // Not deletable entity through method deleteEntities.
  ERROR_TRANSACTION_OPEN = 181,

  ERROR_PENDING_PARTNER_EXISTS = 2100,
  ERROR_APPROVED_PARTNER_EXISTS = 2101,
  ERROR_REJECTED_PARTNER_EXISTS = 2102,
  ERROR_ORDER_OVERPAYMENT = 2103,
  ERROR_INSUFFICIENT_CREDIT = 2110,

  ERROR_CALC_BAD_FORMULA_SYNTAX = 172,
  ERROR_CALC_CIRCULAR_REFERENCE = 173,
  ERROR_CALC_DIVIDED_BY_0 = 174,
  ERROR_CALC_INVALID_REFERENCE = 175,
  ERROR_CALC_WRONG_TYPE = 176,
  ERROR_CALC_NO_VALUE = 177,
  ERROR_CALC_FORMULA_NOT_ALLOWED = 180,
  ERROR_TOO_MANY_REQUESTS = 182,
  ERROR_CLIENT_DISCONNECTED = 183,
  ERROR_METHOD_DO_NOT_EXISTS = 184,
  ERROR_INVALID_VERSION_MODEL = 185,
  ERROR_TOO_MANY_CHANGES_FOR_SYNC = 186,
  ERROR_CALC_VALIDATION_FAILED = 187, // Parameter did not pass user defined validation.
  ERROR_INTEGRATION_FAILED = 188,
  ERROR_NOT_ALLOWED_TO_USE_MOBILE = 189,

  ERROR_SIMULTANEOUS_UPDATE = 198, // trying to update entity that was already updated by other user

  ERROR_NO_PIPELINE_ACCESS = 199, // Owner has no access to specified Pipeline.
  ERROR_BROKEN_SYNC_MODEL = 191,
  ERROR_FK_NOT_FOUND = 192,

  ERROR_PARAMETER_INVALID_MIN_STEPS = 193, // Invalid parameter. Minimum three steps per Pipeline.
  ERROR_PARAMETER_INVALID_MAX_STEPS = 194, // Invalid parameter. Maximum thirty steps per Pipeline

  ERROR_PARAMETER_INVALID_TO_ADDRESS = 195,
  ERROR_HYBRID_SPACE_IS_REQUIRED = 196,
  ERROR_ALREADY_IN_PROGRESS = 197,

  ERROR_DB_SOME_ERROR = 301, // Database error occurred operation isn’t completed.

  ERROR_INVALID_POST_PARAMS = 400,
  ERROR_UNAUTHORIZED = 401,
  ERROR_TEAM_SPACE_NOT_ACTIVE = 402,

  ERROR_OLD_ENTITY_REVISION = 403, // Revision of entity is old. Try reload entity.
  ERROR_INVALID_ENTITY_REVISION = 404, // Revision is corrupted or wrong.
  ERROR_DATA_INTEGRITY = 450,

  ERROR_MISSING_SCHEMA = 460,
  ERROR_INVALID_SCHEMA = 461,
  ERROR_PROCESS_HAS_SCHEDULED_ACTIONS = 462,
  ERROR_SCHEDULED_ACTION_ALREADY_FINISHED = 463,
  ERROR_ENTITY_TOO_LARGE = 465,

  ERROR_NOT_FOUND = 601,
  ERROR_ALREADY_EXISTS = 602,

  // ERROR_TEAM_SPACE_NOT_UNIQUE = 200,
  // ERROR_INCORRECT_VERSION_NUMBER = 201,
  // ERROR_DATABASE_QUERY = 202, // The owner isn’t set.
  // ERROR_CLIENTS_LIMIT_REACHED = 204, // The client’s limit reached.

  ERROR_CLIENTSPACE_NAME_INVALID = 205,
  ERROR_CLIENTSPACE_NAME_EMPTY = 206,
  ERROR_POSITION_ALREADY_EXISTS = 207,
  ERROR_HAS_CHILDS = 208, // Entity with childs can't be deleted.
  ERROR_MUST_HAVE_AT_LEAST_ONE_SU = 209,
  ERROR_CLIENTSPACE_UPGRADE_ERROR = 210,
  ERROR_NO_ROW_UPDATED = 211,
  ERROR_WRITE_LOCK_NOT_OBTAINED = 500, // Raised when API call waits more than 300sec for lock.
  ERROR_USER_LOCK_NOT_OBTAINED = 501,
  // It is due to some other running API call or running synchronization.

  ERROR_ATTRIBUTE_NOT_UNIQUE = 212, // Attribute must be unique.
  ERROR_UPDATE_NOT_ALLOWED = 213, // Attribute is not allowed on update.
  ERROR_PRIMARY_IS_NOT_SET = 214, // Primary entity is not set.
  ERROR_DUPLICATE_RELATION = 215, // Each relational entity must be unique.
  ERROR_MINITEMS_REQUIRED = 216, // Multiple choices are required to set in list.
  ERROR_TEAM_SPACE_UNDER_MAINTENANCE = 217, // Space is disabled for maintenance
  ERROR_NOT_DIRECT_RELATION = 219, // Used in unlink when relation is not direct
  ERROR_OCCUPIED_SCHEDULER_URL = 220, // Appointment scheduler url is already in use
  ERROR_FORBIDDEN_EXTENSION = 221, // Appointment scheduler url is already in use
  ERROR_REMOTE_FILE_NOT_FOUND = 222, // Linked remote file not found (e.g. Google Docs template is deleted in Drive)
  ERROR_REQUIRED_CALC_FORMULA_EMPTY = 223, // Calc formula on field is required - but was empty

  ERROR_UNKNOWN = 1000,

  ERROR_EASTER_EGG_FOUND = 1337,

  ERROR_ASSERT = 5000,

  ERROR_REGISTRATION_FAILED = 300,
  ERROR_LEAD_AUTHORIZATION_FAILED = 302,

  ERROR_SERVER_API = 2000,
  ERROR_CHILD_EXISTS = 2001,
  ERROR_CONFLICTING_DATA = 2002, // You cannot delete the entity, because it is used.
  ERROR_ENTITY_IS_USED = 2003, // Parent or some child is used
  ERROR_ENTITY_UPDATING_FAILED = 2004, // The updating of an entity failed.
  ERROR_TOO_MANY_STAGES = 2005, // The maximum of stages can be 7.
  ERROR_UNKNOWN_TYPE = 2006,
  ERROR_NUMBER_EXCEEDED = 2007, // The number of something is exceeded.
  ERROR_TIME_EXPIRED = 2008, // The timestamp is older.
  ERROR_SESSION_EXPIRED = 2008,
  ERROR_FIELD_EXISTS = 2009, // The field cannot be shared, because it exists in other entity.
  ERROR_FORM_UNKNOWN = 2010, // The server cannot processed a form on field. Check the syntax, or is used unknown function.
  ERROR_TERMS_CONDITIONS_NOT_ACCEPTED = 2011,
  ERROR_3RD_PARTY_AUTH_FAILED = 2012,
  ERROR_INCORRECT_PASSWORD = 2013,
  ERROR_TOO_MANY_CUSTOM_FIELDS = 2014,
  ERROR_UNAVAILABLE_COMPANY_EMAIL = 2015,
  ERROR_UNAVAILABLE_COMPANY_EMAIL_SERVICE = 2016,
  ERROR_REVENUE_SCHEDULE_INVALID_TRANSFORMATION = 2017,
  ERROR_EMAIL_UNSUBSCRIBED = 2018,
  ERROR_INVALID_ROLLUP_CONFIGURATION = 2019,
  ERROR_INVALID_ROLLUP_NESTING = 2020,
  ERROR_FIELD_FORM_PUBLISH_INVALID_CONFIGURATION = 2021,
  ERROR_EMAIL_NOT_SHARED = 2022,

  ERROR_ROLLUP_FILTER_CONTAINS_NOT_ACCESSIBLE_FIELD = 3000,
  ERROR_ROLLUP_SUMMARIZE_FIELD_NOT_ACCESSIBLE = 3001,
  ERROR_ROLLUP_SUMMARIZE_FIELD_IS_DELETED = 3002,
  ERROR_ROLLUP_RELATION_DELETED = 3003,
  ERROR_ROLLUP_INSUFFICIENT_MASTER_RIGHTS = 3004,
  ERROR_ROLLUP_FILTER_CONTAINS_DELETED_FIELD = 3005,
  ERROR_ROLLUP_SORTING_FIELD_IS_DELETED = 3006,
  ERROR_ROLLUP_SORTING_FIELD_NOT_ACCESSIBLE = 3007,
  ERROR_CONNECTION_FAILED = 3203,
  ERROR_AUTODISCOVERY_FAILED = 3205,
  ERROR_CALLING_GEO_RESTRICTED = 3207,

  // ERROR_MESSAGE_TOO_LONG = 5001,
  // ERROR_DEPLOYMENT_FAILED = 5002,
  // ERROR_DYNAMICS_CONFIGURATION_NOT_FOUND = 5003,
  // ERROR_MAX_DEQUEUE_COUNT_REACHED = 5004,

  ERROR_EXPORT_IS_RUNNING = 4000,
  ERROR_EXPORT_HAS_FAILED = 4001,
  ERROR_EXPORT_NOT_FOUND = 4002,

  ERROR_IMAP_HOST_UNREACHABLE = 6000,
  ERROR_IMAP_INCORRECT_LOGIN = 6001,
  ERROR_IMAP_FOLDER_LIST_ERROR = 6002,

  ERROR_EXCHANGE_AUTODISCOVER_FAILED = 7000,
  ERROR_EXCHANGE_LOGIN_FAILED = 7001,
  ERROR_EXCHANGE_UNREACHABLE_FAILED = 7002,
  ERROR_EXCHANGE_UNSUPPORTED = 7003,

  ERROR_REPORT_IS_TOO_LARGE = 8000,
  ERROR_REPORT_HAS_EXPIRED = 8001,
  ERROR_REPORT_DEFINITION = 8002,

  // It's over...
  ERROR_JSON_FORM_INVALID = 9001,
  ERROR_JSON_FORM_PARENT_DATASET_MISSING = 9002,

  ERROR_ENTITY_VALIDATION = 40000,
  ERROR_NO_ACCESS_FOR_REQUIRED_FIELD = 40001,
  ERROR_FIELD_VALIDATION = 40002,
  ERROR_STEP_CHECKLIST_VALIDATION = 40003,

  ERROR_JOB_EXISTS = 50001,
  ERROR_JOB_NOT_FOUND = 50002,
  ERROR_JOB_NOT_FINISHED_YET = 50003,
  ERROR_JOB_RESULT_FAILURE = 50004,
  ERROR_JOB_RESULT_NOT_EXISTS = 50005,
  ERROR_JOB_PROCESS_FAILURE = 50006,
  ERROR_JOB_CONTAINS_RUNNING_WORKER = 50007,
  ERROR_INVALID_GATEWAY_SERVICE = 50008,
  ERROR_INVALID_GATEWAY_METHOD = 50009,
  ERROR_INVALID_GATEWAY_REQUEST = 50010,

  ERROR_3RD_PARTY_API_ERROR = 50011,
  ERROR_3RD_PARTY_API_TOKEN_EXPIRED = 50012,
  ERROR_3RD_PARTY_API_PERMISSION_DENIED = 50013,
  ERROR_3RD_PARTY_API_ERROR_AUTHORIZATION_FAIL = 50014,
  ERROR_3RD_PARTY_API_UNSUPPORTED = 50015,
  ERROR_3RD_PARTY_API_UNAVAILABLE = 50016,
  ERROR_3RD_PARTY_QUOTA_EXCEEDED = 50020,
  ERROR_3RD_PARTY_RESOURCE_LIMIT_EXCEEDED = 50021,
}
