import { NgModule } from '@angular/core';
import { PplTopBarComponent } from './top-bar.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplTopBarComponent
  ],
  exports: [
    PplTopBarComponent
  ]
})
export class PplTopBarModule {}

export * from './top-bar.component';
