export function clamp(value: number, minValue: number, maxValue: number) {
  return value > maxValue ? maxValue : value < minValue ? minValue : value;
}

export function floorToNearest(value: number, multiple: number) {
  return Math.floor(value / multiple) * multiple;
}

export function roundToNearest(value: number, multiple: number) {
  return Math.round(value / multiple) * multiple;
}

export function ceilToNearest(value: number, multiple: number) {
  return Math.ceil(value / multiple) * multiple;
}

export function roundToDecimal(value: number, decimals = 2) {
  const divider = Math.pow(10, decimals);

  return Math.round((value + Number.EPSILON) * divider) / divider;
}

export function getClosestPoint(p: { x: number, y: number }, a: { x: number, y: number }, b: { x: number, y: number }) {
  const lengthSquared = getPointsDistanceSquared(a, b);

  if (lengthSquared === 0) {
    return a;
  }

  let t = ((p.x - a.x) * (b.x - a.x) + (p.y - a.y) * (b.y - a.y)) / lengthSquared;
  t = Math.max(0, Math.min(1, t));

  return { x: a.x + t * (b.x - a.x), y: a.y + t * (b.y - a.y) };
}

export function getLineDegreesAngle(a: { x: number, y: number }, b: { x: number, y: number }) {
  return (Math.atan2(b.y - a.y, b.x - a.x) - Math.PI / 2) * 180 / Math.PI;
}

export function getLineSegmentIntersection(p0: { x: number, y: number }, p1: { x: number, y: number }, p2: { x: number, y: number }, p3: { x: number, y: number }) {
  const s1 = { x: p1.x - p0.x, y: p1.y - p0.y };
  const s2 = { x: p3.x - p2.x, y: p3.y - p2.y };

  const s = (-s1.y * (p0.x - p2.x) + s1.x * (p0.y - p2.y)) / (-s2.x * s1.y + s1.x * s2.y);
  const t = (s2.x * (p0.y - p2.y) - s2.y * (p0.x - p2.x)) / (-s2.x * s1.y + s1.x * s2.y);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    return {
      x: p0.x + (t * s1.x),
      y: p0.y + (t * s1.y)
    };
  }

  return null;
}

export function getPointsDistanceSquared(a: { x: number, y: number }, b: { x: number, y: number }) {
  return Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2);
}

export function mapLinear(value: number, x1: number, x2: number, y1: number, y2: number) {
  return y1 + (value - x1) * (y2 - y1) / (x2 - x1);
}

export function mapLinearClamp(value: number, x1: number, x2: number, y1: number, y2: number) {
  return clamp(y1 + (value - x1) * (y2 - y1) / (x2 - x1), Math.min(y1, y2), Math.max(y1, y2));
}

export function modulo(dividend: number, divisor: number) {
  const r = dividend % divisor;

  return (r * divisor < 0) ? r + divisor : r;
}

export function sum(previousValue: number, currentValue: number): number {
  return (previousValue || 0) + currentValue;
}
