<div class="container demo-content">
  <h2>Info Box Component</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-info-box</h4>
  <div>@Input() <b>type</b>: {{ 'info | warning | danger | error = info' }}</div>
  <div>@Input() <b>text</b>: string</div>
  <div>@Input() <b>icon</b>: string</div>
  <div>@Input() <b>buttonText</b>: string</div>
  <div>@Input() <b>enableClose</b>: boolean = false</div>

  <br>
  <div>@Output() <b>close</b></div>
  <div>@Output() <b>buttonClick</b></div>
  <br>

  <div>@ContentChild('textTemplate') <b>textTemplate</b>: TemplateRef</div>
  <div>@ContentChild('actionsTemplate') <b>actionsTemplate</b>: TemplateRef</div>

  <br>
  <br>

  <h3>Example</h3>
  <ppl-info-box type="info"
                [text]="text"
                buttonText="undo"></ppl-info-box>

  <ppl-info-box type="warning"
                [text]="text"
                buttonText="undo"></ppl-info-box>


  <ppl-info-box type="danger"
                [text]="text"
                buttonText="undo"></ppl-info-box>

  <ppl-info-box type="danger"
                text="Danger with drawer"
                buttonText="undo"
                [drawerOpen]="drawerOpen"
                [enableClose]="true">
    <ng-template #drawerTemplate>
      <div class="drawer">
        13 opportunities are without a primary account. To show opportunities on a map, link them with accounts
      </div>
    </ng-template>

    <ng-template #actionsTemplate>
      <ppl-icon class="drawer-toggle"
                svgIcon="rowline-form_disabled"
                width="16px"
                height="16px"
                (click)="drawerOpen = !drawerOpen"
                [class.active]="drawerOpen"></ppl-icon>
      <div class="divider"></div>
    </ng-template>
  </ppl-info-box>

  <ppl-info-box type="error"
                [text]="text"
                buttonText="undo"
                enableClose="true">
    <ng-template #textTemplate>
      <ng-container>You can populate contact data to account field(s)</ng-container>
    </ng-template>
    <ng-template #actionsTemplate>
      <button pplButton
              pplButtonSmall="xs"
              pplButton7="error">Populate</button>
    </ng-template>
  </ppl-info-box>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
