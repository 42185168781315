import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChildren , QueryList } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PplCarouselSlideComponent } from './carousel-slide/carousel-slide.component';

@Component({
  selector: 'ppl-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplCarouselComponent implements OnInit {
  @ContentChildren(PplCarouselSlideComponent) slides: QueryList<PplCarouselSlideComponent>;
  currentSlide = 0;

  constructor(public domSanitizer: DomSanitizer) {}
  ngOnInit() {}

  get transformStyle() {
    return this.domSanitizer.bypassSecurityTrustStyle(`translate(${this.currentSlide * 100 * -1}%, 0)`);
  }

  move(right = true) {
    const direction = right ? 1 : -1;
    const newSlide = (this.currentSlide + direction) % this.slides.length;
    this.currentSlide = newSlide >= 0 ? newSlide : this.slides.length - 1;
  }
}
