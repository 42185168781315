export interface AsyncValue<T> {
    readonly state: AsyncState;
    readonly value?: T;
    readonly error?: any;
}

export enum AsyncState {
    IDLE = 'IDLE',
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
    ERROR = 'ERROR'
}
