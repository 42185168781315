import type { EmbeddedViewRef, OnChanges, SimpleChanges } from '@angular/core';
import { Directive, Input , TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[pplVirtualScrollForOf]'
})
export class PplVirtualScrollForOfDirective implements OnChanges {

  @Input() pplVirtualScrollForOf: VirtualScrollForOfRecord[];
  @Input() pplVirtualScrollForDataKey?: string;
  @Input() pplVirtualScrollForStartIndex: number;

  viewRefs: { [id: string]: EmbeddedViewRef<any> } = {};
  recordRefs: { [id: string]: any } = {};

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pplVirtualScrollForOf) {
      // Determine which records have been created/deleted
      const recordIds = this.pplVirtualScrollForOf.map((record, index) => this.pplVirtualScrollForDataKey ? record[this.pplVirtualScrollForDataKey].toString() : (this.pplVirtualScrollForStartIndex + index).toString());

      const createIds: string[] = [];
      const deleteIds: string[] = [];

      recordIds.forEach(id => {
        if (!this.viewRefs[id]) {
          createIds.push(id);
        }
      });

      Object.keys(this.viewRefs).forEach(id => {
        if (!recordIds.includes(id)) {
          deleteIds.push(id);
        }
      });

      // Create or update record ViewRef-s
      this.pplVirtualScrollForOf.forEach((record, index) => {
        const recordId = this.pplVirtualScrollForDataKey ? record[this.pplVirtualScrollForDataKey].toString() : (this.pplVirtualScrollForStartIndex + index).toString();

        if (createIds.includes(recordId)) {
          if (deleteIds.length) {
            // Create (reuse other ID)
            const deleteId = deleteIds.pop();

            const viewRef = this.viewRefs[deleteId];
            delete this.viewRefs[deleteId];
            this.viewRefs[recordId] = viewRef;

            this.recordRefs[recordId] = record;
            viewRef.context['\$implicit'] = record;
            viewRef.context['key'] = recordId;
            viewRef.detectChanges();
          } else {
            // Create
            this.recordRefs[recordId] = record;
            this.viewRefs[recordId] = this.viewContainer.createEmbeddedView(this.templateRef, {
              '\$implicit': record,
              'key': recordId
            });
          }
        } else {
          // Update in-place
          if (record !== this.recordRefs[recordId]) {
            const viewRef = this.viewRefs[recordId];

            this.recordRefs[recordId] = record;
            viewRef.context['\$implicit'] = record;
            viewRef.context['key'] = recordId;
            viewRef.detectChanges();
          }
        }
      });

      // Delete unnecessary record ViewRef-s
      for (let i = this.viewContainer.length - 1; i >= 0; i--) {
        const viewRef = this.viewContainer.get(i) as EmbeddedViewRef<any>;

        const deleteId = viewRef.context['key'];

        if (deleteIds.includes(deleteId)) {
          delete this.viewRefs[deleteId];
          delete this.recordRefs[deleteId];

          this.viewContainer.remove(i);
        }
      }
    }
  }

  getViewRefIndexByRecord(record: any) {
    for (let i = 0; i < this.viewContainer.length; i++) {
      const viewRef = this.viewContainer.get(i) as EmbeddedViewRef<any>;

      if (viewRef.context['\$implicit'] === record) {
        return i;
      }
    }

    return -1;
  }

}

export interface VirtualScrollForOfRecord {
  id: string;
}
