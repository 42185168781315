<ppl-grid [actions]="actions"
          [actionsTemplate]="actionsTemplate"
          [singleAction]="singleAction"
          [availableColumns]="gridAvailableColumns"
          [columns]="gridColumns$ | async"
          [columnTemplates]="columnTemplates"
          [sort]="gridSort$ | async"
          [data]="gridData$ | async"
          [displayColumnSelector]="displayColumnSelector"
          [resizable]="true"
          [rowHeight]="rowHeight"
          [selection]="selection"
          [selectionMode]="selectionMode"
          [footerItems]="footerItems"
          (actionClick)="actionClick.emit($event)"
          (selectionChange)="selectionChange.emit($event)"
          (recordDoubleClick)="recordDoubleClick.emit($event)"
          (sortChange)="onGridSortChange($event)"
          (columnsChange)="onGridColumnsChange($event)">
</ppl-grid>
