import type { PhoneNumber } from '../../domain/phone-number';
import type {
  OnChanges,
  OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import type { ValidationErrors} from '@angular/forms';
import { NG_VALIDATORS, Validators } from '@angular/forms';
import type { InputPhoneNumber} from '@ppl/components/input-phone-number';
import { convertPhoneNumberToInputPhoneNumber, inputPhoneNumberValidator } from '@ppl/components/input-phone-number';
import { I18nService } from '@ppl/i18n';
import { TFormBuilder } from '@ppl/ui/form';
import type {
  TAbstractControl,
  TControlsConfig,
  TFormGroup
} from '@ppl/ui/form';
import type {
  TSimpleChanges} from '@ppl/utils';
import {
  FormValueControl,
  getFormControlProvider,
  MemoizeLast,
  PplValidators,
  Unsubscribe
} from '@ppl/utils';
import type { Subscription } from 'rxjs';

@Component({
  selector: 'ppl-calling-phone-edit',
  templateUrl: './calling-phone-edit.component.html',
  styleUrls: ['./calling-phone-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => CallingPhoneEditComponent),
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CallingPhoneEditComponent),
      multi: true
    }
  ]
})
@FormValueControl()
@Unsubscribe()
export class CallingPhoneEditComponent implements OnChanges, OnInit {

  @Input() value: CallingPhoneEditFormData;
  @Input() phone: string;
  @Input() displayForwardingSettings = true;

  @Output() valueChange = new EventEmitter<CallingPhoneEditFormData>();

  form: TFormGroup<CallingPhoneEditFormData>;

  formValueChangesSubscription: Subscription;

  static getFormControlConfig(i18nService: I18nService): TControlsConfig<CallingPhoneEditFormData> {
    return {
      name: ['', Validators.required],
      messageForwardingEmail: [null, PplValidators.email],
      callForwardingPhone: [null, inputPhoneNumberValidator(i18nService)]
    };
  }

  @MemoizeLast<CallingPhoneEditComponent>(['phone'])
  get phoneNumber(): InputPhoneNumber {
    return convertPhoneNumberToInputPhoneNumber(this.phone);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fb: TFormBuilder,
    private i18nService: I18nService
  ) {
    this.form = this.fb.group<CallingPhoneEditFormData>(
      CallingPhoneEditComponent.getFormControlConfig(this.i18nService)
    );
  }

  // eslint:disable-next-line: ng-instance-order
  ngOnChanges(changes: TSimpleChanges<CallingPhoneEditComponent>) {
    if (changes.value) {
      this.form.patchValue(changes.value.currentValue, { emitEvent: false });
    }
  }

  // eslint:disable-next-line: ng-instance-order
  ngOnInit() {
    this.formValueChangesSubscription = this.form.valueChanges.subscribe(value => {
      this.valueChange.emit(value);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: TAbstractControl<CallingPhoneEditFormData>): ValidationErrors | null {
    return this.form.valid ? null : { invalidForm: { valid: false, message: 'phone edit fields are invalid' } };
  }
}

export interface CallingPhoneEditFormData extends Omit<PhoneNumber, 'id' | 'phoneNumber' | 'capability' | 'type' | 'callForwardingPhone'> {
  callForwardingPhone: InputPhoneNumber;
}
