<div class="container demo-content">
  <h2>Input Number</h2>
  <br>
  <div>@Input
    <b>value</b>: number</div>
  <div>@Input
    <b>min</b>: number</div>
  <div>@Input
    <b>max</b>: number</div>
  <div>@Input
    <b>disabled</b>: boolean (optional)</div>
  <div>@Input
    <b>alignLeft</b>: boolean (optional, true)</div>
  <div>@Input
    <b>disabled</b>: boolean (optional)</div>
  <div>@Output
    <b>valueChange</b>: number</div>
  <br>
  <h3>Default ({{value1}})</h3>
  <ppl-input-number [value]="value1" (valueChange)="value1 = $event"></ppl-input-number>
  <br>
  <ppl-prism [code]="demo1"></ppl-prism>
  <h3>Min / Max ({{value2}})</h3>
  <ppl-input-number [value]="value2" [min]="2" [max]="15" (valueChange)="value2 = $event"></ppl-input-number>
  <br>
  <ppl-prism [code]="demo2"></ppl-prism>
  <h3>Disabled ({{value3}})</h3>
  <ppl-input-number [disabled]="true" [value]="value3" (valueChange)="value3 = $event"></ppl-input-number>
  <br>
  <ppl-prism [code]="demo3"></ppl-prism>
  <h3>Non-decimal ({{value4}})</h3>
  <ppl-input-number [decimal]="false" [value]="value4" (valueChange)="value4 = $event"></ppl-input-number>
  <br>
  <ppl-prism [code]="demo4"></ppl-prism>
</div>
