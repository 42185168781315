<div class="value">
  <div *ngIf="prependIcon"
       pplPopover
       [pplPopoverTooltip]="prependText ? prependText : null"
       class="prepend-icon">
    <ppl-icon [svgIcon]="prependIcon"
              height="15px"
              width="15px"></ppl-icon>
  </div>
  <input [attr.readonly]="readonly ? 'true' : null"
         [disabled]="disabled"
         #valueInput
         class="number-input"
         pplInput
         [pplInputAutofill]="autoFill"
         [attr.min]="min"
         [attr.max]="max"
         [style.height]="height"
         [style.lineHeight]="height"
         [placeholder]="placeholder"
         autocomplete="off"
         [style.padding-right.px]="Math.max((clear ? 14 : 0) + (controls ? 20 : 0), 6)"
         (keydown)="onKeyDown($event)"
         (focus)="focus.emit()"
         (blur)="blur.emit()">
  <div *ngIf="clear || controls"
       class="misc"
       #misc>
    <div *ngIf="clear"
         class="clear"
         (click)="clearClick.emit()">
      <ppl-icon [svgIcon]="clearIcon || 'closering-red-white'"
                width="14px"
                height="14px"></ppl-icon>
    </div>
    <div *ngIf="controls && !disabled && !readonly"
         class="controls">
      <div class="number number-up"
           #up
           (click)="onBumpValue(1)">
        <ppl-icon width="6px"
                  height="4px"
                  [actionElement]="up"
                  [hoverEnabled]="!disabled && !readonly"
                  svgIcon="rowfull-up-darkblue"></ppl-icon>
      </div>
      <div class="number number-down"
           #down
           (click)="onBumpValue(-1)">
        <ppl-icon width="6px"
                  height="4px"
                  [actionElement]="down"
                  [hoverEnabled]="!disabled && !readonly"
                  svgIcon="rowfull-down-darkblue"></ppl-icon>
      </div>
    </div>
  </div>
</div>
<div *ngIf="unit"
     class="unit"
     [attr.title]="unit">
  {{unit}}
</div>
