import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '../icon';
import { PplMenuModule } from '../menu';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplPopoverModule } from '../popover';
import { PplTabComponent, PplTabsComponent } from './tabs.component';
import { RouterModule } from '@angular/router';

const components = [
  PplTabsComponent,
  PplTabComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PplIconModule,
    PplPopoverModule,
    PplPopoverDropdownModule,
    PplMenuModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class PplTabsModule {
}

export * from './tabs.component';
