<div class="option-container"
     title="{{ option.label }}">
  <ppl-checkbox [value]="checked"
                [disabled]="disabled"
                [tabIndex]="null"
                (click)="$event.preventDefault()"></ppl-checkbox>
  <ng-container *ngIf="optionTemplate; else defaultOptionTemplate">
    <div class="option-template-wrapper">
      <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #defaultOptionTemplate>
  <div class="option-label">{{ option.label }}</div>
</ng-template>
