import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-tabs-demo',
  templateUrl: './tabs-demo.component.html',
  styleUrls: ['./tabs-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsDemoComponent implements OnInit {

  filterActive: boolean;

  code = `
  <ppl-tabs>
    <ppl-tab label="View"
             icon="view">
      View content
     </ppl-tab>
     <ppl-tab label="Filter"
              icon="color-filter"
              [tabSwitch]="filterActive">
        Filter content
      </ppl-tab>
    <ppl-tabs>
  `;

  constructor() {
  }

  ngOnInit() {
  }

}
