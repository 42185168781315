import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-slide-toggle-demo',
  templateUrl: './slide-toggle-demo.component.html',
  styleUrls: ['./slide-toggle-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideToggleDemoComponent implements OnInit {

  toggled: boolean;

  code = `
  <ppl-slide-toggle label="Label"
                    [value]="true"
                    [disabled]="false"
                    (valueChange)="toggled = $event"></ppl-slide-toggle>
`;

  constructor() {
  }

  ngOnInit() {
  }
}
