<div class="container demo-content">
  <h2>Items Chooser</h2>

  <ppl-input-container label="Basic">
    <ppl-items-chooser [value]="['2', '6']"
                       [options]="options"></ppl-items-chooser>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-items-chooser [value]="['2']"
                       [options]="options"
                       [disabled]="true"></ppl-items-chooser>
  </ppl-input-container>

  <ppl-input-container label="With Errors">
    <ppl-items-chooser [value]="['error-selected-1', '9', '11', 'error-selected-2']"
                       [options]="errorOptions"></ppl-items-chooser>
  </ppl-input-container>

  <div>
    <ppl-input-container label="Usage with forms"
                         [formGroup]="form"
                         [pplFormControl]="form.get('items')">
      <ppl-items-chooser formControlName="items"
                         [options]="options"></ppl-items-chooser>
    </ppl-input-container>
    <button pplButton
            pplButton1
            (click)="onSubmitForm()">Submit</button>
  </div>
</div>