import { PplDatePickerIntl } from './date-picker-intl';
import { PplButtonComponent } from '../button';
import { PplUiIntl } from '../ppl-ui-intl';
import { PplSelectComponent } from '../select';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import { clamp } from '@ppl/utils';
import IMask from 'imask/esm/imask';
import type {
  OnDestroy,
  OnInit
} from '@angular/core';
import type { PplSelectOption } from '../select';

@Component({
  selector: 'ppl-date-picker',
  templateUrl: 'date-picker.component.html',
  styleUrls: ['date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDatePickerComponent implements OnInit, OnDestroy {

  @Input() displayTime = false;
  @Input() displayPlanner = false;
  @Input() displayRemove = true;
  @Input() displayToday = true;
  @Input() firstWeekDay?: number;
  @Input() format?: string;
  @Input() locale?: string;
  @Input() minViewMode = 'days';
  @Input() value: Date | null;
  @Input() fullWidthButton = false;
  @Input() defaultValue?: Date;
  @Input() highlightDate?: Date;

  @Output() valueChange = new EventEmitter<Date | null>();
  @Output() todayClick = new EventEmitter<null>();
  @Output() plannerClick = new EventEmitter<Date>();
  @Output() popoverClose = new EventEmitter<null>();

  @ViewChild('pickerDate', { static: false }) pickerDate: ElementRef;
  @ViewChild('hoursInput', { static: false }) hoursInput: ElementRef;
  @ViewChild('minutesInput', { static: false }) minutesInput: ElementRef;
  @ViewChild('submitButton', { static: false }) submitButton: PplButtonComponent;
  @ViewChild('periodSelect', { static: false }) periodSelect: PplSelectComponent;
  @ViewChild('icons', { static: true }) icons: ElementRef;

  hoursValue = '';
  minutesValue = '';
  selectedTimePeriod = '';

  pickerDateElement: any;
  maskedHoursController: any;
  maskedMinutesController: any;

  timePeriods: PplSelectOption[] = [{ value: 'am', label: 'AM' }, { value: 'pm', label: 'PM' }];

  initControlsFrame: number;

  appointmentPlanner = 'Appointment Planner';
  ok = 'OK';
  today = 'Today';
  remove = 'Remove';

  get displayFormat() {
    if (this.format) {
      return this.format;
    } else {
      if (this.displayTime) {
        return this.datePickerIntl.dateTimeFormat || 'M/d/yy hh:mm';
      } else {
        return this.datePickerIntl.dateFormat || 'M/d/yy';
      }
    }
  }

  get is24HourSystem() {
    return !this.displayFormat.toLowerCase().endsWith('a');
  }

  constructor(
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private intl: PplUiIntl,
    private datePickerIntl: PplDatePickerIntl
  ) { }

  ngOnInit() {
    this.appointmentPlanner = this.intl.appointmentPlanner;
    this.ok = this.intl.ok;
    this.today = this.intl.today;
    this.remove = this.intl.remove;

    // requestAnimationFrame due to usage in popover (ppl-date-input)
    this.initControlsFrame = requestAnimationFrame(() => {
      this.initControls();
    });
  }

  ngOnDestroy() {
    cancelAnimationFrame(this.initControlsFrame);

    if (this.pickerDateElement) {
      this.pickerDateElement.datepicker('destroy');
    }

    this.maskedHoursController?.destroy();
    this.maskedMinutesController?.destroy();
  }

  createHighlightDateFn() {
    return (date: Date) => {
      if (this.highlightDate) {
        return this.highlightDate.getFullYear() === date.getFullYear()
          && this.highlightDate.getMonth() === date.getMonth()
          && this.highlightDate.getDate() === date.getDate()
          ? { classes: 'today' }
          : undefined;
      } else {
        return undefined;
      }
    };
  }

  initControls() {
    this.pickerDateElement = jQuery(this.pickerDate.nativeElement);

    const language = this.locale || this.datePickerIntl.bootstrapDatepickerLocale;
    const weekStart = this.firstWeekDay !== undefined ? this.firstWeekDay : this.datePickerIntl.firstWeekDay;

    this.pickerDateElement
      .datepicker({
        minViewMode: this.minViewMode,
        templates: {
          leftArrow: '<span class="ppl2-arrow ppl2-arrow-left"></span>',
          rightArrow: '<span class="ppl2-arrow ppl2-arrow-right"></span>'
        },
        todayHighlight: !!!this.highlightDate,
        ...this.highlightDate
          ? { beforeShowDay: this.createHighlightDateFn() }
          : {},
        weekStart,
        language,
        locale: language
      })
      .on('changeDate', event => {
        this.ngZone.run(() => {
          if (!this.displayTime) {
            this.valueChange.emit(event.date);
            this.popoverClose.emit();
          } else {
            this.focusAndSelect(this.hoursInput.nativeElement);
          }
        });
      });

    Array.from<Element>(this.pickerDate.nativeElement.querySelectorAll('.ppl2-arrow-left')).forEach(elem => elem.innerHTML = this.icons.nativeElement.querySelector('.prev-icon').innerHTML);
    Array.from<Element>(this.pickerDate.nativeElement.querySelectorAll('.ppl2-arrow-right')).forEach(elem => elem.innerHTML = this.icons.nativeElement.querySelector('.next-icon').innerHTML);

    if (this.displayTime) {
      this.maskedHoursController = IMask(this.hoursInput.nativeElement, {
        mask: '00'
      });

      this.maskedMinutesController = IMask(this.minutesInput.nativeElement, {
        mask: '00'
      });
    }

    this.updatePopoverValue(this.value);
  }

  onPlannerClick() {
    this.plannerClick.emit(this.getPopoverValue());
  }

  onSubmitClick() {
    this.valueChange.emit(this.getPopoverValue());
  }

  onSubmitKeyUp(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.valueChange.emit(this.getPopoverValue());
      this.popoverClose.emit();
    }
  }

  onRemoveClick() {
    this.valueChange.emit(null);
  }

  onHoursKeyUp() {
    const prevLength = this.hoursValue.length;

    this.hoursValue = this.hoursInput.nativeElement.value;

    if (prevLength < 2 && this.hoursValue.length === 2) {
      this.focusAndSelect(this.minutesInput.nativeElement);
    }
  }

  onHoursBlur() {
    const currentValue = this.hoursInput.nativeElement.value;
    const parsedValue = parseInt(currentValue, 10);

    this.hoursValue = !isNaN(parsedValue) ? this.formatHours(this.clampHours(parsedValue)) : this.formatHours(new Date().getHours());
  }

  onMinutesKeyUp() {
    const prevLength = this.minutesValue.length;

    this.minutesValue = this.minutesInput.nativeElement.value;

    if (prevLength < 2 && this.minutesValue.length === 2) {
      if (this.is24HourSystem) {
        this.submitButton.getHostElement().focus();
      } else {
        this.periodSelect.focus();
      }
    }
  }

  onMinutesBlur() {
    const currentValue = this.minutesInput.nativeElement.value;
    const parsedValue = parseInt(currentValue, 10);

    this.minutesValue = !isNaN(parsedValue) ? this.formatMinutes(this.clampMinutes(parsedValue)) : this.formatMinutes(new Date().getMinutes());
  }

  onTimePeriodChange(timePeriod: string) {
    this.selectedTimePeriod = timePeriod;
  }

  private getPopoverValue() {
    const value = new Date(this.pickerDateElement.datepicker('getDate').getTime());

    if (this.displayTime) {
      const parsedHours = parseInt(this.hoursValue, 10);
      const parsedMinutes = parseInt(this.minutesValue, 10);

      if (!isNaN(parsedHours) && !isNaN(parsedMinutes)) {
        if (this.is24HourSystem) {
          value.setHours(this.clampHours(parsedHours));
        } else {
          let hours = parsedHours;

          if (this.selectedTimePeriod === 'pm' && hours < 12) {
            hours += 12;
          } else if (this.selectedTimePeriod === 'am' && hours === 12) {
            hours = 0;
          }

          value.setHours(hours);
        }

        value.setMinutes(this.clampMinutes(parsedMinutes));
      }
    }

    return value;
  }

  private updatePopoverValue(value: Date | null) {
    const sanitizedValue = value instanceof Date
      ? value
      : this.defaultValue || new Date();

    this.pickerDateElement.datepicker('update', sanitizedValue);

    if (this.displayTime) {
      if (this.is24HourSystem) {
        this.hoursValue = this.formatHours(sanitizedValue.getHours());
      } else {
        const hours = sanitizedValue.getHours();

        this.selectedTimePeriod = hours >= 12 ? 'pm' : 'am';
        this.hoursValue = this.formatHours(hours);
      }

      this.minutesValue = this.formatMinutes(sanitizedValue.getMinutes());
      this.changeDetector.detectChanges();
    }
  }

  private formatHours(value: number) {
    if (this.is24HourSystem) {
      return value.toString();
    } else {
      const hours = value % 12;

      return hours === 0 ? '12' : hours.toString();
    }
  }

  private formatMinutes(value: number) {
    return value.toString().padStart(2, '0');
  }

  private clampHours(value: number) {
    if (this.is24HourSystem) {
      return clamp(value, 0, 23);
    } else {
      return clamp(value, 1, 12);
    }
  }

  private clampMinutes(value: number) {
    return clamp(value, 0, 59);
  }

  private focusAndSelect(node: HTMLInputElement) {
    node.focus();
    node.select();
  }

}
