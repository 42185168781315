import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, Route } from '@angular/router';
import { of } from 'rxjs';
import { jwtDecode } from '../../utils/jwt.utils';

declare let Office: any;

@Injectable()
export class OfficeTokenServiceGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot) {
    if (route.queryParams.token) {
      const result = jwtDecode(route.queryParams.token);

      if (result && result.msg === 'LoginSuccessNotification') {
        Office.context.ui.messageParent(JSON.stringify({
          appSessionId: result.app_session_id,
          token: result.ppl_token,
          spaceId: result.team_space_id
        }));
      } else {
        console.error('Token error');
      }
    } else {
      Office.context.ui.messageParent('');
    }

    return of(false);
  }

}

export const OfficeTokenRoute: Route = {
  path: 'sso-prod',
  canActivate: [OfficeTokenServiceGuard],
  component: class {}
};
