import { getTimezoneOffsetInUserTimezoneExtractor, getUserSettingsLocale } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { DatePipe } from '@angular/common';
import { Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';
import type { TimezoneOffsetExtractorFn } from '@ppl/utils';
import type { Subscription } from 'rxjs';

@Pipe({
  name: 'pplDateTimeRange'
})
export class PplDateTimeRangePipe implements PipeTransform {
  locale: string;
  localeSubscription: Subscription;
  getTimezoneOffsetInUserTimezone: TimezoneOffsetExtractorFn = null;
  getTimezoneOffsetInUserTimezoneSubscription: Subscription;

  constructor(
    private datePipe: DatePipe,
    private store: AuthStore
  ) {
    this.localeSubscription = this.store.select(getUserSettingsLocale(true)).subscribe(locale => this.locale = locale);
    this.getTimezoneOffsetInUserTimezoneSubscription = this.store.select(getTimezoneOffsetInUserTimezoneExtractor).subscribe(
      extractor => {
        this.getTimezoneOffsetInUserTimezone = extractor;
      }
    );
  }

  transform(isoDateTimeStart: string, isoDateTimeEnd: string) {
    if (!isoDateTimeStart || !isoDateTimeEnd) {
      return '';
    }

    const tmpDateTimeStart = new Date(isoDateTimeStart);
    const tmpDateTimeEnd = new Date(isoDateTimeEnd);
    const tmpDateTimeStartTimezone = this.getTimezoneOffsetInUserTimezone(tmpDateTimeStart);
    const tmpDateTimeEndTimezone = this.getTimezoneOffsetInUserTimezone(tmpDateTimeEnd);

    const dateStart = this.datePipe.transform(tmpDateTimeStart.getTime(), 'mediumDate', tmpDateTimeStartTimezone, this.locale);
    const dateTimeStart = this.removeSeconds(this.datePipe.transform(tmpDateTimeStart.getTime(), 'medium', tmpDateTimeStartTimezone, this.locale));
    const dateEnd = this.datePipe.transform(tmpDateTimeEnd.getTime(), 'mediumDate', tmpDateTimeEndTimezone, this.locale);

    if (dateStart === dateEnd) {
      const timeEnd = this.removeSeconds(this.datePipe.transform(tmpDateTimeEnd.getTime(), 'mediumTime', tmpDateTimeEndTimezone, this.locale));

      return `${dateTimeStart} – ${timeEnd}`;
    } else {
      const dateTimeEnd = this.removeSeconds(this.datePipe.transform(tmpDateTimeEnd.getTime(), 'medium', tmpDateTimeEndTimezone, this.locale));

      return `${dateTimeStart} – ${dateTimeEnd}`;
    }
  }

  private removeSeconds(input: string | null) {
    if (input) {
      return input.replace(/(:\d+)(:\d+)/, '$1');
    }

    return null;
  }

}
