import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
,
  ChangeDetectorRef} from '@angular/core';


@Component({
  selector: 'ppl-graph-toggle',
  templateUrl: './graph-toggle.component.html',
  styleUrls: ['./graph-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphToggleComponent implements OnInit {

  @Input() @HostBinding('class.checked') value: boolean;
  @Input() @HostBinding('class.disabled') disabled = false;

  @Output() valueChange = new EventEmitter<boolean>();

  constructor(
    private changeDetectionRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  @HostListener('click')
  onHostClick() {
    this.toggle();
  }

  toggle() {
    if (!this.disabled) {
      this.value = !this.value;
      this.valueChange.emit(this.value);
      this.changeDetectionRef.detectChanges();
      this.changeDetectionRef.markForCheck();
    }
  }

}

