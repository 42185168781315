import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownComponent } from './popover-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        PplPopoverModule
    ],
    declarations: [
        PplPopoverDropdownComponent
    ],
    exports: [
        PplPopoverDropdownComponent
    ]
})
export class PplPopoverDropdownModule {
}

export * from './popover-dropdown.component';
