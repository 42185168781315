<ng-template *ngIf="contentTemplate"
             [ngTemplateOutlet]="contentTemplate"
             [ngTemplateOutletContext]="{
  data: data,
  popover: popoverControl,
  position: {
    alignStart: computedAlignStart,
    alignEnd: computedAlignEnd,
    direction: computedDirection,
    initDirection: direction
  }
}"></ng-template>

<ng-container *ngIf="pplPopoverComponent">
  <ng-container #tooltip></ng-container>
</ng-container>

