import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplPopoverDirective } from './popover.directive';
import { PplPopoverCloseDirective } from './popover-close/popover-close.directive';
import { PplPopoverContentComponent } from './popover-content/popover-content.component';
import { PplPopoverDelayCloseDirective } from './popover-delay-close/popover-delay-close.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PplPopoverDirective,
        PplPopoverCloseDirective,
        PplPopoverDelayCloseDirective,
        PplPopoverContentComponent
    ],
    exports: [
        PplPopoverDirective,
        PplPopoverCloseDirective,
        PplPopoverDelayCloseDirective,
        PplPopoverContentComponent
    ]
})
export class PplPopoverModule {
}

export * from './popover.directive';
export * from './popover-close/popover-close.directive';
export * from './popover-content/popover-content.component';
