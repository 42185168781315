import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ppl-date-presets',
  templateUrl: './date-presets.component.html',
  styleUrls: ['./date-presets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDatePresetsComponent {
  @Input() presets: PplDatePreset[];
  @Input() value: string;

  @Output() valueChange = new EventEmitter<string>();
}

export interface PplDatePreset {
  label: string;
  date: string;
  tooltip: string;
}
