import type { AfterViewInit, OnChanges, SimpleChanges  } from '@angular/core';
import { Directive, Input , ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pplInputAutofill]'
})
export class PplInputAutofillDirective implements OnChanges, AfterViewInit {
  @Input() pplInputAutofill = true;

  private className = 'ppl-input--autofill';

  private targetElement;

  constructor(private renderer: Renderer2, private elementRef: ElementRef<Element>) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.pplInputAutofill && this.targetElement) {
      this.toggleClass();
    }
  }

  ngAfterViewInit(): void {
    const childInputElement = this.elementRef.nativeElement.querySelector('input');
    this.targetElement = childInputElement ? childInputElement : this.elementRef.nativeElement;
    this.toggleClass();
  }

  toggleClass() {
    if (this.pplInputAutofill) {
      this.renderer.addClass(this.targetElement, this.className);
    } else {
      this.renderer.removeClass(this.targetElement, this.className);
    }
  }

}
