<div class="line-left"></div>
<div class="label">
  <ppl-icon *ngIf="icon"
            [svgIcon]="icon"
            width="16px"
            height="16px"></ppl-icon>
  <div *ngIf="label">
    {{label}}
  </div>
  <div *ngIf="labelTemplate">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  </div>
  <ppl-information-icon *ngIf="infoMessage"
                        [message]="infoMessage"
                        class="info-icon"></ppl-information-icon>
</div>
<div class="line-right"></div>
<div *ngIf="actionTemplate"
     class="action">
  <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
</div>
