import type { AvailablePhoneNumberFilter, PurchasePhoneInput } from '../../domain/phone-number';
import type { CallingPhoneEditFormData } from '../calling-phone-edit/calling-phone-edit.component';
import type { CallingPhoneGridRecord } from '../calling-phone-grid/calling-phone-grid.component';
import { CallingPhoneGridColumn } from '../calling-phone-grid/calling-phone-grid.component';
import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject
} from '@angular/core';
import type { ValidationErrors } from '@angular/forms';
import type { AuthUser} from '@ppl/auth';
import { ErrorCode } from '@ppl/auth';
import type { CommonGridColumn } from '@ppl/components/grid';
import { formatInputPhoneNumber } from '@ppl/components/input-phone-number';
import type { Step } from '@ppl/components/steps';
import { countries } from '@ppl/domain';
import { I18nService } from '@ppl/i18n';
import { PplDialogRef } from '@ppl/ui/dialog';
import { PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type {  TAbstractControl, TFormGroup  } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type { GridSelection } from '@ppl/ui/grid';
import type { PplSelectOption } from '@ppl/ui/select';
import {
  createDialogCloseResponse,
  createDialogSuccessResponse,
  getGraphQLErrors,
  Unsubscribe
} from '@ppl/utils';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ppl-calling-purchase-wizard-dialog',
  templateUrl: './calling-purchase-wizard-dialog.component.html',
  styleUrls: ['./calling-purchase-wizard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class CallingPurchaseWizardDialogComponent implements OnInit {

  step: CallingPurchaseWizardStepsEnum = CallingPurchaseWizardStepsEnum.ChooseNumber;
  steps: Step[];
  activeStepIndex = 0;

  loading$ = new BehaviorSubject<boolean>(false);

  // STEP 1. Choose Number
  chooseNumberForm: TFormGroup<CallingPurchaseWizardDialogChooseNumberFormData>;
  countriesOptions: PplSelectOption[] = countries.map(countryCode => ({
    value: countryCode,
    label: `${this.i18nService.translate(`Country_${countryCode}`)} - ${countryCode}`
  }));
  phoneColumns: CommonGridColumn[] = [
    { id: CallingPhoneGridColumn.PhoneNumber, name: this.i18nService.translate('Phone Number') },
    { id: CallingPhoneGridColumn.Capability, name: this.i18nService.translate('Capability') },
    { id: CallingPhoneGridColumn.EstimatedPrice, name: this.i18nService.translate('Estimated Price') },
  ];
  phoneData: CallingPhoneGridRecord[];
  gridSelection$ = new BehaviorSubject<GridSelection>(GRID_SELECTION_DEFAULT_VALUE);
  gridData$ = new BehaviorSubject<CallingPhoneGridRecord[]>(null);

  chooseNumberFormCountrySubscription: Subscription;
  chooseNumberFormContainsSubscription: Subscription;

  // STEP 2. Configure;
  form: TFormGroup<CallingPurchaseWizardDialogFormData>;
  selectedPhone: string;

  // STEP 3. Purchase & Finish
  purchaseScreenType: CallingPurchaseWizardPurchaseScreenTypeEnum = CallingPurchaseWizardPurchaseScreenTypeEnum.Success;
  purchaseScreenErrorMessage: string;

  CallingPurchaseWizardStepsEnum = CallingPurchaseWizardStepsEnum;
  CallingPurchaseWizardPurchaseScreenTypeEnum = CallingPurchaseWizardPurchaseScreenTypeEnum;

  private purchasedPhone: any;

  get chooseNumberFormPhonePatternControl() {
    return this.chooseNumberForm.get('phonePattern');
  }

  get isConfigurationEnabled() {
    return this.data.configurationEnabled;
  }

  get showNextButton() {
    return this.isConfigurationEnabled && this.step === CallingPurchaseWizardStepsEnum.ChooseNumber;
  }

  get nextButtonDisabled() {
    return !this.gridSelection$.getValue().include.length;
  }

  get showBackButton() {
    return this.step === CallingPurchaseWizardStepsEnum.Configure;
  }

  get showPurchaseButton() {
    return this.isConfigurationEnabled ? this.step === CallingPurchaseWizardStepsEnum.Configure : this.step === CallingPurchaseWizardStepsEnum.ChooseNumber;
  }

  get purchaseButtonDisabled() {
    return this.isConfigurationEnabled ? !this.form.valid : !this.selectedPhoneId;
  }

  get selectedPhoneId() {
    return this.gridSelection$.getValue().include[0];
  }

  get cancelLabel() {
    return this.step === CallingPurchaseWizardStepsEnum.PurchaseAndFinish ? 'close' : 'cancel';
  }

  constructor(
    @Inject(PPL_DIALOG_DATA) private data: CallingPurchaseWizardDialogData,
    private dialogRef: PplDialogRef,
    private i18nService: I18nService,
    private formBuilder: TFormBuilder
  ) { }

  ngOnInit(): void {
    this.setSteps();
    this.initChooseNumberForm();
    this.initConfigureForm();
  }

  onBack() {
    this.step = CallingPurchaseWizardStepsEnum.ChooseNumber;
    this.activeStepIndex = 0;
  }

  onNext() {
    this.resetChooseNumberContainsValue();
    const selectedPhoneId = this.selectedPhoneId;
    this.selectedPhone = this.gridData$.getValue().find(row => row.id === selectedPhoneId).PhoneNumber;
    this.step = CallingPurchaseWizardStepsEnum.Configure;
    this.onNextStep();
  }

  onPurchase() {
    this.loading$.next(true);
    this.onNextStep();
    const phoneConfig = this.form.getRawValue().phoneConfig;
    const purchaseInput: PurchasePhoneInput = {
      name: phoneConfig.name,
      forwardMessagesToEmail: phoneConfig.messageForwardingEmail,
      forwardCallsToPhone: formatInputPhoneNumber(phoneConfig.callForwardingPhone),
      phoneNumber: this.selectedPhoneId
    };
    this.step = CallingPurchaseWizardStepsEnum.PurchaseAndFinish;
    this.data.purchasePhone(purchaseInput).subscribe({
      next: (purchasedPhone) => {
        this.purchaseScreenType = CallingPurchaseWizardPurchaseScreenTypeEnum.Success;
        this.purchasedPhone = purchasedPhone;
        this.loading$.next(false);
      },
      error: (error) => {
        const gqlErrors = getGraphQLErrors(error) as any[];
        const apiErrorCode = gqlErrors[0]?.api_error?.code;

        switch (apiErrorCode) {
          case ErrorCode.ERROR_3RD_PARTY_API_UNSUPPORTED:
            this.purchaseScreenErrorMessage = this.i18nService.translate('Phone_numbers_from_selected_country_require_regulatory_information._These_numbers_can\'t_be_purchased.');
            break;
          case ErrorCode.ERROR_3RD_PARTY_API_UNAVAILABLE:
            this.purchaseScreenErrorMessage = this.i18nService.translate('Number_no_longer_available');
            break;
          default:
            this.purchaseScreenErrorMessage = this.i18nService.translate('We_couldn\'t_make_a_purchase_of_your_phone_number._Please_contact_support');
            break;
        }
        this.purchaseScreenType = CallingPurchaseWizardPurchaseScreenTypeEnum.Error;
        this.loading$.next(false);
      }
    });
  }

  onClose() {
    if (this.purchasedPhone) {
      this.dialogRef.close(createDialogSuccessResponse({
        phoneNumber: this.purchasedPhone
      }));
    } else {
      this.dialogRef.close(createDialogCloseResponse());
    }
  }

  onGridSelectionChange(selection: GridSelection) {
    this.gridSelection$.next(selection);
  }

  onSearchPhone() {
    if (this.chooseNumberForm.valid) {
      const { countryCode, phonePattern } = this.chooseNumberForm.value;
      this.search({ countryCode, text: phonePattern });
    }
  }

  private search(filter: AvailablePhoneNumberFilter) {
    this.gridSelection$.next(GRID_SELECTION_DEFAULT_VALUE);
    this.loading$.next(true);
    this.data.getAvailablePhoneNumbers(filter).subscribe({
      next: (data) => {
        this.phoneData = data;
        this.gridData$.next(data);
        this.loading$.next(false);
      },
      error: () => {
        this.loading$.next(false);
      }
    });
  }

  private getCountryForUser() {
    const userLocale = this.data.currentUser.settings.locale;
    const userCountry = userLocale.split('-').pop();
    return userCountry;
  }

  private initChooseNumberForm() {
    this.chooseNumberForm = this.formBuilder.group<CallingPurchaseWizardDialogChooseNumberFormData>({
      countryCode: [this.getCountryForUser()],
      phonePattern: ['', phonePatternValidator(this.i18nService)]
    });

    this.chooseNumberFormCountrySubscription = this.chooseNumberForm.get('countryCode').valueChanges.subscribe(() => {
      this.resetChooseNumberContainsValue();
    });

    this.chooseNumberFormContainsSubscription = this.chooseNumberFormPhonePatternControl.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.onSearchPhone();
    });
  }

  private resetChooseNumberContainsValue() {
    this.chooseNumberFormPhonePatternControl.setValue('', { emitEvent: false });
  }

  private initConfigureForm() {
    const user = this.data.currentUser;
    const name = `${user.profile.firstName} ${user.profile.lastName} - VOIP`;
    this.form = this.formBuilder.group<CallingPurchaseWizardDialogFormData>({
      phoneConfig: [{
        name,
        messageForwardingEmail: user.profile.email,
        callForwardingPhone: null
      }]
    });
  }

  private setSteps() {
    const configurationStep = {
      name: this.i18nService.translate('Configure')
    };

    const steps = [
      { name: this.i18nService.translate('Choose_Number') },
      { name: this.i18nService.translate('Purchase_&_Finish') },
    ];

    if (this.isConfigurationEnabled) {
      steps.splice(1, 0, configurationStep);
    }

    this.steps = steps;
  }

  private onNextStep() {
    this.activeStepIndex = (this.activeStepIndex + 1) % this.steps.length;
  }
}

enum CallingPurchaseWizardStepsEnum {
  ChooseNumber,
  Configure,
  PurchaseAndFinish
}

enum CallingPurchaseWizardPurchaseScreenTypeEnum {
  Success = 'Success',
  Error = 'Error'
}

export interface CallingPurchaseWizardDialogData {
  configurationEnabled: boolean;
  currentUser: AuthUser;
  purchasePhone(input: PurchasePhoneInput): Observable<any>;
  getAvailablePhoneNumbers(filter: AvailablePhoneNumberFilter): Observable<any>;
}

export interface CallingPurchaseWizardDialogResponse<T> {
  phoneNumber: T;
}

const GRID_SELECTION_DEFAULT_VALUE: GridSelection = {
  all: false,
  include: [],
  exclude: []
};

interface CallingPurchaseWizardDialogFormData {
  phoneConfig: CallingPhoneEditFormData;
}

interface CallingPurchaseWizardDialogChooseNumberFormData {
  countryCode: string;
  phonePattern: string;
}

function phonePatternValidator(i18nService: I18nService) {
  return (control: TAbstractControl<string>): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (control.value.length < 2) {
      return {
        custom: i18nService.translate('You_must_specify_at_least_two_characters.')
      };
    }

    return /^[\*0-9A-Za-z]+$/.test(control.value)
      ? null
      : { custom: i18nService.translate('Invalid_pattern._Valid_characters_are_*,_0-9,_a-z,_and_A-Z.') };
  };
}
