import { sanitizeHTML } from './sanitization.utils';

export function normalizeText(value: string, type: NormalizeTextType, options: NormalizeTextToPlainOptions & NormalizeTextToRichOptions = {}) {
  if (type === NormalizeTextType.Plain) {
    return normalizeTextToPlain(value, options);
  } else if (type === NormalizeTextType.Rich) {
    return normalizeTextToRich(value, options);
  }
}

export function normalizeTextToPlain(value: string, options: NormalizeTextToPlainOptions = {}) {
  if (!value) {
    return '';
  }

  tmpElem.innerHTML = sanitizeHTML(value);

  function walkValueTree(nodes: NodeListOf<ChildNode>) {
    const result = [];

    nodes.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const nodeName = node.nodeName.toLowerCase();

        if (BlockElements.includes(nodeName)) {
          if (node.childNodes.length) {
            result.push(walkValueTree(node.childNodes));
          }
        } else if (nodeName === 'br' && node.nextSibling) {
          result.push('\n');
        } else if (InlineTextElements.includes(nodeName)) {
          result.push(node.textContent);

          if ((nodeName === 'td' || nodeName === 'th') && node.nextSibling) {
            result.push(' ');
          }
        } else if (options.preserveMarkers && nodeName === MarkerElement) {
          result.push(cleanMarkerElement(node as Element).outerHTML);
        } else if (options.preserveImages && nodeName === 'img') {
          result.push('▨');
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        result.push(node.textContent);
      }
    });

    return result;
  }

  function walkBlockTree(nodes: any[]) {
    let result = '';

    nodes.forEach((node, index) => {
      const prevNode = nodes[index - 1];

      if (prevNode && (Array.isArray(prevNode) || Array.isArray(node))) {
        result += '\n';
      }

      if (Array.isArray(node)) {
        result += walkBlockTree(node);
      } else {
        result += node;
      }
    });

    return result;
  }

  const blockTree = walkValueTree(tmpElem.childNodes);

  return walkBlockTree(blockTree);
}

export function recognizeLinks(text: string) {
  return text.replace(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi, (match, space, url) => {
    let normalizedUrl = url;

    if (!normalizedUrl.match('^https?://')) {
      normalizedUrl = 'http://' + normalizedUrl;
    }

    return space + `<a href="${normalizedUrl}" target="_blank">${url}</a>`;
  });
}

export function normalizeTextToRich(value: string, options: NormalizeTextToRichOptions = {}) {
  if (!value) {
    return '<p><br></p>';
  }

  tmpElem.innerHTML = sanitizeHTML(value);


  function getParagraph(textContent: string) {
    const parElem = document.createElement('p');

    if (textContent) {
      parElem.innerHTML = textContent;
    } else {
      parElem.innerHTML = '<br>';
    }

    return parElem;
  }

  const resultNodes: Node[] = [];

  let tmpNodeTextContent = '';

  tmpElem.querySelectorAll(MarkerElement).forEach(markerElem => {
    const nextMarkerElem = cleanMarkerElement(markerElem);

    markerElem.parentNode.replaceChild(nextMarkerElem, markerElem);
  });

  tmpElem.childNodes.forEach(node => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const nodeName = node.nodeName.toLowerCase();

      if (nodeName === MarkerElement) {
        tmpNodeTextContent += (node as HTMLElement).outerHTML;
      } else {
        resultNodes.push(node);
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      const textLines = node.textContent.split('\n');

      textLines.forEach((textLine, index) => {
        tmpNodeTextContent += options.recognizeLinks ? sanitizeHTML(recognizeLinks(textLine)) : textLine;

        if (index !== textLines.length - 1) {
          resultNodes.push(getParagraph(tmpNodeTextContent));

          tmpNodeTextContent = '';
        }
      });
    }
  });

  if (tmpNodeTextContent) {
    resultNodes.push(getParagraph(tmpNodeTextContent));
  }

  return resultNodes.map(node => (node as HTMLElement).outerHTML).join('');
}

export function isTextEmpty(text: string) {
  return !text || text === '<p><br></p>';
}

function cleanMarkerElement(markerElem: Element) {
  const tmpMarkerElem = document.createElement(MarkerElement);

  MarkerElementAttributes.forEach(attr => {
    const attrValue = markerElem.getAttribute(attr);

    if (attrValue) {
      tmpMarkerElem.setAttribute(attr, attrValue);
    }
  });

  return tmpMarkerElem;
}

export enum NormalizeTextType {
  Plain = 'Plain',
  Rich = 'Rich'
}

export interface NormalizeTextToPlainOptions {
  preserveImages?: boolean;
  preserveMarkers?: boolean;
}

export interface NormalizeTextToRichOptions {
  recognizeLinks?: boolean;
}

const tmpElem = document.createElement('div');

// Source: https://github.com/apostrophecms/sanitize-html
const BlockElements = ['address', 'article', 'aside', 'blockquote', 'dd', 'div', 'dl', 'dt', 'figcaption',
  'figure', 'footer', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'li', 'main', 'nav',
  'ol', 'p', 'pre', 'section', 'table', 'tbody', 'thead', 'tfoot', 'tr', 'ul'];

const InlineTextElements = ['a', 'abbr', 'b', 'bdi', 'bdo', 'cite', 'code', 'data', 'dfn', 'em', 'i', 'kbd',
  'mark', 'q', 'rb', 'rp', 'rt', 'rtc', 'ruby', 's', 'samp', 'small', 'span', 'strong', 'sub', 'sup', 'td',
  'th', 'time', 'u', 'var', 'wbr'];

const MarkerElement = 'ppl-tag';

const MarkerElementAttributes = ['data-id', 'data-type', 'data-offset', 'data-offset-unit', 'data-offset-seconds', 'data-relation-type'];
