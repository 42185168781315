import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplAccountClassComponent } from './account-class.component';
import { PplIconModule } from '../icon';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplAccountClassComponent
  ],
  exports: [
    PplAccountClassComponent
  ]
})
export class PplAccountClassModule {
}

export * from './account-class.component';
export * from './account-class.constants';
