<span class="toggle-container"
      [attr.tabindex]="disabled ? null : 0"
      (focus)="onFocusChange()"
      (blur)="onFocusChange()">
  <span class="toggle-background">
    <ppl-icon svgIcon="checkmark-toggle"
              class="check"
              width="14px"
              height="14px"
              [hoverEnabled]="false"></ppl-icon>
    <ppl-icon svgIcon="closebold-toggle"
              class="cross"
              width="12px"
              height="12px"
              [hoverEnabled]="false"></ppl-icon>
  </span>
  <span class="toggle-thumb">
    <ppl-icon svgIcon="slider"
              width="26px"
              height="20px"
              [hoverEnabled]="false"></ppl-icon>
  </span>
</span>
<div *ngIf="ngUiTheme === 'fabric'"
     class="toggle-state-label">
  <ng-container *ngIf="value">On</ng-container>
  <ng-container *ngIf="!value">Off</ng-container>
</div>
<span *ngIf="label"
      class="toggle-label"
      [style.color]="labelColor"
      [attr.title]="label">
  {{label}}
</span>