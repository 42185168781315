import { PplUiIntl } from '../ppl-ui-intl';
import { PplVirtualScrollComponent } from '../virtual-scroll';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  TemplateRef} from '@angular/core';
import {
  KEYCODE_DOWN,
  KEYCODE_END,
  KEYCODE_ENTER,
  KEYCODE_HOME,
  KEYCODE_PAGE_DOWN,
  KEYCODE_PAGE_UP,
  KEYCODE_UP,
  MemoizeLast,
  notFirstChange
} from '@ppl/utils';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';

@Component({
  selector: 'ppl-option-list',
  templateUrl: './option-list.component.html',
  styleUrls: ['./option-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplOptionListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() options: PplOptionListOption[];
  @Input() value: string;
  @Input() createValue?: string;
  @Input() maxContainerHeight?: number;
  @Input() displayCategoriesSidebar?: boolean;
  @Input() categoriesSidebarWidth?: number;
  @Input() listWidth?: number;

  @Input() actionsTemplate?: TemplateRef<any>;
  @Input() actionsTemplateHeight?: number;
  @Input() categories?: PplOptionListCategory[] | null;
  @Input() optionTemplate: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() displayCreateOption?: string;
  @Input() displayLoading = false;
  @Input() displayNoResults = false;
  @Input() stopPropagation = true;

  @Output() valueChange = new EventEmitter<string>();
  @Output() optionSelect = new EventEmitter<PplOptionListOption>();
  @Output() optionCreate = new EventEmitter();
  @Output() listScrollEnd = new EventEmitter();

  @ViewChild('virtualScroll', { read: ElementRef, static: false }) virtualScrollContainer: ElementRef;
  @ViewChild('virtualScroll', { read: PplVirtualScrollComponent, static: false }) virtualScroll: PplVirtualScrollComponent;

  preventAutoscroll = false;
  prevActiveIndex = -1;

  renderHandle = 0;
  rowHeights: number[];
  containerHeight: number;

  selectedCategory: string = null;

  get showCategoriesSidebar() {
    return this.displayCategoriesSidebar && this.categories && this.categories.length > 1;
  }

  get displayCreate() {
    return this.displayCreateOption && this.createValue;
  }

  @MemoizeLast<PplOptionListComponent>(['options', 'displayLoading', 'selectedCategory'])
  get normalizedOptions(): (PplOptionListOption & { _meta?: { loading: boolean } })[] {
    const categoryOptions =
      this.selectedCategory === null
        ? this.options
        : this.options.filter(option => option.categoryId === this.selectedCategory);

    const loadingOptions =
      this.displayLoading
        ? [
          ...categoryOptions,
          <PplOptionListOption>{ value: null, label: null, _meta: { loading: true } }
        ]
        : categoryOptions;

    return loadingOptions;
  }

  @MemoizeLast<PplOptionListComponent>(['categories'])
  get normalizedSearchCategories(): PplOptionListCategory[] {
    return [
      {
        id: null,
        label: this.intl.all
      },
      ...(this.categories || [])
    ];
  }

  constructor(
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES,
    public intl: PplUiIntl,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.options) || notFirstChange(changes.value)) {
      this.scrollToItem();
    }

    this.recalculateRowHeights();
  }

  ngOnInit() {
    this.scrollToItem();
  }

  ngOnDestroy() {
    cancelAnimationFrame(this.renderHandle);
  }

  onOptionClick(option: PplOptionListOption) {
    this.optionSelect.emit({ ...option });
  }

  onOptionCreate() {
    this.optionCreate.emit();
  }

  onOptionMouseEnter(value: string) {
    // Prevent autoscroll on mouse events (when item is only partially visible)
    this.preventAutoscroll = true;
    this.valueChange.emit(value);
    requestAnimationFrame(() => this.preventAutoscroll = false);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (this.stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onScrollChange(scroll: number) {
    const container = this.virtualScroll.recordsContainer.nativeElement.parentNode;

    if (scroll === container.scrollHeight - container.clientHeight) {
      this.listScrollEnd.emit();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const activeIndex = this.normalizedOptions.findIndex(option => option.value === this.value);
    const keyCode = event.keyCode;

    if (activeIndex !== -1) {
      switch (keyCode) {
        case KEYCODE_UP:
          this.valueChange.emit(this.normalizedOptions[Math.max(activeIndex - 1, 0)].value);
          break;
        case KEYCODE_DOWN:
          this.valueChange.emit(this.normalizedOptions[Math.min(activeIndex + 1, this.normalizedOptions.length - 1)].value);
          break;
        case KEYCODE_PAGE_UP:
          this.valueChange.emit(this.normalizedOptions[Math.max(activeIndex - ITEMS_SKIP - 1, 0)].value);
          break;
        case KEYCODE_PAGE_DOWN:
          this.valueChange.emit(this.normalizedOptions[Math.min(activeIndex + ITEMS_SKIP + 1, this.normalizedOptions.length - 1)].value);
          break;
        case KEYCODE_HOME:
          this.valueChange.emit(this.normalizedOptions[0].value);
          break;
        case KEYCODE_END:
          this.valueChange.emit(this.normalizedOptions[this.normalizedOptions.length - 1].value);
          break;
        case KEYCODE_ENTER:
          // NOTE(mike): Added to prevent immediate submitting of form
          // when this component is inside form-group component and the form is valid
          event.preventDefault();
          const option = this.normalizedOptions[activeIndex];
          if (!option._meta?.loading) {
            this.optionSelect.emit(option);
          }
          break;
      }
    } else if (keyCode === KEYCODE_ENTER && !this.options.length && this.displayCreate) {
      // enter triggers create if no options are available and displayCreate is on
      event.preventDefault();
      this.optionCreate.emit();
    }
  }

  getCategoryLabel(id: string) {
    const category = this.categories.find(cat => cat.id === id);

    return category ? category.label : '';
  }

  trackByOptionValue(index: number, option: PplOptionListOption) {
    return option.value;
  }

  onSelectedCategoryChange($event: string) {
    this.selectedCategory = $event;

    const options = this.normalizedOptions;
    if (options.length) {
      this.valueChange.emit(options[0].value);
    }

    this.recalculateRowHeights();
  }

  /**
   * indicates whether option should also display category label above itself
   */
  isCategoryOption(option: PplOptionListOption, i: number) {
    return this.categories && ((i !== 0 && !!option.categoryId && option.categoryId !== this.normalizedOptions[i - 1].categoryId) || (i === 0 && !!option.categoryId));
  }

  private recalculateRowHeights() {
    const options = this.normalizedOptions;
    const rowHeight = this.optionTemplate ? this.optionTemplateRowHeight || BASIC_ROW_HEIGHT_PX : BASIC_ROW_HEIGHT_PX;

    this.rowHeights = options.map((option, i) =>
      option._meta?.loading
        ? ((i === 0) ? rowHeight : LOADING_HEIGHT_PX)
        : this.isCategoryOption(option, i)
          ? rowHeight + ((this.ngUiTheme === 'fabric') ? CATEGORY_ROW_HEIGHT_PX_FABRIC : CATEGORY_ROW_HEIGHT_PX)
          : rowHeight
    );
    let containerHeight = this.rowHeights.reduce((prev, next) => prev + next, 0);

    if (this.actionsTemplateHeight) {
      containerHeight += this.actionsTemplateHeight;
    }

    // stretch the "No results found" to full height
    if (!options.length && this.maxContainerHeight) {
      this.containerHeight = null;
    } else {
      this.containerHeight = Math.max(containerHeight, this.optionTemplateRowHeight || EMPTY_HEIGHT_PX);
    }
    this.changeDetectorRef.detectChanges();

    if (this.virtualScroll) {
      this.virtualScroll.forceUpdateLayout();
    }
  }

  private scrollToItem() {
    const activeIndex = this.normalizedOptions.findIndex(option => option.value === this.value);

    if (activeIndex !== this.prevActiveIndex) {
      this.prevActiveIndex = activeIndex;

      if (activeIndex !== -1 && !this.preventAutoscroll && this.virtualScroll && this.virtualScroll.recordsContainer) {
        const container = this.virtualScrollContainer.nativeElement as HTMLElement;
        const item = this.virtualScroll.getRecordRect(activeIndex);

        if (item) {
          const itemTop = item.top;
          const itemHeight = item.height;
          if (itemTop - ITEMS_BORDER < container.scrollTop) {
            container.scrollTop = itemTop - ITEMS_BORDER * 3;
          } else if (itemTop + itemHeight + ITEMS_BORDER > container.scrollTop + container.clientHeight) {
            container.scrollTop = itemTop - container.clientHeight + itemHeight - ITEMS_BORDER;
          }
        }
      }
    }
  }
}

const ITEMS_SKIP = 5;
const ITEMS_BORDER = 1;
const BASIC_ROW_HEIGHT_PX = 25;
const CATEGORY_ROW_HEIGHT_PX = 26;
const CATEGORY_ROW_HEIGHT_PX_FABRIC = 30;
const LOADING_HEIGHT_PX = 27;
const EMPTY_HEIGHT_PX = 25;

export interface PplOptionListCategory {
  id: string;
  label: string;
}

export interface PplOptionListOption<T = any> {
  icon?: string;
  label: string;
  value: string;
  categoryId?: string;
  data?: T;
}
