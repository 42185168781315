<div class="container demo-content">
  <h2>Carousel Slide Component</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-carousel-slide</h4>
  <div>@Input() <b>title</b>: string</div>
  <div>@Input() <b>subTitle</b>: string</div>
  <div>@Input() <b>icon</b>: string</div>
  <br>
  <br>

  <h4>Example</h4>
  <ppl-carousel>
    <ppl-carousel-slide title="Connect to your Twitter Account"
                        subTitle="and interact your prospects via Pipeliner."
                        icon="twitter">
      <button pplButton
              pplButton1
              pplButtonSmall>Activate</button>
    </ppl-carousel-slide>
    <ppl-carousel-slide title="Connect to your DropBox Account"
                        subTitle="and interact your prospects via Pipeliner."
                        icon="drop-box">
      <button pplButton
              pplButton1
              pplButtonSmall>Activate</button>
    </ppl-carousel-slide>
    <ppl-carousel-slide title="Connect to your One Drive Account"
                        subTitle="and interact your prospects via Pipeliner."
                        icon="one-drive">
      <button pplButton
              pplButton1
              pplButtonSmall>Activate</button>
    </ppl-carousel-slide>
  </ppl-carousel>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
