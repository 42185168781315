import { SelectorViewComponent } from './selector-view.component';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { PplPopoverModule } from '../popover';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplPopoverModule,
    PplLoadingModule
  ],
  declarations: [
    SelectorViewComponent
  ],
  exports: [
    SelectorViewComponent
  ]
})
export class PplSelectorViewModule { }

export * from './selector-view.component';

