<div class="container demo-content">
  <h2>NavBar</h2>
  <br>

  <h3>Components</h3>
  <h4>nav-bar</h4>

  <p>
    @Input() <b>value:</b> string;
  </p>
  <p>
    @Output() <b>valueChange</b> = new EventEmitter();
  </p>

  <h4>nav-bar-item</h4>
  <p>
    @Input() @HostBinding('class.selected') <b>selected:</b> boolean;
  </p>
  <p>
    @Input() @HostBinding('title') <b>title:</b> string;
  </p>
  <p>
    @Input() <b>value:</b> string;
  </p>


  <h3>Example</h3>

  <p>Selected value: {{selectedValue}}</p>

  <ppl-nav-bar [value]="selectedValue"
               (valueChange)="selectedValue = $event">
    <ppl-nav-bar-item value="1"
                      title="Item 1 Description">Item 1</ppl-nav-bar-item>
    <ppl-nav-bar-item value="2"
                      title="Item 2 Description">Item 2</ppl-nav-bar-item>
    <ppl-nav-bar-item value="3"
                      title="Item 3 Description">Item 3</ppl-nav-bar-item>
  </ppl-nav-bar>

  <br>
  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
