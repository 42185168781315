import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'ppl-tree-select-item',
  templateUrl: './tree-select-item.component.html',
  styleUrls: ['../tree-select-items/tree-select-items.component.scss', './tree-select-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectItemComponent implements OnInit {
  @Input() item: PplTreeSelectItem;
  @Input() openedByDefault = false;
  @Input() highlightCategories?: boolean;
  @Input() collapsible: boolean;
  @Input() searchValue: string;
  @Input() hideUnavailableLabels: boolean;

  @Output() toggle = new EventEmitter<PplTreeSelectItem>();

  isOpened: boolean;

  ngOnInit() {
    this.isOpened = this.openedByDefault;
  }

  get highlighted() {
    return this.highlightCategories ? this.item.children.length > 0 : false;
  }

  toggleOpened(event: MouseEvent) {
    event.preventDefault();
    this.isOpened = !this.isOpened;
  }

  trackTree(index, item: PplTreeSelectItem) {
    return item.id;
  }

  onItemClick() {
    this.toggle.emit(this.item);
  }

}

export interface PplTreeSelectItem {
  id: string;
  name: string;
  checked: boolean;
  parentId: string | null;
  disabled?: boolean;
  unavailable?: boolean;
  deleted?: boolean;
  children: PplTreeSelectItem[];
  avatarEntity?: 'account' | 'client' | 'contact' | 'custom' | 'hidden' | 'unit' | 'lead' | 'opportunity' | 'product' | 'project' | 'projectObjective' | 'application' | 'space';
  picture?: string;
}
