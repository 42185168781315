import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-nav-bar-demo',
  templateUrl: './nav-bar-demo.component.html',
  styleUrls: ['./nav-bar-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarDemoComponent implements OnInit {
  selectedValue = '1';

  code = `
<ppl-nav-bar [value]="selectedValue"
              (valueChange)="selectedValue = $event">
  <ppl-nav-bar-item value="1" title="Item 1 Description">Item 1</ppl-nav-bar-item>
  <ppl-nav-bar-item value="2" title="Item 2 Description">Item 2</ppl-nav-bar-item>
  <ppl-nav-bar-item value="3" title="Item 3 Description">Item 3</ppl-nav-bar-item>
</ppl-nav-bar>
  `;

  constructor() { }

  ngOnInit() {
  }
}
