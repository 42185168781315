import { unsubscribe } from './subscription.utils';
import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { concat, defer, of } from 'rxjs';
import type { ForwardRefFn } from '@angular/core';
import type {
  AbstractControl,
  FormControl
} from '@angular/forms';
import type { TAbstractControl, TFormGroup } from '@ppl/ui/form';
import type { Observable } from 'rxjs';

export function markAllControlsAsDirty(form: UntypedFormGroup) {
  Object.values(form.controls).forEach(control => {
    control.markAsDirty();

    if ((control as UntypedFormGroup).controls) {
      markAllControlsAsDirty(control as UntypedFormGroup);
    }
  });
}

/**
 * Re-calculates the value and validation status of the entire controls tree.
 */
export function updateTreeValidity(group: UntypedFormGroup | UntypedFormArray | TFormGroup<any>): void {
  Object.keys(group.controls).forEach((key: string) => {
    const abstractControl = group.controls[key] as AbstractControl | UntypedFormGroup;

    if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
      updateTreeValidity(abstractControl);
    } else {
      abstractControl.updateValueAndValidity({ emitEvent: false });
    }
  });
}

// Requires 'value', 'valueChange', and 'changeDetectorRef' on class instance
export function FormValueControl({ markForCheck = false }: { markForCheck?: boolean; } = {}) {
  return function (constructor: Function) {
    const prevNgOnDestroy = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      this.writeValue = () => { };
      this.setDisabledState = () => { };
      unsubscribe(this._onChangeSubscription);

      if (prevNgOnDestroy) {
        prevNgOnDestroy.call(this);
      }
    };

    constructor.prototype.writeValue = function (value: any) {
      const previousValue = this.value;

      this.value = value;

      if (this.ngOnChanges) {
        this.ngOnChanges.call(this, { value: { currentValue: this.value, previousValue, firstChange: !this._firstChanged } });
        this._firstChanged = true;
      }

      this.changeDetectorRef.detectChanges();

      if (markForCheck) {
        this.changeDetectorRef.markForCheck();
      }
    };

    constructor.prototype.registerOnChange = function (fn: any) {
      unsubscribe(this._onChangeSubscription);

      this._onChange = fn;
      this._onChangeSubscription = this.valueChange.subscribe(value => {
        const previousValue = this.value;

        this.value = value;
        this._onChange(value);

        if (this.ngOnChanges) {
          this.ngOnChanges.call(this, { value: { currentValue: this.value, previousValue, firstChange: false } });
        }

        setTimeout(() => this.changeDetectorRef.markForCheck(), 1); // I don't even
      });
    };

    constructor.prototype.registerOnTouched = function (fn: any) {
      this._onTouched = fn;
    };

    constructor.prototype.setDisabledState = function (disabled: boolean) {
      this.disabled = disabled;
      this.changeDetectorRef.detectChanges();
    };
  };
}

export function getControlValue$<T extends any>(control: TAbstractControl<T>): Observable<T> {
  return concat(
    of(control.value),
    control.valueChanges
  );
}

export const noEmit = {
  emitEvent: false
};

export function getFormControlProvider(forwardRefFn: ForwardRefFn) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(forwardRefFn),
    multi: true
  };
}

export function isFormValid(formGroup: UntypedFormGroup) {
  formGroup.updateValueAndValidity();

  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();
  });

  formGroup.markAsTouched();

  return formGroup.valid;
}

export function getFormValueObserverFor<T, K extends keyof T>(
  form: TFormGroup<T>, formControlName: K
): Observable<T[K]> {
  return concat(
    defer(() => {
      return of(form.get(formControlName).value);
    }),
    form.get(formControlName).valueChanges
  );
}

export type FormControlType<Type> = {
  [Property in keyof Type as Exclude<Property, '__typename'>]: FormControl<Type[Property]>
}
