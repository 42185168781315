import { getTimezoneOffsetInUserTimezoneExtractor, getUserSettingsLocale } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { DatePipe } from '@angular/common';
import { Pipe } from '@angular/core';
import type { PipeTransform } from '@angular/core';
import type { TimezoneOffsetExtractorFn } from '@ppl/utils';
import type { Subscription } from 'rxjs';


@Pipe({
  name: 'pplDateTime'
})
export class PplDateTimePipe implements PipeTransform {
  locale: string;
  localeSubscription: Subscription;
  getTimezoneOffsetInUserTimezone: TimezoneOffsetExtractorFn = null;
  getTimezoneOffsetInUserTimezoneSubscription: Subscription;

  private transformCache: { [id: string]: string } = {};

  constructor(
    private datePipe: DatePipe,
    private store: AuthStore
  ) {
    this.localeSubscription = this.store.select(getUserSettingsLocale(true)).subscribe(locale => this.locale = locale);
    this.getTimezoneOffsetInUserTimezoneSubscription = this.store.select(getTimezoneOffsetInUserTimezoneExtractor).subscribe(
      extractor => {
        this.getTimezoneOffsetInUserTimezone = extractor;
      }
    );
  }

  transform(isoDateTime: string, showSeconds?: boolean) {
    if (!isoDateTime) {
      return '';
    }

    if (this.transformCache[isoDateTime] && !showSeconds) {
      return this.transformCache[isoDateTime];
    }

    const dateTime = new Date(isoDateTime);
    const timezone = this.getTimezoneOffsetInUserTimezone(dateTime);

    const date = this.datePipe.transform(dateTime, 'medium', timezone, this.locale);
    const result = showSeconds ? date : this.removeSeconds(date);

    if (!showSeconds) {
      this.transformCache[isoDateTime] = result;
    }

    return result;
  }

  private removeSeconds(input: string | null) {
    if (input) {
      return input.replace(/(:\d+)(:\d+)/, '$1');
    }

    return null;
  }
}
