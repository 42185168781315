import type { OnInit } from '@angular/core';
import { Directive , ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pplBottomBarButton]'
})
export class PplBottomBarButtonDirective implements OnInit {
  className = 'ppl-bottom-bar__item';

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, this.className);
  }
}
