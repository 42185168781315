<div class="container demo-content">
  <h2>Ranking Percentage</h2>
  <br>

  <h3>Components</h3>
  <h4>ppl-ranking-percentage</h4>
  <div>@Input() <b>value</b>: number; // &lt;1, 100&gt;</div>
  <div>@Input() <b>disabled</b>: boolean</div>
  <br>
  <div>@Output() <b>valueChange</b>: number</div>
  <br>
  <br>

  <h3>Example</h3>

  <ppl-input-container>
    <ppl-ranking-percentage [value]="percentage"
                            (valueChange)="percentage = $event"></ppl-ranking-percentage>
  </ppl-input-container>

  Selected value: {{percentage}}

  <br>

  <h5>Disabled</h5>

  <ppl-input-container>
    <ppl-ranking-percentage [disabled]="true"
                            [value]="percentage"
                            (valueChange)="percentage = $event"></ppl-ranking-percentage>
  </ppl-input-container>

  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
