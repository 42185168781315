import type { DemoOpenDialogData } from './demo-open-dialog/demo-open-dialog.component';
import { DemoOpenDialogComponent } from './demo-open-dialog/demo-open-dialog.component';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PplDialogService } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-dialog-demo',
  templateUrl: './dialog-demo.component.html',
  styleUrls: ['./dialog-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogDemoComponent implements OnInit {

  code = `
  <ppl-dialog-title (close)="dialogRef.close()">Title</ppl-dialog-title>

  <ppl-dialog-content>Content</ppl-dialog-content>

  <ppl-dialog-actions>
    <button pplButton
            pplButton3
            pplButtonDialog
            (click)="dialogRef.close()">Close</button>
  </ppl-dialog-actions>

  <script>
      open() {
        this.dialogService.open(DemoOpenDialogComponent, {
          width: '400px'
        });
      }
  </script>
  `;

  constructor(private dialogService: PplDialogService) { }

  ngOnInit() {
  }

  open(type: 'default' | 'overlay') {
    this.dialogService.open<DemoOpenDialogComponent, DemoOpenDialogData>(DemoOpenDialogComponent, {
      data: { type },
      autoFocus: false
    });
  }

  openAlert() {
    this.dialogService.alert({
      text: 'Alert dialog demo',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled`
    });
  }

  openWarning() {
    this.dialogService.warning({
      text: 'Warning dialog demo',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled`
    });
  }

  openInfo() {
    this.dialogService.info({
      text: 'Publishing Changes',
      description: 'Publishing your changes went smoothly.'
    });
  }

  openConfirm() {
    this.dialogService.confirm({
      text: 'Confirm dialog demo',
      actionLabel: 'Confirm'
    });
  }

  openYesNo() {
    this.dialogService.yesNoCancel({
      text: 'Yes No dialog demo',
      description: 'Do you want to save modifications?'
    });
  }
}
