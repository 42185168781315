import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
,
  ElementRef} from '@angular/core';

@Component({
  selector: 'ppl-popover-dropdown',
  templateUrl: './popover-dropdown.component.html',
  styleUrls: ['./popover-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplPopoverDropdownComponent implements OnInit {

  @Input() @HostBinding('style.width.px') width: boolean | number;

  @Input() connectToElement?: HTMLElement;

  constructor(private elementRef: ElementRef) {

  }

  ngOnInit(): void {
    if (this.connectToElement) {
      this.width = this.connectToElement.offsetWidth;
    }
  }
}
