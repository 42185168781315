import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplMultiCheckboxComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplMultiCheckboxComponent {
  @Input() value: string[] | null;
  @Input() options: { label: string, value: string }[];
  @Input() initialValue?: string[] | null;
  @Input() hasChanges = false;

  @Output() valueChange = new EventEmitter<string[]>();

  disabled = false;

  @MemoizeLast<PplMultiCheckboxComponent>(['value', 'hasChanges', 'initialValue', 'options'])
  get changes() {
    if (!this.hasChanges) {
      return [];
    } else {
      const initialValue = this.initialValue || [];

      const newItems = (this.value || []).filter(id => !initialValue.includes(id));
      const removedItems = (this.initialValue || []).filter(id => !this.value.includes(id));

      return [...newItems, ...removedItems];
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  onCheck(selectedValue: string, checked: boolean) {
    if (checked) {
      this.valueChange.emit([...(this.value || []), selectedValue]);
    } else {
      this.valueChange.emit(this.value.filter(value => value !== selectedValue));
    }
  }
}
