import { CallingPhoneEditDialogComponent } from '../components/calling-phone-edit-dialog/calling-phone-edit-dialog.component';
import { CallingPurchasePhoneNotAllowedMessageComponent } from '../components/calling-purchase-phone-not-allowed-message/calling-purchase-phone-not-allowed-message.component';
import { CallingPurchaseWizardDialogComponent } from '../components/calling-purchase-wizard-dialog/calling-purchase-wizard-dialog.component';
import { CallingPhoneTypeEnum } from '../domain/phone-number';
import { Injectable } from '@angular/core';
import { AuthStore, getLoggedInUser } from '@ppl/auth';
import { formatInputPhoneNumber } from '@ppl/components/input-phone-number';
import { PplDialogService } from '@ppl/ui/dialog';
import { EMPTY } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import type { AuthUser } from '@ppl/auth';
import type { ApiPatchedClient, DialogResponse } from '@ppl/utils';
import type { Observable } from 'rxjs';
import type { CallingPhoneEditDialogData, CallingPhoneEditDialogResponse } from '../components/calling-phone-edit-dialog/calling-phone-edit-dialog.component';
import type { CallingAvailablePhoneGridRecord, CallingPhoneGridRecord } from '../components/calling-phone-grid/calling-phone-grid.component';
import type { CallingPurchaseWizardDialogData, CallingPurchaseWizardDialogResponse } from '../components/calling-purchase-wizard-dialog/calling-purchase-wizard-dialog.component';
import type {
  AvailablePhoneNumberFilter,
  EditPhoneInput,
  PhoneNumber,
  PurchasePhoneInput
} from '../domain/phone-number';


@Injectable()
export abstract class CallingManageService<PR, DR, T> {

  get currentUser(): AuthUser {
    return this.authStore.get(getLoggedInUser);
  }

  abstract get gqlClient(): ApiPatchedClient;

  constructor(
    protected dialogService: PplDialogService,
    protected authStore: AuthStore
  ) { }

  abstract canDeleteRecord(record: CallingPhoneGridRecord): boolean;
  abstract canEditRecord(record: CallingPhoneGridRecord): boolean;
  abstract fetchPhones(): Observable<CallingPhoneGridRecord[]>;
  abstract getAvailablePhoneNumbers(filter: AvailablePhoneNumberFilter): Observable<CallingAvailablePhoneGridRecord[]>;
  abstract purchasePhone(input: PurchasePhoneInput): Observable<PR>;
  abstract editPhone(input: EditPhoneInput): Observable<any>;
  abstract deletePhone(phoneId: string): Observable<DR>;

  editPhoneDialog(phone: PhoneNumber) {
    const displayForwardingSettings = phone.type !== CallingPhoneTypeEnum.Private;
    return this.dialogService.open<
      CallingPhoneEditDialogComponent,
      CallingPhoneEditDialogData,
      DialogResponse<CallingPhoneEditDialogResponse>
    >(CallingPhoneEditDialogComponent, {
      data: {
        phone,
        displayForwardingSettings,
        save: (formData) => {
          return this.editPhone({
            forwardMessagesToEmail: formData.messageForwardingEmail,
            forwardCallsToPhone: formatInputPhoneNumber(formData.callForwardingPhone),
            name: formData.name,
            id: phone.id
          });
        }
      }
    });
  }

  purchasePhoneDialog(data: CallingManagePurchasePhoneDialogData): Observable<DialogResponse<{ phoneNumber: T }>> {
    if (data.canAddPhones) {
      return this.purchaseCallingAndMessagingPhoneDialog(data).afterClosed();
    } else {
      return this.showPurchasePhoneNotAllowedDialog();
    }
  }

  purchaseCallingAndMessagingPhoneDialog(data: Pick<CallingPurchaseWizardDialogData, 'configurationEnabled'>) {
    return this.dialogService.open<
      CallingPurchaseWizardDialogComponent,
      CallingPurchaseWizardDialogData,
      DialogResponse<CallingPurchaseWizardDialogResponse<T>>
    >(
      CallingPurchaseWizardDialogComponent,
      {
        data: {
          ...data,
          currentUser: this.currentUser,
          purchasePhone: (input: PurchasePhoneInput) => this.purchasePhone(input),
          getAvailablePhoneNumbers: (filter: AvailablePhoneNumberFilter) => this.getAvailablePhoneNumbers(filter)
        },
        autoFocus: false
      }
    );
  }

  showPurchasePhoneNotAllowedDialog() {
    return this.dialogService.open<CallingPurchasePhoneNotAllowedMessageComponent>(
      CallingPurchasePhoneNotAllowedMessageComponent
    ).afterClosed().pipe(
      first(),
      switchMap(() => {
        return EMPTY;
      })
    );
  }
}

export type CallingManagePurchasePhoneDialogData = Pick<CallingPurchaseWizardDialogData, 'configurationEnabled'>
  & { canAddPhones: boolean };
