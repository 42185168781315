import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplSlideToggleComponent } from './slide-toggle.component';
import { PplIconModule } from '../icon';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    PplSlideToggleComponent
  ],
  exports: [
    PplSlideToggleComponent
  ]
})
export class PplSlideToggleModule {
}

export * from './slide-toggle.component';
