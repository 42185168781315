<div class="header">
  <ng-container *ngTemplateOutlet="columnsMetaTemplate"></ng-container>

  <button pplButton
          pplButton1
          pplButtonSmall
          class="reset-button"
          (click)="onWidthResetClick($event)"
          title="Fit all columns to their content"
          i18n-title="@@Fit_all_columns_to_their_content">
    <ppl-icon svgIcon="color-fit-column"
              width="20px"
              height="20px"></ppl-icon>
    <ng-container i18n="@@Fit_column_widths">Fit column widths</ng-container>
  </button>
</div>

<div class="search-container">
  <ppl-search [value]="searchText"
              (valueChange)="onColumnSearch($event)"></ppl-search>
</div>

<div class="column-list">
  <div *ngFor="let category of filteredCategories; trackBy: trackById">
    <div class="category-name"
         *ngIf="!searchText">
      {{ category.name }}
    </div>

    <div class="column-container">
      <div *ngFor="let column of category.columns; trackBy: trackById"
           class="column"
           [class.disabled]="isColumnDisabled(column.id)"
           (click)="onColumnClick(column.id)">
        <ppl-checkbox [value]="isColumnVisible(column.id)"
                      [disabled]="isColumnDisabled(column.id)"></ppl-checkbox>
        <div class="column-name">
          {{column.name}}
        </div>

        <div class="column-frozen">
          <ppl-icon *ngIf="isColumnFrozen(column.id)"
                    svgIcon="pin"
                    width="10px"
                    height="10px"></ppl-icon>
        </div>

        <button *ngIf="isColumnVisible(column.id)"
                pplButton
                pplButton1
                pplButtonSmall
                class="column-frozen-button"
                pplPopover
                pplPopoverAlignEnd
                [pplPopoverTooltip]="isColumnFrozen(column.id) ? 'Click to unfreeze column' : 'Click to freeze column'"
                (click)="frozenToggle.emit(column.id); $event.stopPropagation()">
          <ppl-icon svgIcon="pin"
                    width="10px"
                    height="10px"></ppl-icon>
        </button>
      </div>
    </div>
  </div>
</div>
