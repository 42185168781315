export function requestPostAnimationFrame(handler: () => void): PostAnimationFrameHandle {
  let dialogRenderHandle = 0;
  let dialogDelayHandle: any;

  dialogRenderHandle = requestAnimationFrame(() => {
    dialogDelayHandle = setTimeout(() => {
      handler();
    });
  });

  return {
    clear: () => {
      cancelAnimationFrame(dialogRenderHandle);
      clearTimeout(dialogDelayHandle);
    }
  };
}

export interface PostAnimationFrameHandle {
	clear: () => void;
}
