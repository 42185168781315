import { Toast } from '../toast';
import type { ToastContentInterface  } from '../toast';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
,
  ChangeDetectorRef,
  ComponentFactoryResolver} from '@angular/core';
import { unsubscribe, Unsubscribe } from '@ppl/utils';
import type { Subscription} from 'rxjs';
import { timer , BehaviorSubject} from 'rxjs';
import { tap } from 'rxjs/operators';

let currentZIndex = 2 ** 31 - 1;

@Component({
  selector: 'ppl-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class ToastComponent implements OnInit {
  @Input() toast: Toast;
  @Input() containerMouseOver$: BehaviorSubject<boolean>;

  @Output() close = new EventEmitter();

  @ViewChild('placeholder', { read: ViewContainerRef, static: true }) placeholder: ViewContainerRef;
  @ViewChild('content', { read: ElementRef, static: true }) content: ElementRef;

  zIndex: number;

  closeSubscription: Subscription;
  autoCloseSubscription: Subscription;
  containerMouseOverSubscription: Subscription;

  get contentHeight() {
    return (this.content.nativeElement as HTMLElement)?.clientHeight;
  }

  @HostBinding('style.z-index')
  get zIndexBinding() {
    return this.zIndex;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.zIndex = --currentZIndex;
    this.loadComponent();

    if (!this.containerMouseOver$.getValue()) {
      this.initAutoClose();
    }

    this.containerMouseOverSubscription = this.containerMouseOver$.pipe(
      tap(mouseOver => {
        if (mouseOver) {
          this.cancelAutoClose();
        } else {
          this.initAutoClose();
        }
      })
    ).subscribe();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.toast.component);
    const viewContainerRef = this.placeholder;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory, 0, this.toast.viewContainerRef?.injector);
    const componentInstance = (componentRef.instance as ToastContentInterface);

    if (this.toast.data) {
      Object.assign(componentInstance, this.toast.data);
    }

    if (componentInstance.closeToast$) {
      this.closeSubscription = componentInstance.closeToast$.subscribe(() => this.close.emit());
    }
    this.elementRef.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
    return componentRef.instance;
  }

  initAutoClose() {
    if (!this.toast.options?.sticky) {
      unsubscribe(this.autoCloseSubscription);
      this.autoCloseSubscription = timer(this.toast.options.duration || TOAST_DURATION_MS).pipe(
        tap(() => this.close.emit())
      ).subscribe();
    }
  }

  cancelAutoClose() {
    unsubscribe(this.autoCloseSubscription);
  }
}

const TOAST_DURATION_MS = 10000;
