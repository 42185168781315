<!-- this order is important, since in a dialog the focus can scroll the focus onto sidebar -->
<ng-container [ngSwitch]="position">
  <ng-container *ngSwitchCase="'start'">
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'end'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <ng-container *ngTemplateOutlet="sidebar"></ng-container>
  </ng-container>
</ng-container>

<ng-content select=".ppl-rest"></ng-content>
<div *ngIf="enableOverlay && mode === 'over'"
     [class.opened]="opened"
     class="overlay"
     (click)="close.emit()"></div>

<ng-template #sidebar>
  <ng-content select="ppl-sidebar"></ng-content>
</ng-template>
<ng-template #content>
  <ng-content select="ppl-content"></ng-content>
</ng-template>
