<div class="sensor"
     #container>
  <ppl-buttons-group>

    <ng-container *ngFor="let button of buttonsGroup; let i = index; trackBy: trackById">
      <button #buttonAnchor
              pplButton
              pplButton1
              pplButtonSmall
              type="button"
              [class.hidden]="!button.visible && i > 0 && shrinkable"
              [disabled]="!button.enabled"
              [attr.title]="button.title || null"
              [attr.data-test]="'Toolbar_Button_' + button.id"
              [pplPopover]="button.children ? (button.children.length ? menuItems : menuItemsEmpty) : (button.childrenTemplate ? button.childrenTemplate : null)"
              pplPopoverAlignStart
              [pplPopoverData]="button.children ? (button.children.length ? button.children : button.childrenEmptyLabel) : null"
              [pplPopoverExactCalculations]="true"
              (pplPopoverToggle)="onButtonPopoverToggle($event)"
              (click)="onButtonClick(button)">
        <ppl-icon [svgIcon]="button.icon"
                  width="18px"
                  height="18px"></ppl-icon>
        {{button.text}}
      </button>
    </ng-container>
    <button *ngIf="showHiddenButton"
            pplButton
            pplButton1
            pplButtonSmall
            type="button"
            i18n-title="@@Show_More"
            title="Show More"
            [attr.data-test]="'Toolbar_Button_Overflow'"
            [pplPopover]="showHiddenButton ? menuItems : null"
            pplPopoverActiveClass="ppl-button--active"
            pplPopoverAlignStart
            [pplPopoverData]="hiddenButtonOptions"
            [pplPopoverExactCalculations]="true"
            (pplPopoverToggle)="onButtonPopoverToggle($event)">
      <ppl-icon class="only-icon"
                svgIcon="more-blue"
                width="18px"
                height="18px"></ppl-icon>
    </button>

  </ppl-buttons-group>
</div>

<ng-template #menuItems
             let-data="data"
             let-popover="popover">
  <ppl-popover-dropdown>
    <ppl-menu [hasIcons]="hasMenuIcons(data)"
              [hasChildren]="hasMenuChildren(data)"
              (valueChanged)="onButtonClick($event)">
      <ng-container *ngFor="let menuItem of data; trackBy: trackById">
        <ppl-menu-item *ngIf="menuItem.enabled || menuItem.disableOnly"
                       [value]="menuItem"
                       [disabled]="!menuItem.enabled"
                       [hover]="openMenuItemIds.has(menuItem.id)"
                       [hasChildren]="!!menuItem.children || !!menuItem.childrenComponent"
                       [pplPopover]="menuItem.children ? menuItems : (menuItem.childrenComponent ? menuItemsComponent : null)"
                       pplPopoverEvent="hover-preserve"
                       pplPopoverAlignStart
                       pplPopoverDirection="right"
                       [pplPopoverData]="menuItem.children || (menuItem.childrenComponent ?  { component: menuItem.childrenComponent, injector: getInjector(menuItem) } : undefined)"
                       [pplPopoverExactCalculations]="true"
                       [attr.pplPopoverClose]="menuItem.children ? null : ''"
                       [attr.title]="menuItem.title || null"
                       [attr.data-test]="'Toolbar_Button_' + menuItem.id"
                       (pplPopoverToggle)="onPopoverToggle(menuItem, popover, $event)">
          <ppl-icon *ngIf="menuItem.icon"
                    [svgIcon]="menuItem.icon"
                    width="18px"
                    height="18px"></ppl-icon>
          <ng-container *ngIf="!menuItem.template">
            {{menuItem.text}}
          </ng-container>

          <ng-container *ngIf="menuItem.template">
            <ng-container *ngTemplateOutlet="menuItem.template; context: { $implicit: menuItem, data: menuItem.data }"></ng-container>
          </ng-container>
        </ppl-menu-item>
      </ng-container>
    </ppl-menu>
  </ppl-popover-dropdown>
</ng-template>

<ng-template #menuItemsComponent
             let-data="data">
  <ppl-popover-dropdown>
    <ng-container *ngComponentOutlet="data.component; injector: data.injector"></ng-container>
  </ppl-popover-dropdown>
</ng-template>

<ng-template #menuItemsEmpty
             let-data="data">
  <ppl-popover-dropdown>
    <div class="empty-label">
      <ng-container *ngIf="data">{{data}}</ng-container>
      <ng-container *ngIf="!data"
                    i18n="@@No_items">No items</ng-container>
    </div>
  </ppl-popover-dropdown>
</ng-template>
