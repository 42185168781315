import type {
  Observable} from 'rxjs';
import {
  forkJoin,
  from,
  of
} from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import type { PplAttachment, PplAttachmentFileTypeLimit, PplAttachmentUnsupportedInfo } from '.';

const defaultFileSizeLimit = 30;
const defaultFileTypeLimit: PplAttachmentFileTypeLimit = {
  denied: true,
  list: []
};

@Injectable({
  providedIn: 'root'
})
export class PplAttachmentsService {
  /**
   * Processes files, can be used on multiple places
   * @param config describes how files should be processed
   * @returns processed files and unsupported attachments information
   */
  processFiles(config: PplAttachmentProcessFilesConfig): Observable<{
    unsupportedAttachments: PplAttachmentUnsupportedInfo,
    files: PplAttachment[]
  }> {
    // set defaults if certain config variables are not set
    config = {
      ...config,
      fileSizeLimit: config.fileSizeLimit || defaultFileSizeLimit,
      fileTypeLimit: config.fileTypeLimit || defaultFileTypeLimit
    };

    // start with processing the files
    const originalFiles = config.files;
    const unsupportedAttachments = {
      fileSizeLimit: config.fileSizeLimit,
      fileTypeLimit: config.fileTypeLimit,
      filesEmpty: [],
      filesWithIncorrectSize: [],
      filesWithIncorrectType: []
    };
    const fileList: File[] = [];

    for (const file of originalFiles) {
      if (!file.size) {
        unsupportedAttachments.filesEmpty.push(file.name);
      } else if (!this.isFileSizeOk(file, config.fileSizeLimit)) {
        unsupportedAttachments.filesWithIncorrectSize.push(file.name);
      } else if (!this.isFileExtensionOk(file, config.fileTypeLimit)) {
        unsupportedAttachments.filesWithIncorrectType.push(file.name);
      } else if (!!config.accept && !file.name.includes(config.accept)) {
        unsupportedAttachments.filesWithIncorrectType.push(file.name);
      } else {
        fileList.push(file);
      }
    }

    const fileListObservables = fileList.map(
      file => from(this.getAttachmentInstance(file, config.preserveOriginalFile))
    );

    // in case no files fulfill the requirements
    if (!fileListObservables.length) {
      return of({
        unsupportedAttachments,
        files: []
      });
    } else {
      return forkJoin(fileListObservables).pipe(
        map(files => {
          return {
            unsupportedAttachments,
            files
          };
        })
      );
    }
  }

  getAttachmentInstance(
    file: File,
    preserveOriginalFile = false
  ): Promise<PplAttachment> | Observable<PplAttachment> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({
        // TODO: remove preserveOriginalFile in application
        // file: preserveOriginalFile ? file : null,
        file: file,
        content: (reader.result as string).split(',')[1],
        name: file.name,
        mimeType: (reader.result as string).split(';')[0].split(':')[1]
      } as PplAttachment);
      reader.onerror = error => reject(error);
    });
  }

  private isFileSizeOk(
    file: File,
    fileSizeLimit: number
  ) {
    return (file.size / 1024 / 1024) <= fileSizeLimit;
  }

  private isFileExtensionOk(
    file: File,
    fileTypeLimit: PplAttachmentFileTypeLimit
  ) {
    let isExtensionOk = true;
    if (fileTypeLimit) {
      const fileName = file.name;
      // check if file extension is not denied
      if (fileTypeLimit.denied) {
        isExtensionOk = !fileTypeLimit.list.find(extension => fileName.includes(extension));
      } else {
        isExtensionOk = !!fileTypeLimit.list.find(extension => fileName.includes(extension));
      }
    }
    return isExtensionOk;
  }
}
export interface PplAttachmentProcessFilesConfig {
  files: File[];
  fileSizeLimit?: number;
  fileTypeLimit?: PplAttachmentFileTypeLimit;
  singleSelection?: boolean;
  accept?: string;
  preserveOriginalFile?: boolean;
}
