import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import type { PplBaseOption } from '@ppl/domain';

@Component({
  selector: 'ppl-advanced-multiselect-checkbox-demo',
  templateUrl: './advanced-multiselect-checkbox-demo.component.html',
  styleUrls: ['./advanced-multiselect-checkbox-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedMultiselectCheckboxDemoComponent implements OnInit {

  value1: string[] = ['1'];
  initialValue1: string[] = ['1'];
  options1: PplBaseOption[] = [
    {
      label: 'aoption1',
      value: '1',
    },
    {
      label: 'aoption2',
      value: '2',
    },
    {
      label: 'boption1',
      value: '3',
    },
    {
      label: 'boption2',
      value: '4',
    },
    {
      label: 'coption1',
      value: '5',
    },
    {
      label: 'coption2',
      value: '6',
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(values: string[], valueProp) {
    this[valueProp] = values;
  }

}
