<div class="container demo-content">
  <h2>Partial Select Component</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-partial-select</h4>
  <div>@Input() <b>value</b>: PplPartialSelectValue</div>
  <div>@Input() <b>options</b>: PplPartialSelectOption[]</div>
  <div>@Input() <b>disabled</b>: boolean</div>
  <div>@Input() <b>labelAll</b>?: string</div>
  <div>@Input() <b>labelSelected</b>?: string</div>

  <br>
  <div>@Output() <b>change</b>: PplPartialSelectValue</div>
  <br>
  <br>

  <h3>Example</h3>
  <ppl-partial-select [value]="value"
                      [options]="options"
                      (valueChange)="value = $event"></ppl-partial-select>

  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
