<div class="container demo-content">
  <h2>Datepicker</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-datepicker</h4>
  <div>@Input() <b>value</b>: Date | null</div>
  <div>@Input() <b>disabled </b>= false</div>
  <div>@Input() <b>disabledValue </b>= false</div>
  <div>@Input() <b>readonly </b>= false</div>
  <div>@Input() <b>format? </b> = 'short' (optional)</div>
  <div>@Input() <b>locale? </b>: string (optional)</div>
  <div>@Input() <b>firstWeekDay? </b>: number (optional)</div>
  <div>@Input() <b>buttonLabel </b>: null</div>
  <div>@Input() <b>timezone? </b>: string (optional)</div>
  <br>
  <div>@Input() <b>displayInput </b>= true</div>
  <div>@Input() <b>displayTime </b>= false</div>
  <div>@Input() <b>displayPlanner </b>= false</div>
  <div>@Input() <b>displayRemove </b>= true</div>
  <div>@Input() <b>disableRemove </b>= false</div>
  <div>@Input() <b>outputIsoDate </b>= false</div>
  <div>@Input() <b>minViewMode </b>= 'days'</div>
  <br>

  <div>@Output() <b>changeValue</b>: Date | null</div>
  <div>@Output() <b>todayClick?</b>: null</div>
  <div>@Output() <b>plannerClick?</b>: Date()</div>
  <br>
  <br>

  <p>
    <ppl-checkbox [value]="disabled"
                  (valueChange)="disabled = $event.checked">
      disabled
    </ppl-checkbox>
    <ppl-checkbox [value]="displayRemove"
                  (valueChange)="displayRemove = $event.checked">
      displayRemove
    </ppl-checkbox>
    <ppl-checkbox [value]="disableRemove"
                  (valueChange)="disableRemove = $event.checked">
      disableRemove
    </ppl-checkbox>
    <ppl-input-container label="Timezone">
      <ppl-select [value]="timezone"
                  [options]="timezones"
                  (valueChange)="timezone = $event"></ppl-select>
    </ppl-input-container>
  </p>

  <br>

  <h3>Example</h3>
  <ppl-input-container>
    <ppl-date-input format="M/d/yy, h:mm a"
                    [displayTime]="true"
                    [disabled]="disabled"
                    [displayPlanner]="true"
                    [displayRemove]="displayRemove"
                    [disableRemove]="disableRemove"
                    [timezone]="timezone"
                    [value]="value"
                    (valueChange)="value = $event"></ppl-date-input>
  </ppl-input-container>

  <p>
    value: {{value | json}}
  </p>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
