<div *ngIf="header"
     class="header">
  {{ header }}
</div>
<div *ngIf="header"
     class="divider"></div>
<div class="body">
  <div *ngFor="let option of options; trackBy: trackByFn"
       class="option"
       [class.selected]="isOptionActive(option)"
       (click)="onOptionClick(option)">
    <ng-container *ngIf="optionTemplate; else defaultOptionTemplate">
      <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
    </ng-container>
    <ng-template #defaultOptionTemplate>
      <ppl-icon *ngIf="option.icon"
                [svgIcon]="isOptionActive(option) ? option.iconActive : option.icon"
                height="20px"
                width="20px"></ppl-icon>
      <div class="option-label">
        {{ option.label }}
      </div>
      <div *ngIf="option.suffix">{{ option.suffix }}</div>
    </ng-template>
  </div>
</div>