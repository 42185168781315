import type { PplPopoverAnchorRect} from './popover-content/popover-content.component';
import { PplPopoverContentComponent } from './popover-content/popover-content.component';
import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import type {
  AfterViewInit,
  ComponentRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
,
  ApplicationRef,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  selector: '[pplPopover]',
  exportAs: 'pplPopoverDirective'
})
export class PplPopoverDirective implements AfterViewInit, OnDestroy {

  @Input() pplPopover: TemplateRef<any>;
  @Input() pplPopoverId?: any;
  @Input() pplPopoverAnchor?: HTMLElement;
  @Input() pplPopoverDirection: PplPopoverDirection = 'down';
  @Input() pplPopoverAlignStart: any = false;
  @Input() pplPopoverAlignEnd: any = false;
  @Input() pplPopoverAnchorRect: PplPopoverAnchorRect | null = null;
  @Input() pplPopoverEvent: 'click' | 'right-click' | 'hover' | 'hover-preserve' | null = 'click';
  @Input() pplPopoverEventDelay = 0;
  @Input() pplPopoverData: any;
  @Input() pplPopoverDisabled = false;
  @Input() pplPopoverFocusFirstInput = false;
  @Input() pplPopoverCloseOnAnyLevel = false;
  @Input() pplPopoverForceOutsideClickListener = false;
  @Input() pplPopoverActiveClass = 'popover-active';
  @Input() pplPopoverComponent: any;
  @Input() pplPopoverComponentData: any;
  @Input() pplPopoverExactCalculations = false;
  @Input() pplPopoverPointerEventsNone = false;
  @Input() pplPopoverAutoShow = false;
  @Input() pplPopoverShouldClose?: () => boolean;

  @Output() pplPopoverToggle = new EventEmitter<PplPopoverDirectiveToggleEvent>();
  @Output() pplPopoverCloseRequest = new EventEmitter<Element>();

  get isOpen() {
    return this.visible;
  }

  private visible = false;

  private bodyPortalHost: DomPortalHost;
  private hostContentTimer: any;
  private contentHostTimer: any;
  private contentShowTimer: any;
  private componentRef: ComponentRef<PplPopoverContentComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector) {
    this.bodyPortalHost = new DomPortalHost(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );
  }

  ngAfterViewInit(): void {
    if (this.pplPopoverAutoShow) {
      this.show();
    }
  }

  ngOnDestroy() {
    // Destroy popover if its parent is destroyed
    if (this.visible) {
      this.hide();
    }
  }

  show(options = { forceChangeDetect: false }) {
    if (this.visible || this.pplPopoverDisabled) {
      return;
    }

    this.visible = true;

    const contentPortal = new ComponentPortal(PplPopoverContentComponent, this.viewContainerRef, this.injector);
    this.componentRef = this.bodyPortalHost.attachComponentPortal(contentPortal);

    this.componentRef.instance.anchorElement = this.pplPopoverAnchor || this.elementRef.nativeElement;
    this.componentRef.instance.hostElement = this.elementRef.nativeElement;
    this.componentRef.instance.contentTemplate = this.pplPopover;
    this.componentRef.instance.popoverDirective = this;
    this.componentRef.instance.closeOnAnyLevel = this.pplPopoverCloseOnAnyLevel !== false;

    this.componentRef.instance.data = this.pplPopoverData;

    this.componentRef.instance.direction = this.pplPopoverDirection;
    this.componentRef.instance.alignStart = this.pplPopoverAlignStart === '' || this.pplPopoverAlignStart === true;
    this.componentRef.instance.alignEnd = this.pplPopoverAlignEnd === '' || this.pplPopoverAlignEnd === true;
    this.componentRef.instance.anchorRect = this.pplPopoverAnchorRect;

    this.componentRef.instance.pplPopoverComponent = this.pplPopoverComponent;
    this.componentRef.instance.pplPopoverComponentData = this.pplPopoverComponentData;

    this.componentRef.instance.exactCalculations = this.pplPopoverExactCalculations;
    this.componentRef.instance.pointerEventsNone = this.pplPopoverPointerEventsNone;

    this.componentRef.instance.closeRequest = this.onCloseRequest.bind(this);

    if (this.pplPopoverEvent === 'click' || this.pplPopoverEvent === 'right-click') {
      this.componentRef.instance.mouseUp = this.hide.bind(this);
    } else if (this.pplPopoverEvent === 'hover' || this.pplPopoverEvent === 'hover-preserve') {
      this.componentRef.instance.mouseEnter = this.onContentMouseEnter.bind(this);
      this.componentRef.instance.mouseLeave = this.onContentMouseLeave.bind(this);
    } else {
      if (this.pplPopoverForceOutsideClickListener) {
        this.componentRef.instance.mouseUp = this.hide.bind(this);
      }
      this.componentRef.instance.resizeScrollParent = this.hide.bind(this);
    }

    // Wait until popover content is rendered (off-screen) and then calculate position
    requestAnimationFrame(() => {
      if (this.componentRef) {
        if (options.forceChangeDetect) {
          this.componentRef.instance.changeDetector.detectChanges();
        }

        this.componentRef.instance.recalcPosition();

        if (this.pplPopoverFocusFirstInput) {
          this.componentRef.instance.focusFirstInput();
        }

        this.componentRef.instance.init();
        this.pplPopoverToggle.emit({ visible: true, popover: this });
      }
    });

    this.elementRef.nativeElement.classList.add(this.pplPopoverActiveClass);
  }

  hide() {
    if (!this.visible) {
      return;
    }

    if (this.pplPopoverShouldClose && !this.pplPopoverShouldClose()) {
      return;
    }

    const wasVisible = this.visible;

    this.visible = false;

    clearTimeout(this.contentHostTimer);
    clearTimeout(this.hostContentTimer);
    clearTimeout(this.contentShowTimer);
    this.contentHostTimer = null;
    this.hostContentTimer = null;

    this.componentRef = null;

    this.bodyPortalHost.detach();

    this.elementRef.nativeElement.classList.remove(this.pplPopoverActiveClass);

    if (wasVisible) {
      this.pplPopoverToggle.emit({ visible: false, popover: this });
    }
  }

  toggle() {
    if (this.visible) {
      this.hide();
    } else if (!this.pplPopoverDisabled) {
      this.show();
    }
  }

  recalcPosition() {
    if (this.componentRef) {
      this.componentRef.instance.anchorRect = this.pplPopoverAnchorRect;
      this.componentRef.instance.recalcPosition();
    }
  }

  setComponentData() {
    if (this.componentRef) {
      this.componentRef.instance.setPopoverComponentData(this.pplPopoverComponentData);
    }
  }

  getInstance() {
    return this.componentRef?.instance;
  }

  onCloseRequest(eventTarget: Element) {
    this.pplPopoverCloseRequest.emit(eventTarget);
  }

  @HostListener('click')
  onClick() {
    if (this.pplPopover === null) {
      return;
    }

    if (this.pplPopoverEvent === 'click') {
      if (this.elementRef.nativeElement.nodeName.toLowerCase() !== 'input' || !this.visible) {
        this.toggle();
      }
    } else if (this.pplPopoverEvent === 'right-click') {
      this.hide();
    }
  }

  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent) {
    if (this.pplPopover === null) {
      return;
    }

    if (this.pplPopoverEvent === 'right-click') {
      if (this.elementRef.nativeElement.nodeName.toLowerCase() !== 'input' || !this.visible) {
        this.pplPopoverAlignStart = true;
        this.pplPopoverAnchorRect = {
          left: event.pageX,
          right: event.pageX,
          top: event.pageY,
          bottom: event.pageY,
          width: 0,
          height: 0
        };

        this.toggle();
      }

      event.preventDefault();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.pplPopover === null) {
      return;
    }

    if (this.pplPopoverEvent === 'hover') {
      if (this.pplPopoverEventDelay) {
        this.contentShowTimer = setTimeout(() => {
          // Needs forced change detection, because in other cases changeDetector is run implictly as a part of mouseenter action
          this.show({
            forceChangeDetect: true
          });
        }, this.pplPopoverEventDelay);
      } else {
        this.show();
      }
    } else if (this.pplPopoverEvent === 'hover-preserve') {
      if (!this.contentHostTimer) {
        this.show();
      } else {
        clearTimeout(this.contentHostTimer);
      }
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.pplPopover === null) {
      return;
    }

    clearTimeout(this.contentShowTimer);

    if (this.pplPopoverEvent === 'hover') {
      this.hide();
    } else if (this.pplPopoverEvent === 'hover-preserve') {
      this.hostContentTimer = setTimeout(() => this.hide(), HideWaitTime);
    }
  }

  onContentMouseEnter() {
    clearTimeout(this.hostContentTimer);
  }

  onContentMouseLeave() {
    this.contentHostTimer = setTimeout(() => this.hide(), HideWaitTime);
  }

}

const HideWaitTime = 10;
export type PplPopoverDirection = 'up' | 'down' | 'left' | 'right';
export interface PplPopoverDirectiveToggleEvent {
  visible: boolean;
  popover: PplPopoverDirective;
}
