<ng-container *ngIf="!routable; else routableTabs">
  <div class="nav-container">
    <nav #tabAnchors
         class="tabs">
      <ng-container *ngFor="let tab of tabs; trackBy: trackTabs">
        <ng-container *ngTemplateOutlet="tabTemplate; context: { '\$implicit': tab }"></ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="overflowTab"></ng-container>
    </nav>
    <div class="nav-suffix"
         *ngIf="tabsSuffix">
      <ng-template [ngTemplateOutlet]="tabsSuffix"></ng-template>
    </div>
  </div>
  <ng-content></ng-content>
</ng-container>

<ng-template #routableTabs>
  <div class="nav-container">
    <nav #tabAnchors
         class="tabs">
      <ng-container *ngFor="let tab of tabs; trackBy: trackTabs">
        <ng-container *ngTemplateOutlet="tabTemplate; context: { '\$implicit': tab }"></ng-container>
      </ng-container>
      <ng-container *ngTemplateOutlet="overflowTab"></ng-container>
    </nav>
    <div class="nav-suffix"
         *ngIf="tabsSuffix">
      <ng-template [ngTemplateOutlet]="tabsSuffix"></ng-template>
    </div>
  </div>
  <div class="divider">
    <ng-content select="[ppl-tab-divider]"></ng-content>
  </div>
  <div class="tab-content">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<ng-template #overflowTab>
  <ng-container *ngIf="displayOverflowButton">
    <a class="tab tab-overflow"
       [style.left.px]="buttonOverflowOffset"
       [pplPopover]="overflowTabs"
       [class.selected]="isOverflowedTabActive"
       pplPopoverAlignEnd>
      ...
    </a>
  </ng-container>
</ng-template>

<ng-template #overflowTabs>
  <ppl-popover-dropdown>
    <ppl-menu [hasIcons]="true">
      <ng-container *ngFor="let tab of tabs; let i = index; trackBy: trackTabs">
        <ppl-menu-item *ngIf="!tab.visible"
                       (click)="onTabClick(i)"
                       pplPopoverClose>
          <ppl-icon *ngIf="tab.icon"
                    [svgIcon]="tab.icon"
                    [width]="largeIcons ? '20px' : '16px'"
                    [height]="largeIcons ? '20px' : '16px'"></ppl-icon>

          <span>{{tab.label}}</span>


          <ng-container *ngTemplateOutlet="tabSwitchTemplate; context: { '\$implicit': tab.tabSwitch }"></ng-container>

          <div *ngIf="tab.badgeTemplate"
               class="badge">
            <ng-container *ngTemplateOutlet="tab.badgeTemplate"></ng-container>
          </div>

        </ppl-menu-item>
      </ng-container>
    </ppl-menu>
  </ppl-popover-dropdown>
</ng-template>

<ng-template #tabTemplate
             let-tab>
  <ng-container *ngIf="!routable; else routableTab">
    <a #anchorTab
       class="tab"
       (click)="selectTab(tab)"
       [class.selected]="tab.selected"
       [class.tab-with-icon]="tab.icon"
       [class.error]="!!tab.error"
       [class.hidden]="!tab.visible">

      <ng-container *ngTemplateOutlet="tabSwitchTemplate; context: { '\$implicit': tab.tabSwitch }"></ng-container>

      <ppl-icon *ngIf="tab.icon"
                [svgIcon]="tab.icon"
                [width]="largeIcons ? '20px' : '16px'"
                [height]="largeIcons ? '20px' : '16px'"></ppl-icon>
      <span>{{tab.label}}</span>

      <div *ngIf="tab.badgeTemplate"
           class="badge">
        <ng-container *ngTemplateOutlet="tab.badgeTemplate"></ng-container>
      </div>
    </a>
  </ng-container>

  <ng-template #routableTab>
    <a #anchorTab
       class="tab"
       [routerLink]="tab.link"
       [class.selected]="isLinkActive(tab.link)"
       [class.tab-with-icon]="tab.icon"
       [class.error]="!!tab.error"
       [class.hidden]="!tab.visible"
       [queryParamsHandling]="null">

      <ng-container *ngTemplateOutlet="tabSwitchTemplate; context: { '\$implicit': tab.tabSwitch }"></ng-container>

      <ppl-icon *ngIf="tab.icon"
                [svgIcon]="tab.icon"
                [width]="largeIcons ? '20px' : '16px'"
                [height]="largeIcons ? '20px' : '16px'"></ppl-icon>
      <span>{{tab.label}}</span>
      <div *ngIf="tab.badgeTemplate"
           class="badge">
        <ng-container *ngTemplateOutlet="tab.badgeTemplate"></ng-container>
      </div>
    </a>
  </ng-template>
</ng-template>

<ng-template #tabSwitchTemplate
             let-tabSwitch>
  <div *ngIf="tabSwitch !== null"
       class="switch"
       [class.switch-active]="tabSwitch !== null && tabSwitch"
       [style.background]="tabSwitchColor ? tabSwitchColor : null"></div>
</ng-template>
