import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplBottomBarButtonDirective } from './bottom-bar-button.directive';
import { PplBottomBarComponent } from './bottom-bar.component';
import { PplBottomBarSubmenuButtonComponent } from './submenu/bottom-bar-submenu-button.component';
import { PplBottomBarSubmenuComponent } from './submenu/bottom-bar-submenu.component';
import { PplPopoverModule } from '../popover';

@NgModule({
  imports: [
    CommonModule,
    PplPopoverModule
  ],
  declarations: [
    PplBottomBarComponent,
    PplBottomBarSubmenuComponent,
    PplBottomBarSubmenuButtonComponent,
    PplBottomBarButtonDirective
  ],
  exports: [
    PplBottomBarComponent,
    PplBottomBarSubmenuComponent,
    PplBottomBarSubmenuButtonComponent,
    PplBottomBarButtonDirective
  ]
})
export class PplBottomBarModule {}

export * from './bottom-bar.component';
export * from './submenu/bottom-bar-submenu.component';
export * from './bottom-bar-button.directive';
