<button type="button"
        *ngFor="let option of options"
        class="toggle-button"
        [style.background-color]="option.color"
        [class.default-background]="isSelected(option) && !hasColors"
        [class.active]="isSelected(option)"
        [class.disabled]="disabled || option.disabled"
        [attr.title]="option.label || option.title || null"
        (click)="onButtonClick(option)">
  <ppl-icon *ngIf="option.icon"
            [style.margin-right]="option.label ? '8px': 0"
            [svgIcon]="option.icon.name || option.icon"
            [width]="option.icon.width || '16px'"
            [height]="option.icon.height || '16px'"></ppl-icon>
  <span *ngIf="option.label"
        class="label"
        title="{{ option.label }}">
    {{option.label}}
  </span>
</button>
