import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { getLocationFormatted } from '../domain/functions/location';

@Pipe({
  name: 'pplLocation'
})
export class PplLocationPipe implements PipeTransform {

  constructor() {

  }

  transform(value: { address: string, city: string, stateProvince: string, country: string }) {
    return getLocationFormatted(value);
  }
}
