import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplContainerModule } from '../container/index';
import { PplIconModule } from '../icon/index';
import { PplResizableModule } from '../resizable/index';
import { PplDrawerComponent } from './drawer.component';
import { PplDrawerControlComponent } from './drawer-control/drawer-control.component';
import { PplDrawerDetailComponent } from './drawer-detail/drawer-detail.component';

@NgModule({
    imports: [
        CommonModule,
        PplContainerModule,
        PplIconModule,
        PplResizableModule
    ],
    declarations: [
        PplDrawerComponent,
        PplDrawerControlComponent,
        PplDrawerDetailComponent
    ],
    exports: [
        PplDrawerComponent,
        PplDrawerControlComponent,
        PplDrawerDetailComponent
    ]
})
export class PplDrawerModule {
}

export * from './drawer.component';
