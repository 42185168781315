<div class="container demo-content">
  <h2>Dialog</h2>

  <p>
    <ppl-checkbox [value]="visible"
                  (valueChange)="visible = $event.checked">
      Visible
    </ppl-checkbox>
  </p>
  <p>
    <ppl-checkbox [value]="large"
                  (valueChange)="large = $event.checked">
      Large
    </ppl-checkbox>
  </p>
  <p>
    <ppl-checkbox [value]="inverse"
                  (valueChange)="inverse = $event.checked">
      Inverse
    </ppl-checkbox>
  </p>
  <p>
    text:<br>
    <input type="text"
           pplInput
           (value)="loadingText"
           (keyup)="loadingText = $event.target.value">
  </p>

  <div class="content">
    <ppl-loading-overlay [large]="large"
                         [text]="loadingText"
                         [visible]="visible"
                         [inverse]="inverse"></ppl-loading-overlay>
  </div>
</div>
