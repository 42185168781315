import type { GridAvailableColumn, GridAvailableColumnCategory, GridColumn } from '../grid.interfaces';
import type {
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { I18nService } from '@ppl/i18n';
import { MemoizeLast, notFirstChange, trackById } from '@ppl/utils';
import { fuzzySearch } from '../../autocomplete';
import { DefaultAvailableColumn } from '../grid.constants';

@Component({
  selector: 'ppl-grid-select-columns',
  templateUrl: './grid-select-columns.component.html',
  styleUrls: ['./grid-select-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridSelectColumnsComponent implements OnChanges, OnInit {

  @Input() allColumns: GridAvailableColumn[];
  @Input() selectedColumns: GridColumn[];
  @Input() maxVisibleColumns: number;
  @Input() columnsMetaTemplate: TemplateRef<any>;
  @Input() columnsSource?: any; // if source changes, recalculate categories
  @Input() categories?: GridAvailableColumnCategory[];

  @Output() columnToggle = new EventEmitter<string>();
  @Output() frozenToggle = new EventEmitter<string>();
  @Output() widthResetClick = new EventEmitter();

  searchText = '';
  columnCategories: GridSelectColumnCategory[];
  trackById = trackById;

  @MemoizeLast<GridSelectColumnsComponent>(['columnCategories', 'searchText'])
  get filteredCategories(): GridSelectColumnCategory[] {
    return this.columnCategories.map(category => {
      const normalizedSearch = (this.searchText || '').trim();
      const columns = fuzzySearch({
        list: category.columns,
        term: normalizedSearch,
        key: 'name'
      });

      return {
        ...category,
        columns
      };
    });
  }

  constructor(
    private i18nService: I18nService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.columnsSource)) {
      this.createColumnCategories();
    }
  }

  ngOnInit() {
    this.createColumnCategories();
  }

  onColumnSearch(searchText: string) {
    this.searchText = searchText;
    this.changeDetectorRef.detectChanges();
  }

  isColumnVisible(id: string) {
    return !!this.selectedColumns.find(column => column.id === id);
  }

  isColumnDisabled(id: string) {
    const column = this.allColumns.find(column => column.id === id);

    return (!this.isColumnVisible(id) && this.selectedColumns.length === this.maxVisibleColumns)
      || (this.isColumnVisible(id) && this.selectedColumns.length <= 1)
      || (column && column.removeDisabled);
  }

  isColumnFrozen(id: string) {
    return !!this.selectedColumns.find(column => column.id === id)?.frozen;
  }

  onColumnClick(id: string) {
    if (!this.isColumnDisabled(id)) {
      this.columnToggle.emit(id);
    }
  }

  onWidthResetClick(event: MouseEvent) {
    (event.target as HTMLElement).closest('button')?.blur();
    this.widthResetClick.emit();
  }

  private createColumnCategories() {
    this.columnCategories = [
      {
        name: this.i18nService.translate('Visible'),
        id: 'visible',
        columns: this.selectedColumns.map(selectedColumn => {
          const column = this.allColumns.find(column => column.id === selectedColumn.id);

          if (!column) {
            return {
              ...DefaultAvailableColumn,
              id: selectedColumn.id,
              name: '(unknown)'
            };
          }

          return column;
        })
      }
    ];

    if (this.categories) {
      this.categories.forEach(category => {
        this.columnCategories.push({
          name: `${this.i18nService.translate('Not_visible')} (${category.name})`,
          id: `notVisible_${category.id}`,
          columns: this.allColumns.filter(column => {
            return !this.isColumnVisible(column.id) && column.categoryId === category.id;
          })
        });
      });
    } else {
      this.columnCategories.push({
        name: this.i18nService.translate('Not_visible'),
        id: 'notVisible',
        columns: this.allColumns.filter(column => !this.isColumnVisible(column.id))
      });
    }
  }

}

interface GridSelectColumnCategory {
  name: string;
  id: string;
  columns: GridAvailableColumn[];
}
