import { PplNavBarComponent, PplNavBarItemComponent } from './nav-bar.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplNavBarComponent,
    PplNavBarItemComponent
  ],
  exports: [
    PplNavBarComponent,
    PplNavBarItemComponent
  ]
})
export class PplNavBarModule {
}

export * from './nav-bar.component';

