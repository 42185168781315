import { PplDialogRef } from '../dialog';
import { PplIconService } from '../icon';
import { getIconSVG } from '../icon/icon.service';
import { PplPopoverDirective } from '../popover';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Optional,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef,
  TemplateRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import type { SafeHtml  } from '@angular/platform-browser';
import {
  FormValueControl,
  KEYCODE_ESCAPE,
  Memoize,
  unsubscribe
} from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'ppl-items-chooser',
  templateUrl: './items-chooser.component.html',
  styleUrls: ['./items-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplItemsChooserComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplItemsChooserComponent implements OnInit, OnDestroy, OnChanges {

  @Input() value: string[];
  @Input() options: PplItemsChooserOption[];

  @Input() disabled = false;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionDropdownTemplate?: TemplateRef<any>;
  @Input() itemHeight = 34;

  @Output() valueChange = new EventEmitter<string[]>();

  @ViewChild('container', { static: true }) container: ElementRef<HTMLElement>;
  @ViewChild('dropdownButton', { static: false, read: PplPopoverDirective }) dropdownButton: PplPopoverDirective;

  resizeObserver: ResizeObserver;
  dropdownButtonLeftOffset = 0;
  dropdownItemIds: string[] = [];

  lastItemButtonBeforeDropdownID: string;
  lastItemButtonBeforeDropdownWidth: number;

  dialogUpdateLayoutFallbackHandle;
  subscriptions: Subscription[] = [];

  MARGIN_BETWEEN_ITEMS = MARGIN_BETWEEN_ITEMS;

  get dropdownOptions() {
    return this.options.filter(option => this.dropdownItemIds.includes(option.value));
  }

  get isDropdownOpened() {
    return this.dropdownButton && this.dropdownButton.isOpen;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private iconService: PplIconService,
    @Optional() private dialogRef: PplDialogRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.updateLayout();
    }
  }

  ngOnInit() {
    if (!this.iconService.isLoaded$.getValue()) {
      this.subscriptions.push(this.iconService.isLoaded$.pipe(
        filter(loaded => loaded)
      ).subscribe(() => {
        const getIconSVGCache: Map<string, SafeHtml> = (this.getIconSVG as any).cache;
        getIconSVGCache.clear();
        this.changeDetectorRef.detectChanges();
      }));
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      this.updateLayout();
    });
    this.resizeObserver.observe(this.container.nativeElement);

    if (this.dialogRef) {
      this.subscriptions.push(
        this.dialogRef.afterOpened().subscribe(() => {
          this.updateLayout();
        })
      );
    } else {
      this.dialogUpdateLayoutFallbackHandle = setTimeout(() => {
        this.updateLayout();
      }, 1000);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.dialogUpdateLayoutFallbackHandle);
    this.subscriptions.forEach(unsubscribe);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  onDropdownItemKeyup(event: KeyboardEvent) {
    if (event.keyCode === KEYCODE_ESCAPE) {
      event.stopPropagation();
      this.dropdownButton.hide();
    }
  }

  onItemToggle(option: PplItemsChooserOption) {
    const newValue = this.value.includes(option.value)
      ? this.value.filter(id => id !== option.value)
      : [...this.value, option.value];
    this.valueChange.emit(newValue);
  }

  onDropdownItemToggle(value: string) {
    const option = this.options.find(opt => opt.value === value);
    this.onItemToggle(option);
  }

  onDropdownPopoverToggle(visible: boolean) {
    // NOTE(mike): Here just to trigger change detection and to remove the `hovered`
    // class from `dropdown-button`.
  }

  trackBy(index: number, option: PplItemsChooserOption) {
    return option.value;
  }

  isSelected(option: PplItemsChooserOption) {
    return this.value && this.value.includes(option.value);
  }

  isDropdownItem(option: PplItemsChooserOption) {
    return this.dropdownItemIds.includes(option.value);
  }

  isSomeItemInDropdownSelected() {
    const firstSelectedOption = this.options.find(option => {
      return this.value.includes(option.value) && this.dropdownItemIds.includes(option.value);
    });
    return Boolean(firstSelectedOption);
  }

  @Memoize()
  getIconSVG(iconName: string) {
    const icon = this.iconService.icons[iconName];

    if (icon) {
      const iconSVGAsString = getIconSVG(icon);
      return this.sanitizer.bypassSecurityTrustHtml(iconSVGAsString);
    } else {
      return '';
    }
  }

  updateDropdownItems() {
    const containerElement = this.container.nativeElement;
    const containerRect = containerElement.getBoundingClientRect();

    let itemDropdownLeftOffset = 0;
    const dropdownItemIds: string[] = [];

    this.lastItemButtonBeforeDropdownID = null;
    this.lastItemButtonBeforeDropdownWidth = null;

    const itemButtons = Array.from(containerElement.querySelectorAll<HTMLElement>('.item-button'));
    const lastItemButtonId = itemButtons.length
      ? itemButtons[itemButtons.length - 1].dataset.value
      : null;

    itemButtons.forEach((item, index) => {
      item.style.width = 'auto';
      const itemRect = item.getBoundingClientRect();
      const itemId = item.dataset.value;

      if (itemRect.right <= (containerRect.right - this.itemHeight - MARGIN_BETWEEN_ITEMS)) {
        itemDropdownLeftOffset = itemRect.right - containerRect.left;

      } else if (itemRect.left > containerRect.right) {
        dropdownItemIds.push(itemId);

      } else {
        const isLastItem = lastItemButtonId === itemId;
        const extraWidthForDropdownButton = isLastItem ? 0 : (this.itemHeight + MARGIN_BETWEEN_ITEMS);

        const remainingWidth = containerRect.right - itemRect.left - extraWidthForDropdownButton;
        if (remainingWidth >= MIN_ITEM_BUTTON_WIDTH) {
          this.lastItemButtonBeforeDropdownWidth = remainingWidth;
          this.lastItemButtonBeforeDropdownID = itemId;
          itemDropdownLeftOffset = itemRect.left - containerRect.left + remainingWidth;
        } else {
          dropdownItemIds.push(itemId);
        }
      }
    });

    this.dropdownButtonLeftOffset = itemDropdownLeftOffset + MARGIN_BETWEEN_ITEMS;
    this.dropdownItemIds = dropdownItemIds;

    this.changeDetectorRef.detectChanges();
  }

  updateLayout() {
    requestAnimationFrame(() => {
      this.updateDropdownItems();
    });
  }
}

const MIN_ITEM_BUTTON_WIDTH = 100;
const MARGIN_BETWEEN_ITEMS = 5;

export interface PplItemsChooserOption {
  value: string;
  label: string;
  icon?: string;
  disabled?: string;
  error?: string;
  data?: any;
}
