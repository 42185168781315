<div class="container demo-content">
  <h2>Attachments</h2>
  <br>

  <h3>Components</h3>
  <h4>ppl-attachments</h4>

  <div>@Input() <b>title</b>: string | null = null; // null is default Documents (translated)</div>
  <div>@Input() <b>files</b>: PplAttachment[]</div>
  <div>@Input() <b>fileSizeLimit</b> = 30</div>
  <div>@Input() <b>fileTypeLimit</b>: PplAttachmentFileTypeLimit</div>
  <div>@Input() <b>singleSelection</b> = false</div>
  <div>@Input() <b>hasHeader</b> = true</div>
  <div>@Input() <b>emptyLang</b> = 'No Documents linked'</div>
  <div>@Input() <b>attachmentsLang</b> = 'Attachments'</div>
  <div>@Input() <b>readonly</b> = false</div>
  <div>@Input() <b>accept</b>: string | null = null</div>
  <div>@Input() <b>disableUploadClick</b> = false</div>
  <div>@Input() <b>inlineAddButton</b> = false</div>
  <div>@Input() <b>preserveOriginalFile</b> = false</div>
  <br>

  <div>@Output() <b>filesChange</b>: PplAttachment[]</div>
  <div>@Output() <b>unsupportedAttachment</b>: PplAttachmentUnsupportedInfo</div>
  <div>@Output() <b>addFilesClick</b></div>
  <br>
  <br>

  <h3>Example</h3>
  <h4>Spread (without header)</h4>
  <ppl-attachments [hasHeader]="false"
                   [files]="files1"
                   [fileTypeLimit]="fileTypeLimit"
                   (filesChange)="files1 = $event"
                   (unsupportedAttachment)="onUnsupportedAttachment($event)"></ppl-attachments>
  <br>
  <br>
  <h4>Spread (without header) + readonly</h4>
  <ppl-attachments [hasHeader]="false"
                   [files]="files1"
                   [fileTypeLimit]="fileTypeLimit"
                   [readonly]="true"
                   (filesChange)="files1 = $event"
                   (unsupportedAttachment)="onUnsupportedAttachment($event)"></ppl-attachments>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
