import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PplDialogRef } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-report-expired',
  templateUrl: './report-expired.component.html',
  styleUrls: ['./report-expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportExpiredComponent {

  constructor(
    private dialogRef: PplDialogRef<ReportExpiredComponent>
  ) {}

  onReload() {
    window.location.reload();
  }

  onCancel() {
    this.dialogRef.close();
  }

}
