import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ppl-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class PplBottomBarComponent {

  @HostBinding('class.ppl-bottom-bar--is-open') @Input() isOpen = false;

}
