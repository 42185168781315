import { CheckboxListComponent } from './checkbox-list/checkbox-list.component';
import { PplCheckboxComponent } from './checkbox.component';
import { PplMultiCheckboxComponent } from './multi-checkbox/multi-checkbox.component';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

const PPL_MODULE_DECLARATIONS = [
  PplCheckboxComponent,
  PplMultiCheckboxComponent,
  CheckboxListComponent
];

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplInputModule
  ],
  declarations: [
    ...PPL_MODULE_DECLARATIONS
  ],
  exports: [
    ...PPL_MODULE_DECLARATIONS
  ]
})
export class PplCheckboxModule {
}

export * from './checkbox.component';
