import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplBadgeCountComponent } from './badge-count.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PplBadgeCountComponent],
  exports: [PplBadgeCountComponent]
})
export class PplBadgeCountModule {}

export * from './badge-count.component';
