import { PplInputIntl } from '../input-intl';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  NgZone,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, notFirstChange, roundToDecimal } from '@ppl/utils';
import IMask from 'imask/esm/imask';
import 'imask/esm/masked/number';
import type {
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import type {
  TSimpleChanges
} from '@ppl/utils';

@Component({
  selector: 'ppl-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplInputNumberComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplInputNumberComponent implements AfterViewInit, OnDestroy {
  @Input() value: number;
  @Input() placeholder = '';
  @Input() min: number;
  @Input() max: number;
  @Input() decimal = true;
  @Input() allowLeadingZeroes = false;
  @Input() allowNull = false;
  @Input() allowNegative = true;
  @Input() roundTo = 4;
  @Input() height: string | false;
  @Input() prependIcon: string;
  @Input() prependText: string;
  @Input() clear = false;
  @Input() clearIcon?: string;
  @Input() autoFill = false;
  @Input() autoFocus = false;
  // masks input based on inputIntl. Ignored in case of 'default'
  @Input() mask: InputNumberMask = 'default';

  @Input() @HostBinding('class.controls') controls = true;
  @Input() @HostBinding('class.has-unit') unit: string;
  @Input() @HostBinding('class.disabled') disabled = false;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() @HostBinding('class.left') alignLeft = true;

  @Output() valueChange = new EventEmitter<number>();
  @Output() clearClick = new EventEmitter();
  @Output() enter = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  @ViewChild('valueInput', { static: true }) valueInput: ElementRef;

  inputMask: IMask.InputMask<any>;

  Math = Math;

  @HostBinding('class.has-prepend-icon')
  get hasIcon() {
    return !!this.prependIcon;
  }

  get maskOptions() {
    return getMaskOptions(this.inputIntl, this.mask, this.roundTo, this.decimal);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private inputIntl: PplInputIntl,
    private ngZone: NgZone
  ) { }

  ngOnChanges(changes: TSimpleChanges<PplInputNumberComponent>) {
    if (changes.value) {
      if (this.inputMask?.typedValue as number !== this.value) {
        this.setInputValue();
        this.inputMask?.updateValue();
        this.inputMask?.updateControl();
      }
    }

    if (notFirstChange(changes.roundTo)) {
      this.inputMask.updateOptions({
        scale: this.maskOptions.scale
      });
    }
  }

  ngOnInit() {
    if (this.value !== undefined) {
      this.setInputValue();
      this.inputMask?.updateValue();
    }

    if (this.autoFocus) {
      this.valueInput.nativeElement?.focus();
    }
  }

  ngAfterViewInit(): void {
    const formatOptions = this.maskOptions;

    // configuration: https://imask.js.org/guide.html#masked-number
    this.inputMask = IMask(this.valueInput.nativeElement, {
      mask: Number,  // enable number mask
      signed: this.allowNegative,  // disallow negative
      scale: formatOptions.scale,
      thousandsSeparator: formatOptions.thousandsSeparator,  // any single char
      radix: formatOptions.radix,  // fractional delimiter
      mapToRadix: ['.', ','], // symbols to process as radix
      min: this.min,
      max: this.max
    });
    this.inputMask.on('accept', () => {
      let value = this.inputMask.typedValue as number;

      if (this.inputMask.value === '') {
        if (this.allowNull) {
          value = null;
        } else {
          value = 0;
        }
      }

      if (this.roundToDecimal(this.sanitizeValue(true)) !== value) {
        this.ngZone.run(() => {
          this.valueChange.emit(value);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.inputMask?.destroy();
  }

  onBumpValue(diff: number) {
    if (!this.disabled && !this.readonly) {
      const value = this.sanitizeValue();
      let newValue = value + diff;

      if (this.decimal) {
        newValue = +newValue.toFixed(this.roundTo);
      }
      if ((this.min === undefined || this.min <= newValue) && (this.max === undefined || newValue <= this.max)) {
        this.valueChange.emit(newValue);
      }
    }
  }

  onKeyDown($event: KeyboardEvent) {
    switch ($event.key) {
      case 'Enter':
        this.enter.emit();
        return false;
      case 'Up':
      case 'ArrowUp':
        this.onBumpValue(1);
        $event.preventDefault();
        return false;
      case 'Down':
      case 'ArrowDown':
        this.onBumpValue(-1);
        $event.preventDefault();
        return false;
    }
  }

  setFocus() {
    this.valueInput.nativeElement?.focus();
  }

  private setInputValue() {
    this.valueInput.nativeElement.value = this.roundToDecimal(this.value);
  }

  // this sanitizes the value back to a number safely
  // in case of string/null or something else
  private sanitizeValue(
    nullFriendly = false // if sanitize can return null
  ): number {
    if (this.value === null) {
      return nullFriendly ? null : 0;
    } else if (this.value == 0) { // this is basically check for empty string https://stackoverflow.com/a/825466
      return 0;
    } else if (typeof this.value === 'string') {
      try {
        const parsedValue = parseFloat(this.value);
        return isNaN(parsedValue) ? 0 : parsedValue;
      } catch (e) {
        return 0;
      }
    } else if (!isNaN(this.value)) {
      return this.value;
    } else {
      return 0;
    }
  }

  private roundToDecimal(value: number | null) {
    return value === null
      ? value
      : roundToDecimal(this.value, this.roundTo);
  }
}

export type InputNumberMask = 'default' | 'currency' | 'decimal' | 'integer';

function getMaskOptions(inputIntl: PplInputIntl, format: InputNumberMask, roundTo: number, decimal: boolean): {
  thousandsSeparator: string;
  radix: string;
  scale: number;
} {
  switch (format) {
    case 'default':
      // no special format options except for radix(decimal char) & scale is forwarded
      return {
        thousandsSeparator: '',
        radix: inputIntl.decimal,
        // digits after point, 0 for integers
        scale: decimal ? roundTo : 0
      };
    case 'decimal':
    case 'currency':
      return {
        thousandsSeparator: inputIntl.group,
        radix: inputIntl.decimal,
        // digits after point, 0 for integers
        scale: decimal ? roundTo : 0
      };
    case 'integer':
      return {
        thousandsSeparator: inputIntl.group,
        radix: undefined,
        scale: 0
      };
  }
}
