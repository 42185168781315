import { CallingConversationItemComponent } from './components/calling-conversation-item/calling-conversation-item.component';
import { CallingPhoneDeleteSoftMessageComponent } from './components/calling-phone-delete-soft-message/calling-phone-delete-soft-message.component';
import { CallingPhoneEditDialogComponent } from './components/calling-phone-edit-dialog/calling-phone-edit-dialog.component';
import { CallingPhoneEditComponent } from './components/calling-phone-edit/calling-phone-edit.component';
import { CallingPhoneGridComponent } from './components/calling-phone-grid/calling-phone-grid.component';
import { CallingPhonesComponent } from './components/calling-phones/calling-phones.component';
import { CallingPurchasePhoneNotAllowedMessageComponent } from './components/calling-purchase-phone-not-allowed-message/calling-purchase-phone-not-allowed-message.component';
import { CallingPurchaseWizardDialogComponent } from './components/calling-purchase-wizard-dialog/calling-purchase-wizard-dialog.component';
import { CallingSendMassTextMessageAnimationComponent } from './components/calling-send-mass-text-message-animation/calling-send-mass-text-message-animation.component';
import { CallingTextMessageCharacterCounterComponent } from './components/calling-text-message-character-counter/calling-text-message-character-counter.component';
import { CallingTextMessageCommunicationBoxComponent } from './components/calling-text-message-communication-box/calling-text-message-communication-box.component';
import { CallingTextMessageConversationListComponent } from './components/calling-text-message-conversation-list/calling-text-message-conversation-list.component';
import { CallingTextMessageListComponent } from './components/calling-text-message-list/calling-text-message-list.component';
import { CallingUnsubscribedDialogComponent } from './components/calling-unsubscribed-dialog/calling-unsubscribed-dialog.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PplComponentsAnimationsModule } from '@ppl/components/animations';
import { PplComponentsGridModule } from '@ppl/components/grid';
import { PplComponentsInputPhoneNumberModule } from '@ppl/components/input-phone-number';
import { PplComponentsStepsModule } from '@ppl/components/steps';
import { I18nModule } from '@ppl/i18n';
import { PplAvatarModule } from '@ppl/ui/avatar';
import { PplButtonModule } from '@ppl/ui/button';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplContentBlockModule } from '@ppl/ui/content-block';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplFormModule } from '@ppl/ui/form';
import { PplIconModule } from '@ppl/ui/icon';
import { PplInfoBoxModule } from '@ppl/ui/info-box';
import { PplInputModule } from '@ppl/ui/input';
import { PplLoadingModule } from '@ppl/ui/loading';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverTooltipModule } from '@ppl/ui/popover-tooltip';
import { PplScreenMessageModule } from '@ppl/ui/screen-message';
import { PplSearchModule } from '@ppl/ui/search';
import { PplSelectModule } from '@ppl/ui/select';
import { PplUiToolbarModule } from '@ppl/ui/toolbar';
import { PplVirtualScrollModule } from '@ppl/ui/virtual-scroll';
import { AuthModule } from '@ppl/auth';


const pplUiModules = [
  PplDialogModule,
  PplUiToolbarModule,
  PplIconModule,
  PplLoadingModule,
  PplInputModule,
  PplContentBlockModule,
  PplSelectModule,
  PplScreenMessageModule,
  PplButtonModule,
  PplSearchModule,
  PplInfoBoxModule,
  PplCheckboxModule,
  PplAvatarModule,
  PplVirtualScrollModule,
  PplPopoverModule,
  PplPopoverTooltipModule,
  PplFormModule
];

const pplComponentModules = [
  PplComponentsStepsModule,
  PplComponentsGridModule,
  PplComponentsInputPhoneNumberModule,
  PplComponentsAnimationsModule
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...pplUiModules,
    ...pplComponentModules,
    I18nModule,
    AuthModule
  ],
  declarations: [
    CallingPurchaseWizardDialogComponent,
    CallingPhoneGridComponent,
    CallingPhonesComponent,
    CallingPhoneEditDialogComponent,
    CallingPhoneEditComponent,
    CallingPurchasePhoneNotAllowedMessageComponent,
    CallingPhoneDeleteSoftMessageComponent,
    CallingUnsubscribedDialogComponent,
    CallingConversationItemComponent,
    CallingTextMessageConversationListComponent,
    CallingSendMassTextMessageAnimationComponent,
    CallingTextMessageCharacterCounterComponent,
    CallingTextMessageCommunicationBoxComponent,
    CallingTextMessageListComponent
  ],
  exports: [
    CallingPhoneGridComponent,
    CallingPhonesComponent,
    CallingConversationItemComponent,
    CallingTextMessageConversationListComponent,
    CallingSendMassTextMessageAnimationComponent,
    CallingTextMessageCharacterCounterComponent,
    CallingTextMessageCommunicationBoxComponent
  ]
})
export class CallingSharedModule { }
