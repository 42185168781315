<div class="container demo-content">
  <h2>
    Infinite Scroll
  </h2>
  <br>
  <br>
  <br>
  <div pplInfiniteScroll
       (pplInfiniteScrollLoadMore)="onLoadMore()"
       style="height: 70vh; overflow-y: auto;">
    <div *ngFor="let number of data"
         style="display: block; clear: both;">
      {{number}}
    </div>
    <div style="display: block; clear: both;">
      ... loading more ...
    </div>
  </div>
</div>
