import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-app-container-demo',
  templateUrl: './app-container-demo.component.html',
  styleUrls: ['./app-container-demo.component.scss']
})
export class AppContainerDemoComponent implements OnInit {
  code = `
    <ppl-top-bar #topBar title="Pipeliner-ng-ui"></ppl-top-bar>

    <ppl-app-container>
      content
    </ppl-app-container>

    <ppl-bottom-bar #bottombar>
      <button pplBottomBarButton>Menu 1</button>
      <button pplBottomBarButton>Menu 2</button>
    </ppl-bottom-bar>
  `;

  constructor() {
  }

  ngOnInit() {
  }

}
