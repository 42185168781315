import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  NgZone,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribe } from '@ppl/utils';
import { delay } from 'rxjs/operators';
import type {
  AfterContentInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import type { Subscription } from 'rxjs';

@Component({
  selector: 'ppl-tab',
  templateUrl: './tab/tab.component.html',
  styleUrls: ['./tab/tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PplTabComponent implements OnChanges, OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() link: string;
  @Input() name?: string; // optional, used to manually open a tab if needed
  @HostBinding('class.selected') @Input() selected = false;
  @Input() tabSwitch: boolean | null = null;
  @Input() error?: boolean;
  @Input() badgeTemplate: TemplateRef<any>;

  visible = true;
  url: string;

  get urlTree() {
    return this.router.createUrlTree([this.link], { relativeTo: this.activatedRoute });
  }

  constructor(
    @Inject(forwardRef(() => PplTabsComponent)) private tabsContainer: PplTabsComponent,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['tabSwitch'] && !changes['tabSwitch'].isFirstChange() && changes['tabSwitch'].currentValue !== changes['tabSwitch'].previousValue)
      || (changes['error'] && !changes['error'].isFirstChange() && changes['error'].currentValue !== changes['error'].previousValue)
    ) {
      this.tabsContainer.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit() {
    if (!!this.link) {
      this.url = this.urlTree.toString();
    }
  }
}

@Component({
  selector: 'ppl-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplTabsComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  @Input() routable = false;
  @Input() largeIcons = false;
  @Input() collapseTabs = false;
  @Input() tabSwitchColor?: string;
  @Input() tabsSuffix?: TemplateRef<any>;

  @ContentChildren(PplTabComponent) tabs: QueryList<PplTabComponent>;
  @ViewChildren('anchorTab') tabAnchors: QueryList<ElementRef>;
  @ViewChild('tabAnchors', { static: false }) tabAnchorsContainer: ElementRef;
  @Output() tabChange = new EventEmitter<string>();

  buttonOverflowOffset = 0;

  private tabsChangesListener: Subscription;
  resizeObserver: ResizeObserver;

  get displayOverflowButton() {
    return this.tabs && this.tabs.toArray().some(tab => !tab.visible);
  }

  get isOverflowedTabActive() {
    if (this.routable) {
      return this.tabs && this.tabs.toArray().some(tab => !tab.visible && this.router.isActive(tab.url, true));
    } else {
      return this.tabs && this.tabs.toArray().some(tab => !tab.visible && tab.selected);
    }
  }

  get tabAnchorsSorted() {
    const anchors: ElementRef<HTMLElement>[] = this.tabAnchors
      .toArray()
      // this query list is not properly sorted (new elements appear at the end even if they reside elsewhere)
      .sort((a, b) => getElementIndex(a.nativeElement) - getElementIndex(b.nativeElement));
    return anchors;
  }

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.tabsChangesListener = this.tabs.changes.pipe(delay(1)).subscribe(() => this.updateTabsVisibility());

    const selectedTab = this.tabs.find(tab => tab.selected);
    if (!selectedTab && this.tabs.first) {
      this.selectTab(this.tabs.first);
    }
  }

  ngAfterViewInit(): void {
    this.updateTabsVisibility();

    this.resizeObserver = new ResizeObserver(() => {
      this.ngZone.run(() => {
        this.updateTabsVisibility();
      });
    });

    this.resizeObserver.observe(this.tabAnchorsContainer.nativeElement);
  }

  ngOnDestroy() {
    unsubscribe(this.tabsChangesListener);
    this.resizeObserver?.disconnect();
  }

  selectTabByName(name: string) {
    this.tabs.toArray().forEach(foundTab => (foundTab.selected = foundTab.name === name));
    this.changeDetectorRef.markForCheck();
    // this.changeDetectorRef.detectChanges();
  }

  trackTabs(index, item: PplTabComponent) {
    return item.label;
  }

  onTabClick(index: number) {
    const tab = this.tabAnchorsSorted[index];
    if (tab && tab.nativeElement) {
      (tab.nativeElement as HTMLElement).click();
    }
  }

  isLinkActive(url: string | string[]) {
    const queryParamsIndex = this.router.url.indexOf('?');
    const queryFragmentsIndex = this.router.url.indexOf('#');
    const stripToIndex = queryParamsIndex > -1 ? queryParamsIndex : (queryFragmentsIndex > -1 ? queryFragmentsIndex : -1);
    const baseUrl = stripToIndex === -1 ? this.router.url : this.router.url.slice(0, stripToIndex);
    const processedUrl = this.router.createUrlTree(
      Array.isArray(url) ? url : [url],
      {
        relativeTo: this.activatedRoute
      }
    ).toString();
    return baseUrl === processedUrl;
  }

  selectTab(tab: PplTabComponent) {
    this.tabs.toArray().forEach(foundTab => (foundTab.selected = false));
    tab.selected = true;
    this.tabChange.emit(tab.name);
  }

  private updateTabsVisibility() {
    if (!this.collapseTabs) {
      this.changeDetectorRef.detectChanges();
      return;
    }

    const container = this.tabAnchorsContainer.nativeElement as Element;
    const anchors = this.tabAnchorsSorted.map(anchor => anchor.nativeElement);

    let hasHiddenButtons = false;
    const clientWidth = container.clientWidth - overflowButtonWidth;
    let endWidth = 0;

    this.tabs.toArray().forEach((tab, index) => {
      const buttonWidth = anchors[index].offsetWidth + parseInt(getComputedStyle(anchors[index]).marginLeft, 10);
      endWidth += buttonWidth;

      const visible = endWidth <= clientWidth;
      if (!visible && !hasHiddenButtons) {
        this.buttonOverflowOffset = endWidth - buttonWidth;
        hasHiddenButtons = true;
      }

      tab.visible = visible;
    });

    this.changeDetectorRef.detectChanges();
  }
}

const overflowButtonWidth = 98;

function getElementIndex(element: HTMLElement) {
  return Array.from(element.parentNode.children).indexOf(element);
}
