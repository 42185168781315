<div class="icon-container">
  <ppl-icon [svgIcon]="icon" width="70px" height="70px"></ppl-icon>
</div>
<div class="content">
  <span class="title" [title]="title">
    {{title}}
  </span>
  <span class="title-sub" [title]="subTitle">
    {{subTitle}}
  </span>
  <div>
    <ng-content></ng-content>
  </div>
</div>
