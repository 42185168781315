import { coerceBooleanProperty } from '@angular/cdk/coercion';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostBinding, Input , ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'ppl-button, button[pplButton], a[pplButton], input[pplButton], label[pplButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  exportAs: 'pplButton',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplButtonComponent implements OnInit {

  /** Whether the element is disabled. */
  @HostBinding('attr.disabled') @Input()
  get disabled() {
    return this._disabled || null;
  }

  set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  _disabled = false;

  constructor(
    private renderer: Renderer2,
    public elementRef: ElementRef // keep public, may be used in templates
  ) {
  }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button');
  }

  focus(): void {
    this.getHostElement().focus();
  }

  getHostElement() {
    return this.elementRef.nativeElement;
  }
}
