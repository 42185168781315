import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplPortalComponent } from './portal.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplPortalComponent
  ],
  exports: [
    PplPortalComponent
  ]
})
export class PplPortalModule {}

export * from './portal.component';
