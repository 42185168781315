import type { Environment } from './environment.interface';

const S3Prefix = 'showcase/devel/xf1LhmfWOgvea1GjN2YgNPFcqYXyDN';

export const environment: Environment = {
  name: 'devel',
  S3Prefix: S3Prefix,
  deployUrl: `https://static-webapps.pipelinersales.com/${S3Prefix}`,
  baseUrl: ``
};
