<div class="container demo-content">
  <h2>Resizable Directive</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-info-box</h4>
  <div>@Input() <b>pplResizableSize</b>: number</div>
  <div>@Input() <b>pplResizableActive</b>: boolean</div>
  <div>@Input() <b>pplResizableOrientation</b>: 'horizontal' | 'vertical'</div>
  <div>@Input() <b>pplResizableSizeConstraints?</b>: PplResizableSizeConstraints </div>

  <br>

  <div>@Output() <b>pplResizableSizeChange</b>: number</div>

  <br>
  <br>

  <h3>Example</h3>

  <div class="r-container">
    <div class="r-sidebar"
         [style.width.px]="size">
      width: {{size}}px
    </div>
    <div class="r-handle"
         pplResizable
         [pplResizableSize]="size"
         [pplResizableSizeConstraints]="constraints"
         (pplResizableSizeChange)="size = $event"></div>
    <div class="r-content">
      content
    </div>
  </div>

  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
