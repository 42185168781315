import type {
  OnInit
} from '@angular/core';
import {
  Directive,
  Input
,
  ElementRef,
  Renderer2
} from '@angular/core';

@Directive({ selector: '[pplLink]' })
export class PplLinkDirective implements OnInit {
  @Input() pplLink: boolean;

  constructor(
    private elementRef: ElementRef,
    protected renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.pplLink !== false) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ppl-link');
    }
  }
}
