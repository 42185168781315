import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  Input,
  Optional,
  Output,
  Renderer2,
  SkipSelf,
  TemplateRef
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { applyFieldColorVariables, notFirstChange, unsubscribe } from '@ppl/utils';
import { tap } from 'rxjs/operators';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import type { Subscription } from 'rxjs';

@Component({
  selector: 'ppl-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss']
})
export class PplInputContainerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() label: string | null;
  @Input() description: string | null;
  @Input() bold: boolean;
  @Input() pplFormControl: AbstractControl | null;
  @Input() pplFormControlName: string | null;
  @Input() hasError = false;
  @Input() @HostBinding('class.ppl-input-container--changes') hasChanges = false;
  @Input() color: string;
  @Input() notice?: string;
  @Input() disabled = false;
  @Input() required: boolean;
  @Input() errors: any;
  @Input() showErrors = true;
  @Input() customErrorMessages: { [key: string]: string; };
  @Input() labelContent?: TemplateRef<any>;
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() descriptionHoverPreserve = false;
  @Input() @HostBinding('attr.field-id') fieldId: string;

  @ContentChild('noticeContent', { static: false }) noticeContent?: TemplateRef<any>;

  @Output() undoChanges = new EventEmitter();

  private className = 'ppl-input-container';
  private statusChangesSubscription: Subscription;

  get control() {
    if (this.pplFormControl) {
      return this.pplFormControl;
    }

    if (this.pplFormControlName) {
      const control = this.parent?.control?.get(this.pplFormControlName);
      if (control) {
        return control;
      } else {
        throw new Error(`pplFormControlName must be used with a parent formGroup directive`);
      }
    }

    return null;
  }

  @HostBinding('class.ppl-input-container--error')
  get hasErrorClass() {
    return this.hasError || (this.control && this.control.invalid && (this.control.dirty || this.control.touched));
  }

  get hasRequiredClass() {
    if (this.control && this.control.validator) {
      const validators = this.control.validator({} as AbstractControl);
      return validators && validators.hasOwnProperty('required');
    } else if (this.required === true) {
      return true;
    }
    return false;
  }

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Host() @SkipSelf() private parent: ControlContainer
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (notFirstChange(changes.color)) {
      applyFieldColorVariables(this.elementRef.nativeElement, this.renderer, this.color);
    }
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, this.className);
    applyFieldColorVariables(this.elementRef.nativeElement, this.renderer, this.color);
    this.statusChangesSubscription = this.control?.statusChanges?.pipe(
      tap(() => {
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    unsubscribe(this.statusChangesSubscription);
  }
}
