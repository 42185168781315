<ppl-loading *ngIf="loading"
             [inverse]="inverse"
             [large]="large"
             [withLogo]="withLogo"></ppl-loading>
<span class="text"
      *ngIf="text">
  {{text}}
</span>
<div class="content">
  <ng-content></ng-content>
</div>