import type {
  OnChanges,
  OnInit,
  SimpleChanges

} from '@angular/core';
import {
  Directive,
  Input
,
  ElementRef,
  Renderer2
} from '@angular/core';
import type { TSimpleChanges } from '@ppl/utils';
import { notFirstChange } from '@ppl/utils';

@Directive({
  selector: '[pplButtonSmall]'
})
export class PplButtonSmallDirective implements OnInit {
  @Input('pplButtonSmall') size: 'xs' | 'xxs' | '' | null;

  constructor(protected renderer: Renderer2,
    protected elementRef: ElementRef) {
  }

  ngOnInit() {
    switch (this.size) {
      case 'xs':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--small-xs');
        break;
      case 'xxs':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--small-xxs');
        break;
      default:
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--small');
        break;
    }
  }
}

@Directive({
  selector: '[pplButtonLarge]'
})
export class PplButtonLargeDirective implements OnInit {
  constructor(protected renderer: Renderer2,
    protected elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--large');
  }
}

@Directive({
  selector: '[pplButtonBold]'
})
export class PplButtonBoldDirective implements OnInit {
  constructor(protected renderer: Renderer2,
    protected elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--bold');
  }
}

@Directive({
  selector: '[pplButtonBlock]'
})
export class PplButtonBlockDirective implements OnInit {
  constructor(protected renderer: Renderer2,
    protected elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--block');
  }
}

@Directive({
  selector: '[pplButtonActive]'
})
export class PplButtonActiveDirective implements OnChanges, OnInit {
  @Input() pplButtonActive = true;

  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.pplButtonActive)) {
      this.toggleClass();
    }
  }

  ngOnInit() {
    this.toggleClass();
  }

  private toggleClass() {
    if (this.pplButtonActive) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--active');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'ppl-button--active');
    }
  }
}

@Directive({
  selector: '[pplButtonPressed]'
})
export class PplButtonPressedDirective implements OnChanges, OnInit {
  @Input() pplButtonPressed = true;

  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.pplButtonPressed)) {
      this.toggleClass();
    }
  }

  ngOnInit() {
    this.toggleClass();
  }

  private toggleClass() {
    if (this.pplButtonPressed) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--pressed');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'ppl-button--pressed');
    }
  }
}

@Directive({
  selector: '[pplButtonSelected]'
})
export class PplButtonSelectedDirective implements OnChanges, OnInit {
  @Input() pplButtonSelected = true;

  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.pplButtonSelected)) {
      this.toggleClass();
    }
  }

  ngOnInit() {
    this.toggleClass();
  }

  private toggleClass() {
    if (this.pplButtonSelected) {
      this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--selected');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'ppl-button--selected');
    }
  }
}

@Directive({
  selector: '[pplButton7]'
})
export class PplButton7Directive implements OnInit {
  @Input('pplButton7') buttonType: 'info' | 'warning' | 'danger' | 'error' | 'default' | 'white' | 'outline' = 'info';

  constructor(protected renderer: Renderer2,
    protected elementRef: ElementRef) {
  }

  ngOnInit() {
    this.setClass();
  }

  private setClass() {
    switch (this.buttonType) {
      case 'info':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-info');
        break;
      case 'warning':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-warning');
        break;
      case 'danger':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-danger');
        break;
      case 'error':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-error');
        break;
      case 'default':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-default');
        break;
      case 'white':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-white');
        break;
      case 'outline':
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-outline');
        break;
      default:
        this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--7-info');
        break;
    }
  }
}

@Directive({
  selector: '[pplButtonDialog]'
})
export class PplButtonDialogDirective implements OnInit {
  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--dialog');
  }
}

@Directive({
  selector: '[pplButtonAlert]'
})
export class PplButtonAlertDirective implements OnInit {
  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--alert');
  }
}

@Directive({
  selector: '[pplButtonFocusable]'
})
export class PplButtonFocusableDirective implements OnInit {
  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--focusable');
  }
}

@Directive({
  selector: '[pplButtonSave]'
})
export class PplButtonSaveDirective implements OnInit {
  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--save');
  }
}

@Directive({
  selector: '[pplButtonCancel]'
})
export class PplButtonCancelDirective implements OnInit {
  constructor(private renderer: Renderer2,
    private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'ppl-button--cancel');
  }
}

@Directive({
  selector: '[pplBlockButton]'
})
export class PplBlockButtonDirective implements OnInit {
  constructor(private renderer: Renderer2, private elementsRef: ElementRef) { }

  ngOnInit() {
    this.renderer.addClass(this.elementsRef.nativeElement, 'ppl-button--block');
    this.renderer.addClass(this.elementsRef.nativeElement, 'ppl-button--block-default');
  }
}

export enum ButtonType {
  Button1 = '1',
  Button2 = '2',
  Button3 = '3',
  Button4 = '4',
  Button5 = '5',
  Button6 = '6',
  Button7Info= 'info',
  Button7Warning = '7-warning',
  Button7Danger = '7-danger',
  Button7Error = '7-error',
  Button7Default = '7-default',
  Button7White = '7-white',
  Button7Outline = '7-outline',
}

@Directive()
abstract class PplButtonBaseDirective implements OnChanges, OnInit {
  abstract type: ButtonType;

  constructor(
    protected renderer: Renderer2,
    protected elementRef: ElementRef
  ) { }

  ngOnChanges(changes: TSimpleChanges<Pick<PplButtonBaseDirective, 'type'>>): void {
    if (notFirstChange(changes.type)) {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        this.getClassName(changes.type.previousValue)
      );
      this.renderer.addClass(
        this.elementRef.nativeElement,
        this.getClassName(changes.type.currentValue)
      );
    }
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, this.getClassName(this.type));
  }

  getClassName(type: ButtonType) {
    return `ppl-button--${type}`;
  }
}

@Directive({
  selector: '[pplButtonType]'
})
export class PplButtonTypeDirective extends PplButtonBaseDirective {
  @Input('pplButtonType') type: ButtonType;
}

@Directive({
  selector: '[pplButton1]'
})
export class PplButton1Directive extends PplButtonBaseDirective {
  type = ButtonType.Button1;
}

@Directive({
  selector: '[pplButton2]'
})
export class PplButton2Directive extends PplButtonBaseDirective {
  type = ButtonType.Button2;
}

@Directive({
  selector: '[pplButton3]'
})
export class PplButton3Directive extends PplButtonBaseDirective {
  type = ButtonType.Button3;
}

@Directive({
  selector: '[pplButton4]'
})
export class PplButton4Directive extends PplButtonBaseDirective {
  type = ButtonType.Button4;
}

@Directive({
  selector: '[pplButton5]'
})
export class PplButton5Directive extends PplButtonBaseDirective {
  type = ButtonType.Button5;
}

@Directive({
  selector: '[pplButton6]'
})
export class PplButton6Directive extends PplButtonBaseDirective {
  type = ButtonType.Button6;
}
