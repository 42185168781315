<div class="open-btn-bg"
     *ngIf="showContent"></div>
<div class="header"
     [class.clickable]="showContent"
     [class.open]="open"
     (mouseover)="onHoverChange(true)"
     (mouseleave)="onHoverChange(false)"
     (click)="toggleOpen()">
  <div class="header-icon">
    <ppl-icon [svgIcon]="icon"
              width="60px"
              height="60px"></ppl-icon>
  </div>
  <div class="header-content">
    <span class="header-title"
          [title]="title"
          *ngIf="title">
      <ng-container *ngIf="loading">
        <div class="loader loader-title"></div>
      </ng-container>
      <ng-container *ngIf="!loading">
        {{title}}
      </ng-container>
    </span>
    <span class="header-sub-title"
          [title]="subTitle"
          *ngIf="subTitle">
      <ng-container *ngIf="loading">
        <div class="loader loader-subtitle"></div>
      </ng-container>
      <ng-container *ngIf="!loading">
        {{subTitle}}
      </ng-container>
    </span>
  </div>
  <div class="header-btn"
       *ngIf="showContent">
    <ppl-icon svgIcon="rowline"
              [useSvg]="true"
              width="10px"
              height="10px"></ppl-icon>
  </div>
</div>
<div class="content"
     [class.completly-ejected]="contentCompletlyEjected$ | async"
     [style.max-height.px]="maxHeightPx"
     [style.overflow.visible]="open">
  <div #contentContainer
       class="content-container">
    <ng-container *ngIf="contentVisible">
      <ng-content></ng-content>
    </ng-container>
  </div>
</div>
