<div class="container">
  <div class="container-text">
    <ppl-icon svgIcon="info-completed"
              width="18px"
              height="18px"></ppl-icon>
    <p>{{message}}</p>
  </div>
  <div class="container-actions">
    <button pplButton
            pplButton4
            pplButtonSmall
            (click)="onActionClicked()">{{actionText}}</button>
  </div>
</div>
