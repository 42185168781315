import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplInfoBoxComponent } from './info-box.component';
import { PplIconModule } from '../icon';
import { PplButtonModule } from '../button';


@NgModule({
    imports: [
        CommonModule,
        PplIconModule,
        PplButtonModule
    ],
    declarations: [
        PplInfoBoxComponent
    ],
    exports: [
        PplInfoBoxComponent
    ]
})
export class PplInfoBoxModule {
}

export * from './info-box.component';
