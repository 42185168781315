export const TranslationsFormControlErrors = {
  invalidEmailFormatError: $localize`:@@invalidEmailFormatError:Invalid E-mail format!`,
  invalidUrlFormatError: $localize`:@@invalidUrlFormatError:Invalid URL format!`,
  invalidOptionSelectedError: $localize`:@@invalidOptionSelectedError:Invalid option selected!`,
  ownerNotHaveAccessSalesUnitError: $localize`:@@ownerNotHaveAccessSalesUnitError:Entity owner does not have an access to selected sales unit`,
  userNotMemberOfSalesUnitError: $localize`:@@userNotMemberOfSalesUnitError:User is not a member of selected sales unit!`,
  invalidMinimalValueError: $localize`:@@invalidMinimalValueError:Invalid minimal value`,
  invalidMinDateError: $localize`:@@invalidMinDateError:Invalid date value, min date\:`,
  invalidMaxDateError: $localize`:@@invalidMaxDateError:Invalid date value, max date\:`,
  invalidValueRangeError: $localize`:@@invalidValueRangeError:Invalid value, must be from range <`,
  invalidFieldValueError: $localize`:@@invalidFieldValueError:Invalid field value\:`,
  invalidRequiredPatternError: $localize`:@@invalidRequiredPatternError:Invalid value, required pattern\:`,
  invalintIntegerValueError: $localize`:@@invalintIntegerValueError:Invalid integer value`,
  invalidNumberValueError: $localize`:@@invalidNumberValueError:Invalid number value`,
  minHasToBeSmallerThanMaxError: $localize`:@@minHasToBeSmallerThanMaxError:Min. value has to be smaller than max. value`,
  atLeastOneSelectedError: $localize`:@@atLeastOneSelectedError:At least one option must be selected!`,
  invalidPasswordError: $localize`:@@invalidPasswordError:Invalid password\: Password must contain at least one lower case character, one upper case character and one number character!`,
  invalidLengthError: $localize`:@@invalidLengthError:Invalid length. Max length is`,
  invalidMinLengthError: $localize`:@@invalidMinLengthError:Invalid length. Min length is`,
  actualLength: $localize`:@@actualLength:actual length\:`,
  highlightedProductsAreNotAvailableError: $localize`:@@highlightedProductsAreNotAvailableError:Highlighted products are not available on this pipeline anymore`,
  highlightedCurrencyIsNotAvailableError: $localize`:@@highlightedCurrencyIsNotAvailableError:Highlighted currency is not available anymore`,
  highlightedPriceListIsNotAvailableError: $localize`:@@highlightedPriceListIsNotAvailableError:Highlighted price list is not available anymore`,
  thisFieldIsRequiredError: $localize`:@@thisFieldIsRequiredError:This field is required`,
  firstOrLastNameRequiredError: $localize`:@@firstOrLastNameRequiredError:First or last name is required`,
  Phone_number_is_invalid: $localize`:@@Phone_number_is_invalid:Phone number is invalid`,
  Phone_number_country_is_invalid: $localize`:@@Phone_number_country_is_invalid:Phone number country is invalid`,
  Not_a_phone_number: $localize`:@@Not_a_phone_number:Not a phone number`,
  The_phone_number_is_too_short: $localize`:@@The_phone_number_is_too_short:The phone number is too short`,
  The_phone_number_is_too_long: $localize`:@@The_phone_number_is_too_long:The phone number is too long`
};
