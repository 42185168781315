import type { TemplateRef } from '@angular/core';

export interface GridAction {
  readonly id: string;
  readonly icon: string;
  readonly text: string;
  readonly enabled: boolean | ((record: any) => boolean);
  readonly tooltipEnabled?: boolean;
}

export interface GridActionClickEvent {
  readonly action: GridAction;
  readonly record: GridRecord;
}

export interface GridAvailableColumn {
  readonly categoryId?: string;
  readonly description?: string;
  readonly draggable?: boolean;
  readonly headerTemplate?: TemplateRef<any>;
  readonly hideName?: boolean;
  readonly id: string;
  readonly maxWidth?: number;
  readonly minWidth?: number;
  readonly name: string;
  readonly removeDisabled?: boolean;
  readonly searchable?: boolean;
  readonly sortable?: boolean;
  readonly stretch?: boolean;
  readonly titleTemplate?: TemplateRef<any>;
  readonly type?: string;
  readonly typeOptions?: any;
}

export interface GridAvailableColumnCategory {
  readonly id: string;
  readonly name: string;
}

export interface GridColumn {
  readonly frozen?: boolean;
  readonly id: string;
  readonly pixels?: boolean;
  readonly width: number;
}

export interface GridRecord {
  readonly id: string;
}

export interface GridRowOrderChange {
  source: GridRowOrderChangeSource;
  target: GridRowOrderChangeTarget;
}

export type GridRowOrderChangeSource = {
  kind: GridRowOrderChangeKind.Record;
  ids: string[];
} | {
  kind: GridRowOrderChangeKind.Section;
  id: string;
};

export type GridRowOrderChangeTarget = {
  kind: GridRowOrderChangeKind.Record;
  id: string | null;
  sectionId: string | null;
} | {
  kind: GridRowOrderChangeKind.Section;
  id: string;
};

export enum GridRowOrderChangeKind {
  Section = 'Section',
  Record = 'Record'
}

export interface GridFooterItem {
  readonly id: string;
  readonly name: string;
  readonly data: { [id: string]: any };
}

export interface GridSelection {
  readonly all: boolean;
  readonly include: any[];
  readonly exclude: any[];
}

export interface GridSelectionRequest {
  readonly startId: string;
  readonly stopId: string;
}

export interface GridSort {
  readonly id: string;
  readonly direction: number;
}

// Note: For internal use
export interface GridInternalColumnData {
  readonly offset: number;
  readonly template: TemplateRef<any> | null;
}
