import { createChunks } from './array.utils';
import type {
  BehaviorSubject,
  Observable
} from 'rxjs';
import {
  concat,
  defer,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  map,
  tap,
  toArray
} from 'rxjs/operators';
import type { GridColumnsOutput } from '@ppl/graphql-space-api';
import type { GridColumn } from '@ppl/ui/grid';

export function gridSortToApiSort(sort: { id: string, direction: number }[]) {
  return {
    sortBy: sort[0].id,
    sortOrder: (sort[0].direction === -1) ? 'Asc' : 'Desc'
  };
}

export function apiSortToGridSort(sortBy: string, sortOrder: string) {
  return [{
    id: sortBy,
    direction: sortOrder === 'Asc' ? -1 : 1
  }];
}

export function gridColumnsToApiColumns(columns: GridColumn[]) {
  return columns.map<GridColumnsOutput>(column => ({
    fieldId: column.id,
    frozen: column.frozen,
    width: column.width,
    pixels: column.pixels
  }));
}

export function apiColumnsToGridColumns(columns: GridColumnsOutput[]) {
  return columns.map<GridColumn>(column => ({
    id: column.fieldId,
    frozen: column.frozen,
    width: column.width,
    pixels: column.pixels
  }));
}

export function wrapAPICall<T>(
  observable: Observable<T>,
  progress: BehaviorSubject<boolean>,
  options?: { stopProgressOnError?: boolean }
) {
  return defer(() => {
    progress.next(true);
    return observable.pipe(
      catchError(error => {
        if (getOption(options, 'stopProgressOnError', true)) {
          progress.next(false);
        }
        return throwError(error);
      }),
      tap({
        complete: () => {
          progress.next(false);
        }
      })
    );
  });
}

export function getOption<T, K extends keyof T>(options: T, option: K, defaultValue: T[K]): T[K] {
  return options && option in options
    ? options[option]
    : defaultValue;
}

export function fetchInChunks<T>(
  ids: string[],
  fetchFn: (chunkIds: string[]) => Observable<T[]>,
  options?: { chunkSize?: number }
): Observable<T[]> {
  const chunkSize = options?.chunkSize || DEFAULT_CHUNK_SIZE;

  if (ids.length) {
    const chunks = createChunks(ids, chunkSize);
    return concat(
      ...chunks.map(chunk => fetchFn(chunk))
    ).pipe(
      toArray(),
      map(results => {
        return results.reduce((acc, chunk) => acc.concat(chunk), []);
      })
    );
  } else {
    return of([]);
  }
}

const DEFAULT_CHUNK_SIZE = 1000;
