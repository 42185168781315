import { PplLoadingEllipsisComponent } from './loading-ellipsis/loading-ellipsis.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { PplLoadingComponent } from './loading.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplLoadingComponent,
    LoadingOverlayComponent,
    PplLoadingEllipsisComponent
  ],
  exports: [
    PplLoadingComponent,
    LoadingOverlayComponent,
    PplLoadingEllipsisComponent
  ]
})
export class PplLoadingModule {
}

export * from './loading.component';
