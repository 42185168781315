import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef
,
  ChangeDetectorRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';
import { PplUiIntl } from '../ppl-ui-intl';

@Component({
  selector: 'ppl-partial-select',
  templateUrl: './partial-select.component.html',
  styleUrls: ['./partial-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplPartialSelectComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplPartialSelectComponent implements OnInit {
  @Input() value: PplPartialSelectValue;
  @Input() options: PplPartialSelectOption[];
  @Input() disabled = false;
  @Input() labelAll?: string;
  @Input() labelSelected?: string;
  @Input() optionsRendering: 'checkboxes' | 'multi-select' = 'checkboxes';
  @Input() displayLoading = false;

  @Output() valueChange = new EventEmitter<PplPartialSelectValue>();

  onTouched: any;
  onChange: any;

  get allChecked() {
    return this.value.all;
  }

  get selectedChecked() {
    return !this.value.all;
  }

  get showOptions() {
    switch (this.optionsRendering) {
      case 'checkboxes':
        return !!this.options?.length;
      case 'multi-select':
        return !this.value.all;
    }
  }

  get selectedValues() {
    return this.value.selectedValues;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private intl: PplUiIntl
  ) { }

  ngOnInit() {
    // init translations
    if (!this.labelAll) {
      this.labelAll = this.intl.partialSelectAll;
    }
    if (!this.labelSelected) {
      this.labelSelected = this.intl.partialSelectSelected;
    }
  }

  setAll() {
    const newValue: PplPartialSelectValue = {
      all: true,
      selectedValues: []
    };
    this.valueChange.emit(newValue);
  }

  setSelected() {
    if (!this.selectedChecked) {
      const newValue: PplPartialSelectValue = {
        all: false,
        selectedValues: []
      };
      this.valueChange.emit(newValue);
    }
  }

  optionChecked(value: string) {
    return this.value && this.value.selectedValues.includes(value);
  }

  onOptionChecked(value: string) {
    const newValue: PplPartialSelectValue = {
      all: false,
      selectedValues: this.optionChecked(value)
        ? this.value.selectedValues.filter(selectedValue => selectedValue !== value)
        : [...this.value.selectedValues, value]
    };
    this.valueChange.emit(newValue);
  }

  getColorValue(value: number | string) {
    if (typeof value === 'number') {
      return colorCodeToHex(value);
    } else {
      return value;
    }
  }

  trackOptions(index, item: PplPartialSelectOption) {
    return item.value;
  }

  onSelectedValuesChange($event: string[]) {
    const newValue: PplPartialSelectValue = {
      ...this.value,
      selectedValues: $event
    };
    this.valueChange.emit(newValue);
  }

}

export interface PplPartialSelectValue {
  all: boolean;
  selectedValues: string[];
}

export interface PplPartialSelectOption {
  value: string;
  label: string;
  color?: string | number;
  deleted?: boolean;
}

function colorCodeToHex(colorCode: number) {
  return `#${colorCode.toString(16).padStart(6, '0')}`;
}

export function partialSelectValidator(control) {
  const value = control.value as PplPartialSelectValue;
  return value && (value.all || !!value.selectedValues.length)
    ? null
    : {
      required: true
    };
}
