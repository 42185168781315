import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-graph-toggle-demo',
  templateUrl: './graph-toggle-demo.component.html',
  styleUrls: ['./graph-toggle-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphToggleDemoComponent implements OnInit {

  value = false;

  code = `
    <ppl-graph-toggle [value]="value"
                      (valueChange)="onValueChange($event)"></ppl-graph-toggle>
  `;

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(value: boolean) {
    this.value = value;
  }

}
