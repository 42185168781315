import type {
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output
,
  ChangeDetectorRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, notFirstChange } from '@ppl/utils';

const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

@Component({
  selector: 'ppl-editable',
  templateUrl: 'editable.component.html',
  styleUrls: ['editable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplEditableComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplEditableComponent implements OnChanges {
  @Input() value = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() iconSize = '16px';
  @Input() color: 'white' | 'gray' = 'gray';
  @HostBinding('style.fontSize') @Input() fontSize = '16px';
  @HostBinding('style.fontWeight') @Input() fontWeight = 'normal';
  @HostBinding('class.flex') @Input() flex?: boolean;

  @Output() valueChange = new EventEmitter<string>();

  isEditing = false;
  tempValue = '';

  @HostBinding('class.white')
  get whiteClass() {
    return this.color === 'white';
  }

  @HostBinding('class.gray')
  get grayClass() {
    return this.color === 'gray';
  }

  get checkIcon() {
    switch (this.color) {
      case 'gray':
        return 'checkmark-editable';
      case 'white':
        return 'checkmark-white-medium';
    }
  }

  get closeIcon() {
    switch (this.color) {
      case 'gray':
        return 'closethin-editable';
      case 'white':
        return 'closethin-white';
    }
  }

  get editIcon() {
    switch (this.color) {
      case 'gray':
        return 'edit-editable';
      case 'white':
        return 'edit-white';
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.value)) {
      this.cancelEdit();
    }
  }

  startEdit() {
    if (this.disabled) {
      return;
    }
    this.tempValue = this.value || '';
    this.isEditing = true;
  }

  confirmEdit() {
    this.setValue(this.tempValue);
    this.isEditing = false;
  }

  cancelEdit() {
    this.tempValue = this.value || '';
    this.isEditing = false;
  }

  setTempValue(value: string, key: string) {
    if (key === ENTER_KEY) {
      return this.confirmEdit();
    }
    if (key === ESCAPE_KEY) {
      return this.cancelEdit();
    }
    this.tempValue = value;
  }

  setValue(value: string) {
    this.valueChange.emit(value);
  }
}
