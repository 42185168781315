<div class="container"
     [class.horizontal]="direction === 'left' || direction === 'right'">
  <div *ngIf="direction === 'down' || direction === 'right'"
       [attr.class]="direction === 'down' ? 'content-vertical-space' : 'content-horizonal-space'"></div>

  <div class="content"
       [class.warning]="type === 'warning'"
       [class.error]="type === 'error'"
       [style.border-color]="backgroundColor">
    <svg *ngIf="connectToElement || hostElement"
         [attr.class]="'connector direction-' + direction"
         [ngStyle]="connectorStyle">
      <path *ngIf="direction === 'up'"
            d="M0,0 l10,0 l-5,5 Z"></path>
      <path *ngIf="direction === 'down'"
            d="M5,0 l5,5 l-10,0 Z"></path>
      <path *ngIf="direction === 'left'"
            d="m5,5 -5,5 V 0.5 Z"></path>
      <path *ngIf="direction === 'right'"
            d="m0,5 5,-5 V 10 Z"></path>
    </svg>
    <div class="text-holder"
         [class.preserve-whitespace]="preserveWhitespace"
         [style.background-color]="backgroundColor">
      <ng-container *ngIf="isTextString(); else templateOrContent">
        <div>{{text}}</div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="direction === 'up' || direction === 'left'"
       [attr.class]="direction === 'up' ? 'content-vertical-space' : 'content-horizonal-space'"></div>
</div>

<ng-template #templateOrContent>
  <ng-container *ngIf="text; else content">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </ng-container>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
