<ppl-autocomplete [options]="availableOptions"
                  [value]="filter"
                  [categories]="categories"
                  [displayCategoriesSidebar]="displayCategoriesSidebar"
                  [optionTemplate]="optionTemplate"
                  [optionTemplateRowHeight]="optionTemplateRowHeight"
                  [displayCreateOption]="displayCreateOption"
                  [displayOptionsLoading]="displayOptionsLoading"
                  [displayValueLoading]="displayValueLoading"
                  [forceDisplayClearValue]="forceDisplayClearValue"
                  [valueValidator]="freeValueValidator"
                  [autoFocusOnInit]="autoFocusOnInit"
                  [disabled]="disabled"
                  [placeholder]="placeholder"
                  [maxContainerHeight]="maxContainerHeight"
                  [openOnFocus]="openOnFocus"
                  [popoverAlignStart]="popoverAlignStart"
                  [popoverAlignEnd]="popoverAlignEnd"
                  [popoverWidth]="popoverWidth"
                  [minPopoverWidth]="minPopoverWidth"
                  [categoriesSidebarWidth]="categoriesSidebarWidth"
                  (listOpen)="onListOpen()"
                  (listClose)="onListClose()"
                  (optionSelect)="onOptionSelect($event)"
                  (optionCreate)="onOptionCreate($event)"
                  (listScrollEnd)="onListScrollEnd()"
                  (clearValueClick)="onClearValueClick()"
                  (valueChange)="onValueChange($event)"
                  (valueSubmit)="onValueSubmit($event)"></ppl-autocomplete>
