<div pplDropZone
     (pplDropZoneOver)="setDragOver($event)"
     (pplDropZoneDrop)="onDrop($event)"
     class="image-container"
     [class.round]="forceRound"
     [style.width]="imageWidth"
     [style.height]="imageHeight"
     [style.background-color]="backgroundColor">

  <div *ngIf="dragOver"
       class="overlay">
    <ppl-icon svgIcon="color-upload"
              width="20px"
              height="20px"></ppl-icon>
    <div class="overlay-text">
      {{ dropMessage }}
    </div>
  </div>

  <img *ngIf="picture"
       class="image"
       [class.contain]="containPicture"
       [src]="picture">
  <ppl-icon *ngIf="!picture"
            class="image"
            [svgIcon]="iconName"
            width="48px"
            height="48px"></ppl-icon>
</div>

<ng-container *ngIf="isEditable">
  <label *ngIf="optionsCount === 1 && options.includes('browse'); else moreOptions"
         (click)="fileSelector.click()"
         pplButton
         pplButton1
         class="edit"
         #editBtn>
    <ppl-icon width="18px"
              height="14px"
              svgIcon="icon-photo"
              [hoverEnabled]="true"
              [actionElement]="editBtn.elementRef.nativeElement">
    </ppl-icon>
  </label>

  <ng-template #moreOptions>
    <button type="button"
            [pplPopover]="buttonPopoverOptions"
            pplPopoverAlignStart
            pplButton
            pplButton1
            class="edit"
            #editBtn2>
      <ppl-icon width="18px"
                height="14px"
                svgIcon="icon-photo"
                [states]="[{name: 'hover', active: editBtn2.elementRef.nativeElement.classList.contains('popover-active')}]"
                [hoverEnabled]="true"
                [actionElement]="editBtn2.elementRef.nativeElement">
      </ppl-icon>
    </button>
    <ng-template #buttonPopoverOptions>
      <ppl-popover-dropdown>
        <ppl-menu>
          <ppl-menu-item *ngIf="options.includes('browse')"
                         pplPopoverClose
                         (click)="fileSelector.click()">{{ browse }}</ppl-menu-item>
          <ppl-menu-item *ngIf="options.includes('insertFromUrl')"
                         pplPopoverClose
                         (click)="onInsertFromURL()">{{ insertFromUrl }}</ppl-menu-item>
          <ppl-menu-item *ngIf="options.includes('remove')"
                         pplPopoverClose
                         (click)="onImageRemove()">{{ remove }}</ppl-menu-item>
        </ppl-menu>
      </ppl-popover-dropdown>
    </ng-template>
  </ng-template>

  <input #fileSelector
         type="file"
         style="display: none"
         accept="image/jpg,image/jpeg,image/png"
         (change)="onSelectFile($event)" />
</ng-container>
