import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-loading-overlay-demo',
  templateUrl: './loading-overlay-demo.component.html',
  styleUrls: ['./loading-overlay-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingOverlayDemoComponent implements OnInit {

  loadingText = '';
  visible = false;
  large = false;
  inverse = false;

  constructor() { }

  ngOnInit() {
  }
}
