import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'ppl-container-demo-panel',
  templateUrl: './container-demo.component.html',
  styleUrls: ['./container-demo.component.scss']
})
export class ContainerDemoComponent implements OnInit {

  opened = false;
  modeValues = [
    {value: 'push', label: 'push'},
    {value: 'over', label: 'over'},
    {value: 'breakpoint', label: 'breakpoint'}
  ];
  mode = 'breakpoint';
  positionValues = [
    {value: 'start', label: 'start'},
    {value: 'end', label: 'end'}
  ];
  position = 'start';

  underBreakpoint: null | boolean;

  code = `
    <ppl-container [opened]="opened"
                   [sidebarWidth]="414"
                   [underBreakpointSidebarWidth]="210"
                   [breakPoint]="1693"
                   [mode]="mode"
                   [position]="position"
                   (underBreakpointChange)="onUnderBreakpointChange($event)">
      <ppl-sidebar>
        sidebar content
      </ppl-sidebar>
      <ppl-content>
        Content
      </ppl-content>
    </ppl-container>
  `;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  onToggleOpened() {
    this.opened = !this.opened;
  }

  onUnderBreakpointChange($event) {
    this.underBreakpoint = $event;
    this.changeDetectorRef.detectChanges();
  }
}
