import { getTimezoneOffsetInUserTimezoneExtractor, getUserSettingsLocale } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { DatePipe } from '@angular/common';
import { Pipe } from '@angular/core';
import moment from 'moment';
import type { PipeTransform } from '@angular/core';
import type { TimezoneOffsetExtractorFn } from '@ppl/utils';
import type { Subscription } from 'rxjs';

@Pipe({
  name: 'pplDateRange'
})
export class PplDateRangePipe implements PipeTransform {
  locale: string;
  localeSubscription: Subscription;
  getTimezoneOffsetInUserTimezone: TimezoneOffsetExtractorFn = null;
  getTimezoneOffsetInUserTimezoneSubscription: Subscription;

  constructor(
    private datePipe: DatePipe,
    private store: AuthStore
  ) {
    this.localeSubscription = this.store.select(getUserSettingsLocale(true)).subscribe(locale => this.locale = locale);
    this.getTimezoneOffsetInUserTimezoneSubscription = this.store.select(getTimezoneOffsetInUserTimezoneExtractor).subscribe(
      extractor => {
        this.getTimezoneOffsetInUserTimezone = extractor;
      }
    );
  }

  transform(isoDateStart: string, isoDateEnd: string) {
    if (!isoDateStart || !isoDateEnd) {
      return '';
    }

    const dateTimeStart = moment(isoDateStart);
    const dateTimeEnd = moment(isoDateEnd);
    const dateTimeStartTimezone = this.getTimezoneOffsetInUserTimezone(dateTimeStart.toDate());
    const dateTimeEndTimezone = this.getTimezoneOffsetInUserTimezone(dateTimeEnd.toDate());

    const dateStart = this.datePipe.transform(dateTimeStart.valueOf(), 'mediumDate', dateTimeStartTimezone, this.locale);
    const dateEnd = this.datePipe.transform(dateTimeEnd.valueOf(), 'mediumDate', dateTimeEndTimezone, this.locale);

    if (dateStart === dateEnd) {
      return dateStart;
    } else {
      return `${dateStart} – ${dateEnd}`;
    }
  }

}
