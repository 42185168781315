import { DefaultAvailableColumn, MIN_COLUMN_WIDTH } from './grid.constants';
import type {
  GridAction,
  GridActionClickEvent,
  GridAvailableColumn,
  GridAvailableColumnCategory,
  GridColumn,
  GridRowOrderChange,
  GridRowOrderChangeSource,
  GridRowOrderChangeTarget,
  GridSelectionRequest,
  GridSort,
  GridInternalColumnData,
  GridFooterItem
} from './grid.interfaces';
import { GridRowOrderChangeKind, GridSelection } from './grid.interfaces';
import { PplVirtualScrollComponent } from '../virtual-scroll';
import type { VirtualScreen } from '../virtual-scroll';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  NgZone,
  TemplateRef
} from '@angular/core';
import type { CreatePointerEvent } from '@ppl/utils';
import {
  clamp,
  createCtrlPressedListener,
  createPointerEvents,
  createShiftPressedListener,
  findById,
  findLastIndex,
  MemoizeLast,
  trackById
} from '@ppl/utils';

@Component({
  selector: 'ppl-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements OnInit, OnDestroy, OnChanges {

  @Input() actions: GridAction[] = [];
  @Input() actionsTemplate: TemplateRef<any>;
  @Input() autoResizeColumnsOnLoad = false;
  @Input() availableColumns: GridAvailableColumn[];
  @Input() availableColumnCategories?: GridAvailableColumnCategory[];
  @Input() columns: GridColumn[]; // Note: Do not use this directly, use .migratedColumns
  @Input() columnTemplates: { [id: string]: TemplateRef<any> } = {};
  @Input() columnToolsTemplate: TemplateRef<any>;
  @Input() data: (any & { _meta?: { invalid?: boolean, loading?: boolean, isSection?: boolean, unavailable?: boolean, selectionDisabled?: boolean } })[];
  @Input() sectionTemplate?: TemplateRef<any>;
  @Input() columnsMetaTemplate?: TemplateRef<any>;
  @Input() columnsSource?: any;
  @Input() displayColumnSelector = true;
  @Input() displaySearchIcons = false;
  @Input() displaySelectAll = true;
  @Input() draggable = false;
  @Input() draggableDisableSectionTarget = false;
  @Input() draggableMoveSectionLabel?: string;
  @Input() draggableIgnoreSelection = false;
  @Input() draggableDragFieldIcon?: string | null;
  @Input() draggableReorder = true;
  @Input() draggableValidateDrop?: (record: any) => boolean;
  @Input() dynamicHeight?: boolean;
  @Input() footerItems?: GridFooterItem[] = [];
  @Input() resizable = true;
  @Input() rowHeight = 24;
  @Input() scrollToIndex?: number;
  @Input() selection: GridSelection;
  @Input() selectionMode: 'single' | 'multiple';
  @Input() singleAction = true;
  @Input() sort: GridSort[] = [];
  @Input() shiftSelection = true;

  @Output() virtualScreenChange = new EventEmitter<VirtualScreen>();
  @Output() columnsChange = new EventEmitter<GridColumn[]>();
  @Output() selectionChange = new EventEmitter<GridSelection>();
  @Output() selectionRequest = new EventEmitter<GridSelectionRequest>();

  @Output() sortChange = new EventEmitter<GridSort[]>();
  @Output() recordDoubleClick = new EventEmitter<any>();
  @Output() actionClick = new EventEmitter<GridActionClickEvent>();
  @Output() rowOrderChange = new EventEmitter<GridRowOrderChange>();

  @ViewChild('headerContainer', { static: true }) headerContainer: ElementRef;
  @ViewChild('headerScrollContainer', { static: true }) headerScrollContainer: ElementRef;
  @ViewChild('dataContainer', { static: true }) dataContainer: PplVirtualScrollComponent;

  dataContainerLayout: DataContainerLayout | null = null;
  dataContainerScrollLeft = 0;

  resizeObserver: ResizeObserver;

  ctrlPressed = false;
  shiftPressed = false;
  removeCtrlPressedListener: any;
  removeShiftPressedListener: any;

  columnResizeData: ColumnResizeData | null = null;

  columnDragData: ColumnDragData | null = null;
  columnDragHandle = 0;

  rowDragData: RowDragData | null = null;
  rowDragHandle = 0;

  columnsAutoResized = false;
  columnsAutoResizeHandle = 0;

  trackById = trackById;
  GridRowOrderChangeKind = GridRowOrderChangeKind;
  CHECKBOX_FIELD_WIDTH = CHECKBOX_FIELD_WIDTH;
  DRAG_FIELD_WIDTH = DRAG_FIELD_WIDTH;
  COLUMN_FIELD_WIDTH = COLUMN_FIELD_WIDTH;
  MAX_VISIBLE_COLUMNS = MAX_VISIBLE_COLUMNS;

  @MemoizeLast<GridComponent>(['columns', 'dataContainerLayout', 'firstColumnOffset', 'displayLastField'])
  get migratedColumns() {
    // If all columns' widths are provided in absolute pixels (new system), do sanitization only
    if (this.columns.every(column => column.pixels)) {
      return this.columns.map(column => ({
        ...column,
        frozen: !!column.frozen,
        width: this.getSanitizedColumnPixelWidth(column.id, column.width),
        pixels: true
      }));
    }

    // If some (or all) columns' widths are provided in flex units (old system), convert them to absolute pixels
    // using the current component width, so the user will not notice any change at first
    if (!this.dataContainerLayout) {
      return [];
    }

    const flexColumnsTotalWidth = this.columns.filter(column => !column.pixels).reduce((total, column) => total + this.getSanitizedColumnFlexWidth(column.width), 0);
    const pixelColumnsTotalWidth = this.columns.filter(column => column.pixels).reduce((total, column) => total + this.getSanitizedColumnPixelWidth(column.id, column.width), 0);

    const lastColumnOffset = this.displayLastField ? COLUMN_FIELD_WIDTH : this.dataContainerLayout.offsetWidth - this.dataContainerLayout.clientWidth;
    const availableWidthForColumns = Math.max(this.dataContainerLayout.offsetWidth - this.firstColumnOffset - lastColumnOffset, 0);
    const availableWidthForFlexColumns = Math.max(availableWidthForColumns - pixelColumnsTotalWidth, 0);

    let elapsedColumnWidth = 0;

    return this.columns.map((column, index) => {
      const isLastColumn = (index === this.columns.length - 1);
      let columnWidth = 0;

      if (column.pixels) {
        columnWidth = this.getSanitizedColumnPixelWidth(column.id, column.width);
      } else {
        columnWidth = this.getSanitizedColumnPixelWidth(column.id, Math.floor(availableWidthForFlexColumns * (column.width / flexColumnsTotalWidth)));

        if (isLastColumn) {
          columnWidth = this.getSanitizedColumnPixelWidth(column.id, availableWidthForColumns - elapsedColumnWidth);
        }
      }

      const result: GridColumn = {
        ...column,
        frozen: !!column.frozen,
        width: columnWidth,
        pixels: true
      };

      elapsedColumnWidth += columnWidth;

      return result;
    });
  }

  @MemoizeLast<GridComponent>(['availableColumns', 'migratedColumns', 'columnTemplates'])
  get columnsWithData() {
    let elapsedColumnWidth = 0;

    return this.migratedColumns.map(column => {
      const availableColumn = this.getAvailableColumnById(column.id);

      const columnWithData: GridColumn & GridAvailableColumn & GridInternalColumnData = {
        ...column,
        ...availableColumn,
        offset: this.firstColumnOffset + elapsedColumnWidth,
        template: this.getTemplateForColumn(column.id)
      };

      elapsedColumnWidth += column.width;

      return columnWithData;
    });
  }

  @MemoizeLast<GridComponent>(['displayCheckboxField', 'displayDragField'])
  get firstColumnOffset() {
    return (this.displayCheckboxField ? CHECKBOX_FIELD_WIDTH : 0) + (this.displayDragField ? DRAG_FIELD_WIDTH : 0);
  }

  @MemoizeLast<GridComponent>(['dataContainerLayout'])
  get lastColumnWidth() {
    if (!this.dataContainerLayout) {
      return LAST_COLUMN_DEFAULT_WIDTH;
    }

    return COLUMN_FIELD_WIDTH - (this.dataContainerLayout.offsetWidth - this.dataContainerLayout.clientWidth);
  }

  @MemoizeLast<GridComponent>(['selectionMode'])
  get displayCheckboxField() {
    return this.selectionMode === 'multiple';
  }

  @MemoizeLast<GridComponent>(['draggable', 'draggableDragFieldIcon'])
  get displayDragField() {
    return this.draggable && this.draggableDragFieldIcon !== null;
  }

  @MemoizeLast<GridComponent>(['displayColumnSelector', 'actions', 'actionsTemplate'])
  get displayLastField() {
    return this.displayColumnSelector || this.actions.length > 0 || !!this.actionsTemplate;
  }

  @MemoizeLast<GridComponent>(['actions', 'actionsTemplate'])
  get lastFieldHasActions() {
    return this.actions.length > 0 || !!this.actionsTemplate;
  }

  @MemoizeLast<GridComponent>(['data', 'sectionTemplate'])
  get recordData() {
    return this.sectionTemplate
      ? this.data.filter(data => !data?._meta || !data?._meta.isSection)
      : this.data;
  }

  @MemoizeLast<GridComponent>(['data'])
  get allDataDefined() {
    return this.data.every(Boolean);
  }

  @MemoizeLast<GridComponent>(['rowHeight'])
  get dataBackground() {
    return `repeating-linear-gradient(180deg, transparent, transparent ${this.rowHeight}px, #f6f7f9 ${this.rowHeight}px, #f6f7f9 ${this.rowHeight * 2}px)`;
  }

  @MemoizeLast<GridComponent>(['firstColumnOffset', 'migratedColumns', 'lastColumnWidth'])
  get recordWidth() {
    // Using dataContainer scrollWidth is unreliable (scrollbar is sometimes included in the result, sometimes not), so we calculate the record width manually
    return this.firstColumnOffset + this.migratedColumns.reduce((total, column) => total + column.width, 0) + (this.displayLastField ? this.lastColumnWidth : 0);
  }

  @MemoizeLast<GridComponent>(['recordWidth', 'dataContainerLayout'])
  get scrollWidth() {
    return Math.max(this.recordWidth, this.dataContainerLayout?.clientWidth || 0);
  }

  @MemoizeLast<GridComponent>(['dataContainerScrollLeft'])
  get startDividerVisible() {
    return this.dataContainerScrollLeft > 0;
  }

  @MemoizeLast<GridComponent>(['dataContainerLayout', 'dataContainerScrollLeft'])
  get lastDividerVisible() {
    return this.dataContainerLayout ? this.dataContainerScrollLeft < this.recordWidth - this.dataContainerLayout.clientWidth : false;
  }

  @MemoizeLast<GridComponent>(['dataContainerLayout', 'recordWidth'])
  get horizontalScroll() {
    return this.dataContainerLayout ? this.recordWidth > this.dataContainerLayout.clientWidth : false;
  }

  get rowDragDataSourceHelperStyle() {
    return {
      left: `${this.rowDragData.x + ROW_DRAG_HELPER_OFFSET}px`,
      top: `${this.rowDragData.y + ROW_DRAG_HELPER_OFFSET}px`
    };
  }

  get rowDragDataTargetHelperStyle() {
    const source = this.rowDragData.source;
    const target = this.rowDragData.target;

    const scrollTop = this.dataContainer.elementRef.nativeElement.scrollTop;

    if (source.kind === GridRowOrderChangeKind.Record) {
      if (target.kind === GridRowOrderChangeKind.Record && this.draggableReorder) {
        return {
          height: '2px',
          top: `${HEADER_HEIGHT + this.rowDragData.targetOffset - 1 - scrollTop}px`
        };
      } else if (target.kind === GridRowOrderChangeKind.Section || !this.draggableReorder) {
        return {
          height: `${this.rowHeight}px`,
          opacity: '0.33',
          top: `${HEADER_HEIGHT + this.rowDragData.targetOffset - scrollTop}px`
        };
      }
    } else if (source.kind === GridRowOrderChangeKind.Section) {
      if (target.kind === GridRowOrderChangeKind.Section) {
        return {
          height: '2px',
          top: `${HEADER_HEIGHT + this.rowDragData.targetOffset - 1 - scrollTop}px`
        };
      }
    }
  }

  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.onRowDragFrame = this.onRowDragFrame.bind(this);
    this.onColumnDragFrame = this.onColumnDragFrame.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.autoResizeColumnsOnLoad && changes.data && this.data.length && !this.columnsAutoResized) {
      this.columnsAutoResized = true;

      this.columnsAutoResizeHandle = requestAnimationFrame(() => {
        this.columnsChange.emit(this.migratedColumns.map(column => ({
          ...column,
          width: this.getAutoWidthForColumn(column.id)
        })));
      });
    }
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(() => {
        this.ngZone.run(() => {
          this.measureLayout();
          this.changeDetectorRef.detectChanges();
        });
      });

      this.resizeObserver.observe(this.dataContainer.elementRef.nativeElement);
    });

    this.removeCtrlPressedListener = createCtrlPressedListener(ctrlPressed => {
      this.ctrlPressed = ctrlPressed;
    });

    this.removeShiftPressedListener = createShiftPressedListener(shiftPressed => {
      this.shiftPressed = shiftPressed;
    });
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
    this.removeCtrlPressedListener();
    this.removeShiftPressedListener();
    cancelAnimationFrame(this.rowDragHandle);
    cancelAnimationFrame(this.columnDragHandle);
    cancelAnimationFrame(this.columnsAutoResizeHandle);
  }

  measureLayout() {
    const dataContainer = this.dataContainer.elementRef.nativeElement;

    this.dataContainerLayout = {
      clientWidth: dataContainer.clientWidth,
      offsetWidth: dataContainer.offsetWidth
    };
  }

  getFixedRecordStyle(style: object) {
    return {
      ...style,
      left: `${this.dataContainerScrollLeft}px`,
      width: this.dataContainerLayout ? `min(${this.dataContainerLayout.clientWidth}px, 100%)` : '100%'
    };
  }

  isRecordSelected(record: any) {
    return (this.selection.all && this.selection.exclude.indexOf(record.id) === -1) || (!this.selection.all && this.selection.include.indexOf(record.id) !== -1);
  }

  isColumnVisible(id: string) {
    return !!findById(this.migratedColumns, id);
  }

  isShiftSelectionEnabled() {
    if (this.selectionMode === 'multiple') {
      if (!this.shiftSelection) {
        return false;
      }

      if (this.selectionRequest.observers.length) {
        return true;
      }

      if (this.allDataDefined) {
        return true;
      }
    }

    return false;
  }

  onHeaderClick(column: GridAvailableColumn & GridColumn) {
    if (column.sortable) {
      // Note: Currently, only single column sort is implemented (component API supports multiple columns though)
      let newSort: GridSort[];

      if (this.sort.length !== 0) {
        if (this.sort[0].id === column.id) {
          newSort = [{ id: this.sort[0].id, direction: -this.sort[0].direction }];
        } else {
          newSort = [{ id: column.id, direction: this.sort[0].direction }];
        }
      } else {
        newSort = [{ id: column.id, direction: -1 }];
      }

      this.sortChange.emit(newSort);
    }
  }

  onHeaderCheck() {
    let selectionAll = this.selection.all;
    let selectionExclude = this.selection.exclude;
    let selectionInclude = this.selection.include;

    if (selectionAll) {
      if (selectionExclude.length === 0) {
        selectionAll = false;
      } else {
        selectionAll = true;
        selectionExclude = [];
      }
    } else {
      selectionAll = true;
      selectionInclude = [];
    }

    this.selectionChange.emit({
      all: selectionAll,
      include: selectionInclude,
      exclude: selectionExclude
    });
  }

  onRecordCheck(record: any) {
    let selectionAll = this.selection.all;
    let selectionExclude = this.selection.exclude;
    let selectionInclude = this.selection.include;

    if (this.selectionMode === 'multiple') {
      if (this.displaySelectAll) {
        if (selectionAll) {
          const recordIndex = selectionExclude.indexOf(record.id);

          if (recordIndex !== -1) {
            selectionExclude = [...selectionExclude.slice(0, recordIndex), ...selectionExclude.slice(recordIndex + 1)];
          } else {
            selectionExclude = [...selectionExclude, record.id];
          }

          if (selectionExclude.length === this.recordData.length) {
            selectionAll = false;
            selectionExclude = [];
          }
        } else {
          const recordIndex = selectionInclude.indexOf(record.id);

          if (recordIndex !== -1) {
            selectionInclude = [...selectionInclude.slice(0, recordIndex), ...selectionInclude.slice(recordIndex + 1)];
          } else {
            selectionInclude = [...selectionInclude, record.id];
          }

          if (selectionInclude.length === this.recordData.length) {
            selectionAll = true;
            selectionInclude = [];
          }
        }

        this.selectionChange.emit({
          all: selectionAll,
          include: selectionInclude,
          exclude: selectionExclude
        });
      } else {
        // If "select all" checkbox is not displayed, use just "include" key
        const recordIndex = this.selection.include.indexOf(record.id);
        let nextSelectionInclude = this.selection.include;

        if (recordIndex !== -1) {
          nextSelectionInclude = [...nextSelectionInclude.slice(0, recordIndex), ...nextSelectionInclude.slice(recordIndex + 1)];
        } else {
          nextSelectionInclude = [...nextSelectionInclude, record.id];
        }

        this.selectionChange.emit({
          all: false,
          include: nextSelectionInclude,
          exclude: []
        });
      }
    } else if (this.selectionMode === 'single') {
      if (selectionInclude[0] === record.id) {
        selectionInclude = [];
      } else {
        selectionInclude = [record.id];
      }

      this.selectionChange.emit({
        all: false,
        include: selectionInclude,
        exclude: selectionExclude
      });
    }
  }

  onRecordClick(record: any) {
    if (this.ctrlPressed) {
      this.onRecordCheck(record);
    } else if (this.shiftPressed && this.isShiftSelectionEnabled()) {
      this.onRecordShiftClick(record);
    } else {
      if (this.displaySelectAll) {
        const selectionAll = (this.selectionMode === 'multiple') && this.recordData.length === 1 && this.displaySelectAll;

        this.selectionChange.emit({
          all: selectionAll,
          include: selectionAll ? [] : [record.id],
          exclude: []
        });
      } else {
        // If "select all" checkbox is not displayed, replace only IDs of currently visible records with the clicked record
        this.selectionChange.emit({
          all: false,
          include: [
            ...this.selection.include.filter(id => !this.data.find(rec => rec?.id === id)),
            record.id
          ],
          exclude: []
        });
      }
    }
  }

  onRecordShiftClick(record: any) {
    const recordIds = this.recordData.map(stepRecord => stepRecord ? stepRecord.id : undefined);

    let selectionAll = this.selection.all;
    let selectionExclude = this.selection.exclude;
    let selectionInclude = this.selection.include;

    if (!this.displaySelectAll || !selectionAll) {
      selectionInclude.sort((aId, bId) => recordIds.indexOf(aId) - recordIds.indexOf(bId));

      const pivotRecordIndex = recordIds.indexOf(selectionInclude[0]);
      const clickRecordIndex = recordIds.indexOf(record.id);

      const startSelectionIndex = (pivotRecordIndex < clickRecordIndex) ? pivotRecordIndex : clickRecordIndex;
      const stopSelectionIndex = (pivotRecordIndex < clickRecordIndex) ? clickRecordIndex : pivotRecordIndex;

      const selectedRecordIds = recordIds.slice(startSelectionIndex, stopSelectionIndex + 1);

      if (selectedRecordIds.some(selectionId => selectionId === undefined) && stopSelectionIndex - startSelectionIndex + 1 !== this.recordData.length) {
        this.selectionRequest.emit({
          startId: recordIds[startSelectionIndex],
          stopId: recordIds[stopSelectionIndex]
        });
      } else {
        selectionInclude = recordIds.slice(startSelectionIndex, stopSelectionIndex + 1);
      }

      if (this.displaySelectAll && selectionInclude.length === this.recordData.length) {
        selectionAll = true;
        selectionInclude = [];
      }
    } else if (selectionAll) {
      if (this.recordData.length > 1) {
        selectionAll = false;
        selectionInclude = [record.id];
        selectionExclude = [];
      }
    }

    this.selectionChange.emit({
      all: selectionAll,
      include: selectionInclude,
      exclude: selectionExclude
    });
  }

  onRecordDoubleClick(record: any) {
    this.recordDoubleClick.emit(record.id);
  }

  onRecordMouseDown(record: any, event: MouseEvent) {
    if (this.shiftPressed && this.isShiftSelectionEnabled()) {
      event.preventDefault();
    } else if (this.draggable && this.draggableDragFieldIcon === null) {
      this.onRowDragStart(record, event);
    }
  }

  onColumnResizeStart(event: MouseEvent, id: string) {
    const column = findById(this.migratedColumns, id);

    this.columnResizeData = {
      id: column.id,
      startWidth: column.width
    };

    createPointerEvents({
      event,
      onPointerMove: this.onColumnResizeMove.bind(this),
      onPointerRelease: this.onColumnResizeRelease.bind(this)
    });

    event.preventDefault();
    event.stopPropagation();
  }

  onColumnResizeMove(event: CreatePointerEvent) {
    if (event.dtStartX !== 0) {
      // Prevents firing onHeaderClick
      // Note: Do not move this to onColumnResizeStart, because dblclick for onColumnResizeAuto would not work
      document.body.style.pointerEvents = 'none';
    }

    this.columnsChange.emit(this.migratedColumns.map(column => {
      if (column.id === this.columnResizeData.id) {
        const availableColumn = this.getAvailableColumnById(column.id);

        const minWidth = availableColumn.minWidth || MIN_COLUMN_WIDTH;
        const maxWidth = availableColumn.maxWidth || Infinity;

        return {
          ...column,
          width: clamp(Math.round(this.columnResizeData.startWidth + event.dtStartX), minWidth, maxWidth)
        };
      }

      return column;
    }));
  }

  onColumnResizeRelease() {
    this.columnResizeData = null;

    document.body.style.pointerEvents = 'auto';
  }

  onColumnResizeAuto(id: string) {
    this.columnsChange.emit(this.migratedColumns.map(column => {
      if (column.id === id) {
        return {
          ...column,
          width: this.getAutoWidthForColumn(column.id)
        };
      }

      return column;
    }));
  }

  onColumnDragStart(event: MouseEvent, draggedColumn: GridAvailableColumn & GridColumn) {
    if (draggedColumn.draggable) {
      this.columnDragData = {
        id: draggedColumn.id,
        target: null,
        targetOffset: null,
        helperOffset: null,
        passedThreshold: false,
        x: event.x
      };

      createPointerEvents({
        event,
        onPointerMove: this.onColumnDragMove.bind(this),
        onPointerRelease: this.onColumnDragRelease.bind(this)
      });
    }

    event.preventDefault();
    event.stopPropagation();

    this.onColumnDragFrame();
  }

  onColumnDragMove(event: CreatePointerEvent) {
    if (event.distance >= COLUMN_DRAG_START_THRESHOLD) {
      this.columnDragData.passedThreshold = true;
    }

    if (!this.columnDragData.passedThreshold) {
      return;
    }

    // Find where the column should be placed
    const headerContainerRect = this.headerContainer.nativeElement.getBoundingClientRect();
    let latestX = headerContainerRect.left;

    const dragColumnIndex = this.migratedColumns.findIndex(column => column.id === this.columnDragData.id);

    this.columnDragData.x = event.x;
    this.columnDragData.target = null;
    this.columnDragData.targetOffset = null;
    this.columnDragData.helperOffset = (event.x - headerContainerRect.left) - COLUMN_DRAG_HELPER_OFFSET;

    for (let columnIndex = 0; columnIndex < this.migratedColumns.length; columnIndex++) {
      const column = this.columnsWithData[columnIndex];

      const scrollLeft = column.frozen ? 0 : this.dataContainerScrollLeft;
      const startX = headerContainerRect.left + column.offset - scrollLeft;
      const endX = startX + column.width;
      const halfX = Math.round((startX + endX) / 2);

      if (columnIndex !== dragColumnIndex) {
        if (event.x >= startX && event.x <= halfX && startX >= latestX && columnIndex !== dragColumnIndex + 1) {
          this.columnDragData.target = {
            direction: -1,
            id: column.id
          };
          this.columnDragData.targetOffset = startX - headerContainerRect.left;
          break;
        } else if (event.x > halfX && event.x <= endX && endX >= latestX && columnIndex !== dragColumnIndex - 1) {
          this.columnDragData.target = {
            direction: 1,
            id: column.id
          };
          this.columnDragData.targetOffset = endX - headerContainerRect.left;
          break;
        }
      }

      if (endX > latestX) {
        // Make sure the target is not a column "under" a scrolled sticky column
        latestX = endX;
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  onColumnDragRelease() {
    if (this.columnDragData?.target) {
      const columnIndex = this.migratedColumns.findIndex(column => column.id === this.columnDragData.id);

      const targetColumn = findById(this.migratedColumns, this.columnDragData.target.id);
      const targetColumnIndex = this.migratedColumns.indexOf(targetColumn);

      const updatedColumn: GridColumn = {
        ...this.migratedColumns[columnIndex],
        frozen: targetColumn.frozen
      };

      if (this.columnDragData.target.direction === -1) {
        this.columnsChange.emit([
          ...this.migratedColumns.slice(0, targetColumnIndex).filter(column => column.id !== this.columnDragData.id),
          updatedColumn,
          ...this.migratedColumns.slice(targetColumnIndex).filter(column => column.id !== this.columnDragData.id)
        ]);
      } else if (this.columnDragData.target.direction === 1) {
        this.columnsChange.emit([
          ...this.migratedColumns.slice(0, targetColumnIndex + 1).filter(column => column.id !== this.columnDragData.id),
          updatedColumn,
          ...this.migratedColumns.slice(targetColumnIndex + 1).filter(column => column.id !== this.columnDragData.id)
        ]);
      }
    }

    this.columnDragData = null;

    this.changeDetectorRef.detectChanges();
  }

  onColumnDragFrame() {
    if (this.columnDragData) {
      const headerRect: DOMRect = this.headerContainer.nativeElement.getBoundingClientRect();

      if (this.columnDragData.x <= headerRect.left + COLUMN_DRAG_THRESHOLD) {
        this.dataContainer.elementRef.nativeElement.scrollLeft -= COLUMN_DRAG_SPEED;
      } else if (this.columnDragData.x >= headerRect.right - COLUMN_DRAG_THRESHOLD) {
        this.dataContainer.elementRef.nativeElement.scrollLeft += COLUMN_DRAG_SPEED;
      }

      this.columnDragHandle = requestAnimationFrame(this.onColumnDragFrame);
    }
  }

  onRowDragStart(record: any, event: MouseEvent) {
    let selectionIds: string[] | null = null;

    if (this.selection.include.length) {
      selectionIds = this.selection.include;
    } else if (this.allDataDefined) {
      if (this.selection.all) {
        selectionIds = this.recordData.map(rec => rec.id);
      } else if (this.selection.exclude.length) {
        selectionIds = this.recordData.map(rec => rec.id).filter(rec => !this.selection.exclude.includes(rec.id));
      } else {
        selectionIds = [];
      }
    }

    if (!selectionIds) {
      return;
    }

    this.onRowDragInit({
      kind: GridRowOrderChangeKind.Record,
      ids: (selectionIds.includes(record.id) && !this.draggableIgnoreSelection) ? selectionIds : [record.id]
    }, event);
  }

  onSectionMouseDown(record: any, event: MouseEvent) {
    const eventTarget = event.target as HTMLElement;

    if (eventTarget.closest('*[data-drag-handle]')) {
      this.onRowDragInit({
        kind: GridRowOrderChangeKind.Section,
        id: record.id
      }, event);
    }
  }

  onRowDragInit(source: GridRowOrderChangeSource, event: MouseEvent) {
    this.rowDragData = {
      source,
      target: null,
      targetOffset: null,
      passedThreshold: false,
      x: event.pageX,
      y: event.pageY
    };

    createPointerEvents({
      event,
      onPointerMove: this.onRowDragMove.bind(this),
      onPointerRelease: this.onRowDragRelease.bind(this)
    });

    event.preventDefault();
    event.stopPropagation();

    this.onRowDragFrame();
  }

  onRowDragMove(event: CreatePointerEvent) {
    if (event.distance >= ROW_DRAG_START_THRESHOLD) {
      this.rowDragData.passedThreshold = true;
    }

    if (!this.rowDragData.passedThreshold) {
      return;
    }

    const dataContainerRect = this.dataContainer.elementRef.nativeElement.getBoundingClientRect();
    const offsetY = event.y - dataContainerRect.top + this.dataContainer.elementRef.nativeElement.scrollTop;

    const recordIndex = Math.floor(offsetY / this.rowHeight);
    const record = this.data[recordIndex];

    const source = this.rowDragData.source;

    this.rowDragData.target = null;
    this.rowDragData.targetOffset = null;

    // Calculate sections start/stop indices (includes section headers)
    const sections: RowDragSection[] = [];
    let currentSection: RowDragSection | null = null;

    this.data.forEach((rec, index) => {
      if (rec?._meta?.isSection) {
        if (currentSection) {
          sections.push(currentSection);
        }

        currentSection = {
          id: rec.id,
          startIndex: index,
          stopIndex: index
        };
      } else if (currentSection) {
        currentSection.stopIndex = index;
      }
    });

    if (currentSection) {
      currentSection.stopIndex = this.data.length - 1;

      sections.push(currentSection);
    }

    if (source.kind === GridRowOrderChangeKind.Section) {
      // Drop the section on a section
      const sourceSectionIndex = sections.findIndex(section => section.id === source.id);
      const sourceNextSectionId = sections[sourceSectionIndex + 1]?.id || null;

      sections.forEach((section, sectionIndex) => {
        const startOffset = section.startIndex * this.rowHeight;
        const stopOffset = (section.stopIndex + 1) * this.rowHeight;

        if (offsetY >= startOffset && offsetY <= stopOffset) {
          let targetId: string | null = section.id;
          let targetOffset = startOffset;

          if (stopOffset - offsetY < offsetY - startOffset) {
            if (sections[sectionIndex + 1]) {
              targetId = sections[sectionIndex + 1].id;
            } else {
              targetId = null;
            }

            targetOffset = stopOffset;
          }

          if (targetId !== source.id && targetId !== sourceNextSectionId) {
            this.rowDragData.target = {
              kind: GridRowOrderChangeKind.Section,
              id: targetId
            };
            this.rowDragData.targetOffset = targetOffset;
          }
        }
      });
    } else if (source.kind === GridRowOrderChangeKind.Record) {
      if (record) {
        if (!record._meta || !record._meta.isSection || this.draggableDisableSectionTarget) {
          // Drop records on a record
          const sourceNextIds: string[] = [];

          source.ids.forEach(recordId => {
            const recIndex = this.data.findIndex(rec => rec?.id === recordId);

            if (recIndex !== -1) {
              const nextRecord = this.data[recIndex + 1];

              if (nextRecord && (!nextRecord._meta || !nextRecord._meta.isSection)) {
                sourceNextIds.push(nextRecord.id);
              }
            }
          });

          const recordLocalOffset = offsetY % this.rowHeight;

          let targetId: string | null = record.id;
          let targetOffset = recordIndex * this.rowHeight;

          if (this.draggableReorder && recordLocalOffset > this.rowHeight / 2) {
            const recordData = this.draggableDisableSectionTarget ? this.data : this.recordData;
            const recordDataIndex = recordData.findIndex(rec => rec?.id === targetId);

            if (recordData[recordDataIndex + 1]) {
              targetId = recordData[recordDataIndex + 1].id;
              targetOffset += this.rowHeight;
            } else if (recordDataIndex + 1 >= recordData.length) {
              targetId = null;
              targetOffset += this.rowHeight;
            }
          }

          if (!source.ids.includes(targetId) && !sourceNextIds.includes(targetId)) {
            const sectionId = sections.find(section => recordIndex >= section.startIndex && recordIndex <= section.stopIndex)?.id || null;

            if (!this.draggableValidateDrop || this.draggableValidateDrop(record)) {
              this.rowDragData.target = {
                kind: GridRowOrderChangeKind.Record,
                id: targetId,
                sectionId
              };
              this.rowDragData.targetOffset = targetOffset;
            }
          }
        } else {
          // Drop records on a section
          const targetSection = findById(sections, record.id);

          this.rowDragData.target = {
            kind: GridRowOrderChangeKind.Section,
            id: record.id
          };
          this.rowDragData.targetOffset = targetSection.startIndex * this.rowHeight;
        }
      }
    }

    this.rowDragData.x = event.x;
    this.rowDragData.y = event.y;

    this.changeDetectorRef.detectChanges();
  }

  onRowDragRelease() {
    if (this.rowDragData?.target) {
      this.rowOrderChange.emit({
        source: this.rowDragData.source,
        target: this.rowDragData.target
      });
    }

    this.rowDragData = null;

    this.changeDetectorRef.detectChanges();
  }

  onRowDragFrame() {
    if (this.rowDragData) {
      const scrollableParent = this.getScrollableParent(this.dataContainer.elementRef.nativeElement);

      if (scrollableParent) {
        const scrollableParentRect = scrollableParent.getBoundingClientRect();

        if (this.rowDragData.y <= scrollableParentRect.top + ROW_DRAG_THRESHOLD) {
          scrollableParent.scrollTop -= ROW_DRAG_SPEED;
        } else if (this.rowDragData.y >= scrollableParentRect.bottom - ROW_DRAG_THRESHOLD) {
          scrollableParent.scrollTop += ROW_DRAG_SPEED;
        }
      }

      this.rowDragHandle = requestAnimationFrame(this.onRowDragFrame);
    }
  }

  onColumnToggle(id: string) {
    if (this.isColumnVisible(id)) {
      this.columnsChange.emit(this.migratedColumns.filter(column => column.id !== id));
    } else {
      this.columnsChange.emit([
        ...this.migratedColumns,
        {
          id,
          frozen: false,
          width: DEFAULT_COLUMN_WIDTH,
          pixels: true
        }
      ]);
    }
  }

  onColumnFrozenToggle(id: string) {
    const column = findById(this.migratedColumns, id);

    const updatedColumn: GridColumn = {
      ...column,
      frozen: !column.frozen
    };

    const otherColumns = this.migratedColumns.filter(col => col.id !== id);

    if (column.frozen) {
      // Unfreeze the column and put it in the front of first non-frozen column
      const firstNonFrozenColumnIndex = otherColumns.findIndex(col => !col.frozen);

      if (firstNonFrozenColumnIndex !== -1) {
        this.columnsChange.emit([
          ...otherColumns.slice(0, firstNonFrozenColumnIndex),
          updatedColumn,
          ...otherColumns.slice(firstNonFrozenColumnIndex)
        ]);
      } else {
        this.columnsChange.emit([
          ...otherColumns,
          updatedColumn
        ]);
      }
    } else {
      // Freeze the column and put it as the last frozen column
      const lastFrozenColumnIndex = findLastIndex(otherColumns, col => col.frozen);

      if (lastFrozenColumnIndex !== -1) {
        this.columnsChange.emit([
          ...otherColumns.slice(0, lastFrozenColumnIndex + 1),
          updatedColumn,
          ...otherColumns.slice(lastFrozenColumnIndex + 1)
        ]);
      } else {
        this.columnsChange.emit([
          updatedColumn,
          ...otherColumns
        ]);
      }
    }
  }

  onWidthResetClick() {
    this.columnsChange.emit(this.migratedColumns.map(column => ({
      ...column,
      width: this.getAutoWidthForColumn(column.id)
    })));
  }

  onDataContainerScroll() {
    const scrollLeft = this.dataContainer.elementRef.nativeElement.scrollLeft;

    this.dataContainerScrollLeft = scrollLeft;
    this.headerScrollContainer.nativeElement.scrollLeft = scrollLeft;
  }

  getAutoWidthForColumn(id: string) {
    const availableColumn = this.getAvailableColumnById(id);

    const columnMinWidth = availableColumn.minWidth || MIN_COLUMN_WIDTH;
    let maxWidth = columnMinWidth;

    const dataList = this.dataContainer.elementRef.nativeElement.querySelectorAll(`.field[data-field="${id}"] .data`);
    for (let i = 0; i < dataList.length; i += 1) {
      const dataWidth = dataList[i].scrollWidth;
      maxWidth = Math.max(dataWidth, maxWidth);
    }

    return Math.round(maxWidth + CELL_PADDING * 2 + CELL_EXTENSION);
  }

  getTemplateForColumn(id: string) {
    return this.columnTemplates[`id:${id}`] || this.columnTemplates[`type:${this.getAvailableColumnById(id).type}`] || this.columnTemplates['other'] || null;
  }

  getAvailableColumnById(id: string) {
    return findById(this.availableColumns, id) || DefaultAvailableColumn;
  }

  getScrollableParent(node: Element) {
    while (node) {
      if (node && node instanceof Element && node.scrollHeight > node.clientHeight && node.clientHeight > 0) {
        return node;
      }

      node = node.parentNode as Element;
    }

    return null;
  }

  getSanitizedColumnPixelWidth(id: string, width: number) {
    const availableColumn = this.getAvailableColumnById(id);

    const minColumnWidth = availableColumn.minWidth || MIN_COLUMN_WIDTH;
    const maxColumnWidth = availableColumn.maxWidth || Infinity;

    return clamp(Math.round(Number.isFinite(width) ? width : DEFAULT_COLUMN_WIDTH), minColumnWidth, maxColumnWidth);
  }

  getSanitizedColumnFlexWidth(width: number) {
    return Number.isFinite(width) ? width : 1;
  }

}

const MAX_VISIBLE_COLUMNS = 20;
const COLUMN_DRAG_START_THRESHOLD = 16;
const COLUMN_DRAG_THRESHOLD = 24;
const COLUMN_DRAG_SPEED = 5;
const COLUMN_DRAG_HELPER_OFFSET = 80;
const CELL_PADDING = 10;
const CELL_EXTENSION = 16;
const ROW_DRAG_START_THRESHOLD = 8;
const ROW_DRAG_THRESHOLD = 24;
const ROW_DRAG_SPEED = 5;
const ROW_DRAG_HELPER_OFFSET = 16;
const HEADER_HEIGHT = 32;
const DEFAULT_COLUMN_WIDTH = 200;
const CHECKBOX_FIELD_WIDTH = 30;
const DRAG_FIELD_WIDTH = 30;
const COLUMN_FIELD_WIDTH = 56;
const WIN10_SCROLLBAR_WIDTH = 17;
const LAST_COLUMN_DEFAULT_WIDTH = COLUMN_FIELD_WIDTH - WIN10_SCROLLBAR_WIDTH; // Safe default, actual scrollbar width is measured in lastColumnWidth getter

interface DataContainerLayout {
  clientWidth: number;
  offsetWidth: number;
}

interface ColumnResizeData {
  id: string;
  startWidth: number;
}

interface ColumnDragData {
  id: string;
  target: {
    direction: number;
    id: string;
  } | null;
  targetOffset: number | null;
  helperOffset: number | null;
  passedThreshold: boolean;
  x: number;
}

interface RowDragData {
  source: GridRowOrderChangeSource;
  target: GridRowOrderChangeTarget;
  targetOffset: number | null;
  passedThreshold: boolean;
  x: number;
  y: number;
}

interface RowDragSection {
  id: string;
  startIndex: number;
  stopIndex: number;
}
