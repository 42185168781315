<div class="container demo-content">
  <h2>Tree Select</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-tree-select</h4>
  <div>@Input() <b>options</b>: PplFormTableItem[]</div>
  <div>@Input() <b>value</b>: PplTreeSelectValue</div>
  <div>@Input() <b>disabled</b> = false</div>
  <div>@Input() <b>placeholder?</b>: string</div>
  <br>
  <div>@Output() <b>valueChange</b>: string[]</div>
  <br>
  <br>

  <h3>Example</h3>
  <ppl-input-container label="example tree select">
    <ppl-tree-select [value]="value"
                     [options]="options"
                     (valueChange)="onValueChange($event)">

    </ppl-tree-select>
  </ppl-input-container>
  <br>
  <div>
    {{ value | json }}
  </div>
  <br>
  <br>
  <ppl-input-container label="example tree select loading">
    <ppl-tree-select [value]="value"
                     [options]="options"
                     [displayValueLoading]="true"
                     (valueChange)="onValueChange($event)">

    </ppl-tree-select>
  </ppl-input-container>
  <br>
  <br>
  <br>
  <ppl-input-container label="example single tree select">
    <ppl-tree-select [value]="singleValue"
                     [options]="singleOptions"
                     [collapsible]="true"
                     selectionMode="single"
                     [showChildrenOnMatch]="true"
                     (valueChange)="singleValue = $event">

    </ppl-tree-select>
  </ppl-input-container>

  <br>
  <div>
    value: {{ singleValue }}
  </div>
  <br>
  <br>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>