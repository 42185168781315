<div #container
     class="container"
     [style.height.px]="itemHeight">
  <button *ngFor="let option of options; trackBy: trackBy"
          type="button"
          class="item-button"
          [style.visibility]="isDropdownItem(option) ? 'hidden' : 'visible'"
          [style.height.px]="itemHeight"
          [style.width.px]="option.value === lastItemButtonBeforeDropdownID ? lastItemButtonBeforeDropdownWidth : 'auto'"
          [attr.title]="option.value === lastItemButtonBeforeDropdownID ? option.label : ''"
          [attr.tabindex]="disabled || option.disabled || isDropdownItem(option) ? -1 : 0"
          [attr.data-value]="option.value"
          [disabled]="disabled || option.disabled"
          [class.disabled]="disabled || option.disabled"
          [class.selected]="isSelected(option)"
          [class.error]="option.error"
          (click)="onItemToggle(option)">
    <div class="item-button-wrapper"
         pplPopover
         [pplPopoverTooltip]="option.error || option.disabled"
         [pplPopoverDisabled]="!option.error && !option.disabled"
         [pplPopoverTooltipType]="option.error ? 'error' : null">
      <ng-container *ngTemplateOutlet="optionTemplate || defaultOptionTemplate; context: {
        $implicit: option
      }"></ng-container>
    </div>
  </button>

  <button *ngIf="dropdownItemIds.length"
          #dropdownButton
          type="button"
          class="dropdown-item-button"
          [style.left.px]="dropdownButtonLeftOffset"
          [style.width.px]="itemHeight"
          [style.height.px]="itemHeight"
          [class.selected]="isSomeItemInDropdownSelected()"
          [class.hovered]="isDropdownOpened"
          [pplPopover]="dropdownItems"
          [pplPopoverAlignEnd]="true"
          (pplPopoverToggle)="onDropdownPopoverToggle($event.visible)">
    +{{ dropdownItemIds.length }}
  </button>
</div>

<ng-template #dropdownItems>
  <ppl-popover-dropdown>
    <ppl-menu (keyup)="onDropdownItemKeyup($event)"
              (valueChanged)="onDropdownItemToggle($event)">
      <ppl-menu-item *ngFor="let option of dropdownOptions; trackBy: trackBy"
                     [value]="option.value"
                     [disabled]="disabled || option.disabled"
                     [tabindex]="disabled || option.disabled ? -1 : 0">
        <div class="dropdown-item-container"
             [class.selected]="isSelected(option)"
             [class.disabled]="disabled || option.disabled"
             [class.error]="option.error"
             pplPopover
             [pplPopoverTooltip]="option.error || option.disabled"
             [pplPopoverDisabled]="!option.error && !option.disabled"
             [pplPopoverTooltipType]="option.error ? 'error' : null">
          <ng-container *ngTemplateOutlet="optionDropdownTemplate || defaultOptionDropdownTemplate; context: {
            $implicit: option
          }"></ng-container>
        </div>
      </ppl-menu-item>
    </ppl-menu>
  </ppl-popover-dropdown>
</ng-template>

<ng-template #defaultOptionTemplate
             let-option>
  <div class="item-container">
    <div *ngIf="option.icon"
         class="item-icon"
         [innerHTML]="getIconSVG(option.icon)"></div>
    <div class="item-label">{{ option.label }}</div>
  </div>
</ng-template>

<ng-template #defaultOptionDropdownTemplate
             let-option>
  <div *ngIf="option.icon"
       class="dropdown-item-icon"
       [innerHTML]="getIconSVG(option.icon)"></div>
  <div class="dropdown-item-label"
       title="{{ option.label }}">{{ option.label }}</div>
</ng-template>