import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplCarouselComponent } from './carousel.component';
import { PplCarouselSlideComponent } from './carousel-slide/carousel-slide.component';
import { PplIconModule } from '../icon';

@NgModule({
  imports: [CommonModule, PplIconModule],
  declarations: [PplCarouselComponent, PplCarouselSlideComponent],
  exports: [PplCarouselComponent, PplCarouselSlideComponent]
})
export class PplCarouselModule {}

export * from './carousel.component';
export * from './carousel-slide/carousel-slide.component';
