<div class="container demo-content">
  <h2>Input</h2>

  <br>

  <h3>Module</h3>
  <div>PplInputModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-input-container</h4>
  <div>@Input <b>label</b> (optional)</div>

  <br>

  <h3>Directives</h3>
  <h4>pplInput</h4>
  <div>@Input <b>value</b>: string</div>
  <div>@Input <b>disabled</b>: boolean (optional)</div>
  <div>@Input <b>id</b>: string (optional)</div>
  <div>@Input <b>placeholder</b>: string (optional)</div>
  <div>@Input <b>required</b>: boolean (optional)</div>
  <div>@Input <b>type</b>: string (optional)</div>
  <div>@Input <b>readonly</b>: boolean (optional)</div>

  <br>

  <h4>pplInputAutofill</h4>
  <h4>pplInputIcon</h4>

  <br>

  <h4>Example</h4>
  <ppl-input-container label="Input">
    <input pplInput>
  </ppl-input-container>

  <form #heroForm="ngForm" (ngSubmit)="onSubmit(heroForm)">
    <ppl-input-container label="Input required*">
      <input pplInput [(ngModel)]="hero.name" required name="name" #name="ngModel">
    </ppl-input-container>

    <ppl-input-container>
      <input type="submit" pplButton pplButton1 [disabled]="!heroForm.valid">
    </ppl-input-container>
  </form>

  <ppl-input-container label="Input with icon">
    <input pplInput>
    <ppl-icon svgIcon="search-form" pplInputIcon></ppl-icon>
  </ppl-input-container>

  <ppl-input-container label="Input readonly">
    <input pplInput readonly value="Readonly">
  </ppl-input-container>

  <ppl-input-container label="Input disabled">
    <input pplInput disabled value="Disabled">
  </ppl-input-container>

  <!-- TODO: make this example work -->
  <!-- <ppl-input-container label="Input error" [hasError]="true">
    <input pplInput value="Error state">
    <ppl-input-error>Error message</ppl-input-error>
  </ppl-input-container> -->

  <ppl-input-container label="Input autofill">
    <input pplInput pplInputAutofill="true" value="Autofill">
  </ppl-input-container>

  <ppl-input-container label="Textarea">
    <textarea pplInput></textarea>
  </ppl-input-container>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
