import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'ppl-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingOverlayComponent implements OnInit {
  @Input() large = false;
  @Input() text?: string;
  @Input() loading = true;
  @HostBinding('class.inverse') @Input() inverse = false;
  @HostBinding('class.with-logo') @Input() withLogo = false;
  @HostBinding('class.visible') @Input() visible = false;

  constructor() { }

  ngOnInit() {
  }
}
