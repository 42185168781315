import { InputPhoneNumber } from '../utils/input-phone-number.utils';
import { HttpClient } from '@angular/common/http';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AsyncLoader,
  DEPLOY_URL,
  fetchScript,
  FormValueControl,
  unsubscribe
} from '@ppl/utils';
import type { CountryData } from 'intl-tel-input';
import type { CountryCode } from 'libphonenumber-js/min';
import type { Subscription } from 'rxjs';
import { from, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap
} from 'rxjs/operators';

@Component({
  selector: 'ppl-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./input-phone-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneNumberComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class InputPhoneNumberComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: InputPhoneNumber;
  @Input() dropdownContainer: HTMLElement | null = document.body;
  @Input() disabled = false;
  // if not null, looks up country code and preselects it for the user in the component
  @Input() ipInfoLookupToken: string | null = null;

  @Output() valueChange = new EventEmitter<InputPhoneNumber>();
  @Output() countryChange = new EventEmitter<CountryData>();

  @ViewChild('phone', { static: true, read: ElementRef }) phoneInput: ElementRef<HTMLInputElement>;

  iti: intlTelInput.Plugin;

  asyncLoaderFetchSubscription: Subscription;

  private static readonly asyncLoader = new AsyncLoader<
    (node: Element, options?: intlTelInput.Options) => intlTelInput.Plugin
  >();

  get loading$() {
    return InputPhoneNumberComponent.asyncLoader.loading$;
  }

  constructor(
    @Inject(DEPLOY_URL) private deployUrl: string,
    private changeDetectorRef: ChangeDetectorRef,
    private httpClient: HttpClient
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.updateCountry(changes.value.currentValue);
    }
  }

  ngOnInit() {
    const self = this;
    const initPhoneInput = () => {
      const input = this.phoneInput.nativeElement;
      this.iti = window.intlTelInput(input, {
        separateDialCode: true,
        dropdownContainer: this.dropdownContainer,
        initialCountry: this.ipInfoLookupToken ? 'auto' : undefined,
        geoIpLookup: success => {
          self.httpClient.jsonp<any>(`https://ipinfo.io?token=${this.ipInfoLookupToken}`, 'callback').pipe(
            tap(resp => {
              const countryCode = (resp && resp.country) ? resp.country : 'us';
              success(countryCode);
            }),
            catchError(error => {
              const countryCode = 'us';
              success(countryCode);
              return of(null);
            })
          ).subscribe();
        },
      });
      this.updateCountry(this.value);
    };

    requestAnimationFrame(() => {
      this.phoneInput.nativeElement.blur();
    });
    this.asyncLoaderFetchSubscription = InputPhoneNumberComponent.asyncLoader.fetch(
      () => {
        return from(fetchScript(`${this.deployUrl}/assets/intl-tel-input/intlTelInput.min.js`)).pipe(
          switchMap(() => {
            return from(window.intlTelInputGlobals.loadUtils(`${this.deployUrl}/assets/intl-tel-input/utils.js`) as unknown as Promise<any>);
          }),
          tap(() => this.insertIntlTelInputInlineStyle(this.deployUrl)),
          map(() => window.intlTelInput)
        );
      }
    ).subscribe(() => {
      initPhoneInput();
    });
  }

  ngOnDestroy() {
    unsubscribe(this.asyncLoaderFetchSubscription);
    if (this.iti) {
      this.iti.destroy();
    }
  }

  onCountryChange() {
    if (!this.iti) {
      return;
    }

    const countryData = this.iti.getSelectedCountryData();
    this.valueChange.emit({
      country: countryData.iso2
        ? countryData.iso2.toUpperCase() as CountryCode
        : null,
      number: this.value
        ? this.value.number
        : ''
    });
    this.countryChange.emit(countryData);
  }

  onInputChange(event: any) {
    const eventTarget = event.target as HTMLInputElement;
    const countryData = this.iti.getSelectedCountryData();
    this.valueChange.emit({
      country: this.value
        ? this.value.country
        : countryData.iso2.toUpperCase() as CountryCode,
      number: eventTarget.value
    });
  }

  updateCountry(value: InputPhoneNumber) {
    if (value && value.country && this.iti) {
      this.iti.setCountry(value.country);
    }
  }

  private insertIntlTelInputInlineStyle(deployUrl: string) {
    const css = `
      .iti__flag {
        background-image: url("${deployUrl}/assets/intl-tel-input/flags.png");
      }

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .iti__flag {
        background-image: url("${deployUrl}/assets/intl-tel-input/flags@2x.png");
        }
      }
    `;

    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);
  }
}
