import type { ApolloBase, MutationResult } from 'apollo-angular';
import type { ApolloQueryResult, MutationOptions, QueryOptions } from '@apollo/client/core';
import type { Observable } from 'rxjs';
import type { DocumentNode } from 'graphql';
import origGql from 'graphql-tag';

export function gql(literals: any, ...placeholders: any[]) {
  return () => {
    if (placeholders) {
      placeholders = placeholders.map(placeholder => (typeof placeholder === 'function') ? placeholder() : placeholder);
    }

    return origGql(literals, ...placeholders) as DocumentNode;
  };
}

export function patchClient(client: ApolloBase<any>) {
  // Patch Apollo client instance to allow passing lazily parsed gql queries
  if (!(client as any).patched) {
    const mutate = client.mutate;
    const query = client.query;

    client.mutate = (...options: any[]) => {
      if (typeof options[0].mutation === 'function') {
        options[0].mutation = options[0].mutation();
      }

      return mutate.apply(client, options);
    };

    client.query = (...options: any[]) => {
      if (typeof options[0].query === 'function') {
        options[0].query = options[0].query();
      }

      return query.apply(client, options);
    };

    (client as any).patched = true;
  }

  return client as ApiPatchedClient;
}

interface ApiMutationOptions<T, V> extends MutationOptions<T, V> {
  mutation: any; // () => DocumentNode
}

interface ApiQueryOptions<V> extends QueryOptions<V> {
  query: any; // () => DocumentNode
}

export interface ApiPatchedClient extends ApolloBase {
  mutate<T, V = { [key: string]: any }>(options: ApiMutationOptions<T, V>): Observable<MutationResult<T>>;
  query<T, V = { [key: string]: any }>(options: ApiQueryOptions<V>): Observable<ApolloQueryResult<T>>;
}
