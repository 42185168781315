<div class="container demo-content">
  <h2>Graph Toggle</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-graph-toggle</h4>
  <div>@Input() <b>value</b>: boolean</div>
  <div>@Input() <b>disabled</b>: boolean</div>

  <br>
  <div>@Output() <b>valueChange</b></div>
  <br>

  <br>
  <br>

  <h4>Example</h4>
  <ppl-graph-toggle [value]="value"
                    (onValueChange)="onValueChange($event)"></ppl-graph-toggle>

  <br>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>