import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, ContentChild, HostBinding, Output, EventEmitter , TemplateRef} from '@angular/core';

@Component({
  selector: 'ppl-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplInfoBoxComponent implements OnInit {
  @Input() type: PplInfoBoxType = 'info';
  @Input() text: string | null;
  @Input() icon: string | null;
  @Input() buttonText: string | null;
  @Input() buttonDisabled = false;
  @Input() enableClose = false;
  @Input() drawerOpen = false;
  @Input() @HostBinding('style.borderRadius.px') borderRadius = 8;

  @Output() close = new EventEmitter();
  @Output() buttonClick = new EventEmitter();

  @ContentChild('textTemplate', { static: false }) textTemplate: TemplateRef<any>;
  @ContentChild('drawerTemplate', { static: false }) drawerTemplate: TemplateRef<any>;
  @ContentChild('actionsTemplate', { static: false }) actionsTemplate: TemplateRef<any>;

  get iconName() {
    switch (this.type) {
      case 'info':
        return 'info-green';
      case 'warning':
        return 'info-notification-blue';
      case 'danger':
        return 'notification-icon';
      case 'error':
        return 'error-notification';
      default:
        return 'info-green';
    }
  }

  @HostBinding('class')
  get className() {
    switch (this.type) {
      case 'info':
        return 'box-info';
      case 'warning':
        return 'box-warning';
      case 'danger':
        return 'box-danger';
      case 'error':
        return 'box-error';
      default:
        return 'box-info';
    }
  }

  constructor() { }

  ngOnInit() {
  }

}

export type PplInfoBoxType = 'info' | 'warning' | 'danger' | 'error';
