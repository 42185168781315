/* eslint-disable @typescript-eslint/consistent-type-imports */
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs';

export type TControlsConfig<T extends object> = {
  [K in keyof T]: [T[K], (TValidatorFn<T[K]> | TValidatorFn<T[K]>[])?] | [{ value: T[K], disabled?: boolean }, any?] | TAbstractControl<T[K]>;
};

export class TFormBuilder extends UntypedFormBuilder {
  group<T extends object>(
    controlsConfig: TControlsConfig<T>,
    options?: AbstractControlOptions | null
  ): TFormGroup<T> {
    return super.group(controlsConfig, options) as any as TFormGroup<T>;
  }

  array<T>(
    controlsConfig: Array<TFormGroup<T> | TAbstractControl<T>>,
    options?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ): TFormArray<T> {
    return super.array(controlsConfig, options, asyncValidator) as any as TFormArray<T>;
  }

  control<T extends any>(
    formState: T,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    return super.control(formState, validatorOrOpts, asyncValidator) as TFormControl<T>;
  }
}

export interface TFormGroup<T> extends TAbstractControl<T> {
  controls: {
    [K in keyof T]: TAbstractControl<T[K]>;
  };

  registerControl<K extends keyof T>(name: K, control: TAbstractControl<T[K]>): TAbstractControl<T[K]>;

  addControl<K extends keyof T>(name: K, control: TAbstractControl<T[K]>): void;

  removeControl<K extends keyof T>(name: K): void;

  setControl<K extends keyof T>(name: K, control: TAbstractControl<T[K]>): void;

  contains<K extends keyof T>(controlName: K): boolean;

  setValue(value: T, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void;

  patchValue(value: Partial<T>, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void;

  reset(value?: Partial<T>, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void;

  getRawValue(): T;
}

export interface TFormArray<T> extends UntypedFormArray {
  readonly value: T[];
  readonly controls: TAbstractControl<T>[];
  readonly valueChanges: Observable<T[]>;
  readonly root: TAbstractControl<any>;

  at(index: number): TAbstractControl<T>;

  push(control: TAbstractControl<T>): void;

  insert(index: number, control: TAbstractControl<T>): void;

  setControl(index: number, control: TAbstractControl<T>): void;

  setValue(value: T[], options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void;

  patchValue(value: T[], options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void;

  reset(value?: T[], options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }): void;

  getRawValue(): T[];

  clear(): void;
}

export interface TAbstractControl<T extends any> extends AbstractControl {
  readonly value: T;
  readonly valueChanges: Observable<T>;
  readonly root: TAbstractControl<any>;
  /**
   * Sets the value of the control. Abstract method (implemented in sub-classes).
   */
  setValue(value: T, options?: Object): void;
  /**
   * Patches the value of the control. Abstract method (implemented in sub-classes).
   */
  patchValue(value: Partial<T>, options?: Object): void;
  /**
   * Resets the control. Abstract method (implemented in sub-classes).
   */
  reset(value?: Partial<T>, options?: Object): void;

  get<K extends keyof T>(path: K): TAbstractControl<T[K]>;
  get<K extends Array<number | string>>(path: K): TAbstractControl<any>;
  get<K extends string>(path: K): TAbstractControl<any>;
}

export interface TFormControl<T extends any> extends UntypedFormControl {
  readonly value: T;
  readonly valueChanges: Observable<T>;
  readonly root: TAbstractControl<any>;

  /**
   * Sets the value of the control. Abstract method (implemented in sub-classes).
   */
  setValue(value: T, options?: Object): void;
  /**
   * Patches the value of the control. Abstract method (implemented in sub-classes).
   */
  patchValue(value: Partial<T>, options?: Object): void;
  /**
   * Resets the control. Abstract method (implemented in sub-classes).
   */
  reset(value?: Partial<T>, options?: Object): void;
}

export type TValidatorFn<T> = (control: TAbstractControl<T>) => ValidationErrors | null;
