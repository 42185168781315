import type { SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs';

export function notFirstChange(change?: SimpleChange) {
  return !!change && change.previousValue !== change.currentValue && !change.firstChange;
}

export function Unsubscribe() {
  return function (constructor: { prototype: {} }) {
    const orig = (constructor.prototype as any).ngOnDestroy;
    (constructor.prototype as any).ngOnDestroy = function () {
      Object.values(this)
        .filter(property => property instanceof Subscription)
        .forEach((property: Subscription) => property.unsubscribe());

      if (orig) {
        orig.apply(this);
      }
    };
  };
}

export function trackById(index, item: { id: string }) {
  return item.id;
}

export function trackByKey<T>(key: keyof T) {
  return (index, item: { [id in keyof T]: any }) => {
    return item[key];
  };
}

export function trackByValue(index, item: { value: string }) {
  return item.value;
}

export interface TSimpleChange<T extends any> extends SimpleChange {
  previousValue: T;
  currentValue: T;
}

export type TSimpleChanges<T extends object> = {
  [K in keyof T]: TSimpleChange<T[K]>;
};
