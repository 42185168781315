import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplEditableComponent } from './editable.component';
import { PplEditableAutofocusDirective } from './autofocus.directive';
import { PplIconModule } from '../icon';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    PplEditableComponent,
    PplEditableAutofocusDirective
  ],
  exports: [
    PplEditableComponent
  ]
})
export class PplEditableModule {
}

export * from './editable.component';
