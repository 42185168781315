import { PplCopyIconComponent } from './copy-icon/copy-icon.component';
import { PplIconButtonDirective } from './icon-button.directive';
import { PplIconComponent } from './icon.component';
import { PplInformationIconComponent } from './info-icon/information-icon.component';
import { PplSvgIconComponent } from './svg-icon/svg-icon.component';
import { PplWarningIconComponent } from './warning-icon/warning-icon.component';
import { PplLoadingModule } from '../loading';
import { PplPopoverTooltipModule } from '../popover-tooltip/index';
import { PplPopoverModule } from '../popover/index';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PplPopoverModule,
    PplPopoverTooltipModule,
    PplLoadingModule
  ],
  declarations: [
    PplIconComponent,
    PplInformationIconComponent,
    PplWarningIconComponent,
    PplCopyIconComponent,
    PplIconButtonDirective,
    PplSvgIconComponent
  ],
  exports: [
    PplIconComponent,
    PplInformationIconComponent,
    PplWarningIconComponent,
    PplCopyIconComponent,
    PplIconButtonDirective,
    PplSvgIconComponent
  ]
})
export class PplIconModule {
}

export * from './icon.component';
export * from './icon.service';

