import { TreeSelectComponent } from './tree-select.component';
import { PplCheckboxModule } from '../checkbox';
import { PplFormTableModule } from '../form-table';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { PplOptionListModule } from '../option-list';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplSearchModule } from '../search';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplSlideToggleModule } from '../slide-toggle/index';
import { TreeSelectItemComponent } from './tree-select-item/tree-select-item.component';
import { PplAvatarModule } from '../avatar';
import { TreeSelectItemsComponent } from './tree-select-items/tree-select-items.component';
import { PplHighlightModule } from '../highlight';
import { TreeSelectOptionsListComponent } from './tree-select-options-list/tree-select-options-list.component';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplPopoverModule,
    PplLoadingModule,
    PplSearchModule,
    PplOptionListModule,
    PplCheckboxModule,
    PplPopoverDropdownModule,
    PplFormTableModule,
    PplSlideToggleModule,
    PplAvatarModule,
    PplHighlightModule
  ],
  declarations: [
    TreeSelectComponent,
    TreeSelectItemComponent,
    TreeSelectItemsComponent,
    TreeSelectOptionsListComponent
  ],
  exports: [
    TreeSelectComponent
  ]
})
export class PplTreeSelectModule {
}

export * from './tree-select.component';
