import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject
} from '@angular/core';
import { PPL_DIALOG_DATA, PplDialogRef } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-demo-open-dialog',
  templateUrl: './demo-open-dialog.component.html',
  styleUrls: ['./demo-open-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoOpenDialogComponent implements OnInit {

  constructor(
    public dialogRef: PplDialogRef<DemoOpenDialogComponent>,
    @Inject(PPL_DIALOG_DATA) public data: DemoOpenDialogData,
  ) { }

  ngOnInit() {
  }
}

export interface DemoOpenDialogData {
  type: 'default' | 'overlay';
}
