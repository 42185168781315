<div class="content">
  <ppl-icon [svgIcon]="icon ? icon : iconName"
            width="18px"
            height="18px"
            class="type-icon"></ppl-icon>

  <div class="box_text">
    <ng-template *ngIf="textTemplate"
                 [ngTemplateOutlet]="textTemplate"></ng-template>

    <ng-container *ngIf="!textTemplate && text">{{ text }}</ng-container>
  </div>

  <div class="actions">
    <ng-template *ngIf="actionsTemplate"
                 [ngTemplateOutlet]="actionsTemplate"></ng-template>

    <ppl-button *ngIf="!actionsTemplate && buttonText"
                pplButton
                pplButtonSmall="xs"
                [pplButton7]="type"
                [disabled]="buttonDisabled"
                (click)="buttonClick.emit()">{{ buttonText }}</ppl-button>
    <ppl-icon *ngIf="enableClose"
              svgIcon="closering-red2"
              class="box_close"
              width="18px"
              height="18px"
              (click)="close.emit()"></ppl-icon>
  </div>
</div>
<div *ngIf="drawerOpen"
     class="drawer">
  <ng-template *ngIf="drawerTemplate"
               [ngTemplateOutlet]="drawerTemplate"></ng-template>
</div>
