<div class="container"
     [class.container-vertical]="vertical"
     [class.container-vertical-top]="position === 'top'"
     [class.container-horizontal]="horizontal"
     [class.container-horizontal-left]="position === 'left'">
     <ppl-drawer-control *ngIf="showControl"
                         [disabled]="disabled"
                         [position]="position"
                         [open]="showContent$ | async"
                         (openChange)="onControlClick()"></ppl-drawer-control>
     <div *ngIf="(showContent$ | async) && resizable"
          pplResizable
          [pplResizableOrientation]="position"
          [pplResizableSize]="detailSize"
          [pplResizableActive]="resizable"
          [pplResizableSizeConstraints]="sizeConstraints"
          (pplResizableSizeChange)="onDetailSizeChange($event)"
          [class.container-vertical-border]="vertical"
          [class.container-horizontal-border]="horizontal"
          [class.container-vertical-draggable]="resizable && vertical"
          [class.container-horizontal-draggable]="resizable && horizontal"></div>

     <div *ngIf="(showContent$ | async) && !resizable"
          [class.container-vertical-border]="vertical"
          [class.container-horizontal-border]="horizontal"></div>

     <div *ngIf="showContent$ | async"
          [style.height]="height"
          [style.width]="width"
          [class.container-vertical-content]="vertical"
          [class.container-horizontal-content]="horizontal">
          <ng-content></ng-content>
     </div>
</div>