import { ApolloLink, Observable as ApolloObservable } from '@apollo/client/core';
import type { Operation } from '@apollo/client/core';
import type { ExecutionResult, GraphQLError } from 'graphql';
import type { Observable } from 'rxjs';
import { concat } from 'rxjs';
import { debounceTime, first, skip } from 'rxjs/operators';

const apolloProperties = ['__typename', 'Symbol(id)'];

export function apolloClean(data, removeApolloProperties = true) {
  if (Array.isArray(data)) {
    return data.map(value => apolloClean(value, removeApolloProperties));
  } else if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((dataCopy, key) => {
      if (removeApolloProperties === false || !apolloProperties.includes(key)) {
        dataCopy[key] = apolloClean(data[key], removeApolloProperties);
      }
      return dataCopy;
    }, {});
  }

  return data;
}

export function debounceAfterFirst(observable: Observable<any>, debounce: number) {
  return concat(observable.pipe(first()), observable.pipe(skip(1), debounceTime(debounce)));
}

export interface GraphQLInput<T> {
  input: T;
}


/**
 * Following part is fork of apollo-link-error repository as there were some customizations needed
 * TODO: check later if official repo is working as expected
 */
export interface ErrorResponse {
  graphQLErrors?: GraphQLError[];
  networkError?: Error;
  response?: ExecutionResult;
  operation: Operation;
}

export namespace ErrorLink {
  /**
   * Callback to be triggered when an error occurs within the link stack.
   */
  export interface ErrorHandler {
    (error: ErrorResponse): void;
  }
}

// For backwards compatibility.
export import ErrorHandler = ErrorLink.ErrorHandler;

export const onError = (errorHandler: ErrorHandler): ApolloLink => {
  return new ApolloLink((operation, forward) => {
    return new ApolloObservable(observer => {
      let sub;
      try {
        sub = forward(operation).subscribe({
          next: result => {
            if (result.errors) {
              errorHandler({
                graphQLErrors: result.errors as any, // TODO: Fix typing
                response: result,
                operation,
              });
            }
            observer.next(result);
          },
          error: networkError => {
            errorHandler({
              operation,
              networkError,
              // Network errors can return GraphQL errors on for example a 403
              graphQLErrors: networkError.result && networkError.result.errors,
            });
            observer.error(networkError);
          },
          complete: observer.complete.bind(observer),
        });
      } catch (e) {
        errorHandler({ networkError: e, operation });
        observer.error(e);
      }

      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    });
  });
};

export class ErrorLink extends ApolloLink {
  private link: ApolloLink;

  constructor(errorHandler: ErrorLink.ErrorHandler) {
    super();
    this.link = onError(errorHandler);
  }

  public request(operation, forward): ApolloObservable<ExecutionResult> | null {
    return this.link.request(operation, forward);
  }
}
export function getFormattedGraphqlError(error: (GraphQLError & { api_error: any }), pretty = false) {
  // eslint:disable-next-line: max-line-length
  return `[Pipeliner GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(error.locations)}, Path: ${JSON.stringify(error.path)}${error.api_error ? `, ApiError: ${JSON.stringify(error.api_error, null, pretty ? 2 : null)}` : ''}`;
}
