<span *ngIf="isEditing"
      class="is-editing">
  <input [value]="tempValue"
         (keyup)="setTempValue($event.target.value, $event.key)"
         pplEditableAutofocus>
  <ppl-icon [width]="iconSize"
            [height]="iconSize"
            [svgIcon]="checkIcon"
            class="confirm"
            (click)="confirmEdit()"
            [hoverEnabled]="true"></ppl-icon>&nbsp;
  <ppl-icon [width]="iconSize"
            [height]="iconSize"
            [svgIcon]="closeIcon"
            class="cancel"
            (click)="cancelEdit()"
            [hoverEnabled]="true"></ppl-icon>
</span>
<span *ngIf="!isEditing"
      class="no-editing"
      (click)="startEdit()"
      title="Click to edit"
      #editable>
  <span class="value">{{!!placeholder && !value ? placeholder : value}}</span>
  <ppl-icon [width]="iconSize"
            [height]="iconSize"
            *ngIf="!disabled"
            [svgIcon]="editIcon"
            class="edit"
            [actionElement]="editable"
            [hoverEnabled]="true"></ppl-icon>
</span>
