import { ErrorCode } from './enums/error-code';

/**
 * these are user friendly errors
 * - possible to be reported to user
 * - all of these should be also translated
 */
export const logicalUserErrors: ErrorCode[] = [
  ErrorCode.ERROR_NOT_ENOUGH_PERMISSIONS,
  ErrorCode.ERROR_USER_OWNS_OPPTY,
  ErrorCode.ERROR_PARAMETER_INVALID,
  ErrorCode.ERROR_PARAMETER_UNKNOWN,
  ErrorCode.ERROR_PARAMETER_MISSING,
  ErrorCode.ERROR_LEAD_NOT_FOUND,
  ErrorCode.ERROR_LEAD_ALREADY_ASSIGNED,
  ErrorCode.ERROR_SALES_UNIT_DOESNT_EXISTS,
  ErrorCode.ERROR_CLIENT_DOESNT_EXISTS,
  ErrorCode.ERROR_CLIENT_DOESNT_EXISTS_IN_SALES_UNIT,
  ErrorCode.ERROR_ACCOUNT_DOESNT_EXISTS,
  ErrorCode.ERROR_NO_ENTITY,
  ErrorCode.ERROR_NO_PRIMARY_EMAIL,
  ErrorCode.ERROR_NO_PRIMARY_PHONE,
  ErrorCode.ERROR_NOT_DELETABLE,
  ErrorCode.ERROR_CALC_BAD_FORMULA_SYNTAX,
  ErrorCode.ERROR_CALC_CIRCULAR_REFERENCE,
  ErrorCode.ERROR_CALC_DIVIDED_BY_0,
  ErrorCode.ERROR_CALC_INVALID_REFERENCE,
  ErrorCode.ERROR_CALC_WRONG_TYPE,
  ErrorCode.ERROR_CALC_NO_VALUE,
  ErrorCode.ERROR_CALC_FORMULA_NOT_ALLOWED,
  ErrorCode.ERROR_CALC_VALIDATION_FAILED,
  ErrorCode.ERROR_MINITEMS_REQUIRED,
  ErrorCode.ERROR_FORM_UNKNOWN,
  ErrorCode.ERROR_IMAP_HOST_UNREACHABLE,
  ErrorCode.ERROR_IMAP_INCORRECT_LOGIN,
  ErrorCode.ERROR_IMAP_FOLDER_LIST_ERROR,
  ErrorCode.ERROR_REPORT_IS_TOO_LARGE,
  ErrorCode.ERROR_REPORT_HAS_EXPIRED,
  ErrorCode.ERROR_RELATIONS_DOESNT_EXIST,
  ErrorCode.ERROR_NO_MORE_LICENSE_SEATS_AVAILABLE,
  ErrorCode.ERROR_JSON_FORM_PARENT_DATASET_MISSING,
  ErrorCode.ERROR_NOT_DIRECT_RELATION,
  ErrorCode.ERROR_PARAMETER_INVALID_MIN_STEPS,
  ErrorCode.ERROR_PARAMETER_INVALID_MAX_STEPS,
  ErrorCode.ERROR_ATTRIBUTE_NOT_UNIQUE,
  ErrorCode.ERROR_3RD_PARTY_API_ERROR_AUTHORIZATION_FAIL,
  ErrorCode.ERROR_MISSING_SCHEMA,
  ErrorCode.ERROR_NO_PIPELINE_ACCESS,
  ErrorCode.ERROR_JSON_FORM_INVALID,
  ErrorCode.ERROR_TOO_MANY_CUSTOM_FIELDS,
  ErrorCode.ERROR_SUBSCRIPTION_HAS_EXPIRED,
  ErrorCode.ERROR_SCHEDULED_ACTION_ALREADY_FINISHED,
  ErrorCode.ERROR_HYBRID_SPACE_IS_REQUIRED,
  ErrorCode.ERROR_FEATURE_NOT_ALLOWED,
  ErrorCode.ERROR_INVALID_ROLLUP_CONFIGURATION,
  ErrorCode.ERROR_TOO_MANY_REQUESTS,
  ErrorCode.ERROR_ALREADY_IN_PROGRESS,
  ErrorCode.ERROR_REMOTE_FILE_NOT_FOUND,
  ErrorCode.ERROR_OCCUPIED_SCHEDULER_URL,
  ErrorCode.ERROR_FORBIDDEN_EXTENSION,
  ErrorCode.ERROR_REQUIRED_CALC_FORMULA_EMPTY,
  ErrorCode.ERROR_EMAIL_NOT_SHARED,
  ErrorCode.ERROR_ENTITY_TOO_LARGE,
];

// explicitely disable reporting of these errors to sentry
export const ignoredSentryErrors: ErrorCode[] = [
  ErrorCode.ERROR_ENTITY_VALIDATION,
  ErrorCode.ERROR_CONFLICTING_DATA,
  ErrorCode.ERROR_NO_ENTITY,
  ErrorCode.ERROR_UNAVAILABLE_COMPANY_EMAIL,
  ErrorCode.ERROR_3RD_PARTY_API_TOKEN_EXPIRED,
  ErrorCode.ERROR_FIELD_ACCESS_INVALID_CONDITION,
  ErrorCode.ERROR_SIMULTANEOUS_UPDATE
];

/**
 * This errors wont be translated, instead message from API error will be shown
 */
export const logicalUserErrorsMessages: ErrorCode[] = [
  ErrorCode.ERROR_ATTRIBUTE_NOT_UNIQUE,
  ErrorCode.ERROR_INVALID_ROLLUP_CONFIGURATION,
  ErrorCode.ERROR_FORBIDDEN_EXTENSION
];
