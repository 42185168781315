import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'ppl-screen-message',
  templateUrl: './screen-message.component.html',
  styleUrls: ['./screen-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScreenMessageComponent {

  @Input() icon: string;
  @Input() label: string;
  @Input() description: string;
  @Input() minified: boolean;

}
