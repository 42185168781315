import { PplFormControlErrorsComponent } from './form-control-errors/form-control-errors.component';
import { PplInputAutofillDirective } from './input-autofill.directive';
import { PplInputContainerComponent } from './input-container/input-container.component';
import { PplInputCopyComponent } from './input-copy/input-copy.component';
import { PplInputEmailComponent } from './input-email/input-email.component';
import { PplInputErrorComponent } from './input-error/input-error.component';
import { PplInputIconDirective } from './input-icon.directive';
import { PplInputIntl } from './input-intl';
import { PplInputNumberComponent } from './input-number/input-number.component';
import { PplInputPhoneComponent } from './input-phone/input-phone.component';
import { PplInputUrlComponent } from './input-url/input-url.component';
import { PplInputDirective } from './input.directive';
import { PplButtonModule } from '../button';
import { PplIconModule } from '../icon';
import { PplPopoverModule } from '../popover';
import { PplPopoverTooltipModule } from '../popover-tooltip';
import { CommonModule } from '@angular/common';
import type { ModuleWithProviders} from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

const PPL_MODULE_DECLARATIONS = [
  PplInputContainerComponent,
  PplInputDirective,
  PplInputAutofillDirective,
  PplInputIconDirective,
  PplInputErrorComponent,
  PplFormControlErrorsComponent,
  PplInputEmailComponent,
  PplInputUrlComponent,
  PplInputPhoneComponent,
  PplInputNumberComponent,
  PplInputCopyComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PplIconModule,
    PplButtonModule,
    PplPopoverModule,
    PplPopoverTooltipModule
  ],
  declarations: [
    PPL_MODULE_DECLARATIONS
  ],
  exports: [
    PPL_MODULE_DECLARATIONS
  ]
})
export class PplInputModule {
  static forRoot(): ModuleWithProviders<PplInputModule> {
    return {
      ngModule: PplInputModule,
      providers: [
        PplInputIntl
      ]
    };
  }
}

export * from './input-container/input-container.component';
export * from './input-number/input-number.component';
export * from './input-error/input-error.component';
export * from './input-icon.directive';
export * from './input-intl';
export * from './input.directive';

