<ppl-icon [svgIcon]="iconName"
          width="14px"
          height="14px"
          pplPopover
          pplPopoverAlignStart
          [pplPopoverTooltip]="message"
          [pplPopoverDisabled]="!message"
          [pplPopoverTooltipDirection]="tooltipDirection"
          [pplPopoverTooltipPreserveWhitespace]="tooltipPreserveWhitespace"
          [style.display]="'block'">
</ppl-icon>
