import type {
  AfterContentInit,
  OnChanges,
  OnInit,
  SimpleChanges

} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Output
,
  ChangeDetectorRef,
  ElementRef,
  QueryList,
  Renderer2
} from '@angular/core';
import { notFirstChange } from '@ppl/utils';

@Component({
  selector: 'ppl-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplNavBarComponent implements OnChanges, OnInit, AfterContentInit {
  @ContentChildren(forwardRef(() => PplNavBarItemComponent)) menuItems: QueryList<PplNavBarItemComponent>;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.value)) {
      this.reselectMenuItem();
    }
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.reselectMenuItem();
    this.menuItems.changes.subscribe(() => this.reselectMenuItem());
  }

  emitValueChanged(value: string) {
    this.reselectMenuItem();
    this.valueChange.emit(value);
  }

  private reselectMenuItem() {
    this.menuItems.forEach(menuItem => {
      menuItem.selected = (menuItem.value === this.value);
      menuItem.changeDetectorRef.markForCheck();
    });
  }
}

@Component({
  selector: 'ppl-nav-bar-item',
  templateUrl: './nav-bar-item/nav-bar-item.component.html',
  styleUrls: ['./nav-bar-item/nav-bar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplNavBarItemComponent implements OnChanges, OnInit {
  @Input() @HostBinding('class.selected') selected: boolean;
  @Input() @HostBinding('attr.title') title: string | null = null;
  @Input() value: string;

  constructor(
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    public changeDetectorRef: ChangeDetectorRef,
    public parentNavBarComponent: PplNavBarComponent,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && !changes['value'].isFirstChange() && changes['value'].currentValue !== changes['value'].previousValue) {
      this.selected = this.value === this.parentNavBarComponent.value;
      if (this.selected) {
        this.parentNavBarComponent.emitValueChanged(this.value);
      }
    }
  }

  ngOnInit() {
    this.selected = this.value === this.parentNavBarComponent.value;
  }

  @HostListener('click')
  onClick() {
    this.parentNavBarComponent.emitValueChanged(this.value);
  }
}
