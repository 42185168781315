<div class="container demo-content">
  <h2>Multiple autocomplete</h2>
  <br>

  <h3>Components</h3>
  <h4>ppl-multiple-autocomplete</h4>
  <div>@Input() <b>options</b>: PplMultipleAutocompleteOption[]</div>
  <div>@Input() <b>value</b>: string[]</div>
  <div>@Input() <b>disabled</b> = false</div>
  <div>@Input() <b>freeValue</b> = false</div>
  <div>@Input() <b>freeValueValidator?</b>: (value: string) => boolean</div>
  <div>@Input() <b>maxValues?</b>: number</div>
  <div>@Input() <b>placeholder</b>: string = 'start typing...'</div>
  <br>
  <div>@Output() <b>valueChange?</b>: string[]</div>
  <br>
  <br>

  <h3>Example</h3>
  <ppl-input-container label="Basic">
    <ppl-multiple-autocomplete [options]="options"
                               [value]="value"
                               placeholder="Placeholder"
                               (valueChange)="value = $event"></ppl-multiple-autocomplete>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-multiple-autocomplete [options]="options"
                               [value]="value"
                               placeholder="Placeholder"
                               [disabled]="true"
                               (valueChange)="value = $event"></ppl-multiple-autocomplete>
  </ppl-input-container>

  <div>
    {{value | json}}
  </div>

  <br />

  <ppl-input-container label="Many options">
    <ppl-multiple-autocomplete [options]="options1"
                               [value]="value1"
                               placeholder="Placeholder"
                               (valueChange)="value1 = $event"></ppl-multiple-autocomplete>
  </ppl-input-container>

  <div>
    {{value1 | json}}
  </div>

  <br />

  <ppl-input-container label="Free value (email validator)">
    <ppl-multiple-autocomplete [options]="options2"
                               [value]="value2"
                               [freeValue]="true"
                               [freeValueValidator]="emailValidator"
                               placeholder="Select or write e-mail addresses"
                               (valueChange)="value2 = $event"></ppl-multiple-autocomplete>
  </ppl-input-container>

  <ppl-input-container label="Categories">
    <ppl-multiple-autocomplete [options]="options3"
                               [value]="value3"
                               [categories]="categories"
                               placeholder="Placeholder"
                               (valueChange)="value3 = $event"></ppl-multiple-autocomplete>
  </ppl-input-container>

  <div>
    {{value2 | json}}
  </div>
  <br>
  <br>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
