import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplRadioButtonComponent, PplRadioGroupComponent } from './radio';
import { PplInputModule } from '../input';

@NgModule({
  imports: [
    CommonModule,
    PplInputModule
  ],
  declarations: [
    PplRadioGroupComponent,
    PplRadioButtonComponent
  ],
  exports: [
    PplRadioGroupComponent,
    PplRadioButtonComponent
  ]
})
export class PplRadioModule {
}

export * from './radio';
export * from './radio-change';
