<div class="container demo-content">
  <h2>Bottom bar</h2>

  <br>

  <h3>Module</h3>
  <div>PplBottomBarModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-bottom-bar</h4>

  <br>

  <h3>Directives</h3>
  <h4>pplBottomBarButton</h4>

  <br>

  <h4>Example</h4>

  <br>
  <br>

  <div style="position: relative">
    <ppl-bottom-bar>
      <button pplBottomBarButton>Oppty</button>
      <button pplBottomBarButton>Contact</button>
      <button pplBottomBarButton>Account</button>
    </ppl-bottom-bar>
  </div>

  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
