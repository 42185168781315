import type { PplAutocompleteOptionsRequest } from '../autocomplete/autocomplete.component';
import { ExternalFilter } from '../autocomplete/external-filter';
import { fuzzySearch } from '../autocomplete/fuzzy-search';
import { PplPopoverDirective } from '../popover/popover.directive';
import { PplUiIntl } from '../ppl-ui-intl';
import { PplSearchComponent } from '../search/search.component';
import type {
  OnChanges,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  TemplateRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, KEYCODE_ENTER, KEYCODE_TAB } from '@ppl/utils';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';


@Component({
  selector: 'ppl-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplMultipleSelectComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplMultipleSelectComponent implements OnInit, OnChanges {

  @Input() value: string[];
  @Input() categories: PplMultipleSelectCategory[];
  @Input() options: PplMultipleSelectOption[];
  @Input() selectedFirst = true;

  @Input() disabled = false;
  @Input() displayValueLoading = false;
  @Input() displayOptionsLoading = false;
  @Input() placeholder?: string;
  @Input() boldPlaceholder?: boolean;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() selectedOptionTemplate?: TemplateRef<any>;
  @Input() maxContainerHeight?: number;

  @Output() valueChange = new EventEmitter<string[]>();
  @Output() optionsRequest = new EventEmitter<PplMultipleSelectOptionsRequest>();

  @ViewChild(PplPopoverDirective, { static: true }) optionsPopover: PplPopoverDirective;
  @ViewChild('selectedValues', { static: false }) selectedValues: ElementRef;
  @ViewChild('search', { static: false }) search: PplSearchComponent;
  @ViewChild('container', { static: true, read: ElementRef }) container: ElementRef;
  @ViewChild('optionsContainer', { static: false, read: ElementRef }) optionsContainer: ElementRef;

  focused = false;
  listSelectedIndex = -1;
  filter = '';
  externalFilter: ExternalFilter;
  availableOptions: PplMultipleSelectOption[];

  dropdownMinWidth = 0;
  dropdownMaxWidth = 0;
  dropdownAlignStart = false;
  dropdownAlignEnd = false;

  optionValueCache: { [id: string]: PplMultipleSelectOption } = {};

  get selectedOptions() {
    const values = this.value;
    const options = [];

    if (values) {
      values.forEach(value => {
        const foundOption = this.options.find(option => option.value === value);
        const cachedOption = this.optionValueCache[value];
        if (foundOption || cachedOption) {
          options.push(foundOption || cachedOption);
        }
      });
    }

    return options;
  }

  get selectedValuesLabel() {
    const selectedOptions = this.selectedOptions;
    return selectedOptions.length
      ? this.formatOptions(selectedOptions)
      : this.optionsPopover.isOpen
        ? this.formatOptions(selectedOptions)
        : this.placeholder;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private intl: PplUiIntl,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.updateAvailableOptions();

      setTimeout(() => {
        this.optionsPopover.recalcPosition();
      });
    }
  }

  ngOnInit() {
    if (this.placeholder === undefined) {
      this.placeholder = this.intl.clickToSearch;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      !this.container.nativeElement.contains(event.target) &&
      (this.optionsContainer && !this.optionsContainer.nativeElement.contains(event.target))
    ) {
      this.closePopover();
      setTimeout(() => {
        this.selectedValues.nativeElement.focus();
      });
    }
  }

  onContainerClick(event: MouseEvent) {
    if (!this.disabled && !this.displayValueLoading) {
      if (this.optionsPopover.isOpen) {
        this.closePopover();
      } else if (!this.optionsPopover.isOpen) {
        this.openPopover();
      }
      this.selectedValues.nativeElement.focus();
    }
  }

  onContainerKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYCODE_ENTER:
        if (!this.optionsPopover.isOpen) {
          event.stopPropagation();
          this.openPopover();
        }
        break;
      case KEYCODE_TAB:
        if (this.optionsPopover.isOpen) {
          this.closePopover();
          this.selectedValues.nativeElement.focus();
        }
        break;
    }
  }

  onOptionsContainerKeydown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYCODE_TAB:
        if (this.optionsPopover.isOpen) {
          this.closePopover();
          setTimeout(() => {
            this.selectedValues.nativeElement.focus();
          });
        }
        break;
    }
  }

  onFocus() {
    if (!this.disabled) {
      this.focused = true;
    }
  }

  onBlur() {
    if (this.focused && !this.optionsPopover.isOpen) {
      this.focused = false;
    }
  }

  onOptionSearchClick(event: MouseEvent) {
    event.stopPropagation();
    setTimeout(() => {
      this.search.searchInput.nativeElement.focus();
    });
  }

  onOptionSearch(filter: string) {
    this.filter = filter;

    if (this.externalFilter) {
      this.externalFilter.next(filter);
    } else {
      this.updateAvailableOptions();
    }
  }

  onOptionClear(event: MouseEvent) {
    this.valueChange.emit([]);
  }

  onOptionSelect(option: PplMultipleSelectOption) {
    const currentValues = this.value ? this.value : [];
    const values = currentValues.includes(option.value)
      ? currentValues.filter(value => value !== option.value)
      : [...currentValues, option.value];

    this.valueChange.emit(values);
  }

  onOptionSelectAll(event: MouseEvent) {
    const values = this.availableOptions.map(option => option.value);
    this.valueChange.emit(values);
  }

  onInputMouseDown() {
    const container = this.container.nativeElement;
    const containerRect = container.getBoundingClientRect();

    this.dropdownMinWidth = containerRect.width + 2; // +2px due to popover-content "fix" for floating point issues in Chrome
    this.dropdownMaxWidth = this.dropdownMinWidth * 2;

    if (containerRect.left < window.innerWidth / 2) {
      this.dropdownAlignStart = true;
      this.dropdownAlignEnd = false;
    } else if (containerRect.right > window.innerHeight / 2) {
      this.dropdownAlignStart = false;
      this.dropdownAlignEnd = true;
    }
  }

  onListScrollEnd() {
    if (this.externalFilter && !this.displayOptionsLoading) {
      this.externalFilter.listEnd(this.options[this.options.length - 1]);
    }
  }

  updateOptionValueCache(options: PplMultipleSelectOption[]) {
    options.forEach(option => {
      this.optionValueCache[option.value] = option;
    });
  }

  updateAvailableOptions() {
    const selectedValues = this.value ? this.value : [];
    const options = [...this.options].filter(option => !option.deleted || selectedValues.includes(option.value));
    if (this.selectedFirst) {
      options.sort((a, b) => {
        return selectedValues.includes(a.value)
          ? -1
          : selectedValues.includes(b.value) ? 1 : 0;
      });
    }

    this.updateOptionValueCache(this.options);

    if (this.isExternalFilter()) {
      this.availableOptions = options;
    } else {
      this.availableOptions = fuzzySearch({ list: options, term: this.filter });
    }

    this.listSelectedIndex = (this.availableOptions.length !== 0)
      ? Math.min(Math.max(this.listSelectedIndex, 0), this.availableOptions.length - 1)
      : -1;
  }

  onOptionValueChange(value: string) {
    this.listSelectedIndex = this.getOptionIndexByValue(value);
  }

  getOptionIndexByValue(value: string) {
    return this.availableOptions.findIndex(option => option.value === value);
  }

  formatOptions(options: PplMultipleSelectOption[]) {
    return options.map(option => option.label).join(', ');
  }

  isChecked(option: PplMultipleSelectOption) {
    const values = this.value;
    return values && values.includes(option.value);
  }

  openPopover() {
    if (!this.disabled && !this.displayValueLoading) {
      this.filter = '';

      if (this.isExternalFilter()) {
        this.externalFilter = new ExternalFilter({
          onChange: () => { },
          onOptionsRequest: event => {
            this.optionsRequest.emit(event);
          }
        });
      }

      this.updateAvailableOptions();
      this.optionsPopover.show();
    }
  }

  closePopover() {
    this.optionsPopover.hide();
    this.filter = '';

    if (this.externalFilter) {
      this.externalFilter.dispose();
      this.externalFilter = null;
    }
  }

  isExternalFilter() {
    return this.optionsRequest.observers.length !== 0;
  }

  trackByOptionFn(index, item: PplMultipleSelectOption) {
    return item.value;
  }
}

export interface PplMultipleSelectOption {
  value: string;
  label: string;
  data?: any;
  deleted?: boolean;
  categoryId?: string;
}

export interface PplMultipleSelectCategory {
  id: string;
  label: string;
}

export interface PplMultipleSelectOptionsRequest extends PplAutocompleteOptionsRequest {
}
