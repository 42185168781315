import { PplPopoverTooltipComponent } from './popover-tooltip.component';
import { PplPopoverDirective } from '../popover/popover.directive';
import type {
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {
  Directive,
  Input
} from '@angular/core';

@Directive({
  selector: '[pplPopover][pplPopoverTooltip]'
})
export class PplTooltipDirective implements OnChanges, OnInit {

  @Input() pplPopoverTooltip: string | TemplateRef<any>;
  @Input() pplPopoverTooltipDirection: 'down' | 'up' = 'down';
  @Input() pplPopoverTooltipType?: 'warning' | 'error';
  @Input() pplPopoverTooltipPreserveWhitespace = false;
  @Input() pplPopoverTooltipHoverPreserve = false;

  constructor(
    private popoverDirective: PplPopoverDirective
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pplPopoverTooltip && !changes.pplPopoverTooltip.firstChange) {
      if (this.pplPopoverTooltip) {
        this.setPopoverText();
      }
    }
  }

  ngOnInit() {
    if (this.pplPopoverTooltip) {
      this.popoverDirective.pplPopoverDirection = this.pplPopoverTooltipDirection;
      this.popoverDirective.pplPopoverEvent = !this.pplPopoverTooltipHoverPreserve ? 'hover' : 'hover-preserve';
      this.popoverDirective.pplPopoverComponent = PplPopoverTooltipComponent;
      this.setPopoverText();
    }
  }

  setPopoverText() {
    this.popoverDirective.pplPopoverComponentData = {
      type: this.pplPopoverTooltipType,
      text: this.pplPopoverTooltip,
      color: 'red',
      preserveWhitespace: this.pplPopoverTooltipPreserveWhitespace
    };
  }
}
