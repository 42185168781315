<ng-content></ng-content>
<ng-container *ngFor="let option of options; trackBy: trackByValue">
  <span *ngIf="!showOne || (showOne && option.value === value)"
        class="account-class-star"
        [style.color]="isOptionColored(option.value) ? ACCOUNT_CLASS_COLORS[option.value].main : null"
        [style.border-color]="isOptionColored(option.value) ? ACCOUNT_CLASS_COLORS[option.value].main : null"
        [style.background-color]="isOptionColored(option.value) ? ACCOUNT_CLASS_COLORS[option.value].base : null"
        [class.disabled]="disabled"
        [class.readonly]="readonly"
        [class.is-checked]="isOptionChecked(option.value)"
        (click)="changeValue(option.value)">{{option.text}}</span>
</ng-container>
