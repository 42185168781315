<div class="container demo-content">
  <h2>Form Builder Demo</h2>
  <div class="form-container">
    <div class="form-container-half">
      <ppl-form-group [formGroup]="form"
                      [formActions]="formActions"
                      (cancel)="onReset()"
                      (submit)="onSubmit()">
        <ppl-form-container>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('select')"
                                   class="big-input"
                                   label="Input Number">
                <ppl-select formControlName="select"
                            [options]="deletedSelectOptions"></ppl-select>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('inputNumber')"
                                   class="big-input"
                                   label="Input Number">
                <ppl-input-number formControlName="inputNumber"></ppl-input-number>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('inputUrl')"
                                   class="big-input"
                                   label="Input Url">
                <ppl-input-url formControlName="inputUrl"></ppl-input-url>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('inputEmail')"
                                   class="big-input"
                                   label="Input Email">
                <ppl-input-email formControlName="inputEmail"></ppl-input-email>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('inputPhone')"
                                   class="big-input"
                                   label="Input Phone">
                <ppl-input-phone formControlName="inputPhone"></ppl-input-phone>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('radioGroup')"
                                   class="big-input"
                                   label="Radio Group">
                <ppl-radio-group formControlName="radioGroup"
                                 [hasContainerClass]="false">
                  <ppl-radio-button [value]="'1'">
                    option 1
                  </ppl-radio-button>
                  <ppl-radio-button [value]="'2'">
                    option 2
                  </ppl-radio-button>
                  <ppl-radio-button [value]="'3'">
                    option 3
                  </ppl-radio-button>
                </ppl-radio-group>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('datePicker')"
                                   class="big-input"
                                   label="Date Picker">
                <ppl-date-input formControlName="datePicker"></ppl-date-input>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('multipleAutocomplete')"
                                   class="big-input"
                                   label="Multiple autocomplete">
                <ppl-multiple-autocomplete [options]="[
                                              {
                                                value: '1',
                                                label: 'Value 1'
                                              },
                                              {
                                                value: '2',
                                                label: 'Value 2'
                                              },
                                              {
                                                value: '3',
                                                label: 'Value 3'
                                              }
                                            ]"
                                           formControlName="multipleAutocomplete"></ppl-multiple-autocomplete>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('partialSelect')"
                                   class="big-input"
                                   label="Partial Select">
                <ppl-partial-select formControlName="partialSelect"
                                    [options]="options"></ppl-partial-select>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('ranking')"
                                   class="big-input"
                                   label="Ranking">
                <ppl-ranking formControlName="ranking"></ppl-ranking>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('rankingPercentage')"
                                   class="big-input"
                                   label="Ranking Percentage">
                <ppl-ranking-percentage formControlName="rankingPercentage"></ppl-ranking-percentage>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('accountClass')"
                                   class="big-input"
                                   label="Account Class">
                <ppl-account-class formControlName="accountClass"></ppl-account-class>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container [pplFormControl]="form.get('slideToggle')"
                                   class="big-input"
                                   label="Slide Toggle">
                <ppl-slide-toggle formControlName="slideToggle"></ppl-slide-toggle>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
          <ppl-form-row>
            <ppl-form-column>
              <ppl-input-container label="example tree select"
                                   [pplFormControl]="form.get('treeSelect')">
                <ppl-tree-select formControlName="treeSelect"
                                 [options]="treeOptions">

                </ppl-tree-select>
              </ppl-input-container>
            </ppl-form-column>
          </ppl-form-row>
        </ppl-form-container>
      </ppl-form-group>
    </div>
    <div class="form-container-half"
         style="max-width: 400px;">
      <div class="result">{{formRawValue}}</div>
      <ppl-form-row>
        <ppl-form-column>
          <ppl-input-container>
            <ppl-form-actions #formActions></ppl-form-actions>
          </ppl-input-container>
        </ppl-form-column>
      </ppl-form-row>
    </div>
  </div>
</div>