import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-checkbox-demo',
  templateUrl: './checkbox-demo.component.html',
  styleUrls: ['./checkbox-demo.component.scss']
})
export class CheckboxDemoComponent implements OnInit {
  checkbox1 = true;
  checkbox2 = false;

  checkbox3 = false;
  checkbox3Indeterminate = true;

  code = `
  <ppl-input-container label="Checkboxes">
    <ppl-checkbox [disabled]="true"
                  [value]="checkbox1"
                  (valueChange)="checkbox1 = $event.checked">Checkbox 1</ppl-checkbox>

    <ppl-checkbox [value]="checkbox2" (valueChange)="change($event.checked)">Checkbox 2</ppl-checkbox>
  </ppl-input-container>
`;

  constructor() {
  }

  ngOnInit() {
  }

  change(checked: boolean) {
    this.checkbox2 = checked;
  }

}
