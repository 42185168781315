<ppl-dialog [title]="title"
            [actionLabel]="apply"
            [cancelLabel]="cancel"
            width="660px"
            (action)="onSave()">
  <div class="image-cropper">
    <img #image
         [src]="imageSrc">
  </div>
  <div class="toolbar">
    <button type="button"
            pplButton
            pplButton1
            pplButtonSmall
            class="change-photo"
            (click)="fileSelector.click()">{{changePhoto}}</button>
    <button type="button"
            pplButton
            pplButton1
            pplButtonSmall
            title="Rotate -90"
            (click)="onRotate(-90)">
      <ppl-icon svgIcon="rotate-left"
                width="12px"
                height="15px"></ppl-icon>
    </button>
    <button type="button"
            pplButton
            pplButton1
            pplButtonSmall
            title="Rotate 90"
            (click)="onRotate(90)">
      <ppl-icon svgIcon="rotate-right"
                width="12px"
                height="15px"></ppl-icon>
    </button>
    <input #fileSelector
           type="file"
           style="display: none"
           (change)="onSelectFile($event)">
  </div>
  <ppl-info-box type="info">
    <ng-template #textTemplate>
      {{dragTheYellowCorner}} <span class="cropper-point"></span> {{inOrderToCropUploadedImage}}.
    </ng-template>
  </ppl-info-box>
</ppl-dialog>
