import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import type { PplToggleGroupOption } from '@ppl/ui/toggle-group';


@Component({
  selector: 'ppl-toggle-group-demo',
  templateUrl: './toggle-group-demo.component.html',
  styleUrls: ['./toggle-group-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleGroupDemoComponent implements OnInit {
  options: PplToggleGroupOption[] = [
    {
      value: '1',
      label: 'M'
    },
    {
      value: '2',
      label: 'T'
    },
    {
      value: '3',
      label: 'W'
    },
    {
      value: '4',
      label: 'T'
    },
    {
      value: '5',
      label: 'F'
    },
    {
      value: '6',
      label: 'S'
    },
    {
      value: '0',
      label: 'S'
    }
  ];

  colorOptions = [
    {
      value: 'Not Started',
      label: 'Not Started',
      color: '#5992CF'
    },
    {
      value: 'In Progress',
      label: 'In Progress',
      color: '#5992CF'
    },
    {
      value: 'Waiting',
      label: 'Waiting',
      color: '#5992CF'
    },
    {
      value: 'Completed',
      label: 'Completed',
      color: '#62CCA7'
    },
    {
      value: 'Deferred',
      label: 'Deferred',
      color: '#B3B3B3'
    }
  ];

  code = `
  <ppl-input-container label="Multiple options (days)">
    <ppl-toggle-group [options]="options"
                      selection="multiple"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option (days)">
    <ppl-toggle-group [options]="options"
                      selection="one"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option disabled (days)">
    <ppl-toggle-group [options]="options"
                      selection="one"
                      [disabled]="true"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option color status">
    <ppl-toggle-group [options]="colorOptions"
                      selection="one"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option color status disabled">
    <ppl-toggle-group [options]="colorOptions"
                      selection="one"
                      [disabled]="true"></ppl-toggle-group>
  </ppl-input-container>
  `;

  constructor() {}

  ngOnInit() {}
}
