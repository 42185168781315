import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NETFLIX_LOGO } from '../../utils/netfix';
import { EXAMPLE_PICTURE } from '../../utils/picture';

@Component({
  selector: 'ppl-addressbook-picture-demo',
  templateUrl: './addressbook-picture-demo.component.html',
  styleUrls: ['./addressbook-picture-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressbookPictureDemoComponent implements OnInit {

  netflixImage = NETFLIX_LOGO;
  personImage = EXAMPLE_PICTURE;

  code = `
    <h4>Account with image</h4>
    <ppl-addressbook-picture entity="account"
                            [picture]="netflixImage"></ppl-addressbook-picture>

    <h4>Contact with image</h4>
    <ppl-addressbook-picture entity="contact"
                            [picture]="personImage"></ppl-addressbook-picture>

    <h4>Contact</h4>
    <ppl-addressbook-picture></ppl-addressbook-picture>

    <h4>Account</h4>
    <ppl-addressbook-picture entity="account"></ppl-addressbook-picture>

    <h4>Account< Round</h4>
    <ppl-addressbook-picture entity="account"
                            [isEditable]="true"
                            [forceRound]="true"></ppl-addressbook-picture>
`;

  constructor() { }

  ngOnInit() {
  }

}
