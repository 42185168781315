import type { PhoneNumber, AvailablePhoneNumber, CallingPhoneTypeEnum } from '../../domain/phone-number';
import { PhoneNumberCapability } from '../../domain/phone-number';
import type {
  OnInit,
  TemplateRef} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import type { CommonGridColumn, CommonGridData } from '@ppl/components/grid';
import { I18nService } from '@ppl/i18n';
import type { GridSelection , GridAction, GridRecord } from '@ppl/ui/grid';


@Component({
  selector: 'ppl-calling-phone-grid',
  templateUrl: './calling-phone-grid.component.html',
  styleUrls: ['./calling-phone-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallingPhoneGridComponent implements OnInit {

  @Input() records: CallingPhoneGridRecord[];
  @Input() selection: GridSelection;
  @Input() columns: CommonGridColumn[];
  @Input() hideActions?: boolean;
  @Input() displayColumnSelector = true;
  @Input() phoneNumberColumnWithLocality: boolean;
  @Input() actions?: GridAction[];
  @Input() customColumnTemplates?: { [id: string]: TemplateRef<any> };

  @Output() selectionChange = new EventEmitter<GridSelection>();
  @Output() actionClick = new EventEmitter<{ record: CallingPhoneGridRecord, action: GridAction }>();

  @ViewChild('capabilityColumnTemplate', { static: true }) capabilityColumnTemplate: TemplateRef<any>;
  @ViewChild('emailOrDefaultColumnTemplate', { static: true }) emailOrDefaultColumnTemplate: TemplateRef<any>;
  @ViewChild('phoneNumberOrDefaultColumnTemplate', { static: true }) phoneNumberOrDefaultColumnTemplate: TemplateRef<any>;
  @ViewChild('booleanColumnTemplate', { static: true }) booleanColumnTemplate: TemplateRef<any>;
  @ViewChild('phoneNumberColumnTemplate', { static: true }) phoneNumberColumnTemplate: TemplateRef<any>;
  @ViewChild('priceColumnTemplate', { static: true }) priceColumnTemplate: TemplateRef<any>;

  columnTemplates: { [id: string]: TemplateRef<any> };

  rowHeight = 40;

  PhoneNumberCapability = PhoneNumberCapability;

  get availableActions() {
    if (this.hideActions) {
      return [];
    } else {
      return this.actions || [
        {
          id: CallingPhoneGridAction.Edit,
          icon: 'color-edit',
          text: this.i18nService.translate('Edit'),
          enabled: true,
        },
        {
          id: CallingPhoneGridAction.Delete,
          icon: 'color-delete',
          text: this.i18nService.translate('Delete'),
          enabled: true,
        }
      ];
    }
  }

  constructor(
    public i18nService: I18nService
  ) { }

  ngOnInit() {
    this.setColumns();
    this.setRowHeight();
  }

  private setColumns() {
    const columns = {
      [`id:${CallingPhoneGridColumn.Capability}`]: this.capabilityColumnTemplate,
      [`id:${CallingPhoneGridColumn.MessageForwardingEmail}`]: this.emailOrDefaultColumnTemplate,
      [`id:${CallingPhoneGridColumn.CallForwardingPhone}`]: this.phoneNumberOrDefaultColumnTemplate,
      [`id:${CallingPhoneGridColumn.IsVOIPNumber}`]: this.booleanColumnTemplate,
      [`id:${CallingPhoneGridColumn.EstimatedPrice}`]: this.priceColumnTemplate,
    };

    if (this.phoneNumberColumnWithLocality) {
      columns[`id:${CallingPhoneGridColumn.PhoneNumber}`] = this.phoneNumberColumnTemplate;
    }
    this.columnTemplates = {
      ...columns,
      ...this.customColumnTemplates
    };
  }

  private setRowHeight() {
    if (this.phoneNumberColumnWithLocality) {
      this.rowHeight = 50;
    }
  }
}

export enum CallingPhoneGridAction {
  Edit = 'Edit',
  Delete = 'Delete'
}
export enum CallingPhoneGridColumn {
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Capability = 'Capability',
  MessageForwardingEmail = 'MessageForwardingEmail',
  CallForwardingPhone = 'CallForwardingPhone',
  EstimatedPrice = 'EstimatedPrice',
  IsVOIPNumber = 'IsVOIPNumber',
  Owner = 'Owner',
  Type = 'Type'
}

export interface CallingPhoneGridRecord extends GridRecord, CommonGridData {
  type: CallingPhoneTypeEnum;
  [CallingPhoneGridColumn.Name]: string;
  [CallingPhoneGridColumn.PhoneNumber]: string;
  [CallingPhoneGridColumn.Capability]: PhoneNumberCapability[];
  [CallingPhoneGridColumn.MessageForwardingEmail]?: string | null;
  [CallingPhoneGridColumn.CallForwardingPhone]?: string | null;
  [CallingPhoneGridColumn.EstimatedPrice]?: string | null;
  [CallingPhoneGridColumn.IsVOIPNumber]?: boolean;
}

export type CallingAvailablePhoneGridRecord = Omit<CallingPhoneGridRecord, 'type'>;

export interface CallingPhoneGridRecordWithPermissions<T> extends CallingPhoneGridRecord {
  permissions: T[];
}

export function convertPhoneGridRecordToPhoneNumber(record: CallingPhoneGridRecord): PhoneNumber {
  return {
    id: record.id,
    capability: record.Capability,
    name: record.Name,
    phoneNumber: record.PhoneNumber,
    messageForwardingEmail: record.MessageForwardingEmail,
    callForwardingPhone: record.CallForwardingPhone,
    type: record.type
  };
}

export function convertPhoneNumberToPhoneGridRecord(phone: PhoneNumber): CallingPhoneGridRecord {
  const { id } = phone;
  return {
    id,
    type: phone.type,
    [CallingPhoneGridColumn.Name]: phone.name,
    [CallingPhoneGridColumn.PhoneNumber]: phone.phoneNumber,
    [CallingPhoneGridColumn.Capability]: phone.capability,
    [CallingPhoneGridColumn.MessageForwardingEmail]: phone.messageForwardingEmail,
    [CallingPhoneGridColumn.CallForwardingPhone]: phone.callForwardingPhone
  };
}


export function convertAvailablePhoneNumberToPhoneGridRecord(availablePhoneNumber: AvailablePhoneNumber): CallingAvailablePhoneGridRecord {
  return {
    id: availablePhoneNumber.phoneNumber,
    locality: availablePhoneNumber.locality,
    region: availablePhoneNumber.region,
    [CallingPhoneGridColumn.Name]: availablePhoneNumber.name,
    [CallingPhoneGridColumn.PhoneNumber]: availablePhoneNumber.phoneNumber,
    [CallingPhoneGridColumn.Capability]: [PhoneNumberCapability.SMS, PhoneNumberCapability.Voice],
    [CallingPhoneGridColumn.EstimatedPrice]: availablePhoneNumber.price.toString()
  };
}
