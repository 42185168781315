import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-carousel-demo',
  templateUrl: './carousel-demo.component.html',
  styleUrls: ['./carousel-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselDemoComponent implements OnInit {

  code = `
  <ppl-carousel-slide title="Connect to your Twitter Account"
                      subTitle="and interact your prospects via Pipeliner."
                      icon="twitter">
      <button pplButton
              pplButton1
              pplButtonSmall>Activate</button>
  </ppl-carousel-slide>
  `;

  constructor() { }

  ngOnInit() {
  }

}
