import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { NgForm } from '@angular/forms';

export interface Hero {
  name: string;
}

@Component({
  selector: 'ppl-input-demo',
  templateUrl: './input-demo.component.html',
  styleUrls: ['./input-demo.component.scss']
})
export class InputDemoComponent implements OnInit {
  hero: Hero = {
    name: ''
  };

  code = `
  <ppl-input-container label="Input">
    <input pplInput>
  </ppl-input-container>

  <form #heroForm="ngForm" (ngSubmit)="onSubmit(heroForm)">
    <ppl-input-container label="Input required*">
      <input pplInput [(ngModel)]="hero.name" required name="name" #name="ngModel">
    </ppl-input-container>

    <ppl-input-container>
      <input type="submit" pplButton pplButton1 [disabled]="!heroForm.valid">
    </ppl-input-container>
  </form>

  <ppl-input-container label="Input with icon">
    <input pplInput>
  </ppl-input-container>

  <ppl-input-container label="Input readonly">
    <input pplInput readonly>
  </ppl-input-container>

  <ppl-input-container label="Input disabled">
    <input pplInput disabled>
  </ppl-input-container>

  <ppl-input-container label="Input error" [hasError]="true">
    <input pplInput value="Error state">
    <ppl-input-error>Error message</ppl-input-error>
  </ppl-input-container>

  <ppl-input-container label="Input autofill">
    <input pplInput pplInputAutofill>
  </ppl-input-container>

  <ppl-input-container label="Textarea">
    <textarea pplInput></textarea>
  </ppl-input-container>
  `;

  constructor() {
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
    alert(`Form valid: ${form.valid}`);
  }

}
