import { PplFormActionsComponent } from '../form-actions/form-actions.component';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { notFirstChange, uuid } from '@ppl/utils';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import type { TFormGroup } from '../form-builder';
import type { PplFormPopoverActionsComponent } from '../form-popover-actions/form-popover-actions.component';

@Component({
  selector: 'ppl-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplFormGroupComponent implements OnChanges, OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() transparent = false;
  @Input() noPadding = false;
  @Input() saving = false;
  @Input() showOverlay = true;
  @Input() formActions?: PplFormActionsComponent | PplFormPopoverActionsComponent;

  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ContentChild(PplFormActionsComponent, { static: false }) childFormActions: PplFormActionsComponent;

  id = `form-${uuid()}`;

  markForCheckTimeout: any;

  get actions() {
    return this.formActions || this.childFormActions || null;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.saving)) {
      this.propagateSaving();
    }
    if (notFirstChange(changes.formActions)) {
      this.addActionListeners();
    }
  }

  ngOnInit() {
    this.addActionListeners();
  }

  ngOnDestroy(): void {
    clearTimeout(this.markForCheckTimeout);
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      markFormGroupTouched(this.formGroup);

      clearTimeout(this.markForCheckTimeout);
      // this has to be here, since the content projected into this one is held by the parent component
      this.markForCheckTimeout = setTimeout(() => this.changeDetectorRef.markForCheck(), 1);
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.submit.emit(null);
  }

  onCancel() {
    this.cancel.emit(null);
  }

  private propagateSaving() {
    if (this.actions) {
      this.actions.saving = this.saving;
      this.actions.changeDetectorRef.detectChanges();
    }
  }

  private addActionListeners() {
    if (this.actions) {
      this.actions.onSave = () => {
        if (isIE11()) {
          this.onSubmit();
        }
      };
      this.actions.onCancel = () => this.onCancel();
      this.actions.id = this.id;
      this.actions.changeDetectorRef.detectChanges();
    }
  }
}

export function markFormGroupTouched(formGroup: UntypedFormGroup | TFormGroup<any>) {
  if (formGroup.controls) {
    const keys = Object.keys(formGroup.controls);
    for (let i = 0; i < keys.length; i++) {
      const control = formGroup.controls[keys[i]];

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormArray) {
        control.markAsTouched({ onlySelf: true });
        control.controls.forEach(markFormGroupTouched);
      } else if (control instanceof UntypedFormGroup) {
        markFormGroupTouched(control);
      }
    }
  }
}

// TODO: use @ppl/utils
export function isIE11() {
  return !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
}
