import type { GridSelection, GridAvailableColumn, GridSort, GridColumn } from './grid.interfaces';

interface Record {
  id: string;
}

export function getGridSelectedRecords<T extends Record>(records: T[], selection: GridSelection): T[] {
  if (records && records.length) {
    if (selection.all) {
      return records.filter(item => !selection.exclude.includes(item?.id));
    } else {
      return records.filter(item => selection.include.includes(item?.id));
    }
  }
  return [];
}

export function getGridSelectedRecord<T extends Record>(records: T[], selection: GridSelection): T {
  if (records && records.length) {
    if (selection.include.length === 1) {
      return records.find(item => item.id === selection.include[0]);
    } else if (selection.all && records.length === 1) {
      return records[0];
    }
  }
  return null;
}


interface State {
  selection: GridSelection;
}

export function getGridSelectionRemoveDeleted(state: State, records: Record[], deleted: string[]) {
  return {
    all: records.length ? state.selection.all : false,
    include: state.selection.include.filter(id => !deleted.includes(id)),
    exclude: state.selection.include.filter(id => !deleted.includes(id))
  };
}


export interface GridTreeNode {
  id: string;
  children: GridTreeNode[];
  name: string;
  closed: boolean;
}


export function createGridTreeRecords<T extends any>(
  nodes: GridTreeNode[],
  depth: number,
  createRecordFn: (node: GridTreeNode, depth: number) => T): T[] {
  const records = [];

  nodes.forEach(node => {
    records.push(createRecordFn(node, depth));

    if (!node.closed && node.children.length) {
      records.push(...createGridTreeRecords(node.children, depth + 1, createRecordFn));
    }
  });

  return records;
}

export function getGridSortOrder(availableColumns: GridAvailableColumn[]) {
  const firstSortableColumn = availableColumns.find(column => column.sortable);
  return firstSortableColumn
    // NOTE(mike): `direction` is in ascending order
    ? { id: firstSortableColumn.id, direction: -1 }
    : { id: '', direction: 0 };
}

export function defaultGridStringSortFunction(a: string, b: string) {
  return (b || '').localeCompare(a || '', undefined, { numeric: true });
}

export function createGridDefaultSortFunction(columnId: string) {
  return (a, b) => {
    return a[columnId] < b[columnId] ? 1 : -1;
  };
}

export function createGridDefaultStringSortFunction(columnId: string) {
  return (a, b) => {
    return defaultGridStringSortFunction(a[columnId], b[columnId]);
  };
}

export function sortGridData(
  data: any[],
  sort: GridSort[],
  compare: (a, b) => number
) {
  const { id, direction } = sort[0];
  return id && direction
    ? [...data].sort((a, b) => direction * compare(a, b))
    : data;
}

export function getGridEmptySelection(): GridSelection {
  return {
    all: false,
    include: [],
    exclude: []
  };
}

export function isGridSelectionEmpty(selection: GridSelection) {
  return !selection.all && !selection.include.length;
}
