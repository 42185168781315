import type { OnInit } from '@angular/core';
import { Directive, HostBinding, Input , ElementRef, Renderer2 } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

// Increasing integer for generating unique ids for input components.
let nextUniqueId = 0;

@Directive({
  selector: 'input[pplInput], textarea[pplInput], select[pplInput]'
})
export class PplInputDirective implements OnInit {

  /** Whether the element is disabled. */
  /*@HostBinding('disabled') @Input()
  get disabled() { return this._disabled; }
  set disabled(value: any) { this._disabled = coerceBooleanProperty(value); }*/

  /** Unique id of the element. */
  @HostBinding('attr.id') @Input()
  get id() { return this._id; }
  set id(value: string) {
    this._id = value === null ? null : value || `ppl-input-${++nextUniqueId}`;
  }

  /** Placeholder attribute of the element. */
  @HostBinding('placeholder') @Input() placeholder = '';

  /** Whether the element is required. */
  @HostBinding('required') @Input()
  get required() { return this._required; }
  set required(value: any) { this._required = coerceBooleanProperty(value); }

  /** Input type of the element. */
  @Input()
  get type() {
    return this._type;
  }

  set type(value: string) {
    this._type = value || 'text';
  }

  /** Whether the element is readonly. */
  @Input()
  get readonly() { return this._readonly; }
  set readonly(value: any) { this._readonly = value; }

  className = 'ppl-input';

  /** The input element's value. */
  get value() { return this.elementRef.nativeElement.value; }
  set value(value: string) { this.elementRef.nativeElement.value = value; }

  private _type = 'text';
  //private _disabled = false;
  private _required = false;
  private _readonly = false;
  private _id: string;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    // eslint-disable-next-line no-self-assign
    this.id = this.id;
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, this.className);
  }

  focus() {
    this.elementRef.nativeElement.focus();
  }
}
