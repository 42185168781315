import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { PplAttachment } from '@ppl/ui/attachments';
import { testAttachmentFiles } from './test-attachment-files';

@Component({
  selector: 'ppl-attachments-demo',
  templateUrl: './attachments-demo.component.html',
  styleUrls: ['./attachments-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsDemoComponent implements OnInit {
  files: PplAttachment[] = [];
  files1: PplAttachment[] = testAttachmentFiles;

  fileTypeLimit: { denied: boolean, list: string[] } = {
    denied: true, list: [
      '.ade', '.adp', '.bat', '.chm', '.cmd', '.com', '.cpl', '.exe', '.hta', '.ins', '.isp',
      '.jse', '.lib', '.lnk', '.mde', '.msc', '.msp', '.mst', '.pif', '.scr', '.sct', '.shb',
      '.sys', '.vb', '.vbe', '.vbs', '.vxd', '.wsc', '.wsf', '.wsh'
    ]
  };
  code = `
  <h4>Spread (without header)</h4>
  <ppl-attachments [hasHeader]="false"
                   [files]="files1"
                   (filesChange)="files1 = $event"
                   (unsupportedAttachment)="onUnsupportedAttachment($event)"></ppl-attachments>


  <h4>Spread (without header) + readonly</h4>
  <ppl-attachments [hasHeader]="false"
                   [files]="files1"
                   [readonly]="true"
                   (filesChange)="files1 = $event"
                   (unsupportedAttachment)="onUnsupportedAttachment($event)"></ppl-attachments>
  `;

  constructor() { }

  ngOnInit() { }

  onUnsupportedAttachment($event) {
    alert(`Unsupported attachments, ${JSON.stringify($event)}`);
  }
}
