<ng-container *ngIf="horizontal; else vertical">
  <ppl-container [opened]="(showDetail$ | async) || false"
                 [sidebarWidth]="detailSize"
                 [position]="sidebarPosition"
                 mode="push">
    <ppl-content>
      <div class="horizontal-container"
           [class.horizontal-container-reverse]="position === 'left'">
        <div class="horizontal-container-content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        <ng-container *ngIf="detailVisible">
          <ppl-drawer-control *ngIf="resizable"
                              pplResizable
                              [class.drawer-control-resizable]="open"
                              [pplResizableOrientation]="position"
                              [pplResizableSize]="detailSize"
                              [pplResizableActive]="resizable"
                              [pplResizableSizeConstraints]="sizeConstraints"
                              [open]="open"
                              [disabled]="disabled"
                              [position]="position"
                              (openChange)="onOpenChange()"
                              (pplResizableSizeChange)="onDetailSizeChange($event)"></ppl-drawer-control>

          <ppl-drawer-control *ngIf="!resizable"
                              [open]="open"
                              [disabled]="disabled"
                              [position]="position"
                              (openChange)="onOpenChange()"></ppl-drawer-control>
        </ng-container>
      </div>
    </ppl-content>
    <ppl-sidebar>
      <ppl-drawer-detail [showControl]="showControlHorizontal"
                         [position]="position"
                         [detailSizeLocalStorageKey]="detailSizeLocalStorageKey"
                         [resizable]="resizable"
                         [detailSize]="detailSize"
                         [sizeConstraints]="sizeConstraints"
                         (sizeChange)="onDetailSizeChange($event)">
        <ng-container *ngTemplateOutlet="detail"></ng-container>
      </ppl-drawer-detail>
    </ppl-sidebar>
  </ppl-container>
</ng-container>

<ng-template #vertical>
  <div class="vertical-container">
    <div class="vertical-container-content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <ppl-drawer-detail [position]="position"
                       [showControl]="showControlVertical"
                       [open]="open"
                       [disabled]="disabled"
                       [detailSizeLocalStorageKey]="detailSizeLocalStorageKey"
                       [resizable]="resizable"
                       [detailSize]="detailSize"
                       [sizeConstraints]="sizeConstraints"
                       (openChange)="onOpenChange()"
                       (sizeChange)="onDetailSizeChange($event)">
      <ng-container *ngTemplateOutlet="detail"></ng-container>
    </ppl-drawer-detail>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
