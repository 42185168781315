import type { ValidationErrors } from '@angular/forms';
import type { I18nService } from '@ppl/i18n';
import type { TAbstractControl } from '@ppl/ui/form';
import type {
  CountryCode,
  NumberFormat,
  PhoneNumber
} from 'libphonenumber-js/min';
import {
  ParseError,
  parsePhoneNumber,
  parsePhoneNumberWithError
} from 'libphonenumber-js/min';

export interface InputPhoneNumber {
  country: CountryCode;
  number: string;
}

export function convertPhoneNumberToInputPhoneNumber(value: string, country: CountryCode = 'US'): InputPhoneNumber {
  try {
    const phoneNumber = parsePhoneNumberWithError(value, country);
    return phoneNumber.isValid()
      ? {
        country: phoneNumber.country,
        number: phoneNumber.nationalNumber.toString()
      }
      : null;
  } catch (e) {
    return null;
  }
}

export function convertInputPhoneNumberToPhoneNumber(inputPhoneNumber: InputPhoneNumber): PhoneNumber | null {
  if (inputPhoneNumber.number) {
    const phoneNumber = parsePhoneNumber(inputPhoneNumber.number, inputPhoneNumber.country || 'US');
    return phoneNumber;
  } else {
    return null;
  }
}

export function formatInputPhoneNumber(inputPhoneNumber: InputPhoneNumber | null, format: NumberFormat = 'E.164') {
  if (!inputPhoneNumber) {
    return null;
  }

  const phoneNumber = convertInputPhoneNumberToPhoneNumber(inputPhoneNumber);
  return phoneNumber && phoneNumber.isValid()
    ? phoneNumber.format(format)
    : null;
}

export function isInputPhoneNumberValid(inputPhoneNumber: InputPhoneNumber): boolean {
  const phoneNumber = convertInputPhoneNumberToPhoneNumber(inputPhoneNumber);
  return Boolean(phoneNumber) && phoneNumber.isValid();
}

export function inputPhoneNumberRequiredValidator() {
  return (control: TAbstractControl<InputPhoneNumber>): ValidationErrors | null => {
    if (!control.value || !control.value.number) {
      return {
        required: true
      };
    } else {
      return null;
    }
  };
}

export function inputPhoneNumberValidator(i18nService: I18nService) {
  return (control: TAbstractControl<InputPhoneNumber>): ValidationErrors | null => {
    if (!control.value || !control.value.number) {
      return null;
    }

    try {
      const { country, number } = control.value;
      const phoneNumber = parsePhoneNumberWithError(number, country || 'US');
      return phoneNumber.isValid()
        ? null
        : {
          custom: i18nService.translate('Phone_number_is_invalid')
        };
    } catch (error) {
      if (error instanceof ParseError) {
        const i18nId = PHONE_NUMBER_PARSE_ERROR_TO_I18N_MAP[error.message];
        return {
          custom: i18nId
            ? i18nService.translate(i18nId)
            : i18nService.translate('Unknown_Error')
        };
      } else {
        return {
          custom: i18nService.translate('Unknown_Error')
        };
      }
    }
  };
}

export const PHONE_NUMBER_PARSE_ERROR_TO_I18N_MAP = {
  INVALID_COUNTRY: 'Phone_number_country_is_invalid',
  NOT_A_NUMBER: 'Not_a_phone_number',
  TOO_SHORT: 'The_phone_number_is_too_short',
  TOO_LONG: 'The_phone_number_is_too_long'
};
