import type { PplOptionListOption } from '../option-list.component';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { trackById } from '@ppl/utils';

@Component({
  selector: 'ppl-option-list-categories',
  templateUrl: './option-list-categories.component.html',
  styleUrls: ['./option-list-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionListCategoriesComponent implements OnInit {
  @Input() value: string = null;
  @Input() categories: PplOptionListOption[];

  @Output() valueChange = new EventEmitter<string>();

  trackById = trackById;

  constructor() { }

  ngOnInit(): void {
  }
}
