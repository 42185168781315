import { PplPopoverDirection, PplPopoverDirective } from '../popover';
import { PplUiIntl } from '../ppl-ui-intl';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild
,
  ElementRef,
  TemplateRef} from '@angular/core';
import { KEYCODE_DOWN, KEYCODE_ENTER, notFirstChange } from '@ppl/utils';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';

@Component({
  selector: 'ppl-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplAutocompleteComponent implements OnChanges, OnInit, OnDestroy {

  @Input() options: PplAutocompleteOption[];
  @Input() value: string;

  @Input() categories?: PplAutocompleteCategory[];
  @Input() displayCategoriesSidebar?: boolean;
  @Input() icon: 'search' | 'dropdown' = 'search';
  @Input() optionTemplate: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() displayCreateOption?: string;
  @Input() displayValueLoading = false;
  @Input() displayOptionsLoading = false;
  @Input() forceDisplayClearValue = false;
  @Input() placeholder?: string;
  @Input() maxContainerHeight?: number;
  @Input() valueValidator?: (value: string) => boolean;
  @Input() autoFocusOnInit = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() openOnFocus = true;
  @Input() popoverDirection: PplPopoverDirection = 'down';
  @Input() popoverAlignStart = true;
  @Input() popoverAlignEnd = true;
  @Input() popoverWidth?: number;
  @Input() minPopoverWidth?: number;
  @Input() categoriesSidebarWidth?: number;

  @Output() valueChange = new EventEmitter<string>();
  @Output() valueSubmit = new EventEmitter<string>();
  @Output() optionSelect = new EventEmitter<PplAutocompleteOption>();
  @Output() optionCreate = new EventEmitter<string>();
  @Output() listOpen = new EventEmitter();
  @Output() listClose = new EventEmitter();
  @Output() listScrollEnd = new EventEmitter();
  @Output() clearValueClick = new EventEmitter();

  @ViewChild('valueInput', { static: false }) valueInput: ElementRef;
  @ViewChild(PplPopoverDirective, { static: true }) optionsPopover: PplPopoverDirective;

  focused = false;
  listSelectedIndex = -1;
  recalcRenderHandle = -1;

  constructor(
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES,
    private intl: PplUiIntl
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.listSelectedIndex = (this.options.length !== 0) ? Math.min(Math.max(this.listSelectedIndex, 0), this.options.length - 1) : -1;
    }

    if (notFirstChange(changes.options) && changes.options.previousValue.length !== changes.options.currentValue.length && this.optionsPopover.isOpen) {
      this.recalcRenderHandle = requestAnimationFrame(() => {
        this.optionsPopover.recalcPosition();
      });
    }
  }

  ngOnInit() {
    if (this.autoFocusOnInit && !this.readonly) {
      requestAnimationFrame(() => {
        if (this.valueInput) {
          this.valueInput.nativeElement.focus();
          this.openPopover();
        }
      });
    }

    if (this.placeholder === undefined) {
      this.placeholder = this.intl.startTyping;
    }
  }

  ngOnDestroy() {
    cancelAnimationFrame(this.recalcRenderHandle);
  }

  onContainerMouseDown(event: MouseEvent) {
    const eventTarget = event.target as HTMLElement;

    if (eventTarget.tagName !== 'INPUT') {
      event.preventDefault();
    }

    event.stopPropagation();

    if (!this.disabled && !this.displayValueLoading && !this.readonly) {
      if (!this.focused) {
        this.valueInput.nativeElement.focus();
        this.openPopover();
      } else if (!this.optionsPopover.isOpen) {
        this.openPopover();
      }
    }
  }

  onValueInputFocus() {
    if (!this.readonly) {
      this.focused = true;
    }
  }

  onValueInputBlur() {
    this.focused = false;
    this.closePopover();
  }

  onDropdownIconMouseDown(event: MouseEvent) {
    if (this.focused) {
      this.valueInput.nativeElement.blur();
      event.stopPropagation();
    }
  }

  onValueInputInput(event: any) {
    const inputText = event.target.value;

    if (inputText && !this.optionsPopover.isOpen) {
      this.openPopover();
    }

    this.listSelectedIndex = (this.options.length !== 0) ? 0 : -1;
    this.valueChange.emit(inputText);
  }

  onValueInputKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case KEYCODE_DOWN:
        if (!this.optionsPopover.isOpen) {
          this.openPopover();
        }
        break;
      case KEYCODE_ENTER:
        if (this.listSelectedIndex === -1 && (!this.valueValidator || this.valueValidator(this.value))) {
          this.valueSubmit.emit(this.value);
          this.closePopover();
        }
        break;
    }
  }

  onValueClear() {
    this.valueChange.emit('');
    this.clearValueClick.emit();
  }

  onOptionCreate() {
    this.optionCreate.emit(this.value);
    this.closePopover();
  }

  onOptionSelect(option: PplAutocompleteOption) {
    this.optionSelect.emit(option);
    this.closePopover();
  }

  onListScrollEnd() {
    this.listScrollEnd.emit();
  }

  openPopover() {
    this.optionsPopover.show();
    this.listOpen.emit();
  }

  closePopover() {
    this.optionsPopover.hide();
    this.listClose.emit();
  }

  getOptionIndexByValue(value: string) {
    return this.options.findIndex(option => option.value === value);
  }

}

export interface PplAutocompleteOption {
  categoryId?: string;
  data?: any;
  label: string;
  value: string;
}

export interface PplAutocompleteCategory {
  id: string;
  label: string;
  data?: any;
}

export interface PplAutocompleteOptionsRequest {
  filter: string;
  lastOptionValue: string | null;
  lastOptionData?: any;
}
