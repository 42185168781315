import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
,
  TemplateRef
} from '@angular/core';
import { MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-graph-item',
  templateUrl: './graph-item.component.html',
  styleUrls: ['./graph-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphItemComponent implements OnInit {

  @Input() name: string;
  @Input() disabled = false;
  @Input() isToggled = true;
  @Input() title?: string;
  @HostBinding('class.clickable') @Input() clickable = true;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clicked: EventEmitter<null> = new EventEmitter();

  @ContentChild('iconTemplate', { static: false }) iconTemplate: TemplateRef<any>;

  @HostBinding('attr.title')
  @MemoizeLast<GraphItemComponent>(['clickable'])
  get getTitle() {
    return this.clickable && this.title ? this.title : null;
  }

  constructor() { }

  ngOnInit(): void {
  }

  onToggle(value: boolean) {
    if (!this.disabled) {
      this.toggled.emit(value);
    }
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.disabled && this.clickable) {
      this.clicked.emit();
    }
  }
}
