<div class="container demo-content">
  <h2>Toast</h2>

  <h3>Module</h3>
  <div>PplToastModule</div>
  <br />
  <br />

  <h3>Examples</h3>
  <div class="row">
    <div class="col-lg-12">
      <button pplButton
              pplButton1
              (click)="onOpenToast()">
        Show Toast
      </button>

      <br />
      <br />

      <button pplButton
              pplButton1
              (click)="onOpenToast('sticky')">
        Show Sticky Toast
      </button>
      <br />
      <br />

      <button pplButton
              pplButton1
              (click)="onOpenToast('short')">
        Show 1 second Toast
      </button>
      <br />
      <br />

      <button pplButton
              pplButton1
              (click)="onOpenMessage()">
        Show Custom Message Toast
      </button>
      <br />
      <br />

      <button pplButton
              pplButton1
              (click)="hide()">
        Hide
      </button>
      <br />
      <br />
    </div>
    <h4>Code Example</h4>
    <ppl-prism [code]="code"></ppl-prism>
  </div>
</div>
