import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'ppl-grid-header-field',
  templateUrl: './grid-header-field.component.html',
  styleUrls: ['./grid-header-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridHeaderFieldComponent {

  @Input() name: string;
  @Input() hideName = false;

  @Input() description?: string;

  @Input() headerTemplate?: TemplateRef<any>;
  @Input() headerTemplateData?: any;

  @Input() sorted = false;
  @Input() sortDirection = 0;
  @Input() @HostBinding('class.sort-disabled') sortDisabled = false;

  @Input() resizable = false;
  @Input() @HostBinding('class.frozen') frozen = false;
  @Input() searchable = false;

  @Input() @HostBinding('class.placeholder') displayPlaceholder = false;
  @Input() displaySearchIcons = false;

  @Output() resizeStart = new EventEmitter<MouseEvent>();
  @Output() resizeAuto = new EventEmitter();

}
