<div class="container"
     #selectInputContainer
     [pplPopover]="selectDropdown"
     [pplPopoverDisabled]="disabled || readonly"
     [pplPopoverAlignStart]="dropdownAlignStart"
     [pplPopoverAlignEnd]="dropdownAlignEnd"
     [class.disabled]="disabled"
     [class.readonly]="readonly"
     [class.active]="!(disabled || readonly) && (opened || focused)"
     (mousedown)="calculateDropdownAlignment()"
     (pplPopoverToggle)="opened = $event.visible"
     tabindex="-1">
  <div #input
       class="input"
       (focus)="onFocus()"
       (blur)="onBlur()"
       [attr.tabindex]="(disabled || readonly) ? null : 0"
       (keydown)="onValueInputKeyDown($event); calculateDropdownAlignment()"
       title="{{selectedOption ? selectedOption.label : placeholder}}">
    <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: selectedOption, selected: true }"></ng-container>
  </div>
  <div class="btn dropdown-button"
       [class.active]="!(disabled || readonly) && (!opened && focused)"
       [class.disabled]="disabled"
       [class.readonly]="readonly"
       [class.opened]="opened"
       [ngSwitch]="ngUiTheme"
       tabindex="-1">

    <ppl-icon *ngSwitchDefault
              [svgIcon]="!disabled ? 'rowline-form' : 'rowline-form_disabled'"
              class="dropdown-icon"
              width="7px"
              height="7px"></ppl-icon>

    <ppl-icon *ngSwitchCase="'material'"
              class="dropdown-button-icon"
              svgIcon="arrow-down-gray"
              width="12px"
              height="6px"></ppl-icon>

    <svg *ngSwitchCase="'fabric'"
         class="dropdown-button-icon"
         width="12px"
         height="8px">
      <path d="m 6 5.5 l 5.25 -4.7 l 0.75 0.7 l -6 5.5 l -6 -5.5 l 0.75 -0.7"
            fill="#323130"></path>
    </svg>
  </div>
</div>

<ng-template #optionTemplate
             let-option
             let-selected="selected">
  <div class="option"
       [class.big]="big"
       [class.disabled]="option.disabled"
       [title]="option.labelTooltip || option.label"
       [attr.data-test]="'Select_Option_' + option.value"
       [class.selected]="option.value === value">

    <div class="option-icon"
         *ngIf="hasIcons">
      <ng-container *ngTemplateOutlet="optionIcon; context: { '\$implicit': option }"></ng-container>
    </div>
    <div class="option-color"
         *ngIf="hasColors">
      <div class="color"
           width="5px"
           height="10px"
           [style.background-color]="option.color"></div>
    </div>

    <ng-container *ngIf="selected">
      <ng-container *ngIf="normalizedSelectedItemTemplate; let template; else basicText">
        <ng-container *ngTemplateOutlet="template; context: { '\$implicit': option.label, value: option.value, option: option }"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!selected">
      <ng-container *ngIf="itemTemplate; let template; else basicText">
        <ng-container *ngTemplateOutlet="template; context: { '\$implicit': option.label, value: option.value, option: option }"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #basicText>
      <span class="option-label">
        {{option.label}}
      </span>
    </ng-template>
  </div>
</ng-template>

<ng-template #optionIcon
             let-option>
  <ng-container *ngIf="option.icon">
    <ng-container *ngIf="hasOptionIconDimensions(option); else noDimensions">
      <ppl-icon class="option-icon"
                [svgIcon]="option.icon.name"
                [width]="option.icon.width"
                [height]="option.icon.height"></ppl-icon>
    </ng-container>
    <ng-template #noDimensions>
      <ppl-icon class="option-icon"
                [svgIcon]="option.icon"
                width="18px"
                height="18px"></ppl-icon>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #selectDropdown>
  <ppl-popover-dropdown *ngIf="!(disabled || readonly)"
                        [style.min-width.px]="(popoverWidth === undefined) ? dropdownMinWidth : undefined"
                        [style.max-width.px]="(popoverWidth === undefined) ? dropdownMaxWidth : undefined"
                        class="dropdown">
    <ppl-option-list-with-filter [categories]="categories"
                                 [displayCategoriesSidebar]="displayCategoriesSidebar"
                                 [options]="normalizedOptions"
                                 [optionTemplate]="optionTemplate"
                                 [value]="value"
                                 [maxContainerHeight]="optionListMaxContainerHeight"
                                 [optionTemplateRowHeight]="optionTemplateRowHeight"
                                 [displaySearch]="displaySearch && normalizedOptions.length >= minItemsToShowSearch"
                                 [style.width.px]="popoverWidth"
                                 [categoriesSidebarWidth]="categoriesSidebarWidth"
                                 (optionSelect)="onOptionSelect($event)"
                                 (keyEscapePressed)="onKeyEscapePressed()">
      <ng-content select="dropdownContentBeforeList"
                  ngProjectAs="contentAfterSearch"></ng-content>

    </ppl-option-list-with-filter>
  </ppl-popover-dropdown>
</ng-template>
