import { PplAutocompleteComponent } from './autocomplete.component';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { PplLoadingModule } from '../loading';
import { PplOptionListModule } from '../option-list';
import { PplPopoverModule } from '../popover';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplPopoverModule,
    PplOptionListModule,
    PplLoadingModule,
    PplInputModule
  ],
  declarations: [
    PplAutocompleteComponent
  ],
  exports: [
    PplAutocompleteComponent
  ]
})
export class PplAutocompleteModule {
}

export * from './autocomplete.component';
export * from './external-filter';
export * from './fuzzy-search';

