import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { PplTreeSelectOption, PplTreeSelectValue } from '@ppl/ui/tree-select';

@Component({
  selector: 'ppl-tree-select-demo',
  templateUrl: './tree-select-demo.component.html',
  styleUrls: ['./tree-select-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectDemoComponent implements OnInit {

  code = `
    <ppl-tree-select [value]="value"
                     [options]="options"
                     (valueChange)="onValueChange($event)">
    </ppl-tree-select>
  `;

  value: PplTreeSelectValue = {
    all: false,
    selectedValues: []
  };

  singleValue = 'main1';

  options: PplTreeSelectOption[] = [
    {
      value: 'main1',
      label: 'main1',
      parentId: null,
      disabled: false,
    },
    {
      value: 'amain1-sub1',
      label: 'amain1-sub1',
      parentId: 'main1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub1',
      label: 'main1-sub1-sub1',
      parentId: 'amain1-sub1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub1-sub1',
      label: 'main1-sub1-sub1-sub1',
      parentId: 'main1-sub1-sub1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub2',
      label: 'main1-sub1-sub2',
      parentId: 'amain1-sub1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub2-sub1',
      label: 'main1-sub1-sub2-sub1',
      parentId: 'main1-sub1-sub2',
      disabled: false,
    },
    {
      value: 'main1-sub2',
      label: 'main1-sub2',
      parentId: 'main1',
      disabled: false,
    },
    {
      value: 'main1-sub2-sub2',
      label: 'main1-sub2-sub2',
      parentId: 'main1-sub2',
      disabled: false,
    },
    {
      value: 'main1-sub2-sub2-sub2',
      label: 'main1-sub2-sub2-sub2',
      parentId: 'main1-sub2-sub2',
      disabled: false,
    },
    {
      value: 'main2',
      label: 'main2',
      parentId: null,
      disabled: false,
    },
    {
      value: 'main2-sub1',
      label: 'main2-sub1',
      parentId: 'main2',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub1',
      label: 'main2-sub1-sub1',
      parentId: 'main2-sub1',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub1-sub1',
      label: 'main2-sub1-sub1-sub1',
      parentId: 'main2-sub1-sub1',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub2',
      label: 'main2-sub1-sub2',
      parentId: 'main2-sub1',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub2-sub1',
      label: 'main2-sub1-sub2-sub1',
      parentId: 'main2-sub1-sub2',
      disabled: false,
    },
    {
      value: 'main2-sub2',
      label: 'main2-sub2',
      parentId: 'main2',
      disabled: false,
    },
    {
      value: 'main2-sub2-sub2',
      label: 'main2-sub2-sub2',
      parentId: 'main2-sub2',
      disabled: false,
    },
    {
      value: 'main2-sub2-sub2-sub2',
      label: 'main2-sub2-sub2-sub2',
      parentId: 'main2-sub2-sub2',
      disabled: false,
    }
  ];

  singleOptions: PplTreeSelectOption[] = [
    {
      value: 'main1',
      label: 'main1',
      parentId: null,
      disabled: false,
      avatarEntity: 'unit',
      unavailable: false,
      // unavailable: randomBoolean(),
      deleted: true,
    },
    {
      value: 'amain1-sub1',
      label: 'amain1-sub1',
      parentId: 'main1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub1-sub1',
      label: 'main1-sub1-sub1',
      parentId: 'amain1-sub1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub1-sub1-sub1',
      label: 'main1-sub1-sub1-sub1',
      parentId: 'main1-sub1-sub1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub1-sub2',
      label: 'main1-sub1-sub2',
      parentId: 'amain1-sub1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub1-sub2-sub1',
      label: 'main1-sub1-sub2-sub1',
      parentId: 'main1-sub1-sub2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub2',
      label: 'main1-sub2',
      parentId: 'main1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub2-sub2',
      label: 'main1-sub2-sub2',
      parentId: 'main1-sub2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main1-sub2-sub2-sub2',
      label: 'main1-sub2-sub2-sub2',
      parentId: 'main1-sub2-sub2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2',
      label: 'main2',
      parentId: null,
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub1',
      label: 'main2-sub1',
      parentId: 'main2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub1-sub1',
      label: 'main2-sub1-sub1',
      parentId: 'main2-sub1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub1-sub1-sub1',
      label: 'main2-sub1-sub1-sub1',
      parentId: 'main2-sub1-sub1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub1-sub2',
      label: 'main2-sub1-sub2',
      parentId: 'main2-sub1',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub1-sub2-sub1',
      label: 'main2-sub1-sub2-sub1',
      parentId: 'main2-sub1-sub2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub2',
      label: 'main2-sub2',
      parentId: 'main2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub2-sub2',
      label: 'main2-sub2-sub2',
      parentId: 'main2-sub2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    },
    {
      value: 'main2-sub2-sub2-sub2',
      label: 'main2-sub2-sub2-sub2',
      parentId: 'main2-sub2-sub2',
      disabled: false,
      avatarEntity: 'unit',
      unavailable: randomBoolean()
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(event: PplTreeSelectValue) {
    this.value = event;
  }

}

function randomBoolean() {
  return false;
  return Math.random() < 0.5;
}
