import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplCarouselSlideComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit() {}
}
