import { TokenService } from './token/token.service';
import { ReportExpiredComponent } from '../components/report-expired/report-expired.component';
import { UnauthorizedDialogComponent } from '../components/unauthorized-dialog/unauthorized-dialog.component';
import { ErrorCode } from '../domain/enums/error-code';
import { logicalUserErrorsMessages } from '../domain/logical-user-errors';
import { hasErrorCode } from '../utils/api-client.utils';
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  Optional
} from '@angular/core';
import type { EntityNameEnum, EntityValidationError, FieldError } from '@ppl/graphql-space-api';
import { I18nService } from '@ppl/i18n';
import { PplDialogService } from '@ppl/ui/dialog';
import { CustomError, getFormattedGraphqlError, UserAlertError } from '@ppl/utils';
import * as Sentry from '@sentry/browser';
import type { Operation } from '@apollo/client/core';
import type { ExecutionResult, GraphQLError } from 'graphql';
import { Subject } from 'rxjs';

export const SENTRY_ENABLED = new InjectionToken<string>('SentryEnabled');
export const LOGICAL_USER_ERRORS = new InjectionToken<string>('LogicalUserErrors');
export const IGNORE_SENTRY_ERRORS = new InjectionToken<string>('IgnoreSentryErrors');


@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  public error$: Subject<Error>;

  unauthorizedHandled = false;
  windowMessageListenerCallback: ($event) => any;

  constructor(
    @Inject(SENTRY_ENABLED) private sentryEnabled: boolean,
    @Inject(LOGICAL_USER_ERRORS) private logicalUserErrors: ErrorCode[],
    @Inject(IGNORE_SENTRY_ERRORS) private ignoredSentryErrors: ErrorCode[],
    private dialogService: PplDialogService,
    private i18nService: I18nService,
    @Optional() private tokenService: TokenService
  ) {
    super();
    this.error$ = new Subject();
  }

  handleUnauthorizedError(context?: {reloginInPopup?: boolean}) {
    // only handle this state once (don't show multiple unauthorized popups)
    if (this.unauthorizedHandled) {
      return;
    }

    this.dialogService.closeAll();
    if (this.tokenService) {
      this.tokenService.deleteToken();
    }

    this.dialogService.open(UnauthorizedDialogComponent, {
      // closeOnNavigation: false
    }).afterClosed().subscribe(() => {
      if (this.tokenService) {
        this.tokenService.reloadPage();
      } else {
        this.cookieRefresh(context);
      }
    });

    this.unauthorizedHandled = true;
  }

  /**
   * Handle various errors raised by server
   * @param context.errorWhitelist - errors in this list are not reported even if they are logical/expected
   */
  handleApiError(context: {
    networkError?: Error,
    graphQLErrors?: GraphQLError[],
    response?: ExecutionResult,
    operation?: Operation,
    errorWhiteList?: ErrorCode[],
    reloginInPopup?: boolean
  } = {}) {

    // don't show any other errors if 401 error was already handled
    if (this.unauthorizedHandled) {
      return;
    }

    // in case of a space admin, the 401 error is propagated on FE via graphql error code in a network response
    if (hasErrorCode(context, ErrorCode.ERROR_UNAUTHORIZED)) {
      return this.handleUnauthorizedError(context);
    }

    const errorWhiteList = context.errorWhiteList || [];

    function isWhiteListError(error: ApiError) {
      return errorWhiteList.find(whiteListedErrorCode => error.code === whiteListedErrorCode);
    }

    const isExpectedListError = (error: ApiError) => {
      return this.logicalUserErrors.includes(error.code) && !isWhiteListError(error);
    };

    if (context.graphQLErrors && context.graphQLErrors.length && context.graphQLErrors.length > 0) {

      if (this.sentryEnabled) {
        // these are the errors which are not whitelisted & also not logical/user errors
        // these errors are reported into sentry, where they should be resolved
        const unknownGraphQLErrors: GraphQLError[] = (context.graphQLErrors as any[])
          .filter((error: { api_error?: ApiError }) =>
            !error.api_error
            || (
              error.api_error
              // is not whitelisted error (handled in context)
              && !isWhiteListError(error.api_error)
              // is not a logical error (reported back to user)
              && !this.logicalUserErrors.includes(error.api_error.code)
              // is not explicitely stated as ignored for sentry
              && !this.ignoredSentryErrors.includes(error.api_error.code)
            )
          );

        if (unknownGraphQLErrors.length) {
          Sentry.captureEvent({
            tags: {
              graphql: context.operation.operationName,
              handler: 'error-handler-service'
            },
            message: unknownGraphQLErrors
              .map(({ message }, index) => `[error ${index}]: ${message}`)
              .join('\n'),
            extra: {
              query: context.operation.query.loc.source.body,
              variables: context.operation.variables,
              graphqlErrors: unknownGraphQLErrors
                .map((error: (GraphQLError & { api_error: any }), index) => `${index}: ${getFormattedGraphqlError(error, true)}`)
                .join('\n\n')
            },
          });
        }
      }

      // try to retrieve logical api errors
      const reportErrors = (context.graphQLErrors as any[])
        // filter out provided api_errors
        .map((error: { api_error: ApiError }) => error.api_error)
        // filter out white listed errors && the error has to be in the set of user-feasible errors + in expected list of errors defined by user
        .filter((error: ApiError) => !!error && isExpectedListError(error))
        // filter out duplicate errors of the same code, without filtering multiple different messages
        .filter((error: ApiError, index, currentArray) =>
          logicalUserErrorsMessages.includes(error.code) || currentArray.findIndex(err => err.code === error.code) === index
        );

      const reportErrorMessages = reportErrors
        // map touser messages
        .map((error: ApiError) => logicalUserErrorsMessages.includes(error.code)
          ? `${this.i18nService.translate(`EnumErrorCode_${error.name}`)} ${error.message}`
          : this.i18nService.translate(`EnumErrorCode_${error.name}`)
        );

      if (reportErrors.find(error => error.code === ErrorCode.ERROR_REPORT_HAS_EXPIRED)) {
        this.dialogService.open(ReportExpiredComponent, { autoFocus: false });
      } else if (reportErrorMessages.length > 0) {
        this.dialogService.alert({ text: reportErrorMessages.join('\n\n') });
      }
    }
  }

  handleError(error: Error) {
    if (error instanceof UserAlertError) {
      this.dialogService.alert({ text: error.message });
      return;
    }

    if (error instanceof CustomError) {
      // do nothing, should be handled elsewhere
      return;
    }

    const apolloError = error instanceof Object && ('networkError' in error || 'graphQLErrors' in error);

    if (!apolloError) {
      this.logErrorToSentry(error);
    }
    this.error$.next(error);
    super.handleError(error);
  }

  logErrorToSentry(error: any) {
    if (this.sentryEnabled) {
      Sentry.captureException(error);
    }
  }

  private cookieRefresh(context?: {reloginInPopup?: boolean}) {
    if (!context?.reloginInPopup) {
      window.location.href = `${window.location.origin}/relogin?redirect_url=${window.location.href}`;
      return;
    }
    const removeListener = () => {
      if (this.windowMessageListenerCallback) {
        window.removeEventListener('message', this.windowMessageListenerCallback);
      }
    };

    // remove previous listeners
    removeListener();

    const callback = ($event: { data: {
        type: 'pipeliner-auth';
        result: 'success' | 'failed';
      } }) => {
      const data = $event.data;
      if (data && data.type === 'pipeliner-auth' && data.result === 'success') {
        this.unauthorizedHandled = false;
        removeListener();
      }
    };
    const newLoginWindow = this.openReloginWindow();
    if (newLoginWindow) {
      // listen to child window messages, once the message arrives with proper type and data, authorize the user
      window.addEventListener('message', callback);
      this.windowMessageListenerCallback = callback;

      const timer = setInterval(function () {
        if (newLoginWindow.closed) {
          clearInterval(timer);
          removeListener();
        }
      }, 500);

    }
  }

  private openReloginWindow() {
    const url = `${window.location.origin}/relogin?redirect_url=window_close`;
    const size = 800;

    const wLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const wTop = window.screenTop ? window.screenTop : window.screenY;

    const left = wLeft + (window.innerWidth / 2) - (size / 2);
    const top = wTop + (window.innerHeight / 2) - (size / 2);

    // eslint:disable-next-line:max-line-length
    const newWindow = window.open(url, 'Pipeliner CRM Login', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${size},width=${size},left=${left},top=${top}`);
    if (newWindow && newWindow.focus) {
      newWindow.focus();
    }
    return newWindow;
  }

}

export interface ApiErrorBase {
  code: ErrorCode;
  name: keyof ErrorCode;
  message: string;
}

export interface FieldExceptionError extends ApiErrorBase {
  field_id: string;
  errors: ApiErrorBase[];
}

export interface EntityValidationException extends ApiErrorBase {
  code: ErrorCode.ERROR_ENTITY_VALIDATION;
  message: string;
  entity_errors: ApiErrorBase[];
  entity_index: number | null;
  entity_id: string | null;
  entity_name: string | null;
  field_errors: (FieldExceptionError | EntityValidationException)[];
  step_checklist_errors: StepChecklistValidationError[] | null;
}

export interface StepChecklistValidationError {
  code: ErrorCode.ERROR_STEP_CHECKLIST_VALIDATION;
  message: string | null;
  name: string;
  step_checklist_id: string;
}

export interface OtherApiError extends ApiErrorBase {
  code: Exclude<ErrorCode, ErrorCode.ERROR_FIELD_ACCESS_NOT_ALLOWED | ErrorCode.ERROR_ENTITY_VALIDATION>;
}

export interface FieldAccessNotAllowedApiError extends ApiErrorBase {
  code: ErrorCode.ERROR_FIELD_ACCESS_NOT_ALLOWED;
  message: string;
  permission_level: [];
  field_id: string;
}

export interface FieldPublishError extends ApiErrorBase {
  code: ErrorCode.ERROR_FIELD_FORM_PUBLISH_INVALID_CONFIGURATION;
  dependent_entities: EntityNameEnum[];
  message: string;
}

export function entityValidationExceptionToEntityValidationError(exception: EntityValidationException): EntityValidationError {
  return {
    __typename: 'EntityValidationError',
    fieldErrors: exception.field_errors.map(fieldExceptionErrorToFieldError),
    code: exception.code || ErrorCode.ERROR_ENTITY_VALIDATION,
    entityId: exception.entity_id || '',
    entityErrors: [],
    entityName: exception.entity_name || '',
    stepChecklistErrors: (exception.step_checklist_errors || []).map(stepChecklistError => ({
      __typename: 'StepChecklistError',
      code: stepChecklistError.code,
      message: stepChecklistError.message,
      name: stepChecklistError.name,
      stepChecklistId: stepChecklistError.step_checklist_id
    })),
    message: exception.message || '',
    name: exception.name || '',
    entityIndex: exception.entity_index || 0
  };
}

export function entityValidationErrorToEntityValidationException(error: EntityValidationError): EntityValidationException {
  return {
    field_errors: error?.fieldErrors?.map(fieldErrorToFieldExceptionError) || [],
    entity_errors: [],
    code: error.code,
    entity_id: error.entityId,
    entity_name: error.entityName,
    message: error.message,
    name: error.name as keyof ErrorCode,
    entity_index: error.entityIndex,
    step_checklist_errors: []
  };
}

function fieldErrorToFieldExceptionError(error: FieldError): FieldExceptionError {
  return {
    code: error.code,
    name: error.name as keyof ErrorCode,
    message: error.message,
    field_id: error.fieldId,
    errors: error.errors as ApiErrorBase[]
  };
}

function fieldExceptionErrorToFieldError(fieldExceptionError: FieldExceptionError): FieldError {
  return {
    __typename: 'FieldError',
    fieldId: fieldExceptionError.field_id,
    code: fieldExceptionError.code,
    errors: fieldExceptionError.errors.map(apiBaseError => ({
      __typename: 'FieldError',
      fieldId: fieldExceptionError.field_id,
      code: apiBaseError.code,
      errors: [],
      message: apiBaseError.message,
      name: apiBaseError.name,
      fieldName: fieldExceptionError.name
    })),
    message: fieldExceptionError.message,
    name: fieldExceptionError.name,
    fieldName: fieldExceptionError.name
  };
}

export type ApiError = OtherApiError | FieldAccessNotAllowedApiError | FieldPublishError | EntityValidationException;
