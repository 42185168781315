import type { AsyncValue } from '@ppl/store';
import { AsyncState } from '@ppl/store';
import type { Observable} from 'rxjs';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';


export function fetchScript(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.onload = resolve;
    script.onerror = reject;

    script.type = 'text/javascript';
    script.defer = true;
    script.src = url;

    document.body.appendChild(script);
  });
}

export function fetchStyle(url: string) {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');

    link.onload = resolve;
    link.onerror = reject;

    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';

    head.appendChild(link);
  });
}

export class AsyncLoader<T = any> {

  private readonly state$ = new BehaviorSubject<AsyncValue<T>>({
    state: AsyncState.IDLE
  });

  private get isAlreadyLoadedOrLoading() {
    const state = this.state.state;
    return state !== AsyncState.IDLE && state !== AsyncState.ERROR;
  }

  get state() {
    return this.state$.getValue();
  }

  get loading$(): Observable<boolean> {
    return this.state$.pipe(
      map(state => {
        return state.state === AsyncState.IDLE || state.state === AsyncState.FETCHING;
      })
    );
  }

  constructor() { }

  fetch(startFetchFn: () => Observable<T>): Observable<T> {
    if (this.isAlreadyLoadedOrLoading) {
      return this.state$.pipe(
        first(state => state.state === AsyncState.FETCHED),
        map(state => {
          return state.value;
        })
      );
    } else {
      this.state$.next({ state: AsyncState.FETCHING });
      return startFetchFn().pipe(
        catchError(error => {
          this.state$.next({
            state: AsyncState.ERROR,
            error
          });
          return throwError(error);
        }),
        first(),
        map(result => {
          this.state$.next({
            state: AsyncState.FETCHED,
            value: result
          });
          return result;
        })
      );
    }
  }
}
