import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output
,
  ChangeDetectorRef,
  ElementRef,
  TemplateRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';

@Component({
  selector: 'ppl-text-dropdown',
  templateUrl: './text-dropdown.component.html',
  styleUrls: ['./text-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplTextDropdownComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplTextDropdownComponent implements OnInit {
  @Input() options: PplTextDropdownOption[];
  @Input() value: string;

  @Input() hasIcons = false;
  @Input() @HostBinding('class.disabled') disabled = false;
  @Input() selectedTextTemplate?: TemplateRef<any>;
  @Input() linkStyle?: boolean;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  isVisible = isVisible;

  get label() {
    return (this.value !== undefined) ? this.options.find(option => option.value === this.value)?.label : null;
  }

  get tooltip() {
    return (this.value !== undefined) ? this.options.find(option => option.value === this.value)?.tooltip : null;
  }

  get hasSomeVisibleOption() {
    return hasSomeVisibleOption(this.options);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef // leave here !! (referred-to in template)
  ) { }

  ngOnInit() { }

  onValueChange(value: string) {
    this.valueChange.emit(value);
    this.changeDetectorRef.detectChanges();
  }

}

function isVisible(option: PplTextDropdownOption) {
  return 'visible' in option
    ? option.visible
    : true;
}

export function hasSomeVisibleOption(options: PplTextDropdownOption[]) {
  return options.some(option => isVisible(option));
}

export interface PplTextDropdownOption<T = string> {
  value: T;
  label: string;
  icon?: string;
  visible?: boolean;
  tooltip?: string;
}
