import { PplDialogContainerComponent, PplDialogContainerData } from './dialog-container/dialog-container.component';
import type { PplDialogMessageData } from './dialog-message/dialog-message.component';
import { PplDialogMessageComponent } from './dialog-message/dialog-message.component';
import { PPL_DIALOG_DATA, PplDialogRef } from './dialog-ref';
import type { ButtonType } from '../button/button.directive';
import type {
  ComponentType} from '@angular/cdk/portal';
import {
  ComponentPortal,
  DomPortalHost,
  TemplatePortal
} from '@angular/cdk/portal';
import { Location } from '@angular/common';
import type {
  ViewContainerRef
} from '@angular/core';
import {
  ApplicationRef,
  Injectable,
  Injector,
  TemplateRef
,
  ComponentFactoryResolver} from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class PplDialogService {

  private appRef: ApplicationRef;
  private bodyPortalHost: DomPortalHost;
  private openDialogs: PplDialogRef[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private location: Location
  ) { }

  open<C = any, D = any, R = any>(dialog: ComponentType<any> | TemplateRef<any>, options: PplDialogOpenOptions<D> = {}) {

    if (!this.bodyPortalHost) {
      if (!this.appRef) {
        this.appRef = this.injector.get<ApplicationRef>(ApplicationRef);
      }

      this.bodyPortalHost = new DomPortalHost(
        document.body,
        this.componentFactoryResolver,
        this.appRef,
        this.injector
      );
    }

    const afterClosed = new Subject<R>();
    const afterOpened = new Subject<void>();

    const close = (value?: R) => {
      this.openDialogs = this.openDialogs.filter(openDialog => openDialog !== dialogRef);
      containerRef.destroy();
      afterClosed.next(value);
    };

    const containerData: PplDialogContainerData = {
      afterOpened,
      autoFocus: options.autoFocus === null ? null : options.autoFocus !== false,
      enableClose: options.enableClose === true,
      containerClass: options.containerClass,
      close,
      ...('zIndexIncrement' in options) ? { zIndexIncrement: options.zIndexIncrement } : {}
    };

    const dialogRef: PplDialogRef<C, R> = {
      afterClosed: () => afterClosed.asObservable(),
      afterOpened: () => afterOpened.asObservable(),
      close,
      componentInstance: undefined
    };

    const injector = options.injector || (options.viewContainerRef && options.viewContainerRef.injector) || this.injector;

    const containerInjector = Injector.create([{
      provide: PplDialogContainerData,
      useValue: containerData
    }], injector);

    const containerPortal = new ComponentPortal(PplDialogContainerComponent, null, containerInjector);
    const containerRef = this.bodyPortalHost.attachComponentPortal(containerPortal);

    const componentInjector = Injector.create([{
      provide: PplDialogRef,
      useValue: dialogRef
    }, {
      provide: PPL_DIALOG_DATA,
      useValue: options.data || {}
    }], injector);

    const dialogPortal = (dialog instanceof TemplateRef)
      ? new TemplatePortal(dialog, null)
      : new ComponentPortal(dialog, null, componentInjector);
    const dialogPortalRef = containerRef.instance.dialogPortal.attach(dialogPortal);

    dialogRef.componentInstance = dialogPortalRef.instance;

    this.openDialogs.push(dialogRef);

    const locationSubscription = this.location.subscribe(() => {
      close();
      locationSubscription.unsubscribe();
    });

    return dialogRef;
  }

  closeAll() {
    [...this.openDialogs].reverse().forEach(openDialog => {
      openDialog.close();
    });
  }

  alert(options: PplDialogMessageOptions & { actionLabel?: string, cancelVisible?: boolean, cancelLabel?: string }) {
    return this.open<PplDialogMessageComponent, PplDialogMessageData, true>(PplDialogMessageComponent, {
      data: {
        description: options.description,
        text: options.text,
        type: 'alert',
        actionLabel: options.actionLabel,
        cancelVisible: options.cancelVisible,
        cancelLabel: options.cancelLabel
      },
      zIndexIncrement: 3,
      autoFocus: false
    }).afterClosed();
  }

  confirm(options: PplDialogMessageOptions & { actionLabel?: string, actionButtonType?: ButtonType, cancelLabel?: string, onlyPositive?: boolean }) {
    return this.open<PplDialogMessageComponent, PplDialogMessageData, boolean>(PplDialogMessageComponent, {
      data: {
        actionLabel: options.actionLabel,
        actionButtonType: options.actionButtonType,
        cancelLabel: options.cancelLabel,
        description: options.description,
        text: options.text,
        type: 'confirm'
      },
      zIndexIncrement: 3,
      autoFocus: false
    }).afterClosed().pipe(filter(result => ((options.onlyPositive === false) ? true : result)));
  }

  info(options: PplDialogMessageOptions) {
    return this.open<PplDialogMessageComponent, PplDialogMessageData, true>(PplDialogMessageComponent, {
      data: {
        description: options.description,
        text: options.text,
        type: 'info'
      },
      zIndexIncrement: 3,
      autoFocus: false
    }).afterClosed();
  }

  yesNo(options: PplDialogMessageOptions & { displayCancel?: boolean, yesLabel?: string, noLabel?: string, noButtonType?: ButtonType }) {
    return this.open<PplDialogMessageComponent, PplDialogMessageData, PplYesNoCancelResult>(PplDialogMessageComponent, {
      data: {
        description: options.description,
        noLabel: options.noLabel,
        noButtonType: options.noButtonType,
        text: options.text,
        type: options.displayCancel ? 'yesNoCancel' : 'yesNo',
        yesLabel: options.yesLabel
      },
      zIndexIncrement: 3,
      autoFocus: false
    }).afterClosed();
  }

  yesNoCancel(options: PplDialogMessageOptions & { yesLabel?: string, noLabel?: string, noButtonType?: ButtonType }) {
    return this.open<PplDialogMessageComponent, PplDialogMessageData, PplYesNoCancelResult>(PplDialogMessageComponent, {
      data: {
        description: options.description,
        noLabel: options.noLabel,
        noButtonType: options.noButtonType,
        text: options.text,
        type: 'yesNoCancel',
        yesLabel: options.yesLabel
      },
      zIndexIncrement: 3,
      autoFocus: false
    }).afterClosed();
  }

  warning(options: PplDialogMessageOptions & { actionLabel?: string, cancelVisible?: boolean, cancelLabel?: string }) {
    return this.open<PplDialogMessageComponent, PplDialogMessageData, true>(PplDialogMessageComponent, {
      data: {
        description: options.description,
        text: options.text,
        type: 'warning',
        actionLabel: options.actionLabel,
        cancelVisible: options.cancelVisible,
        cancelLabel: options.cancelLabel
      },
      zIndexIncrement: 3,
      autoFocus: false
    }).afterClosed();
  }

  hasOpenDialogs() {
    return this.openDialogs.length > 0;
  }

}

export interface PplDialogOpenOptions<D> {
  autoFocus?: boolean | null;
  containerClass?: string;
  data?: D;
  injector?: Injector;
  viewContainerRef?: ViewContainerRef;
  enableClose?: boolean; // if true, ESC/click-on-backdrop closes the dialog
  zIndexIncrement?: number;
}

export interface PplDialogMessageOptions {
  description?: string;
  text: string;
  width?: string;
  zIndexIncrement?: number;
}

export enum PplYesNoCancelResult {
  Yes = 'Yes',
  No = 'No',
  Cancel = 'Cancel'
}
