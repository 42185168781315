import { CommonModule } from '@angular/common';
import { PplInfiniteScrollDirective } from './infinite-scroll.directive';
import { NgModule } from '@angular/core';

@NgModule({
 imports: [
  CommonModule
 ],
 declarations: [
  PplInfiniteScrollDirective
 ],
 exports: [
  PplInfiniteScrollDirective
 ]
})
export class PplInfiniteScrollModule { }

export * from './infinite-scroll.directive';
