<ppl-ui-toolbar></ppl-ui-toolbar>

<div class="container demo-content">
  <h2 class="center">
    <ppl-icon class="logo"
              height="80px"
              width="80px"
              svgIcon="pipeliner-switcher"></ppl-icon>
    Components' Showcase
  </h2>
</div>
