import { ButtonType } from '../../button';
import type {
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output
,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplFormActionsComponent implements OnInit, OnDestroy {
  @Input() saving = false;
  @Input() actionLabel: 'save' | 'saveAs' | 'create' | 'add' | 'remove' | string = 'save';
  @Input() actionVisible = true;
  @Input() actionDisabled = false;
  @Input() actionTooltip?: string;
  @Input() actionButtonType = ButtonType.Button2;
  @Input() cancelLabel: 'cancel' | 'close' | 'undoChanges' = 'cancel';
  @Input() cancelDisabledOnSave = false;
  @Input() cancelVisible = true;
  @Input() cancelDisabled = false;
  @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  id: string;

  constructor(
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onSave = () => { };
    this.onCancel = () => { };
  }

  onSave = () => { };
  onCancel = () => { };
}
