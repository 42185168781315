import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import type { PplTreeSelectItem } from '../tree-select-item/tree-select-item.component';

@Component({
  selector: 'ppl-tree-select-options-list',
  templateUrl: './tree-select-options-list.component.html',
  styleUrls: ['./tree-select-options-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectOptionsListComponent implements OnInit {

  @Input() value: string;
  @Input() items: PplTreeSelectItem[];
  @Input() collapsible: boolean;
  @Input() openedByDefault = false;
  @Input() highlightCategories?: boolean;
  @Input() searchValue: string;
  @Input() hideUnavailableLabels: boolean;

  @Output() toggle = new EventEmitter<PplTreeSelectItem>();

  constructor() { }

  ngOnInit(): void {
    this.scrollToSelectedOption();
  }

  private scrollToSelectedOption() {
    const selectedOption = this.value;

    if (selectedOption) {
      setTimeout(() => {
        const selector = `div[data-itemId="${selectedOption}"]`;
        const element = document.querySelector(selector);
        if (element) {
          element.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
      }, 0);
    }
  }
}
