import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplPopoverModule } from '../popover';
import { PplPopoverWindowComponent } from './popover-window.component';

@NgModule({
    imports: [
        CommonModule,
        PplPopoverModule
    ],
    declarations: [
        PplPopoverWindowComponent
    ],
    exports: [
        PplPopoverWindowComponent
    ]
})
export class PplPopoverWindowModule {
}

export * from './popover-window.component';
