import { PplPopoverDirective } from '../../popover';
import { PplUiTimezoneService } from '../../timezones';
import { PplDatePickerIntl } from '../date-picker-intl';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  FormValueControl,
  guessLocalTimezone,
  momentToIsoDateString,
  momentToIsoDateTimeString,
  TimezoneHelper
} from '@ppl/utils';
import moment from 'moment-timezone';
import type {
  OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import type { PplDatePreset } from '../../date-presets';


@Component({
  selector: 'ppl-date-input',
  templateUrl: 'date-input.component.html',
  styleUrls: ['date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplDateInputComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplDateInputComponent implements OnChanges, OnInit {
  @Input() value: string | null = null;
  @Input() disabled = false;
  @Input() disabledValue = false;
  @Input() readonly = false;
  @Input() format?: string;
  @Input() firstWeekDay?: number;
  @Input() buttonLabel = null;
  @Input() displayInput = true;
  @Input() displayTime = false;
  @Input() displayPlanner = false;
  @Input() displayRemove = true;
  @Input() disableRemove = false;
  @Input() outputIsoDate = false;
  @Input() minViewMode = 'days';
  @Input() placeholder = '';
  @Input() displayToday = true;
  @Input() presets?: PplDatePreset[];
  @Input() fullWidthButton = false;
  @Input() timezone: string | null = null;

  @Output() valueChange = new EventEmitter<string | null>();
  @Output() todayClick = new EventEmitter<null>();
  @Output() plannerClick = new EventEmitter<string>();

  @ViewChild('dateInput', { static: false }) dateInput: ElementRef;
  @ViewChild('pickerButton', { read: PplPopoverDirective, static: true }) pickerButtonPopover: PplPopoverDirective;
  @ViewChild('pickerHiddenFocus', { static: false }) pickerHiddenFocus: ElementRef;

  dateValue = '';
  dateValueTouched = false;

  popoverValue: Date | null = null;
  popoverDefaultValue: Date = null;

  timezoneHelper = new TimezoneHelper(() => this.getTimezone());
  popoverHighlightDate: Date = this.timezoneHelper.getCurrentZonedTime();

  get displayFormat() {
    if (this.format) {
      return this.format;
    } else {
      if (this.displayTime) {
        return this.datePickerIntl.dateTimeFormat || 'M/d/yy hh:mm';
      } else {
        return this.datePickerIntl.dateFormat || 'M/d/yy';
      }
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private datePickerIntl: PplDatePickerIntl,
    @Optional() @Inject(PplUiTimezoneService) private timezoneService: PplUiTimezoneService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value || changes.format || this.timezone) {
      this.updateDateInput(this.value);
    }
  }

  ngOnInit() {
    this.updatePopoverDefaultValue();
  }

  onDateInput() {
    if (!this.readonly) {
      this.dateValue = this.dateInput.nativeElement.value;
      this.dateValueTouched = true;
    }
  }

  onDateKeyUp(event: KeyboardEvent) {
    if (!this.readonly && event.keyCode === 13) {
      this.triggerChangeEvent(this.parseUserInput());
      this.pickerButtonPopover.hide();
    }
  }

  onDateBlur() {
    if (!this.readonly && this.dateValueTouched) {
      this.triggerChangeEvent(this.parseUserInput());
    }
  }

  onPopoverOpened(opened: boolean) {
    if (opened) {
      let popoverValue = this.value
        ? this.timezoneHelper.getValueAsZonedTime(this.value)
        : null;

      if (this.dateValueTouched) {
        const value = this.parseUserInput();

        if (value) {
          popoverValue = value;
        } else {
          const currentDate = this.timezoneHelper.getCurrentZonedTime();

          this.triggerChangeEvent(currentDate);
          popoverValue = currentDate;
        }
      }

      this.popoverValue = popoverValue;
      this.pickerHiddenFocus?.nativeElement?.focus();

      // Recalc position after jQuery datepicker has been inserted
      this.pickerButtonPopover.recalcPosition();
    }
  }

  onPlannerClick(date: Date) {
    this.plannerClick.emit(this.timezoneHelper.getZonedTimeAsValue(date).toISOString());
    this.pickerButtonPopover.hide();
  }

  onPopoverValueChange(value: Date | null) {
    this.triggerChangeEvent(value);
  }

  onPopoverClose() {
    this.pickerButtonPopover.hide();
  }

  onTodayClick() {
    if (this.todayClick && this.todayClick.observers.length !== 0) {
      this.todayClick.emit();
    } else {
      this.onPopoverValueChange(this.timezoneHelper.getCurrentZonedTime());
    }
  }

  onPresetValueChange(date: string) {
    this.triggerChangeEvent(this.timezoneHelper.getValueAsZonedTime(date));
    this.onPopoverClose();
  }

  private triggerChangeEvent(value: Date | null) {
    const dateValue: Date | null = this.timezoneHelper.getZonedTimeAsValue(value);

    // deny incorrect value if remove is not allowed
    if (this.disableRemove && !dateValue) {
      this.updateDateInput(this.value);
      return;
    }

    const nextValue = dateValue
      ? (
        this.outputIsoDate
          ? (
            this.displayTime
              ? momentToIsoDateTimeString(this.timezoneHelper.getDateAsMoment(dateValue))
              : momentToIsoDateString(this.timezoneHelper.getDateAsMoment(dateValue))
          )
          : this.timezoneHelper.getDateAsMoment(dateValue).format())
      : null;

    this.valueChange.emit(nextValue);
  }

  private updateDateInput(value: string | null) {
    if (value) {
      const zonedTime = this.timezoneHelper.getValueAsZonedTime(value);
      this.dateValue = this.datePipe.transform(
        zonedTime,
        this.displayFormat || null,
        null,
        this.datePickerIntl.angularLocaleId
      );
    } else {
      this.dateValue = '';
    }

    this.dateValueTouched = false;
  }

  // NOTE(mike): Returns zoned Date.
  private parseUserInput() {
    const getZonedTime = (value: string, format: string): Date => {
      return this.timezoneHelper.getValueAsZonedTime(moment.tz(value, format, this.timezoneHelper.getTimezone()).format());
    };

    const baseFormat = this.displayFormat.replace(/d/g, 'D').replace(/y/g, 'Y');

    // 1. try to parse 4digit year in the date string (if format includes two y)
    const fullYearMomentFormat = baseFormat.replace(/YY/g, 'YYYY');

    const fullYearParsedDate = getZonedTime(this.dateValue, fullYearMomentFormat);
    if (fullYearParsedDate.toString() !== 'Invalid Date') {
      return fullYearParsedDate;
    }

    // 2. otherwise default to base format
    const parsedDate = getZonedTime(this.dateValue, baseFormat);

    if (parsedDate.toString() !== 'Invalid Date') {
      return parsedDate;
    }

    return null;
  }

  private getTimezone(): string {
    return this.timezone || this.timezoneService?.getDefaultTimezone() || guessLocalTimezone();
  }

  private updatePopoverDefaultValue() {
    this.popoverDefaultValue = this.timezoneHelper.getCurrentZonedTime();
  }
}
