import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PplUiIntl {
  search = 'Search';
  showAll = 'Show All';
  all = 'All';
  showOnlyPrimary = 'Show Only Primary';
  today = 'Today';
  day = 'Day';
  week = 'Week';
  workWeek = 'Work Week';
  month = 'Month';
  documents = 'Documents';
  more = 'more';
  attachmentsUpload = 'Click here to upload';
  browse = 'Browse';
  insertFromUrl = 'Insert From Url';
  remove = 'Remove';
  imageEditor = 'Image Editor';
  changePhoto = 'Change Photo';
  dragTheYellowCorner = 'Drag the yellow corner';
  inOrderToCropUploadedImage = 'in order to crop uploaded image';
  apply = 'Apply';
  cancel = 'Cancel';
  createNew = 'Create new';
  addNew = 'Add new';
  addNewAccount = 'Add new account';
  addNewContact = 'Add new contact';
  addNewLinkedItem = 'Add new linked item';
  attachNewDocument = 'Attach new document';
  primary = 'PRIMARY';
  setAsPrimary = 'SET AS PRIMARY';
  no = 'No';
  linked = 'linked';
  clickHereToWrite = 'Click here to write';
  clickHereToAdd = 'Click here to Add';
  add = 'Add';
  appointmentPlanner = 'Appointment Planner';
  ok = 'OK';
  tierTitle = 'Choose a plan that\'s right for your business';
  current = 'CURRENT';
  user = 'User';
  contactUs = 'CONTACT US';
  activate = 'ACTIVATE';
  addSalesRole = 'Add Sales Role';
  addAccountRole = 'Add Account Role';
  create = 'create';
  Create = 'Create';
  or = 'or';
  inactive = 'Inactive';
  Deleted = 'Deleted';
  dropMessage = 'Drop here your image to upload';
  noResultsFound = 'No results found';
  startTyping = 'start typing...';
  clickToSearch = 'Click to search...';
  invalidEmailFormatError = 'invalidEmailFormatError';
  invalidUrlFormatError = 'invalidUrlFormatError';
  invalidSecureUrlFormatError = 'invalidSecureUrlFormatError';
  invalidOptionSelectedError = 'invalidOptionSelectedError';
  ownerNotHaveAccessSalesUnitError = 'ownerNotHaveAccessSalesUnitError';
  userNotMemberOfSalesUnitError = 'userNotMemberOfSalesUnitError';
  invalidMinimalValueError = 'invalidMinimalValueError';
  invalidMinDateError = 'invalidMinDateError';
  invalidMaxDateError = 'invalidMaxDateError';
  invalidValueRangeError = 'invalidValueRangeError';
  invalidFieldValueError = 'invalidFieldValueError';
  invalidRequiredPatternError = 'invalidRequiredPatternError';
  invalintIntegerValueError = 'invalintIntegerValueError';
  invalidNumberValueError = 'invalidNumberValueError';
  minHasToBeSmallerThanMaxError = 'minHasToBeSmallerThanMaxError';
  atLeastOneSelectedError = 'atLeastOneSelectedError';
  invalidPasswordError = 'invalidPasswordError';
  invalidLengthError = 'invalidLengthError';
  invalidMinLengthError = 'invalidMinLengthError';
  actualLength = 'actualLength';
  invalidCount = 'invalidCountError';
  invalidMinCount = 'invalidMinCountError';
  actualCount = 'actualCount';
  highlightedProductsAreNotAvailableError = 'highlightedProductsAreNotAvailableError';
  productsServicesInvalidProductsError = 'productsServicesInvalidProductsError';
  highlightedCurrencyIsNotAvailableError = 'highlightedCurrencyIsNotAvailableError';
  highlightedPriceListIsNotAvailableError = 'highlightedPriceListIsNotAvailableError';
  dayIsNotSelectedError = 'dayIsNotSelectedError';
  itemUnavailableError = 'itemUnavailableError';
  thisFieldIsRequiredError = 'thisFieldIsRequiredError';
  firstOrLastNameRequiredError = 'firstOrLastNameRequiredError';
  invalidHeaderKeyError = 'invalidHeaderKeyError';
  invalidHeaderValueError = 'invalidHeaderValueError';
  duplicateHeadersError = 'duplicateHeadersError';
  invalidRollupNestingError = 'invalidRollupNestingError';
  requiredDependentEntityError = 'requiredDependentEntityError';
  enterJobPosition = 'Enter Job Position';
  partialSelectAll = 'partialAll';
  partialSelectSelected = 'partialSelected';
  DateOffsetUnit_Hour = 'DateOffsetUnit_Hour';
  DateOffsetUnit_Day = 'DateOffsetUnit_Day';
  DateOffsetUnit_WorkingDay = 'DateOffsetUnit_WorkingDay';
  DateOffsetUnit_Week = 'DateOffsetUnit_Week';
  DateOffsetUnit_Month = 'DateOffsetUnit_Month';
  DateOffsetDirection_Before = 'DateOffsetDirection_Before';
  DateOffsetDirection_After = 'DateOffsetDirection_After';
  Proceed = 'Proceed';
  Confirm = 'Confirm';
  Cancel = 'Cancel';
  Continue = 'Continue';
  Yes = 'Yes';
  No = 'No';
  AddAll = 'AddAll';
  clickToSelect = 'Click to select...';
  noOptionsSelected = 'No options selected';
}
