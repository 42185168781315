import type { Observable } from 'rxjs';

export abstract class TokenService {

  abstract signIn(options: TokenSignInOptions): Observable<boolean>;
  abstract signOut(options: TokenSignOutOptions): Observable<boolean>;

  abstract getToken(): Observable<string | null>;
  abstract setToken(token: string): Observable<null>;
  abstract deleteToken(): Observable<null>;

  abstract reloadPage(): void;

}

export interface TokenSignInOptions {
  appDescription?: string;
  appUrl: string;
  disableGoogleSignIn?: boolean;
  disableMicrosoftSignIn?: boolean;
  queryForSpace?: boolean;
  ssoUrl: string;
}

export interface TokenSignOutOptions {
  appUrl: string;
  ssoUrl: string;
}
