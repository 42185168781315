<div *ngIf="displayCheckboxField"
     class="field field-checkbox"
     [style.width.px]="checkboxFieldWidth"
     (dblclick)="$event.stopPropagation()">
  <ng-container *ngIf="displayCheckbox">
    <ng-container *ngIf="checkboxTemplate; else defaultCheckboxTemplate">
      <ng-container *ngTemplateOutlet="checkboxTemplate; context: { $implicit: selected, record: record }"></ng-container>
    </ng-container>
    <ng-template #defaultCheckboxTemplate>
      <ppl-checkbox [value]="selected"
                    (valueChange)="recordCheck.emit()"
                    (click)="$event.stopPropagation()"></ppl-checkbox>
    </ng-template>
  </ng-container>

  <div *ngIf="!displayDragField && columnsWithData[0] && !columnsWithData[0].frozen"
       class="divider"
       [class.visible]="startDividerVisible"></div>
</div>

<div *ngIf="displayDragField"
     class="field field-drag"
     [style.left.px]="displayCheckboxField ? checkboxFieldWidth : 0"
     [style.width.px]="dragFieldWidth"
     (mousedown)="dragStart.emit($event)">
  <ppl-icon [svgIcon]="dragFieldIcon ? dragFieldIcon : 'reorder-arrows'"
            width="20px"
            height="24px"></ppl-icon>

  <div *ngIf="columnsWithData[0] && !columnsWithData[0].frozen"
       class="divider"
       [class.visible]="startDividerVisible"></div>
</div>

<div *ngFor="let column of columnsWithData; let i = index; trackBy: trackById"
     #columnElement
     class="field"
     [class.frozen]="column.frozen"
     [style.left]="column.frozen ? column.offset + 'px' : 'auto'"
     [style.width.px]="column.width"
     [class.placeholder]="dragColumnId && dragColumnId === column.id"
     [attr.data-field]="column.id"
     [style.backgroundColor]="record?._meta?.colors?.[column.id]?.bg"
     [style.color]="record?._meta?.colors?.[column.id]?.font">
  <span *ngIf="(!dragColumnId || dragColumnId !== column.id)"
        class="data"
        [class.stretch]="column.stretch"
        [title]="column.template ? '' : record[column.id]">
    <ng-container *ngIf="column.template; else textColumn">
      <ng-template *ngTemplateOutlet="column.template; context: {
        '\$implicit': record[column.id],
        record: record,
        fieldID: column.id,
        typeOptions: column.typeOptions
      }"></ng-template>
    </ng-container>
    <ng-template #textColumn>
      {{record[column.id]}}
    </ng-template>
  </span>

  <div *ngIf="record && (dragColumnId && dragColumnId === column.id)"
       class="fake-rect"></div>

  <div *ngIf="columnToolsTemplate"
       class="tools">
    <ng-container *ngTemplateOutlet="columnToolsTemplate; context: {
      $implicit: record[column.id],
      record: record,
      fieldID: column.id,
      rowIndex: index,
      columnElement: columnElement,
      columnExtras: record?._meta?.extras?.[column.id]
    }"></ng-container>
  </div>

  <div *ngIf="column.frozen && columnsWithData[i + 1] && !columnsWithData[i + 1].frozen"
       class="divider"
       [class.visible]="startDividerVisible"></div>
</div>

<!-- Layout placeholder if lastField is floating -->
<div *ngIf="displayLastField && (!horizontalScroll || !lastFieldHasActions)"
     [style.width.px]="lastColumnWidth"></div>

<div *ngIf="displayLastField && lastFieldHasActions"
     class="field field-last"
     [class.float-right]="!horizontalScroll"
     [style.width.px]="lastColumnWidth"
     (dblclick)="$event.stopPropagation()">
  <ppl-grid-record-actions-button *ngIf="!actionsTemplate && enabledActions.length > 0 && (enabledActions.length > 1 || !singleAction)"
                                  [actions]="enabledActions"
                                  [record]="record"
                                  (actionClick)="actionClick.emit($event)"></ppl-grid-record-actions-button>

  <ppl-grid-record-action-icon *ngIf="!actionsTemplate && (enabledActions.length === 1 && singleAction)"
                               [action]="enabledActions[0]"
                               [record]="record"
                               (actionClick)="actionClick.emit($event)"></ppl-grid-record-action-icon>

  <ng-container *ngIf="actionsTemplate">
    <ng-container *ngTemplateOutlet="actionsTemplate; context: {
      $implicit: record
    }"></ng-container>
  </ng-container>

  <div class="divider reversed"
       [class.visible]="lastDividerVisible"></div>
</div>
