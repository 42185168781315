import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { ActivatedRouteSnapshot, CanActivate, Route } from '@angular/router';
import { of } from 'rxjs';
import { AuthStore } from '../../store/auth.state';
import { jwtDecode } from '../../utils/jwt.utils';
import { RedirectTokenService } from './redirect-token.service';

@Injectable()
export class RedirectTokenServiceGuard implements CanActivate {

  constructor(
    private redirectTokenService: RedirectTokenService,
    private router: Router,
    private store: AuthStore
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const result = jwtDecode(route.queryParams.token);

    if (result) {
      this.store.dispatch('Auth_UpdateSsoResult', {
        token: result.ppl_token,
        spaceId: result.team_space_id
      });

      this.redirectTokenService.setToken(result.ppl_token);
      this.redirectTokenService.setAppSessionId(result.app_session_id);
    } else {
      console.error('Token decode error');
      return of(false);
    }

    return this.router.navigateByUrl('/');
  }

}

export const RedirectTokenRoute: Route = {
  path: 'sso',
  canActivate: [RedirectTokenServiceGuard],
  component: class {}
};
