<ppl-ranking [disabled]="disabled || readonly"
             [value]="stars"
             (valueChange)="onStarsChange($event)"></ppl-ranking>
<div class="input-container">
  <div *ngIf="prependIcon"
       class="prepend-icon"
       pplPopover
       [pplPopoverTooltip]="prependText ? prependText : null">
    <ppl-icon [svgIcon]="prependIcon"
              height="15px"
              width="15px"></ppl-icon>
  </div>
  <input #valueInput
         pplInput
         [disabled]="disabled || readonly"
         [attr.readonly]="readonly ? true : null"
         [value]="value"
         (keyup)="onInputChange($event.target.value)">
  <span class="input-container-sign">%</span>
</div>
