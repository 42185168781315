<div *ngIf="isRoot; else list"
     class="root">
  <ng-container *ngTemplateOutlet="list"></ng-container>
</div>

<ng-template #list>
  <ul>
    <li *ngFor="let item of items; trackBy: trackTree"
        [class.parent-li]="!item.parentId">
      <ppl-tree-select-item [collapsible]="collapsible"
                            [searchValue]="searchValue"
                            [item]="item"
                            [openedByDefault]="openedByDefault"
                            [highlightCategories]="highlightCategories"
                            [hideUnavailableLabels]="hideUnavailableLabels"
                            (toggle)="toggle.emit($event)">
      </ppl-tree-select-item>
    </li>
  </ul>
</ng-template>