<div class="container demo-content">
  <h2>Account class</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-account-class</h4>
  <div>@Input()<b>value</b>: 1 | 2 | 3 | 4 | 5</div>
  <div>@Input()<b>selection</b>: 'one' | 'multiple' = 'one'</div>
  <div>@Input()<b>showOne</b>: = false</div>
  <div>@Input()<b>disabled</b>: boolean</div>
  <div>@Input()<b>readonly</b>: boolean</div>
  <br>
  <div>@Output()<b>change</b>: 1 | 2 | 3 | 4 | 5</div>
  <br>
  <br>

  <h3>Example</h3>

  <h4>Normal</h4>
  <ppl-input-container>
    <ppl-account-class [value]="value"
                       (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>

  <br>

  <h4>One Value</h4>
  <ppl-input-container>
    <ppl-account-class [showOne]="true"
                       [value]="value"
                       (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>

  <br>

  <h4>Readonly</h4>
  <ppl-input-container>
    <ppl-account-class [readonly]="true"
                       [value]="value"
                       (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>

  <br>

  <h5>Disabled</h5>
  <ppl-input-container>
    <ppl-account-class [value]="value"
                       [disabled]=true
                       (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
