import { CommonModule } from '@angular/common';
import { ResizableDirective } from './resizable.directive';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ResizableDirective
  ],
  exports: [
    ResizableDirective
  ]
})
export class PplResizableModule { }

export * from './resizable.directive';
