import { PplItemsChooserComponent } from './items-chooser.component';
import { PplMenuModule } from '../menu';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplPopoverTooltipModule } from '../popover-tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';


@NgModule({
  imports: [
    CommonModule,
    PplPopoverModule,
    PplPopoverTooltipModule,
    PplMenuModule,
    PplPopoverDropdownModule
  ],
  declarations: [
    PplItemsChooserComponent
  ],
  exports: [
    PplItemsChooserComponent
  ]
})
export class PplItemsChooserModule {
}

export * from './items-chooser.component';

