<div class="sales-units-header">
  <div>
    <ppl-checkbox *ngIf="!searchText && !readonly"
                  [class.middle-state]="hasCheckboxMiddleState"
                  [value]="isCheckboxChecked"
                  (valueChange)="toggleAll($event)"></ppl-checkbox>
    <span class="title">
      {{ title }}
    </span>
  </div>
  <div *ngIf="displaySearch">
    <ppl-search [value]="searchText"
                (valueChange)="onSearchChange($event)"></ppl-search>
  </div>
  <div *ngIf="hasSubItemsSlider">
    <ppl-slide-toggle label="{{includeSubunitsLabel ? includeSubunitsLabel : 'Include Sub units'}}"
                      [value]="shouldIncludeSubItems"
                      labelPosition="before"
                      (valueChange)="shouldIncludeSubItems = $event"></ppl-slide-toggle>
  </div>
</div>
<div class="sales-units-content"
     [class.ellipsis]="hasSingleLevel">
  <ppl-form-table-tree *ngFor="let item of itemsTree; trackBy: trackTree"
                       [openedByDefault]="openedByDefault"
                       [item]="item"
                       [readonly]="readonly"
                       [actionTemplate]="actionTemplate"
                       (toggle)="toggle($event)"></ppl-form-table-tree>
</div>

<div *ngIf="disabled"
     class="disabled-overlay"></div>
