import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import type { PplFormTableItem } from '@ppl/ui/form-table';

@Component({
  selector: 'ppl-form-table-demo',
  templateUrl: './form-table-demo.component.html',
  styleUrls: ['./form-table-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormTableDemoComponent implements OnInit {

  items: PplFormTableItem[] = [
    {
      id: '1',
      name: 'example1',
      checked: false,
      parentId: null
    },
    {
      id: '2',
      name: 'example2',
      checked: false,
      parentId: null
    },
    {
      id: '3',
      name: 'example3',
      checked: false,
      parentId: null
    },
    {
      id: '4',
      name: 'example4',
      checked: false,
      parentId: null
    }
  ];

  code = `
  <ppl-form-table [items]="items"
                  (changeItems)="onSimpleChange($event)"
                  title="example"></ppl-form-table>
  `;

  constructor() { }

  ngOnInit() {
  }

  onSimpleChange($event: PplFormTableItem[]) {
    this.items = $event;
  }

}
