import { PplLinkDirective } from './link.directive';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplLinkDirective
  ],
  exports: [
    PplLinkDirective
  ]
})
export class PplLinkModule {
}

export * from './link.directive';

