import { hasInterpolation } from './interpolation.utils';
import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

function isEmptyInputValue(value: any): boolean {
  return value == null || value.length === 0;
}

// email regex same as in API and other apps
/* eslint:disable-next-line:max-line-length */
const emailRegex = new RegExp(/^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i);

export function validateEmail(email: string): boolean {
  if (isEmptyInputValue(email) || hasInterpolation(email)) {
    // don't validate empty values to allow optional controls
    // don't validate interpolated values
    return true;
  }
  return emailRegex.test(email);
}

export class PplValidators {

  static integer = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || hasInterpolation(control.value)) {
      return null;
    }

    return /^-?[0-9]+$/.test(control.value)
      ? null
      : { custom: 'Not an integer' };
  };

  static number = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || hasInterpolation(control.value)) {
      return null;
    }

    return /[-+]?(?:\d*\.?\d+|\d+\.?\d*)(?:[eE][-+]?\d+)?/.test(control.value)
      ? null
      : { custom: 'Not a number' };
  };

  static url = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || hasInterpolation(control.value)) {
      return null;
    }

    return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(control.value)
      ? null
      : { custom: 'Invalid URL' };
  };

  static secureUrl = (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value || hasInterpolation(control.value)) {
      return null;
    }

    return /^(?:https:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(control.value)
      ? null
      : { custom: 'Invalid URL. It has to start with https://' };
  };

  static email = (control: AbstractControl): ValidationErrors | null => {
    return validateEmail(control.value) ? null : { email: 'Invalid email' };
  };

  static nonEmptyArray = (control: AbstractControl): ValidationErrors | null => {
    return isEmptyInputValue(control.value)
      ? { required: true }
      : null;
  };

  static nonEmptyOptionArray = (control: AbstractControl): ValidationErrors | null => {
    return isEmptyInputValue(control.value)
      ? { atLeastOneOption: true }
      : null;
  };

  static maxCount = (count: number) => (control: AbstractControl) => {
    if (!control || !control.value) {
      return null;
    }

    const length = (control.value as any[]).length;

    return length > count ? {
      maxItemCount: {
        actualLength: length,
        requiredLength: count
      }
    } : null;
  };

  static minCount = (count: number) => (control: AbstractControl) => {
    if (!control || !control.value) {
      return null;
    }

    const length = (control.value as any[]).length;

    return length < count ? {
      minItemCount: {
        actualLength: length,
        requiredLength: count
      }
    } : null;
  };

  static emailsSeparatedBySemicolon = (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional controls
    }
    for (const email of control.value.split(';')) {
      if (!validateEmail(email)) {
        return { email: 'Invalid emails format separated by semicolon' };
      }
    }
    return null;
  };

  static nonDeletedSelectOption = (options?: { value: string, deleted?: boolean }[], errorKey?: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      } else {
        const eligibleOptions: typeof options = (options || ((control as any).lazyLoadedOptions) || []);
        // console.log(eligibleOptions);
        const selectedOption = eligibleOptions.find(foundOption => foundOption.value === control.value);
        // either no option is selected (use required validator if needed)
        // or if there is a selected one, make sure it's not labeled as deleted
        return !selectedOption || !selectedOption.deleted
          ? null
          : {
            [errorKey || 'invalidOption']: true
          };
      }
    };
  };

  static ownerMemberOfUnit = (clientUnitIds: string[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      } else {
        return clientUnitIds.includes(control.value)
          ? null
          : {
            ownerUnitMembership: true
          };
      }
    };
  };

  static requiredMinItems(minItems): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || (Array.isArray(value) && value.length < minItems)) {
        return {
          required: {
            minItems
          }
        };
      }
      return null;
    };
  }

  static minDateValue(minValue, isRecurrentDatePicker = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return;
      }
      if ((isRecurrentDatePicker && hasInterpolation(control.value.date)) || hasInterpolation(control.value)) {
        return null;
      }
      const value = (isRecurrentDatePicker) ? moment(control.value.date) : moment(control.value);
      if (value) {
        value.set('second', 0);
      }
      if (control.value && value.isValid() && value.isBefore(minValue, 'minute')) {
        return { minDateValue: minValue };
      }
      return null;
    };
  }

  static maxDateValue(maxValue, isRecurrentDatePicker = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return;
      }
      if ((isRecurrentDatePicker && hasInterpolation(control.value.date)) || hasInterpolation(control.value)) {
        return null;
      }
      const value = (isRecurrentDatePicker) ? moment(control.value.date) : moment(control.value);
      if (value) {
        value.set('second', 59);
      }
      if (control.value && value.isValid() && value.isAfter(maxValue, 'minute')) {
        return { maxDateValue: maxValue };
      }
      return null;
    };
  }

  static requiredCombined(fieldsCombination: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null;  // don't validate empty values to allow optional controls
      }
      const fields = fieldsCombination.split('OR').map((fieldId: string) => fieldId.trim());
      fields.forEach((fieldId) => {
        if (control.value[fieldId] && control.value[fieldId]) {
          return null;
        }
      });
      return { requiredCombined: fieldsCombination };
    };
  }
}
