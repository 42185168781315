import type { CreatePointerEvent } from '@ppl/utils';
import { clamp, createPointerEvents } from '@ppl/utils';
import type {
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  Directive,
  EventEmitter,
  Input,
  Output
,
  ElementRef,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[pplResizable]'
})
export class ResizableDirective implements OnDestroy, OnInit {
  @Input() pplResizableSize: number;
  @Input() pplResizableActive = true; // indicates whether resizable logic should work at the time of mousedown
  @Input() pplResizableOrientation: PplPosition = 'left';
  @Input() pplResizableSizeConstraints?: PplResizableSizeConstraints;
  @Output() pplResizableSizeChange = new EventEmitter<number>();

  destroyMouseDown: () => void;

  get container(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get isElementScrollable() {
    return this.container.offsetHeight !== this.container.scrollHeight;
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {
    this.destroyMouseDown = this.renderer.listen(this.container, 'mousedown', $event => this.onMouseDown($event));
  }

  ngOnDestroy(): void {
    if (this.destroyMouseDown) {
      this.destroyMouseDown();
    }
  }

  private onMouseDown(pressEvent: MouseEvent) {
    if (!this.pplResizableActive) {
      return;
    }

    const startSidebarSize = this.pplResizableSize;

    // note: should probably extract createPointerEvents & clamp fns into a separate, ng-ui file
    createPointerEvents({
      event: pressEvent,
      onPointerMove: (event) => {
        const result = calculateResult(this.pplResizableOrientation, startSidebarSize, event);
        const size = this.pplResizableSizeConstraints
          ? clamp(result, this.pplResizableSizeConstraints.min, this.pplResizableSizeConstraints.max)
          : result;
        this.pplResizableSizeChange.emit(size);
      }
    });
  }
}

function calculateResult(direction: PplPosition, startSize: number, event: CreatePointerEvent) {
  switch (direction) {
    case 'left':
      return startSize + event.dtStartX;
    case 'right':
      return startSize - event.dtStartX;
    case 'top':
      return startSize + event.dtStartY;
    case 'bottom':
      return startSize - event.dtStartY;
  }
}

export type PplPosition = 'top' | 'bottom' | 'left' | 'right';

export interface PplResizableSizeConstraints {
  readonly min: number;
  readonly max: number;
}
