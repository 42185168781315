import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-ranking-demo',
  templateUrl: './ranking-demo.component.html',
  styleUrls: ['./ranking-demo.component.scss']
})
export class RankingDemoComponent implements OnInit {
  value = 1;

  code = `
  <ppl-input-container>
    <ppl-ranking [value]="value" (valueChange)="change($event)"></ppl-ranking>
  </ppl-input-container>

  Selected value: {{value}}

  <h5>Disabled</h5>

  <ppl-input-container>
    <ppl-ranking [value]="value" [disabled]="true" (valueChange)="change($event)"></ppl-ranking>
  </ppl-input-container>

  Selected value: {{value}}`;

  constructor() { }

  ngOnInit() {
  }

  change(value: number) {
    this.value = value;
  }

}
