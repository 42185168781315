<input pplInput
       [disabled]="disabled"
       [value]="value"
       [autocomplete]="autocompleteId"
       [id]="null"
       (blur)="inputBlur.emit()"
       (input)="onValueChange($event.target.value)">
<button type="button"
        *ngIf="showSendEmail"
        pplButton
        pplButton1
        pplButtonFocusable
        [pplPopover]="popoverTemplate"
        pplPopoverActiveClass="ppl-button--active"
        pplPopoverAlignEnd
        [disabled]="valueEmpty ? true : null">
  <ppl-icon svgIcon="open-email"
            width="18px"
            height="18px"></ppl-icon>
</button>
