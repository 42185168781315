import {
  Directive,
  EventEmitter,
  HostListener,
  Output
} from '@angular/core';

@Directive({
  selector: '[pplDropZone]'
})
export class PplDropZoneDirective {

  @Output() pplDropZoneOver = new EventEmitter<boolean>();
  @Output() pplDropZoneDrop = new EventEmitter<File[]>();

  dragEnterEventCounter = 0;

  @HostListener('drag', ['$event'])
  onDrag($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('dragstart', ['$event'])
  onDragStart($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.pplDropZoneOver.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.pplDropZoneOver.emit(true);
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter($event) {
    this.dragEnterEventCounter++;
    $event.preventDefault();
    $event.stopPropagation();
    this.pplDropZoneOver.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    // Prevent dragenter/dragleave event firing when mouse pointer goes from one descendant element to another (causes blinking of the drag overlay)
    // https://stackoverflow.com/a/21002544
    this.dragEnterEventCounter--;
    if (this.dragEnterEventCounter === 0) {
      this.pplDropZoneOver.emit(false);
    }

    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop($event) {
    const files = $event.dataTransfer.files as File[];
    $event.preventDefault();
    $event.stopPropagation();
    this.dragEnterEventCounter = 0;
    this.pplDropZoneOver.emit(false);
    this.pplDropZoneDrop.emit(files);
  }
}
