import { PplPosition } from '../../resizable';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ppl-drawer-control',
  templateUrl: './drawer-control.component.html',
  styleUrls: ['./drawer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDrawerControlComponent implements OnInit {

  @Input() open: boolean;
  @Input() disabled: boolean;
  @Input() position: PplPosition;

  @Output() openChange = new EventEmitter<boolean>();

  @HostBinding('class')
  get hostClass() {
    if (this.position) {
      return this.position.toLowerCase();
    }
  }

  @HostBinding('class.vertical')
  get vertical() {
    return this.position === 'top' || this.position === 'bottom';
  }

  get horizontal() {
    return this.position === 'right' || this.position === 'left';
  }

  constructor() { }

  ngOnInit() { }


  onSliderClick() {
    this.openChange.emit(this.open);
  }

}
