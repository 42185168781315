import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import type { Action } from './action';
import type { Reducer } from './reducer';

export class Store<S, A> {

  private rootReducer: Reducer<S>;
  private listener: <T extends keyof A>(state: S, action: Action<T, A[T]>) => any;
  private state: BehaviorSubject<S>;
  private devTools: any;

  constructor() {}

  public setRootReducer(rootReducer: Reducer<S>, initialState?: S) {
    this.rootReducer = rootReducer;

    // Initialize state tree
    const initAction = { type: '__StoreInit__', payload: undefined };
    this.state = new BehaviorSubject<S>(initialState ? initialState : this.rootReducer(undefined, initAction));

    const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
    if (devTools) {
      this.devTools = devTools.connect();
      this.devTools.send(initAction, this.getState());
    }
  }

  public setListener(listener: <T extends keyof A>(state: S, action: Action<T, A[T]>) => any) {
    this.listener = listener;
  }

  public getState() {
    return this.state.getValue();
  }

  public dispatch<T extends keyof A>(type: T, payload: A[T]) {
    const action = { type, payload };

    this.state.next(this.rootReducer(this.state.getValue(), action));

    if (this.devTools) {
      this.devTools.send(action, this.getState());
    }

    if (this.listener) {
      this.listener.call(null, this.getState(), action);
    }
  }

  public select<R>(selector: (state: S) => R) {
    return this.state.asObservable().pipe(map(selector), distinctUntilChanged());
  }

  public get<R>(selector: (state: S) => R) {
    return selector(this.getState());
  }

}
