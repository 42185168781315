import type { PplImageEditorData } from './image-editor/image-editor.component';
import { PplImageEditorComponent } from './image-editor/image-editor.component';
import { ImageUrlComponent } from './image-url/image-url.component';
import { PplDialogService } from '../dialog';
import { PplUiIntl } from '../ppl-ui-intl';
import {
  ChangeDetectorRef,
  ElementRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import type { ControlValueAccessor} from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MemoizeLast } from '@ppl/utils';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ppl-addressbook-picture',
  templateUrl: 'addressbook-picture.component.html',
  styleUrls: ['addressbook-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplAddressbookPictureComponent),
      multi: true
    }
  ]
})
export class PplAddressbookPictureComponent implements ControlValueAccessor {
  @Input() picture: string;
  @Input() entity = 'contact';
  @Input() isEditable = false;
  @Input() forceRound = false;
  @Input() containPicture = false;
  @Input() backgroundColor: string = null;
  @Input() insertFromUrlEnabled = false;
  @Input() width?: string;
  @Input() height?: string;

  @Output() pictureChange = new EventEmitter();
  @Output() pictureUrlChange = new EventEmitter();

  onChange: any;
  onTouched: any;
  dragOver: boolean;
  image: any;

  browse = 'Browse';
  insertFromUrl = 'Insert From URL';
  remove = 'Remove';
  dropMessage = 'Drop here your image to upload';

  imageTypeLimit: PplAttachmentFileType = { denied: false, list: ['png', 'jpg', 'jpeg', 'jfif'] };
  singleSelection = true;

  @ViewChild('fileSelector', { static: false }) fileSelector: ElementRef;

  get iconName() {
    return ({
      account: 'account-avatar',
      contact: 'contact-avatar',
      space: 'pipeline-avatar',
      unit: 'salesunit-avatar',
      product: 'product-avatar'
    })[this.entity];
  }

  @MemoizeLast<PplAddressbookPictureComponent>(['isEditable', 'picture', 'insertFromUrlEnabled'])
  get options(): Option[] {
    const result: Option[] = [];

    if (!!this.isEditable) {
      result.push('browse');
    }
    if (!!this.isEditable && this.insertFromUrlEnabled) {
      result.push('insertFromUrl');
    }
    if (!!this.isEditable && !!this.picture) {
      result.push('remove');
    }

    return result;
  }

  get optionsCount() {
    return this.options.length;
  }

  @HostBinding('style.width') get imageWidth() {
    return this.width || (this.entity === 'space' ? '164px' : '96px');
  }

  @HostBinding('style.height') get imageHeight() {
    return this.height || (this.entity === 'space' ? '124px' : '96px');
  }

  @HostBinding('class.image-contact') get isContact() {
    return this.entity === 'contact';
  }

  @HostBinding('class.image-space') get isSpace() {
    return this.entity === 'space';
  }

  @HostBinding('class.image-not-selected') get imageIsNotSelected() {
    return !this.picture;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: PplDialogService,
    private intl: PplUiIntl
  ) {
    this.browse = this.intl.browse;
    this.insertFromUrl = this.intl.insertFromUrl;
    this.remove = this.intl.remove;
    this.dropMessage = this.intl.dropMessage;
  }

  onSelectFile(event, dropEvent?: boolean) {
    if (dropEvent) {
      if (!event) {
        return;
      }
      this.image = event;
    } else {
      if (!event.target.files[0]) {
        return;
      }
      this.image = event.target.files[0];
    }

    if (!this.isFileExtensionOk()) {
      return;
    }

    if (this.fileSelector?.nativeElement) {
      this.fileSelector.nativeElement.value = '';
    }

    const dialogRef = this.dialogService.open<PplImageEditorComponent, PplImageEditorData>(PplImageEditorComponent, {
      data: {
        image: this.image,
        options: (this.entity === 'space') ? { aspectRatio: 41 / 31, minCropBoxWidth: 82 } : {}
      }
    });

    dialogRef.afterClosed().subscribe((result: HTMLCanvasElement | null) => {
      if (result) {
        this.picture = result.toDataURL('image/jpeg');
        this.pictureChange.emit(result.toDataURL());
        this.updateFormControl();
        this.fileSelector.nativeElement.value = '';
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  onInsertFromURL() {
    this.dialogService.open<ImageUrlComponent, any, Blob | null>(ImageUrlComponent).afterClosed().pipe(
      tap(url => {
        if (!!url) {
          this.pictureUrlChange.emit(url);
        }
      })
    ).subscribe();
  }

  onImageRemove() {
    this.fileSelector.nativeElement.value = '';
    this.picture = '';
    this.pictureChange.emit('');
    this.changeDetectorRef.detectChanges();
    this.updateFormControl();
  }

  updateFormControl() {
    if (!this.onChange) {
      return;
    }
    this.onChange(this.picture);
    setTimeout(() => this.changeDetectorRef.markForCheck(), 1);
  }

  writeValue(picture): void {
    this.picture = picture;
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDragOver($event) {
    this.dragOver = $event;
    this.changeDetectorRef.detectChanges();
  }

  onDrop($event) {
    this.onSelectFile($event[0], true);
  }

  isFileExtensionOk() {
    let isExtensionOk = true;
    if (this.imageTypeLimit) {
      const fileName = this.image.name;
      // check if file extension is not denied
      if (this.imageTypeLimit.denied) {
        isExtensionOk = !this.imageTypeLimit.list.find(extension => fileName.toLowerCase().includes(extension));
      } else {
        isExtensionOk = !!this.imageTypeLimit.list.find(extension => fileName.toLowerCase().includes(extension));
      }
    }
    return isExtensionOk;
  }

}

export interface PplAttachmentFileType {
  denied: boolean;
  list: string[];
}

type Option = 'browse' | 'insertFromUrl' | 'remove';
