import { PplDialogRef } from '../../dialog';
import { TFormBuilder } from '../../form';
import type { TFormGroup  } from '../../form';
import { PplUiIntl } from '../../ppl-ui-intl';
import { HttpClient } from '@angular/common/http';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { PplValidators } from '@ppl/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppl-image-url',
  templateUrl: './image-url.component.html',
  styleUrls: ['./image-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUrlComponent implements OnInit {
  form: TFormGroup<{ url: string; }>;

  loading$ = new BehaviorSubject(false);

  insertFromUrl = 'Insert From URL';

  constructor(
    private formBuilder: TFormBuilder,
    private dialogRef: PplDialogRef<ImageUrlComponent>,
    private http: HttpClient,
    private intl: PplUiIntl
  ) {
    this.insertFromUrl = this.intl.insertFromUrl;

    this.form = this.formBuilder.group<{ url: string }>({
      url: ['', [PplValidators.secureUrl, Validators.required]]
    });
  }

  ngOnInit() {
  }

  onAction() {
    const { url } = this.form.value;

    this.dialogRef.close(url);
  }
}
