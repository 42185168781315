import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PplPopoverPosition } from '../popover';

@Component({
  selector: 'ppl-popover-window',
  templateUrl: './popover-window.component.html',
  styleUrls: ['./popover-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplPopoverWindowComponent implements OnChanges, OnInit {

  @Input() connectToElement?: Element;
  @Input() position: PplPopoverPosition;

  connectToElementSize = 0;
  connectorStyle = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes.position && this.connectToElementSize) {
      const offset = this.connectToElementSize / 2 - CONNECTOR_SIZE / 2;
      const clampedOffset = offset < minOffsetPosition ? minOffsetPosition : offset;

      if (this.position.alignStart) {
        this.connectorStyle = {
          left: `${clampedOffset}px`
        };
      } else if (this.position.alignEnd) {
        this.connectorStyle = {
          right: `${clampedOffset}px`
        };
      } else {
        this.connectorStyle = {};
      }
    }
  }

  ngOnInit() {
    if (this.connectToElement) {
      this.connectToElementSize = this.connectToElement.clientWidth;
    }
  }
}

const CONNECTOR_SIZE = 10;
const minOffsetPosition = 2;
