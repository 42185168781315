import { GridFooterComponent } from './grid-footer/grid-footer.component';
import { GridHeaderFieldComponent } from './grid-header-field/grid-header-field.component';
import { GridRecordActionIconComponent } from './grid-record-action-icon/grid-record-action-icon.component';
import { GridRecordActionsButtonComponent } from './grid-record-actions-button/grid-record-actions-button.component';
import { GridRecordComponent } from './grid-record/grid-record.component';
import { GridSelectColumnsComponent } from './grid-select-columns/grid-select-columns.component';
import { GridComponent } from './grid.component';
import { PplButtonModule } from '../button';
import { PplCheckboxModule } from '../checkbox';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { PplLoadingModule } from '../loading';
import { PplMenuModule } from '../menu';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplPopoverTooltipModule } from '../popover-tooltip';
import { PplPortalModule } from '../portal';
import { PplSearchModule } from '../search';
import { PplVirtualScrollModule } from '../virtual-scroll';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplButtonModule,
    PplCheckboxModule,
    PplIconModule,
    PplInputModule,
    PplLoadingModule,
    PplMenuModule,
    PplPopoverModule,
    PplPopoverDropdownModule,
    PplPopoverTooltipModule,
    PplPortalModule,
    PplSearchModule,
    PplVirtualScrollModule
  ],
  declarations: [
    GridComponent,
    GridRecordComponent,
    GridRecordActionIconComponent,
    GridRecordActionsButtonComponent,
    GridSelectColumnsComponent,
    GridFooterComponent,
    GridHeaderFieldComponent
  ],
  exports: [
    GridComponent,
    GridRecordActionIconComponent,
    GridRecordActionsButtonComponent,
    GridSelectColumnsComponent,
    GridFooterComponent,
    GridRecordComponent,
    GridHeaderFieldComponent
  ]
})
export class PplGridModule {
}

export * from './grid.component';
export * from './grid.constants';
export * from './grid.interfaces';
export * from './grid.utils';

