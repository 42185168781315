<div class="container demo-content">
  <h2>Input Phone Number</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-input-phone-number</h4>

  <div>@Input() <b>value</b>: InputPhoneNumber;</div>
  <div>@Input() <b>dropdownContainer</b>: HTMLElement | null = document.body;</div>
  <div>@Output() <b>valueChange</b></div>
  <div>@Output() <b>countryChange</b></div>

  <br>
  <br>

  <h4>Example</h4>

  <div [formGroup]="form">
    <ppl-input-container label="Form phone number"
                         [pplFormControl]="form.get('phoneNumber')">
      <ppl-input-phone-number formControlName="phoneNumber"></ppl-input-phone-number>
    </ppl-input-container>
  </div>

  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>