import type { ID } from '@ppl/graphql-space-api';


export interface TreeNode {
  id: ID;
  children: any[];
}

export type LeveledTreeNode = TreeNode & { level: number };

export interface Entity {
  id: ID;
  parentId: ID | null;
}

export function createTrees(entities: Entity[], createNodeFn: (entity: Entity) => TreeNode) {
  const roots = [];
  const nodes = [];
  const entityIdToNodeIndex = {};

  entities.forEach((entity, index) => {
    entityIdToNodeIndex[entity.id] = index;
    nodes.push(createNodeFn(entity));
  });
  entities.forEach(entity => {
    const node = nodes[entityIdToNodeIndex[entity.id]];
    if (entity.parentId === null) {
      roots.push(node);
    } else {
      nodes[entityIdToNodeIndex[entity.parentId]].children.push(node);
    }
  });

  return roots;
}

export function flattenTree(root: TreeNode, level = 0): LeveledTreeNode[] {
  const flatten = [{ ...root, level } as LeveledTreeNode];

  if (root.children && root.children.length > 0) {
    return [
      ...flatten,
      ...root.children.map(child => flattenTree(child, level + 1)).reduce((a, b) => [...a, ...b], ([] as LeveledTreeNode[]))
    ];
  } else {
    return flatten;
  }
}


export function filterTree(node, condition: (node) => boolean) {
  let found = false;
  if (condition(node)) {
    found = true;
  }

  if (node.children.length) {
    [...node.children].forEach(childNode => {
      if (filterTree(childNode, condition)) {
        found = true;
      } else {
        node.children.splice(node.children.indexOf(childNode), 1);
      }
    });
  }

  return found;
}


export function findTreeNode(node: TreeNode, searchedNodeId: ID) {
  if (node.id === searchedNodeId) {
    return node;
  } else {
    for (const childNode of node.children) {
      const foundNode = findTreeNode(childNode, searchedNodeId);
      if (foundNode) {
        return foundNode;
      }
    }
  }
}


export function collectTreeNodeChildNodes(node, nodes: TreeNode[]) {
  for (const childNode of node.children) {
    nodes.push(childNode);
    collectTreeNodeChildNodes(childNode, nodes);
  }
}
