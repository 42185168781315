<div class="steps">
  <ng-container *ngFor="let step of steps; index as index">
    <div class="step"
         [class.active]="index === activeStepIndex">
      <div class="step-circle"
           [class.done]="index < activeStepIndex"
           [class.active]="index === activeStepIndex">
        <div class="step-info">{{ index + 1 }}</div>
        <div *ngIf="index === activeStepIndex"
             class="step-triangle">▲</div>
      </div>
      <div class="step-name">{{ step.name }}</div>
    </div>
    <svg *ngIf="index !== lastStepIndex"
         viewBox="0 0 23 20"
         class="step-arrow">
      <path d="m 13.970699,20.00095 8.999998,-9.99999 -8.999998,-10 -2.05,2.25 6.929998,7.75 -6.929998,7.74999 z" />
      <rect y="8.5009766"
            x="0.066077396"
            height="3"
            width="19" />
    </svg>
  </ng-container>
</div>