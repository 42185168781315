import { PplPosition, PplResizableSizeConstraints } from '../resizable';
import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import { LocalStorageService } from '@ppl/utils';
import type {
  Observable} from 'rxjs';
import {
  BehaviorSubject,
  combineLatest,
  concat,
  fromEvent,
  of
} from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'ppl-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDrawerComponent implements OnInit {

  @Input()
  set open(value) {
    this._open$.next(value);
  }

  get open() {
    return this._open$.getValue();
  }

  @Input()
  set disabled(value) {
    this._disabled$.next(value);
  }
  get disabled() {
    return this._disabled$.getValue();
  }

  @Input() detail: TemplateRef<any>;
  @Input() detailSizeLocalStorageKey: string;
  @Input() position: PplPosition;
  @Input() resizable: boolean;
  @Input() detailSize: number;
  @Input() sizeConstraints: PplResizableSizeConstraints;
  @Input() minWindowWidth: number;
  @Input() detailVisible = true;
  @Input() showControlHorizontal = false;
  @Input() showControlVertical = true;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() sizeChange = new EventEmitter<number>();

  windowWidth$: Observable<number>;
  showDetail$: Observable<boolean>;

  private _open$ = new BehaviorSubject<boolean>(true);
  private _disabled$ = new BehaviorSubject<boolean>(false);

  get vertical() {
    return this.position === 'top' || this.position === 'bottom';
  }

  get horizontal() {
    return this.position === 'right' || this.position === 'left';
  }

  get sidebarPosition() {
    if (this.position === 'right') {
      return 'end';
    }
    if (this.position === 'left') {
      return 'start';
    }
  }

  constructor(private localStorageService: LocalStorageService, private changeDetectorRef: ChangeDetectorRef) { }


  ngOnInit() {
    this.detailSize = this.detailSizeLocalStorageKey ? this.localStorageService.getItem(this.detailSizeLocalStorageKey, this.detailSize) : this.detailSize;
    this.windowWidth$ = concat(of(window.innerWidth), fromEvent(window, 'resize')).pipe(
      map(() => window.innerWidth)
    );
    this.showDetail$ = combineLatest([this.windowWidth$, this._open$, this._disabled$]).pipe(
      map(([windowWidth, open, disabled]) => {
        if (this.minWindowWidth && windowWidth > this.minWindowWidth) {
          return false;
        }

        return !!(open && !disabled);
      }),
      tap(() => {
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  onOpenChange() {
    this.openChange.emit(!this.open);
  }

  onDetailSizeChange($event: number) {
    this.detailSize = $event;
    if (this.detailSizeLocalStorageKey) {
      this.localStorageService.setItem(this.detailSizeLocalStorageKey, $event);
    }
    this.sizeChange.emit($event);
  }
}

