<div class="container demo-content">
  <h2>Slide Toggle</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-slide-toggle</h4>
  <div>@Input() <b>label</b>: string</div>
  <div>@Input() <b>disabled</b>: boolean</div>
  <div>@Input() <b>labelPosition</b>: 'before' | 'after' = 'after'</div>
  <div>@Input() <b>value</b>: boolean</div>
  <div>@Input() <b>toggleImmediatelly</b> = true // indicates, whether visual feedback is immediate or the toggled attribute is changed explicitely later on</div>
  <br>
  <div>@Output() <b>valueChange</b>: boolean;</div>
  <br>
  <br>

  <h3>Example</h3>

  <ppl-slide-toggle label="Label"
                    [value]="true"
                    [disabled]="false"
                    (valueChange)="toggled = $event"></ppl-slide-toggle>

  <br>
  <h4>Code</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
