import { getTimezoneOffsetInUserTimezoneExtractor, getUserSettingsLocale } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { DatePipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { I18nService } from '@ppl/i18n';
import moment from 'moment-timezone';
import type { PipeTransform } from '@angular/core';
import type { Subscription } from 'rxjs';


@Pipe({
  name: 'pplDate'
})
export class PplDatePipe implements PipeTransform {
  locale: string;
  localeSubscription: Subscription;
  getTimezoneOffsetInUserTimezone = null;
  getTimezoneOffsetInUserTimezoneSubscription: Subscription;

  private transformCache: { [id: string]: string } = {};

  constructor(
    private datePipe: DatePipe,
    private i18nService: I18nService,
    private store: AuthStore
  ) {
    this.localeSubscription = this.store.select(getUserSettingsLocale(true)).subscribe(locale => this.locale = locale);
    this.getTimezoneOffsetInUserTimezoneSubscription = this.store.select(getTimezoneOffsetInUserTimezoneExtractor).subscribe(
      extractor => {
        this.getTimezoneOffsetInUserTimezone = extractor;
      }
    );
  }

  transform(isoDateWithoutTime: string, customFormat?: 'month' | 'month-long' | 'month-short' | 'quarter' | 'quarter-fy' | 'week' | 'week-day' | 'year' | 'year-fy' | 'day-of-month' | 'mediumDate' | 'day-month-medium' | string) {
    if (!isoDateWithoutTime) {
      return '';
    }

    if (this.transformCache[isoDateWithoutTime] && !customFormat) {
      return this.transformCache[isoDateWithoutTime];
    }

    const inputMoment = moment(isoDateWithoutTime);
    const inputTime = inputMoment.valueOf();
    const timezone = this.getTimezoneOffsetInUserTimezone(inputMoment.toDate());

    switch (customFormat) {
      case 'month-short':
        return this.datePipe.transform(inputTime, 'LLL', timezone, this.locale);
      case 'month':
        return this.datePipe.transform(inputTime, 'LLL yyyy', timezone, this.locale);
      case 'month-long':
        return this.datePipe.transform(inputTime, 'LLLL', timezone, this.locale);
      case 'quarter':
        // TODO: How this should be localized?
        return `Q${Math.floor(inputMoment.month() / 3) + 1} ${this.datePipe.transform(inputTime, 'yyyy', timezone, this.locale)}`;
      case 'quarter-fy':
        // TODO: How this should be localized?
        return `Q${Math.floor(inputMoment.month() / 3) + 1} FY ${this.datePipe.transform(inputTime, 'yyyy', timezone, this.locale)}`;
      case 'week':
        // TODO: How this should be localized?
        return this.i18nService.translate('Week') + ` ${this.datePipe.transform(inputTime, 'w', timezone, this.locale)}`;
      case 'week-day':
        return this.datePipe.transform(inputTime, 'EEEE', timezone, this.locale);
      case 'week-day-short':
        return this.datePipe.transform(inputTime, 'EEE', timezone, this.locale);
      case 'year':
        return this.datePipe.transform(inputTime, 'yyyy', timezone, this.locale);
      case 'year-fy':
        // TODO: How this should be localized?
        return `FY ${this.datePipe.transform(inputTime, 'yyyy', timezone, this.locale)}`;
      case 'day-of-month':
        return this.datePipe.transform(inputTime, 'd', timezone, this.locale);
      case 'day-month-medium':
        return this.datePipe.transform(inputTime, 'MMM d', timezone, this.locale);
      default:
        const result = this.datePipe.transform(inputTime, customFormat || 'mediumDate', timezone, this.locale);

        this.transformCache[isoDateWithoutTime] = result;

        return result;
    }
  }
}
