import type { Renderer2} from '@angular/core';
import { RendererStyleFlags2 } from '@angular/core';
export function hexToRGBDecimal(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
}

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

/**
 * apply css variables for custom field color
 * @param value HEX color code
 */
export function applyFieldColorVariables(element: HTMLElement, renderer: Renderer2, value: string | null) {
  renderer.setStyle(element, '--ppl-field-color', value || null, RendererStyleFlags2.DashCase);
  renderer.setStyle(element, '--ppl-field-color-0-1', value ? hexToRGB(value, 0.1) : null, RendererStyleFlags2.DashCase);
}

export function colorCodeToHex(colorCode: number) {
  return `#${colorCode.toString(16).padStart(6, '0')}`;
}

export function colorCodeToRgba(colorCode: number, opacity: number) {
  // eslint:disable: no-bitwise
  const r = (colorCode >> 16) & 255;
  const g = (colorCode >> 8) & 255;
  const b = colorCode & 255;

  return `rgba(${[r, g, b].join(',')}, ${opacity})`;
}
