import { MIN_COLUMN_WIDTH } from '../grid.constants';
import type {
  GridAction,
  GridActionClickEvent,
  GridAvailableColumn,
  GridColumn,
  GridRecord,
  GridInternalColumnData
} from '../grid.interfaces';
import {
  TemplateRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { colorCodeToHex, MemoizeLast, trackById } from '@ppl/utils';

@Component({
  selector: 'ppl-grid-record',
  templateUrl: './grid-record.component.html',
  styleUrls: ['./grid-record.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridRecordComponent {

  @Input() columnsWithData: (GridColumn & GridAvailableColumn & GridInternalColumnData)[];
  @Input() record: GridRecord & { _meta: any };

  @Input() @HostBinding('class.hover-disabled') hoverDisabled = false;
  @Input() @HostBinding('class.selected') selected = false;
  @Input() @HostBinding('class.alternative') alternative = false;
  @Input() @HostBinding('class.invalid') invalid = false;

  @Input() displayCheckbox = true;
  @Input() displayCheckboxField = false;
  @Input() displayDragField = false;
  @Input() displayLastField = false;
  @Input() lastFieldHasActions = false;

  @Input() startDividerVisible = false;
  @Input() lastDividerVisible = false;

  @Input() checkboxTemplate?: TemplateRef<any>;

  @Input() dragFieldIcon?: string;

  @Input() actionsTemplate: TemplateRef<any>;
  @Input() actions: GridAction[] = [];
  @Input() singleAction = true;

  @Input() dragColumnId: string | null = null;

  @Input() columnToolsTemplate: TemplateRef<any>;

  @Input() checkboxFieldWidth = 0;
  @Input() dragFieldWidth = 0;
  @Input() lastColumnWidth = 0;

  @Input() horizontalScroll = false;

  @Output() actionClick = new EventEmitter<GridActionClickEvent>();
  @Output() dragStart = new EventEmitter<MouseEvent>();
  @Output() recordCheck = new EventEmitter();

  MIN_COLUMN_WIDTH = MIN_COLUMN_WIDTH;

  trackById = trackById;
  colorCodeToHex = colorCodeToHex;

  @MemoizeLast<GridRecordComponent>(['record', 'actions'])
  get enabledActions() {
    return this.actions.filter(action => (typeof action.enabled === 'function') ? action.enabled(this.record) : action.enabled);
  }

}
