import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-top-bar-demo',
  templateUrl: './top-bar-demo.component.html',
  styleUrls: ['./top-bar-demo.component.scss']
})
export class TopBarDemoComponent implements OnInit {
  code = `
    <ppl-top-bar title="Title"></ppl-top-bar>
  `;

  constructor() { }

  ngOnInit() {
  }

}
