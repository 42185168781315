import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AccordionDemoComponent } from './components/accordion-demo/accordion-demo.component';
import { AccountClassDemoComponent } from './components/account-class-demo/account-class-demo.component';
import { AddressbookPictureDemoComponent } from './components/addressbook-picture-demo/addressbook-picture-demo.component';
import { AppContainerDemoComponent } from './components/app-container-demo/app-container-demo.component';
import { AttachmentsDemoComponent } from './components/attachments-demo/attachments-demo.component';
import { AutocompleteDemoComponent } from './components/autocomplete/autocomplete-demo.component';
import { BottomBarDemoComponent } from './components/bottom-bar/bottom-bar-demo.component';
import { ButtonsDemoComponent } from './components/buttons-demo/buttons-demo.component';
import { CarouselDemoComponent } from './components/carousel-demo/carousel-demo.component';
import { CheckboxDemoComponent } from './components/checkbox/checkbox-demo.component';
import { ContainerDemoComponent } from './components/container-demo/container-demo.component';
import { DatepickerDemoComponent } from './components/datepicker/datepicker-demo.component';
import { DemoOpenDialogComponent } from './components/dialog-demo/demo-open-dialog/demo-open-dialog.component';
import { DialogDemoComponent } from './components/dialog-demo/dialog-demo.component';
import { DrawerDemoComponent } from './components/drawer-demo/drawer-demo.component';
import { EditableDemoComponent } from './components/editable-demo/editable-demo.component';
import { FormBuilderDemoComponent } from './components/form-builder-demo/form-builder-demo.component';
import { FormTableDemoComponent } from './components/form-table-demo/form-table-demo.component';
import { GraphItemDemoComponent } from './components/graph-item-demo/graph-item-demo.component';
import { GraphToggleDemoComponent } from './components/graph-toggle-demo/graph-toggle-demo.component';
import { HomeComponent } from './components/home/home.component';
import { IconsDemoComponent } from './components/icons-demo/icons-demo.component';
import { InfiniteScrollDemoComponent } from './components/infinite-scroll-demo/infinite-scroll-demo.component';
import { InfoBoxDemoComponent } from './components/info-box-demo/info-box-demo.component';
import { InputNumberDemoComponent } from './components/input-number-demo/input-number-demo.component';
import { InputPhoneNumberDemoComponent } from './components/input-phone-number-demo/input-phone-number-demo.component';
import { InputDemoComponent } from './components/input/input-demo.component';
import { ItemsChooserDemoComponent } from './components/items-chooser-demo/items-chooser-demo.component';
import { LinkDemoComponent } from './components/link-demo/link-demo.component';
import { LoadingOverlayDemoComponent } from './components/loading-overlay-demo/loading-overlay-demo.component';
import { MenuDemoComponent } from './components/menu-demo/menu-demo.component';
import { MultipleAutocompleteDemoComponent } from './components/multiple-autocomplete-demo/multiple-autocomplete-demo.component';
import { MultipleSelectDemoComponent } from './components/multiple-select-demo/multiple-select-demo.component';
import { NavBarDemoComponent } from './components/nav-bar-demo/nav-bar-demo.component';
import { PartialSelectDemoComponent } from './components/partial-select-demo/partial-select-demo.component';
import { PopoverDemoComponent } from './components/popover-demo/popover-demo.component';
import { PrismComponent } from './components/prism/prism.component';
import { RadioButtonDemoComponent } from './components/radio-button/radio-button-demo.component';
import { RankingDemoComponent } from './components/ranking-demo/ranking-demo.component';
import { RankingPercentageDemoComponent } from './components/ranking-percentage-demo/ranking-percentage-demo.component';
import { ResizableDemoComponent } from './components/resizable-demo/resizable-demo.component';
import { SelectDemoComponent } from './components/select/select-demo.component';
import { SelectorViewDemoComponent } from './components/selector-view-demo/selector-view-demo.component';
import { SlideToggleDemoComponent } from './components/slide-toggle-demo/slide-toggle-demo.component';
import { StepsDemoComponent } from './components/steps-demo/steps-demo.component';
import { TabsDemoComponent } from './components/tabs-demo/tabs-demo.component';
import { TextDropdownDemoComponent } from './components/text-dropdown-demo/text-dropdown-demo.component';
import { ToastContentDemoComponent } from './components/toast-demo/toast-content-demo/toast-content-demo.component';
import { ToastDemoComponent } from './components/toast-demo/toast-demo.component';
import { ToggleGroupDemoComponent } from './components/toggle-group-demo/toggle-group-demo.component';
import { ToggleListDemoComponent } from './components/toggle-list-demo/toggle-list-demo.component';
import { TopBarDemoComponent } from './components/top-bar-demo/top-bar-demo.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { TreeSelectDemoComponent } from './components/tree-select-demo/tree-select-demo.component';
import { environment } from '../environments/environment';
import { APP_BASE_HREF, CommonModule, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PplComponentsGraphModule } from '@ppl/components/graph';
import { PplComponentsStepsModule } from '@ppl/components/steps';
import { PplComponentsInputPhoneNumberModule } from '@ppl/components/input-phone-number';
import { I18nService } from '@ppl/i18n';
import { PplAccordionModule } from '@ppl/ui/accordion';
import { PplAccountClassModule } from '@ppl/ui/account-class';
import { PplAddressbookModule } from '@ppl/ui/addressbook-picture';
import { PplAppContainerModule } from '@ppl/ui/app-container';
import { PplAttachmentsModule } from '@ppl/ui/attachments';
import { PplAutocompleteModule } from '@ppl/ui/autocomplete';
import { PplAutocompletePickerModule } from '@ppl/ui/autocomplete-picker';
import { PplAutocompleteSelectModule } from '@ppl/ui/autocomplete-select';
import { PplAvatarModule } from '@ppl/ui/avatar';
import { PplBottomBarModule } from '@ppl/ui/bottom-bar';
import { PplButtonModule } from '@ppl/ui/button';
import { PplCarouselModule } from '@ppl/ui/carousel';
import { PplCheckboxModule } from '@ppl/ui/checkbox';
import { PplContainerModule } from '@ppl/ui/container';
import { PplDatePickerModule } from '@ppl/ui/date-picker';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplDrawerModule } from '@ppl/ui/drawer';
import { PplEditableModule } from '@ppl/ui/editable';
import { PplFormModule } from '@ppl/ui/form';
import { PplFormTableModule } from '@ppl/ui/form-table';
import { PplIconModule, PplIconService } from '@ppl/ui/icon';
import { PplInfiniteScrollModule } from '@ppl/ui/infinite-scroll';
import { PplInfoBoxModule } from '@ppl/ui/info-box';
import { PplInputModule } from '@ppl/ui/input';
import { PplItemsChooserModule } from '@ppl/ui/items-chooser';
import { PplLinkModule } from '@ppl/ui/link';
import { PplLoadingModule } from '@ppl/ui/loading';
import { PplMenuModule } from '@ppl/ui/menu';
import { PplMultipleAutocompleteModule } from '@ppl/ui/multiple-autocomplete';
import { PplMultipleSelectModule } from '@ppl/ui/multiple-select';
import { PplNavBarModule } from '@ppl/ui/nav-bar';
import { PplOptionListModule } from '@ppl/ui/option-list';
import { PplPartialSelectModule } from '@ppl/ui/partial-select';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverTooltipModule } from '@ppl/ui/popover-tooltip';
import { PplPopoverWindowModule } from '@ppl/ui/popover-window';
import { PplRadioModule } from '@ppl/ui/radio';
import { PplRankingModule } from '@ppl/ui/ranking';
import { PplResizableModule } from '@ppl/ui/resizable';
import { PplSelectModule } from '@ppl/ui/select';
import { PplSelectorViewModule } from '@ppl/ui/selector-view';
import { PplSlideToggleModule } from '@ppl/ui/slide-toggle';
import { PplTabsModule } from '@ppl/ui/tabs';
import { PplTextDropdownModule } from '@ppl/ui/text-dropdown';
import { PplToastModule } from '@ppl/ui/toast';
import { PplToggleGroupModule } from '@ppl/ui/toggle-group';
import { PplToggleListModule } from '@ppl/ui/toggle-list';
import { PIPELINER_NG_UI_THEME } from '@ppl/ui/tokens';
import { PplUiToolbarModule } from '@ppl/ui/toolbar';
import { PplTopBarModule } from '@ppl/ui/top-bar';
import { PplTreeSelectModule } from '@ppl/ui/tree-select';
import { DEPLOY_URL } from '@ppl/utils';
import { CallingSharedModule } from '@ppl/calling-shared';
import { CallingConversationItemDemoComponent } from './components/calling-conversation-item-demo/calling-conversation-item-demo.component';
import { AdvancedMultiselectCheckboxDemoComponent } from './components/advanced-multiselect-checkbox-demo/advanced-multiselect-checkbox-demo.component';
import { PplMultiCheckboxAdvancedModule } from '../../../../libs/pipeliner-ng-ui/src/lib/multi-checkbox-advanced/index';

const pplUiModules = [
  PplInputModule,
  PplButtonModule,
  PplAutocompleteModule,
  PplCheckboxModule,
  PplRadioModule,
  PplSelectModule,
  PplMultipleSelectModule,
  PplAccountClassModule,
  PplMultipleAutocompleteModule,
  PplRankingModule,
  PplAppContainerModule,
  PplTopBarModule,
  PplUiToolbarModule,
  PplBottomBarModule,
  PplEditableModule,
  PplDialogModule,
  PplPopoverModule,
  PplPopoverWindowModule,
  PplIconModule,
  PplMenuModule,
  PplAddressbookModule,
  PplFormModule,
  PplContainerModule,
  PplTabsModule,
  PplSlideToggleModule,
  PplAccordionModule,
  PplCarouselModule,
  PplAttachmentsModule,
  PplToggleGroupModule,
  PplInfiniteScrollModule,
  PplResizableModule,
  PplLoadingModule,
  PplToastModule,
  PplDatePickerModule,
  PplInfoBoxModule,
  PplPartialSelectModule,
  PplAutocompletePickerModule,
  PplAutocompleteSelectModule,
  PplNavBarModule,
  PplPopoverTooltipModule,
  PplTextDropdownModule,
  PplItemsChooserModule,
  PplDrawerModule,
  PplFormTableModule,
  PplToggleListModule,
  PplLinkModule,
  PplAvatarModule,
  PplTreeSelectModule,
  PplSelectorViewModule,
  PplOptionListModule,
  PplMultiCheckboxAdvancedModule
];

const pplComponentsModules = [
  PplComponentsStepsModule,
  PplComponentsInputPhoneNumberModule,
  PplComponentsGraphModule
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TopNavigationComponent,
    AutocompleteDemoComponent,
    HomeComponent,
    CheckboxDemoComponent,
    DatepickerDemoComponent,
    InputDemoComponent,
    RadioButtonDemoComponent,
    SelectDemoComponent,
    MultipleSelectDemoComponent,
    SlideToggleDemoComponent,
    BottomBarDemoComponent,
    AccountClassDemoComponent,
    ButtonsDemoComponent,
    MultipleAutocompleteDemoComponent,
    RankingDemoComponent,
    TopBarDemoComponent,
    PrismComponent,
    AppContainerDemoComponent,
    EditableDemoComponent,
    DialogDemoComponent,
    DemoOpenDialogComponent,
    PopoverDemoComponent,
    IconsDemoComponent,
    MenuDemoComponent,
    AddressbookPictureDemoComponent,
    ContainerDemoComponent,
    TabsDemoComponent,
    AccordionDemoComponent,
    CarouselDemoComponent,
    InputNumberDemoComponent,
    AttachmentsDemoComponent,
    ToggleGroupDemoComponent,
    FormBuilderDemoComponent,
    RankingPercentageDemoComponent,
    InfiniteScrollDemoComponent,
    LoadingOverlayDemoComponent,
    ToastDemoComponent,
    ToastContentDemoComponent,
    ResizableDemoComponent,
    InfoBoxDemoComponent,
    PartialSelectDemoComponent,
    NavBarDemoComponent,
    TextDropdownDemoComponent,
    ItemsChooserDemoComponent,
    DrawerDemoComponent,
    FormTableDemoComponent,
    ToggleListDemoComponent,
    StepsDemoComponent,
    InputPhoneNumberDemoComponent,
    LinkDemoComponent,
    GraphToggleDemoComponent,
    GraphItemDemoComponent,
    TreeSelectDemoComponent,
    SelectorViewDemoComponent,
    CallingConversationItemDemoComponent,
    AdvancedMultiselectCheckboxDemoComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    CallingSharedModule,
    ...pplUiModules,
    ...pplComponentsModules
  ],
  providers: [
    PplIconService,
    I18nService,
    I18nPluralPipe,
    { provide: APP_BASE_HREF, useValue: environment.baseUrl },
    { provide: PIPELINER_NG_UI_THEME, useValue: 'pipeliner' },
    { provide: DEPLOY_URL, useValue: environment.deployUrl },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
