import { PplIconModule } from '../icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplOptionListWithFilterComponent } from './option-list-with-filter.component';
import { PplOptionListModule } from '../option-list';
import { PplSearchModule } from '../search';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplOptionListModule,
    PplSearchModule
  ],
  declarations: [
    PplOptionListWithFilterComponent
  ],
  exports: [
    PplOptionListWithFilterComponent
  ]
})
export class PplOptionListWithFilterModule {
}

export * from './option-list-with-filter.component';
