<div #dropdown
     class="dropdown"
     [class.disabled]="disabled"
     [class.focused]="focused"
     [class.value-loading]="displayValueLoading"
     [pplPopover]="treePopover"
     [pplPopoverAlignEnd]="dropdownAlignEnd"
     [pplPopoverAlignStart]="dropdownAlignStart"
     [pplPopoverEvent]="null"
     (keydown)="onContainerKeyDown($event)"
     (mousedown)="onInputMouseDown()"
     (click)="onContainerClick()">
  <ng-container *ngIf="!displayValueLoading; loading">
    <div #selectedValues
         [attr.tabindex]="disabled ? -1 : 0"
         (focus)="onFocus()"
         (blur)="onBlur()"
         title="{{ selectionMode === 'multiple' ? selectedLabels : selectedOption?.label }}"
         class="dropdown-selected"
         [class.disabled]="disabled">
      <ng-container *ngIf="selectionMode === 'multiple'">
        <span *ngIf="!value.all"
              class="dropdown-selected-values"
              [class.placeholder]="!selectedOptions.length && !treePopover.isOpen">
          {{ selectedLabels }}
        </span>
        <span *ngIf="value.all"
              class="dropdown-selected-values"
              [class.placeholder]="!selectedOptions.length && !treePopover.isOpen"
              i18n="All">
          All
        </span>
      </ng-container>
      <ng-container *ngIf="selectionMode === 'single'">
        <span class="dropdown-selected-values"
              [class.placeholder]="!selectedOption">
          {{ selectedOption?.label }}
        </span>
      </ng-container>
    </div>

    <div class="dropdown-button"
         [class.disabled]="disabled">
      <ppl-icon [svgIcon]="disabled ? 'rowline-form_disabled' : 'rowline-form'"
                width="7px"
                height="7px"></ppl-icon>
    </div>

  </ng-container>
  <ppl-loading *ngIf="displayValueLoading"></ppl-loading>
</div>

<ng-template #treePopover>
  <ppl-popover-dropdown [style.min-width.px]="dropdownMinWidth"
                        [style.max-width.px]="dropdownMaxWidth"
                        (click)="$event.stopPropagation()">
    <div #treeContainer
         class="dropdown-tree"
         (keydown)="onTreeContainerKeydown($event)">
      <ppl-search #search
                  placeholder="Start typing..."
                  i18n-placeholder="@@Start_typing..."
                  [focusOnMount]="true"
                  [value]="filter"
                  (click)="onSearchClick($event)"
                  (valueChange)="onSearchValueChange($event)">
      </ppl-search>
      <ng-container *ngIf="selectionMode === 'multiple'">
        <!-- SUBCATEGORIES -->
        <div class="dropdown-tree-subcategories">
          <div class="dropdown-tree-subcategories-label">
            <ng-container *ngIf="includeSubcategoriesLabel; else defaultIncludeSubcategoriesLabel">
              {{ includeSubcategoriesLabel }}
            </ng-container>
          </div>

          <div class="dropdown-tree-subcategories-divider">
          </div>

          <ppl-slide-toggle [value]="includeSubcategories"
                            (valueChange)="includeSubcategories = !includeSubcategories"></ppl-slide-toggle>
        </div>
        <!-- ALL -->
        <ppl-checkbox *ngIf="displayAll"
                      i18n="@@All"
                      [value]="value.all"
                      (valueChange)="onSelectAll($event.checked)">All</ppl-checkbox>

        <!-- TREE -->
        <div class="dropdown-tree-options dropdown-tree-options-small">
          <ppl-form-table-tree *ngFor="let option of availableOptions; trackBy: trackTree"
                               [item]="option"
                               [forceOpened]="true"
                               [highlightCategories]="true"
                               (toggle)="onTreeToggle($event)"
                               (click)="$event.stopPropagation()">
          </ppl-form-table-tree>
        </div>
      </ng-container>

      <ng-container *ngIf="selectionMode === 'single'">
        <div class="dropdown-tree-options">
          <ppl-tree-select-options-list *ngIf="availableOptions.length; else emptyMessage"
                                        [value]="value"
                                        [collapsible]="collapsible"
                                        [searchValue]="filter"
                                        [items]="availableOptions"
                                        [openedByDefault]="true"
                                        [highlightCategories]="true"
                                        [hideUnavailableLabels]="hideUnavailableLabels"
                                        (toggle)="onTreeValueChange($event)"
                                        (click)="$event.stopPropagation()">
          </ppl-tree-select-options-list>

          <ng-template #emptyMessage>
            <div class="no-results"
                 i18n="@@No_Results_Found">
              No Results Found
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ppl-popover-dropdown>
</ng-template>

<ng-template #defaultIncludeSubcategoriesLabel
             i18n="@@Selection_Include_Sub-Categories">
  Selection Include Sub-Categories
</ng-template>
