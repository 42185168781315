import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplCheckboxModule } from '../checkbox';
import { PplIconModule } from '../icon';
import { PplSearchModule } from '../search';
import { PplSlideToggleModule } from '../slide-toggle';
import { PplFormTableTreeComponent } from './form-table-tree/form-table-tree.component';
import { PplFormTableComponent } from './form-table.component';

@NgModule({
  imports: [
    CommonModule,
    PplCheckboxModule,
    PplSlideToggleModule,
    PplIconModule,
    PplSearchModule
  ],
  declarations: [
    PplFormTableComponent,
    PplFormTableTreeComponent
  ],
  exports: [
    PplFormTableComponent,
    PplFormTableTreeComponent
  ]
})
export class PplFormTableModule {
}

export * from './form-table-item';
export * from './form-table.component';

