<div class="container"
     [class.disabled]="disabled"
     [class.focused]="focused"
     [class.readonly]="readonly"
     [class.value-loading]="displayValueLoading"
     [pplPopover]="optionsPopover"
     [pplPopoverAlignStart]="popoverAlignStart"
     [pplPopoverAlignEnd]="popoverAlignEnd"
     [pplPopoverDirection]="popoverDirection"
     [pplPopoverEvent]="null"
     (mousedown)="onContainerMouseDown($event)">
  <ng-container *ngIf="!displayValueLoading">
    <input class="value-input"
           #valueInput
           [attr.autocomplete]="'off'"
           [attr.autocorrect]="'off'"
           [attr.autocapitalize]="'off'"
           [attr.spellcheck]="'false'"
           [attr.tabindex]="disabled ? -1 : 0"
           [disabled]="disabled"
           [placeholder]="readonly ? '' : placeholder"
           [readonly]="readonly"
           [value]="value"
           (focus)="onValueInputFocus()"
           (blur)="onValueInputBlur()"
           (input)="onValueInputInput($event)"
           (keydown)="onValueInputKeyDown($event)" />

    <ppl-icon *ngIf="!forceDisplayClearValue && !value && icon === 'search'"
              class="search-icon"
              svgIcon="search"
              width="14px"
              height="14px"></ppl-icon>

    <ng-container *ngIf="forceDisplayClearValue && value && icon === 'dropdown' && !disabled && !readonly">
      <ppl-icon *ngIf="ngUiTheme === 'pipeliner'"
                class="clear-icon"
                svgIcon="closering-red2"
                width="14px"
                height="14px"
                (mousedown)="$event.stopPropagation()"
                (click)="onValueClear();"></ppl-icon>

      <ppl-icon *ngIf="ngUiTheme === 'fabric'"
                class="clear-icon"
                svgIcon="close-gray-sm"
                width="12px"
                height="12px"
                (mousedown)="$event.stopPropagation()"
                (click)="onValueClear()"></ppl-icon>
    </ng-container>

    <div *ngIf="icon === 'dropdown' && !readonly"
         class="dropdown-icon"
         (mousedown)="onDropdownIconMouseDown($event)">
      <ppl-icon svgIcon="rowline-form"
                width="7px"
                height="7px"></ppl-icon>
    </div>

    <ppl-icon *ngIf="(forceDisplayClearValue || value) && icon === 'search' && !disabled"
              class="clear-icon"
              svgIcon="closering-red2"
              width="14px"
              height="14px"
              (mousedown)="$event.stopPropagation()"
              (click)="onValueClear(); valueInput.focus()"></ppl-icon>
  </ng-container>

  <ppl-loading *ngIf="displayValueLoading"></ppl-loading>
</div>

<ng-template #optionsPopover>
  <ppl-option-list [options]="options"
                   [value]="listSelectedIndex !== -1 ? options[listSelectedIndex].value : null"
                   [createValue]="value"
                   [categories]="categories"
                   [displayCategoriesSidebar]="displayCategoriesSidebar"
                   [optionTemplate]="optionTemplate"
                   [optionTemplateRowHeight]="optionTemplateRowHeight"
                   [displayCreateOption]="displayCreateOption"
                   [displayLoading]="displayOptionsLoading"
                   [displayNoResults]="!displayOptionsLoading && options.length === 0"
                   [maxContainerHeight]="maxContainerHeight"
                   [style.width.px]="popoverWidth"
                   [style.min-width.px]="minPopoverWidth"
                   [categoriesSidebarWidth]="categoriesSidebarWidth"
                   (optionCreate)="onOptionCreate()"
                   (optionSelect)="onOptionSelect($event)"
                   (valueChange)="listSelectedIndex = getOptionIndexByValue($event)"
                   (listScrollEnd)="onListScrollEnd()"></ppl-option-list>
</ng-template>
