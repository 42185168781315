import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { PplOptionListModule } from '../option-list';
import { PplPopoverModule } from '../popover';
import { PplMultipleAutocompleteComponent } from './multiple-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplLoadingModule,
    PplPopoverModule,
    PplOptionListModule
  ],
  declarations: [
    PplMultipleAutocompleteComponent
  ],
  exports: [
    PplMultipleAutocompleteComponent
  ]
})
export class PplMultipleAutocompleteModule {
}

export * from './multiple-autocomplete.component';

