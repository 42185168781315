import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplAvatarComponent } from './avatar.component';
import { PplIconModule } from '../icon';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    PplAvatarComponent
  ],
  exports: [
    PplAvatarComponent
  ]
})
export class PplAvatarModule {
}

export * from './avatar.component';
