<div class="container demo-content">
  <h2>Multiple Select</h2>


  <ppl-input-container label="Basic multiple select">
    <ppl-multiple-select [options]="options"
                         [value]="values"
                         (valueChange)="onBasicValueChange($event)"></ppl-multiple-select>
  </ppl-input-container>

  <ppl-input-container label="Display loading">
    <ppl-multiple-select [options]="options"
                         [displayValueLoading]="true"
                         (valueChange)="onValueChange($event)"></ppl-multiple-select>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-multiple-select [options]="options"
                         [disabled]="true"
                         (valueChange)="onValueChange($event)"></ppl-multiple-select>
  </ppl-input-container>

  <ppl-input-container label="Placeholder">
    <ppl-multiple-select [options]="options"
                         placeholder="Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder "
                         (valueChange)="onValueChange($event)"></ppl-multiple-select>
  </ppl-input-container>

  <ppl-input-container label="Selected values">
    <ppl-multiple-select [options]="options"
                         [value]="selectedValues"
                         placeholder="Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder "
                         (valueChange)="onValueChange($event)"></ppl-multiple-select>
  </ppl-input-container>

  <ppl-input-container label="Selected Options custom template">
    <ppl-multiple-select [options]="options"
                         [value]="selectedValues"
                         [optionTemplate]="customTemplate"
                         [selectedOptionTemplate]="customTemplate"
                         placeholder="Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder Some placeholder "
                         (valueChange)="onValueChange($event)"></ppl-multiple-select>
  </ppl-input-container>
</div>

<ng-template #customTemplate
             let-option>
  <div class="custom-template">
    <div class="color"
         [style.backgroundColor]="option.data.color"></div>
    {{ option.label }}

  </div>
</ng-template>