<div #container
     class="container"
     [class.disabled]="disabled"
     [class.focused]="focused"
     [class.display-all]="displayAll"
     [class.value-loading]="displayValueLoading"
     [class.scrollable]="scrollable"
     [class.has-item-count]="hasItemCount"
     [pplPopover]="optionsPopover"
     pplPopoverAlignStart
     pplPopoverAlignEnd
     [pplPopoverEvent]="null"
     (pplPopoverToggle)="onOptionsPopoverToggle($event.visible)"
     (mousedown)="onContainerMouseDown($event)">
  <ng-container *ngIf="!displayValueLoading">
    <ng-container *ngFor="let option of selectedOptions; trackBy: trackByOptionValue">
      <div [class]="'item ' + (selectedOptionClass ? selectedOptionClass(option.value) : '')"
           [class.deleted]="option.deleted"
           [style.background-color]="selectedOptionColor ? selectedOptionColor(option.value) : option.color"
           (mousedown)="onItemClick(option.value, $event)">
        <ng-container *ngTemplateOutlet="selectedOptionTemplate || defaultSelectedOptionTemplate; context: { $implicit: option }"></ng-container>
        <div *ngIf="!disabled"
             class="item-remove"
             (mousedown)="onItemRemoveClick(option.value, $event)">
          <ppl-icon svgIcon="closebold-darkblue"
                    width="8px"
                    height="8px"></ppl-icon>
        </div>
      </div>
    </ng-container>

    <div *ngIf="hasItemCount"
         class="item-count"
         [style.left.px]="invisibleItemCountOffset">
      +{{invisibleItemCount}}
    </div>

    <input class="filter-input"
           #filterInput
           [attr.autocomplete]="'off'"
           [attr.autocorrect]="'off'"
           [attr.autocapitalize]="'off'"
           [attr.spellcheck]="'false'"
           [attr.tabindex]="disabled ? -1 : 0"
           [attr.disabled]="disabled ? true : null"
           [style.min-width.px]="filterInputSize"
           [value]="filterInputText"
           (focus)="onFilterInputFocus()"
           (blur)="onFilterInputBlur()"
           (input)="onFilterInputInput($event)"
           (keydown)="onFilterInputKeyDown($event)" />

    <div *ngIf="!focused && !disabled && placeholder && value.length === 0"
         class="placeholder">
      {{placeholder}}
    </div>
  </ng-container>

  <ppl-loading *ngIf="displayValueLoading"></ppl-loading>
</div>

<ng-template #defaultSelectedOptionTemplate
             let-option>
  <ppl-icon *ngIf="option.icon"
            class="item-icon"
            [svgIcon]="option.icon"
            width="14px"
            height="14px"></ppl-icon>
  <div class="item-label">
    {{option.label}}
  </div>
</ng-template>

<ng-template #optionsPopover>
  <ppl-option-list [options]="availableOptions"
                   [value]="listSelectedIndex !== -1 ? availableOptions[listSelectedIndex].value : null"
                   [createValue]="filterInputText"
                   [categories]="categories"
                   [optionTemplate]="optionTemplate"
                   [optionTemplateRowHeight]="optionTemplateRowHeight"
                   [displayLoading]="displayOptionsLoading"
                   [displayNoResults]="availableOptions.length === 0"
                   [displayCreateOption]="displayCreateOption"
                   [maxContainerHeight]="maxOptionListContainerHeight"
                   [displayCategoriesSidebar]="displayCategoriesSidebar"
                   (optionSelect)="onOptionSelect($event)"
                   (valueChange)="listSelectedIndex = getAvailableOptionIndexByValue($event)"
                   (listScrollEnd)="onListScrollEnd()"
                   (optionCreate)="onOptionCreate()"></ppl-option-list>
</ng-template>
