<div class="sales-units-item"
		 [class.readonly]="readonly">
	<ppl-checkbox *ngIf="!readonly"
								[disabled]="item.disabled"
								[value]="item.checked"
								[block]="true"
								(valueChange)="toggle.emit(item)">
	</ppl-checkbox>

	<span class="spacer"
				[style.width]="level * 15 + 'px'"></span>
	<span *ngIf="level > 0"
				style="position:relative"
				[style.margin-right]="!item.children.length ? '5px' : '0'">
		<span *ngFor="let i of levels"
					class="toggle-icon toggle-icon--level-straight"
					[style.left]="i * -15 - 10 + 'px'"></span>
		<span class="toggle-icon toggle-icon--level"></span>
	</span>

	<ng-container *ngIf="item.children.length">
		<span *ngIf="isOpened"
					class="toggle-icon toggle-icon--minus"
					(click)="toggleOpened($event)"></span>
		<span *ngIf="!isOpened"
					class="toggle-icon toggle-icon--plus"
					(click)="toggleOpened($event)"></span>
	</ng-container>
	<span [class.highlighted]="highlighted"
				[style.color]="item.color">
		{{item.name}}
	</span>
	<div *ngIf="actionTemplate"
			 class="actions">
		<ng-template [ngTemplateOutlet]="actionTemplate"
								 [ngTemplateOutletContext]="{ '\$implicit': item }">
		</ng-template>
	</div>
</div>

<ng-container *ngIf="isOpened">
	<ppl-form-table-tree *ngFor="let childItem of item.children; trackBy: trackTree"
											 [item]="childItem"
											 [level]="level + 1"
											 [forceOpened]="forceOpened"
											 [highlightCategories]="highlightCategories"
											 (toggle)="toggle.emit($event)"></ppl-form-table-tree>
</ng-container>
