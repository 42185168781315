import { dayNameMap } from '../domain/functions/day-name';
import { getAvailableSpaceIdpId } from '../domain/functions/space';
import { AppsEnum, NameFormat } from '@ppl/graphql-user-api';
import { AsyncState, createSelector } from '@ppl/store';
import { createTimezoneIANAOffsetExtractor, guessLocalTimezone } from '@ppl/utils';
import type { ID, Space } from '@ppl/graphql-user-api';
import type { AuthStateInCoreState } from '../store/auth.state';

const INITIAL_PIPELINER_CLOUD_SPACE_VERSION = 100;

export function isLoggedIn(state: AuthStateInCoreState) {
  return state.auth.user.state === AsyncState.FETCHED;
}

export function getAvailableSpaces(state: AuthStateInCoreState) {
  if (isLoggedIn(state)) {
    const spaces = getLoggedInUser(state).spaces.filter(
      space => space.version >= INITIAL_PIPELINER_CLOUD_SPACE_VERSION
    );
    spaces.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return spaces;
  } else {
    return [];
  }
}

export function getSubscriptionsAdministeredByMeTotalCount(state: AuthStateInCoreState) {
  if (!isLoggedIn(state)) {
    return 0;
  }
  return getLoggedInUser(state).entities.subscription.getAdministeredByMe.totalCount;
}

export function getSpaceById(spaceId: string | ID) {
  return (state: AuthStateInCoreState) => {
    return getAvailableSpaces(state).find(availableSpace => availableSpace.id === spaceId);
  };
}

export function getLoggedInUser(state: AuthStateInCoreState) {
  return state.auth.user.value;
}

export function getLoggedInUserProfile(state: AuthStateInCoreState) {
  return getLoggedInUser(state).profile;
}

export function getLoggedInUserProfileId(state: AuthStateInCoreState) {
  return getLoggedInUserProfile(state).id;
}

export function getLoggedInUserProfilePictureUrl(state: AuthStateInCoreState) {
  return getLoggedInUserProfile(state).pictureUrl;
}

export const getLoggedInUserTimezoneName = createSelector(
  isLoggedIn,
  (state: AuthStateInCoreState) => getLoggedInUser(state)?.profile,
  (isUserLoggedIn, profile): string | null => {
    if (isUserLoggedIn && profile && !profile.timezoneAutodetect) {
      return profile.timezoneName;
    } else {
      return null;
    }
  }
);

export const getLoggedInUserTimezoneNameOrLocal = createSelector(
  getLoggedInUserTimezoneName,
  (userTimezoneName) => {
    return userTimezoneName || guessLocalTimezone();
  }
);

export const getTimezoneOffsetInUserTimezoneExtractor = createSelector(
  getLoggedInUserTimezoneName,
  userTimezoneName => {
    return userTimezoneName
      ? createTimezoneIANAOffsetExtractor(userTimezoneName)
      : (date: Date): string => null;
  }
);

export function getUserSettings(state: AuthStateInCoreState) {
  return state.auth.user.value && state.auth.user.value.settings;
}

export function getUserApps(state: AuthStateInCoreState) {
  return state.auth.user.value && state.auth.user.value.accessControl.apps;
}

export function getUserSettingsNameFormat(state: AuthStateInCoreState) {
  return getUserSettings(state) && getUserSettings(state).nameFormat;
}

/**
 * @param angularLocaleId if used, provides with loaded angular locale id (which was loaded & passed into registerLocaleData())
 */
export function getUserSettingsLocale(angularLocaleId = false) {
  return (state: AuthStateInCoreState) => {
    if (angularLocaleId) {
      return state.auth.angularLocaleId;
    } else {
      return getUserSettings(state).locale;
    }
  };
}

export function getUserSettingsFirstWeekDay(state: AuthStateInCoreState) {
  const firstWeekDay = getUserSettings(state).firstWeekDay;
  return dayNameMap[firstWeekDay];
}

export function getUserLocale(state: AuthStateInCoreState) {
  return getUserSettings(state).locale || 'en';
}

export function getNameFormat(state: AuthStateInCoreState) {
  return getUserSettings(state).nameFormat || NameFormat.FirstMiddleLast;
}


export function getCurrentIdp(state: AuthStateInCoreState) {
  return getLoggedInUserProfile(state).currentIdp;
}

export function getCurrentIdpId(state: AuthStateInCoreState) {
  const idp = getCurrentIdp(state);
  return idp ? idp.id : null;
}

export function isSpaceAuthorizationNeeded(space: Space) {
  return (state: AuthStateInCoreState) => {
    return getCurrentIdpId(state) !== getAvailableSpaceIdpId(space);
  };
}

export function getAvailableIdentityProviders(state: AuthStateInCoreState) {
  return getLoggedInUserProfile(state).availableIdentityProviders;
}

export function getAuthSsoToken(state: AuthStateInCoreState) {
  return state.auth.ssoToken;
}

export function getAuthSsoSpaceId(state: AuthStateInCoreState) {
  return state.auth.ssoSpaceId;
}

export function hasAccessToBusinessCenter(state: AuthStateInCoreState) {
  return getUserApps(state).includes(AppsEnum.BusinessCenter);
}

export function hasAccessToCustomerPortal(state: AuthStateInCoreState) {
  return getSubscriptionsAdministeredByMeTotalCount(state) > 0;
}

