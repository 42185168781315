import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';
import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output
} from '@angular/core';
import {
  clamp,
  FormValueControl,
  getFormControlProvider,
  MemoizeLast
} from '@ppl/utils';

@Component({
  selector: 'ppl-ranking',
  templateUrl: 'ranking.component.html',
  styleUrls: ['ranking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => PplRankingComponent)
  ]
})
@FormValueControl()
export class PplRankingComponent {
  @Input() value = 1;
  @Input() showOne = false;
  @Input() disabled?: boolean;
  @Input() percentValue?: boolean;
  @Input() starSize?: string;
  @Input() readonly = false;

  @Output() valueChange = new EventEmitter<number>();

  stars = [1, 2, 3, 4, 5];
  hoverValue: number | null = null;

  @MemoizeLast<PplRankingComponent>(['starSize', 'ngUiTheme'])
  get normalizedStarSize() {
    return this.starSize || ThemeIcons[this.ngUiTheme].iconSize;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES,
  ) { }

  getStarIcon(value: number) {
    const thisValue = this.percentValue ? clamp(Math.ceil(this.value / 100 * 5), 1, 5) : this.value;

    if (this.isStarActive(value)) {
      return this.hoverValue ? ThemeIcons[this.ngUiTheme].hoverIcon : ThemeIcons[this.ngUiTheme].icons[thisValue - 1];
    } else {
      return ThemeIcons[this.ngUiTheme].defaultIcon;
    }
  }

  isStarActive(value: number) {
    const thisValue = this.percentValue ? clamp(Math.ceil(this.value / 100 * 5), 1, 5) : this.value;
    return value <= (this.hoverValue || thisValue);
  }

  onStarMouseEnter(value: number) {
    if (!this.disabled && !this.readonly && this.ngUiTheme !== 'fabric') {
      this.hoverValue = value;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.hoverValue = null;
  }

  onStarClick(value: number) {
    if (!this.disabled && !this.readonly) {
      this.valueChange.emit(this.percentValue ? value * 20 : value);
    }
  }

  trackByValue(index, item: number) {
    return item;
  }

}

const ThemeIcons = {
  pipeliner: {
    iconSize: '24px',
    icons: ['ranking-red-big', 'ranking-orange-big', 'ranking-yellow-big', 'ranking-blue-big', 'ranking-green-big'],
    defaultIcon: 'ranking-gray-big',
    hoverIcon: 'ranking-hover-big'
  },
  fabric: {
    iconSize: '32px',
    icons: ['ranking-star-red-selected', 'ranking-star-orange-selected', 'ranking-star-golden-selected', 'ranking-star-blue-selected', 'ranking-star-green-selected'],
    defaultIcon: 'ranking-star-gray-unselected',
    hoverIcon: 'ranking-star-golden-selected'
  }
};
