import type { OnInit } from '@angular/core';
import { Directive , ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pplInputIcon]'
})
export class PplInputIconDirective implements OnInit {
  private className = 'ppl-input-icon';

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, this.className);
  }
}
