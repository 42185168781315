import type { PplAutocompleteOption, PplAutocompleteOptionsRequest } from '../autocomplete';
import { PplAutocompleteComponent } from '../autocomplete';
import { ExternalFilter } from '../autocomplete/external-filter';
import { fuzzySearch } from '../autocomplete/fuzzy-search';
import { PplPopoverDirection } from '../popover';
import type {
  OnChanges,
  OnDestroy,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  TemplateRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplAutocompleteSelectComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplAutocompleteSelectComponent implements OnChanges, OnDestroy {

  @Input() options: PplAutocompleteOption[];
  @Input() value: string;

  @Input() categories?: any[];
  @Input() displayCategoriesSidebar?: boolean;
  @Input() freeValue = false;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() displayCreateOption?: string;
  @Input() displayValueLoading = false;
  @Input() displayOptionsLoading = false;
  @Input() forceDisplayClearValue = false;
  @Input() autoFocusOnInit = false;
  @Input() disabled = false;
  @Input() maxContainerHeight?: number;
  @Input() readonly = false;
  @Input() placeholder?: string;
  @Input() initFilterEnabled = false;
  @Input() popoverDirection: PplPopoverDirection = 'down';

  @Output() valueChange = new EventEmitter<string>();
  @Output() optionCreate = new EventEmitter<string>();
  @Output() optionSelect = new EventEmitter<string>();
  @Output() optionsRequest = new EventEmitter<PplAutocompleteOptionsRequest>();
  @Output() listClose = new EventEmitter();
  @Output() clearValueClick = new EventEmitter();

  @ViewChild(PplAutocompleteComponent, { static: true }) autocomplete: PplAutocompleteComponent;

  filter = '';
  externalFilter: ExternalFilter;

  optionValueCache: { [id: string]: string } = {};

  get availableOptions() {
    if (this.isExternalFilter()) {
      return this.options;
    } else {
      return this.availableOptionsFiltered;
    }
  }

  @MemoizeLast(['options', 'filter'])
  get availableOptionsFiltered() {
    return fuzzySearch({
      list: this.options,
      term: this.filter
    });
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      const valueInCache = this.optionValueCache[this.value];

      this.options.forEach(option => {
        this.optionValueCache[option.value] = option.label;
      });

      if (!this.freeValue && !valueInCache && this.optionValueCache[this.value]) {
        this.filter = this.getSelectedOptionLabel(this.value);
      }
    }

    if (changes.value) {
      if (this.freeValue) {
        this.filter = this.value;
      } else {
        this.filter = this.getSelectedOptionLabel(this.value);
      }
    }
  }

  ngOnDestroy() {
    if (this.externalFilter) {
      this.externalFilter.dispose();
    }
  }

  onListOpen() {
    if (this.isExternalFilter()) {
      this.externalFilter = new ExternalFilter({
        initValue: this.initFilterEnabled ? this.filter : undefined,
        onChange: value => {
          if (this.freeValue) {
            this.valueChange.emit(value);
          }
        },
        onOptionsRequest: event => {
          this.optionsRequest.emit(event);
        }
      });
    }
  }

  onListClose() {
    if (!this.freeValue) {
      this.filter = this.getSelectedOptionLabel(this.value);
    }

    if (this.externalFilter) {
      this.externalFilter.dispose();
      this.externalFilter = null;
    }

    this.listClose.emit();
  }

  onListScrollEnd() {
    if (this.externalFilter && !this.displayOptionsLoading) {
      this.externalFilter.listEnd(this.options[this.options.length - 1]);
    }
  }

  onOptionSelect(option: PplAutocompleteOption) {
    this.valueChange.emit(this.freeValue ? option.label : option.value);
    this.optionSelect.emit(option.value);
  }

  onOptionCreate(value: string) {
    this.optionCreate.emit(value);
  }

  onClearValueClick() {
    this.clearValueClick.emit();
  }

  onValueChange(value: string) {
    this.filter = value;

    if (this.externalFilter) {
      this.externalFilter.next(value);
    } else if (this.freeValue) {
      this.valueChange.emit(value);
    }
  }

  getSelectedOptionLabel(value: string) {
    const selectedOption = this.options.find(option => option.value === value);

    return selectedOption ? selectedOption.label : (this.optionValueCache[value] || '');
  }

  isExternalFilter() {
    return this.optionsRequest.observers.length !== 0;
  }

  closeOptionsPopover() {
    this.autocomplete.optionsPopover.hide();
  }
}
