import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PplDialogService } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-graph-item-demo',
  templateUrl: './graph-item-demo.component.html',
  styleUrls: ['./graph-item-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphItemDemoComponent implements OnInit {

  code = `
    <ppl-graph-item></ppl-graph-item>
  `;

  constructor(
    private dialogService: PplDialogService
  ) { }

  ngOnInit(): void {
  }

  onGraphItemClick() {
    this.dialogService.info({ text: 'Graph item click' });
  }

  onGraphItemToggle() {
    this.dialogService.info({ text: 'Graph item toggled' });
  }

}
