import { PplPopoverControl } from '../popover/popover-content/popover-content.component';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input
} from '@angular/core';
import type { OnInit, TemplateRef } from '@angular/core';
import type { PplPopoverComponentInterface } from '../popover/popover-content/popover-content.component';

@Component({
  selector: 'ppl-popover-tooltip',
  templateUrl: './popover-tooltip.component.html',
  styleUrls: ['./popover-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplPopoverTooltipComponent implements OnInit, PplPopoverComponentInterface {

  hostElement: Element;
  connectorStyle = {};
  recalcTimeout: any;

  @Input() text: string | TemplateRef<any>;
  @Input() direction: 'up' | 'down' | 'left' | 'right' = 'down';
  @Input() connectToElement?: Element;
  @Input() popover: PplPopoverControl;
  @Input() preserveWhitespace = false;
  @Input() type?: 'warning' | 'error';

  @Input() backgroundColor: string;
  @HostBinding('style.color') @Input() fontColor: string;
  @HostBinding('class.borderless') @Input() borderless = false;

  constructor(
    public elementRef: ElementRef, // being used in reminder-component
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.popover) {
      this.popover.positionRecalculated = () => this.recalcPosition();
    }
    this.recalcPosition();
  }

  isTextString() {
    return typeof this.text === 'string';
  }

  calcMaxOffsetConstraint(hostWidth: number, offset: number): number {
    const maxOffset = hostWidth - ARROW_WIDTH;
    return offset > maxOffset ? maxOffset : offset;
  }

  recalcPosition() {
    if (this.connectToElement || this.hostElement) {
      const elementRect = this.elementRef.nativeElement.getBoundingClientRect();
      const hostRect = this.hostElement ? this.hostElement.getBoundingClientRect() : this.connectToElement.getBoundingClientRect();

      if (this.direction === 'up' || this.direction === 'down') {
        const leftDiff = hostRect.left - elementRect.left;
        const leftOffset = leftDiff + hostRect.width / 2 - ARROW_WIDTH / 2;
        const leftOffsetWithConstraint = this.calcMaxOffsetConstraint(leftDiff + hostRect.width / 2, leftOffset);
        this.connectorStyle = {
          'left': `${leftOffsetWithConstraint}px`
        };
      } else {
        const topDiff = hostRect.top - elementRect.top;
        const topOffset = topDiff + hostRect.height / 2 - ARROW_HEIGHT / 2;
        const topOffsetWithConstraint = this.calcMaxOffsetConstraint(topDiff + hostRect.height / 2, topOffset);
        this.connectorStyle = {
          'top': `${topOffsetWithConstraint}px`
        };
      }
      this.changeDetectorRef.detectChanges();
    }
  }
}

const ARROW_WIDTH = 5;
const ARROW_HEIGHT = 10;
