import { PplMultipleSelectComponent } from './multiple-select.component';
import { PplCheckboxModule } from '../checkbox';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { PplOptionListModule } from '../option-list';
import { PplPopoverModule } from '../popover';
import { PplSearchModule } from '../search';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplMultipleSelectOptionModule } from '../multiple-select-option';


@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplPopoverModule,
    PplLoadingModule,
    PplSearchModule,
    PplOptionListModule,
    PplCheckboxModule,
    PplPopoverDropdownModule,
    PplMultipleSelectOptionModule
  ],
  declarations: [
    PplMultipleSelectComponent,
  ],
  exports: [
    PplMultipleSelectComponent,
  ]
})
export class PplMultipleSelectModule {
}

export * from './multiple-select.component';

