import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-ranking-percentage-demo',
  templateUrl: './ranking-percentage-demo.component.html',
  styleUrls: ['./ranking-percentage-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RankingPercentageDemoComponent implements OnInit {
  percentage = 50;

  code = `
  <ppl-input-container>
    <ppl-ranking-percentage [value]="percentage"
                            (valueChange)="percentage = $event"></ppl-ranking-percentage>
  </ppl-input-container>
  `;

  constructor() {}

  ngOnInit() {}
}
