import { Component } from '@angular/core';
import { PplIconService } from '@ppl/ui/icon';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';

interface NavItem {
  name: string;
  route: string;
  children: NavItem[];
}

@Component({
  selector: 'ppl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'components-showcase';

  navItems: NavItem[] = [
    { name: 'Home', children: [], route: '' },
    {
      name: 'Layout',
      route: '',
      children: [
        { name: 'Top Bar', children: [], route: 'top-bar' },
        { name: 'Bottom Bar', children: [], route: 'bottom-bar' },
        { name: 'App Container', children: [], route: 'app-container' },
        { name: 'Accordion', children: [], route: 'accordion' },
        { name: 'Carousel', children: [], route: 'carousel' },
        { name: 'Drawer', children: [], route: 'drawer' }
      ]
    },
    {
      name: 'Form',
      route: '',
      children: [
        { name: 'Input', children: [], route: 'input' },
        { name: 'Input number', children: [], route: 'input-number' },
        { name: 'Radio', children: [], route: 'radio-button' },
        { name: 'Checkbox', children: [], route: 'checkbox' },
        { name: 'Editable', children: [], route: 'editable' },
        { name: 'Select', children: [], route: 'select' },
        { name: 'Multiple Select', children: [], route: 'multiple-select' },
        { name: 'Autocomplete', children: [], route: 'autocomplete' },
        { name: 'Datepicker', children: [], route: 'datepicker' },
        { name: 'Multiple-autocomplete', children: [], route: 'multiple-autocomplete' },
        { name: 'Ranking', children: [], route: 'ranking' },
        { name: 'Ranking Percentage', children: [], route: 'ranking-percentage' },
        { name: 'Account-class', children: [], route: 'account-class' },
        { name: 'Slide toggle', children: [], route: 'slide-toggle' },
        { name: 'Attachments', children: [], route: 'attachments' },
        { name: 'Toggle group', children: [], route: 'toggle-group' },
        { name: 'Partial select', children: [], route: 'partial-select' },
        { name: 'Builder', children: [], route: 'form-builder' },
        { name: 'Items Chooser', children: [], route: 'items-chooser' },
        { name: 'Form Table', children: [], route: 'form-table' },
        { name: 'Tree Select', children: [], route: 'tree-select' },
        { name: 'Selector View', children: [], route: 'selector-view' },
        { name: 'Multi Checkbox Advanced', children: [], route: 'multi-checkbox-advanced' },
      ]
    },
    { name: 'Icons', children: [], route: 'icons' },
    { name: 'Buttons', children: [], route: 'buttons' },
    { name: 'Link', children: [], route: 'link' },
    { name: 'Menu', children: [], route: 'menu' },
    { name: 'NavBar', children: [], route: 'nav-bar' },
    { name: 'Addressbook Picture', children: [], route: 'addressbook-picture' },
    {
      name: 'Content manipulation',
      route: '',
      children: [
        { name: 'Container', children: [], route: 'container' },
        { name: 'Popover', children: [], route: 'popover' },
        { name: 'Dialog', children: [], route: 'dialog' },
        { name: 'Tabs', children: [], route: 'tabs' },
        { name: 'Infinite Scrol', children: [], route: 'infinite-scroll' },
        { name: 'Loading Overlay', children: [], route: 'loading-overlay' },
        { name: 'Toast', children: [], route: 'toast' },
        { name: 'Info Box', children: [], route: 'info-box' },
        { name: 'Resizable', children: [], route: 'resizable' },
        { name: 'Text Dropdown', children: [], route: 'text-dropdown' },
        { name: 'Toggle List', children: [], route: 'toggle-list' }
      ]
    },
    {
      name: 'Components',
      route: '',
      children: [
        { name: 'Steps', route: 'steps', children: [] },
        { name: 'Phone number', route: 'input-phone-number', children: [] },
        { name: 'Graph toggle', route: 'graph-toggle', children: [] },
        { name: 'Graph item', route: 'graph-item', children: [] }
      ]
    },
    {
      name: 'Calling & Messaging',
      route: '',
      children: [
        { name: 'Conversation Item', route: 'calling-conversation-item', children: [] }
      ]
    }
  ];

  constructor(
    private iconService: PplIconService,
    private titleService: Title
  ) {
    this.iconService.registerPplIcons(environment.deployUrl);
    this.titleService.setTitle('Components\' Showcase');
  }
}
