<div class="content">
  <ppl-option-list-categories *ngIf="showCategoriesSidebar"
                              [categories]="normalizedSearchCategories"
                              [value]="selectedCategory"
                              (valueChange)="onSelectedCategoryChange($event)"
                              [class.default-width]="listWidth === undefined"
                              [style.max-height.px]="maxContainerHeight ? maxContainerHeight : null"
                              [style.width.px]="categoriesSidebarWidth"></ppl-option-list-categories>

  <div #container
       class="container"
       [class.with-search]="showCategoriesSidebar"
       [style.height.px]="containerHeight"
       [style.max-height.px]="maxContainerHeight ? maxContainerHeight : null"
       [style.width.px]="listWidth">

    <ng-container *ngIf="actionsTemplate">
      <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
    </ng-container>

    <ppl-virtual-scroll *ngIf="!!normalizedOptions.length"
                        #virtualScroll
                        [data]="normalizedOptions"
                        [dataTemplate]="rowTemplate"
                        [minRowHeight]="25"
                        [rowHeights]="rowHeights"
                        [containerMargin]="[0, 0, 0, 0]"
                        (scrollChange)="onScrollChange($event)">
    </ppl-virtual-scroll>

    <div *ngIf="displayNoResults && !displayLoading"
         class="no-results"
         [style.height.px]="optionTemplateRowHeight || 25">{{ intl.noResultsFound }}</div>

    <ng-template #rowTemplate
                 let-option
                 let-style="style"
                 let-i="realIndex">
      <div *ngIf="!option._meta"
           class="row"
           [ngStyle]="style">
        <div *ngIf="isCategoryOption(option, i)"
             class="category">
          {{getCategoryLabel(option.categoryId)}}
        </div>

        <div class="item"
             [class.selected]="option.value === value"
             [attr.data-index]="i"
             (click)="onOptionClick(option, $event)"
             (mouseenter)="onOptionMouseEnter(option.value)">
          <ng-container *ngIf="optionTemplate; else basicOption">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { '\$implicit': option }"></ng-container>
          </ng-container>
          <ng-template #basicOption>
            <div class="item-basic">
              <ppl-icon *ngIf="option.icon"
                        class="item-icon"
                        [svgIcon]="option.icon"
                        width="14px"
                        height="14px"></ppl-icon>

              <div class="item-label">
                {{option.label}}
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <div *ngIf="option._meta && option._meta.loading"
           class="row"
           [ngStyle]="style">
        <ppl-loading-overlay></ppl-loading-overlay>
      </div>
    </ng-template>
  </div>
</div>

<div class="footer">
  <div *ngIf="displayCreate"
       class="create"
       (click)="onOptionCreate()">
    <ppl-icon svgIcon="color-add"
              width="18px"
              height="18px"></ppl-icon>
    <div class="create-label">{{displayCreateOption}} <span class="create-label-token">"{{createValue}}"</span></div>
  </div>
</div>
