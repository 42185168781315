import type { PplFormTableItem } from './form-table-item';

export function flattenTree(root, key) {
  const flatten = [Object.assign({}, root)];
  delete flatten[0][key];

  if (root[key] && root[key].length > 0) {
    return flatten.concat(root[key]
      .map((child) => flattenTree(child, key))
      .reduce((a, b) => a.concat(b), [])
    );
  }
  return flatten;
}

export function listToTree(inputData: PplFormTableItem[]) {
  const ID_KEY = 'id';
  const PARENT_KEY = 'parentId';
  const CHILDREN_KEY = 'children';

  const data = [...inputData];
  const tree: Array<any> = [];
  const childrenOf = {};
  let item: any;
  let id;
  let parentId;

  for (let i = 0, length = data.length; i < length; i++) {
    if (!data[i].deleted || data[i].checked) {
      item = { ...data[i] };
      id = item[ID_KEY];
      parentId = item[PARENT_KEY] || null;
      // every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // init its children
      item[CHILDREN_KEY] = childrenOf[id];
      if (parentId !== null) {
        // init its parent's children object
        childrenOf[parentId] = childrenOf[parentId] || [];
        // push it into its parent's children object
        childrenOf[parentId].push(item);
      } else {
        tree.push(item);
      }
    }
  }
  return tree;
}
