<div #recordsContainer
     class="records-container"
     [style.width.px]="scrollWidth"
     [style.height.px]="scrollHeight">
  <ng-container *pplVirtualScrollFor="let record of visibleRecords; dataKey: dataKey; startIndex: overscanStartIndex">
    <ng-template [ngTemplateOutlet]="dataTemplate"
                 [ngTemplateOutletContext]="{
      '\$implicit': record,
      nextRecord: visibleRecords[getRecordIndex(record) + 1],
      extraData: extraData,
      style: getRecordStyle(overscanStartIndex + getRecordIndex(record)),
      index: getRecordIndex(record),
      realIndex: overscanStartIndex + getRecordIndex(record),
      odd: (overscanStartIndex + getRecordIndex(record)) % 2 === 1,
      last: overscanStartIndex + getRecordIndex(record) === data.length - 1
    }"></ng-template>
  </ng-container>
</div>

<ng-container *ngIf="frozenPanes && frozenPanes.length !== 0">
  <div *ngIf="frozenPaneTop"
       class="frozen-pane-top"
       [style.left.px]="frozenPaneTop.left"
       [style.top.px]="frozenPaneTop.top"
       [innerHTML]="frozenPaneTop.content">
  </div>

  <div *ngFor="let frozenPaneLeft of frozenPanesLeft; trackBy: trackFrozenPane"
       class="frozen-pane-left"
       [style.left.px]="frozenPaneLeft.left"
       [style.top.px]="frozenPaneLeft.top"
       [innerHTML]="frozenPaneLeft.content">
  </div>

  <div *ngIf="frozenPaneTopLeft"
       class="frozen-pane-top-left"
       [style.left.px]="frozenPaneTopLeft.left"
       [style.top.px]="frozenPaneTopLeft.top"
       [innerHTML]="frozenPaneTopLeft.content">
  </div>
</ng-container>