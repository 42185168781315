<div class="container demo-content">
  <h2>Icons</h2>

  <ppl-icon svgIcon="test-account-detailtabs"></ppl-icon>

  <h3>Usage</h3>
  <div><b>&lt;ppl-icon svgIcon="icon" width="20px" height="20px"&gt;&lt;/ppl-icon&gt;</b></div>

  <br>

  <ppl-input-container>
    <input #input pplInput placeholder="Search icon">
  </ppl-input-container>

  <div class="icons-container">
    <div class="icon" *ngFor="let icon of icons; trackBy: trackIcon">
      <ppl-icon [svgIcon]="icon"></ppl-icon>
      <br>
      <small style="word-break: break-all; font-size: 11px;">{{icon}}</small>
    </div>
  </div>
</div>
