import { PplContentBlockComponent } from './content-block.component';
import { PplContentTitleComponent } from './content-title/content-title.component';
import { PplButtonModule } from '../button';
import { PplIconModule } from '../icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplButtonModule,
    PplIconModule
  ],
  declarations: [
    PplContentBlockComponent,
    PplContentTitleComponent
  ],
  exports: [
    PplContentBlockComponent,
    PplContentTitleComponent
  ]
})
export class PplContentBlockModule {
}

export * from './content-block.component';
export * from './content-title/content-title.component';
