import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-link-demo',
  templateUrl: './link-demo.component.html',
  styleUrls: ['./link-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkDemoComponent implements OnInit {
  alert = alert;

  code = `
  <ppl-input-container label="Anchor">
    <a pplLink
       href="https://google.com"
       target="_blank">Anchor</a>
  </ppl-input-container>

  <ppl-input-container label="Span">
    <span pplLink
          (click)="alert('Clicked!')">Span Visual</span>
  </ppl-input-container>
  `;

  constructor() { }

  ngOnInit(): void {
  }
}
