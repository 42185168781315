import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { AuthStore } from '../../store/auth.state';
import type { TokenService, TokenSignInOptions, TokenSignOutOptions } from './token.service';
import { createLoginToken, createLogoutToken } from './token.utils';

declare let Office: any;

@Injectable()
export class OfficeTokenService implements TokenService {

  constructor(
    private store: AuthStore
  ) {}

  signIn(options: TokenSignInOptions) {
    const result$ = new Subject<boolean>();

    const ssoToken = createLoginToken({
      appUrl: options.appUrl,
      queryForSpace: options.queryForSpace,
      disableGoogleSignIn: Office.context.diagnostics && Office.context.diagnostics.platform !== Office.PlatformType.OfficeOnline,
      returnUrl: `${options.appUrl}/sso-prod`
    });

    Office.context.ui.displayDialogAsync(`${options.appUrl}/sso-redirect?url=${encodeURIComponent(`${options.ssoUrl}/sso/${ssoToken}`)}`, { height: 50, promptBeforeOpen: false, width: 50 }, result => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.error(result.error);
        result$.next(false);
      } else {
        result.value.addEventHandler(Office.EventType.DialogMessageReceived, data => {
          result.value.close();

          const message = JSON.parse(data.message);

          this.store.dispatch('Auth_UpdateSsoResult', {
            token: message.token,
            spaceId: message.spaceId
          });

          this.setToken(message.token);
          this.setAppSessionId(message.appSessionId);

          if (message.spaceId) {
            if (Office.context.document) {
              // Word/Excel - save spaceId to document settings
              Office.context.document.settings.set('Pipeliner_Space', message.spaceId);
              Office.context.document.settings.saveAsync();
            } else if (Office.context.mailbox) {
              // Outlook - save spaceId to localStorage
              localStorage.setItem('Pipeliner_Space', message.spaceId);
            }
          }

          result$.next(true);
        });
      }
    });

    return result$.asObservable();
  }

  signOut(options: TokenSignOutOptions) {
    const result$ = new Subject<boolean>();

    const ssoToken = createLogoutToken({
      appSessionId: this.getAppSessionId(),
      appUrl: options.appUrl,
      returnUrl: `${options.appUrl}/sso-prod`
    });

    Office.context.ui.displayDialogAsync(`${options.appUrl}/sso-redirect?url=${encodeURIComponent(`${options.ssoUrl}/sso_logout/${ssoToken}`)}`, { height: 50, promptBeforeOpen: false, width: 50 }, result => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.error(result.error);
        result$.next(false);
      } else {
        result.value.addEventHandler(Office.EventType.DialogMessageReceived, () => {
          result.value.close();

          result$.next(true);
        });
      }
    });

    return result$.asObservable();
  }

  getToken() {
    try {
      const token = localStorage.getItem('Pipeliner_Token');

      if (token) {
        this.store.dispatch('Auth_UpdateSsoToken', token);
      }

      return of(token);
    } catch (ex) {
      return of(null);
    }
  }

  setToken(token: string) {
    try {
      localStorage.setItem('Pipeliner_Token', token);
    } catch (ex) {}

    return of(null);
  }

  deleteToken() {
    try {
      this.store.dispatch('Auth_UpdateSsoToken', null);

      localStorage.removeItem('Pipeliner_Token');
    } catch (ex) {}

    return of(null);
  }

  reloadPage() {
    location.reload();
  }

  getAppSessionId() {
    try {
      return localStorage.getItem('Pipeliner_AppSessionId');
    } catch (ex) {
      return null;
    }
  }

  setAppSessionId(appSessionId: string) {
    try {
      localStorage.setItem('Pipeliner_AppSessionId', appSessionId);
    } catch (ex) {}

    return null;
  }

}
