import { NameFormat } from '@ppl/graphql-user-api';

export interface GeneralNameFormatInterface {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  title?: string;
  email?: string;
  email1?: string;
}

export function getNameFormatted(name: GeneralNameFormatInterface, format: NameFormat) {
  let result = '';
  switch (format) {
    case NameFormat.FirstMiddleLast:
      result = [name.title, name.firstName, name.middleName, name.lastName]
        .filter(value => value)
        .join(' ')
        .trim();
      return result || name.email || name.email1 || '';
    case NameFormat.LastFirstMiddle:
      result = [[name.title, name.lastName].filter(value => value).join(' '), [name.firstName, name.middleName].filter(value => value).join(' ')]
        .filter(value => value)
        .join(', ')
        .replace(/\s+/g, ' ') // Remove double spaces
        .trim();
      return result || name.email || name.email1 || '';
    default:
      return '';
  }
}
