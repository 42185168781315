import { ExternalFilter } from '../autocomplete/external-filter';
import type { PplOptionListOption } from '../option-list';
import { PplPopoverDirective } from '../popover';
import { PplUiIntl } from '../ppl-ui-intl';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef,
  NgZone,
  TemplateRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  arrayEquals,
  FormValueControl,
  KEYCODE_BACKSPACE,
  KEYCODE_ENTER,
  KEYCODE_TAB,
  MemoizeLast
} from '@ppl/utils';

@Component({
  selector: 'ppl-multiple-autocomplete',
  templateUrl: './multiple-autocomplete.component.html',
  styleUrls: ['./multiple-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplMultipleAutocompleteComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplMultipleAutocompleteComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() options: PplMultipleAutocompleteOption[];
  @Input() value: string[] = [];

  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() selectedOptionTemplate?: TemplateRef<any>;
  @Input() selectedOptionColor?: (value: string) => string;
  @Input() selectedOptionClass?: (value: string) => string;
  @Input() categories?: PplMultipleAutocompleteCategory[];
  @Input() disabled = false;
  @Input() freeValue = false;
  @Input() scrollable = false;
  @Input() freeValueValidator?: (value: string) => boolean;
  @Input() maxValues?: number;
  @Input() placeholder = 'start typing...';
  @Input() displayValueLoading = false;
  @Input() displayOptionsLoading = false;
  @Input() maxOptionListContainerHeight?: number;
  @Input() openOnFocus = true;
  @Input() autoFocusOnInit = false;
  @Input() displayAddAll = false;
  @Input() displayAll = false;
  @Input() displayCategoriesSidebar?: boolean;
  @Input() createOptionLabel?: 'create' | 'add' = 'add';

  @Output() blur = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();
  @Output() optionsRequest = new EventEmitter<PplMultipleAutocompleteOptionsRequest>();
  @Output() valueChange = new EventEmitter<string[]>();
  @Output() optionClick = new EventEmitter<string>();

  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('filterInput', { static: false }) filterInput: ElementRef;
  @ViewChild(PplPopoverDirective, { static: true }) optionsPopover: PplPopoverDirective;

  externalFilter: ExternalFilter;
  filterInputText = '';
  filterInputSize = MIN_FILTER_INPUT_SIZE;
  focused = false;
  invisibleItemCount = 0;
  invisibleItemCountOffset = 0;
  listSelectedIndex = -1;

  renderHandleChange = 0;
  renderHandleFilter = 0;
  recalcHandle: any = 0;

  pasteListener: any;

  resizeObserver: ResizeObserver;

  @MemoizeLast<PplMultipleAutocompleteComponent>(['availableOptions', 'filterInputText', 'freeValue'])
  get displayCreateOption() {
    const textInOptions = this.options.find(option => option.label === this.filterInputText);
    const textInValue = this.value.includes(this.filterInputText);
    const textValid = this.freeValueValidator ? this.freeValueValidator(this.filterInputText) : true;

    if (this.freeValue && !!this.filterInputText && !textInOptions && !textInValue && textValid) {
      switch (this.createOptionLabel) {
        case 'add':
          return this.intl.add;
        case 'create':
          return this.intl.Create;
      }
    } else {
      return null;
    }
  }

  @MemoizeLast<PplMultipleAutocompleteComponent>(['options', 'value', 'freeValue'])
  get selectedOptions() {
    if (this.isExternalFilter()) {
      // External filter does not have all options available at all times, so parent component has to specify selectedOptionTemplate and maintain its own label cache
      // (Or someone can implement displayValueLoading/optionValueCache apparatus like in ppl-autocomplete-select)
      return this.value.map<PplOptionListOption & { color?: string }>(value => ({ label: '', value }));
    }

    const options: (PplOptionListOption & { color?: string })[] = this.options.filter(option => {
      return this.value.includes(option.value);
    });

    if (this.freeValue) {
      // Add free values not present in options
      this.value.forEach(value => {
        if (!options.find(option => option.label === value)) {
          options.push({ label: value, value });
        }
      });
    }

    return options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }

  @MemoizeLast<PplMultipleAutocompleteComponent>(['options', 'value', 'filterInputText', 'freeValueValidator'])
  get availableOptions() {
    let options: PplOptionListOption[] = [];

    if (this.isExternalFilter()) {
      options = this.options;
    } else {
      options = this.options.filter(option => {
        return !this.value.includes(option.value) && !option.deleted && (!this.filterInputText || option.label.toLowerCase().includes(this.filterInputText.toLowerCase()));
      });
    }

    return options;
  }

  get hasItemCount() {
    return !this.focused && this.invisibleItemCount && !this.displayAll;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public intl: PplUiIntl,
    private ngZone: NgZone
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.value && !arrayEquals(changes.value.previousValue, this.value)) || changes.options) {
      if (!changes.options) {
        this.setFilterInputText('');
      }

      this.updateListSelectedIndex();

      if (this.optionsPopover.isOpen) {
        // Component can change height
        this.renderHandleChange = requestAnimationFrame(() => this.optionsPopover.recalcPosition());
      } else if (this.listSelectedIndex !== -1 && this.focused && (this.maxValues === undefined || this.value.length < this.maxValues)) {
        if (this.openOnFocus) {
          // Ensure option list is visible
          this.optionsPopover.show();
        } else {
          this.optionsPopover.hide();
        }
      }

      if (!this.focused) {
        this.recalcItemCount();
      }
    }
  }

  ngOnInit() {
    // IE11 (which does not have ResizeObserver) in Outlook actually does not need it because we do not display itemCount there
    if ('ResizeObserver' in window) {
      this.ngZone.runOutsideAngular(() => {
        this.resizeObserver = new ResizeObserver(() => {
          this.ngZone.run(() => {
            this.recalcItemCount();
          });
        });

        this.resizeObserver.observe(this.container.nativeElement.parentNode);
      });
    }

    if (this.autoFocusOnInit) {
      requestAnimationFrame(() => {
        this.filterInput?.nativeElement.focus();

        if (this.openOnFocus) {
          this.optionsPopover.show();
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.freeValue) {
      this.filterInput.nativeElement.addEventListener('paste', this.pasteListener = (event: ClipboardEvent) => {
        this.processClipboardData(event.clipboardData.getData('Text'));

        event.preventDefault();
      });
    }
  }

  ngOnDestroy() {
    if (this.freeValue) {
      this.filterInput.nativeElement.removeEventListener('paste', this.pasteListener);
    }

    this.resizeObserver?.disconnect();
    cancelAnimationFrame(this.renderHandleChange);
    cancelAnimationFrame(this.renderHandleFilter);
    clearTimeout(this.recalcHandle);
  }

  onContainerMouseDown(event: MouseEvent) {
    const eventTarget = event.target as HTMLElement;
    const itemCountClick = eventTarget.classList.contains('item-count');
    const inputClick = eventTarget.tagName === 'INPUT';

    if (this.displayValueLoading) {
      return;
    }

    if (!inputClick || itemCountClick) {
      event.preventDefault();
    }

    event.stopPropagation();

    if (!this.disabled) {
      this.filterInput.nativeElement.focus();

      if (!itemCountClick) {
        this.optionsPopover.show();
      }
    } else {
      this.focused = true;
    }
  }

  scrollToBottom() {
    if (!this.disabled && this.scrollable) {
      const containerElement: HTMLElement = this.container.nativeElement;
      this.container.nativeElement.scrollTop = (
        containerElement.scrollHeight - containerElement.clientHeight
      );
    }
  }

  onFilterInputFocus() {
    this.setFilterInputText('');
    this.updateListSelectedIndex(true);
    this.focused = true;
    this.focus.emit();

    // NOTE(mike): Must delay scroll to bottom until change
    // detection runs and `filterInput` is shown so that the
    // scrollbar takes `filterInput` into account.
    setTimeout(() => {
      this.scrollToBottom();
    });
  }

  onFilterInputBlur() {
    this.focused = false;
    this.blur.emit();

    this.optionsPopover.hide();

    this.recalcItemCount();
  }

  onFilterInputInput(event: any) {
    this.setFilterInputText(event.target.value);
    this.updateListSelectedIndex(true);

    if (this.filterInputText) {
      // Ensure option list is visible
      this.optionsPopover.show();
    } else {
      if (!this.openOnFocus) {
        this.optionsPopover.hide();
      }
    }
  }

  onFilterInputKeyDown(event: KeyboardEvent) {
    if (event.keyCode === KEYCODE_BACKSPACE && !this.filterInputText) {
      const selectedOptions = this.selectedOptions;

      if (selectedOptions.length !== 0) {
        this.valueChange.emit(this.value.filter(value => value !== selectedOptions[selectedOptions.length - 1].value));
      }

      event.preventDefault();
    } else if (event.keyCode === KEYCODE_TAB && this.filterInputText) {
      event.preventDefault();
      const option = this.availableOptions[this.listSelectedIndex];
      if (option) {
        this.onOptionSelect(option);
      }
    } else if (event.keyCode === KEYCODE_ENTER && this.filterInputText) {
      event.preventDefault();
    }
  }

  onItemRemoveClick(value: string, event: MouseEvent) {
    if (!this.disabled) {
      let valueRemoved = false;

      // In case of duplicate IDs, remove only first value
      this.valueChange.emit(this.value.filter(v => {
        if (v === value && !valueRemoved) {
          valueRemoved = true;
          return false;
        }

        return true;
      }));
    }
  }

  onOptionSelect(option: { value: string }) {
    if (this.maxValues !== undefined && this.value.length >= this.maxValues) {
      return;
    }

    if (this.availableOptions.length === 1 || (this.maxValues !== undefined && this.maxValues - this.value.length === 1) || !this.openOnFocus) {
      // Hide option list if last option selected, or openOnFocus is disabled
      this.optionsPopover.hide();
    }

    this.valueChange.emit([...this.value, option.value]);
  }

  onOptionCreate() {
    this.onOptionSelect({ value: this.filterInputText });
  }

  onOptionSelectAll() {
    this.valueChange.emit(this.options.map(option => option.value));
    this.optionsPopover.hide();
  }

  onOptionsPopoverToggle(opened: boolean) {
    if (opened) {
      if (this.isExternalFilter()) {
        this.externalFilter = new ExternalFilter({
          // If options popover opens on user keyboard input, we have to send this current filter text as initValue for external filter
          initValue: !this.openOnFocus ? this.filterInputText : undefined,
          onChange: () => { },
          onOptionsRequest: event => {
            this.optionsRequest.emit(event);
          }
        });
      }
    } else {
      if (this.externalFilter) {
        this.externalFilter.dispose();
        this.externalFilter = null;
      }
    }
  }

  onListScrollEnd() {
    if (this.externalFilter && !this.displayOptionsLoading) {
      this.externalFilter.listEnd(this.options[this.options.length - 1]);
    }
  }

  setFilterInputText(text: string) {
    this.filterInputText = text;

    if (ctx) {
      // NOTE(mike): The +1 is a Bulgarian constant so the text doesn't wobble from
      // left to right when writing.
      this.filterInputSize = Math.max(Math.ceil(ctx.measureText(text).width) + 1, MIN_FILTER_INPUT_SIZE);
      this.renderHandleFilter = requestAnimationFrame(() => this.optionsPopover.recalcPosition());
    }

    if (this.externalFilter) {
      this.externalFilter.next(text);
    }
  }

  updateListSelectedIndex(resetToFirstOption = false) {
    // Generate available options after filter set
    const availableOptions = this.availableOptions;

    this.listSelectedIndex = (availableOptions.length !== 0) ? (resetToFirstOption ? 0 : Math.min(Math.max(this.listSelectedIndex, 0), availableOptions.length - 1)) : -1;
  }

  getAvailableOptionIndexByValue(value: string) {
    return this.availableOptions.findIndex(option => option.value === value);
  }

  recalcItemCount() {
    const containerElement: HTMLElement = this.container.nativeElement;

    clearTimeout(this.recalcHandle);

    this.recalcHandle = setTimeout(() => {
      const containerRect = containerElement.getBoundingClientRect();

      // Once again, greatest hack of century - if component is inside dialog and animation has not started yet (i.e. dialog is at -9999px),
      // we have to scale all dimensions by 1 / 0.7 (transition scale start value - see dialog-container.component.scss);
      // UPDATE 12.8.2021: Not necessary anymore? (PLW-17727)
      const scaleHack = /*(containerRect.left < 0) ? 1 / 0.7 : */1;

      let firstItemTop = 0;
      let lastVisibleItemRight = 0;
      let visibleItems = 0;

      Array.from(containerElement.querySelectorAll('.item')).forEach((item, index) => {
        const itemRect = item.getBoundingClientRect();

        if (index === 0) {
          firstItemTop = itemRect.top;
        }

        if (itemRect.top === firstItemTop) {
          lastVisibleItemRight = itemRect.right - containerRect.left;
          visibleItems++;
        }
      });

      this.invisibleItemCount = this.selectedOptions.length - visibleItems;
      this.invisibleItemCountOffset = lastVisibleItemRight * scaleHack;

      this.changeDetectorRef.detectChanges();

      this.container.nativeElement.scrollTop = 0;
    }, 0);
  }

  isExternalFilter() {
    return this.optionsRequest.observers.length !== 0;
  }

  trackByOptionValue(index: number, option: PplMultipleAutocompleteOption) {
    return option.value;
  }

  onItemClick(value: string, event: MouseEvent) {
    const eventTarget = event.target as Element;

    if (!!this.optionClick.observers.length && !eventTarget.matches('.item-remove')) {
      event.stopPropagation();

      this.optionClick.emit(value);
    }
  }

  processClipboardData(clipboardData: string) {
    if (clipboardData && this.freeValue) {
      const text = clipboardData.replace(/[\r\n]+/g, ',');
      const nextValue = [...this.value];

      text.split(/[\t ,;]/).filter(Boolean).forEach(token => {
        if (this.maxValues !== undefined && nextValue.length >= this.maxValues) {
          return;
        }

        if (!nextValue.includes(token) && (!this.freeValueValidator || this.freeValueValidator(token))) {
          nextValue.push(token);
        }
      });

      this.optionsPopover.hide();
      this.valueChange.emit(nextValue);

      this.changeDetectorRef.detectChanges();
    }
  }

  focusInput(showOptions = true) {
    (this.filterInput.nativeElement as HTMLElement)?.focus();

    if (showOptions) {
      this.optionsPopover.show();
    }
  }
}

// Canvas for text size measurements
const canvas = document.createElement('canvas');
let ctx: CanvasRenderingContext2D = null;

if (canvas && canvas.getContext) {
  ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = `13px ${getComputedStyle(document.body).fontFamily}`;
  }
}

const MIN_FILTER_INPUT_SIZE = 64;

export interface PplMultipleAutocompleteOption {
  categoryId?: string;
  color?: string;
  data?: any;
  deleted?: boolean;
  icon?: string; // One of icons from ppl-icon repository
  label: string;
  value: string;
}

export interface PplMultipleAutocompleteCategory {
  id: string;
  label: string;
  data?: any;
}

export interface PplMultipleAutocompleteOptionsRequest {
  filter: string;
  lastOptionValue: string | null;
  lastOptionData?: any;
}
