export function arrayEquals(a: any[], b: any[]) {
  if (a === b) {
    return true;
  }

  if (a == null || b == null) {
    return false;
  }

  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}

export function arrayToggle<T>(array: T[], value: T) {
  const index = array.indexOf(value);

  if (index !== -1) {
    return [...array.slice(0, index), ...array.slice(index + 1)];
  } else {
    return [...array, value];
  }
}

export function sortByKey<T extends any>(key: keyof T) {
  return (a: T, b: T) => (a[key] && b[key]) ? a[key].toString().localeCompare(b[key].toString()) : 1;
}

export function sortByKeyNumeric<T extends any>(key: keyof T) {
  return (a: T, b: T) => a[key] < b[key] ? -1 : 1;
}

export function sortByOrder<T, V>(valueGetter: (item: T) => V, sortOrder: V[]) {
  return (a: T, b: T) => {
    const aIndex = sortOrder.indexOf(valueGetter(a));
    const bIndex = sortOrder.indexOf(valueGetter(b));
    if (aIndex === -1 && bIndex === -1) {
      return -1;
    } else if (aIndex === -1 || bIndex === -1) {
      return aIndex === -1 ? 1 : -1;
    } else {
      return aIndex < bIndex ? -1 : 1;
    }
  };
}

export function removeArrayValue<T>(array: T[], index: number) {
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ];
}

export function updateArrayValue<T>(array: T[], index: number, mapValue: (value: T, index: number) => T) {
  return [
    ...array.slice(0, index),
    mapValue(array[index], index),
    ...array.slice(index + 1)
  ];
}

export function insertArrayValue<T>(array: T[], index: number, ...values: T[]) {
  return [
    ...array.slice(0, index),
    ...values,
    ...array.slice(index)
  ];
}

export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr.filter(value => value);
}

export function upsert(array, item, key = 'id') { // (1)
  const arrayIndex = array.findIndex(_item => _item[key] === item[key]);
  if (arrayIndex > -1) {
    array[arrayIndex] = item; { }
  } else {
    array.push(item);
  }
  return array;
}

export function findById<T extends { id: string }>(array: T[], id: string) {
  return array.find(item => item.id === id);
}

export function updateById<T extends { id: string }>(array: T[], id: string, mapValue: (item: T) => T) {
  return array.map(item => {
    if (item.id !== id) {
      return item;
    }

    return mapValue(item);
  });
}

export function createSortByPriorityFn<T>(priorities: string[], valueGetter: (item: T) => string) {
  const LRU: { [id: string]: number } = {};
  priorities.forEach((id, index) => {
    LRU[id] = index;
  });

  function comparePriorities(a: T, b: T) {
    const aValue = valueGetter(a);
    const bValue = valueGetter(b);
    if (aValue in LRU && bValue in LRU) {
      return LRU[aValue] < LRU[bValue] ? -1 : 1;
    } else {
      return aValue in LRU
        ? -1
        : bValue in LRU ? 1 : 0;
    }
  }

  return comparePriorities;
}

export function sortByPriority<T>(priorities: string[], valueGetter: (item: T) => string) {
  return createSortByPriorityFn<T>(priorities, valueGetter);
}

export function makeUniqueArray<T>(array: T[], idGetter: (item: T) => string): T[] {
  const uniqueIds = new Set<string>();
  const uniqueArray: T[] = [];

  for (const item of array) {
    const id = idGetter(item);
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id);
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
}

export function createChunks<T>(array: T[], length: number) {
  const chunks: T[][] = [];
  let i = 0;
  const n = array.length;

  while (i < n) {
    chunks.push(array.slice(i, i += length));
  }

  return chunks;
}

export function pairs<T>(array: T[], count: number): T[][] {
  return array.reduce((result, value, index) => {
    if (index % count === 0) {
      result.push(array.slice(index, index + count));
    }
    return result;
  }, []);
}

export function findLastIndex<T>(array: T[], predicate: (record: T, index: number) => boolean) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (!!predicate(array[i], i)) {
      return i;
    }
  }

  return -1;
}
