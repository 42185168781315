import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { PplResizableSizeConstraints } from '@ppl/ui/resizable';

@Component({
  selector: 'ppl-resizable-demo',
  templateUrl: './resizable-demo.component.html',
  styleUrls: ['./resizable-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResizableDemoComponent implements OnInit {
  constraints = constraints;
  size = 300;

  code = `
  <div class="r-container">
    <div class="r-sidebar"
         [style.width.px]="size">
      width: {{size}}px
    </div>
    <div class="r-handle"
         pplResizable
         [pplResizableSize]="size"
         [pplResizableSizeConstraints]="constraints"
         (pplResizableSizeChange)="size = $event"></div>
    <div class="r-content">
      content
    </div>
  </div>
  `;

  constructor() { }

  ngOnInit() {
  }
}

const constraints: PplResizableSizeConstraints = { min: 280, max: 560 };
