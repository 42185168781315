<div #headerContainer
     class="header-container">
  <div #headerScrollContainer
       class="header-scroll-container">
    <div *ngIf="selectionMode === 'multiple' && migratedColumns.length > 0"
         class="header-field header-field-first"
         [style.width.px]="CHECKBOX_FIELD_WIDTH">
      <ppl-checkbox *ngIf="displaySelectAll"
                    [value]="selection.all && selection.exclude.length === 0"
                    [disabled]="recordData.length === 0"
                    (valueChange)="onHeaderCheck()"></ppl-checkbox>
    </div>

    <div *ngIf="draggable && draggableDragFieldIcon !== null"
         class="header-field header-field-drag"
         [style.left.px]="(selectionMode === 'multiple' && migratedColumns.length > 0) ? CHECKBOX_FIELD_WIDTH : 0"></div>

    <ppl-grid-header-field *ngFor="let column of columnsWithData; trackBy: trackById"
                           [name]="column.name"
                           [hideName]="column.hideName"
                           [description]="column.description"
                           [headerTemplate]="column.headerTemplate"
                           [headerTemplateData]="column"
                           [sorted]="sort[0]?.id === column.id"
                           [sortDirection]="sort[0]?.direction"
                           [sortDisabled]="!column.sortable"
                           [resizable]="resizable"
                           [frozen]="column.frozen"
                           [searchable]="column.searchable"
                           [displayPlaceholder]="columnDragData?.passedThreshold && columnDragData.id === column.id"
                           [displaySearchIcons]="displaySearchIcons"
                           [title]="column.titleTemplate ? '' : column.name"
                           [pplPopover]="column.titleTemplate ? popoverHeaderField : undefined"
                           [pplPopoverData]="column"
                           pplPopoverEvent="hover-preserve"
                           [style.left]="column.frozen ? column.offset + 'px' : 'auto'"
                           [style.width.px]="column.width"
                           (click)="onHeaderClick(column)"
                           (mousedown)="onColumnDragStart($event, column)"
                           (resizeStart)="onColumnResizeStart($event, column.id)"
                           (resizeAuto)="onColumnResizeAuto(column.id)"></ppl-grid-header-field>

    <div *ngIf="displayColumnSelector || (actions.length > 0 || actionsTemplate)"
         class="header-field header-field-last sort-disabled"
         [style.width.px]="COLUMN_FIELD_WIDTH"></div>

    <div *ngIf="!displayColumnSelector && (actions.length === 0 && !actionsTemplate)"
         class="header-field header-field-last sort-disabled"
         [style.padding.px]="0"
         [style.width.px]="COLUMN_FIELD_WIDTH - lastColumnWidth"></div>

    <div *ngIf="displayColumnSelector && migratedColumns.length > 0"
         class="header-field-columns"
         [pplPopover]="popoverColumnList"
         pplPopoverAlignEnd
         [pplPopoverFocusFirstInput]="true"
         [pplPopoverExactCalculations]="true"
         [style.width.px]="COLUMN_FIELD_WIDTH + 1">
      <ppl-icon svgIcon="column-chooser"
                width="16px"
                height="16px"></ppl-icon>
      <ppl-icon svgIcon="rowline"
                width="8px"
                height="5px"></ppl-icon>
    </div>
  </div>

  <svg *ngIf="columnDragData?.target"
       class="drag-helper-line"
       width="10"
       height="32"
       [style.left.px]="columnDragData.targetOffset">
    <path d="M0,0 L4,2 L4,30 L0,32 L10,32 L6,30 L6,2 L10,0 Z" />
  </svg>
</div>

<ppl-virtual-scroll #dataContainer
                    [data]="data"
                    [dataTemplate]="record"
                    [dynamicHeight]="dynamicHeight"
                    [minRowHeight]="rowHeight"
                    [scrollToIndex]="scrollToIndex"
                    [enableHorizontalScroll]="true"
                    [scrollWidth]="scrollWidth"
                    [style.background-image]="dataBackground"
                    (scroll)="onDataContainerScroll()"
                    (virtualScreenChange)="virtualScreenChange.emit($event)"></ppl-virtual-scroll>

<ppl-grid-footer *ngFor="let footerItem of footerItems; trackBy: trackById"
                 [columnsWithData]="columnsWithData"
                 [name]="footerItem.name"
                 [record]="footerItem.data"
                 [scrollLeft]="dataContainerScrollLeft"></ppl-grid-footer>

<div *ngIf="rowDragData?.target"
     class="row-drag-data-target-helper"
     [ngStyle]="rowDragDataTargetHelperStyle"></div>

<ppl-portal [zIndex]="1001">
  <div *ngIf="rowDragData?.passedThreshold"
       class="row-drag-data-source-helper"
       [ngStyle]="rowDragDataSourceHelperStyle">
    <ng-container *ngIf="rowDragData.source.kind === GridRowOrderChangeKind.Record">
      {rowDragData.source.ids.length, plural, =1 {Move 1 item} other {Move {{rowDragData.source.ids.length}} items}}
    </ng-container>

    <ng-container *ngIf="rowDragData.source.kind === GridRowOrderChangeKind.Section">
      <ng-container *ngIf="draggableMoveSectionLabel; else defaultMoveSectionLabel">
        {{ draggableMoveSectionLabel }}
      </ng-container>

      <ng-template #defaultMoveSectionLabel
                   i18n="@@Move_section">
        Move section
      </ng-template>
    </ng-container>
  </div>
</ppl-portal>

<ng-template #record
             let-record
             let-style="style"
             let-odd="odd"
             let-index="index">
  <ppl-grid-record *ngIf="record && (!record._meta || (!record._meta.isSection && !record._meta.loading && !record._meta.unavailable))"
                   [ngStyle]="style"
                   [columnsWithData]="columnsWithData"
                   [record]="record"
                   [hoverDisabled]="!!rowDragData && rowDragData.passedThreshold"
                   [selected]="isRecordSelected(record)"
                   [alternative]="((!record._meta || record._meta.alternative === undefined) && odd) || record._meta?.alternative"
                   [invalid]="record._meta && record._meta.invalid"
                   [displayCheckbox]="!record._meta || !record._meta.selectionDisabled"
                   [displayCheckboxField]="displayCheckboxField"
                   [displayDragField]="displayDragField"
                   [displayLastField]="displayLastField"
                   [lastFieldHasActions]="lastFieldHasActions"
                   [dragFieldIcon]="draggableDragFieldIcon"
                   [actionsTemplate]="actionsTemplate"
                   [actions]="actions"
                   [singleAction]="singleAction"
                   [dragColumnId]="columnDragData?.passedThreshold ? columnDragData.id : null"
                   [columnToolsTemplate]="columnToolsTemplate"
                   [checkboxFieldWidth]="CHECKBOX_FIELD_WIDTH"
                   [dragFieldWidth]="DRAG_FIELD_WIDTH"
                   [lastColumnWidth]="lastColumnWidth"
                   [startDividerVisible]="startDividerVisible"
                   [lastDividerVisible]="lastDividerVisible"
                   [horizontalScroll]="horizontalScroll"
                   (click)="onRecordClick(record)"
                   (dblclick)="onRecordDoubleClick(record)"
                   (mousedown)="onRecordMouseDown(record, $event)"
                   (actionClick)="actionClick.emit($event)"
                   (dragStart)="onRowDragStart(record, $event)"
                   (recordCheck)="onRecordCheck(record)"></ppl-grid-record>

  <div *ngIf="record && record._meta && record._meta.loading"
       class="record-loading"
       [ngStyle]="getFixedRecordStyle(style)">
    <ppl-loading-overlay></ppl-loading-overlay>
  </div>

  <div *ngIf="record && record._meta && record._meta.isSection"
       class="record-section"
       [ngStyle]="getFixedRecordStyle(style)"
       (mousedown)="onSectionMouseDown(record, $event)">
    <ng-container *ngTemplateOutlet="sectionTemplate; context: {
        $implicit: record,
        checkboxFieldWidth: CHECKBOX_FIELD_WIDTH,
        columnsWithData: columnsWithData,
        dataContainerScrollLeft: dataContainerScrollLeft,
        lastColumnWidth: lastColumnWidth
    }"></ng-container>
  </div>

  <div *ngIf="record && record._meta && record._meta.unavailable"
       class="record-unavailable"
       [ngStyle]="getFixedRecordStyle(style)">
    Unavailable
  </div>
</ng-template>

<div *ngIf="columnDragData?.passedThreshold"
     class="drag-helper-box"
     [style.left.px]="columnDragData.helperOffset"></div>

<ng-template #popoverHeaderField
             let-column="data">
  <ppl-popover-tooltip>
    {{column.name}}
    <ng-template *ngIf="column.titleTemplate"
                  [ngTemplateOutlet]="column.titleTemplate"
                  [ngTemplateOutletContext]="{ '\$implicit': column }"></ng-template>
  </ppl-popover-tooltip>
</ng-template>

<ng-template #popoverColumnList>
  <ppl-grid-select-columns [allColumns]="availableColumns"
                           [selectedColumns]="migratedColumns"
                           [maxVisibleColumns]="MAX_VISIBLE_COLUMNS"
                           [columnsMetaTemplate]="columnsMetaTemplate"
                           [columnsSource]="columnsSource"
                           [categories]="availableColumnCategories"
                           (columnToggle)="onColumnToggle($event)"
                           (frozenToggle)="onColumnFrozenToggle($event)"
                           (widthResetClick)="onWidthResetClick($event)"></ppl-grid-select-columns>
</ng-template>
