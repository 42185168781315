<ppl-dialog [formGroup]="form"
            width="300px"
            [title]="insertFromUrl"
            (action)="onAction()">
  <ppl-form-row>
    <ppl-form-column>
      <ppl-input-container label="Image URL"
                           i18n-label="@@Image_URL"
                           [pplFormControl]="form.get('url')">
        <input type="text"
               pplInput
               formControlName="url">
      </ppl-input-container>
    </ppl-form-column>
  </ppl-form-row>
</ppl-dialog>
