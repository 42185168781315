export const TranslationsFields = {
  Account_related_contact_count: $localize`:@@Account_related_contact_count:No. of related %Contact_p%`,
  Account_Classification: $localize`:@@Account_Classification:%Account_s% classification`,
  Account_related_oppty_lost: $localize`:@@Account_related_oppty_lost:Lost %Opportunity_p%`,
  Account_related_oppty_open: $localize`:@@Account_related_oppty_open:Open %Opportunity_p%`,
  Account_kpi: $localize`:@@Account_kpi:KPI`,
  Account_related_activity_in_progress: $localize`:@@Account_related_activity_in_progress:No. of related in progress activities`,
  Account_related_activity_not_started: $localize`:@@Account_related_activity_not_started:No. of related not started activities`,
  Account_email2: $localize`:@@Account_email2:Secondary E-mail`,
  Account_email3: $localize`:@@Account_email3:E-mail 3`,
  Account_parent_account: $localize`:@@Account_parent_account:Parent %Account_s%`,
  Account_customer_type_id: $localize`:@@Account_customer_type_id:Customer type`,
  Account_related_activity_overdue: $localize`:@@Account_related_activity_overdue:No. of related overdue activities`,
  Account_name: $localize`:@@Account_name:%Account_s% name`,
  Account_parent_unit_id: $localize`:@@Account_parent_unit_id:Parent unit`,
  Account_notes: $localize`:@@Account_notes:Notes`,
  Account_tags: $localize`:@@Account_tags:%Account_s% Tags`,
  Account_Tags: $localize`:@@Account_Tags:%Account_s% Tags`,
  Account_sharing: $localize`:@@Account_sharing:Sharing`,
  Account_related_activity_deferred: $localize`:@@Account_related_activity_deferred:No. of related deferred activities`,
  Account_account_id: $localize`:@@Account_account_id:%Account_s% ID`,
  Account_account_class: $localize`:@@Account_account_class:%Account_s% Class`,
  Account_phone2: $localize`:@@Account_phone2:Secondary phone`,
  Account_home_page: $localize`:@@Account_home_page:Home page`,
  Account_watchers: $localize`:@@Account_watchers:Watchers`,
  Account_industries_id: $localize`:@@Account_industries_id:Industries`,
  Account_comments: $localize`:@@Account_comments:Comments`,
  Account_comment: $localize`:@@Account_comment:Comment`,
  Account_is_assistant: $localize`:@@Account_is_assistant:Is assistant`,
  Account_parent_contact_id: $localize`:@@Account_parent_contact_id:Parent %Contact_s%`,
  Account_position: $localize`:@@Account_position:Job Title`,
  Account_owner_id: $localize`:@@Account_owner_id:Owner`,
  Account_Owner: $localize`:@@Account_Owner:Owner`,
  Account_quick_parent_account_name: $localize`:@@Account_quick_parent_account_name:Quick parent %Account_s% name`,
  Account_documents: $localize`:@@Account_documents:Documents`,
  Account_address: $localize`:@@Account_address:Street address`,
  Account_email5: $localize`:@@Account_email5:E-mail 5`,
  Account_created: $localize`:@@Account_created:%Account_s% created date`,
  Account_phone5: $localize`:@@Account_phone5:Home phone`,
  Account_is_primary: $localize`:@@Account_is_primary:Is primary`,
  Account_lead_oppty_id: $localize`:@@Account_lead_oppty_id:%Lead_s% %Opportunity_s% ID`,
  Account_related_activity_waiting: $localize`:@@Account_related_activity_waiting:No. of related waiting activities`,
  Account_parent_account_card: $localize`:@@Account_parent_account_card:Parent %Account_s%`,
  Account_related_activity_finished: $localize`:@@Account_related_activity_finished:No. of related completed activities`,
  Account_sharing_units: $localize`:@@Account_sharing_units:Units`,
  Account_has_scheduled_activity: $localize`:@@Account_has_scheduled_activity:Has scheduled activity`,
  Account_picture_id: $localize`:@@Account_picture_id:%Account_s% picture ID`,
  Account_email4: $localize`:@@Account_email4:E-mail 4`,
  Account_unit_id: $localize`:@@Account_unit_id:Sales unit`,
  Account_created_by: $localize`:@@Account_created_by:%Account_s% created by`,
  Account_phone3: $localize`:@@Account_phone3:Fax`,
  Account_state_province: $localize`:@@Account_state_province:State / Province`,
  Account_city: $localize`:@@Account_city:City`,
  Account_sharing_clients: $localize`:@@Account_sharing_clients:Users`,
  Account_country: $localize`:@@Account_country:Country`,
  Account_modified: $localize`:@@Account_modified:Modified`,
  Account_modified_by_user: $localize`:@@Account_modified_by_user:Modified Date By User`,
  Account_phone1: $localize`:@@Account_phone1:Primary phone`,
  Account_share_actor: $localize`:@@Account_share_actor:My %Account_s% role`,
  Account_account_type_id: $localize`:@@Account_account_type_id:%Account_s% type`,
  Account_email1: $localize`:@@Account_email1:Primary E-mail`,
  Account_is_favorite: $localize`:@@Account_is_favorite:Favorite`,
  Account_email33: $localize`:@@Account_email33:E-mail 3`,
  Account_editors: $localize`:@@Account_editors:Editors`,
  Account_share_mode: $localize`:@@Account_share_mode:%Account_s% share mode`,
  Account_zip_code: $localize`:@@Account_zip_code:ZIP Code`,
  Account_phone4: $localize`:@@Account_phone4:Mobile`,
  Account_parent_account_relation_type_id: $localize`:@@Account_parent_account_relation_type_id:Parent %Account_s% relation`,
  Account_related_oppty_won: $localize`:@@Account_related_oppty_won:No. of related won %Opportunity_p%`,
  Account_last_contacted_date: $localize`:@@Account_last_contacted_date:%Account_s% last contacted date`,
  Account_related_editors: $localize`:@@Account_related_editors:%Account_s% related editors`,
  Account_related_watchers: $localize`:@@Account_related_watchers:%Account_s% related watchers`,
  Account_is_unsubscribed: $localize`:@@Account_is_unsubscribed:Unsubscribed`,
  Account_social_media: $localize`:@@Account_social_media:Social Media`,
  Account_automatizer_created_by_process: $localize`:@@Account_automatizer_created_by_process:Created by process`,
  Account_automatizer_process_triggered_date: $localize`:@@Account_automatizer_process_triggered_date:Process triggered date`,
  Account_automatizer_process_with_scheduled_action: $localize`:@@Account_automatizer_process_with_scheduled_action:Process with scheduled action`,
  Account_automatizer_process_with_scheduled_action_date: $localize`:@@Account_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  Account_automatizer_triggered_by_actor: $localize`:@@Account_automatizer_triggered_by_actor:Triggered by actor`,
  Account_automatizer_triggered_by_process: $localize`:@@Account_automatizer_triggered_by_process:Triggered by process`,
  Account_automatizer_updated_by_process: $localize`:@@Account_automatizer_updated_by_process:Updated by process`,
  Account_record_url: $localize`:@@Account_record_url:Record URL`,
  Account_lifetime_value: $localize`:@@Account_lifetime_value:Won Value`,
  Account_location: $localize`:@@Account_location:Location`,
  Account_health_category: $localize`:@@Account_health_category:%Account_s% health category`,
  Account_health_status: $localize`:@@Account_health_status:%Account_s% health`,
  Account_email_sequence_enrolled_in_sequence: $localize`:@@Account_email_sequence_enrolled_in_sequence:Enrolled in sequence`,
  Account_email_sequence_currently_enrolled_in_sequence: $localize`:@@Account_email_sequence_currently_enrolled_in_sequence:Currently enrolled`,
  Account_email_sequence_last_enrollment_date: $localize`:@@Account_email_sequence_last_enrollment_date:Last enrollment date`,
  Account_email_sequence_enrolled_by_actor: $localize`:@@Account_email_sequence_enrolled_by_actor:Enrolled by (actor)`,
  Contact_watchers: $localize`:@@Contact_watchers:Watchers`,
  Contact_email1: $localize`:@@Contact_email1:Primary E-mail`,
  Contact_position: $localize`:@@Contact_position:Quick %Account_s% position`,
  Contact_title: $localize`:@@Contact_title:Title/Salutation`,
  Contact_documents: $localize`:@@Contact_documents:Documents`,
  Contact_phone5: $localize`:@@Contact_phone5:Home phone`,
  Contact_email4: $localize`:@@Contact_email4:E-mail 4`,
  Contact_related_activity_in_progress: $localize`:@@Contact_related_activity_in_progress:No. of related in progress activities`,
  Contact_related_activity_overdue: $localize`:@@Contact_related_activity_overdue:No. of related overdue activities`,
  Contact_related_oppty_open: $localize`:@@Contact_related_oppty_open:No. of related open %Opportunity_p%`,
  Contact_share_mode: $localize`:@@Contact_share_mode:%Contact_s% share mode`,
  Contact_created_by: $localize`:@@Contact_created_by:%Contact_s% created by`,
  Contact_picture_id: $localize`:@@Contact_picture_id:%Contact_s% picture ID`,
  Contact_comments: $localize`:@@Contact_comments:Comments`,
  Contact_email5: $localize`:@@Contact_email5:E-mail 5`,
  Contact_share_actor: $localize`:@@Contact_share_actor:My %Contact_s% role`,
  Contact_state_province: $localize`:@@Contact_state_province:State/province`,
  Contact_related_account: $localize`:@@Contact_related_account:Primary %Account_s% name`,
  Contact_phone2: $localize`:@@Contact_phone2:Secondary Phone`,
  Contact_phone3: $localize`:@@Contact_phone3:Fax`,
  Contact_related_activity_waiting: $localize`:@@Contact_related_activity_waiting:No. of related waiting activities`,
  Contact_related_oppty_lost: $localize`:@@Contact_related_oppty_lost:No. of related lost %Opportunity_p%`,
  Contact_related_activity_deferred: $localize`:@@Contact_related_activity_deferred:No. of related deferred activities`,
  Contact_parent_unit_id: $localize`:@@Contact_parent_unit_id:Parent unit`,
  Contact_sharing_units: $localize`:@@Contact_sharing_units:Units`,
  Contact_email3: $localize`:@@Contact_email3:E-mail 3`,
  Contact_middle_name: $localize`:@@Contact_middle_name:Middle name`,
  Contact_related_oppty_won: $localize`:@@Contact_related_oppty_won:No. of related won %Opportunity_p%`,
  Contact_is_favorite: $localize`:@@Contact_is_favorite:Favorite`,
  Contact_country: $localize`:@@Contact_country:Country`,
  Contact_email2: $localize`:@@Contact_email2:Secondary E-mail`,
  Contact_phone4: $localize`:@@Contact_phone4:Mobile`,
  Contact_sharing_clients: $localize`:@@Contact_sharing_clients:Users`,
  Contact_zip_code: $localize`:@@Contact_zip_code:ZIP Code`,
  Contact_unit_id: $localize`:@@Contact_unit_id:Sales unit`,
  Contact_kpi: $localize`:@@Contact_kpi:KPI`,
  Contact_created: $localize`:@@Contact_created:%Contact_s% created date`,
  Contact_gender: $localize`:@@Contact_gender:Gender`,
  Contact_related_activity_not_started: $localize`:@@Contact_related_activity_not_started:No. of related not started activities`,
  Contact_address: $localize`:@@Contact_address:Street address`,
  Contact_first_name: $localize`:@@Contact_first_name:First name`,
  Contact_contact_type_id: $localize`:@@Contact_contact_type_id:%Contact_s% type`,
  Contact_modified: $localize`:@@Contact_modified:Modified`,
  Contact_modified_by_user: $localize`:@@Contact_modified_by_user:Modified Date By User`,
  Contact_related_activity_finished: $localize`:@@Contact_related_activity_finished:No. of related completed activities`,
  Contact_editors: $localize`:@@Contact_editors:Editors`,
  Contact_city: $localize`:@@Contact_city:City`,
  Contact_owner_id: $localize`:@@Contact_owner_id:Owner`,
  Contact_Owner: $localize`:@@Contact_Owner:Owner`,
  Contact_quick_account_name: $localize`:@@Contact_quick_account_name:Quick %Account_s% name`,
  Contact_account_id: $localize`:@@Contact_account_id:%Account_s% name`,
  Contact_sharing: $localize`:@@Contact_sharing:Sharing`,
  Contact_name: $localize`:@@Contact_name:Name`,
  Contact_phone1: $localize`:@@Contact_phone1:Primary phone`,
  Contact_last_name: $localize`:@@Contact_last_name:Last name`,
  Contact_contact_id: $localize`:@@Contact_contact_id:%Contact_s% ID`,
  Contact_has_scheduled_activity: $localize`:@@Contact_has_scheduled_activity:Has scheduled activities`,
  Contact_notes: $localize`:@@Contact_notes:Notes`,
  Contact_tags: $localize`:@@Contact_tags:%Contact_s% Tags`,
  Contact_Tags: $localize`:@@Contact_Tags:%Contact_s% Tags`,
  Contact_accounts: $localize`:@@Contact_accounts:%Contact_s% %Account_p%`,
  Contact_last_contacted_date: $localize`:@@Contact_last_contacted_date:%Contact_s% last contacted date`,
  Contact_related_editors: $localize`:@@Contact_related_editors:%Contact_s% related editors`,
  Contact_related_watchers: $localize`:@@Contact_related_watchers:%Contact_s% related watchers`,
  Contact_related_accounts: $localize`:@@Contact_related_accounts:%Contact_s% related %Account_p%`,
  Contact_related_account_position: $localize`:@@Contact_related_account_position:Primary %Account_s% Job Position`,
  Contact_is_unsubscribed: $localize`:@@Contact_is_unsubscribed:Unsubscribed`,
  Contact_automatizer_created_by_process: $localize`:@@Contact_automatizer_created_by_process:Created by process`,
  Contact_automatizer_process_triggered_date: $localize`:@@Contact_automatizer_process_triggered_date:Process triggered date`,
  Contact_automatizer_process_with_scheduled_action: $localize`:@@Contact_automatizer_process_with_scheduled_action:Process with scheduled action`,
  Contact_automatizer_process_with_scheduled_action_date: $localize`:@@Contact_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  Contact_automatizer_triggered_by_actor: $localize`:@@Contact_automatizer_triggered_by_actor:Triggered by actor`,
  Contact_automatizer_triggered_by_process: $localize`:@@Contact_automatizer_triggered_by_process:Triggered by process`,
  Contact_automatizer_updated_by_process: $localize`:@@Contact_automatizer_updated_by_process:Updated by process`,
  Contact_record_url: $localize`:@@Contact_record_url:Record URL`,
  Contact_location: $localize`:@@Contact_location:Location`,
  Contact_social_media: $localize`:@@Contact_social_media:Social Media`,
  Contact_primary_account_account_roles: $localize`:@@Contact_primary_account_account_roles:Primary %Account_s% Roles`,
  Contact_primary_account_relationship: $localize`:@@Contact_primary_account_relationship:Primary %Account_s% Relationship`,
  Contact_email_sequence_enrolled_in_sequence: $localize`:@@Contact_email_sequence_enrolled_in_sequence:Enrolled in sequence`,
  Contact_email_sequence_currently_enrolled_in_sequence: $localize`:@@Contact_email_sequence_currently_enrolled_in_sequence:Currently enrolled`,
  Contact_email_sequence_last_enrollment_date: $localize`:@@Contact_email_sequence_last_enrollment_date:Last enrollment date`,
  Contact_email_sequence_enrolled_by_actor: $localize`:@@Contact_email_sequence_enrolled_by_actor:Enrolled by (actor)`,
  Lead_share_actor: $localize`:@@Lead_share_actor:My %Lead_s% role`,
  Lead_related_activity_deferred: $localize`:@@Lead_related_activity_deferred:No. of related deferred activities`,
  Lead_lead_id: $localize`:@@Lead_lead_id:%Lead_s% ID`,
  Lead_lost_date: $localize`:@@Lead_lost_date:Lost date`,
  Lead_modified: $localize`:@@Lead_modified:Modified`,
  Lead_modified_by_user: $localize`:@@Lead_modified_by_user:Modified Date By User`,
  Lead_label_flag: $localize`:@@Lead_label_flag:Label`,
  Lead_owner_id: $localize`:@@Lead_owner_id:Owner`,
  Lead_Owner: $localize`:@@Lead_Owner:Owner`,
  Lead_quick_account_email: $localize`:@@Lead_quick_account_email:Quick %Account_s% e-mail`,
  Lead_unit_id: $localize`:@@Lead_unit_id:Sales Unit`,
  Lead_is_favorite: $localize`:@@Lead_is_favorite:Favorite`,
  Lead_is_archived: $localize`:@@Lead_is_archived:Archived`,
  Lead_account_card: $localize`:@@Lead_account_card:Primary %Account_s% name`,
  Lead_created: $localize`:@@Lead_created:%Lead_s% created date`,
  Lead_watchers: $localize`:@@Lead_watchers:Watchers`,
  Lead_lead_type_id: $localize`:@@Lead_lead_type_id:%Lead_s% type`,
  Lead_share_mode: $localize`:@@Lead_share_mode:%Lead_s% share mode`,
  Lead_has_scheduled_activity: $localize`:@@Lead_has_scheduled_activity:Has scheduled activity`,
  Lead_quick_email: $localize`:@@Lead_quick_email:Quick %Contact_s% e-mail`,
  Lead_fitness: $localize`:@@Lead_fitness:Fitness`,
  Lead_fitness_indicators: $localize`:@@Lead_fitness_indicators:%Lead_s% Fitness Indicators`,
  Lead_related_activity_waiting: $localize`:@@Lead_related_activity_waiting:No. of related waiting activities`,
  Lead_ranking: $localize`:@@Lead_ranking:Ranking`,
  Lead_sharing: $localize`:@@Lead_sharing:Sharing`,
  Lead_contact: $localize`:@@Lead_contact:%Contact_s%`,
  Lead_quick_account_name: $localize`:@@Lead_quick_account_name:Quick %Account_s% name`,
  Lead_kpi: $localize`:@@Lead_kpi:KPI`,
  Lead_parent_unit_id: $localize`:@@Lead_parent_unit_id:Parent unit`,
  Lead_sharing_clients: $localize`:@@Lead_sharing_clients:Users`,
  Lead_description: $localize`:@@Lead_description:Description`,
  Lead_days_in_queue: $localize`:@@Lead_days_in_queue:Days in queue`,
  Lead_sharing_units: $localize`:@@Lead_sharing_units:Units`,
  Lead_account: $localize`:@@Lead_account:%Account_s% name`,
  Lead_qualified_at: $localize`:@@Lead_qualified_at:Qualified date`,
  Lead_related_activity_overdue: $localize`:@@Lead_related_activity_overdue:No. of related overdue activities`,
  Lead_name: $localize`:@@Lead_name:%Lead_s% name`,
  Lead_related_activity_finished: $localize`:@@Lead_related_activity_finished:No. of related completed activities`,
  Lead_related_activity_not_started: $localize`:@@Lead_related_activity_not_started:No. of related not started activities`,
  Lead_quick_account_phone: $localize`:@@Lead_quick_account_phone:Quick %Account_s% phone`,
  Lead_status: $localize`:@@Lead_status:%Lead_s% status`,
  Lead_lead_status: $localize`:@@Lead_lead_status:%Lead_s% status`,
  Lead_editors: $localize`:@@Lead_editors:Editors`,
  Lead_related_activity_in_progress: $localize`:@@Lead_related_activity_in_progress:No. of related in progress activities`,
  Lead_contact_card: $localize`:@@Lead_contact_card:Primary %Contact_s% name`,
  Lead_created_by: $localize`:@@Lead_created_by:%Lead_s% created by`,
  Lead_qualified_by: $localize`:@@Lead_qualified_by:Qualified by`,
  Lead_documents: $localize`:@@Lead_documents:Documents`,
  Lead_quick_contact_name: $localize`:@@Lead_quick_contact_name:Quick %Contact_s% name`,
  Lead_quick_phone: $localize`:@@Lead_quick_phone:Quick %Contact_s% phone`,
  Lead_ready_to_move: $localize`:@@Lead_ready_to_move:Ready to move`,
  Lead_age_metadata: $localize`:@@Lead_age_metadata:%Lead_s% age`,
  Lead_notes: $localize`:@@Lead_notes:Notes`,
  Lead_tags: $localize`:@@Lead_tags:%Lead_s% Tags`,
  Lead_Tags: $localize`:@@Lead_Tags:%Lead_s% Tags`,
  Lead_related_account_email: $localize`:@@Lead_related_account_email:Related %Account_s% e-mail`,
  Lead_related_account_phone: $localize`:@@Lead_related_account_phone:Related %Account_s% phone`,
  Lead_related_contact_email: $localize`:@@Lead_related_contact_email:Related %Contact_s% e-mail`,
  Lead_related_contact_phone: $localize`:@@Lead_related_contact_phone:Related %Contact_s% phone`,
  Lead_related_editors: $localize`:@@Lead_related_editors:%Lead_s% related editors`,
  Lead_related_watchers: $localize`:@@Lead_related_watchers:%Lead_s% related watchers`,
  Lead_related_accounts: $localize`:@@Lead_related_accounts:%Lead_s% related %Account_p%`,
  Lead_related_contacts: $localize`:@@Lead_related_contacts:%Lead_s% related %Contact_p%`,
  Lead_automatizer_created_by_process: $localize`:@@Lead_automatizer_created_by_process:Created by process`,
  Lead_automatizer_process_triggered_date: $localize`:@@Lead_automatizer_process_triggered_date:Process triggered date`,
  Lead_automatizer_process_with_scheduled_action: $localize`:@@Lead_automatizer_process_with_scheduled_action:Process with scheduled action`,
  Lead_automatizer_process_with_scheduled_action_date: $localize`:@@Lead_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  Lead_automatizer_triggered_by_actor: $localize`:@@Lead_automatizer_triggered_by_actor:Triggered by actor`,
  Lead_automatizer_triggered_by_process: $localize`:@@Lead_automatizer_triggered_by_process:Triggered by process`,
  Lead_automatizer_updated_by_process: $localize`:@@Lead_automatizer_updated_by_process:Updated by process`,
  Lead_record_url: $localize`:@@Lead_record_url:Record URL`,
  Lead_sales_step_activities: $localize`:@@Lead_sales_step_activities:%Lead_s% Step Activities`,
  Lead_sales_cycle_length: $localize`:@@Lead_sales_cycle_length:Sales Cycle length`,
  Lead_email_sequence_enrolled_in_sequence: $localize`:@@Lead_email_sequence_enrolled_in_sequence:Enrolled in sequence`,
  Lead_email_sequence_currently_enrolled_in_sequence: $localize`:@@Lead_email_sequence_currently_enrolled_in_sequence:Currently enrolled`,
  Lead_email_sequence_last_enrollment_date: $localize`:@@Lead_email_sequence_last_enrollment_date:Last enrollment date`,
  Lead_email_sequence_enrolled_by_actor: $localize`:@@Lead_email_sequence_enrolled_by_actor:Enrolled by (actor)`,
  Lead_reason_of_close_id: $localize`:@@Lead_reason_of_close_id:%Lead_s% Lost reason`,
  Lead_reason_of_close_description: $localize`:@@Lead_reason_of_close_description:Lost reason description`,
  Lead_lead_process: $localize`:@@Lead_lead_process:%Lead_s% process`,
  Lead_velocity: $localize`:@@Lead_velocity:%Lead_s% velocity`,
  Lead_step_id: $localize`:@@Lead_step_id:%Lead_s% step`,
  Lead_scoring_category: $localize`:@@Lead_scoring_category:%Lead_s% score category`,
  Lead_scoring_status: $localize`:@@Lead_scoring_status:%Lead_s% score`,
  Opportunity_active_quote_id: $localize`:@@Opportunity_active_quote_id:Active %Quote_s%`,
  Opportunity_contact_card: $localize`:@@Opportunity_contact_card:Primary %Contact_s% name`,
  Opportunity_ContactCard: $localize`:@@Opportunity_ContactCard:%Contact_s% Card`,
  Opportunity_AccountCard: $localize`:@@Opportunity_AccountCard:%Account_s% Card`,
  Opportunity_fitness: $localize`:@@Opportunity_fitness:Fitness`,
  Opportunity_fitness_indicators: $localize`:@@Opportunity_fitness_indicators:%Opportunity_s% Fitness Indicators`,
  Opportunity_lost_won_by: $localize`:@@Opportunity_lost_won_by:Lost/won by`,
  Opportunity_lost_date: $localize`:@@Opportunity_lost_date:Lost date`,
  Opportunity_sharing_clients: $localize`:@@Opportunity_sharing_clients:Users`,
  Opportunity_target_count: $localize`:@@Opportunity_target_count:%Opportunity_s% count`,
  Opportunity_has_scheduled_activity: $localize`:@@Opportunity_has_scheduled_activity:Has scheduled activity`,
  Opportunity_product_difference: $localize`:@@Opportunity_product_difference:Product difference (count)`,
  Opportunity_product_price_list_id: $localize`:@@Opportunity_product_price_list_id:Product price list`,
  Opportunity_oppty_type_id: $localize`:@@Opportunity_oppty_type_id:%Opportunity_s% type`,
  Opportunity_real_closing_date: $localize`:@@Opportunity_real_closing_date:Actual closing date`,
  Opportunity_ranked_value: $localize`:@@Opportunity_ranked_value:Ranked value`,
  Opportunity_step_probability: $localize`:@@Opportunity_step_probability:Sales step win probability`,
  Opportunity_quick_phone: $localize`:@@Opportunity_quick_phone:Quick %Contact_s% phone`,
  Opportunity_share_actor: $localize`:@@Opportunity_share_actor:My %Opportunity_s% role`,
  Opportunity_product_count: $localize`:@@Opportunity_product_count:Product count`,
  Opportunity_product_id: $localize`:@@Opportunity_product_id:Product`,
  Opportunity_oppty_id: $localize`:@@Opportunity_oppty_id:%Opportunity_s% ID`,
  Opportunity_ready_to_move_metadata: $localize`:@@Opportunity_ready_to_move_metadata:Ready to move`,
  Opportunity_related_activity_in_progress: $localize`:@@Opportunity_related_activity_in_progress:No. of related in progress activities`,
  Opportunity_opportunity_value_foreign: $localize`:@@Opportunity_opportunity_value_foreign:%Opportunity_s% value`,
  Opportunity_sharing_units: $localize`:@@Opportunity_sharing_units:Units`,
  Opportunity_status: $localize`:@@Opportunity_status:%Opportunity_s% status`,
  Opportunity_oppty_status: $localize`:@@Opportunity_oppty_status:%Opportunity_s% status`,
  Opportunity_account: $localize`:@@Opportunity_account:Primary %Account_s% name`,
  Opportunity_product_difference_percent: $localize`:@@Opportunity_product_difference_percent:Product difference (%)`,
  Opportunity_products: $localize`:@@Opportunity_products:Products`,
  Opportunity_contact: $localize`:@@Opportunity_contact:Primary %Contact_s% name`,
  Opportunity_sharing: $localize`:@@Opportunity_sharing:Sharing`,
  Opportunity_product_currency_id: $localize`:@@Opportunity_product_currency_id:Product currency`,
  Opportunity_label_flag: $localize`:@@Opportunity_label_flag:Label`,
  Opportunity_quick_account_phone: $localize`:@@Opportunity_quick_account_phone:Quick %Account_s% phone`,
  Opportunity_product_value_sum: $localize`:@@Opportunity_product_value_sum:Product Total Amount`,
  Opportunity_sales_role_group: $localize`:@@Opportunity_sales_role_group:Sales roles`,
  Opportunity_weighted_value: $localize`:@@Opportunity_weighted_value:Weighted value`,
  Opportunity_created: $localize`:@@Opportunity_created:%Opportunity_s% created date`,
  Opportunity_reason_of_close_description: $localize`:@@Opportunity_reason_of_close_description:Lost reason description`,
  Opportunity_reason_of_close_id: $localize`:@@Opportunity_reason_of_close_id:Lost reason`,
  Opportunity_owner_id: $localize`:@@Opportunity_owner_id:Owner`,
  Opportunity_Owner: $localize`:@@Opportunity_Owner:Owner`,
  Opportunity_real_value: $localize`:@@Opportunity_real_value:Won value`,
  Opportunity_quick_contact_name: $localize`:@@Opportunity_quick_contact_name:Quick %Contact_s% name`,
  Opportunity_oppty_lead_created_by: $localize`:@@Opportunity_oppty_lead_created_by:%Opportunity_s% created by`,
  Opportunity_was_qualified: $localize`:@@Opportunity_was_qualified:Was qualified`,
  Opportunity_account_card: $localize`:@@Opportunity_account_card:Primary %Account_s% name`,
  Opportunity_is_archived: $localize`:@@Opportunity_is_archived:Is archived`,
  Opportunity_step_id: $localize`:@@Opportunity_step_id:Sales step`,
  Opportunity_base_value: $localize`:@@Opportunity_base_value:%Opportunity_s% base value`,
  Opportunity_value_auto_calculate: $localize`:@@Opportunity_value_auto_calculate:Auto calculated %Opportunity_s% value`,
  Opportunity_parent_unit_id: $localize`:@@Opportunity_parent_unit_id:Parent unit`,
  Opportunity_related_activity_deferred: $localize`:@@Opportunity_related_activity_deferred:No. of related deferred activities`,
  Opportunity_ranking: $localize`:@@Opportunity_ranking:Ranking`,
  Opportunity_modified: $localize`:@@Opportunity_modified:Modified`,
  Opportunity_modified_by_user: $localize`:@@Opportunity_modified_by_user:Modified Date By User`,
  Opportunity_pipeline: $localize`:@@Opportunity_pipeline:Pipeline`,
  Opportunity_related_activity_waiting: $localize`:@@Opportunity_related_activity_waiting:No. of related waiting activities`,
  Opportunity_timeframe: $localize`:@@Opportunity_timeframe:Velocity`,
  Opportunity_unweighted_value: $localize`:@@Opportunity_unweighted_value:Unweighted value`,
  Opportunity_related_activity_overdue: $localize`:@@Opportunity_related_activity_overdue:No. of related overdue activities`,
  Opportunity_oppty_lead_created: $localize`:@@Opportunity_oppty_lead_created:%Lead_s% created date`,
  Opportunity_kpi: $localize`:@@Opportunity_kpi:%Opportunity_s% KPI`,
  Opportunity_related_activity_finished: $localize`:@@Opportunity_related_activity_finished:No. of related completed activities`,
  Opportunity_description: $localize`:@@Opportunity_description:%Opportunity_s% description`,
  Opportunity_notes: $localize`:@@Opportunity_notes:Notes`,
  Opportunity_tags: $localize`:@@Opportunity_tags:%Opportunity_s% Tags`,
  Opportunity_Tags: $localize`:@@Opportunity_Tags:%Opportunity_s% Tags`,
  Opportunity_unit_id: $localize`:@@Opportunity_unit_id:Sales unit`,
  Opportunity_quick_account_name: $localize`:@@Opportunity_quick_account_name:Quick %Account_s% name`,
  Opportunity_currency_id: $localize`:@@Opportunity_currency_id:Currency`,
  Opportunity_age_metadata: $localize`:@@Opportunity_age_metadata:%Opportunity_s% age`,
  Opportunity_share_mode: $localize`:@@Opportunity_share_mode:%Opportunity_s% share mode`,
  Opportunity_quick_account_email: $localize`:@@Opportunity_quick_account_email:Quick %Account_s% email`,
  Opportunity_ready_to_move: $localize`:@@Opportunity_ready_to_move:Ready to move`,
  Opportunity_created_qualified_by: $localize`:@@Opportunity_created_qualified_by:%Opportunity_s% created/qualified by`,
  Opportunity_target_contribution: $localize`:@@Opportunity_target_contribution:Target contribution`,
  Opportunity_is_favorite: $localize`:@@Opportunity_is_favorite:Favorite`,
  Opportunity_documents: $localize`:@@Opportunity_documents:Documents`,
  Opportunity_value: $localize`:@@Opportunity_value:%Opportunity_s% value`,
  Opportunity_Value: $localize`:@@Opportunity_Value:%Opportunity_s% Value`,
  Opportunity_watchers: $localize`:@@Opportunity_watchers:Watchers`,
  Opportunity_has_recurrence: $localize`:@@Opportunity_has_recurrence:Has recurrence`,
  Opportunity_time_in_sales_step: $localize`:@@Opportunity_time_in_sales_step:Days in sales step`,
  Opportunity_due_in_sales_step: $localize`:@@Opportunity_due_in_sales_step:Due in sales step`,
  Opportunity_balanced_value: $localize`:@@Opportunity_balanced_value:Balance value`,
  Opportunity_closing_date: $localize`:@@Opportunity_closing_date:Closing date`,
  Opportunity_related_activity_not_started: $localize`:@@Opportunity_related_activity_not_started:No. of related not started activities`,
  Opportunity_quick_email: $localize`:@@Opportunity_quick_email:Quick %Contact_s% E-mail`,
  Opportunity_name: $localize`:@@Opportunity_name:%Opportunity_s% name`,
  Opportunity_editors: $localize`:@@Opportunity_editors:Editors`,
  Opportunity_last_moved_datetime: $localize`:@@Opportunity_last_moved_datetime:Last move date`,
  Opportunity_previous_step_id: $localize`:@@Opportunity_previous_step_id:Previous step ID`,
  Opportunity_related_account_email: $localize`:@@Opportunity_related_account_email:Related %Account_s% e-mail`,
  Opportunity_related_account_phone: $localize`:@@Opportunity_related_account_phone:Related %Account_s% phone`,
  Opportunity_related_contact_email: $localize`:@@Opportunity_related_contact_email:Related %Contact_s% e-mail`,
  Opportunity_related_contact_phone: $localize`:@@Opportunity_related_contact_phone:Related %Contact_s% phone`,
  Opportunity_related_editors: $localize`:@@Opportunity_related_editors:%Opportunity_s% related editors`,
  Opportunity_related_watchers: $localize`:@@Opportunity_related_watchers:%Opportunity_s% related watchers`,
  Opportunity_related_accounts: $localize`:@@Opportunity_related_accounts:%Opportunity_s% related %Account_p%`,
  Opportunity_related_contacts: $localize`:@@Opportunity_related_contacts:%Opportunity_s% related %Contact_p%`,
  Opportunity_related_products: $localize`:@@Opportunity_related_products:%Opportunity_s% related products`,
  Opportunity_won_date: $localize`:@@Opportunity_won_date:Won date`,
  Opportunity_automatizer_created_by_process: $localize`:@@Opportunity_automatizer_created_by_process:Created by process`,
  Opportunity_automatizer_process_triggered_date: $localize`:@@Opportunity_automatizer_process_triggered_date:Process triggered date`,
  Opportunity_automatizer_process_with_scheduled_action: $localize`:@@Opportunity_automatizer_process_with_scheduled_action:Process with scheduled action`,
  Opportunity_automatizer_process_with_scheduled_action_date: $localize`:@@Opportunity_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  Opportunity_automatizer_triggered_by_actor: $localize`:@@Opportunity_automatizer_triggered_by_actor:Triggered by actor`,
  Opportunity_automatizer_triggered_by_process: $localize`:@@Opportunity_automatizer_triggered_by_process:Triggered by process`,
  Opportunity_automatizer_updated_by_process: $localize`:@@Opportunity_automatizer_updated_by_process:Updated by process`,
  Opportunity_record_url: $localize`:@@Opportunity_record_url:Record URL`,
  Opportunity_scheduled_revenue: $localize`:@@Opportunity_scheduled_revenue:Scheduled revenue`,
  Opportunity_scheduled_revenue_recognized: $localize`:@@Opportunity_scheduled_revenue_recognized:Scheduled revenue (recognized)`,
  Opportunity_scheduled_revenue_duration: $localize`:@@Opportunity_scheduled_revenue_duration:Scheduled revenue duration`,
  Opportunity_scheduled_revenue_target: $localize`:@@Opportunity_scheduled_revenue_target:Scheduled revenue in target period`,
  Opportunity_sales_step_activities: $localize`:@@Opportunity_sales_step_activities:Sales Step Activities`,
  Opportunity_velocity: $localize`:@@Opportunity_velocity:Velocity`,
  Opportunity_sales_cycle_length: $localize`:@@Opportunity_sales_cycle_length:Sales Cycle length`,
  Opportunity_email_sequence_enrolled_in_sequence: $localize`:@@Opportunity_email_sequence_enrolled_in_sequence:Enrolled in sequence`,
  Opportunity_email_sequence_currently_enrolled_in_sequence: $localize`:@@Opportunity_email_sequence_currently_enrolled_in_sequence:Currently enrolled`,
  Opportunity_email_sequence_last_enrollment_date: $localize`:@@Opportunity_email_sequence_last_enrollment_date:Last enrollment date`,
  Opportunity_email_sequence_enrolled_by_actor: $localize`:@@Opportunity_email_sequence_enrolled_by_actor:Enrolled by (actor)`,
  LeadOppty_contact_card: $localize`:@@LeadOppty_contact_card:Primary %Contact_s% name`,
  LeadOppty_fitness: $localize`:@@LeadOppty_fitness:Fitness`,
  LeadOppty_fitness_indicators: $localize`:@@LeadOppty_fitness_indicators:%Lead_s% & %Opportunity_s% Fitness Indicators`,
  LeadOppty_lost_won_by: $localize`:@@LeadOppty_lost_won_by:Lost/won by`,
  LeadOppty_sharing_clients: $localize`:@@LeadOppty_sharing_clients:Users`,
  LeadOppty_target_count: $localize`:@@LeadOppty_target_count:Target count`,
  LeadOppty_has_scheduled_activity: $localize`:@@LeadOppty_has_scheduled_activity:Has scheduled activity`,
  LeadOppty_product_difference: $localize`:@@LeadOppty_product_difference:Product difference (count)`,
  LeadOppty_product_price_list_id: $localize`:@@LeadOppty_product_price_list_id:Product price list`,
  LeadOppty_oppty_type_id: $localize`:@@LeadOppty_oppty_type_id:%Opportunity_s% type`,
  LeadOppty_real_closing_date: $localize`:@@LeadOppty_real_closing_date:Actual closing date`,
  LeadOppty_ranked_value: $localize`:@@LeadOppty_ranked_value:Ranked value`,
  LeadOppty_step_probability: $localize`:@@LeadOppty_step_probability:Sales step win probability`,
  LeadOppty_quick_phone: $localize`:@@LeadOppty_quick_phone:%Contact_s% quick phone`,
  LeadOppty_share_actor: $localize`:@@LeadOppty_share_actor:My role`,
  LeadOppty_product_count: $localize`:@@LeadOppty_product_count:Product count`,
  LeadOppty_product_id: $localize`:@@LeadOppty_product_id:Product`,
  LeadOppty_oppty_id: $localize`:@@LeadOppty_oppty_id:%Opportunity_s% ID`,
  LeadOppty_ready_to_move_metadata: $localize`:@@LeadOppty_ready_to_move_metadata:Ready to move`,
  LeadOppty_related_activity_in_progress: $localize`:@@LeadOppty_related_activity_in_progress:No. of related in progress activities`,
  LeadOppty_opportunity_value_foreign: $localize`:@@LeadOppty_opportunity_value_foreign:%Opportunity_s% value`,
  LeadOppty_sharing_units: $localize`:@@LeadOppty_sharing_units:Units`,
  LeadOppty_status: $localize`:@@LeadOppty_status:Status`,
  LeadOppty_qualified_by: $localize`:@@LeadOppty_qualified_by:Qualified by`,
  LeadOppty_account: $localize`:@@LeadOppty_account:%Account_s% name`,
  LeadOppty_product_difference_percent: $localize`:@@LeadOppty_product_difference_percent:Product difference (%)`,
  LeadOppty_products: $localize`:@@LeadOppty_products:Products`,
  LeadOppty_contact: $localize`:@@LeadOppty_contact:Primary %Contact_s% name`,
  LeadOppty_sharing: $localize`:@@LeadOppty_sharing:Sharing`,
  LeadOppty_product_currency_id: $localize`:@@LeadOppty_product_currency_id:Product currency`,
  LeadOppty_label_flag: $localize`:@@LeadOppty_label_flag:Label`,
  LeadOppty_quick_account_phone: $localize`:@@LeadOppty_quick_account_phone:Quick %Account_s% phone`,
  LeadOppty_product_value_sum: $localize`:@@LeadOppty_product_value_sum:Product Total Amount`,
  LeadOppty_sales_role_group: $localize`:@@LeadOppty_sales_role_group:Sales roles`,
  LeadOppty_weighted_value: $localize`:@@LeadOppty_weighted_value:Weighted value`,
  LeadOppty_created: $localize`:@@LeadOppty_created:Created date`,
  LeadOppty_CustomStepField: $localize`:@@LeadOppty_CustomStepField:Sales step field`,
  LeadOppty_reason_of_close_id: $localize`:@@LeadOppty_reason_of_close_id:Lost reason`,
  LeadOppty_reason_of_close_description: $localize`:@@LeadOppty_reason_of_close_description:Lost reason description`,
  LeadOppty_owner_id: $localize`:@@LeadOppty_owner_id:Owner`,
  LeadOppty_real_value: $localize`:@@LeadOppty_real_value:%Opportunity_s% value`,
  LeadOppty_quick_contact_name: $localize`:@@LeadOppty_quick_contact_name:Quick %Contact_s% name`,
  LeadOppty_oppty_lead_created_by: $localize`:@@LeadOppty_oppty_lead_created_by:%Lead_s% created by`,
  LeadOppty_was_qualified: $localize`:@@LeadOppty_was_qualified:Was qualified`,
  LeadOppty_account_card: $localize`:@@LeadOppty_account_card:Primary %Account_s% name`,
  LeadOppty_is_archived: $localize`:@@LeadOppty_is_archived:Is archived`,
  LeadOppty_step_id: $localize`:@@LeadOppty_step_id:Sales step`,
  LeadOppty_base_value: $localize`:@@LeadOppty_base_value:%Opportunity_s% base value`,
  LeadOppty_value_auto_calculate: $localize`:@@LeadOppty_value_auto_calculate:Auto calculated %Opportunity_s% value`,
  LeadOppty_ranking: $localize`:@@LeadOppty_ranking:Ranking`,
  LeadOppty_related_activity_deferred: $localize`:@@LeadOppty_related_activity_deferred:No. of related deferred activities`,
  LeadOppty_parent_unit_id: $localize`:@@LeadOppty_parent_unit_id:Parent unit`,
  LeadOppty_modified: $localize`:@@LeadOppty_modified:Modified`,
  LeadOppty_modified_by_user: $localize`:@@LeadOppty_modified_by_user:Modified Date By User`,
  LeadOppty_days_in_queue: $localize`:@@LeadOppty_days_in_queue:Days in queue`,
  LeadOppty_pipeline: $localize`:@@LeadOppty_pipeline:Pipeline`,
  LeadOppty_related_activity_waiting: $localize`:@@LeadOppty_related_activity_waiting:No. of related waiting activities`,
  LeadOppty_timeframe: $localize`:@@LeadOppty_timeframe:Velocity`,
  LeadOppty_unweighted_value: $localize`:@@LeadOppty_unweighted_value:Unweighted value`,
  LeadOppty_related_activity_overdue: $localize`:@@LeadOppty_related_activity_overdue:No. of related overdue activities`,
  LeadOppty_oppty_lead_created: $localize`:@@LeadOppty_oppty_lead_created:%Lead_s% created date`,
  LeadOppty_kpi: $localize`:@@LeadOppty_kpi:KPI`,
  LeadOppty_created_by: $localize`:@@LeadOppty_created_by:Created by`,
  LeadOppty_related_activity_finished: $localize`:@@LeadOppty_related_activity_finished:No. of related completed activities`,
  LeadOppty_description: $localize`:@@LeadOppty_description:Description`,
  LeadOppty_notes: $localize`:@@LeadOppty_notes:Notes`,
  LeadOppty_unit_id: $localize`:@@LeadOppty_unit_id:Sales unit`,
  LeadOppty_lead_type_id: $localize`:@@LeadOppty_lead_type_id:%Lead_s% type`,
  LeadOppty_quick_account_name: $localize`:@@LeadOppty_quick_account_name:Quick %Account_s% name`,
  LeadOppty_currency_id: $localize`:@@LeadOppty_currency_id:Currency`,
  LeadOppty_age_metadata: $localize`:@@LeadOppty_age_metadata:LeadOppty_age_metadata`,
  LeadOppty_share_mode: $localize`:@@LeadOppty_share_mode:Share mode`,
  LeadOppty_quick_account_email: $localize`:@@LeadOppty_quick_account_email:Quick %Account_s% e-mail`,
  LeadOppty_ready_to_move: $localize`:@@LeadOppty_ready_to_move:Ready to move`,
  LeadOppty_created_qualified_by: $localize`:@@LeadOppty_created_qualified_by:Created/qualified by`,
  LeadOppty_target_contribution: $localize`:@@LeadOppty_target_contribution:Target contribution`,
  LeadOppty_is_favorite: $localize`:@@LeadOppty_is_favorite:Favorite`,
  LeadOppty_documents: $localize`:@@LeadOppty_documents:Documents`,
  LeadOppty_value: $localize`:@@LeadOppty_value:%Opportunity_s% Value`,
  LeadOppty_watchers: $localize`:@@LeadOppty_watchers:Watchers`,
  LeadOppty_has_recurrence: $localize`:@@LeadOppty_has_recurrence:Has recurrence`,
  LeadOppty_time_in_sales_step: $localize`:@@LeadOppty_time_in_sales_step:Days in step (velocity)`,
  LeadOppty_due_in_sales_step: $localize`:@@LeadOppty_due_in_sales_step:Due in step`,
  LeadOppty_qualified_at: $localize`:@@LeadOppty_qualified_at:Qualified date`,
  LeadOppty_balanced_value: $localize`:@@LeadOppty_balanced_value:Balanced value`,
  LeadOppty_closing_date: $localize`:@@LeadOppty_closing_date:Closing date`,
  LeadOppty_related_activity_not_started: $localize`:@@LeadOppty_related_activity_not_started:No. of related not started activities`,
  LeadOppty_quick_email: $localize`:@@LeadOppty_quick_email:%Contact_s% quick e-mail`,
  LeadOppty_editors: $localize`:@@LeadOppty_editors:Editors`,
  LeadOppty_name: $localize`:@@LeadOppty_name:Name`,
  LeadOppty_oppty_status: $localize`:@@LeadOppty_oppty_status:%Opportunity_s% status`,
  LeadOppty_lead_status: $localize`:@@LeadOppty_lead_status:%Lead_s% status`,
  LeadOppty_automatizer_created_by_process: $localize`:@@LeadOppty_automatizer_created_by_process:Created by process`,
  LeadOppty_automatizer_process_triggered_date: $localize`:@@LeadOppty_automatizer_process_triggered_date:Process triggered date`,
  LeadOppty_automatizer_process_with_scheduled_action: $localize`:@@LeadOppty_automatizer_process_with_scheduled_action:Process with scheduled action`,
  LeadOppty_automatizer_process_with_scheduled_action_date: $localize`:@@LeadOppty_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  LeadOppty_automatizer_triggered_by_actor: $localize`:@@LeadOppty_automatizer_triggered_by_actor:Triggered by actor`,
  LeadOppty_automatizer_triggered_by_process: $localize`:@@LeadOppty_automatizer_triggered_by_process:Triggered by process`,
  LeadOppty_automatizer_updated_by_process: $localize`:@@LeadOppty_automatizer_updated_by_process:Updated by process`,
  LeadOppty_sales_step_activities: $localize`:@@LeadOppty_sales_step_activities:Sales Step Activities`,
  LeadOppty_sales_cycle_length: $localize`:@@LeadOppty_sales_cycle_length:Sales Cycle length`,
  LeadOppty_email_sequence_enrolled_in_sequence: $localize`:@@LeadOppty_email_sequence_enrolled_in_sequence:Enrolled in sequence`,
  LeadOppty_email_sequence_currently_enrolled_in_sequence: $localize`:@@LeadOppty_email_sequence_currently_enrolled_in_sequence:Currently enrolled`,
  LeadOppty_email_sequence_last_enrollment_date: $localize`:@@LeadOppty_email_sequence_last_enrollment_date:Last enrollment date`,
  LeadOppty_email_sequence_enrolled_by_actor: $localize`:@@LeadOppty_email_sequence_enrolled_by_actor:Enrolled by (actor)`,
  LeadOppty_Tags: $localize`:@@LeadOppty_Tags:%Lead_s% & %Opportunity_s% Tags`,
  LeadOppty_tags: $localize`:@@LeadOppty_tags:%Lead_s% & %Opportunity_s% tags`,
  LeadOppty: $localize`:@@LeadOppty:%Lead_s% & %Opportunity_s%`,
  LeadOpptyKPI_client_id: $localize`:@@LeadOpptyKPI_client_id:User`,
  LeadOpptyKPI_unit_id: $localize`:@@LeadOpptyKPI_unit_id:Sales Unit`,
  LeadOpptyKPI: $localize`:@@LeadOpptyKPI:Indicator`,
  Activity_finished_at: $localize`:@@Activity_finished_at:Completed date`,
  Activity_sharing_units: $localize`:@@Activity_sharing_units:Sales Unit`,
  Activity_created: $localize`:@@Activity_created:Activity created date`,
  Activity_watchers: $localize`:@@Activity_watchers:Watchers`,
  Activity_lead_oppty_relations: $localize`:@@Activity_lead_oppty_relations:Activity_lead_oppty_relations`,
  Activity_linked_entity_types: $localize`:@@Activity_linked_entity_types:Linked items types`,
  Activity_description: $localize`:@@Activity_description:Description`,
  Activity_activity_status: $localize`:@@Activity_activity_status:Status`,
  Activity_invitees_contacts: $localize`:@@Activity_invitees_contacts:Invitees`,
  Activity_has_recurrence: $localize`:@@Activity_has_recurrence:Has recurrence`,
  Activity_due_date: $localize`:@@Activity_due_date:Due date`,
  Activity_sharing_clients: $localize`:@@Activity_sharing_clients:Users`,
  Activity_sharing: $localize`:@@Activity_sharing:Sharing`,
  Activity_editors: $localize`:@@Activity_editors:Editors`,
  Activity_linked_entity_names: $localize`:@@Activity_linked_entity_names:Linked Items`,
  Activity_reminder_date: $localize`:@@Activity_reminder_date:Reminder date`,
  Activity_subject: $localize`:@@Activity_subject:Subject`,
  Activity_priority: $localize`:@@Activity_priority:Priority`,
  Activity_has_reminder: $localize`:@@Activity_has_reminder:Has reminder`,
  Activity_start_date: $localize`:@@Activity_start_date:Start date`,
  Activity_task_start_date: $localize`:@@Activity_task_start_date:Task start date`,
  Activity_date: $localize`:@@Activity_date:Date`,
  Activity_owner_id: $localize`:@@Activity_owner_id:Owner`,
  Activity_last_comment_subject: $localize`:@@Activity_last_comment_subject:Last comment`,
  Activity_is_confirmed: $localize`:@@Activity_is_confirmed:Confirmed`,
  Activity_period: $localize`:@@Activity_period:Date`,
  Activity_invitees_can_edit: $localize`:@@Activity_invitees_can_edit:Invitees can edit`,
  Activity_id: $localize`:@@Activity_id:Activity ID`,
  Activity_last_comment_owner: $localize`:@@Activity_last_comment_owner:Last commented by`,
  Activity_contact_relations: $localize`:@@Activity_contact_relations:%Contact_s% relations`,
  Activity_share_mode: $localize`:@@Activity_share_mode:Activity share mode`,
  Activity_location: $localize`:@@Activity_location:Location`,
  Activity_share_actor: $localize`:@@Activity_share_actor:My activity role`,
  Activity_activity_type_id: $localize`:@@Activity_activity_type_id:Activity type`,
  Activity_linked_items: $localize`:@@Activity_linked_items:Linked Items`,
  Activity_comments: $localize`:@@Activity_comments:Comments`,
  Activity_unit_id: $localize`:@@Activity_unit_id:Sales unit`,
  Activity_end_date: $localize`:@@Activity_end_date:End Date`,
  Activity_invitees_clients: $localize`:@@Activity_invitees_clients:Users`,
  Activity_kpi: $localize`:@@Activity_kpi:Activity KPI`,
  Activity_daterange: $localize`:@@Activity_daterange:Date range`,
  Activity_modified: $localize`:@@Activity_modified:Modified`,
  Activity_modified_by_user: $localize`:@@Activity_modified_by_user:Modified Date By User`,
  Activity_last_comment_date: $localize`:@@Activity_last_comment_date:Last commented date`,
  Activity_documents: $localize`:@@Activity_documents:Documents`,
  Activity_status: $localize`:@@Activity_status:Status`,
  Activity_is_overdue: $localize`:@@Activity_is_overdue:Is overdue`,
  Activity_account_relations: $localize`:@@Activity_account_relations:%Account_s% relations`,
  Activity_attachments: $localize`:@@Activity_attachments:Attachments`,
  Activity_attendee_emails: $localize`:@@Activity_attendee_emails:Attendee emails`,
  Activity_related_editors: $localize`:@@Activity_related_editors:Activity related editors`,
  Activity_related_watchers: $localize`:@@Activity_related_watchers:Activity related watchers`,
  Activity_automatizer_created_by_process: $localize`:@@Activity_automatizer_created_by_process:Created by process`,
  Activity_automatizer_process_triggered_date: $localize`:@@Activity_automatizer_process_triggered_date:Process triggered date`,
  Activity_automatizer_process_with_scheduled_action: $localize`:@@Activity_automatizer_process_with_scheduled_action:Process with scheduled action`,
  Activity_automatizer_process_with_scheduled_action_date: $localize`:@@Activity_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  Activity_automatizer_triggered_by_actor: $localize`:@@Activity_automatizer_triggered_by_actor:Triggered by actor`,
  Activity_automatizer_triggered_by_process: $localize`:@@Activity_automatizer_triggered_by_process:Triggered by process`,
  Activity_automatizer_updated_by_process: $localize`:@@Activity_automatizer_updated_by_process:Updated by process`,
  Activity_record_url: $localize`:@@Activity_record_url:Record URL`,
  Activity_call_outcome_id: $localize`:@@Activity_call_outcome_id:Call Outcome`,
  Activity_call_duration: $localize`:@@Activity_call_duration:Call Duration`,
  Activity_comment_count: $localize`:@@Activity_comment_count:Comment Count`,
  Activity_duration: $localize`:@@Activity_duration:Duration`,
  Note_note: $localize`:@@Note_note:Note`,
  Note_lead_oppty_id: $localize`:@@Note_lead_oppty_id:%Lead_s%/%Opportunity_s% name`,
  Note_created: $localize`:@@Note_created:Note created date`,
  Note_modified: $localize`:@@Note_modified:Modified`,
  Note_contact_id: $localize`:@@Note_contact_id:%Contact_s% name`,
  Note_id: $localize`:@@Note_id:Note ID`,
  Note_account_id: $localize`:@@Note_account_id:%Account_s% name`,
  Note_owner_id: $localize`:@@Note_owner_id:Owner`,
  Note_relation: $localize`:@@Note_relation:Relation`,
  CloudObject_created: $localize`:@@CloudObject_created:Document created date`,
  CloudObject_size: $localize`:@@CloudObject_size:Size`,
  CloudObject_id: $localize`:@@CloudObject_id:ID`,
  CloudObject_type: $localize`:@@CloudObject_type:Type`,
  CloudObject_mime_type: $localize`:@@CloudObject_mime_type:Type`,
  CloudObject_url: $localize`:@@CloudObject_url:URL`,
  CloudObject_modified: $localize`:@@CloudObject_modified:Modified`,
  CloudObject_filename: $localize`:@@CloudObject_filename:Name`,
  CloudObject_linked_entity_name: $localize`:@@CloudObject_linked_entity_name:Linked Record Name`,
  CloudObject_linked_entity_type: $localize`:@@CloudObject_linked_entity_type:Linked Record Type`,
  CloudObject_creator_id: $localize`:@@CloudObject_creator_id:Owner`,
  CloudObject_status: $localize`:@@CloudObject_status:Status`,
  LeadOpptyAccountRelation_id: $localize`:@@LeadOpptyAccountRelation_id:LeadOpptyAccountRelation_id`,
  LeadOpptyAccountRelation_account_id: $localize`:@@LeadOpptyAccountRelation_account_id:Primary %Account_s% name`,
  LeadOpptyAccountRelation_is_primary: $localize`:@@LeadOpptyAccountRelation_is_primary:Is primary %Account_s%`,
  LeadOpptyAccountRelation_lead_oppty_id: $localize`:@@LeadOpptyAccountRelation_lead_oppty_id:LeadOpptyAccountRelation_lead_oppty_id`,
  LeadOpptyAccountRelation_created: $localize`:@@LeadOpptyAccountRelation_created:Primary %Account_s% created date`,
  LeadOpptyAccountRelation_modified: $localize`:@@LeadOpptyAccountRelation_modified:Primary %Account_s% modified`,
  LeadOpptyContactRelation_id: $localize`:@@LeadOpptyContactRelation_id:LeadOpptyContactRelation_id`,
  LeadOpptyContactRelation_sales_role_group: $localize`:@@LeadOpptyContactRelation_sales_role_group:%Contact_s% sales roles`,
  LeadOpptyContactRelation_sales_roles: $localize`:@@LeadOpptyContactRelation_sales_roles:Sales roles`,
  LeadOpptyContactRelation_comment: $localize`:@@LeadOpptyContactRelation_comment:Primary %Contact_s% comment`,
  LeadOpptyContactRelation_contact_id: $localize`:@@LeadOpptyContactRelation_contact_id:Primary %Contact_s% name`,
  LeadOpptyContactRelation_is_primary: $localize`:@@LeadOpptyContactRelation_is_primary:Is primary %Contact_s%`,
  LeadOpptyContactRelation_lead_oppty_id: $localize`:@@LeadOpptyContactRelation_lead_oppty_id:LeadOpptyContactRelation_lead_oppty_id`,
  LeadOpptyContactRelation_created: $localize`:@@LeadOpptyContactRelation_created:Primary %Contact_s% created date`,
  LeadOpptyContactRelation_modified: $localize`:@@LeadOpptyContactRelation_modified:Primary %Contact_s% modified`,
  LeadOpptyContactRelation_parent_contact_id: $localize`:@@LeadOpptyContactRelation_parent_contact_id:Parent %Contact_s%`,
  LeadOpptyContactRelation_relationship: $localize`:@@LeadOpptyContactRelation_relationship:Primary %Contact_s% relationship`,
  ContactAccountRelation_comment: $localize`:@@ContactAccountRelation_comment:Comment`,
  ContactAccountRelation_is_assistant: $localize`:@@ContactAccountRelation_is_assistant:Is assistant`,
  ContactAccountRelation_is_primary: $localize`:@@ContactAccountRelation_is_primary:Is primary`,
  ContactAccountRelation_parent_contact_id: $localize`:@@ContactAccountRelation_parent_contact_id:Parent %Contact_s%`,
  ContactAccountRelation_position: $localize`:@@ContactAccountRelation_position:Job Title`,
  ContactAccountRelation_is_sibling: $localize`:@@ContactAccountRelation_is_sibling:Is sibling`,
  ContactAccountRelation_relationship: $localize`:@@ContactAccountRelation_relationship:Relationship`,
  Product_id: $localize`:@@Product_id:Product ID`,
  Product_name: $localize`:@@Product_name:Product name`,
  Product_allowed_pipelines: $localize`:@@Product_allowed_pipelines:Product pipelines`,
  Product_description: $localize`:@@Product_description:Product description`,
  Product_product_category_id: $localize`:@@Product_product_category_id:Product category`,
  Product_product_category: $localize`:@@Product_product_category:Product category`,
  Product_product_pipelines: $localize`:@@Product_product_pipelines:Product pipelines`,
  Product_sku: $localize`:@@Product_sku:Product SKU`,
  Product_unit_symbol: $localize`:@@Product_unit_symbol:Product unit`,
  Product_created: $localize`:@@Product_created:Product created date`,
  Product_modified: $localize`:@@Product_modified:Product modified`,
  Product_product_type_id: $localize`:@@Product_product_type_id:Product type`,
  Product_picture: $localize`:@@Product_picture:Product image URL`,
  ProductCategory_name: $localize`:@@ProductCategory_name:Product category name`,
  OpptyProductRelation_amount: $localize`:@@OpptyProductRelation_amount:Amount`,
  OpptyProductRelation_comment: $localize`:@@OpptyProductRelation_comment:Comment`,
  OpptyProductRelation_price: $localize`:@@OpptyProductRelation_price:Price`,
  OpptyProductRelation_quantity: $localize`:@@OpptyProductRelation_quantity:Quantity`,
  OpptyProductRelation_product: $localize`:@@OpptyProductRelation_product:Product Name`,
  OpptyProductRelation_product_card: $localize`:@@OpptyProductRelation_product_card:Product Name`,
  OpptyProductRelation_value_difference: $localize`:@@OpptyProductRelation_value_difference:Value difference`,
  OpptyProductRelation_id: $localize`:@@OpptyProductRelation_id:Product Line Item ID`,
  OpptyProductRelation_discount_percentage: $localize`:@@OpptyProductRelation_discount_percentage:Discount Percentage`,
  OpptyProductRelation_discount_value: $localize`:@@OpptyProductRelation_discount_value:Discount Value`,
  OpptyProductRelation_oppty_id: $localize`:@@OpptyProductRelation_oppty_id:%Opportunity_s% ID`,
  OpptyProductRelation_oppty_product_relation_type_id: $localize`:@@OpptyProductRelation_oppty_product_relation_type_id:Product Line Item Type`,
  OpptyProductRelation_product_id: $localize`:@@OpptyProductRelation_product_id:Product ID`,
  OpptyProductRelation_created: $localize`:@@OpptyProductRelation_created:Created`,
  OpptyProductRelation_modified: $localize`:@@OpptyProductRelation_modified:Modified`,
  OpptyProductRelation_modified_by_user: $localize`:@@OpptyProductRelation_modified_by_user:Modified Date By User`,
  Timeframe_id: $localize`:@@Timeframe_id:Velocity ID`,
  Timeframe_oppty_id: $localize`:@@Timeframe_oppty_id:%Opportunity_s% velocity`,
  Timeframe_step_id: $localize`:@@Timeframe_step_id:Sales step velocity`,
  Timeframe_timeframe: $localize`:@@Timeframe_timeframe:Velocity`,
  Timeframe_created: $localize`:@@Timeframe_created:Velocity created date`,
  Timeframe_modified: $localize`:@@Timeframe_modified:Velocity modified`,
  Memo_memo_id: $localize`:@@Memo_memo_id:Message`,
  Memo_subject: $localize`:@@Memo_subject:Message subject`,
  Memo_account_relations: $localize`:@@Memo_account_relations:Message linked %Account_p%`,
  Memo_last_comment_date: $localize`:@@Memo_last_comment_date:Message last commented date`,
  Memo_documents: $localize`:@@Memo_documents:Message documents`,
  Memo_last_comment_owner: $localize`:@@Memo_last_comment_owner:Message last commented by`,
  Memo_sharing_clients: $localize`:@@Memo_sharing_clients:Message users sharing`,
  Memo_contact_relations: $localize`:@@Memo_contact_relations:Message linked %Contact_p%`,
  Memo_last_comment_subject: $localize`:@@Memo_last_comment_subject:Message last comment`,
  Memo_sharing_units: $localize`:@@Memo_sharing_units:Message units sharing`,
  Memo_sharing: $localize`:@@Memo_sharing:Message sharing`,
  Memo_linked_items: $localize`:@@Memo_linked_items:Message linked items`,
  Memo_lead_oppty_relations: $localize`:@@Memo_lead_oppty_relations:Message linked %Lead_p%/%Opportunity_p%`,
  Memo_comments: $localize`:@@Memo_comments:Message comments`,
  Memo_owner_id: $localize`:@@Memo_owner_id:Message owner`,
  Memo_created: $localize`:@@Memo_created:Message created date`,
  Memo_modified: $localize`:@@Memo_modified:Message modified`,
  Memo_has_attachment: $localize`:@@Memo_has_attachment:Message has attachment`,
  Email_email_id: $localize`:@@Email_email_id:E-mail`,
  Email_subject: $localize`:@@Email_subject:E-mail subject`,
  Email_contact_relations: $localize`:@@Email_contact_relations:E-mail linked %Contact_p%`,
  Email_lead_oppty_relations: $localize`:@@Email_lead_oppty_relations:E-mail linked %Lead_p%/%Opportunity_p%`,
  Email_account_relations: $localize`:@@Email_account_relations:E-mail linked %Account_p%`,
  Email_sharing_units: $localize`:@@Email_sharing_units:E-mail units sharing`,
  Email_linked_items: $localize`:@@Email_linked_items:E-mail linked items`,
  Email_sharing: $localize`:@@Email_sharing:E-mail sharing`,
  Email_documents: $localize`:@@Email_documents:E-mail documents`,
  Email_body: $localize`:@@Email_body:E-mail body`,
  Email_body_summary: $localize`:@@Email_body_summary:E-mail body summary`,
  Email_cc: $localize`:@@Email_cc:E-mail CC`,
  Email_content_type: $localize`:@@Email_content_type:E-mail content type`,
  Email_date_sent: $localize`:@@Email_date_sent:E-mail date sent`,
  Email_sharing_clients: $localize`:@@Email_sharing_clients:E-mail users sharing`,
  Email_direction: $localize`:@@Email_direction:E-mail direction`,
  Email_owner_id: $localize`:@@Email_owner_id:E-mail owner`,
  Email_sender: $localize`:@@Email_sender:E-mail sender`,
  Email_to: $localize`:@@Email_to:E-mail to`,
  Email_uid: $localize`:@@Email_uid:E-mail UID`,
  Email_created: $localize`:@@Email_created:E-mail created date`,
  Email_modified: $localize`:@@Email_modified:E-mail modified`,
  Email_is_tracked: $localize`:@@Email_is_tracked:E-mail is tracked`,
  Email_parent_email_id: $localize`:@@Email_parent_email_id:E-mail parent e-mail`,
  Email_tracking_status: $localize`:@@Email_tracking_status:E-mail tracking status`,
  Email_type: $localize`:@@Email_type:E-mail type`,
  Email_share_mode: $localize`:@@Email_share_mode:E-mail share mode`,
  Email_content_share_mode: $localize`:@@Email_content_share_mode:E-mail content share mode`,
  Email_watchers: $localize`:@@Email_watchers:E-mail watchers`,
  Email_has_attachment: $localize`:@@Email_has_attachment:E-mail has attachment`,
  MessageRelation_id: $localize`:@@MessageRelation_id:MessageRelation_id`,
  MessageRelation_relation: $localize`:@@MessageRelation_relation:MessageRelation_relation`,
  MessageRelation_account_id: $localize`:@@MessageRelation_account_id:MessageRelation_account_id`,
  MessageRelation_contact_id: $localize`:@@MessageRelation_contact_id:MessageRelation_contact_id`,
  MessageRelation_lead_oppty_id: $localize`:@@MessageRelation_lead_oppty_id:MessageRelation_lead_oppty_id`,
  MessageRelation_message_id: $localize`:@@MessageRelation_message_id:MessageRelation_message_id`,
  MessageRelation_created: $localize`:@@MessageRelation_created:MessageRelation_created`,
  MessageRelation_modified: $localize`:@@MessageRelation_modified:MessageRelation_modified`,
  Virtual_User_first_name: $localize`:@@Virtual_User_first_name:First Name`,
  Virtual_User_middle_name: $localize`:@@Virtual_User_middle_name:Middle Name`,
  Virtual_User_last_name: $localize`:@@Virtual_User_last_name:Last Name`,
  Virtual_User_name: $localize`:@@Virtual_User_name:Name`,
  Virtual_User_email: $localize`:@@Virtual_User_email:E-mail`,
  Virtual_User_id: $localize`:@@Virtual_User_id:ID`,
  Virtual_System_current_date: $localize`:@@Virtual_System_current_date:Current Date`,
  Virtual_current_date_time: $localize`:@@Virtual_current_date_time:Now`,
  Virtual_current_process_name: $localize`:@@Virtual_current_process_name:Current Process Name`,
  Virtual_current_process_description: $localize`:@@Virtual_current_process_description:Current Process Description`,
  Virtual_current_process_status: $localize`:@@Virtual_current_process_status:Current Process Status`,
  Virtual_current_email_sequence_name: $localize`:@@Virtual_current_email_sequence_name:Current Email Sequence Name`,
  Virtual_current_email_sequence_description: $localize`:@@Virtual_current_email_sequence_description:Current Email Sequence Description`,
  Virtual_current_email_sequence_status: $localize`:@@Virtual_current_email_sequence_status:Current Email Sequence Status`,
  Virtual_current_email_sequence_time_zone: $localize`:@@Virtual_current_email_sequence_time_zone:Current Email Sequence Time Zone`,
  Virtual_trigger_started: $localize`:@@Virtual_trigger_started:Started`,
  Virtual_trigger_email: $localize`:@@Virtual_trigger_email:Main Email Address`,
  Virtual_trigger_type: $localize`:@@Virtual_trigger_type:Type`,
  Virtual_trigger_record_type: $localize`:@@Virtual_trigger_record_type:Record Type Name`,
  Virtual_triggered_record: $localize`:@@Virtual_triggered_record:Triggered Record`,
  Virtual_triggered_record_owner: $localize`:@@Virtual_triggered_record_owner:Triggered Record Owner`,
  Virtual_process_owner: $localize`:@@Virtual_process_owner:Process Owner`,
  Virtual_process_actor: $localize`:@@Virtual_process_actor:Process Actor`,
  Virtual_triggered_record_primary_account: $localize`:@@Virtual_triggered_record_primary_account:Triggered Record Primary %Account_s%`,
  Virtual_triggered_record_primary_contact: $localize`:@@Virtual_triggered_record_primary_contact:Triggered Record Primary %Contact_s%`,
  Virtual_triggered_record_products: $localize`:@@Virtual_triggered_record_products:Triggered Record Products`,
  Virtual_triggered_record_linked_items: $localize`:@@Virtual_triggered_record_linked_items:Triggered Record Linked Items`,
  Virtual_product_line_items: $localize`:@@Virtual_product_line_items:Product Line Items`,
  Client_position: $localize`:@@Client_position:Position`,
  Client_email: $localize`:@@Client_email:E-mail`,
  Client_first_name: $localize`:@@Client_first_name:First Name`,
  Client_middle_name: $localize`:@@Client_middle_name:Middle Name`,
  Client_last_name: $localize`:@@Client_last_name:Last Name`,
  Client_master_right_id: $localize`:@@Client_master_right_id:Role`,
  Client_member_units: $localize`:@@Client_member_units:Sales Unit`,
  Client_default_unit_id: $localize`:@@Client_default_unit_id:Default Sales Unit`,
  Client_name: $localize`:@@Client_name:Name`,
  Client_phone: $localize`:@@Client_phone:Phone`,
  Client_timezone: $localize`:@@Client_timezone:Timezone`,
  Client_region: $localize`:@@Client_region:Region`,
  Process_activity: $localize`:@@Process_activity:Activity (24 hours)`,
  Process_created: $localize`:@@Process_created:Created date`,
  Process_deactivation_reason: $localize`:@@Process_deactivation_reason:Deactivation reason`,
  Process_description: $localize`:@@Process_description:Description`,
  Process_id: $localize`:@@Process_id:ID`,
  Process_is_enabled: $localize`:@@Process_is_enabled:Enabled`,
  Process_last_status_change: $localize`:@@Process_last_status_change:Last status change date`,
  Process_last_process_run: $localize`:@@Process_last_process_run:Last process run`,
  Process_modified: $localize`:@@Process_modified:Modified date`,
  Process_name: $localize`:@@Process_name:Name`,
  Process_owner_id: $localize`:@@Process_owner_id:Owner`,
  Process_type: $localize`:@@Process_type:Type`,
  Process_status: $localize`:@@Process_status:Status`,
  Process_trigger_type: $localize`:@@Process_trigger_type:Trigger type`,
  Process_trigger_entity_name: $localize`:@@Process_trigger_entity_name:Record Type`,
  EmailSequence_description: $localize`:@@EmailSequence_description:Description`,
  EmailSequence_name: $localize`:@@EmailSequence_name:Name`,
  EmailSequence_created: $localize`:@@EmailSequence_created:Created date`,
  EmailSequence_deactivation_reason: $localize`:@@EmailSequence_deactivation_reason:Deactivation reason`,
  EmailSequence_id: $localize`:@@EmailSequence_id:ID`,
  EmailSequence_is_enabled: $localize`:@@EmailSequence_is_enabled:Enabled`,
  EmailSequence_last_schema_change: $localize`:@@EmailSequence_last_schema_change:Last schema change date`,
  EmailSequence_last_status_change: $localize`:@@EmailSequence_last_status_change:Last status change date`,
  EmailSequence_modified: $localize`:@@EmailSequence_modified:Modified date`,
  EmailSequence_owner_id: $localize`:@@EmailSequence_owner_id:Owner`,
  EmailSequence_status: $localize`:@@EmailSequence_status:Status`,
  EmailSequence_trigger_entity_name: $localize`:@@EmailSequence_trigger_entity_name:Record Type`,
  EmailSequence_type: $localize`:@@EmailSequence_type:Type`,
  EmailSequence_bounce_rate: $localize`:@@EmailSequence_bounce_rate:Bounce rate`,
  EmailSequence_click_rate: $localize`:@@EmailSequence_click_rate:Click rate`,
  EmailSequence_created_count: $localize`:@@EmailSequence_created_count:Created Tasks`,
  EmailSequence_duration: $localize`:@@EmailSequence_duration:Duration`,
  EmailSequence_enroll_count: $localize`:@@EmailSequence_enroll_count:Total Enrolled`,
  EmailSequence_last_email_sequence_run: $localize`:@@EmailSequence_last_email_sequence_run:Last email sequence run`,
  EmailSequence_last_steps_statistics_reset: $localize`:@@EmailSequence_last_steps_statistics_reset:Last steps statistics reset`,
  EmailSequence_open_rate: $localize`:@@EmailSequence_open_rate:Open rate`,
  EmailSequence_reply_rate: $localize`:@@EmailSequence_reply_rate:Reply rate`,
  EmailSequence_sent_count: $localize`:@@EmailSequence_sent_count:Sent count`,
  EmailSequence_step_count: $localize`:@@EmailSequence_step_count:Step count`,
  EmailSequence_unsubscribe_rate: $localize`:@@EmailSequence_unsubscribe_rate:Unsubscribe rate`,
  EmailSequence_trigger_field_id: $localize`:@@EmailSequence_trigger_field_id:Email address field`,
  Project_stakeholders: $localize`:@@Project_stakeholders:Stakeholders`,
  Project_account_card: $localize`:@@Project_account_card:%Account_s%`,
  Project_contact_card: $localize`:@@Project_contact_card:Stakeholders`,
  Project_period: $localize`:@@Project_period:Period`,
  Project_description: $localize`:@@Project_description:Description`,
  Project_project_id: $localize`:@@Project_project_id:ID`,
  Project_revenue_goal: $localize`:@@Project_revenue_goal:Revenue Goal`,
  Project_unit_id: $localize`:@@Project_unit_id:Sales Unit`,
  Project_name: $localize`:@@Project_name:Name`,
  Project_account: $localize`:@@Project_account:%Account_s%`,
  Project_owner_id: $localize`:@@Project_owner_id:Owner`,
  Project_modified: $localize`:@@Project_modified:Modified`,
  Project_modified_by_user: $localize`:@@Project_modified_by_user:Modified Date By User`,
  Project_completed_date: $localize`:@@Project_completed_date:Completed date`,
  Project_completion_rate: $localize`:@@Project_completion_rate:Completion rate`,
  Project_created: $localize`:@@Project_created:Created`,
  Project_end_date: $localize`:@@Project_end_date:End Date`,
  Project_editors: $localize`:@@Project_editors:Editors`,
  Project_is_favorite: $localize`:@@Project_is_favorite:Favorite`,
  Project_project_type_id: $localize`:@@Project_project_type_id:Project Type`,
  Project_related_activity_finished: $localize`:@@Project_related_activity_finished:No. of related completed activities`,
  Project_related_activity_in_progress: $localize`:@@Project_related_activity_in_progress:No. of related in progress activities`,
  Project_related_activity_not_started: $localize`:@@Project_related_activity_not_started:No. of related not started activities`,
  Project_related_activity_overdue: $localize`:@@Project_related_activity_overdue:No. of related overdue activities`,
  Project_revenue_achieved: $localize`:@@Project_revenue_achieved:Revenue achieved`,
  Project_share_mode: $localize`:@@Project_share_mode:Project share mode`,
  Project_start_date: $localize`:@@Project_start_date:Start Date`,
  Project_status: $localize`:@@Project_status:Status`,
  Project_tags: $localize`:@@Project_tags:Project Tags`,
  Project_Tags: $localize`:@@Project_Tags:Project Tags`,
  Project_is_completed: $localize`:@@Project_is_completed:Is Completed`,
  Project_won_revenue: $localize`:@@Project_won_revenue:Won revenue`,
  Project_watchers: $localize`:@@Project_watchers:Watchers`,
  Project_email_sequence_enrolled_in_sequence: $localize`:@@Project_email_sequence_enrolled_in_sequence:Enrolled in sequence`,
  Project_email_sequence_currently_enrolled_in_sequence: $localize`:@@Project_email_sequence_currently_enrolled_in_sequence:Currently enrolled`,
  Project_email_sequence_last_enrollment_date: $localize`:@@Project_email_sequence_last_enrollment_date:Last enrollment date`,
  Project_email_sequence_enrolled_by_actor: $localize`:@@Project_email_sequence_enrolled_by_actor:Enrolled by (actor)`,
  ProjectObjective_project_id: $localize`:@@ProjectObjective_project_id:Project ID`,
  ProjectObjective_modified: $localize`:@@ProjectObjective_modified:Modified`,
  ProjectObjective_created: $localize`:@@ProjectObjective_created:Project objective created date`,
  ProjectObjective_name: $localize`:@@ProjectObjective_name:Name`,
  ProjectObjective_id: $localize`:@@ProjectObjective_id:ID`,
  ProjectObjective_sort_order: $localize`:@@ProjectObjective_sort_order:Sort order`,
  Report_owner_id: $localize`:@@Report_owner_id:Owner`,
  Report_type: $localize`:@@Report_type:Type`,
  Report_name: $localize`:@@Report_name:Name`,
  Activity_invitee_first_name: $localize`:@@Activity_invitee_first_name:Invitee First Name`,
  Activity_invitee_last_name: $localize`:@@Activity_invitee_last_name:Invitee Last Name`,
  Activity_invitee_email: $localize`:@@Activity_invitee_email:Invitee Email`,
  Forecast_created: $localize`:@@Forecast_created:Created`,
  Forecast_description: $localize`:@@Forecast_description:Description`,
  Forecast_end_date: $localize`:@@Forecast_end_date:End Date`,
  Forecast_field_id: $localize`:@@Forecast_field_id:Field ID`,
  Forecast_id: $localize`:@@Forecast_id:Forecast ID`,
  Forecast_modified: $localize`:@@Forecast_modified:Modified`,
  Forecast_name: $localize`:@@Forecast_name:Name`,
  Forecast_owner_id: $localize`:@@Forecast_owner_id:Owner`,
  Forecast_period: $localize`:@@Forecast_period:Period`,
  Forecast_pipeline_id: $localize`:@@Forecast_pipeline_id:Pipeline`,
  Forecast_share_actor: $localize`:@@Forecast_share_actor:My Forecast Role`,
  Forecast_share_mode: $localize`:@@Forecast_share_mode:Share Mode`,
  Forecast_start_date: $localize`:@@Forecast_start_date:Start Date`,
  Forecast_type: $localize`:@@Forecast_type:Type`,
  Forecast_won_value: $localize`:@@Forecast_won_value:Won Value`,
  Forecast_won_value_vs_quota: $localize`:@@Forecast_won_value_vs_quota:Won Value vs. Quota`,
  Target_name: $localize`:@@Target_name:Name`,
  Target_calculation_type: $localize`:@@Target_calculation_type:Target Type`,
  Target_owner_id: $localize`:@@Target_owner_id:Owner`,
  Target_description: $localize`:@@Target_description:Description`,
  Target_type: $localize`:@@Target_type:Type`,
  Target_period: $localize`:@@Target_period:Period`,
  Target_is_active: $localize`:@@Target_is_active:Is Active`,
  Target_period_to: $localize`:@@Target_period_to:Period To`,
  Target_targets: $localize`:@@Target_targets:Targets`,
  Target_modified: $localize`:@@Target_modified:Modified`,
  Target_finished_at: $localize`:@@Target_finished_at:Finished At`,
  Target_status: $localize`:@@Target_status:Status`,
  Target_target: $localize`:@@Target_target:Target`,
  Target_created: $localize`:@@Target_created:Created`,
  Target_days_left: $localize`:@@Target_days_left:Days Left`,
  Target_target_client_id: $localize`:@@Target_target_client_id:User`,
  Target_period_from: $localize`:@@Target_period_from:Period From`,
  Target_current_target_value: $localize`:@@Target_current_target_value:Achieved Target Value`,
  Target_id: $localize`:@@Target_id:Target ID`,
  Target_finished_date: $localize`:@@Target_finished_date:Finished Date`,
  Target_group_target: $localize`:@@Target_group_target:Group Target`,
  Target_settings: $localize`:@@Target_settings:Target Settings`,
  Target_estimated_target: $localize`:@@Target_estimated_target:Estimated Target Value`,
  Quote_name: $localize`:@@Quote_name:Name`,
  Quote_is_favorite: $localize`:@@Quote_is_favorite:Favorite`,
  Quote_account_card: $localize`:@@Quote_account_card:Primary %Account_s% name`,
  Quote_expiration_date: $localize`:@@Quote_expiration_date:Expiration date`,
  Quote_owner_id: $localize`:@@Quote_owner_id:Owner`,
  Quote_unit_id: $localize`:@@Quote_unit_id:Sales unit`,
  Quote_created: $localize`:@@Quote_created:Created`,
  Quote_contact_card: $localize`:@@Quote_contact_card:Primary %Contact_s% name`,
  Quote_description: $localize`:@@Quote_description:Description`,
  Quote_editors: $localize`:@@Quote_editors:Editors`,
  Quote_is_archived: $localize`:@@Quote_is_archived:Is archived`,
  Quote_modified: $localize`:@@Quote_modified:Modified`,
  Quote_oppty_card: $localize`:@@Quote_oppty_card:%Opportunity_s%`,
  Quote_oppty_id: $localize`:@@Quote_oppty_id:%Opportunity_s% ID`,
  Quote_product_id: $localize`:@@Quote_product_id:Product`,
  Quote_product_price_list_id: $localize`:@@Quote_product_price_list_id:Product price list`,
  Quote_quick_account_email: $localize`:@@Quote_quick_account_email:Quick %Account_s% e-mail`,
  Quote_quick_account_name: $localize`:@@Quote_quick_account_name:Quick %Account_s% name`,
  Quote_quick_account_phone: $localize`:@@Quote_quick_account_phone:Quick %Account_s% phone`,
  Quote_quick_contact_name: $localize`:@@Quote_quick_contact_name:Quick %Contact_s% name`,
  Quote_quick_email: $localize`:@@Quote_quick_email:Quick %Contact_s% e-mail`,
  Quote_quick_phone: $localize`:@@Quote_quick_phone:Quick %Contact_s% phone`,
  Quote_quote_id: $localize`:@@Quote_quote_id:%Quote_s% ID`,
  Quote_quote_number: $localize`:@@Quote_quote_number:%Quote_s% number`,
  Quote_quote_process: $localize`:@@Quote_quote_process:%Quote_s% process`,
  Quote_quote_type_id: $localize`:@@Quote_quote_type_id:%Quote_s% type`,
  Quote_ready_to_move: $localize`:@@Quote_ready_to_move:Ready to move`,
  Quote_reason_of_close_description: $localize`:@@Quote_reason_of_close_description:Lost reason description`,
  Quote_reason_of_close_id: $localize`:@@Quote_reason_of_close_id:Lost reason`,
  Quote_share_mode: $localize`:@@Quote_share_mode:Share mode`,
  Quote_status: $localize`:@@Quote_status:Status`,
  Quote_step_id: $localize`:@@Quote_step_id:Step`,
  Quote_total_amount: $localize`:@@Quote_total_amount:Total amount`,
  Quote_version: $localize`:@@Quote_version:Version`,
  Quote_watchers: $localize`:@@Quote_watchers:Watchers`,
  Quote_product_currency_id: $localize`:@@Quote_product_currency_id:Product currency ID`,
  Quote_automatizer_created_by_process: $localize`:@@Quote_automatizer_created_by_process:Created by process`,
  Quote_automatizer_process_triggered_date: $localize`:@@Quote_automatizer_process_triggered_date:Process triggered date`,
  Quote_automatizer_process_with_scheduled_action: $localize`:@@Quote_automatizer_process_with_scheduled_action:Process with scheduled action`,
  Quote_automatizer_process_with_scheduled_action_date: $localize`:@@Quote_automatizer_process_with_scheduled_action_date:Process with scheduled action date`,
  Quote_automatizer_triggered_by_actor: $localize`:@@Quote_automatizer_triggered_by_actor:Triggered by actor`,
  Quote_automatizer_triggered_by_process: $localize`:@@Quote_automatizer_triggered_by_process:Triggered by process`,
  Quote_automatizer_updated_by_process: $localize`:@@Quote_automatizer_updated_by_process:Updated by process`,
  Quote_record_url: $localize`:@@Quote_record_url:Record URL`,
  Quote_modified_by_user: $localize`:@@Quote_modified_by_user:Modified Date By User`,
  QuoteAccountRelation_quote_id: $localize`:@@QuoteAccountRelation_quote_id:%Quote_s% ID`,
  QuoteAccountRelation_account_id: $localize`:@@QuoteAccountRelation_account_id:%Account_s% ID`,
  QuoteAccountRelation_is_primary: $localize`:@@QuoteAccountRelation_is_primary:Is primary %Account_s%`,
};
