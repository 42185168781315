import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-input-number-demo',
  templateUrl: './input-number-demo.component.html',
  styleUrls: ['./input-number-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputNumberDemoComponent implements OnInit {
  value1 = 10;
  value2 = 10;
  value3 = 10;
  value4 = 10;

  demo1 = `<ppl-input-number [value]="value1" (valueChange)="value1 = $event"></ppl-input-number>`;
  demo2 = `<ppl-input-number [value]="value2" [min]="2" [max]="15" (valueChange)="value2 = $event"></ppl-input-number>`;
  demo3 = `<ppl-input-number [disabled]="true" [value]="value3" (valueChange)="value3 = $event"></ppl-input-number>`;
  demo4 = `<ppl-input-number [value]="value4" (valueChange)="value4 = $event"></ppl-input-number>`;

  constructor() {}

  ngOnInit() {}
}
