<ppl-loading-overlay *ngIf="showOverlay && saving"
                     [large]="true"
                     [visible]="true"></ppl-loading-overlay>

<form [formGroup]="formGroup"
      [attr.id]="id"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
      (submit)="$event.stopPropagation()">
  <ppl-form-container [transparent]="transparent"
                      [noPadding]="noPadding">
    <ng-content></ng-content>
  </ppl-form-container>
</form>
