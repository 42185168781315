import { unsubscribe } from '@ppl/utils';
import type { Subscription} from 'rxjs';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce, skip } from 'rxjs/operators';
import type { PplAutocompleteOption, PplAutocompleteOptionsRequest } from './autocomplete.component';

export class ExternalFilter {

  private value: BehaviorSubject<string>;
  private valueSubscription: Subscription;

  constructor(private options: ExternalFilterOptions) {
    this.value = new BehaviorSubject(this.options.initValue || '');

    this.valueSubscription = this.value.pipe(skip(1), debounce(value => {
      return value ? timer(DEBOUNCE_TIME) : timer(0);
    })).subscribe(value => {
      this.options.onChange(value);

      this.options.onOptionsRequest({
        filter: value,
        lastOptionValue: null
      });
    });

    this.options.onOptionsRequest({
      filter: this.options.initValue || '',
      lastOptionValue: null
    });
  }

  next(value: string) {
    this.value.next(value);
  }

  listEnd(lastOption: PplAutocompleteOption) {
    this.options.onOptionsRequest({
      filter: this.value.getValue(),
      lastOptionValue: lastOption.value,
      lastOptionData: lastOption.data
    });
  }

  dispose() {
    unsubscribe(this.valueSubscription);
  }

}

const DEBOUNCE_TIME = 200;

export interface ExternalFilterOptions {
  initValue?: string;
  onChange: (value: string) => void;
  onOptionsRequest: (event: PplAutocompleteOptionsRequest) => void;
}
