<div class="container demo-content">
  <h2>Checkboxes</h2>

  <h3>Module</h3>
  <div>PplMultiCheckboxAdvancedModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-multi-checkbox-advanced</h4>
  <div>@Input
    <b>value</b>: string[] | null;
  </div>
  <div>@Input
    <b>options</b>: PplBaseOption[];
  </div>
  <div>@Input
    <b>initialValue?</b>: string[] | null (optional)
  </div>
  <div>@Input
    <b>hasChanges</b>: boolean = false
  </div>
  <div>@Output
    <b>valueChange</b>: string[]
  </div>

  <br>

  <br>

  <h3>Example</h3>

  <ppl-input-container label="Checkboxes">
    <ppl-multi-checkbox-advanced [value]="value1"
                                 [initialValue]="initialValue1"
                                 [placeholder]="'Click to select...'"
                                 [options]="options1"
                                 (valueChange)="onValueChange($event, 'value1')"></ppl-multi-checkbox-advanced>
    <br>
  </ppl-input-container>

  <ppl-input-container label="Checkboxes">
    <h6>Disabled</h6>
    <ppl-multi-checkbox-advanced [value]="value1"
                                 [initialValue]="initialValue1"
                                 [placeholder]="'Click to select...'"
                                 [disabled]="true"
                                 [options]="options1"
                                 (valueChange)="onValueChange($event, 'value1')"></ppl-multi-checkbox-advanced>
    <br>
  </ppl-input-container>
  <h3>Code Example</h3>
  <!-- <ppl-prism [code]="code"></ppl-prism> -->
</div>
