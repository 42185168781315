/**
 * check for subscription being initialized before unsubscribing
 * @param {Subscription} subscription
 */
import { Subscription } from 'rxjs';

export function unsubscribe(subscription: Subscription | Array<Subscription | any> | any) {
  if (Array.isArray(subscription)) {
    subscription.forEach(unsubscribe);
    return [];
  }
  if (subscription instanceof Subscription) {
    subscription.unsubscribe();
  }
}

export function isSubscribed(subscription: Subscription) {
  return !!subscription && !subscription.closed;
}
