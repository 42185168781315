import { NgModule } from '@angular/core';
import { PplAppContainerComponent } from './app-container.component';

@NgModule({
  imports: [],
  declarations: [
    PplAppContainerComponent
  ],
  exports: [
    PplAppContainerComponent
  ],
})
export class PplAppContainerModule {}

export * from './app-container.component';
