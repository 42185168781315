<div #dialogBackdrop
     class="backdrop"
     [class.visible]="dialogVisible"
     (click)="onBackdropClick()"></div>
<div #dialog
     class="dialog"
     [class.pre-visible]="dialogPreVisible"
     [class.visible]="dialogVisible"
     cdkTrapFocus
     (transitionend)="onTransitionEnd($event)">
  <div #dialogHiddenFocus
       *ngIf="data.autoFocus === false"
       tabindex="0"></div>
  <ng-container cdkPortalOutlet></ng-container>
</div>
