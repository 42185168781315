import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import type { PplToggleListOption } from '@ppl/ui/toggle-list';

@Component({
  selector: 'ppl-toggle-list-demo',
  templateUrl: './toggle-list-demo.component.html',
  styleUrls: ['./toggle-list-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleListDemoComponent implements OnInit {
  value: string;

  options: PplToggleListOption[] = [
    {
      value: '1',
      label: 'Example1',
      icon: 'all-stream-blue',
      iconActive: 'all-stream-white',
      suffix: '1'
    },
    {
      value: '2',
      label: 'Example2',
      icon: 'all-stream-blue',
      iconActive: 'all-stream-white',
      suffix: '2'
    },
    {
      value: '3',
      label: 'Example3',
      icon: 'all-stream-blue',
      iconActive: 'all-stream-white',
      suffix: '3'
    },
    {
      value: '4',
      label: 'Example4',
      icon: 'all-stream-blue',
      iconActive: 'all-stream-white',
      suffix: '4'
    },
    {
      value: '5',
      label: 'Example5',
      icon: 'all-stream-blue',
      iconActive: 'all-stream-white',
      suffix: '5'
    },
  ];

  code = `
  <ppl-toggle-list [value]="value"
                   [header]="Example"
                   [options]="options"
                   (valueChange)="value = $event">
  `;

  constructor() { }

  ngOnInit() {
  }

}
