import { PplFormActionsComponent } from './form-actions/form-actions.component';
import { TFormBuilder } from './form-builder';
import { PplFormColumnComponent } from './form-column/form-column.component';
import { PplFormContainerComponent } from './form-container/form-container.component';
import { PplFormGroupComponent } from './form-group/form-group.component';
import { PplFormPopoverActionsComponent } from './form-popover-actions/form-popover-actions.component';
import { PplFormRowComponent } from './form-row/form-row.component';
import { PplButtonModule } from '../button';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { PplPopoverModule } from '../popover';
import { PplPopoverTooltipModule } from '../popover-tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';

const PPL_FORM_COMPONENTS = [
  PplFormContainerComponent,
  PplFormRowComponent,
  PplFormColumnComponent,
  PplFormActionsComponent,
  PplFormGroupComponent,
  PplFormPopoverActionsComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PplButtonModule,
    PplIconModule,
    PplLoadingModule,
    PplPopoverModule,
    PplPopoverTooltipModule
  ],
  providers: [
    { provide: TFormBuilder, useClass: UntypedFormBuilder }
  ],
  declarations: [
    ...PPL_FORM_COMPONENTS
  ],
  exports: [
    ...PPL_FORM_COMPONENTS
  ]
})
export class PplFormModule {
}

export * from './form-actions/form-actions.component';
export * from './form-builder';
export * from './form-group/form-group.component';

