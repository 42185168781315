import { PplPartialSelectComponent } from './partial-select.component';
import { PplCheckboxModule } from '../checkbox';
import { PplMultipleSelectModule } from '../multiple-select';
import { PplRadioModule } from '../radio';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplRadioModule,
    PplCheckboxModule,
    PplMultipleSelectModule,
  ],
  declarations: [
    PplPartialSelectComponent
  ],
  exports: [
    PplPartialSelectComponent
  ]
})
export class PplPartialSelectModule {
}

export * from './partial-select.component';
