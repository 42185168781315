import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-toggle-list',
  templateUrl: './toggle-list.component.html',
  styleUrls: ['./toggle-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplToggleListComponent implements OnInit {

  @Input() value: string;
  @Input() header?: string;
  @Input() options: PplToggleListOption[];
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() disabled?: boolean;

  @Output() valueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  onOptionClick(option: PplToggleListOption) {
    if (this.value !== option.value && !this.disabled) {
      this.value = option.value;
      this.valueChange.emit(option.value);
    }
  }

  isOptionActive(option: PplToggleListOption) {
    return this.value === option.value;
  }

  trackByFn(index, item) {
    return item.value;
  }
}

export interface PplToggleListOption {
  value: string | null;
  label: string;
  icon?: string;
  iconActive?: string;
  suffix?: string;
}
