import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplAccordionComponent } from './accordion.component';
import { PplIconModule } from '../icon';

@NgModule({
  imports: [CommonModule, PplIconModule],
  declarations: [PplAccordionComponent],
  exports: [PplAccordionComponent]
})
export class PplAccordionModule {}

export * from './accordion.component';
