import { fuzzySearch } from '../autocomplete/fuzzy-search';
import { PplOptionListComponent } from '../option-list/option-list.component';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MemoizeLast } from '@ppl/utils';
import type { PplOptionListCategory, PplOptionListOption } from '../option-list/option-list.component';

@Component({
  selector: 'ppl-option-list-with-filter',
  templateUrl: './option-list-with-filter.component.html',
  styleUrls: ['./option-list-with-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplOptionListWithFilterComponent {
  @Input() categories: PplOptionListCategory[];
  @Input() displayCategoriesSidebar?: boolean;
  @Input() options: PplOptionListOption[];
  @Input() autoFocusSearchInput = true;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() value?: string;
  @Input() maxContainerHeight?: number;
  @Input() optionTemplateRowHeight?: number;
  @Input() categoriesSidebarWidth?: number;
  @Input() displaySearch = true;

  @ViewChild(PplOptionListComponent, { static: true }) list: PplOptionListComponent;

  @Output() optionSelect = new EventEmitter<PplOptionListOption | string>();
  @Output() keyEscapePressed = new EventEmitter<null>();

  search = '';
  localValue = null;

  get displayNoResults() {
    return this.filteredOptions.length === 0;
  }

  @MemoizeLast<PplOptionListWithFilterComponent>(['value', 'options', 'search'])
  get filteredOptions() {
    const filteredOptions = fuzzySearch({ list: this.options, term: this.search });

    return filteredOptions;
  }

  @MemoizeLast<PplOptionListWithFilterComponent>(['filteredOptions', 'localValue'])
  get autoSelectValue() {
    const value = this.localValue || this.value;

    if (this.filteredOptions.find(option => option.value === value)) {
      return value;
    } else {
      return (this.filteredOptions.length !== 0 ? this.filteredOptions[0].value : null);
    }
  }

  constructor() { }

  onSearch(searchText: string) {
    if (searchText !== this.search) {
      this.search = searchText;
    }
  }

  onValueChange(value: string) {
    this.localValue = value;
  }

  onOptionSelect(option: PplOptionListOption) {
    this.optionSelect.emit(option);
  }
}
