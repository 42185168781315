import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type { PplItemsChooserOption } from '@ppl/ui/items-chooser';


@Component({
  selector: 'ppl-items-chooser-demo',
  templateUrl: './items-chooser-demo.component.html',
  styleUrls: ['./items-chooser-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsChooserDemoComponent implements OnInit {

  options: PplItemsChooserOption[] = [
    { value: '1', label: 'Option 1' },
    { value: '2', label: 'Option 2' },
    { value: '3', label: 'Option 3', disabled: 'Disabled because I said so' },
    { value: '4', label: 'Option 4' },
    { value: '5', label: 'Option 5' },
    { value: '6', label: 'Option 6', icon: 'order-processing-blue' },
    { value: '7', label: 'Option 7' },
    { value: '8', label: 'Option 8', icon: 'ticketing-integration-blue' },
    { value: '9', label: 'Option 9' },
    { value: '10', label: 'Option 10', disabled: 'I hate 10', icon: 'marketing-communication-system-blue' },
    { value: '11', label: 'Option 11', icon: 'callborder-feed_blue' }
  ];

  errorOptions: PplItemsChooserOption[] = [
    { value: 'error-selected-1', label: 'Meeting', error: 'Some error message' },
    ...this.options.slice(0, 1),
    { value: 'error', label: 'Meeting', error: 'Some error message', icon: 'appointment' },
    ...this.options.slice(1),
    { value: 'error-selected-2', label: 'What!!!', error: 'Option error message', icon: 'sms-messaging-blue' }
  ];

  form: TFormGroup<ItemsChooserDemoFormData>;

  constructor(
    private tFormBuilder: TFormBuilder
  ) {
    this.form = this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    return this.tFormBuilder.group<ItemsChooserDemoFormData>({
      items: [[], Validators.required]
    });
  }

  onSubmitForm() {
    if (this.form.valid) {
      console.log('form submitted');
    } else {
      this.form.markAllAsTouched();
    }
  }

}


interface ItemsChooserDemoFormData {
  items: string[];
}
