import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ppl-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss']
})
export class PplInputErrorComponent {
  @Input() type: 'error' | 'warning' = 'error';

  @HostBinding('class.warning')
  get classWarning() {
    return this.type === 'warning';
  }
}
