import * as CryptoJS from 'crypto-js';

export function jwtEncode(data: object) {
  const header = {
    alg: 'HS256',
    typ: 'JWT'
  };

  const encHeader = toBase64url(CryptoJS.enc.Utf8.parse(JSON.stringify(header)));
  const encData = toBase64url(CryptoJS.enc.Utf8.parse(JSON.stringify(data)));
  const encSignature = toBase64url(CryptoJS.HmacSHA256(`${encHeader}.${encData}`, 'none'));

  return `${encHeader}.${encData}.${encSignature}`;
}

export function jwtDecode(data: string) {
  const result = data.split('.');

  try {
    return JSON.parse(atob(result[1]));
  } catch (ex) {
    return null;
  }
}

function toBase64url(source: CryptoJS.WordArray) {
  // Encode in classical base64
  let encodedSource = CryptoJS.enc.Base64.stringify(source);

  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
}
