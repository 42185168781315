import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'ppl-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplFormContainerComponent implements OnInit {
  @HostBinding('class.transparent') @Input() transparent = false;
  @HostBinding('class.no-padding') @Input() noPadding = false;

  constructor() { }

  ngOnInit() {
  }
}
