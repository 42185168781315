import { GraphItemComponent } from './components/graph-item/graph-item.component';
import { GraphToggleComponent } from './components/graph-toggle/graph-toggle.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '@ppl/ui/icon';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    GraphItemComponent,
    GraphToggleComponent
  ],
  exports: [
    GraphItemComponent,
    GraphToggleComponent
  ]
})
export class PplComponentsGraphModule { }
