import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input , HostBinding , ChangeDetectorRef, ElementRef} from '@angular/core';
import { PplIconService } from './icon.service';
import type { Subscription } from 'rxjs';

export interface PplIconState {
  name: string;
  active: boolean;
}

const HOVER_VALUE = 'hover';
const STATE_DELIMITER = '_';

@Component({
  selector: 'ppl-icon',
  template: '',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplIconComponent implements OnInit, OnChanges, OnDestroy {
  @Input() svgIcon: string;
  @Input() actionElement: Element;
  @Input() hoverEnabled = false;
  @Input() states: PplIconState[] = [];
  @Input() useSvg = false;
  @Input() @HostBinding('style.--ppl-icon-color') color?: string;

  @HostBinding('style.width') @Input() width = '20px';
  @HostBinding('style.height') @Input() height = '20px';

  private element: Element;
  private isHovered: boolean;
  private iconsLoadedSubscription: Subscription;

  private mouseEnterHandler: EventListener;
  private mouseLeaveHandler: EventListener;

  constructor(public elementRef: ElementRef, private iconService: PplIconService, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['svgIcon'] && !changes['svgIcon'].isFirstChange() && changes['svgIcon'].previousValue !== changes['svgIcon'].currentValue) ||
      (changes['states'] && this.hasStateChanged(changes['states'].previousValue, changes['states'].currentValue))
    ) {
      this.initStates();
    }

    if (changes.hoverEnabled && !changes.hoverEnabled.firstChange && changes.hoverEnabled.currentValue !== changes.hoverEnabled.previousValue) {
      if (changes.hoverEnabled.currentValue) {
        this.registerHoverListeners();
      } else {
        this.unregisterHoverListeners();
      }
    }
  }

  ngOnInit() {
    if (!this.iconService.isLoaded$.getValue()) {
      this.iconsLoadedSubscription = this.iconService.isLoaded$.subscribe(() => {
        this.initStates();
      });
    }

    this.element = this.actionElement || this.elementRef.nativeElement;
    this.initStates();

    if (this.hoverEnabled) {
      this.registerHoverListeners();
    }
  }

  ngOnDestroy() {
    if (this.hoverEnabled) {
      this.unregisterHoverListeners();
    }
    if (this.iconsLoadedSubscription) {
      this.iconsLoadedSubscription.unsubscribe();
    }
  }

  registerHoverListeners() {
    this.element.addEventListener('mouseenter', this.mouseEnterHandler = this.onMouseEnter.bind(this));
    this.element.addEventListener('mouseleave', this.mouseLeaveHandler = this.onMouseLeave.bind(this));
  }

  unregisterHoverListeners() {
    this.element.removeEventListener('mouseenter', this.mouseEnterHandler);
    this.element.removeEventListener('mouseleave', this.mouseLeaveHandler);
  }

  onMouseEnter() {
    this.isHovered = true;
    this.initStates();
  }

  onMouseLeave() {
    this.isHovered = false;
    this.initStates();
  }

  private hasStateChanged(previousValue: PplIconState[] | null, currentValue: PplIconState[] | null) {
    if (!currentValue || !previousValue) {
      return true;
    } else {
      return !!currentValue.find((state, index) => previousValue[index].name !== state.name || previousValue[index].active !== state.active);
    }
  }

  private initStates() {
    if (this.element) {
      let iconName = this.svgIcon;
      const iconStates = new Set();

      this.states.forEach((state) => {
        if (state.active) {
          iconStates.add(state.name);
        }
      });

      if (this.isHovered && !iconStates.has(HOVER_VALUE)) {
        iconStates.add(HOVER_VALUE);
      }

      if (iconStates.size > 0) {
        iconName = iconName + STATE_DELIMITER + Array.from(iconStates).join(STATE_DELIMITER);
      }

      const baseIcon = this.iconService.icons[this.svgIcon];
      const resultIcon = this.iconService.icons[iconName];
      const icon = resultIcon ? resultIcon : baseIcon ? baseIcon : null;

      if (icon) {
        if (!this.useSvg) {
          this.elementRef.nativeElement.style.background = `url(${icon.dataURL}) no-repeat center`;
          return;
        }

        this.elementRef.nativeElement.innerHTML = icon.innerHTML;
      }
    }
  }
}
