<div (click)="onSliderClick()"
     class="slider"
     [ngClass]="{
        'slider-left': position === 'left',
        'slider-right': position === 'right',
        'slider-top': position === 'top',
        'slider-bottom': position === 'bottom'
      }"
     [class.slider-horizontal]="horizontal"
     [class.slider-disabled]="disabled">
  <ppl-icon [svgIcon]="open ? 'rowrollup-context_close' : 'rowrollup-context_open'"
            width="5px"
            height="10px"></ppl-icon>
</div>