import type { ToastContentInterface } from '../toast';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppl-toast-message',
  templateUrl: 'toast-message.component.html',
  styleUrls: ['toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastMessageComponent implements OnInit, ToastContentInterface {

  closeToast$ = new Subject<null>();
  actionClicked?: () => void;

  message: string;
  actionText: string;

  constructor() {
  }

  ngOnInit() {
  }

  onActionClicked() {
    this.actionClicked?.();
    this.dismiss();
  }

  dismiss() {
    this.closeToast$.next();
    this.closeToast$.complete();
  }
}
