import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  forwardRef
,
  ChangeDetectorRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';

@Component({
  selector: 'ppl-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplToggleGroupComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplToggleGroupComponent implements OnInit {

  @Input() value: PplToggleGroupValue = null;
  @Input() options: PplToggleGroupOption[];
  @Input() selection: PplToggleGroupOptionSelection = 'multiple';
  @Input()
  @HostBinding('class.full-width')
  fullWidth = false;
  @Input()
  @HostBinding('class.variable-button-width')
  variableButtonWidth = false;
  @Input()
  @HostBinding('class.disabled')
  disabled = false;
  @HostBinding('class.has-icons')
  hasIcons = false;
  @HostBinding('class.has-colors')
  hasColors = false;
  // indicates, whether visual feedback is immediate or the toggled attribute is changed explicitely later on
  @Input() toggleImmediatelly = true;

  @Output() valueChange: EventEmitter<PplToggleGroupValue> = new EventEmitter();

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.hasIcons = !!this.options.find(option => 'icon' in option);
    this.hasColors = !!this.options.find(option => 'color' in option);
  }

  isSelected(option: PplToggleGroupOption) {
    switch (this.selection) {
      case 'multiple':
        return ((this.value as string[]) || []).find(foundValue => foundValue === option.value);
      case 'one':
        return ((this.value as string) || '') === option.value;
    }
  }

  onButtonClick(option: PplToggleGroupOption) {
    if (this.disabled || option.disabled) {
      return;
    }

    switch (this.selection) {
      case 'multiple':
        const selectedValues = (this.value as string[]) || [];
        let newSelectedValues;
        if (selectedValues.includes(option.value)) {
          newSelectedValues = selectedValues.filter(selectedValue => selectedValue !== option.value);
        } else {
          newSelectedValues = [...selectedValues, option.value];
        }

        if (this.toggleImmediatelly) {
          this.value = newSelectedValues;
        }
        this.valueChange.emit(newSelectedValues);
        break;
      case 'one':
        if (option.value !== this.value) {

          if (this.toggleImmediatelly) {
            this.value = option.value;
          }
          this.valueChange.emit(option.value);
        }
        break;
    }
  }

}

export type PplToggleGroupValue = string | string[] | null;
export type PplToggleGroupOptionSelection = 'multiple' | 'one';
export type PplToggleGroupOptionIcon =
  | string
  | {
    name: string;
    width: string;
    height: string;
  };

export interface PplToggleGroupOption<T = string> {
  value: T;
  label: string;
  title?: string;
  icon?: PplToggleGroupOptionIcon;
  color?: string;
  disabled?: boolean;
}
