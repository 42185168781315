import { PplDialogContainerComponent } from './dialog-container/dialog-container.component';
import { PplDialogMessageComponent } from './dialog-message/dialog-message.component';
import { DialogOverlayComponent } from './dialog-overlay/dialog-overlay.component';
import { PplDialogService } from './dialog-service';
import { PplDialogComponent } from './dialog/dialog.component';
import { PplButtonModule } from '../button';
import { PplFormModule } from '../form';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplBadgeModule } from '../badge';

@NgModule({
    imports: [
        CommonModule,
        A11yModule,
        PortalModule,
        PplBadgeModule,
        PplButtonModule,
        PplIconModule,
        PplLoadingModule,
        PplFormModule
    ],
    declarations: [
        PplDialogComponent,
        PplDialogMessageComponent,
        PplDialogContainerComponent,
        DialogOverlayComponent
    ],
    exports: [
        PplDialogComponent,
        PplDialogMessageComponent,
        DialogOverlayComponent
    ],
    providers: [
        PplDialogService
    ]
})
export class PplDialogModule { }

export * from './dialog-ref';
export * from './dialog-service';
export * from './dialog-message/dialog-message.component';
export * from './dialog-overlay/dialog-overlay.component';
