import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import type {
  AfterViewInit,
  OnDestroy,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-popover-content',
  templateUrl: './popover-content.component.html',
  styleUrls: ['./popover-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplPopoverContentComponent implements AfterViewInit, OnDestroy {

  @ViewChild('tooltip', { read: ViewContainerRef, static: false }) tooltip: ViewContainerRef;

  anchorElement: Element;
  hostElement: Element;
  contentTemplate: TemplateRef<any>;
  popoverDirective: {
    hide: () => void;
  };

  data: any;

  direction: 'up' | 'down' | 'left' | 'right';
  alignStart: boolean;
  alignEnd: boolean;

  anchorRect: PplPopoverAnchorRect | null = null;

  mouseUp: () => void;
  mouseEnter: () => void;
  mouseLeave: () => void;
  resizeScrollParent: () => void;

  closeRequest: (eventTarget: Element) => void;

  mouseDownListener: any;
  mouseUpListener: any;
  mouseEnterListener: any;
  mouseLeaveListener: any;

  exactCalculations: boolean;
  pointerEventsNone: boolean;

  computedAlignStart: boolean;
  computedAlignEnd: boolean;
  computedDirection: string;

  popoverControl: PplPopoverControl;

  closeOnAnyLevel = false;

  popoverComponentInstance: any;
  pplPopoverComponent: any;
  pplPopoverComponentData: any;
  recalcTimeout: any;

  tooltipInjector: Injector;


  private preventClose = false;
  private preventOutsideClose = false;
  private scrollableElements: Element[] = [];

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    public changeDetector: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.popoverControl = {
      recalculatePosition: this.recalcPosition.bind(this),
      positionRecalculated: () => { },
      togglePreventClose: (state: boolean) => {
        this.preventClose = state;
      },
      setStyle: (style: Partial<CSSStyleDeclaration>) => {
        const host = this.elementRef.nativeElement as HTMLElement;
        for (const [key, value] of Object.entries(style)) {
          host.style[key] = value;
        }
      },
      close: () => {
        this.popoverDirective.hide();
      }
    };
  }

  ngAfterViewInit() {
    if (this.pplPopoverComponent && this.pplPopoverComponentData) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.pplPopoverComponent);
      const viewContainerRef = this.tooltip;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      this.popoverComponentInstance = (componentRef.instance as PplPopoverComponentInterface);
      Object.assign(this.popoverComponentInstance, this.pplPopoverComponentData);
      this.popoverComponentInstance.direction = this.direction as ('up' | 'down');
      this.popoverComponentInstance.hostElement = this.hostElement;
      this.changeDetector.detectChanges();
    }

    if (this.pointerEventsNone) {
      this.elementRef.nativeElement.style.pointerEvents = 'none';
    }
  }

  ngOnDestroy() {
    if (this.mouseUp) {
      document.removeEventListener('mousedown', this.mouseDownListener);
      document.removeEventListener('mouseup', this.mouseUpListener);

      this.scrollableElements.forEach(element => element.removeEventListener('scroll', this.mouseUp));
      this.scrollableElements = [];

      window.removeEventListener('resize', this.mouseUp);
    } else if (this.mouseLeave) {
      this.elementRef.nativeElement.removeEventListener('mouseenter', this.mouseEnterListener);
      this.elementRef.nativeElement.removeEventListener('mouseleave', this.mouseLeaveListener);
      clearTimeout(this.recalcTimeout);
    } else if (this.resizeScrollParent) {
      this.scrollableElements.forEach(element => element.removeEventListener('scroll', this.resizeScrollParent));
      this.scrollableElements = [];

      window.removeEventListener('resize', this.resizeScrollParent);
    }
  }

  init() {
    if (this.mouseUp) {
      document.addEventListener('mousedown', this.mouseDownListener = this.onMouseDown.bind(this));
      document.addEventListener('mouseup', this.mouseUpListener = this.onMouseUp.bind(this));

      this.scrollableElements = this.getParentScrollableElements(this.hostElement);
      this.scrollableElements.forEach(element => element.addEventListener('scroll', this.mouseUp));

      window.addEventListener('resize', this.mouseUp);
    } else if (this.mouseLeave) {
      this.elementRef.nativeElement.addEventListener('mouseenter', this.mouseEnterListener = this.onMouseEnter.bind(this));
      this.elementRef.nativeElement.addEventListener('mouseleave', this.mouseLeaveListener = this.onMouseLeave.bind(this));
    } else if (this.resizeScrollParent) {
      this.scrollableElements = this.getParentScrollableElements(this.hostElement);
      this.scrollableElements.forEach(element => element.addEventListener('scroll', this.resizeScrollParent));

      window.addEventListener('resize', this.resizeScrollParent);
    }
  }

  focusFirstInput() {
    const firstInput = this.elementRef.nativeElement.querySelector('input');

    if (firstInput) {
      firstInput.focus();
    }
  }

  recalcPosition() {
    let direction: string = this.direction;
    let alignStart = this.alignStart;
    let alignEnd = this.alignEnd;

    let anchorRect: any = this.anchorRect || this.anchorElement.getBoundingClientRect();

    // "Fix" for floating point issues in Chrome
    if (!this.exactCalculations) {
      anchorRect = {
        bottom: anchorRect.bottom + 1,
        left: anchorRect.left - 1,
        right: anchorRect.right + 1,
        top: anchorRect.top - 1,
        width: anchorRect.width + 2,
        height: anchorRect.height + 2
      };
    }

    const anchorCenterX = anchorRect.left + anchorRect.width / 2;
    const anchorCenterY = anchorRect.top + anchorRect.height / 2;

    const container = this.elementRef.nativeElement as HTMLElement;
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let stretchMainAxis = false;
    let centerCrossAxis = false;
    let stretchCrossAxis = false;

    // Set values by orientation
    let nearDirection = 'up';
    let farDirection = 'down';

    let mainContainerSize = containerHeight;
    let mainWindowSize = windowHeight;
    let mainNearKey = 'top';
    let mainFarKey = 'bottom';

    let crossContainerSize = containerWidth;
    let crossWindowSize = windowWidth;
    let crossCenter = anchorCenterX;
    let crossNearKey = 'left';
    let crossFarKey = 'right';

    if (this.direction === 'left' || this.direction === 'right') {
      nearDirection = 'left';
      farDirection = 'right';

      mainContainerSize = containerWidth;
      mainWindowSize = windowWidth;
      mainNearKey = 'left';
      mainFarKey = 'right';

      crossContainerSize = containerHeight;
      crossWindowSize = windowHeight;
      crossCenter = anchorCenterY;
      crossNearKey = 'top';
      crossFarKey = 'bottom';
    }

    // 1. Check available space and flip direction and/or add scrollbars if necessary
    // Main axis
    const dtMainNear = anchorRect[mainNearKey] - mainContainerSize;
    const dtMainFar = mainWindowSize - (anchorRect[mainFarKey] + mainContainerSize);

    if (direction === nearDirection && dtMainNear < 0) {
      if (dtMainFar > dtMainNear) {
        direction = farDirection;

        if (dtMainFar < 0) {
          stretchMainAxis = true;
        }
      } else {
        stretchMainAxis = true;
      }
    } else if (direction === farDirection && dtMainFar < 0) {
      if (dtMainNear > dtMainFar) {
        direction = nearDirection;

        if (dtMainNear < 0) {
          stretchMainAxis = true;
        }
      } else {
        stretchMainAxis = true;
      }
    }

    // Cross axis
    const dtCrossNear = anchorRect[crossFarKey] - crossContainerSize;
    const dtCrossFar = crossWindowSize - (anchorRect[crossNearKey] + crossContainerSize);

    if (!alignStart && !alignEnd) {
      if (crossCenter + Math.round(crossContainerSize / 2) > crossWindowSize) {
        alignStart = false;
        alignEnd = true;
      } else if (crossCenter - Math.round(crossContainerSize / 2) < 0) {
        alignStart = true;
        alignEnd = false;
      }
    }

    if (alignStart && !alignEnd && dtCrossFar < 0) {
      if (dtCrossNear >= 0) {
        alignStart = false;
        alignEnd = true;
      } else if (crossWindowSize > crossContainerSize) {
        centerCrossAxis = true;
      } else {
        stretchCrossAxis = true;
      }
    } else if (!alignStart && alignEnd && dtCrossNear < 0) {
      if (dtCrossFar >= 0) {
        alignStart = true;
        alignEnd = false;
      } else if (crossWindowSize > crossContainerSize) {
        centerCrossAxis = true;
      } else {
        stretchCrossAxis = true;
      }
    }

    // 2. Calculate position
    let position: any = {};

    // Main axis
    if (direction === nearDirection) {
      position = { ...position, [mainFarKey]: mainWindowSize - anchorRect[mainNearKey] };

      if (stretchMainAxis) {
        position = { ...position, [mainNearKey]: 0 };
      }
    } else if (direction === farDirection) {
      position = { ...position, [mainNearKey]: anchorRect[mainFarKey] };

      if (stretchMainAxis) {
        position = { ...position, [mainFarKey]: 0 };
      }
    }

    // Cross axis
    if (centerCrossAxis) {
      position = { ...position, [crossNearKey]: Math.floor((crossWindowSize - crossContainerSize) / 2) };
    } else if (stretchCrossAxis) {
      position = { ...position, [crossNearKey]: 0, [crossFarKey]: 0 };
    } else {
      if (alignStart || alignEnd) {
        if (alignStart) {
          position = { ...position, [crossNearKey]: anchorRect[crossNearKey] };
        }

        if (alignEnd) {
          position = { ...position, [crossFarKey]: crossWindowSize - anchorRect[crossFarKey] };
        }
      } else {
        position = { ...position, [crossNearKey]: Math.round(crossCenter - Math.round(crossContainerSize / 2)) };
      }
    }

    ['top', 'bottom', 'left', 'right'].forEach(key => {
      container.style[key] = (key in position) ? `${position[key]}px` : 'auto';
    });

    this.computedAlignStart = alignStart;
    this.computedAlignEnd = alignEnd;
    this.computedDirection = direction;

    this.changeDetector.detectChanges();

    if (this.popoverComponentInstance && typeof this.popoverComponentInstance.recalcPosition === 'function') {
      this.popoverComponentInstance.recalcPosition();
    }
    if (this.popoverControl.positionRecalculated) {
      this.popoverControl.positionRecalculated();
    }
  }

  onMouseDown(event: MouseEvent) {
    // Do not close popover if mouse event starts at input element (i.e. selecting text)
    if ((event.target as Element).nodeName.toLowerCase() === 'input') {
      this.preventOutsideClose = true;
    }
  }

  onMouseUp(event: MouseEvent) {
    const eventTarget = event.target as Element;
    const openPopovers = [
      ...Array.from(document.querySelectorAll('ppl-popover-content')),
      ...Array.from(document.querySelectorAll('ppl-extract-container'))
    ];

    if (this.preventOutsideClose) {
      this.preventOutsideClose = false;
      return;
    }

    if (this.preventClose) {
      return;
    }

    this.closeRequest.call(this, eventTarget);

    // Only close popovers on top
    if (openPopovers.indexOf(this.elementRef.nativeElement) === openPopovers.length - 1 || this.closeOnAnyLevel) {
      const closeElements = Array.from<Element>(this.elementRef.nativeElement.querySelectorAll('*[pplpopoverclose]'));

      const closeElementsDelay = Array.from<Element>(this.elementRef.nativeElement.querySelectorAll('*[pplPopoverDelayClose]'));

      if (!this.hostElement.contains(eventTarget) && !this.elementRef.nativeElement.contains(eventTarget)) {
        // Close popover by clicking outside content element
        this.mouseUp.call(this);
      } else if (closeElements.length > 0 && closeElements.some(closeElement => closeElement.contains(eventTarget))) {
        // Close popover by clicking on content element with pplPopoverClose directive (uses setTimeout, otherwise destroyed element will not fire click event)
        setTimeout(() => this.mouseUp.call(this), 10);
      } else if (closeElementsDelay.length > 0 && closeElementsDelay.some(closeDelayElement => closeDelayElement.contains(eventTarget))) {
        // Close popover with delay by clicking on content element with pplPopoverDelayClose directive (uses setTimeout, otherwise destroyed element will not fire click event)
        setTimeout(() => this.mouseUp.call(this), 1500);
      }
    }
  }

  onMouseEnter() {
    if (this.preventClose) {
      return;
    }

    this.mouseEnter.call(this);
  }

  onMouseLeave() {
    if (this.preventClose) {
      return;
    }

    this.mouseLeave.call(this);
  }

  getParentScrollableElements(node: Element) {
    const nodes: Element[] = [];

    while (node) {
      node = node.parentNode as Element;
      if (node && node instanceof Element && node.scrollHeight > node.clientHeight && node.clientHeight > 0) {
        nodes.push(node);
      }
    }

    return nodes;
  }

  setPopoverComponentData(pplPopoverComponentData: any) {
    this.pplPopoverComponentData = pplPopoverComponentData;
    if (this.popoverComponentInstance) {
      Object.assign(this.popoverComponentInstance, this.pplPopoverComponentData);
      if ('changeDetectorRef' in this.popoverComponentInstance) {
        this.popoverComponentInstance.changeDetectorRef.detectChanges();
      }
    }

    if (this.popoverComponentInstance && typeof this.popoverComponentInstance.recalcPosition === 'function') {
      this.popoverComponentInstance.recalcPosition();
    }
  }
}

export interface PplPopoverPosition {
  alignStart: boolean;
  alignEnd: boolean;
  direction: string;
  initDirection: string;
}

export interface PplPopoverControl {
  recalculatePosition: () => void;
  positionRecalculated: () => void;
  togglePreventClose: (boolean) => void;
  setStyle: (style: Partial<CSSStyleDeclaration>) => void;
  close: () => void;
}

export interface PplPopoverAnchorRect {
  bottom: number;
  left: number;
  right: number;
  top: number;
  width: number;
  height: number;
}

export interface PplPopoverComponentInterface {
  text: string | TemplateRef<any>;
  direction: 'up' | 'down' | 'left' | 'right';
  hostElement: Element;
  recalcPosition(): void;
}
