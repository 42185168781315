export const KEYCODE_UP = 38;
export const KEYCODE_DOWN = 40;
export const KEYCODE_ENTER = 13;
export const KEYCODE_SPACE = 32;
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_BACKSPACE = 8;
export const KEYCODE_PAGE_UP = 33;
export const KEYCODE_PAGE_DOWN = 34;
export const KEYCODE_HOME = 36;
export const KEYCODE_END = 35;
export const KEYCODE_LEFT = 37;
export const KEYCODE_RIGHT = 39;
export const KEYCODE_TAB = 9;

// NOTE: keycode is depricated
export const KEYEVENTCODE_ENTER = 'Enter';
export const KEYEVENTCODE_TAB = 'Tab';
