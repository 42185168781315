<div [style.backgroundColor]="'#fff'"
     [style.boxShadow]="'0 0 8px #aaa'"
     [style.borderRadius.px]="4">
  <div class="content">
    <p>Toast Test Content #{{index}}</p>
    <button pplButton
            pplButton1
            pplButtonSmall
            (click)="onClose()">Close</button>
  </div>
</div>
