<ppl-dialog title="Edit Phone Number"
            i18n-title="@@Edit_Phone_Number"
            [loading]="updating$ | async"
            (cancel)="onCancel()"
            (action)="onSave()"
            [formGroup]="form"
            width="620px">
  <ppl-calling-phone-edit formControlName="phone"
                          [phone]="phone"
                          [displayForwardingSettings]="displayForwardingSettings"></ppl-calling-phone-edit>
</ppl-dialog>
