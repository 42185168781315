export const TranslationsEntities = {
  EnumEntityNameEnum_Account: $localize`:@@EnumEntityNameEnum_Account:%Account_s%`,
  EnumEntityNameEnum_AccountDataExRelation: $localize`:@@EnumEntityNameEnum_AccountDataExRelation:AccountDataExRelation`,
  EnumEntityNameEnum_AccountKPI: $localize`:@@EnumEntityNameEnum_AccountKPI:KPI`,
  EnumEntityNameEnum_AccountSharingClientRelation: $localize`:@@EnumEntityNameEnum_AccountSharingClientRelation:AccountSharingClientRelation`,
  EnumEntityNameEnum_AccountSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_AccountSharingSalesUnitRelation:AccountSharingSalesUnitRelation`,
  EnumEntityNameEnum_AccountSocialRelation: $localize`:@@EnumEntityNameEnum_AccountSocialRelation:AccountSocialRelation`,
  EnumEntityNameEnum_AccountType: $localize`:@@EnumEntityNameEnum_AccountType:%Account_s% Type`,
  EnumEntityNameEnum_Activity: $localize`:@@EnumEntityNameEnum_Activity:Activity`,
  EnumEntityNameEnum_ActivityComment: $localize`:@@EnumEntityNameEnum_ActivityComment:Activity Comment`,
  EnumEntityNameEnum_ActivityDataExRelation: $localize`:@@EnumEntityNameEnum_ActivityDataExRelation:ActivityDataExRelation`,
  EnumEntityNameEnum_ActivityKPI: $localize`:@@EnumEntityNameEnum_ActivityKPI:ActivityKPI`,
  EnumEntityNameEnum_ActivityRelation: $localize`:@@EnumEntityNameEnum_ActivityRelation:ActivityRelation`,
  EnumEntityNameEnum_ActivityType: $localize`:@@EnumEntityNameEnum_ActivityType:Activity Type`,
  EnumEntityNameEnum_AllowedPipeline: $localize`:@@EnumEntityNameEnum_AllowedPipeline:Allowed Pipeline`,
  EnumEntityNameEnum_Appointment: $localize`:@@EnumEntityNameEnum_Appointment:Appointment`,
  EnumEntityNameEnum_AppointmentClientInviteesRelation: $localize`:@@EnumEntityNameEnum_AppointmentClientInviteesRelation:AppointmentClientInviteesRelation`,
  EnumEntityNameEnum_AppointmentContactInviteesRelation: $localize`:@@EnumEntityNameEnum_AppointmentContactInviteesRelation:AppointmentContactInviteesRelation`,
  EnumEntityNameEnum_AppointmentReminder: $localize`:@@EnumEntityNameEnum_AppointmentReminder:Appointment Reminder`,
  EnumEntityNameEnum_AppointmentType: $localize`:@@EnumEntityNameEnum_AppointmentType:Appointment Type`,
  EnumEntityNameEnum_Client: $localize`:@@EnumEntityNameEnum_Client:Client`,
  EnumEntityNameEnum_ClientFavorite: $localize`:@@EnumEntityNameEnum_ClientFavorite:ClientFavorite`,
  EnumEntityNameEnum_ClientSetting: $localize`:@@EnumEntityNameEnum_ClientSetting:ClientSetting`,
  EnumEntityNameEnum_ClientStoryRead: $localize`:@@EnumEntityNameEnum_ClientStoryRead:ClientStoryRead`,
  EnumEntityNameEnum_CloudObject: $localize`:@@EnumEntityNameEnum_CloudObject:Document`,
  EnumEntityNameEnum_CloudObjectFolder: $localize`:@@EnumEntityNameEnum_CloudObjectFolder:Folder`,
  EnumEntityNameEnum_CloudObjectRelation: $localize`:@@EnumEntityNameEnum_CloudObjectRelation:CloudObjectRelation`,
  EnumEntityNameEnum_CloudObjectStepRelation: $localize`:@@EnumEntityNameEnum_CloudObjectStepRelation:CloudObjectStepRelation`,
  EnumEntityNameEnum_Contact: $localize`:@@EnumEntityNameEnum_Contact:%Contact_s%`,
  EnumEntityNameEnum_ContactAccountRelation: $localize`:@@EnumEntityNameEnum_ContactAccountRelation:ContactAccountRelation`,
  EnumEntityNameEnum_ContactDataExRelation: $localize`:@@EnumEntityNameEnum_ContactDataExRelation:ContactDataExRelation`,
  EnumEntityNameEnum_ContactKPI: $localize`:@@EnumEntityNameEnum_ContactKPI:ContactKPI`,
  EnumEntityNameEnum_ContactSharingClientRelation: $localize`:@@EnumEntityNameEnum_ContactSharingClientRelation:ContactSharingClientRelation`,
  EnumEntityNameEnum_ContactSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_ContactSharingSalesUnitRelation:ContactSharingSalesUnitRelation`,
  EnumEntityNameEnum_ContactSocialRelation: $localize`:@@EnumEntityNameEnum_ContactSocialRelation:ContactSocialRelation`,
  EnumEntityNameEnum_ContactType: $localize`:@@EnumEntityNameEnum_ContactType:%Contact_s% Type`,
  EnumEntityNameEnum_Currency: $localize`:@@EnumEntityNameEnum_Currency:Currency`,
  EnumEntityNameEnum_CurrencyExchangeRate: $localize`:@@EnumEntityNameEnum_CurrencyExchangeRate:Currency Exchange Rate`,
  EnumEntityNameEnum_CurrencyExchangeRatesList: $localize`:@@EnumEntityNameEnum_CurrencyExchangeRatesList:Currency Exchange Rates List`,
  EnumEntityNameEnum_Data: $localize`:@@EnumEntityNameEnum_Data:Data`,
  EnumEntityNameEnum_DataRelation: $localize`:@@EnumEntityNameEnum_DataRelation:Data Relation`,
  EnumEntityNameEnum_Email: $localize`:@@EnumEntityNameEnum_Email:E-mail`,
  EnumEntityNameEnum_EmailSequence: $localize`:@@EnumEntityNameEnum_EmailSequence:Email Sequence`,
  EnumEntityNameEnum_Entity: $localize`:@@EnumEntityNameEnum_Entity:Entity`,
  EnumEntityNameEnum_EntityType: $localize`:@@EnumEntityNameEnum_EntityType:Entity Type`,
  EnumEntityNameEnum_Field: $localize`:@@EnumEntityNameEnum_Field:Field`,
  EnumEntityNameEnum_FieldSequence: $localize`:@@EnumEntityNameEnum_FieldSequence:Field Auto Number`,
  EnumEntityNameEnum_Forecast: $localize`:@@EnumEntityNameEnum_Forecast:Forecast`,
  EnumEntityNameEnum_Target: $localize`:@@EnumEntityNameEnum_Target:Target`,
  EnumEntityNameEnum_Lead: $localize`:@@EnumEntityNameEnum_Lead:%Lead_s%`,
  EnumEntityNameEnum_LeadOppty: $localize`:@@EnumEntityNameEnum_LeadOppty:%Lead_s% and %Opportunity_s%`,
  EnumEntityNameEnum_LeadOpptyAccountRelation: $localize`:@@EnumEntityNameEnum_LeadOpptyAccountRelation:LeadOpptyAccountRelation`,
  EnumEntityNameEnum_LeadOpptyContactRelation: $localize`:@@EnumEntityNameEnum_LeadOpptyContactRelation:LeadOpptyContactRelation`,
  EnumEntityNameEnum_LeadOpptyContactRelationInfluenceLine: $localize`:@@EnumEntityNameEnum_LeadOpptyContactRelationInfluenceLine:LeadOpptyContactRelationInfluenceLine`,
  EnumEntityNameEnum_LeadOpptyContactSalesRoleRelation: $localize`:@@EnumEntityNameEnum_LeadOpptyContactSalesRoleRelation:LeadOpptyContactSalesRoleRelation`,
  EnumEntityNameEnum_LeadOpptyDataExRelation: $localize`:@@EnumEntityNameEnum_LeadOpptyDataExRelation:LeadOpptyDataExRelation`,
  EnumEntityNameEnum_LeadOpptyKPI: $localize`:@@EnumEntityNameEnum_LeadOpptyKPI:LeadOpptyKPI`,
  EnumEntityNameEnum_LeadOpptySharingClientRelation: $localize`:@@EnumEntityNameEnum_LeadOpptySharingClientRelation:LeadOpptySharingClientRelation`,
  EnumEntityNameEnum_LeadOpptySharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_LeadOpptySharingSalesUnitRelation:LeadOpptySharingSalesUnitRelation`,
  EnumEntityNameEnum_LeadOpptyStepCheckListRelation: $localize`:@@EnumEntityNameEnum_LeadOpptyStepCheckListRelation:LeadOpptyStepCheckListRelation`,
  EnumEntityNameEnum_LeadOpptyType: $localize`:@@EnumEntityNameEnum_LeadOpptyType:LeadOpptyType`,
  EnumEntityNameEnum_LeadType: $localize`:@@EnumEntityNameEnum_LeadType:%Lead_s% Type`,
  EnumEntityNameEnum_MasterRight: $localize`:@@EnumEntityNameEnum_MasterRight:Master Right`,
  EnumEntityNameEnum_Memo: $localize`:@@EnumEntityNameEnum_Memo:Message`,
  EnumEntityNameEnum_MemoComment: $localize`:@@EnumEntityNameEnum_MemoComment:Memo Comment`,
  EnumEntityNameEnum_Message: $localize`:@@EnumEntityNameEnum_Message:Message`,
  EnumEntityNameEnum_MessageRelation: $localize`:@@EnumEntityNameEnum_MessageRelation:Message Relation`,
  EnumEntityNameEnum_MessageSharingClientRelation: $localize`:@@EnumEntityNameEnum_MessageSharingClientRelation:Message Sharing Client Relation`,
  EnumEntityNameEnum_MessageSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_MessageSharingSalesUnitRelation:Message Sharing Sales Unit Relation`,
  EnumEntityNameEnum_Note: $localize`:@@EnumEntityNameEnum_Note:Note`,
  EnumEntityNameEnum_Opportunity: $localize`:@@EnumEntityNameEnum_Opportunity:%Opportunity_s%`,
  EnumEntityNameEnum_OpportunityType: $localize`:@@EnumEntityNameEnum_OpportunityType:%Opportunity_s% Type`,
  EnumEntityNameEnum_OpptyProductRelation: $localize`:@@EnumEntityNameEnum_OpptyProductRelation:Product Line Item`,
  EnumEntityNameEnum_OpptyRecurrence: $localize`:@@EnumEntityNameEnum_OpptyRecurrence:Recurrence`,
  EnumEntityNameEnum_ParentAccountRelationType: $localize`:@@EnumEntityNameEnum_ParentAccountRelationType:ParentAccountRelationType`,
  EnumEntityNameEnum_Pipeline: $localize`:@@EnumEntityNameEnum_Pipeline:Pipeline`,
  EnumEntityNameEnum_Process: $localize`:@@EnumEntityNameEnum_Process:Process`,
  EnumEntityNameEnum_Product: $localize`:@@EnumEntityNameEnum_Product:Product`,
  EnumEntityNameEnum_ProductCategory: $localize`:@@EnumEntityNameEnum_ProductCategory:Product Category`,
  EnumEntityNameEnum_ProductPipelineRelation: $localize`:@@EnumEntityNameEnum_ProductPipelineRelation:ProductPipelineRelation`,
  EnumEntityNameEnum_ProductPriceList: $localize`:@@EnumEntityNameEnum_ProductPriceList:Product Price List`,
  EnumEntityNameEnum_ProductPriceListPrice: $localize`:@@EnumEntityNameEnum_ProductPriceListPrice:Product Price List Price`,
  EnumEntityNameEnum_Profile: $localize`:@@EnumEntityNameEnum_Profile:Profile`,
  EnumEntityNameEnum_ProfileSharingClientRelation: $localize`:@@EnumEntityNameEnum_ProfileSharingClientRelation:ProfileSharingClientRelation`,
  EnumEntityNameEnum_ProfileSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_ProfileSharingSalesUnitRelation:ProfileSharingSalesUnitRelation`,
  EnumEntityNameEnum_Project: $localize`:@@EnumEntityNameEnum_Project:Project`,
  EnumEntityNameEnum_Quote: $localize`:@@EnumEntityNameEnum_Quote:%Quote_s%`,
  EnumEntityNameEnum_QuoteAccountRelation: $localize`:@@EnumEntityNameEnum_QuoteAccountRelation:QuoteAccountRelation`,
  EnumEntityNameEnum_QuoteContactRelation: $localize`:@@EnumEntityNameEnum_QuoteContactRelation:QuoteContactRelation`,
  EnumEntityNameEnum_Report: $localize`:@@EnumEntityNameEnum_Report:Report`,
  EnumEntityNameEnum_ReportFolder: $localize`:@@EnumEntityNameEnum_ReportFolder:Folder`,
  EnumEntityNameEnum_ReportSharingClientRelation: $localize`:@@EnumEntityNameEnum_ReportSharingClientRelation:ReportSharingClientRelation`,
  EnumEntityNameEnum_ReportSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_ReportSharingSalesUnitRelation:ReportSharingSalesUnitRelation`,
  EnumEntityNameEnum_SalesRole: $localize`:@@EnumEntityNameEnum_SalesRole:Sales Role`,
  EnumEntityNameEnum_SalesUnit: $localize`:@@EnumEntityNameEnum_SalesUnit:Sales Unit`,
  EnumEntityNameEnum_SalesUnitClientRelation: $localize`:@@EnumEntityNameEnum_SalesUnitClientRelation:SalesUnitClientRelation`,
  EnumEntityNameEnum_Step: $localize`:@@EnumEntityNameEnum_Step:Step`,
  EnumEntityNameEnum_StepChecklist: $localize`:@@EnumEntityNameEnum_StepChecklist:Step sales activities`,
  EnumEntityNameEnum_StepChecklistActivityTemplate: $localize`:@@EnumEntityNameEnum_StepChecklistActivityTemplate:StepChecklistActivityTemplate`,
  EnumEntityNameEnum_StepChecklistActivityTplSharingClientRelation: $localize`:@@EnumEntityNameEnum_StepChecklistActivityTplSharingClientRelation:StepChecklistActivityTplSharingClientRelation`,
  EnumEntityNameEnum_StepChecklistActivityTplSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_StepChecklistActivityTplSharingSalesUnitRelation:StepChecklistActivityTplSharingSalesUnitRelation`,
  EnumEntityNameEnum_Subscription: $localize`:@@EnumEntityNameEnum_Subscription:Subscription`,
  EnumEntityNameEnum_Task: $localize`:@@EnumEntityNameEnum_Task:Task`,
  EnumEntityNameEnum_TaskRecurrence: $localize`:@@EnumEntityNameEnum_TaskRecurrence:Recurrence`,
  EnumEntityNameEnum_TaskReminder: $localize`:@@EnumEntityNameEnum_TaskReminder:Task Reminder`,
  EnumEntityNameEnum_TaskSharingClientRelation: $localize`:@@EnumEntityNameEnum_TaskSharingClientRelation:TaskSharingClientRelation`,
  EnumEntityNameEnum_TaskSharingSalesUnitRelation: $localize`:@@EnumEntityNameEnum_TaskSharingSalesUnitRelation:TaskSharingSalesUnitRelation`,
  EnumEntityNameEnum_TaskType: $localize`:@@EnumEntityNameEnum_TaskType:Task Type`,
  EnumEntityNameEnum_TeamSpace: $localize`:@@EnumEntityNameEnum_TeamSpace:Space`,
  EnumEntityNameEnum_TeamSpaceClient: $localize`:@@EnumEntityNameEnum_TeamSpaceClient:TeamSpaceClient`,
  EnumEntityNameEnum_TeamSpaceRegion: $localize`:@@EnumEntityNameEnum_TeamSpaceRegion:TeamSpaceRegion`,
  EnumEntityNameEnum_TextMessage: $localize`:@@EnumEntityNameEnum_TextMessage:Text Message`,
  EnumEntityNameEnum_Timeframe: $localize`:@@EnumEntityNameEnum_Timeframe:Velocity`,
  EnumEntityNameEnum_Type: $localize`:@@EnumEntityNameEnum_Type:Type`,
  EnumEntityNameEnum_UserProfile: $localize`:@@EnumEntityNameEnum_UserProfile:UserProfile`,
  EnumEntityNameEnum_Webresource: $localize`:@@EnumEntityNameEnum_Webresource:Web Resource`,
  EnumEntityNameEnum_Virtual_User: $localize`:@@EnumEntityNameEnum_Virtual_User:User`,
  EnumEntityNameEnum_Virtual_System: $localize`:@@EnumEntityNameEnum_Virtual_System:System`
};
