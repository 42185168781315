import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsComponent {

  @Input() activeStepIndex: number;
  @Input() steps: Step[];

  @MemoizeLast<StepsComponent>(['steps'])
  get lastStepIndex() {
    return this.steps.length - 1;
  }

  constructor() { }

}

export interface Step {
  name: string;
}
