import { DomPortalHost, TemplatePortal } from '@angular/cdk/portal';
import type {
  AfterViewInit,
  EmbeddedViewRef,
  OnDestroy
} from '@angular/core';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
,
  ComponentFactoryResolver,
  Injector,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'ppl-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplPortalComponent implements AfterViewInit, OnDestroy {

  @Input() zIndex = 10;

  @ViewChild('content') content: TemplateRef<any>;

  private appRef: ApplicationRef;
  private bodyPortalHost: DomPortalHost;
  private instanceRef: EmbeddedViewRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngAfterViewInit() {
    this.appRef = this.injector.get<ApplicationRef>(ApplicationRef);

    this.bodyPortalHost = new DomPortalHost(
      document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    const instance = new TemplatePortal(this.content, this.viewContainerRef);

    this.instanceRef = this.bodyPortalHost.attachTemplatePortal(instance);
  }

  ngOnDestroy() {
    this.instanceRef.destroy();
  }

}
