import { ReminderStatusEnum } from '@ppl/graphql-space-api';
import moment from 'moment';
import type { AppointmentReminderEntity, TaskReminderEntity } from '@ppl/graphql-space-api';

export function resolveAppointmentReminder(options: { startDate: string, reminder: AppointmentReminderEntity | null, defaultReminder: number | null }): AppointmentReminder {
  if (options.reminder && options.reminder.status === ReminderStatusEnum.Dismissed) {
    return {
      state: ReminderState.NOT_SET
    };
  }

  let setDate: Date | null = null;
  let setOffset: number | null = null;

  if (options.reminder) {
    setOffset = options.reminder.endDateOffset;
  } else if (options.defaultReminder !== null) {
    setOffset = options.defaultReminder;
  }

  if (setOffset === null) {
    return {
      state: ReminderState.NOT_SET
    };
  }

  setDate = new Date(new Date(options.startDate).getTime() - setOffset * 1000);

  const isOverdue = Date.now() > setDate.getTime();

  if (options.reminder && options.reminder.status === ReminderStatusEnum.Snoozed && Date.now() <= new Date(options.reminder.snoozeDate).getTime()) {
    return {
      state: ReminderState.SNOOZED,
      offset: setOffset,
      date: new Date(options.reminder.snoozeDate)
    };
  }

  if (isOverdue) {
    return {
      state: ReminderState.OVERDUE,
      offset: setOffset,
      date: setDate
    };
  }

  return {
    state: ReminderState.SET,
    offset: setOffset,
    date: setDate
  };
}

export function resolveTaskReminder(options: { dueDate: string | null, reminder: TaskReminderEntity | null, defaultReminderDay: number | null, defaultReminderTime: number | null }): TaskReminder {
  if (options.reminder && options.reminder.status === ReminderStatusEnum.Dismissed) {
    return {
      state: ReminderState.NOT_SET
    };
  }

  let setDate: Date | null = null;

  if (options.reminder) {
    setDate = new Date(options.reminder.setDate);
  } else if (options.dueDate && options.defaultReminderDay !== null) {
    setDate = moment(options.dueDate).subtract(options.defaultReminderDay, 'day').startOf('day').add(options.defaultReminderTime, 'second').toDate();
  }

  if (!setDate) {
    return {
      state: ReminderState.NOT_SET
    };
  }

  const isOverdue = Date.now() > setDate.getTime();

  if (options.reminder && options.reminder.status === ReminderStatusEnum.Snoozed && !isOverdue) {
    return {
      state: ReminderState.SNOOZED,
      date: setDate
    };
  }

  if (isOverdue) {
    return {
      state: ReminderState.OVERDUE,
      date: setDate
    };
  }

  return {
    state: ReminderState.SET,
    date: setDate
  };
}

export enum ReminderState {
  NOT_SET,
  SET,
  OVERDUE,
  SNOOZED
}

export type AppointmentReminder = {
  state: ReminderState.NOT_SET;
} | {
  state: ReminderState.SET;
  offset: number;
  date: Date;
} | {
  state: ReminderState.OVERDUE;
  offset: number;
  date: Date;
} | {
  state: ReminderState.SNOOZED;
  offset: number;
  date: Date;
};

export type TaskReminder = {
  state: ReminderState.NOT_SET;
} | {
  state: ReminderState.SET;
  date: Date;
} | {
  state: ReminderState.OVERDUE;
  date: Date;
} | {
  state: ReminderState.SNOOZED;
  date: Date;
};

export const REMINDER_OFFSETS = [
  { label: 'ReminderOffset_None', value: '' },
  { label: 'ReminderOffset_0', value: 0 },
  { label: 'ReminderOffset_300', value: 300 },
  { label: 'ReminderOffset_600', value: 600 },
  { label: 'ReminderOffset_900', value: 900 },
  { label: 'ReminderOffset_1800', value: 1800 },
  { label: 'ReminderOffset_3600', value: 3600 },
  { label: 'ReminderOffset_7200', value: 7200 },
  { label: 'ReminderOffset_14400', value: 14400 },
  { label: 'ReminderOffset_28800', value: 28800 },
  { label: 'ReminderOffset_43200', value: 43200 },
  { label: 'ReminderOffset_86400', value: 86400 },
  { label: 'ReminderOffset_172800', value: 172800 },
  { label: 'ReminderOffset_604800', value: 604800 },
  { label: 'ReminderOffset_1209600', value: 1209600 }
];
