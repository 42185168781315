import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import type { PplOptionListOption } from '@ppl/ui/option-list';
import type { PplPopoverControl } from '@ppl/ui/popover';
import type { SelectorViewPickerEvent } from '@ppl/ui/selector-view';
import { normalizeToObject } from '@ppl/utils';

@Component({
  selector: 'ppl-selector-view-demo',
  templateUrl: './selector-view-demo.component.html',
  styleUrls: ['./selector-view-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorViewDemoComponent implements OnInit {

  code = `
    <ppl-selector-view [value]="value"
                       [valueTemplate]="valueTemplate"
                       [pickerPopoverTemplate]="pickerPopoverTemplate"
                       [loading]="loading"
                       (contentClick)="onContentClick($event)"
                       (pickerClick)="onPickerClick($event)>
    </ppl-selector-view>

    <ng-template #valueTemplate
                 let-value>
      <span>{{ optionsById[value].label }}</span>
    </ng-template>

    <ng-template #pickerPopoverTemplate
                 let-value
                 let-popover="popover">
      <ppl-option-list [options]="options"
                       [value]="optionListValue || value"
                       (optionSelect)="onOptionSelect(popover, $event)"
                       (valueChange)="onOptionChange($event)"></ppl-option-list>
    </ng-template>
  `;

  value = '1';
  options: PplOptionListOption[] = [{
    value: '1',
    label: 'First entry'
  }, {
    value: '2',
    label: 'Second entry'
  }];
  optionListValue: string;
  optionsById: { [id: string]: PplOptionListOption } = normalizeToObject(this.options, 'value');
  loading = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  onContentClick(value: string) {
    console.log('content event', value);
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }, 3000);
  }

  onPickerClick(event: SelectorViewPickerEvent) {
    console.log('picker event', event);
  }

  onOptionSelect(popover: PplPopoverControl, option: PplOptionListOption) {
    const value = option.value;
    this.value = value;
    this.optionListValue = value;
    popover.close();
  }

  onOptionChange(value: string) {
    this.optionListValue = value;
  }

}
