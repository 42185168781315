import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { findStringInText } from '@ppl/utils';

@Component({
  selector: 'ppl-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightComponent implements OnInit {
  @Input() highlight: string;
  @Input() text: string;

  constructor() { }

  ngOnInit() {
  }

  get tokens() {
    return findStringInText(this.highlight, this.text);
  }

}
