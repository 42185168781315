import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-menu-demo',
  templateUrl: './menu-demo.component.html',
  styleUrls: ['./menu-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDemoComponent implements OnInit {

  code = `
 <ppl-menu [value]="selectedValue" [markSelected]="true" [hasIcons]="true" (valueChanged)="selectedValue = $event">
    <ppl-menu-item [value]="'1'">
      <ppl-icon svgIcon="pipeline-view" width="18px" height="16px"></ppl-icon>
      Menu item 1
    </ppl-menu-item>
    <ppl-menu-item [value]="'2'">
      <ppl-icon svgIcon="bubble-view" width="18px" height="18px"></ppl-icon>
      Menu item 2
    </ppl-menu-item>
    <ppl-menu-item [value]="'3'">
      <ppl-icon svgIcon="compact-view" width="18px" height="18px"></ppl-icon>
      Menu item 3
    </ppl-menu-item>
    <ppl-menu-item [value]="'4'">
      <ppl-icon svgIcon="list-view" width="18px" height="18px"></ppl-icon>
      Menu item 4
    </ppl-menu-item>
  </ppl-menu>
  `;

  selectedValue = '1';

  constructor() {
  }

  ngOnInit() {
  }

}
