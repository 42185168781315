import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding , ChangeDetectorRef} from '@angular/core';
import type { Observable} from 'rxjs';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { LocalStorageService } from '@ppl/utils';
import { map, tap } from 'rxjs/operators';
import { PplPosition, PplResizableSizeConstraints } from '../../resizable';

@Component({
  selector: 'ppl-drawer-detail',
  templateUrl: './drawer-detail.component.html',
  styleUrls: ['./drawer-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDrawerDetailComponent implements OnInit {

  @Input()
  set open(value) {
    this._open$.next(value);
  }

  get open() {
    return this._open$.getValue();
  }

  @Input()
  set disabled(value) {
    this._disabled$.next(value);
  }
  get disabled() {
    return this._disabled$.getValue();
  }

  @Input() showControl: boolean;
  @Input() position: PplPosition;
  @Input() detailSizeLocalStorageKey?: string;
  @Input() resizable: boolean;
  @Input() detailSize: number;
  @Input() sizeConstraints: PplResizableSizeConstraints;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() sizeChange = new EventEmitter<number>();

  windowWidth$: Observable<number>;
  showContent$: Observable<boolean>;

  private _open$ = new BehaviorSubject<boolean>(true);
  private _disabled$ = new BehaviorSubject<boolean>(false);

  get vertical() {
    return this.position === 'top' || this.position === 'bottom';
  }

  @HostBinding('class.horizontal')
  get horizontal() {
    return this.position === 'right' || this.position === 'left';
  }

  get width() {
    return this.horizontal ? `${this.detailSize}px` : '100%';
  }

  get height() {
    return this.vertical ? `${this.detailSize}px` : '100%';
  }

  constructor(private localStorageService: LocalStorageService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.detailSize = this.detailSizeLocalStorageKey ? this.localStorageService.getItem(this.detailSizeLocalStorageKey, this.detailSize) : this.detailSize;
    this.showContent$ = combineLatest([this._open$, this._disabled$]).pipe(
      map(([open, disabled]) => {
        const result = open && !disabled;
        return result;
      }),
      tap(() => {
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  onControlClick() {
    this.openChange.emit(!this.open);
  }

  onDetailSizeChange($event: number) {
    this.detailSize = $event;
    if (this.detailSizeLocalStorageKey) {
      this.localStorageService.setItem(this.detailSizeLocalStorageKey, $event);
    }
    this.sizeChange.emit($event);
  }

}

