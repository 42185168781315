import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AuthStore } from '../../store/auth.state';
import type { TokenService, TokenSignInOptions, TokenSignOutOptions } from './token.service';
import { createLoginToken, createLogoutToken } from './token.utils';

@Injectable()
export class RedirectTokenService implements TokenService {

  constructor(
    private store: AuthStore
  ) {}

  signIn(options: TokenSignInOptions) {
    const ssoToken = createLoginToken({
      appUrl: options.appUrl,
      disableGoogleSignIn: true,
      disableMicrosoftSignIn: true,
      queryForSpace: options.queryForSpace,
      returnUrl: `${options.appUrl}/sso`
    });

    location.href = `${options.ssoUrl}/sso/${ssoToken}`;

    return of(false);
  }

  signOut(options: TokenSignOutOptions) {
    const ssoToken = createLogoutToken({
      appSessionId: this.getAppSessionId(),
      appUrl: options.appUrl,
      returnUrl: options.appUrl
    });

    location.href = `${options.ssoUrl}/sso_logout/${ssoToken}`;

    return of(false);
  }

  getToken() {
    try {
      const token = localStorage.getItem('Pipeliner_Token');

      if (token) {
        this.store.dispatch('Auth_UpdateSsoToken', token);
      }

      return of(token);
    } catch (ex) {
      return of(null);
    }
  }

  setToken(token: string) {
    try {
      localStorage.setItem('Pipeliner_Token', token);
    } catch (ex) {}

    return of(null);
  }

  deleteToken() {
    try {
      this.store.dispatch('Auth_UpdateSsoToken', null);

      localStorage.removeItem('Pipeliner_Token');
    } catch (ex) {}

    return of(null);
  }

  reloadPage() {
    location.reload();
  }

  getAppSessionId() {
    try {
      const token = localStorage.getItem('Pipeliner_AppSessionId');

      return token;
    } catch (ex) {
      return null;
    }
  }

  setAppSessionId(appSessionId: string) {
    try {
      localStorage.setItem('Pipeliner_AppSessionId', appSessionId);
    } catch (ex) {}

    return null;
  }

}
