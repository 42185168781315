<div #container
     class="container"
     [class.disabled]="disabled"
     [class.focused]="focused"
     [class.value-loading]="displayValueLoading"
     [pplPopover]="optionsPopover"
     [pplPopoverAlignEnd]="dropdownAlignEnd"
     [pplPopoverAlignStart]="dropdownAlignStart"
     [pplPopoverEvent]="null"
     (keydown)="onContainerKeyDown($event)"
     (mousedown)="onInputMouseDown()"
     (click)="onContainerClick($event)">
  <ng-container *ngIf="!displayValueLoading">
    <div #selectedValues
         class="selected-values"
         [attr.tabindex]="disabled ? -1 : 0"
         (focus)="onFocus()"
         (blur)="onBlur()"
         title="{{ selectedValuesLabel }}">
      <span *ngIf="!selectedOptionTemplate"
            class="selected-values-label"
            [class.placeholder]="!selectedOptions.length && !optionsPopover.isOpen && !boldPlaceholder">
        {{ selectedValuesLabel }}
      </span>
      <ng-container *ngIf="selectedOptionTemplate">
        <ng-container *ngFor="let option of selectedOptions; let i = index; trackBy: trackByOptionFn">
          <ng-container *ngTemplateOutlet="selectedOptionTemplate; context: { $implicit: option }"></ng-container>
          <span *ngIf="i + 1 < selectedOptions.length"
                class="custom-template-spacer">, </span>
        </ng-container>
      </ng-container>
    </div>
    <div class="dropdown-icon">
      <ppl-icon [svgIcon]="disabled ? 'rowline-form_disabled' : 'rowline-form'"
                width="7px"
                height="7px"></ppl-icon>
    </div>
    <svg *ngIf="ngUiTheme === 'fabric'"
         class="dropdown-fabric-icon"
         width="12px"
         height="8px">
      <path d="m 6 5.5 l 5.25 -4.7 l 0.75 0.7 l -6 5.5 l -6 -5.5 l 0.75 -0.7"
            fill="#323130"></path>
    </svg>
  </ng-container>

  <ppl-loading *ngIf="displayValueLoading"></ppl-loading>
</div>

<ng-template #customOptionTemplate
             let-option>
  <ppl-multiple-select-option [checked]="isChecked(option)"
                              [option]="option"
                              [optionTemplate]="optionTemplate"></ppl-multiple-select-option>
</ng-template>

<ng-template #optionsPopover>
  <ppl-popover-dropdown [style.min-width.px]="dropdownMinWidth"
                        [style.max-width.px]="dropdownMaxWidth">
    <div #optionsContainer
         class="options-container"
         (keydown)="onOptionsContainerKeydown($event)">
      <ppl-search #search
                  placeholder="Start typing..."
                  i18n-placeholder="@@Start_typing..."
                  [focusOnMount]="true"
                  [value]="filter"
                  (click)="onOptionSearchClick($event)"
                  (valueChange)="onOptionSearch($event)"></ppl-search>
      <ng-content select="dropdownContentBeforeList"></ng-content>
      <div class="buttons">
        <button type="button"
                class="clear-button"
                i18n="@@Clear_selected"
                [attr.tabindex]="-1"
                (mousedown)="$event.preventDefault()"
                (click)="onOptionClear($event)">Clear selected</button>
        <button *ngIf="!isExternalFilter()"
                type="button"
                class="clear-button"
                i18n="@@Select_All"
                [attr.tabindex]="-1"
                (mousedown)="$event.preventDefault()"
                (click)="onOptionSelectAll($event)">Select All</button>
      </div>
      <ppl-option-list [options]="availableOptions"
                       [categories]="categories"
                       [value]="listSelectedIndex !== -1 ? availableOptions[listSelectedIndex].value : null"
                       [optionTemplate]="customOptionTemplate"
                       [optionTemplateRowHeight]="(ngUiTheme === 'fabric' && !optionTemplateRowHeight) ? 31 : optionTemplateRowHeight"
                       [displayLoading]="displayOptionsLoading"
                       [displayNoResults]="!displayOptionsLoading && availableOptions.length === 0"
                       [maxContainerHeight]="maxContainerHeight"
                       (optionSelect)="onOptionSelect($event)"
                       (listScrollEnd)="onListScrollEnd()"
                       (valueChange)="onOptionValueChange($event)"></ppl-option-list>
    </div>
  </ppl-popover-dropdown>
</ng-template>
