export function createPointerEvents(options: CreatePointerEventsOptions) {
  const startX = options.event.pageX;
  const startY = options.event.pageY;

  let distance = 0;
  let prevX = startX;
  let prevY = startY;
  let pointerMoved = false;

  document.addEventListener('mousemove', onPointerMove);
  document.addEventListener('mouseup', onPointerRelease);

  options.event.stopPropagation();
  options.event.preventDefault();

  function onPointerMove(event: MouseEvent) {
    const pointerEvent = createPointerEvent(event);

    if (pointerEvent.dtStartX !== 0 || pointerEvent.dtStartY !== 0) {
      pointerMoved = true;
    }

    distance += Math.abs(event.pageX - prevX) + Math.abs(event.pageY - prevY);

    if (options.onPointerMove) {
      options.onPointerMove(pointerEvent);
    }

    prevX = event.pageX;
    prevY = event.pageY;
  }

  function onPointerRelease(event: MouseEvent) {
    document.removeEventListener('mousemove', onPointerMove);
    document.removeEventListener('mouseup', onPointerRelease);

    if (options.onPointerRelease) {
      options.onPointerRelease(createPointerEvent(event));
    }
  }

  function createPointerEvent(event: MouseEvent): CreatePointerEvent {
    return {
      x: event.pageX,
      y: event.pageY,
      dtX: event.pageX - prevX,
      dtY: event.pageY - prevY,
      dtStartX: event.pageX - startX,
      dtStartY: event.pageY - startY,
      distance,
      pointerMoved,
      clientX: event.clientX,
      clientY: event.clientY
    };
  }
}

export interface CreatePointerEventsOptions {
  event: MouseEvent;
  onPointerMove?: (event: CreatePointerEvent) => void;
  onPointerRelease?: (event: CreatePointerEvent) => void;
}

export interface CreatePointerEvent {
  x: number;
  y: number;
  dtX: number;
  dtY: number;
  dtStartX: number;
  dtStartY: number;
  distance: number;
  pointerMoved?: boolean;
  clientX: number;
  clientY: number;
}
