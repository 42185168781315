import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { PplAutocompleteCategory, PplAutocompleteOption, PplAutocompleteOptionsRequest } from '@ppl/ui/autocomplete';

@Component({
  selector: 'ppl-autocomplete-demo',
  templateUrl: './autocomplete-demo.component.html',
  styleUrls: ['./autocomplete-demo.component.scss']
})
export class AutocompleteDemoComponent {

  value1 = '';

  pickerStaticOptions: PplAutocompleteOption[] = [
    { value: 'austria', label: 'Austria', categoryId: 'a' },
    { value: 'croatia', label: 'Croatia', categoryId: 'a' },
    { value: 'czech-republic', label: 'Czech Republic', categoryId: 'a' },
    { value: 'france', label: 'France', categoryId: 'a' },
    { value: 'germany', label: 'Germany', categoryId: 'b' },
    { value: 'norway', label: 'Norway', categoryId: 'b' },
    { value: 'poland', label: 'Poland', categoryId: 'b' },
    { value: 'slovakia', label: 'Slovakia', categoryId: 'b' },
    { value: 'slovenia', label: 'Slovenia', categoryId: 'b' }
  ];

  pickerStaticCategories: PplAutocompleteCategory[] = [
    { id: 'a', label: 'A - F' },
    { id: 'b', label: 'G - S' }
  ];

  pickerDynamicOptions$ = new BehaviorSubject<PplAutocompleteOption[]>([]);
  pickerDynamicDisplayLoading = false;
  pickerDynamicLoad: any = 0;

  selectStaticOptions: PplAutocompleteOption[] = [
    { value: 'v1', label: 'Value 1' },
    { value: 'v2', label: 'Value 2' },
    { value: 'v3', label: 'Value 3' }
  ];

  selectStaticValue = 'v1';

  onPickerOptionsRequest(event: PplAutocompleteOptionsRequest) {
    clearTimeout(this.pickerDynamicLoad);

    if (!event.lastOptionValue) {
      this.pickerDynamicOptions$.next([]);
    }

    this.pickerDynamicDisplayLoading = true;

    this.pickerDynamicLoad = setTimeout(() => {
      const firstIndex = event.lastOptionValue === null ? 0 : parseInt(event.lastOptionValue, 10) + 1;
      const pickerOptions: PplAutocompleteOption[] = Array.from({ length: 10 }, (value, index) => ({
        value: `${firstIndex + index}`,
        label: `Option ${firstIndex + index} (filter: ${event.filter})`
      }));

      this.pickerDynamicDisplayLoading = false;
      this.pickerDynamicOptions$.next([...this.pickerDynamicOptions$.getValue(), ...pickerOptions]);
    }, 1000);
  }

  onPickerOptionSelect(value: string) {
    console.log('Selected: ' + value);
  }

}
