import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppl-app-container',
  templateUrl: 'app-container.component.html',
  styleUrls: ['app-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplAppContainerComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
