<div class="container"
     #multiCheckboxContainer
     [pplPopover]="optionsPopover"
     [pplPopoverDisabled]="disabled || readonly"
     [pplPopoverAlignStart]="dropdownAlignStart"
     [pplPopoverAlignEnd]="dropdownAlignEnd"
     [pplPopoverEvent]="null"
     [class.disabled]="disabled"
     [class.readonly]="readonly"
     [class.focused]="focused"
     (mousedown)="calculateDropdownAlignment()"
     (click)="onContainerClick($event)">

  <input class="search-input"
         pplInput
         #valueInput
         [attr.autocomplete]="'off'"
         [attr.autocorrect]="'off'"
         [attr.autocapitalize]="'off'"
         [attr.spellcheck]="'false'"
         [attr.tabindex]="disabled ? -1 : 0"
         [disabled]="disabled"
         [placeholder]="readonly ? '' : placeholder"
         [readonly]="readonly"
         [value]="searchValue"
         (focus)="onInputFocus()"
         (blur)="onInputBlur()"
         (input)="onSearchValueChange($event)" />

  <ppl-icon class="search-icon"
            svgIcon="search"
            width="14px"
            height="14px"></ppl-icon>
</div>

<div class="separator"
     [class.disabled]="disabled"></div>
<ng-container *ngIf="showOptions; else loading">
  <div *ngIf="(renderedOptions$ | async).length; else empty"
       class="pills">
    <div *ngFor="let option of renderedOptions$ | async; trackBy: trackByFn;"
         class="pill"
         [class.pill-changes]="(option.added || option.removed) && !disabled"
         [class.pill-disabled]="disabled"
         [class.pill-readonly]="readonly">
      <span class="pill-label"
            [class.pill-label-removed]="option.removed && !disabled">{{ option.label }}</span>
      <ppl-icon *ngIf="!option.removed && !disabled && !readonly"
                svgIcon="closebold-darkblue"
                width="8px"
                height="8px"
                (click)="onOptionRemove(option)"></ppl-icon>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <ppl-loading></ppl-loading>
</ng-template>

<ng-template #empty>
  <span class="empty-message">
    {{ intl.noOptionsSelected }}
  </span>
</ng-template>

<ng-template #customOptionTemplate
             let-option>
  <ppl-multiple-select-option [checked]="isChecked(option)"
                              [option]="option"
                              [optionTemplate]="optionTemplate"></ppl-multiple-select-option>
</ng-template>

<ng-template #optionsPopover>
  <ppl-popover-dropdown [style.min-width.px]="dropdownMinWidth"
                        [style.max-width.px]="dropdownMaxWidth">
    <div #optionsContainer
         class="options-container"
         (keydown)="onOptionsContainerKeydown($event)">
      <div class="buttons">
        <button type="button"
                class="clear-button"
                i18n="@@Clear_selected"
                [attr.tabindex]="-1"
                (mousedown)="$event.preventDefault()"
                (click)="onOptionClear($event)">Clear selected</button>
        <button type="button"
                class="clear-button"
                i18n="@@Select_All"
                [attr.tabindex]="-1"
                (mousedown)="$event.preventDefault()"
                (click)="onOptionSelectAll($event)">Select All</button>
      </div>
      <ppl-option-list [options]="availableOptions"
                       [value]="listSelectedIndex !== -1 ? availableOptions[listSelectedIndex].value : null"
                       [optionTemplate]="customOptionTemplate"
                       [optionTemplateRowHeight]="31"
                       [displayLoading]="displayOptionsLoading"
                       [displayNoResults]="!displayOptionsLoading && availableOptions.length === 0"
                       [maxContainerHeight]="maxContainerHeight"
                       (optionSelect)="onOptionSelect($event)"
                       (valueChange)="onOptionValueChange($event)"></ppl-option-list>
    </div>
  </ppl-popover-dropdown>
</ng-template>
