import { CommonGridComponent } from './common-grid/common-grid.component';
import { GridAsyncActionsButtonComponent } from './grid-async-actions-button/grid-async-actions-button.component';
import { GridAsyncActionsComponent } from './grid-async-actions/grid-async-actions.component';
import { GridTreeColumnComponent } from './grid-tree-column/grid-tree-column.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@ppl/i18n';
import { PplAvatarModule } from '@ppl/ui/avatar';
import { PplBadgeModule } from '@ppl/ui/badge';
import { PplGridModule } from '@ppl/ui/grid';
import { PplIconModule } from '@ppl/ui/icon';
import { PplMenuModule } from '@ppl/ui/menu';
import { PplLoadingModule } from '@ppl/ui/loading';
import { PplPopoverModule } from '@ppl/ui/popover';
import { PplPopoverDropdownModule } from '@ppl/ui/popover-dropdown';

@NgModule({
  imports: [
    CommonModule,
    PplGridModule,
    PplIconModule,
    PplMenuModule,
    PplLoadingModule,
    PplPopoverModule,
    PplPopoverDropdownModule,
    I18nModule,
    PplAvatarModule,
    PplBadgeModule
  ],
  declarations: [
    CommonGridComponent,
    GridTreeColumnComponent,
    GridAsyncActionsComponent,
    GridAsyncActionsButtonComponent
  ],
  exports: [
    CommonGridComponent,
    GridTreeColumnComponent,
    GridAsyncActionsComponent,
    GridAsyncActionsButtonComponent
  ]
})
export class PplComponentsGridModule { }
