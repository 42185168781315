import { ChangeDetectorRef, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Inject, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, KEYCODE_SPACE } from '@ppl/utils';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';

@Component({
  selector: 'ppl-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplSlideToggleComponent),
      multi: true
    }
  ]
})
@FormValueControl({ markForCheck: true })
export class PplSlideToggleComponent {

  @Input() label: string;
  @Input()
  @HostBinding('class.disabled')
  disabled = false;
  @Input() labelPosition: 'before' | 'after' = 'after';
  @Input()
  @HostBinding('class.toggled')
  value: boolean;
  @Input() toggledOnColor?: string;
  @Input() toggledOffColor?: string;
  // indicates, whether visual feedback is immediate or the toggled attribute is changed explicitely later on
  @Input() toggleImmediatelly = true;

  @Output() valueChange = new EventEmitter<boolean>();

  private focused = false;

  get labelColor() {
    if (this.value && this.toggledOnColor) {
      return this.toggledOnColor;
    } else if (!this.value && this.toggledOffColor) {
      return this.toggledOffColor;
    } else {
      return null;
    }
  }

  @HostBinding('class.label-before')
  get classLabelBefore() {
    return this.labelPosition === 'before';
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES
  ) { }

  @HostListener('keydown', ['$event'])
  onKeydown($event: KeyboardEvent) {
    if ($event.keyCode === KEYCODE_SPACE && this.focused) {
      this.onClick();
      $event.preventDefault();
    }
  }

  @HostListener('click')
  onClick() {
    if (this.disabled) {
      return;
    }

    if (this.toggleImmediatelly) {
      this.value = !this.value;
    }

    this.valueChange.emit(this.value);
  }

  onFocusChange() {
    this.focused = !this.focused;
  }
}

export function pplSlideToggleValidator(control) {
  const value = control.value as boolean;
  return value
    ? null
    : {
      required: true
    };
}
