import { ACCOUNT_CLASS_COLORS } from './account-class.constants';
import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';

export type PplAccountClass = 'NoClass' | 'ClassA' | 'ClassB' | 'ClassC' | 'ClassD';

@Component({
  selector: 'ppl-account-class',
  templateUrl: './account-class.component.html',
  styleUrls: ['./account-class.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplAccountClassComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplAccountClassComponent {
  @Input() value: PplAccountClass | PplAccountClass[];
  @Input() selection: 'one' | 'multiple' = 'one';
  @Input() showOne = false;
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() @HostBinding('class.disabled') disabled: boolean;

  @Output() valueChange = new EventEmitter<PplAccountClass | PplAccountClass[]>();

  options: any[] = [{ value: 'NoClass', text: '0' }, { value: 'ClassA', text: 'A' }, { value: 'ClassB', text: 'B' }, { value: 'ClassC', text: 'C' }, { value: 'ClassD', text: 'D' }];

  ACCOUNT_CLASS_COLORS = ACCOUNT_CLASS_COLORS;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  changeValue(value: PplAccountClass) {
    if (this.disabled || this.readonly) {
      return;
    }

    switch (this.selection) {
      case 'one':
        this.valueChange.emit(value);
        break;
      case 'multiple':
        const valueSelected = ((this.value as PplAccountClass[]) || []).find(foundValue => foundValue === value);

        this.valueChange.emit(valueSelected
          ? (this.value as PplAccountClass[]).filter(foundValue => foundValue !== value)
          : [...(this.value as PplAccountClass[]), value]);
        break;
    }
  }

  isOptionChecked(value: PplAccountClass) {
    switch (this.selection) {
      case 'multiple':
        return ((this.value as string[]) || []).find(foundValue => foundValue === value);
      case 'one':
        return ((this.value as string) || '') === value;
    }
  }

  trackByValue(index, item) {
    return item.value;
  }

  isOptionColored(value: PplAccountClass) {
    if (this.readonly || this.disabled) {
      return this.isOptionChecked(value);
    } else {
      return true;
    }
  }
}
