import moment from 'moment';
import { I18nService } from '@ppl/i18n';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'pplTimeAgo'
})
export class PplTimeAgoPipe implements PipeTransform {
  constructor(
    private i18nService: I18nService
  ) { }

  transform(isoDateTime: string, displaySuffix = true) {
    const time = moment(isoDateTime);
    const now = moment();

    const secondsDiff = Math.round(Math.abs(time.diff(now, 's')));
    const minutesDiff = Math.round(Math.abs(time.diff(now, 'm')));
    const hoursDiff = Math.round(Math.abs(time.diff(now, 'h')));
    const daysDiff = Math.round(Math.abs(time.diff(now, 'd')));
    const monthsDiff = Math.round(Math.abs(time.diff(now, 'M')));
    const yearsDiff = Math.round(Math.abs(time.diff(now, 'y')));

    if (secondsDiff <= thresholds.s) {
      return this.i18nService.translate(displaySuffix ? `secondsAgo` : 'secondsWoAgo', { amount: secondsDiff });
    } else if (minutesDiff <= 1) {
      return this.i18nService.translate(displaySuffix ? `minuteAgo` : 'minuteWoAgo', { amount: 1 });
    } else if (minutesDiff <= thresholds.m) {
      return this.i18nService.translate(displaySuffix ? `minutesAgo` : 'minutesWoAgo', { amount: minutesDiff });
    } else if (hoursDiff <= 1) {
      return this.i18nService.translate(displaySuffix ? `hourAgo` : 'hourWoAgo', { amount: 1 });
    } else if (hoursDiff <= thresholds.h) {
      return this.i18nService.translate(displaySuffix ? `hoursAgo` : 'hoursWoAgo', { amount: hoursDiff });
    } else if (daysDiff <= 1) {
      return this.i18nService.translate(displaySuffix ? `dayAgo` : 'dayWoAgo', { amount: 1 });
    } else if (daysDiff <= thresholds.d) {
      return this.i18nService.translate(displaySuffix ? `daysAgo` : 'daysWoAgo', { amount: daysDiff });
    } else if (monthsDiff <= 1) {
      return this.i18nService.translate(displaySuffix ? `monthAgo` : 'monthWoAgo', { amount: 1 });
    } else if (monthsDiff <= thresholds.M) {
      return this.i18nService.translate(displaySuffix ? `monthsAgo` : 'monthsWoAgo', { amount: monthsDiff });
    } else if (yearsDiff <= 1) {
      return this.i18nService.translate(displaySuffix ? `yearAgo` : 'yearWoAgo', { amount: 1 });
    } else {
      return this.i18nService.translate(displaySuffix ? `yearsAgo` : 'yearsWoAgo', { amount: yearsDiff });
    }
  }
}

const thresholds = {
  s: 45, // seconds to minute
  m: 45, // minutes to hour
  h: 22, // hours to day
  d: 26, // days to month
  M: 11 // months to year
};
