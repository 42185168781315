import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PplDatePickerIntl {
  bootstrapDatepickerLocale = 'en';
  angularLocaleId = 'en-US';
  firstWeekDay = 0;
  dateTimeFormat = 'M/d/yy hh:ss';
  dateFormat = 'M/d/yy';

  constructor() { }
}
