import type { PplAttachment, PplAttachmentUnsupportedInfo } from './model';

export function isAttachmentUnsupportedInfo(
  info: PplAttachmentUnsupportedInfo
) {
  return info.filesEmpty.length || info.filesWithIncorrectSize.length || info.filesWithIncorrectType.length;
}

export function getUnsupportedAttachmentsText(info: PplAttachmentUnsupportedInfo) {
  const alerts = [];
  if (info.filesEmpty.length) {
    alerts.push(`The following files have 0 bytes, so they will not be attached:\n${info.filesEmpty.join(', ')}`);
  }
  if (info.filesWithIncorrectSize.length) {
    alerts.push(`The following files have incorrect size (max. ${info.fileSizeLimit}MB):\n${info.filesWithIncorrectSize.join(', ')}`);
  }
  if (info.filesWithIncorrectType.length) {
    alerts.push(`The following files have incorrect file type:\n${info.filesWithIncorrectType.join(', ')}`);
  }

  return alerts.join('\n\n');
}

export function getAttachments(
  currentFiles: PplAttachment[],
  newFiles: PplAttachment[],
  singleSelection?: boolean
): PplAttachment[] {
  if (singleSelection) {
    // always get first file
    return [
      newFiles[0]
    ];
  } else {
    return [
      ...currentFiles,
      ...newFiles
    ];
  }
}

function getUrlExtension(url: string) {
  const dividedByDot = url.split('.').pop() || '';
  return (dividedByDot.split(/\#|\?/) || [''])[0];
}

const fileExtensionToIcon: { [extension: string]: string } = {
  docx: 'docx-big',
  doc: 'doc-big',
  csv: 'csv-big',
  eml: 'eml-big',
  default: 'file-big',
  html: 'html-big',
  jpg: 'jpg-big',
  jpeg: 'jpg-big',
  pdf: 'pdf-big',
  png: 'png-big',
  pptx: 'pptx-big',
  rar: 'rar-big',
  url: 'url-big',
  xlsx: 'xlsx-big',
  zip: 'zip-big',
  xml: 'xml-big'
};

export function getAttachmentIconFromUrl(url: string) {
  const extension = getUrlExtension(url);
  if (!extension) {
    return fileExtensionToIcon.default;
  } else {
    return fileExtensionToIcon[extension] || fileExtensionToIcon.default;
  }
}
