import { Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class PplTopBarComponent {
  @Input() title: string;
}
