import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'ppl-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplAvatarComponent {
  @Input() entity: 'account'
    | 'client'
    | 'contact'
    | 'custom'
    | 'hidden'
    | 'unit'
    | 'lead'
    | 'opportunity'
    | 'product'
    | 'productCategories'
    | 'project'
    | 'projectObjective'
    | 'application'
    | 'space'
    | 'quote';
  @Input() icon?: string; // if custom icon is set, it will be used
  @Input() name?: string;
  @Input() background = true;
  @Input() outline = true;
  @Input() picture?: string | null;
  @Input() forceRound = false;
  @Input() customPictureSize?: string | null;
  @Input() unassignedOwner = false;
  @Input() @HostBinding('style.height.px') @HostBinding('style.width.px') size: number;

  get showPicture() {
    return !this.showCustomIcon && !!this.picture;
  }

  get showFirstLetter() {
    return !this.showCustomIcon && !this.picture && this.name && (this.entity === 'account' || this.entity === 'contact');
  }

  get showIconPlaceholder() {
    return !this.showCustomIcon && !this.picture && (!this.name || (this.entity !== 'account' && this.entity !== 'contact'));
  }

  get showCustomIcon() {
    return !!this.icon && !this.entity;
  }

  @HostBinding('style.display')
  get isHidden() {
    return (this.entity === 'hidden') ? 'none' : 'block';
  }

  get containerClass() {
    const classes = ['picture-container'];

    if (!this.picture) {
      if (this.background) {
        classes.push('background');
      }

      if (this.outline) {
        classes.push('outline');
      }
    }

    if (this.forceRound) {
      classes.push('round');
    }

    /*if (this.entity === 'client' || this.entity === 'contact') {
      classes.push('round');
    }*/

    return classes.join(' ');
  }

  get pictureSize() {
    if (this.customPictureSize) {
      return this.customPictureSize;
    }

    if (this.entity === 'custom') {
      return this.size * 0.75;
    }

    if (!this.picture && this.outline) {
      return this.size - 2;
    }

    return this.size;
  }

  get letterSize() {
    return this.size / 2;
  }

  get iconPlaceholderSize() {
    return `${this.size / 2}px`;
  }

  get iconPlaceholder() {
    return ({
      account: 'account-avatar',
      client: this.unassignedOwner ? 'unassigned-avatar' : 'contact-avatar',
      contact: 'contact-avatar',
      unit: 'salesunit-avatar',
      lead: 'lead-avatar',
      opportunity: 'opportunity-avatar',
      product: 'product-avatar',
      productCategories: 'product-categories-avatar',
      project: 'project-avatar',
      projectObjective: 'objective-avatar',
      application: 'applications-avatar',
      space: 'spaces-avatar-md',
      quote: 'quotes-avatar'
    })[this.entity];
  }

}
