import type {
  AfterContentInit,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output
,
  ChangeDetectorRef,
  ElementRef} from '@angular/core';
import type { Subscription} from 'rxjs';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * | CONTENT
 */
@Component({
  selector: 'ppl-content',
  templateUrl: './content/content.component.html',
  styleUrls: ['./content/content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplContentComponent implements OnInit, AfterContentInit {
  @HostBinding('style.margin-left.px') styleMarginLeftPx = 0;
  @HostBinding('style.margin-right.px') styleMarginRightPx = 0;

  constructor(
    @Inject(forwardRef(() => PplContainerComponent)) private container: PplContainerComponent,
    private changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef
  ) {}

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.container.contentBehavior$.subscribe(behavior => {
      if (this.styleMarginLeftPx !== behavior.contentMarginLeft || this.styleMarginRightPx !== behavior.contentMarginRight) {
        this.styleMarginLeftPx = behavior.contentMarginLeft;
        this.styleMarginRightPx = behavior.contentMarginRight;
        this.changeDetectorRef.markForCheck();
      }
    });
  }
}

/**
 * SIDEBAR |
 */
@Component({
  selector: 'ppl-sidebar',
  templateUrl: './sidebar/sidebar.component.html',
  styleUrls: ['./sidebar/sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplSidebarComponent implements OnInit, AfterContentInit {
  @HostBinding('style.width.px') width: number;
  @HostBinding('style.left.px') styleLeftPx = null;
  @HostBinding('style.right.px') styleRightPx = null;
  @HostBinding('class.animate') @HostBinding('class.push') classAnimate = true;
  @HostBinding('tabindex') tabindex = -1;
  @HostBinding('class.hidden') visibilityHidden = false;
  @Input() delayAnimate = 200;

  get visible() {
    return !this.visibilityHidden;
  }

  constructor(
    @Inject(forwardRef(() => PplContainerComponent)) private container: PplContainerComponent,
    public changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    this.container.contentBehavior$.subscribe(behavior => {
      if (
        (this.width !== behavior.sidebarWidth) ||
        (this.styleLeftPx !== behavior.sidebarLeft) ||
        (this.styleRightPx !== behavior.sidebarRight) ||
        (this.classAnimate !== behavior.animate)
      ) {
        this.width = behavior.sidebarWidth;
        this.styleLeftPx = behavior.sidebarLeft;
        this.styleRightPx = behavior.sidebarRight;
        if (this.delayAnimate && !this.classAnimate && behavior.animate) {
          setTimeout(() => {
            this.classAnimate = behavior.animate;
            this.changeDetectorRef.markForCheck();
          }, this.delayAnimate);
        } else {
          this.classAnimate = behavior.animate;
        }
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  focus() {
    // NOTE(mike): The timeout of 200ms must be the same as
    // sidebar.component.css .animate transition timeout, so
    // you don't see artefacts during the sidebar open animation.
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    }, 200);
  }

  @HostListener('keyup.escape', ['$event'])
  onEscapeKey(event: KeyboardEvent) {
    if (this.elementRef.nativeElement === event.target) {
      this.container.close.emit();
    } else {
      this.focus();
    }
  }

}

/**
 * [ CONTAINER ]
 */
@Component({
  selector: 'ppl-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplContainerComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() opened = false;
  @Input() sidebarWidth = 200;
  @Input() underBreakpointSidebarWidth = 210;
  @Input() position: 'start' | 'end' = 'start';
  @Input() mode: 'push' | 'over' | 'auto' = 'push';
  @Input() breakPoint: number = null;
  @Input() animate: 'always' | 'breakpoint' = 'always';
  @Input() enableOverlay = false;

  @ContentChild(PplContentComponent, { static: false }) content: PplContentComponent;
  @ContentChild(PplSidebarComponent, { static: false }) sidebar: PplSidebarComponent;

  @Output() underBreakpointChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close = new EventEmitter();

  contentBehavior$: Subject<ContentBehavior> = new Subject();
  underBreakpointBehavior$: Subject<boolean> = new Subject();

  sidebarCloseSubscription: Subscription;

  private windowWidth: number;

  constructor() {
    this.underBreakpointBehavior$.pipe(distinctUntilChanged()).subscribe(value => {
      this.underBreakpointChange.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['opened'] && !changes['opened'].isFirstChange() && changes['opened'].previousValue !== changes['opened'].currentValue) ||
      (changes['sidebarWidth'] && !changes['sidebarWidth'].isFirstChange() && changes['sidebarWidth'].previousValue !== changes['sidebarWidth'].currentValue) ||
      (changes['position'] && !changes['position'].isFirstChange() && changes['position'].previousValue !== changes['position'].currentValue) ||
      (changes['mode'] && !changes['mode'].isFirstChange() && changes['mode'].previousValue !== changes['mode'].currentValue) ||
      (changes['breakPoint'] && !changes['breakPoint'].isFirstChange() && changes['breakPoint'].previousValue !== changes['breakPoint'].currentValue)
    ) {
      this.recalculatePositions();
    }
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    if (!this.content || !this.sidebar) {
      throw new Error('Either Content or Sidebar is missing.');
    }
    this.captureWindowWidth();
    this.recalculatePositions();
  }

  ngAfterViewInit(): void {
    this.captureWindowWidth();
  }

  ngOnDestroy(): void {
    if (this.sidebarCloseSubscription) {
      this.sidebarCloseSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.captureWindowWidth();
    this.recalculatePositions();
  }

  private captureWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  private isUnderBreakpoint() {
    if (this.mode === 'auto' && !this.breakPoint) {
      throw new Error('Breakpoint is not defined.');
    }
    return this.windowWidth <= this.breakPoint;
  }

  private recalculatePositions() {
    const isUnderBreakpoint = this.isUnderBreakpoint();
    const constants = {
      sidebarWidth: this.breakPoint && isUnderBreakpoint ? this.underBreakpointSidebarWidth : this.sidebarWidth,
      animate: this.animate === 'always' || (this.breakPoint && !isUnderBreakpoint)
    };

    let contentBehavior: ContentBehavior = null;

    // 'breakpoint' mode either pushes the content or is over it, but
    // only if mode `auto` is selected.
    const calculatedMode: 'over' | 'push' = this.breakPoint && this.mode === 'auto'
      ? (isUnderBreakpoint ? 'over' : 'push')
      : this.mode === 'over'
        ? 'over'
        : 'push';

    switch (calculatedMode) {
      case 'push':
        switch (this.position) {
          case 'start':
            contentBehavior = {
              sidebarLeft: this.opened ? 0 : -constants.sidebarWidth,
              sidebarRight: null,
              contentMarginLeft: this.opened ? constants.sidebarWidth : 0,
              contentMarginRight: 0,
              ...constants
            };
            break;
          case 'end':
            contentBehavior = {
              sidebarLeft: null,
              sidebarRight: this.opened ? 0 : -constants.sidebarWidth,
              contentMarginLeft: 0,
              contentMarginRight: this.opened ? constants.sidebarWidth : 0,
              ...constants
            };
            break;
        }
        break;
      case 'over':
        const overConstants = {
          contentMarginLeft: 0,
          contentMarginRight: 0,
        };
        switch (this.position) {
          case 'start':
            contentBehavior = {
              sidebarLeft: this.opened ? 0 : -constants.sidebarWidth,
              sidebarRight: null,
              ...overConstants,
              ...constants
            };
            break;
          case 'end':
            contentBehavior = {
              sidebarLeft: null,
              sidebarRight: this.opened ? 0 : -constants.sidebarWidth,
              ...overConstants,
              ...constants
            };
            break;
        }
        break;
    }

    this.contentBehavior$.next(contentBehavior);

    if (this.breakPoint) {
      this.underBreakpointBehavior$.next(isUnderBreakpoint);
    }

    this.onOpenChange(this.opened, contentBehavior.animate);
  }

  private onOpenChange(open: boolean, animate: boolean) {
    const toggleState = () => {
      this.sidebar.visibilityHidden = !!open === false;
      this.sidebar.changeDetectorRef.markForCheck();
    };

    if (this.sidebarCloseSubscription) {
      this.sidebarCloseSubscription.unsubscribe();
    }

    if (open) {
      // if sidebar gets open, remove possible subscription
      toggleState();
    } else {
      // if sidebar gets closed & its animated, attempt close after animation completes
      if (animate) {
        this.sidebarCloseSubscription = timer(sidebarAnimationMs).subscribe(toggleState);
      } else {
        toggleState();
      }
    }
  }
}

const sidebarAnimationMs = 200;

interface ContentBehavior {
  sidebarLeft: number | null;
  sidebarRight: number | null;
  sidebarWidth: number;
  contentMarginLeft: number;
  contentMarginRight: number;
  animate?: boolean;
}
