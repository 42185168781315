import type { PipeTransform} from '@angular/core';
import {Pipe} from '@angular/core';
import {I18nService} from './i18n.service';

/**
 *  Workaround for https://github.com/angular/angular/issues/11405 TODO: update when released (probably 4.1 or later)
 *
 *  Translations are defined as in translations.component.html as e.g.:
 *          <span i18n='@@EntityAccount' id='i18n-Account'>Account</span>
 *
 */

@Pipe({
  name: 't'
})
export class TPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: any, args?: any): any {
    return this.i18nService.translate(value);
  }
}
