import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { PplMultipleSelectOption } from '@ppl/ui/multiple-select';

@Component({
  selector: 'ppl-multiple-select-demo',
  templateUrl: './multiple-select-demo.component.html',
  styleUrls: ['./multiple-select-demo.component.scss']
})
export class MultipleSelectDemoComponent implements OnInit {

  options: PplMultipleSelectOption[] = [
    { value: 'value-1', label: 'Option 1', data: { color: '#FAEBD7' } },
    { value: 'value-2', label: 'Option 2', data: { color: '#00FFFF' } },
    { value: 'value-3', label: 'Option 3', data: { color: '#0000FF' } },
    { value: 'value-4', label: 'Option 4', data: { color: '#7FFF00' } },
    { value: 'value-5', label: 'Option 5', data: { color: '#D2691E' } },
    { value: 'value-6', label: 'Option 6', data: { color: '#DC143C' } },
    { value: 'value-7', label: 'Option 7', data: { color: '#006400' } },
    { value: 'value-8', label: 'Option 8', data: { color: '#1E90FF' } },
    { value: 'value-9', label: 'Option 9', data: { color: '#A52A2A' } },
    { value: 'value-10', label: 'Option 10', data: { color: '#FFD700' } },
    { value: 'value-11', label: 'Option 11', data: { color: '#40E0D0' } }
  ];

  selectedValues = ['value-1', 'value-3'];

  values = [];

  constructor() { }

  ngOnInit() {
  }

  onBasicValueChange(values: string[]) {
    console.log(values);
    this.values = values;
  }

  onValueChange(values: string[]) {
    console.log(values);
  }
}
