<div class="container demo-content">
  <h2>Top Bar</h2>

  <br>

  <h3>Module</h3>
  <div>PplTopBarModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-top-bar</h4>
  <div>@Input <b>title</b> (optional)</div>

  <br>

  <h4>Example</h4>
  <div style="position: relative">
    <ppl-top-bar title="Title"></ppl-top-bar>
  </div>

  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
