import { PplRadioChange } from './radio-change';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  Output,
  QueryList,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import type {
  AfterContentInit,
  OnInit
} from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';

// Increasing integer for generating unique ids for radio components.
let nextUniqueGroupId = 0;

@Component({
  selector: 'ppl-radio-group',
  templateUrl: './radio-group/radio-group.component.html',
  styleUrls: ['./radio-group/radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplRadioGroupComponent),
      multi: true
    }
  ]
})
export class PplRadioGroupComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @Input() label: string;
  @Input() hasContainerClass = true;

  onTouched: any;
  onChange: any;

  /** Name of the radio button group. All radio buttons inside this group will use this name. */
  @Input()
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this._updateRadioButtonNames();
  }

  /** Value of the radio button. */
  @Input()
  get value(): any {
    return this._value;
  }

  set value(newValue: any) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue;

      this._updateSelectedRadioFromValue();
      this._checkSelectedRadioButton();
    }
  }

  /** Whether the radio button is selected. */
  @Input()
  get selected() {
    return this._selected;
  }

  set selected(selected: PplRadioButtonComponent | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkSelectedRadioButton();
  }

  /** Whether the radio group is disabled */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
    this._markRadiosForCheck();
  }

  /** Whether the radio group is required */
  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = value;
    this._markRadiosForCheck();
  }

  /**
   * Event emitted when the group value changes.
   * Change events are only emitted when the value changes due to user interaction with
   * a radio button (the same behavior as `<input type-"radio">`).
   */
  @Output() valueChange: EventEmitter<PplRadioChange> = new EventEmitter<PplRadioChange>();

  /** Child radio buttons. */
  @ContentChildren(forwardRef(() => PplRadioButtonComponent), { descendants: true })
  _radios: QueryList<PplRadioButtonComponent>;

  /**
   * Selected value for group. Should equal the value of the selected radio button if there *is*
   * a corresponding radio button with a matching value. If there is *not* such a corresponding
   * radio button, this value persists to be applied in case a new radio button is added with a
   * matching value.
   */
  private _value: any = null;

  /** The HTML name attribute applied to radio buttons in this group. */
  private _name = `ppl-radio-group-${nextUniqueGroupId++}`;

  /** The currently selected radio button. Should match value. */
  private _selected: PplRadioButtonComponent | null = null;

  /** Whether the `value` has been set to its initial value. */
  private _isInitialized = false;

  /** Whether the radio group is disabled. */
  private _disabled = false;

  /** Whether the radio group is required. */
  private _required = false;

  private className = 'ppl-input-container';

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {
    if (this.hasContainerClass) {
      this.renderer.addClass(this.elementRef.nativeElement, this.className);
    }
  }

  /**
   * Initialize properties once content children are available.
   * This allows us to propagate relevant attributes to associated buttons.
   */
  ngAfterContentInit() {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on MatRadioGroup, and it is possible that the OnInit of the
    // NgModel occurs *after* the OnInit of the MatRadioGroup.
    this._isInitialized = true;
  }

  _checkSelectedRadioButton() {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  /** Dispatch change event with current selection and group value. */
  _emitChangeEvent(): void {
    if (this._isInitialized) {
      const event = new PplRadioChange();
      event.source = this._selected;
      event.value = this._value;
      this.valueChange.emit(event);
      if (this.onChange) {
        this.onChange(event.value);
      }
    }
  }

  _markRadiosForCheck() {
    if (this._radios) {
      this._radios.forEach(radio => radio._markForCheck());
    }
  }

  // Value accessors
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Updates the `selected` radio button from the internal _value state. */
  private _updateSelectedRadioFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected = this._selected != null && this._selected.value === this._value;

    if (this._radios != null && !isAlreadySelected) {
      this._selected = null;
      this._radios.forEach(radio => {
        radio.checked = this.value === radio.value;
        if (radio.checked) {
          this._selected = radio;
        }
      });
    }
  }

  private _updateRadioButtonNames(): void {
    if (this._radios) {
      this._radios.forEach(radio => {
        radio.name = this.name;
      });
    }
  }
}

// BUTTON

// Increasing integer for generating unique ids for radio components.
let nextUniqueButtonId = 0;

@Component({
  selector: 'ppl-radio-button',
  templateUrl: './radio-button/radio-button.component.html',
  styleUrls: ['./radio-button/radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'pplRadioButton'
})
export class PplRadioButtonComponent implements OnInit {
  /** The unique ID for the radio button. */
  @Input() id: string;

  /** Analog to HTML 'name' attribute used to group radios for unique selection. */
  @Input() name: string;

  @HostBinding('class.autofill') @Input() autofill: boolean;

  @HostBinding('class.full-width') @Input() fullWidth: boolean;

  checkFocus = false;

  @HostBinding('class.checked')
  get classChecked() {
    return this.checked;
  }

  @Input()
  get checked(): boolean {
    return this._checked;
  }

  set checked(newCheckedState: boolean) {
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState;

      if (newCheckedState && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (!newCheckedState && this.radioGroup && this.radioGroup.value === this.value) {
        // When unchecking the selected radio button, update the selected radio
        // property on the group.
        this.radioGroup.selected = null;
      }

      if (newCheckedState && this.radioGroup && this.radioGroup._radios) {
        this.radioGroup._radios.forEach(radio => {
          if (radio.name === this.name) {
            radio.uncheck(this.id, this.name);
          }
        });
      }
      this._changeDetector.markForCheck();
    }
  }

  /** The value of this radio button. */
  @Input()
  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup != null) {
        if (!this.checked) {
          // Update checked when the value changed to match the radio group's value
          this.checked = this.radioGroup.value === value;
        }
        if (this.checked) {
          this.radioGroup.selected = this;
        }
      }
    }
  }

  /** Whether the radio button is disabled. */
  @Input()
  get disabled(): boolean {
    return this._disabled || (this.radioGroup != null && this.radioGroup.disabled);
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  /** Whether the radio button is required. */
  @Input()
  get required(): boolean {
    return this._required || (this.radioGroup && this.radioGroup.required);
  }

  set required(value: boolean) {
    this._required = value;
  }

  /**
   * Event emitted when the checked state of this radio button changes.
   * Change events are only emitted when the value changes due to user interaction with
   * the radio button (the same behavior as `<input type-"radio">`).
   */
  @Output() change: EventEmitter<PplRadioChange> = new EventEmitter<PplRadioChange>();

  /** The parent radio group. May or may not be present. */
  radioGroup: PplRadioGroupComponent;

  @ViewChild('input', { static: true }) _inputElement: ElementRef;

  private _uniqueId = `ppl-radio-${++nextUniqueButtonId}`;
  private _checked = false;
  private _disabled: boolean;
  private _required: boolean;
  private _value: any = null;

  constructor(@Optional() radioGroup: PplRadioGroupComponent, private _changeDetector: ChangeDetectorRef) {
    this.radioGroup = radioGroup;
  }

  ngOnInit() {
    if (!this.id) {
      this.id = this._uniqueId;
    }
    if (this.radioGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.radioGroup.value === this._value;
      // Copy name from parent radio group
      this.name = this.radioGroup.name;
    }
  }

  uncheck(id: string, name: string) {
    if (id !== this.id && name === this.name) {
      this.checked = false;
    }
  }

  /**
   * Marks the radio button as needing checking for change detection.
   * This method is exposed because the parent radio group will directly
   * update bound properties of the radio button.
   */
  _markForCheck() {
    // When group value changes, the button will not be notified. Use `markForCheck` to explicit
    // update radio button's status
    this._changeDetector.markForCheck();
  }

  _onInputClick(event: Event) {
    // We have to stop propagation for click events on the visual hidden input element.
    // By default, when a user clicks on a label element, a generated click event will be
    // dispatched on the associated input element. Since we are using a label element as our
    // root container, the click event on the `radio-button` will be executed twice.
    // The real click event will bubble up, and the generated click event also tries to bubble up.
    // This will lead to multiple click events.
    // Preventing bubbling for the second event will solve that issue.
    event.stopPropagation();
  }

  /**
   * Triggered when the radio button received a click or the input recognized any change.
   * Clicking on a label element, will trigger a change event on the associated input.
   */
  _onInputChange(event: Event) {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();

    this.check();
  }

  onRadioButtonKeyDown($event) {
    if (this.checkFocus && $event.keyCode === keyCodeSpace) {
      // if SPACE pressed
      $event.preventDefault();
      this.check();
      return;
    }
  }

  private check() {
    if (!this.checked && !this.disabled) {
      const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
      this.checked = true;
      this._emitChangeEvent();

      if (this.radioGroup) {
        if (groupValueChanged) {
          this.radioGroup._emitChangeEvent();
        }
      }
    }
  }

  /** Dispatch change event with current value. */
  private _emitChangeEvent(): void {
    const event = new PplRadioChange();
    event.source = this;
    event.value = this._value;
    this.change.emit(event);
  }
}

const keyCodeSpace = 32;
