<div [class]="containerClass"
     [style.width.px]="size"
     [style.height.px]="size">

  <img *ngIf="showPicture"
       class="picture-image"
       [src]="picture"
       [attr.width]="pictureSize"
       [attr.height]="pictureSize">

  <div *ngIf="showFirstLetter"
       class="letter-placeholder"
       [style.font-size.px]="letterSize">{{name[0]}}</div>

  <ppl-icon *ngIf="showIconPlaceholder"
            class="icon-placeholder"
            [width]="iconPlaceholderSize"
            [height]="iconPlaceholderSize"
            [svgIcon]="iconPlaceholder"></ppl-icon>

  <ppl-icon *ngIf="showCustomIcon"
            [svgIcon]="icon"
            [width]="iconPlaceholderSize"
            [height]="iconPlaceholderSize"
            class="icon-placeholder"></ppl-icon>
</div>
