import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-buttons-demo',
  templateUrl: './buttons-demo.component.html',
  styleUrls: ['./buttons-demo.component.scss']
})
export class ButtonsDemoComponent implements OnInit {

  code = `
<div class="row">
  <button pplButton
          [pplButtonActive]="active"
          pplButton1>Default</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButton1>OK</button>
</div>
<br>
<br>

<button pplButton
        [pplButtonActive]="active"
        pplButton1
        pplButtonBold>Change password</button>
<br>
<br>

<button pplButton
        [pplButtonActive]="active"
        pplButton1
        pplButtonSmall>Change Owner</button>
<br>
<br>

<div class="row">
  <button pplButton
          [pplButtonActive]="active"
          pplButton4
          pplButtonSmall>Qualify</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButton5
          pplButtonSmall>Archive</button>
</div>
<br>
<br>

<div class="row">
  <button pplButton
          [pplButtonActive]="active"
          pplButton2>Dialog Button</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButton3>Manage</button>
</div>
<br>
<br>

<div class="row"
     style="width: 100%; max-width: 200px;">
  <button pplButton
          [pplButtonActive]="active"
          pplButton6
          pplButtonBlock>Sign Out</button>
</div>
<br>
<br>

<ppl-buttons-group>
  <button pplButton
          [pplButtonActive]="active"
          pplButton1>Add Activities</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButton1>Add Document</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButton1>Add Note</button>
</ppl-buttons-group>
<br>
<br>

<div class="row">
  <button pplButton
          [pplButtonActive]="active"
          pplButtonSmall="xs"
          pplButton7="info">Info</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButtonSmall="xs"
          pplButton7="warning">Warning</button>
  <button pplButton
          [pplButtonActive]="active"
          pplButtonSmall="xs"
          pplButton7="danger">Danger</button>

  <button pplButton
          [pplButtonActive]="active"
          pplButtonSmall="xs"
          pplButton7="error">Error</button>
</div>

<button pplButton
        pplButton1>
  <ppl-icon width="18px"
            height="18px"
            svgIcon="color-add"
            pplIconButton></ppl-icon>
  Button with icon
</button>
`;

  active = false;

  constructor() {
  }

  ngOnInit() {
  }

}
