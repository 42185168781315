<ng-container *ngIf="!displayPlaceholder">
  <div *ngIf="!headerTemplate"
       class="title">{{hideName ? '' : name}}</div>

  <div *ngIf="headerTemplate"
       class="title">
    <ng-template [ngTemplateOutlet]="headerTemplate"
                 [ngTemplateOutletContext]="{ '\$implicit': headerTemplateData }"></ng-template>
  </div>

  <ppl-icon *ngIf="description"
            svgIcon="info-small-blue"
            width="12px"
            height="12px"
            title=""
            pplPopover
            pplPopoverAlignStart
            [pplPopoverTooltip]="description"></ppl-icon>

  <ppl-icon *ngIf="searchable && displaySearchIcons"
            class="search-icon"
            svgIcon="search-metal-blue"
            width="12px"
            height="12px"
            title=""
            pplPopover
            pplPopoverAlignStart
            pplPopoverTooltip="This field is used in Search."></ppl-icon>

  <div *ngIf="sorted"
       class="sort-icon">
    <svg width="7"
         height="7">
      <path *ngIf="sortDirection === 1"
            d="M0,0 L7,0 L3.5,7 Z" />
      <path *ngIf="sortDirection === -1"
            d="M0,7 L7,7 L3.5,0 Z" />
    </svg>
  </div>
</ng-container>

<div *ngIf="displayPlaceholder"
     class="fake-rect"></div>

<div *ngIf="resizable"
     class="resize-handle"
     (mousedown)="resizeStart.emit($event)"
     (click)="$event.stopPropagation()"
     (dblclick)="resizeAuto.emit()"></div>
