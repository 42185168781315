import type { GridAvailableColumn } from './grid.interfaces';

export const MIN_COLUMN_WIDTH = 64;

export const DefaultAvailableColumn: Omit<GridAvailableColumn, 'id'> = {
  draggable: true,
  minWidth: MIN_COLUMN_WIDTH,
  name: '',
  sortable: true,
  type: ''
};
