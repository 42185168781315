import { InjectionToken } from '@angular/core';
import type { Observable } from 'rxjs';

export class PplDialogRef<C = any, R = any> {
  afterClosed: () => Observable<R>;
  afterOpened: () => Observable<void>;
  close: (value?: R) => void;
  componentInstance: C;
}

export const PPL_DIALOG_DATA = new InjectionToken<{}>('PPL_DIALOG_DATA');
