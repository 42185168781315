import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input
} from '@angular/core';
import { MemoizeLast } from '@ppl/utils';
import type {
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-information-icon',
  templateUrl: './information-icon.component.html',
  // styleUrls: ['./information-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplInformationIconComponent {
  @Input() message?: string | TemplateRef<any>;
  @Input() tooltipDirection: 'down' | 'up' = 'down';
  @Input() tooltipPreserveWhitespace = false;
  @Input() icon: 'white' | 'default' = 'default';

  @MemoizeLast()
  get iconName() {
    switch (this.icon) {
      case 'default':
        return 'info-small-blue';
      case 'white':
        return 'info-small-white';
    }
  }

  constructor(
    public elementRef: ElementRef, // used elsewhere in template
  ) { }
}
