<div class="container demo-content">
  <h2>Dialog Component</h2>
  <br>

  <h3>Components</h3>
  <h4>ppl-dialog-title</h4>
  <h4>ppl-dialog-content</h4>
  <h4>ppl-dialog-actions</h4>
  <br>
  <br>

  <h3>Example</h3>
  <button pplButton
          pplButton1
          (click)="open('default')">Open Default Dialog</button>

  <button pplButton
          pplButton1
          (click)="openConfirm()">Open Confirm Dialog</button>

  <button pplButton
          pplButton1
          (click)="openInfo()">Open Info Dialog</button>

  <button pplButton
          pplButton1
          (click)="openAlert()">Open Alert Dialog</button>

  <button pplButton
          pplButton1
          (click)="openWarning()">Open Warning Dialog</button>

  <button pplButton
          pplButton1
          (click)="openYesNo()">Open YesNo Dialog</button>

  <button pplButton
          pplButton1
          (click)="open('overlay')">Open Overlay Dialog</button>
  <br>
  <br>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>

</div>
