/** Change event object emitted by PplRadio and PplRadioGroup. */

import type { PplRadioButtonComponent } from './radio';

export class PplRadioChange {
  /** The PplRadioButton that emits the change event. */
  source: PplRadioButtonComponent | null;
  /** The value of the PplRadioButton. */
  value: any;
}
