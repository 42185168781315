import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import type { PplPopoverDirectiveToggleEvent } from '../popover/popover.directive';

@Component({
  selector: 'ppl-selector-view',
  templateUrl: './selector-view.component.html',
  styleUrls: ['./selector-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorViewComponent {

  @Input() value: any;
  @Input() valueTemplate: TemplateRef<any>;
  @Input() pickerPopoverTemplate: TemplateRef<any>;
  @Input() pickerPopoverData: any;

  @Input() loading = false;
  @Input() loadingInverse = false;
  @Input() available = true;
  @Input() disabled = false;
  @Input() contentDisabled = false;
  @Input() pickerDisabled = false;

  @Output() contentClick = new EventEmitter<any>();
  @Output() pickerClick = new EventEmitter<SelectorViewPickerEvent>();

  constructor() { }

  onPopoverToggle(event: PplPopoverDirectiveToggleEvent) {
    this.pickerClick.emit({ visible: event.visible });
  }

  onContentClick() {
    if (!this.disabled) {
      this.contentClick.emit(this.value);
    }
  }

}

export interface SelectorViewPickerEvent {
  visible: boolean;
}
