import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplIconModule } from '../icon';
import { PplPopoverModule } from '../popover';
import { PplTextDropdownComponent } from './text-dropdown.component';
import { PplPopoverWindowModule } from '../popover-window';
import { PplMenuModule } from '../menu';



@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplPopoverModule,
    PplPopoverWindowModule,
    PplMenuModule
  ],
  declarations: [
    PplTextDropdownComponent
  ],
  exports: [
    PplTextDropdownComponent
  ]
})
export class PplTextDropdownModule { }

export * from './text-dropdown.component';
