import { ToastContentDemoComponent } from './toast-content-demo/toast-content-demo.component';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ViewContainerRef
} from '@angular/core';
import { ToastService } from '@ppl/ui/toast';

@Component({
  selector: 'ppl-toast-demo',
  templateUrl: './toast-demo.component.html',
  styleUrls: ['./toast-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastDemoComponent implements OnInit {

  code = `
  <button
    pplButton
    pplButton1
    (click)="onOpenToast()">
    Show Toast
  </button>

  <script>
    onOpenToast() {
      this.toastService.show({ viewContainerRef, component: ToastContentDemoComponent, data: { ... }, options: { ... } });
    }
  </script>
  `;

  index = 0;

  constructor(
    private toastService: ToastService,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
  }

  onOpenToast(type?: 'sticky' | 'short') {
    this.index++;
    this.toastService.show({
      viewContainerRef: this.viewContainerRef,
      component: ToastContentDemoComponent,
      data: { index: this.index },
      options: { sticky: type === 'sticky', duration: type === 'short' ? 1000 : undefined }
    });
  }

  onOpenMessage() {
    this.index++;
    this.toastService.showMessage(`Custom Message ${this.index}`, 'Action Text', { actionClicked: () => alert('Action Clicked!') });
  }

  hide() {
    this.toastService.hide();
  }
}
