export const TranslationsErrorCodes = {
  EnumErrorCode_NO_ERROR: $localize`:@@EnumErrorCode_NO_ERROR:No error.`,
  EnumErrorCode_ERROR_CONFLICT_DETECTED: $localize`:@@EnumErrorCode_ERROR_CONFLICT_DETECTED:A conflict is detected.`,
  EnumErrorCode_ERROR_INCORRECT_LOGIN: $localize`:@@EnumErrorCode_ERROR_INCORRECT_LOGIN:Incorrect login.`,
  EnumErrorCode_ERROR_TEAM_SPACE_NOT_ACTIVATED: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_NOT_ACTIVATED:The team space is not activated.`,
  EnumErrorCode_ERROR_DATA_COULD_NOT_BE_CONVERTED: $localize`:@@EnumErrorCode_ERROR_DATA_COULD_NOT_BE_CONVERTED:Data cannot be converted.`,
  EnumErrorCode_ERROR_INVALID_MESSAGE: $localize`:@@EnumErrorCode_ERROR_INVALID_MESSAGE:Invalid message.`,
  EnumErrorCode_ERROR_INVALID_TEAM_SPACE_VERSION: $localize`:@@EnumErrorCode_ERROR_INVALID_TEAM_SPACE_VERSION:Invalid version of team space.`,
  EnumErrorCode_ERROR_UNKNOWN_VERSION: $localize`:@@EnumErrorCode_ERROR_UNKNOWN_VERSION:Unknown space version.`,
  EnumErrorCode_ERROR_ENGINE_FOR_VERSION_NOT_EXISTS: $localize`:@@EnumErrorCode_ERROR_ENGINE_FOR_VERSION_NOT_EXISTS:Synchronization engine for this version doesn't exist.`,
  EnumErrorCode_ERROR_DB_FOR_VERSION_NOT_EXISTS: $localize`:@@EnumErrorCode_ERROR_DB_FOR_VERSION_NOT_EXISTS:Database for this version doesn't exist.`,
  EnumErrorCode_ERROR_USING_HIGHER_VERSION_TO_CS: $localize`:@@EnumErrorCode_ERROR_USING_HIGHER_VERSION_TO_CS:Client uses higher version than version of team pipeline.`,
  EnumErrorCode_ERROR_USING_OLDER_VERSION_TO_CS: $localize`:@@EnumErrorCode_ERROR_USING_OLDER_VERSION_TO_CS:Client uses older version than team pipeline.`,
  EnumErrorCode_ERROR_VERSION_UPGRADE_FAILED: $localize`:@@EnumErrorCode_ERROR_VERSION_UPGRADE_FAILED:Upgrade of team space to higher version failed!`,
  EnumErrorCode_ERROR_VERSION_UPGRADE_ALREADY_DONE: $localize`:@@EnumErrorCode_ERROR_VERSION_UPGRADE_ALREADY_DONE:Upgrade of team space has been already done to selected version.`,
  EnumErrorCode_ERROR_REVISION_ENGINE_HAS_LOCK: $localize`:@@EnumErrorCode_ERROR_REVISION_ENGINE_HAS_LOCK:Revision engine has lock.`,
  EnumErrorCode_ERROR_NOT_ENOUGH_PERMISSIONS: $localize`:@@EnumErrorCode_ERROR_NOT_ENOUGH_PERMISSIONS:Not enough permissions for this operation.`,
  EnumErrorCode_ERROR_REVISION_ENGINE_HAS_NOT_LOCK: $localize`:@@EnumErrorCode_ERROR_REVISION_ENGINE_HAS_NOT_LOCK:Error Code\: ERROR REVISION ENGINE HAS NOT LOCK`,
  EnumErrorCode_ERROR_TEAM_SPACE_NOT_UNIQUE: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_NOT_UNIQUE:Name of team space is not unique.`,
  EnumErrorCode_ERROR_INCORRECT_VERSION_NUMBER: $localize`:@@EnumErrorCode_ERROR_INCORRECT_VERSION_NUMBER:Unsupported version number.`,
  EnumErrorCode_ERROR_DATABASE_QUERY: $localize`:@@EnumErrorCode_ERROR_DATABASE_QUERY:Invalid request. The database is corrupted.`,
  EnumErrorCode_ERROR_USER_OWNS_OPPTY: $localize`:@@EnumErrorCode_ERROR_USER_OWNS_OPPTY:User owns %Opportunity_s%.`,
  EnumErrorCode_ERROR_CLIENTS_LIMIT_REACHED: $localize`:@@EnumErrorCode_ERROR_CLIENTS_LIMIT_REACHED:Maximum clients connected to team pipeline reached.`,
  EnumErrorCode_ERROR_SN_ALREADY_OCCUPIED: $localize`:@@EnumErrorCode_ERROR_SN_ALREADY_OCCUPIED:The serial number is already occupied.`,
  EnumErrorCode_ERROR_NO_MORE_LICENSE_SEATS_AVAILABLE: $localize`:@@EnumErrorCode_ERROR_NO_MORE_LICENSE_SEATS_AVAILABLE:No more license seats are available.`,
  EnumErrorCode_ERROR_LICENSE_DOES_NOT_EXISTS: $localize`:@@EnumErrorCode_ERROR_LICENSE_DOES_NOT_EXISTS:License doesn't exist.`,
  EnumErrorCode_ERROR_SN_NOT_OCCUPYING_LICENSE: $localize`:@@EnumErrorCode_ERROR_SN_NOT_OCCUPYING_LICENSE:Serial number is not occupying license.`,
  EnumErrorCode_ERROR_LICENSE_ALREADY_EXPIRED: $localize`:@@EnumErrorCode_ERROR_LICENSE_ALREADY_EXPIRED:License already expired.`,
  EnumErrorCode_ERROR_READ_PIPELINE_INIT_FILE: $localize`:@@EnumErrorCode_ERROR_READ_PIPELINE_INIT_FILE:Error reading space file.`,
  EnumErrorCode_ERROR_INVALID_SN: $localize`:@@EnumErrorCode_ERROR_INVALID_SN:Serial number is invalid.`,
  EnumErrorCode_ERROR_READING_TEMPLATE_FILE: $localize`:@@EnumErrorCode_ERROR_READING_TEMPLATE_FILE:Error reading template file.`,
  EnumErrorCode_ERROR_CANNOT_CONVERT_USERSPACE_USED: $localize`:@@EnumErrorCode_ERROR_CANNOT_CONVERT_USERSPACE_USED:Cannot convert space.`,
  EnumErrorCode_ERROR_TRANSACTION_NOT_OPEN: $localize`:@@EnumErrorCode_ERROR_TRANSACTION_NOT_OPEN:Transaction is not open.`,
  EnumErrorCode_ERROR_TEAM_SPACE_NOT_EXISTS: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_NOT_EXISTS:Team space doesn't exist.`,
  EnumErrorCode_ERROR_NOT_IMPLEMENTED: $localize`:@@EnumErrorCode_ERROR_NOT_IMPLEMENTED:The method is not implemented yet.`,
  EnumErrorCode_ERROR_PARAMETER_INVALID: $localize`:@@EnumErrorCode_ERROR_PARAMETER_INVALID:Invalid parameter. Type or the value of this parameter is wrong.`,
  EnumErrorCode_ERROR_PARAMETER_UNKNOWN: $localize`:@@EnumErrorCode_ERROR_PARAMETER_UNKNOWN:Unknown parameter.`,
  EnumErrorCode_ERROR_PARAMETER_MISSING: $localize`:@@EnumErrorCode_ERROR_PARAMETER_MISSING:Required parameter is missing.`,
  EnumErrorCode_ERROR_RELATIONS_DOESNT_EXIST: $localize`:@@EnumErrorCode_ERROR_RELATIONS_DOESNT_EXIST:Related object does not exist.`,
  EnumErrorCode_ERROR_CYCLE: $localize`:@@EnumErrorCode_ERROR_CYCLE:You want to set a cycle, which is not allowed.`,
  EnumErrorCode_ERROR_BATCH: $localize`:@@EnumErrorCode_ERROR_BATCH:Class cannot be updated or inserted.`,
  EnumErrorCode_ERROR_LEAD_NOT_FOUND: $localize`:@@EnumErrorCode_ERROR_LEAD_NOT_FOUND:%Lead_s% not found.`,
  EnumErrorCode_ERROR_LEAD_ALREADY_ASSIGNED: $localize`:@@EnumErrorCode_ERROR_LEAD_ALREADY_ASSIGNED:%Lead_s% is already assigned.`,
  EnumErrorCode_ERROR_SALES_UNIT_DOESNT_EXISTS: $localize`:@@EnumErrorCode_ERROR_SALES_UNIT_DOESNT_EXISTS:Sales unit doesn't exist.`,
  EnumErrorCode_ERROR_CLIENT_DOESNT_EXISTS: $localize`:@@EnumErrorCode_ERROR_CLIENT_DOESNT_EXISTS:User doesn't exist.`,
  EnumErrorCode_ERROR_CLIENT_DOESNT_EXISTS_IN_SALES_UNIT: $localize`:@@EnumErrorCode_ERROR_CLIENT_DOESNT_EXISTS_IN_SALES_UNIT:User doesn't exist in sales unit.`,
  EnumErrorCode_ERROR_UNAUTHORIZED_CALL: $localize`:@@EnumErrorCode_ERROR_UNAUTHORIZED_CALL:Authentication failed. The username or password is wrong.`,
  EnumErrorCode_ERROR_NO_LONGER_SUPPORTED: $localize`:@@EnumErrorCode_ERROR_NO_LONGER_SUPPORTED:Entity is no longer supported for actual Server API version.`,
  EnumErrorCode_ERROR_SN_DISABLED: $localize`:@@EnumErrorCode_ERROR_SN_DISABLED:Error Code\: ERROR SN DISABLED`,
  EnumErrorCode_ERROR_CONTRACT_DISABLED: $localize`:@@EnumErrorCode_ERROR_CONTRACT_DISABLED:Error Code\: ERROR CONTRACT DISABLED`,
  EnumErrorCode_ERROR_ACCOUNT_DISABLED: $localize`:@@EnumErrorCode_ERROR_ACCOUNT_DISABLED:Error Code\: ERROR ACCOUNT DISABLED`,
  EnumErrorCode_ERROR_TEAM_PIPELINE_DOESNT_EXISTS_OR_ACCESS_DENIED: $localize`:@@EnumErrorCode_ERROR_TEAM_PIPELINE_DOESNT_EXISTS_OR_ACCESS_DENIED:Space does not exist or you have insufficient rights to access it`,
  EnumErrorCode_ERROR_TEAM_PIPELINE_IS_COMMERCIAL: $localize`:@@EnumErrorCode_ERROR_TEAM_PIPELINE_IS_COMMERCIAL:Error Code\: TEAM PIPELINE IS COMMERCIAL`,
  EnumErrorCode_ERROR_SN_DOESNT_EXISTS_OR_ACCESS_DENIED: $localize`:@@EnumErrorCode_ERROR_SN_DOESNT_EXISTS_OR_ACCESS_DENIED:Error Code\: SN DOESNT EXISTS OR ACCESS DENIED`,
  EnumErrorCode_ERROR_INVALID_CONTRACT: $localize`:@@EnumErrorCode_ERROR_INVALID_CONTRACT:Error Code\: INVALID CONTRACT`,
  EnumErrorCode_ERROR_ACCOUNT_DOESNT_EXISTS: $localize`:@@EnumErrorCode_ERROR_ACCOUNT_DOESNT_EXISTS:Error Code\: ACCOUNT DOESNT EXISTS`,
  EnumErrorCode_ERROR_SERIAL_IS_NOT_TEAM: $localize`:@@EnumErrorCode_ERROR_SERIAL_IS_NOT_TEAM:Error Code\: SERIAL IS NOT TEAM`,
  EnumErrorCode_ERROR_SIZE_CANT_BE_DETERMINED: $localize`:@@EnumErrorCode_ERROR_SIZE_CANT_BE_DETERMINED:Error Code\: CANT BE DETERMINED`,
  EnumErrorCode_ERROR_TEAM_SPACE_NO_MORE_SPACE_LEFT: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_NO_MORE_SPACE_LEFT:Error Code\: TEAM SPACE NO MORE SPACE LEFT`,
  EnumErrorCode_ERROR_TIME_OVERLAPPED: $localize`:@@EnumErrorCode_ERROR_TIME_OVERLAPPED:Time period overlaps with other period.`,
  EnumErrorCode_ERROR_CLIENT_EXISTS: $localize`:@@EnumErrorCode_ERROR_CLIENT_EXISTS:User exists already. You cannot store user with this same username.`,
  EnumErrorCode_ERROR_NO_ENTITY: $localize`:@@EnumErrorCode_ERROR_NO_ENTITY:Entity doesn't exist. You use method set with wrong ID.`,
  EnumErrorCode_ERROR_AUTHORIZATION_ENGINE_NOT_AVAILABLE: $localize`:@@EnumErrorCode_ERROR_AUTHORIZATION_ENGINE_NOT_AVAILABLE:Error Code\: AUTHORIZATION ENGINE NOT AVAILABLE`,
  EnumErrorCode_ERROR_CANNOT_CONVERT_USERSPACE_INTEGRATED: $localize`:@@EnumErrorCode_ERROR_CANNOT_CONVERT_USERSPACE_INTEGRATED:Error Code\: CANNOT CONVERT USERSPACE INTEGRATED`,
  EnumErrorCode_ERROR_SINGLETON_ERROR: $localize`:@@EnumErrorCode_ERROR_SINGLETON_ERROR:Error Code\: SINGLETON ERROR`,
  EnumErrorCode_ERROR_USER_ACCOUNT_DISABLED: $localize`:@@EnumErrorCode_ERROR_USER_ACCOUNT_DISABLED:Error Code\: USER ACCOUNT DISABLED`,
  EnumErrorCode_ERROR_USER_IN_TEAM_PIPELINE_DISABLED: $localize`:@@EnumErrorCode_ERROR_USER_IN_TEAM_PIPELINE_DISABLED:Error Code\: USER IN SPACE DISABLED`,
  EnumErrorCode_ERROR_SUBSCRIPTION_HAS_EXPIRED: $localize`:@@EnumErrorCode_ERROR_SUBSCRIPTION_HAS_EXPIRED:Error Code\: SUBSCRIPTION HAS EXPIRED`,
  EnumErrorCode_ERROR_CLIENT_SPACE_RESET: $localize`:@@EnumErrorCode_ERROR_CLIENT_SPACE_RESET:Error Code\: CLIENT SPACE RESET`,
  EnumErrorCode_ERROR_AUTO_LOGOUT: $localize`:@@EnumErrorCode_ERROR_AUTO_LOGOUT:Error Code\: AUTO LOGOUT`,
  EnumErrorCode_ERROR_UNEXPECTED_RESPONSE: $localize`:@@EnumErrorCode_ERROR_UNEXPECTED_RESPONSE:Error Code\: UNEXPECTED RESPONSE`,
  EnumErrorCode_ERROR_NO_PRIMARY_EMAIL: $localize`:@@EnumErrorCode_ERROR_NO_PRIMARY_EMAIL:Error Code\: NO PRIMARY EMAIL`,
  EnumErrorCode_ERROR_NO_PRIMARY_PHONE: $localize`:@@EnumErrorCode_ERROR_NO_PRIMARY_PHONE:Error Code\: NO PRIMARY PHONE`,
  EnumErrorCode_ERROR_ACC_NO_OWNER: $localize`:@@EnumErrorCode_ERROR_ACC_NO_OWNER:Error Code\: ACCOUNT NO OWNER`,
  EnumErrorCode_ERROR_SCHEMA_CHANGED: $localize`:@@EnumErrorCode_ERROR_SCHEMA_CHANGED:Error Code\: SCHEMA CHANGED`,
  EnumErrorCode_ERROR_REVISION_ENGINE_IN_READONLY_MODE: $localize`:@@EnumErrorCode_ERROR_REVISION_ENGINE_IN_READONLY_MODE:Error Code\: REVISION ENGINE IN READONLY MODE`,
  EnumErrorCode_ERROR_DOCUMENT_IS_DELETED_ALREADY: $localize`:@@EnumErrorCode_ERROR_DOCUMENT_IS_DELETED_ALREADY:Error Code\: DOCUMENT IS ALREADY DELETED`,
  EnumErrorCode_ERROR_FORMUPDATE_COMMAND_NOT_RECOGNIZED: $localize`:@@EnumErrorCode_ERROR_FORMUPDATE_COMMAND_NOT_RECOGNIZED:Error Code\: FORM UPDATE COMMAND NOT RECOGNIZED`,
  EnumErrorCode_ERROR_FORGET_PASSWORD_EMAIL_SEND_FAILED: $localize`:@@EnumErrorCode_ERROR_FORGET_PASSWORD_EMAIL_SEND_FAILED:Error Code\: FORGET PASSWORD EMAIL SEND FAILED`,
  EnumErrorCode_ERROR_FORGET_PASSWORD_EMAIL_INVALID: $localize`:@@EnumErrorCode_ERROR_FORGET_PASSWORD_EMAIL_INVALID:Error Code\: FORGET PASSWORD EMAIL INVALID`,
  EnumErrorCode_ERROR_FORGET_PASSWORD_EMAIL_MISMATCH: $localize`:@@EnumErrorCode_ERROR_FORGET_PASSWORD_EMAIL_MISMATCH:Error Code\: FORGET PASSWORD EMAIL MISMATCH`,
  EnumErrorCode_ERROR_SENDING_MAIL: $localize`:@@EnumErrorCode_ERROR_SENDING_MAIL:Error Code\: ERROR SENDING MAIL`,
  EnumErrorCode_ERROR_TRIAL_EMAIL_EXISTS: $localize`:@@EnumErrorCode_ERROR_TRIAL_EMAIL_EXISTS:Error Code\: TRIAL EMAIL EXISTS`,
  EnumErrorCode_ERROR_TEAM_SPACE_TRANSFER: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_TRANSFER:Error Code\: TEAM SPACE TRANSFER`,
  EnumErrorCode_ERROR_RELOAD_TOKEN: $localize`:@@EnumErrorCode_ERROR_RELOAD_TOKEN:Error Code\: RELOAD TOKEN`,
  EnumErrorCode_ERROR_CHANGES_ARE_BG_SYNC_NOT_COMPATIBLE: $localize`:@@EnumErrorCode_ERROR_CHANGES_ARE_BG_SYNC_NOT_COMPATIBLE:Error Code\: CHANGES ARE BG SYNC NOT COMPATIBLE`,
  EnumErrorCode_ERROR_CLEANING_DUMP: $localize`:@@EnumErrorCode_ERROR_CLEANING_DUMP:Error Code\: CLEANING DUMP`,
  EnumErrorCode_ERROR_DUMPING_DB: $localize`:@@EnumErrorCode_ERROR_DUMPING_DB:Error Code\: DUMPING DB`,
  EnumErrorCode_ERROR_NOT_DELETABLE: $localize`:@@EnumErrorCode_ERROR_NOT_DELETABLE:Not deletable entity through method deleteEntities.`,
  EnumErrorCode_ERROR_TRANSACTION_OPEN: $localize`:@@EnumErrorCode_ERROR_TRANSACTION_OPEN:Error Code\: TRANSACTION OPEN`,
  EnumErrorCode_ERROR_CALC_BAD_FORMULA_SYNTAX: $localize`:@@EnumErrorCode_ERROR_CALC_BAD_FORMULA_SYNTAX:Error Code\: CALC BAD FORMULA SYNTAX`,
  EnumErrorCode_ERROR_CALC_CIRCULAR_REFERENCE: $localize`:@@EnumErrorCode_ERROR_CALC_CIRCULAR_REFERENCE:Error Code\: CALC CIRCULAR REFERENCE`,
  EnumErrorCode_ERROR_CALC_DIVIDED_BY_0: $localize`:@@EnumErrorCode_ERROR_CALC_DIVIDED_BY_0:Error Code\: CALCULATION DIVIDED BY ZERO`,
  EnumErrorCode_ERROR_CALC_INVALID_REFERENCE: $localize`:@@EnumErrorCode_ERROR_CALC_INVALID_REFERENCE:Error Code\: CALCULATION INVALID REFERENCE`,
  EnumErrorCode_ERROR_CALC_WRONG_TYPE: $localize`:@@EnumErrorCode_ERROR_CALC_WRONG_TYPE:Error Code\: CALCULATION WRONG TYPE`,
  EnumErrorCode_ERROR_CALC_NO_VALUE: $localize`:@@EnumErrorCode_ERROR_CALC_NO_VALUE:Error Code\: CALCULATION NO VALUE`,
  EnumErrorCode_ERROR_CALC_FORMULA_NOT_ALLOWED: $localize`:@@EnumErrorCode_ERROR_CALC_FORMULA_NOT_ALLOWED:Error Code\: CALCULATION FORMULA NOT ALLOWED`,
  EnumErrorCode_ERROR_TOO_MANY_REQUESTS: $localize`:@@EnumErrorCode_ERROR_TOO_MANY_REQUESTS:Error Code\: TOO MANY REQUESTS`,
  EnumErrorCode_ERROR_CLIENT_DISCONNECTED: $localize`:@@EnumErrorCode_ERROR_CLIENT_DISCONNECTED:Error Code\: CLIENT DISCONNECTED`,
  EnumErrorCode_ERROR_METHOD_DO_NOT_EXISTS: $localize`:@@EnumErrorCode_ERROR_METHOD_DO_NOT_EXISTS:Error Code\: METHOD DO NOT EXISTS`,
  EnumErrorCode_ERROR_INVALID_VERSION_MODEL: $localize`:@@EnumErrorCode_ERROR_INVALID_VERSION_MODEL:Error Code\: INVALID_VERSION_MODEL`,
  EnumErrorCode_ERROR_TOO_MANY_CHANGES_FOR_SYNC: $localize`:@@EnumErrorCode_ERROR_TOO_MANY_CHANGES_FOR_SYNC:Error Code\: TOO MANY CHANGES FOR SYNC`,
  EnumErrorCode_ERROR_CALC_VALIDATION_FAILED: $localize`:@@EnumErrorCode_ERROR_CALC_VALIDATION_FAILED:Error Code\: CALCULATION VALIDATION FAILED`,
  EnumErrorCode_ERROR_INTEGRATION_FAILED: $localize`:@@EnumErrorCode_ERROR_INTEGRATION_FAILED:Error Code\: NTEGRATION FAILED`,
  EnumErrorCode_ERROR_NOT_ALLOWED_TO_USE_MOBILE: $localize`:@@EnumErrorCode_ERROR_NOT_ALLOWED_TO_USE_MOBILE:Error Code\: NOT ALLOWED TO USE MOBILE`,
  EnumErrorCode_ERROR_NO_PIPELINE_ACCESS: $localize`:@@EnumErrorCode_ERROR_NO_PIPELINE_ACCESS:Error Code\: NO PIPELINE ACCESS`,
  EnumErrorCode_ERROR_BROKEN_SYNC_MODEL: $localize`:@@EnumErrorCode_ERROR_BROKEN_SYNC_MODEL:Error Code\: BROKEN SYNC MODEL`,
  EnumErrorCode_ERROR_FK_NOT_FOUND: $localize`:@@EnumErrorCode_ERROR_FK_NOT_FOUND:Error Code\: FK NOT FOUND`,
  EnumErrorCode_ERROR_DB_SOME_ERROR: $localize`:@@EnumErrorCode_ERROR_DB_SOME_ERROR:Database error occurred, operation is not completed!`,
  EnumErrorCode_ERROR_INVALID_POST_PARAMS: $localize`:@@EnumErrorCode_ERROR_INVALID_POST_PARAMS:Error Code\: INVALID POST PARAMETERS`,
  EnumErrorCode_ERROR_UNAUTHORIZED: $localize`:@@EnumErrorCode_ERROR_UNAUTHORIZED:Error Code\: UNAUTHORIZED ACCESS`,
  EnumErrorCode_ERROR_TEAM_SPACE_NOT_ACTIVE: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_NOT_ACTIVE:Error Code\: SPACE NOT ACTIVE`,
  EnumErrorCode_ERROR_OLD_ENTITY_REVISION: $localize`:@@EnumErrorCode_ERROR_OLD_ENTITY_REVISION:Revision of entity is old. Try reload entity.`,
  EnumErrorCode_ERROR_INVALID_ENTITY_REVISION: $localize`:@@EnumErrorCode_ERROR_INVALID_ENTITY_REVISION:Revision is corrupted or wrong.`,
  EnumErrorCode_ERROR_DATA_INTEGRITY: $localize`:@@EnumErrorCode_ERROR_DATA_INTEGRITY:Error Code\: DATA ERROR INTEGRITY`,
  EnumErrorCode_ERROR_CLIENTSPACE_NAME_INVALID: $localize`:@@EnumErrorCode_ERROR_CLIENTSPACE_NAME_INVALID:Error Code\: SPACE NAME INVALID`,
  EnumErrorCode_ERROR_CLIENTSPACE_NAME_EMPTY: $localize`:@@EnumErrorCode_ERROR_CLIENTSPACE_NAME_EMPTY:Error Code\: SPACE NAME EMPTY`,
  EnumErrorCode_ERROR_POSITION_ALREADY_EXISTS: $localize`:@@EnumErrorCode_ERROR_POSITION_ALREADY_EXISTS:Error Code\: POSITION ALREADY EXISTS`,
  EnumErrorCode_ERROR_HAS_CHILDS: $localize`:@@EnumErrorCode_ERROR_HAS_CHILDS:Entity with children can't be deleted.`,
  EnumErrorCode_ERROR_MUST_HAVE_AT_LEAST_ONE_SU: $localize`:@@EnumErrorCode_ERROR_MUST_HAVE_AT_LEAST_ONE_SU:Error Code\: MUST HAVE AT LEAST ONE SU`,
  EnumErrorCode_ERROR_CLIENTSPACE_UPGRADE_ERROR: $localize`:@@EnumErrorCode_ERROR_CLIENTSPACE_UPGRADE_ERROR:Error Code\: SPACE UPGRADE ERROR`,
  EnumErrorCode_ERROR_NO_ROW_UPDATED: $localize`:@@EnumErrorCode_ERROR_NO_ROW_UPDATED:Error Code\: NO ROW UPDATED`,
  EnumErrorCode_ERROR_LOCK_NOT_OBTAINED: $localize`:@@EnumErrorCode_ERROR_LOCK_NOT_OBTAINED:Raised when API call waits more than 300sec for lock.`,
  EnumErrorCode_ERROR_ATTRIBUTE_NOT_UNIQUE: $localize`:@@EnumErrorCode_ERROR_ATTRIBUTE_NOT_UNIQUE:Attribute must be unique.`,
  EnumErrorCode_ERROR_UPDATE_NOT_ALLOWED: $localize`:@@EnumErrorCode_ERROR_UPDATE_NOT_ALLOWED:Attribute is not allowed on update.`,
  EnumErrorCode_ERROR_PRIMARY_IS_NOT_SET: $localize`:@@EnumErrorCode_ERROR_PRIMARY_IS_NOT_SET:Primary entity is not set.`,
  EnumErrorCode_ERROR_DUPLICATE_RELATION: $localize`:@@EnumErrorCode_ERROR_DUPLICATE_RELATION:Each relational entity must be unique.`,
  EnumErrorCode_ERROR_MINITEMS_REQUIRED: $localize`:@@EnumErrorCode_ERROR_MINITEMS_REQUIRED:Multiple choices are required to set in list.`,
  EnumErrorCode_ERROR_TEAM_SPACE_UNDER_MAINTENANCE: $localize`:@@EnumErrorCode_ERROR_TEAM_SPACE_UNDER_MAINTENANCE:Space is disabled for maintenance`,
  EnumErrorCode_ERROR_TRAY_IO_DOESNT_RESPOND: $localize`:@@EnumErrorCode_ERROR_TRAY_IO_DOESNT_RESPOND:Automation hub does not respond`,
  EnumErrorCode_ERROR_NOT_DIRECT_RELATION: $localize`:@@EnumErrorCode_ERROR_NOT_DIRECT_RELATION:Not a direct relation`,
  EnumErrorCode_ERROR_UNKNOWN: $localize`:@@EnumErrorCode_ERROR_UNKNOWN:Error Code\: UNKNOWN`,
  EnumErrorCode_ERROR_ASSERT: $localize`:@@EnumErrorCode_ERROR_ASSERT:Error Code\: ASSERT`,
  EnumErrorCode_ERROR_REGISTRATION_FAILED: $localize`:@@EnumErrorCode_ERROR_REGISTRATION_FAILED:Error Code\: REGISTRATION FAILED`,
  EnumErrorCode_ERROR_LEAD_AUTHORIZATION_FAILED: $localize`:@@EnumErrorCode_ERROR_LEAD_AUTHORIZATION_FAILED:Error Code\: LEAD AUTHORIZATION FAILED`,
  EnumErrorCode_ERROR_SERVER_API: $localize`:@@EnumErrorCode_ERROR_SERVER_API:Error Code\: SERVER API`,
  EnumErrorCode_ERROR_CHILD_EXISTS: $localize`:@@EnumErrorCode_ERROR_CHILD_EXISTS:Error Code\: CHILD EXISTS`,
  EnumErrorCode_ERROR_CONFLICTING_DATA: $localize`:@@EnumErrorCode_ERROR_CONFLICTING_DATA:You cannot delete the entity, because it is used.`,
  EnumErrorCode_ERROR_ENTITY_IS_USED: $localize`:@@EnumErrorCode_ERROR_ENTITY_IS_USED:Error Code\: ENTITY IS USED`,
  EnumErrorCode_ERROR_ENTITY_UPDATING_FAILED: $localize`:@@EnumErrorCode_ERROR_ENTITY_UPDATING_FAILED:Updating of an entity failed.`,
  EnumErrorCode_ERROR_TOO_MANY_STAGES: $localize`:@@EnumErrorCode_ERROR_TOO_MANY_STAGES:The maximum of stages can be 7.`,
  EnumErrorCode_ERROR_UNKNOWN_TYPE: $localize`:@@EnumErrorCode_ERROR_UNKNOWN_TYPE:Error Code\: UNKNOWN TYPE`,
  EnumErrorCode_ERROR_NUMBER_EXCEEDED: $localize`:@@EnumErrorCode_ERROR_NUMBER_EXCEEDED:Number of something is exceeded.`,
  EnumErrorCode_ERROR_TIME_EXPIRED: $localize`:@@EnumErrorCode_ERROR_TIME_EXPIRED:Timestamp is older.`,
  EnumErrorCode_ERROR_FIELD_EXISTS: $localize`:@@EnumErrorCode_ERROR_FIELD_EXISTS:Field cannot be shared, because it exists in other entity.`,
  EnumErrorCode_ERROR_FORM_UNKNOWN: $localize`:@@EnumErrorCode_ERROR_FORM_UNKNOWN:Server cannot process a form on field. Check the syntax, or is used unknown function.`,
  EnumErrorCode_ERROR_CONNECTION_FAILED: $localize`:@@EnumErrorCode_ERROR_CONNECTION_FAILED:Error Code\: CONNECTION FAILED`,
  EnumErrorCode_ERROR_MESSAGE_TOO_LONG: $localize`:@@EnumErrorCode_ERROR_MESSAGE_TOO_LONG:Error Code\: MESSAGE TOO LONG`,
  EnumErrorCode_ERROR_DEPLOYMENT_FAILED: $localize`:@@EnumErrorCode_ERROR_DEPLOYMENT_FAILED:Error Code\: DEPLOYMENT FAILED`,
  EnumErrorCode_ERROR_DYNAMICS_CONFIGURATION_NOT_FOUND: $localize`:@@EnumErrorCode_ERROR_DYNAMICS_CONFIGURATION_NOT_FOUND:Error Code\: DYNAMICS CONFIGURATION NOT FOUND`,
  EnumErrorCode_ERROR_MAX_DEQUEUE_COUNT_REACHED: $localize`:@@EnumErrorCode_ERROR_MAX_DEQUEUE_COUNT_REACHED:Error Code\: MAX DEQUEUE COUNT REACHED`,
  EnumErrorCode_ERROR_EXPORT_IS_RUNNING: $localize`:@@EnumErrorCode_ERROR_EXPORT_IS_RUNNING:Error Code\: EXPORT IS RUNNING`,
  EnumErrorCode_ERROR_EXPORT_HAS_FAILED: $localize`:@@EnumErrorCode_ERROR_EXPORT_HAS_FAILED:Error Code\: EXPORT HAS FAILED`,
  EnumErrorCode_ERROR_EXPORT_NOT_FOUND: $localize`:@@EnumErrorCode_ERROR_EXPORT_NOT_FOUND:Error Code\: EXPORT NOT FOUND`,
  EnumErrorCode_ERROR_IMAP_HOST_UNREACHABLE: $localize`:@@EnumErrorCode_ERROR_IMAP_HOST_UNREACHABLE:Error Code\: IMAP HOST UNREACHABLE`,
  EnumErrorCode_ERROR_IMAP_INCORRECT_LOGIN: $localize`:@@EnumErrorCode_ERROR_IMAP_INCORRECT_LOGIN:Error Code\: IMAP INCORRECT LOGIN`,
  EnumErrorCode_ERROR_IMAP_FOLDER_LIST_ERROR: $localize`:@@EnumErrorCode_ERROR_IMAP_FOLDER_LIST_ERROR:Error Code\: IMAP FOLDER LIST ERROR`,
  EnumErrorCode_ERROR_EXCHANGE_AUTODISCOVER_FAILED: $localize`:@@EnumErrorCode_ERROR_EXCHANGE_AUTODISCOVER_FAILED:Error Code\: EXCHANGE AUTODISCOVER FAILED`,
  EnumErrorCode_ERROR_EXCHANGE_LOGIN_FAILED: $localize`:@@EnumErrorCode_ERROR_EXCHANGE_LOGIN_FAILED:Error Code\: EXCHANGE LOGIN FAILED`,
  EnumErrorCode_ERROR_EXCHANGE_UNREACHABLE_FAILED: $localize`:@@EnumErrorCode_ERROR_EXCHANGE_UNREACHABLE_FAILED:Error Code\: EXCHANGE UNREACHABLE FAILED`,
  EnumErrorCode_ERROR_EXCHANGE_UNSUPPORTED: $localize`:@@EnumErrorCode_ERROR_EXCHANGE_UNSUPPORTED:Error Code\: EXCHANGE UNSUPPORTED`,
  EnumErrorCode_ERROR_REPORT_IS_TOO_LARGE: $localize`:@@EnumErrorCode_ERROR_REPORT_IS_TOO_LARGE:Error Code\: REPORT IS TOO LARGE`,
  EnumErrorCode_ERROR_REPORT_HAS_EXPIRED: $localize`:@@EnumErrorCode_ERROR_REPORT_HAS_EXPIRED:Error Code\: REPORT HAS EXPIRED`,
  EnumErrorCode_ERROR_JSON_FORM_INVALID: $localize`:@@EnumErrorCode_ERROR_JSON_FORM_INVALID:Error Code\: JSON FORM INVALID`,
  EnumErrorCode_ERROR_TOO_MANY_CUSTOM_FIELDS: $localize`:@@EnumErrorCode_ERROR_TOO_MANY_CUSTOM_FIELDS:Error Code\: TOO MANY CUSTOM FIELDS`,
  EnumErrorCode_ERROR_JSON_FORM_PARENT_DATASET_MISSING: $localize`:@@EnumErrorCode_ERROR_JSON_FORM_PARENT_DATASET_MISSING:Dependent dropdown field is missing on form!`,
  EnumErrorCode_ERROR_JOB_EXISTS: $localize`:@@EnumErrorCode_ERROR_JOB_EXISTS:Error Code\: JOB EXISTS`,
  EnumErrorCode_ERROR_JOB_NOT_FOUND: $localize`:@@EnumErrorCode_ERROR_JOB_NOT_FOUND:Error Code\: JOB NOT FOUND`,
  EnumErrorCode_ERROR_JOB_NOT_FINISHED_YET: $localize`:@@EnumErrorCode_ERROR_JOB_NOT_FINISHED_YET:Error Code\: JOB NOT FINISHED YET`,
  EnumErrorCode_ERROR_JOB_RESULT_FAILURE: $localize`:@@EnumErrorCode_ERROR_JOB_RESULT_FAILURE:Error Code\: JOB RESULT FAILURE`,
  EnumErrorCode_ERROR_JOB_RESULT_NOT_EXISTS: $localize`:@@EnumErrorCode_ERROR_JOB_RESULT_NOT_EXISTS:Error Code\: JOB RESULT NOT EXISTS`,
  EnumErrorCode_ERROR_JOB_PROCESS_FAILURE: $localize`:@@EnumErrorCode_ERROR_JOB_PROCESS_FAILURE:Error Code\: JOB PROCESS FAILURE`,
  EnumErrorCode_ERROR_JOB_CONTAINS_RUNNING_WORKER: $localize`:@@EnumErrorCode_ERROR_JOB_CONTAINS_RUNNING_WORKER:Error Code\: JOB CONTAINS RUNNING WORKER`,
  EnumErrorCode_ERROR_INVALID_GATEWAY_SERVICE: $localize`:@@EnumErrorCode_ERROR_INVALID_GATEWAY_SERVICE:Error Code\: NVALID GATEWAY SERVICE`,
  EnumErrorCode_ERROR_INVALID_GATEWAY_METHOD: $localize`:@@EnumErrorCode_ERROR_INVALID_GATEWAY_METHOD:Error Code\: INVALID GATEWAY METHOD`,
  EnumErrorCode_ERROR_INVALID_GATEWAY_REQUEST: $localize`:@@EnumErrorCode_ERROR_INVALID_GATEWAY_REQUEST:Error Code\: INVALID GATEWAY REQUEST`,
  EnumErrorCode_ERROR_3RD_PARTY_API_TOKEN_EXPIRED: $localize`:@@EnumErrorCode_ERROR_3RD_PARTY_API_TOKEN_EXPIRED:Error Code\: 3RD PARTY API TOKEN EXPIRED`,
  EnumErrorCode_ERROR_3RD_PARTY_API_ERROR: $localize`:@@EnumErrorCode_ERROR_3RD_PARTY_API_ERROR:3rd party API error.`,
  EnumErrorCode_ERROR_3RD_PARTY_API_ERROR_AUTHORIZATION_FAIL: $localize`:@@EnumErrorCode_ERROR_3RD_PARTY_API_ERROR_AUTHORIZATION_FAIL:3rd party API authorization failure.`,
  EnumErrorCode_ERROR_3RD_PARTY_QUOTA_EXCEEDED: $localize`:@@EnumErrorCode_ERROR_3RD_PARTY_QUOTA_EXCEEDED:3rd party storage quota exceeded.`,
  EnumErrorCode_ERROR_3RD_PARTY_RESOURCE_LIMIT_EXCEEDED: $localize`:@@EnumErrorCode_ERROR_3RD_PARTY_RESOURCE_LIMIT_EXCEEDED:Number of items in 3rd party storage exceeded limit.`,
  EnumErrorCode_ERROR_ERROR_3RD_PARTY_API_PERMISSION_DENIED: $localize`:@@EnumErrorCode_ERROR_ERROR_3RD_PARTY_API_PERMISSION_DENIED:3rd party API permission denied.`,
  EnumErrorCode_ERROR_PARAMETER_INVALID_MIN_STEPS: $localize`:@@EnumErrorCode_ERROR_PARAMETER_INVALID_MIN_STEPS:Invalid parameter. Minimum three steps are required per Pipeline.`,
  EnumErrorCode_ERROR_PARAMETER_INVALID_MAX_STEPS: $localize`:@@EnumErrorCode_ERROR_PARAMETER_INVALID_MAX_STEPS:Invalid parameter. Maximum thirty steps are allowed per Pipeline.`,
  EnumErrorCode_ERROR_PARAMETER_INVALID_TO_ADDRESS: $localize`:@@EnumErrorCode_ERROR_PARAMETER_INVALID_TO_ADDRESS:One or more email addresses are invalid.`,
  EnumErrorCode_ERROR_INVALID_EMAIL: $localize`:@@EnumErrorCode_ERROR_INVALID_EMAIL:One or more email addresses are invalid.`,
  EnumErrorCode_ERROR_MISSING_SCHEMA: $localize`:@@EnumErrorCode_ERROR_MISSING_SCHEMA:Error Code\: Missing process schema`,
  EnumErrorCode_ERROR_INVALID_SCHEMA: $localize`:@@EnumErrorCode_ERROR_INVALID_SCHEMA:Error Code\: Invalid process schema`,
  EnumErrorCode_ERROR_UNAVAILABLE_COMPANY_EMAIL: $localize`:@@EnumErrorCode_ERROR_UNAVAILABLE_COMPANY_EMAIL:No access to this company email.`,
  EnumErrorCode_ERROR_SCHEDULED_ACTION_ALREADY_FINISHED: $localize`:@@EnumErrorCode_ERROR_SCHEDULED_ACTION_ALREADY_FINISHED:Scheduled action already finished.`,
  EnumErrorCode_ERROR_EMAIL_UNSUBSCRIBED: $localize`:@@EnumErrorCode_ERROR_EMAIL_UNSUBSCRIBED:The email was not sent because the record was unsubscribed from Email communication.`,
  EnumErrorCode_ERROR_HYBRID_SPACE_IS_REQUIRED: $localize`:@@EnumErrorCode_ERROR_HYBRID_SPACE_IS_REQUIRED:Error Code\: HYBRID SPACE IS REQUIRED`,
  EnumErrorCode_ERROR_FEATURE_NOT_ALLOWED: $localize`:@@EnumErrorCode_ERROR_FEATURE_NOT_ALLOWED:Error Code\: Feature not allowed.`,
  EnumErrorCode_ERROR_INVALID_ROLLUP_CONFIGURATION: $localize`:@@EnumErrorCode_ERROR_INVALID_ROLLUP_CONFIGURATION:Error Code\: Invalid rollup configuration.`,
  EnumErrorCode_ERROR_ROLLUP_FILTER_CONTAINS_NOT_ACCESSIBLE_FIELD: $localize`:@@EnumErrorCode_ERROR_ROLLUP_FILTER_CONTAINS_NOT_ACCESSIBLE_FIELD:The filter of Summarized Records contains a not accessible field.`,
  EnumErrorCode_ERROR_ROLLUP_SUMMARIZE_FIELD_NOT_ACCESSIBLE: $localize`:@@EnumErrorCode_ERROR_ROLLUP_SUMMARIZE_FIELD_NOT_ACCESSIBLE:Field to summarize is not accessible.`,
  EnumErrorCode_ERROR_ROLLUP_SUMMARIZE_FIELD_IS_DELETED: $localize`:@@EnumErrorCode_ERROR_ROLLUP_SUMMARIZE_FIELD_IS_DELETED:Field to summarize is deleted.`,
  EnumErrorCode_ERROR_ROLLUP_RELATION_DELETED: $localize`:@@EnumErrorCode_ERROR_ROLLUP_RELATION_DELETED:Summarization record is not accessible — is deleted.`,
  EnumErrorCode_ERROR_ROLLUP_INSUFFICIENT_MASTER_RIGHTS: $localize`:@@EnumErrorCode_ERROR_ROLLUP_INSUFFICIENT_MASTER_RIGHTS:Summarization record is not accessible — not enough permissions.`,
  EnumErrorCode_ERROR_AUTODISCOVERY_FAILED: $localize`:@@EnumErrorCode_ERROR_AUTODISCOVERY_FAILED:Error Code\: Autodiscovery failed.`,
  EnumErrorCode_ERROR_NOT_FOUND: $localize`:@@EnumErrorCode_ERROR_NOT_FOUND:Error Code\: Not found.`,
  EnumErrorCode_ERROR_ROLLUP_FILTER_CONTAINS_DELETED_FIELD: $localize`:@@EnumErrorCode_ERROR_ROLLUP_FILTER_CONTAINS_DELETED_FIELD:The field in the filter for summarizing is deleted.`,
  EnumErrorCode_ERROR_ROLLUP_SORTING_FIELD_IS_DELETED: $localize`:@@EnumErrorCode_ERROR_ROLLUP_SORTING_FIELD_IS_DELETED:The field by which the records are sorted is deleted.`,
  EnumErrorCode_ERROR_ROLLUP_SORTING_FIELD_NOT_ACCESSIBLE: $localize`:@@EnumErrorCode_ERROR_ROLLUP_SORTING_FIELD_NOT_ACCESSIBLE:The field by which the records are sorted is not accessible.`,
  EnumErrorCode_ERROR_ALREADY_IN_PROGRESS: $localize`:@@EnumErrorCode_ERROR_ALREADY_IN_PROGRESS:Error Code\: Already in progress.`,
  EnumErrorCode_ERROR_ALREADY_EXISTS: $localize`:@@EnumErrorCode_ERROR_ALREADY_EXISTS:Error Code\: Already exists.`,
  EnumErrorCode_ERROR_FORBIDDEN_EXTENSION: $localize`:@@EnumErrorCode_ERROR_FORBIDDEN_EXTENSION:Error Code\: Forbidden Extension.`,
  EnumErrorCode_ERROR_REQUIRED_CALC_FORMULA_EMPTY: $localize`:@@EnumErrorCode_ERROR_REQUIRED_CALC_FORMULA_EMPTY:Error Code\: Calculated formula on field is required - but is empty.`,
  EnumErrorCode_ERROR_EMAIL_NOT_SHARED: $localize`:@@EnumErrorCode_ERROR_EMAIL_NOT_SHARED:Selected personal email account is not shared with you.`,
  EnumErrorCode_ERROR_INSUFFICIENT_CREDIT: $localize`:@@EnumErrorCode_ERROR_INSUFFICIENT_CREDIT:Insufficient credit`,
};
