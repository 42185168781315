<div #connectElement>
  <button *ngIf="!linkStyle"
          class="button"
          [disabled]="disabled"
          [pplPopover]="dropdown"
          [pplPopoverDisabled]="!options || !hasSomeVisibleOption"
          pplPopoverAlignEnd
          type="button">
    <ng-container *ngIf="selectedTextTemplate; else defaultSelectedTextTemplate">
      <ng-container *ngTemplateOutlet="selectedTextTemplate; context: { $implicit: label }"></ng-container>
    </ng-container>
  </button>

  <span *ngIf="linkStyle"
        class="link"
        [pplPopover]="dropdown"
        [pplPopoverDisabled]="!options || !hasSomeVisibleOption"
        pplPopoverAlignEnd>{{label}}</span>
</div>

<ng-template #defaultSelectedTextTemplate>
  <div class="selected-text">
    <span class="label">{{ label }}</span>

    <ppl-information-icon *ngIf="tooltip"
                          [message]="tooltip"></ppl-information-icon>

    <ppl-icon *ngIf="!disabled"
              svgIcon="rowrollup-dark"
              height="8px"
              width="8px"></ppl-icon>
  </div>
</ng-template>

<ng-template #dropdown
             let-position="position"
             let-popover="popover">
  <ppl-popover-window [position]="position"
                      [connectToElement]="connectElement">
    <ppl-menu [value]="value"
              [markSelected]="true"
              [hasIcons]="hasIcons"
              (valueChanged)="onValueChange($event)">
      <ng-container *ngFor="let option of options">
        <ppl-menu-item *ngIf="isVisible(option)"
                       [value]="option.value"
                       pplPopoverClose>
          <ppl-icon *ngIf="option.icon"
                    [svgIcon]="option.icon"
                    width="20px"
                    height="20px"></ppl-icon>
          {{ option.label }}
        </ppl-menu-item>
      </ng-container>
    </ppl-menu>
  </ppl-popover-window>
</ng-template>
