<div class="dialog-container"
     [class.type-alert]="type === 'alert'"
     [class.type-confirm]="type === 'confirm'"
     [class.type-info]="type === 'info'"
     [class.type-yes-no]="type === 'yesNo'"
     [class.type-yes-no-cancel]="type === 'yesNoCancel'"
     [class.type-warning]="type === 'warning'"
     [style.max-width]="maxWidthValue"
     [style.width]="widthValue">
  <div class="dialog-content">
    <div *ngIf="type"
         class="dialog-icon">
      <ppl-icon [svgIcon]="MessageIcons[type]"
                width="48px"
                height="48px"></ppl-icon>
    </div>

    <div class="dialog-data">
      <div *ngIf="text"
           class="dialog-text">{{text}}</div>

      <div *ngIf="description"
           class="dialog-description"
           [innerHTML]="description"></div>

      <ng-content></ng-content>
    </div>
  </div>

  <ng-container *ngIf="actionsTemplate">
    <div class="dialog-actions">
      <ng-template [ngTemplateOutlet]="actionsTemplate"></ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="!actionsTemplate">
    <div *ngIf="type === 'alert' || type === 'warning'"
         class="dialog-actions">
      <button type="button"
              pplButton
              [pplButtonType]="actionButtonTypeValue"
              pplButtonSmall
              pplButtonDialog
              (click)="onClose(true)">
        {{actionLabel || intl.Proceed}}
      </button>
      <button *ngIf="cancelVisible"
              pplButton
              pplButton1
              pplButtonSmall
              pplButtonDialog
              (click)="onClose(false)">
        {{cancelLabel || intl.Cancel}}
      </button>
    </div>

    <div *ngIf="type === 'confirm'"
         class="dialog-actions">
      <button *ngIf="actionVisible"
              pplButton
              [pplButtonType]="actionButtonTypeValue"
              pplButtonSmall
              pplButtonDialog
              (click)="onClose(true)">
        {{actionLabel || intl.Confirm}}
      </button>
      <button *ngIf="cancelVisible"
              pplButton
              pplButton1
              pplButtonSmall
              pplButtonDialog
              (click)="onClose(false)">
        {{cancelLabel || intl.Cancel}}
      </button>
    </div>

    <div *ngIf="type === 'info'"
         class="dialog-actions">
      <button type="button"
              pplButton
              [pplButtonType]="actionButtonTypeValue"
              pplButtonSmall
              pplButtonDialog
              (click)="onClose(true)">
        {{actionLabel || intl.Continue}}
      </button>
    </div>

    <div *ngIf="type === 'yesNo' || type === 'yesNoCancel'"
         class="dialog-actions">
      <button pplButton
              [pplButtonType]="actionButtonTypeValue"
              pplButtonSmall
              pplButtonDialog
              (click)="dialogRef.close('Yes')">
        {{yesLabel || intl.Yes}}
      </button>
      <button pplButton
              [pplButtonType]="noButtonTypeValue"
              pplButtonSmall
              pplButtonDialog
              (click)="dialogRef.close('No')">
        {{noLabel || intl.No}}
      </button>
      <button *ngIf="type === 'yesNoCancel'"
              pplButton
              pplButton1
              pplButtonSmall
              pplButtonDialog
              (click)="dialogRef.close('Cancel')">
        {{cancelLabel || intl.Cancel}}
      </button>
    </div>
  </ng-container>
</div>
