<ng-container *ngFor="let star of stars; trackBy: trackByValue">
  <div *ngIf="!showOne || star === 1"
       class="star"
       [class.disabled]="disabled"
       [class.readonly]="readonly"
       (mouseenter)="onStarMouseEnter(star)"
       (click)="onStarClick(star)">
    <ppl-icon [svgIcon]="getStarIcon(star)"
              [width]="normalizedStarSize"
              [height]="normalizedStarSize"></ppl-icon>
  </div>
</ng-container>
