import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ResizableToggleGroupComponent } from './resizable-toggle-group.component';
import { PplToggleGroupComponent } from './toggle-group.component';
import { RouterModule } from '@angular/router';
import { PplIconModule } from '../icon';

const components = [PplToggleGroupComponent, ResizableToggleGroupComponent];

@NgModule({
  imports: [CommonModule, RouterModule, PplIconModule],
  declarations: [...components],
  exports: [...components]
})
export class PplToggleGroupModule {}

export * from './toggle-group.component';
export * from './resizable-toggle-group.component';
