import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridAction, GridRecord } from '../grid.interfaces';
import type { GridActionClickEvent } from '../grid.interfaces';

@Component({
  selector: 'ppl-grid-record-action-icon',
  templateUrl: './grid-record-action-icon.component.html',
  styleUrls: ['./grid-record-action-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridRecordActionIconComponent {

  @Input() action: GridAction;
  @Input() record: GridRecord;

  @Output() actionClick = new EventEmitter<GridActionClickEvent>();

  isActionEnabled() {
    return (typeof this.action.enabled === 'function') ? this.action.enabled(this.record) : this.action.enabled;
  }

}
