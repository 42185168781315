import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'ppl-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavigationComponent implements OnInit {
  @Input() screenName: string;

  constructor() { }

  ngOnInit() {
  }
}
