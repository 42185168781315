import type {
  OnDestroy,
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef
,
  ChangeDetectorRef} from '@angular/core';
import { isAttachmentUnsupportedInfo, getAttachments, getAttachmentIconFromUrl } from './attachments.utils';
import { PplAttachmentFileTypeLimit } from './model';
import type { PplAttachment, PplAttachmentUnsupportedInfo  } from './model';
import { PplAttachmentsService } from './attachments.service';
import { PplUiIntl } from '../ppl-ui-intl';
import type { Subscription } from 'rxjs';
import { PplAttachmentUploadStatusEnum } from './model/attachment';

@Component({
  selector: 'ppl-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplAttachmentsComponent implements OnInit, OnDestroy {
  @Input() title: string | null = null; // null is default Documents (translated)
  @Input() files: PplAttachment[] = [];
  @Input() fileSizeLimit = 30;
  @Input() fileTypeLimit: PplAttachmentFileTypeLimit;
  @Input() singleSelection = false; // if set to true, only one file will be accepted
  @Input() hasHeader = true;
  @Input() emptyLang = 'No Documents linked';
  @Input() attachmentsLang = 'Attachments';
  @Input() readonly = false;
  @Input() accept: string | null = null;
  @Input() disableUploadClick = false;
  @Input() inlineAddButton = false;
  @Input() preserveOriginalFile = false;
  @Input() changedItemIds: string[];
  @Input() hasChanges = false;

  @Output() fileClick = new EventEmitter();
  @Output() filesChange: EventEmitter<PplAttachment[]> = new EventEmitter();
  @Output() unsupportedAttachment = new EventEmitter<PplAttachmentUnsupportedInfo>();
  @Output() addFilesClick = new EventEmitter();
  @Output() undoChanges = new EventEmitter();

  @ViewChild('fileInput', { read: ElementRef, static: true }) fileInput: ElementRef;

  addNewLang = 'Add New';
  browseLang = 'Browse';

  getAttachmentIconFromUrl = getAttachmentIconFromUrl;

  fileResolverSubscription: Subscription;
  upload = 'Click here to upload';

  dragOver = false;

  PplAttachmentUploadStatusEnum = PplAttachmentUploadStatusEnum;

  get addLang() {
    return this.singleSelection && this.files.length === 1 ? this.browseLang : this.addNewLang;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private attachmentsService: PplAttachmentsService,
    private intl: PplUiIntl
  ) { }

  ngOnInit() {
    if (this.title === null) {
      this.title = this.intl.documents;
    }
    this.upload = this.intl.attachmentsUpload;
    this.addNewLang = this.intl.attachNewDocument;
    this.browseLang = this.intl.browse;
  }

  ngOnDestroy() {
    if (this.fileResolverSubscription) {
      this.fileResolverSubscription.unsubscribe();
    }
  }

  onRemoveAttachment(index: number) {
    const files = this.files.filter((file, i) => i !== index);
    this.files = files;
    this.emitChanges();
  }

  onAddAttachment($event: Event) {
    const selectedFiles = Array.from(($event.target as HTMLInputElement).files as FileList);
    this.resolveFiles(selectedFiles);
  }

  onClickAttachment($event: PplAttachment) {
    this.fileClick.emit($event);
  }

  onAddClick() {
    if (!this.disableUploadClick) {
      this.fileInput.nativeElement.click();
    }
    this.addFilesClick.emit();
  }

  setDragOver($event) {
    if (this.readonly) {
      return;
    }
    this.dragOver = $event;
    this.changeDetectorRef.detectChanges();
  }

  onDrop($event: File[]) {
    if (this.readonly) {
      return;
    }
    this.resolveFiles($event);
  }

  private resolveFiles(files: File[]) {
    // process the files from user
    this.fileResolverSubscription = this.attachmentsService.processFiles({
      files,
      fileSizeLimit: this.fileSizeLimit,
      fileTypeLimit: this.fileTypeLimit,
      singleSelection: this.singleSelection,
      accept: this.accept,
      preserveOriginalFile: this.preserveOriginalFile
    }).subscribe(value => {
      const { files, unsupportedAttachments } = value;
      // 1. emit unprocessed/unsupported attachment info to the user
      if (isAttachmentUnsupportedInfo(unsupportedAttachments)) {
        this.unsupportedAttachment.emit(unsupportedAttachments);
      }

      if (files && !!files.length) {
        // 2. process the files
        this.files = getAttachments(
          this.files,
          files,
          this.singleSelection
        );
        this.emitChanges();
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  private emitChanges() {
    this.filesChange.emit(this.files);
  }
}
