export function mapObjectValues<T>(object: { [id: string]: T } | { [id: number]: T }, mapHandler: (value: T, key: string) => T) {
  const nextObject: { [id: string]: T } = {};

  Object.keys(object).forEach(key => {
    nextObject[key] = mapHandler(object[key], key);
  });

  return nextObject;
}

export function getFirstKey<T>(object: { [id: string]: T }) {
  return Object.keys(object)[0];
}

export function getSingleValue<T>(array: T[]) {
  if (array.length !== 0 && array.every((value) => value === array[0])) {
    return array[0];
  }

  return null;
}

export function truncateObject(object: any) {
  if (object) {
    Object.keys(object).forEach((key) => delete object[key]);
  }
}

export function retrieveByPath(rootObject: object, path: string) {
  return path.split('.').reduce((object, name) => object[name], rootObject) as any;
}

export function removeUndefinedKeys(object: any) {
  Object.keys(object).forEach(key => {
    if (object[key] === undefined) {
      delete object[key];
    }
  });

  return object;
}

export function removeEmptyKeys(object: any) {
  return Object.entries(object)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export function normalizeToObject<T extends object = any, K extends keyof T = any>(arr: T[], key: K = 'id' as any): { [id: string]: T } {
  const normalizedData = {};
  arr.forEach(item => {
    if (item[key]) {
      normalizedData[item[key] as any] = item;
    }
  });
  return normalizedData;
}

export function filterObject<T>(object: { [id: string]: T }, filterValue: (value: T, key: string) => boolean) {
  const nextObject: { [id: string]: T } = {};

  Object.keys(object).forEach(key => {
    if (filterValue(object[key], key)) {
      nextObject[key] = object[key];
    }
  });

  return nextObject;
}

export function createObject<T, K extends string | number, V>(arr: T[], keyValueGetter: (value: T) => [K, V]) {
  const obj: { [id in K]: V } = {} as { [id in K]: V };

  arr.forEach(item => {
    const [key, value] = keyValueGetter(item);
    obj[key] = value;
  });

  return obj;
}
