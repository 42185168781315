import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component
,
  ChangeDetectorRef} from '@angular/core';
import { PplDialogRef } from '@ppl/ui/dialog';

@Component({
  selector: 'ppl-unauthorized-dialog',
  templateUrl: './unauthorized-dialog.component.html',
  styleUrls: ['./unauthorized-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnauthorizedDialogComponent implements OnInit {
  errorMessage = 'Please click the button below to log back in and save your data.';

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: PplDialogRef<UnauthorizedDialogComponent>
  ) { }

  ngOnInit() {
  }

  reload() {
    this.errorMessage = 'Reloading, please wait';
    this.changeDetectorRef.detectChanges();
    this.dialogRef.close();
  }
}
