import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-editable-demo',
  templateUrl: './editable-demo.component.html',
  styleUrls: ['./editable-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditableDemoComponent implements OnInit {
  value = 'value';

  code = `
  <ppl-input-container label="Inline Edit">
    <ppl-editable [value]="value" (valueChange)="setValue($event)"></ppl-editable>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-editable [value]="value" disabled="true" (valueChange)="setValue($event)"></ppl-editable>
  </ppl-input-container>
  `;

  constructor() {
  }

  ngOnInit() {
  }

  setValue(value: string) {
    this.value = value;
  }

}
