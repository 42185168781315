import { CallingManageService } from '../../services/calling-manage.service';
import type {
  CallingPhoneGridRecord} from '../calling-phone-grid/calling-phone-grid.component';
import {
  CallingPhoneGridAction,
  CallingPhoneGridColumn,
  convertPhoneGridRecordToPhoneNumber
} from '../calling-phone-grid/calling-phone-grid.component';
import type {
  OnChanges,
  OnInit,
  TemplateRef
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import type { CommonGridColumn } from '@ppl/components/grid';
import { I18nService } from '@ppl/i18n';
import type { GridAction, GridSelection } from '@ppl/ui/grid';
import { getGridSelectedRecord } from '@ppl/ui/grid';
import type { ToolbarButtonOption } from '@ppl/ui/toolbar';
import type {
  TSimpleChanges} from '@ppl/utils';
import {
  DialogResult,
  unsubscribe,
  Unsubscribe,
  wrapAPICall
} from '@ppl/utils';
import type {
  Observable,
  Subscription
} from 'rxjs';
import {
  BehaviorSubject,
  combineLatest
} from 'rxjs';
import { first, map } from 'rxjs/operators';


@Component({
  selector: 'ppl-calling-phones',
  templateUrl: './calling-phones.component.html',
  styleUrls: ['./calling-phones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class CallingPhonesComponent implements OnChanges, OnInit {

  @Input() columns: CallingPhonesGridColumn[];
  @Input() addPhoneLabel = this.i18nService.translate('Add Phone Number');
  @Input() canAddPhones = false;
  @Input() purchasePhoneConfigEnabled?: boolean;
  @Input() gridActions: GridAction[];
  @Input() loading = false;
  @Input() customColumnTemplates?: { [id: string]: TemplateRef<any> };

  updateToolbarButtons$ = new BehaviorSubject<void>(null);
  fetching$ = new BehaviorSubject<boolean>(false);
  deleting$ = new BehaviorSubject<boolean>(false);

  gridSelection$ = new BehaviorSubject<GridSelection>({
    all: false,
    include: [],
    exclude: []
  });
  gridData$ = new BehaviorSubject<CallingPhoneGridRecord[]>(null);

  toolbarButtons$: Observable<ToolbarButtonOption[]>;

  purchasePhoneDialogSubscription: Subscription;

  get selectedRecord() {
    return getGridSelectedRecord(this.gridData$.value, this.gridSelection$.value);
  }

  constructor(
    private i18nService: I18nService,
    private manageCalling: CallingManageService<unknown, unknown, unknown>
  ) {
    this.toolbarButtons$ = combineLatest([
      this.fetching$,
      this.deleting$,
      this.gridData$,
      this.gridSelection$,
      this.updateToolbarButtons$.asObservable()
    ]).pipe(
      map(([fetching, deleting]) => {
        const isLoading = this.loading || fetching || deleting;
        const record = this.selectedRecord;
        const showEditButton = !!record && this.manageCalling.canEditRecord(record);
        const showDeleteButton = !!record && this.manageCalling.canDeleteRecord(record);

        const buttons: ToolbarButtonOption[] = [
          {
            id: CallingPhonesAction.Add,
            icon: 'color-add',
            text: this.addPhoneLabel,
            enabled: !isLoading,
            disableOnly: isLoading
          },
          {
            id: CallingPhonesAction.Edit,
            icon: 'color-edit',
            text: this.i18nService.translate('Edit'),
            enabled: showEditButton && !isLoading,
            disableOnly: showEditButton && isLoading
          },
          {
            id: CallingPhonesAction.Delete,
            icon: 'color-delete',
            text: this.i18nService.translate('Delete'),
            enabled: showDeleteButton && !isLoading,
            disableOnly: showDeleteButton && isLoading
          },
        ];

        return buttons;
      })
    );
  }

  ngOnChanges(changes: TSimpleChanges<CallingPhonesComponent>) {
    if (changes.loading && changes.loading.previousValue !== changes.loading.currentValue) {
      this.updateToolbarButtons$.next();
    }
  }

  ngOnInit() {
    this.fetchPhones();
  }

  onGridSelectionChange(selection: GridSelection) {
    this.gridSelection$.next(selection);
  }

  onGridActionClick(event: { record: CallingPhoneGridRecord, action: GridAction }) {
    switch (event.action.id) {
      case CallingPhoneGridAction.Delete:
        this.deletePhone(event.record.id);
        break;
      case CallingPhoneGridAction.Edit:
        this.editPhone(event.record);
        break;
    }
  }

  onToolbarButtonClick(button: ToolbarButtonOption) {
    const record = this.selectedRecord;
    switch (button.id) {
      case CallingPhonesAction.Add:
        unsubscribe(this.purchasePhoneDialogSubscription);
        this.purchasePhoneDialogSubscription = this.manageCalling.purchasePhoneDialog({
          canAddPhones: this.canAddPhones,
          configurationEnabled: this.purchasePhoneConfigEnabled
        }).subscribe(() => {
          this.fetchPhones();
        });
        break;
      case CallingPhonesAction.Edit:
        if (record) {
          this.editPhone(record);
        }
        break;
      case CallingPhonesAction.Delete:
        if (record) {
          this.deletePhone(record.id);
        }
        break;
    }
  }

  private fetchPhones() {
    wrapAPICall(
      this.manageCalling.fetchPhones(),
      this.fetching$
    ).subscribe(records => {
      this.gridData$.next(records);
    });
  }

  private deletePhone(phoneId: string) {
    wrapAPICall(
      this.manageCalling.deletePhone(phoneId),
      this.deleting$
    ).subscribe({
      next: () => {
        this.fetchPhones();
      }
    });
  }

  private editPhone(record: CallingPhoneGridRecord) {
    return this.manageCalling.editPhoneDialog(convertPhoneGridRecordToPhoneNumber(record))
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (response) => {
          if (response?.result === DialogResult.SUCCESS) {
            this.fetchPhones();
          }
        }
      });
  }
}

enum CallingPhonesAction {
  Add = 'Add',
  Edit = 'Edit',
  Delete = 'Delete'
}

export interface CallingPhonesGridColumn extends CommonGridColumn {
  id: CallingPhoneGridColumn;
}

export function createCommonPhoneGridColumns(i18nService: I18nService): CallingPhonesGridColumn[] {
  return [
    { id: CallingPhoneGridColumn.Name, name: i18nService.translate('Name') },
    { id: CallingPhoneGridColumn.PhoneNumber, name: i18nService.translate('Phone_Number') },
    { id: CallingPhoneGridColumn.Capability, name: i18nService.translate('Capability') },
    { id: CallingPhoneGridColumn.MessageForwardingEmail, name: i18nService.translate('Message_Forwarding_to_Email'), width: 2 },
    { id: CallingPhoneGridColumn.CallForwardingPhone, name: i18nService.translate('Call_Forwarding'), width: 2 }
  ];
}
