<div *ngIf="icon"
     class="icon">
  <ng-container *ngTemplateOutlet="icon"></ng-container>
</div>

<div *ngIf="title"
     class="title">
  {{title}}
</div>

<div *ngIf="content">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
