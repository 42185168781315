import { DecimalPipe } from '@angular/common';
import { AuthStore } from '../store/auth.state';
import { getUserSettingsLocale } from '../store/auth.selectors';
import type { OnDestroy, PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Subscription } from 'rxjs';
import { unsubscribe } from '@ppl/utils';

@Pipe({
  name: 'pplDecimal'
})
export class PplDecimalPipe implements PipeTransform, OnDestroy {
  locale: string;
  localeSubscription: Subscription;

  constructor(private decimalPipe: DecimalPipe, private store: AuthStore) {
    this.localeSubscription = this.store.select(getUserSettingsLocale(true)).subscribe(locale => this.locale = locale);
  }

  ngOnDestroy(): void {
    unsubscribe(this.localeSubscription);
  }

  transform(value: number, digits?: string) {
    return this.decimalPipe.transform(value, digits, this.locale);
  }
}
