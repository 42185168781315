<ppl-top-bar #topBar
             title="Pipeliner-ng-ui">
  <ppl-top-navigation screenName="Components' Showcase"></ppl-top-navigation>
</ppl-top-bar>

<ppl-app-container class="components-showcase-container">
  <router-outlet></router-outlet>
</ppl-app-container>

<ppl-bottom-bar #bottombar>
  <ng-container *ngFor="let navItem of navItems">
    <button *ngIf="navItem.children.length"
            [pplPopover]="submenu"
            pplPopoverAlignStart
            pplPopoverEvent="hover-preserve"
            pplPopoverActiveClass="active"
            pplBottomBarButton>{{navItem.name}}
    </button>
    <ng-template #submenu>
      <ppl-bottom-bar-submenu>
        <ppl-bottom-bar-submenu-button *ngFor="let child of navItem.children"
                                       [routerLink]="child.route"
                                       routerLinkActive="active">
          {{child.name}}
        </ppl-bottom-bar-submenu-button>
      </ppl-bottom-bar-submenu>
    </ng-template>
    <button *ngIf="!navItem.children.length"
            pplBottomBarButton
            [routerLink]="navItem.route"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
      {{navItem.name}}
    </button>
  </ng-container>
</ppl-bottom-bar>
