<div #pickerDate
     class="picker-date"></div>

<div *ngIf="displayTime"
     class="picker-time">
  <div class="time">
    <input #hoursInput
           pplInput
           class="time-input"
           autocomplete="off"
           [value]="hoursValue"
           (keyup)="onHoursKeyUp($event)"
           (blur)="onHoursBlur()" />
    <div class="colon">:</div>
    <input #minutesInput
           pplInput
           class="time-input"
           autocomplete="off"
           [value]="minutesValue"
           (keyup)="onMinutesKeyUp($event)"
           (blur)="onMinutesBlur()" />
    <ppl-select #periodSelect
                *ngIf="!is24HourSystem"
                [options]="timePeriods"
                [value]="selectedTimePeriod"
                (valueChange)="onTimePeriodChange($event)">
    </ppl-select>
  </div>

  <button type="button"
          *ngIf="displayPlanner"
          pplButton
          pplButton1
          pplButtonSmall
          pplPopoverClose
          tabindex="-1"
          (click)="onPlannerClick()">{{appointmentPlanner}}</button>
</div>

<div class="picker-actions">
  <button type="button"
          #submitButton
          *ngIf="displayTime"
          pplButton
          pplButton1
          pplButtonSmall
          pplButtonBold
          pplButtonFocusable
          pplPopoverClose
          (click)="onSubmitClick()"
          (keyup)="onSubmitKeyUp($event)">{{ok}}</button>
  <button type="button"
          *ngIf="!displayTime && displayToday"
          pplButton
          pplButton1
          pplButtonSmall
          pplButtonBold
          pplButtonFocusable
          pplPopoverClose
          (click)="todayClick.emit()">{{today}}</button>
  <button type="button"
          *ngIf="displayRemove"
          [class.full-width]="fullWidthButton"
          pplButton
          pplButton5
          pplButtonSmall
          pplButtonBold
          pplButtonFocusable
          pplPopoverClose
          (click)="onRemoveClick()">{{remove}}</button>
</div>

<div #icons
     style="display: none;">
  <ppl-icon class="prev-icon"
            svgIcon="rowfull-left"
            height="12px"
            [useSvg]="true"></ppl-icon>
  <ppl-icon class="next-icon"
            svgIcon="rowfull-right"
            height="12px"
            [useSvg]="true"></ppl-icon>
</div>
