import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplAutocompleteModule } from '../autocomplete';
import { PplAutocompletePickerComponent } from './autocomplete-picker.component';

@NgModule({
  imports: [
    CommonModule,
    PplAutocompleteModule
  ],
  declarations: [
    PplAutocompletePickerComponent
  ],
  exports: [
    PplAutocompletePickerComponent
  ]
})
export class PplAutocompletePickerModule {
}

export * from './autocomplete-picker.component';

