<div class="selected-marker"
     *ngIf="parentMenuComponent.markSelected">
  <ppl-icon *ngIf="parentMenuComponent.value === value"
            svgIcon="checkmark-toggle"
            width="14px"
            height="14px"></ppl-icon>
</div>
<div class="option-icon"
     *ngIf="parentMenuComponent.hasIcons">
  <ng-content select="ppl-icon"></ng-content>
</div>
<div class="content">
  <ng-content></ng-content>
</div>
<ng-content select="extra-content"></ng-content>
<div class="option-children"
     *ngIf="parentMenuComponent.hasChildren">
  <svg *ngIf="hasChildren"
       width="6px"
       height="12px">
    <path d="M0,0 l6,6 l-6,6 Z"
          fill="#03273f"></path>
  </svg>
</div>