import type { OnInit, OnChanges } from '@angular/core';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PplUiIntl } from '../../ppl-ui-intl';
import type { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'ppl-form-control-errors',
  templateUrl: './form-control-errors.component.html',
  styleUrls: ['./form-control-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplFormControlErrorsComponent implements OnInit, OnChanges {
  @Input() errors: ValidationErrors | null;
  @Input() customErrorMessages: { [key: string]: string };
  errorKeys = [];

  invalidEmailFormat = 'Invalid E-mail format!';
  invalidUrlFormat = 'Invalid Url format!';
  invalidSecureUrlFormat = 'Invalid URL format. Request URL must start with „https://“.';
  invalidOptionSelected = 'Invalid option selected!';
  ownerNotHaveAccessSalesUnit = 'Entity owner does not have an access to selected sales unit';
  userNotMemberOfSalesUnit = 'User is not a member of selected sales unit!';
  invalidMinimalValue = 'Invalid minimal value';
  invalidMinDate = 'Invalid date value, min date:';
  invalidMaxDate = 'Invalid date value, max date:';
  invalidValueRange = 'Invalid value, must be from range &lt;';
  invalidFieldValue = 'Invalid field value:';
  invalidRequiredPattern = 'Invalid value, required pattern:';
  invalintIntegerValue = 'Invalid integer value';
  invalidNumberValue = 'Invalid number value';
  minHasToBeSmallerThanMax = 'Min. value has to be smaller than max. value';
  atLeastOneSelected = 'At least one option must be selected!';
  invalidPassword = 'Invalid password: Password must contain at least one lower case character, one upper case character and one number character!';
  invalidLength = 'Invalid length. Max length is';
  invalidMinLength = 'Invalid length. Min length is';
  invalidCount = 'Invalid count. Max count is';
  invalidMinCount = 'Invalid count. Min count is';
  actualLength = 'actualLength';
  actualCount = 'actualCount';
  highlightedProductsAreNotAvailable = 'Highlighted products are not available on this pipeline anymore';
  productsServicesInvalidProducts = 'Highlighted products are invalid.';
  highlightedCurrencyIsNotAvailable = 'Highlighted currency is not available anymore';
  highlightedPriceListIsNotAvailable = 'Highlighted pricelist is not available anymore';
  dayIsNotSelected = 'Day is not selected';
  itemUnavailable = 'Item not available';
  thisFieldIsRequired = 'This field is required';
  firstOrLastNameRequired = 'First or last name is required';
  invalidHeaderKey = 'Header value incorrect: only ascii characters and no spaces allowed!';
  invalidHeaderValue = 'Header value incorrect: only ascii characters allowed!';
  duplicateHeaders = 'Duplicate headers not allowed!';
  invalidRollupNesting = 'Field cannot be selected, max two levels of rollup fields can be aggregated';
  requiredDependentEntity = 'Contains dependencies that need to be published';
  invalidPageLinkFormat = 'Invalid format. Please use lowercase only "a-z", "0-9", "-", "_".';
  takenPageLink = 'This Link is already taken';
  processInfiniteLoop = 'Selected process might create an endless loop. Check conditions in that process or select other one.';
  processDeleted = 'This process is deleted';
  filesUploading = 'Upload in progress';

  constructor(private intl: PplUiIntl) {
    this.invalidEmailFormat = this.intl.invalidEmailFormatError;
    this.invalidUrlFormat = this.intl.invalidUrlFormatError;
    this.invalidSecureUrlFormat = this.intl.invalidSecureUrlFormatError;
    this.invalidOptionSelected = this.intl.invalidOptionSelectedError;
    this.ownerNotHaveAccessSalesUnit = this.intl.ownerNotHaveAccessSalesUnitError;
    this.userNotMemberOfSalesUnit = this.intl.userNotMemberOfSalesUnitError;
    this.invalidMinimalValue = this.intl.invalidMinimalValueError;
    this.invalidMinDate = this.intl.invalidMinDateError;
    this.invalidMaxDate = this.intl.invalidMaxDateError;
    this.invalidValueRange = this.intl.invalidValueRangeError;
    this.invalidFieldValue = this.intl.invalidFieldValueError;
    this.invalidRequiredPattern = this.intl.invalidRequiredPatternError;
    this.invalintIntegerValue = this.intl.invalintIntegerValueError;
    this.invalidNumberValue = this.intl.invalidNumberValueError;
    this.minHasToBeSmallerThanMax = this.intl.minHasToBeSmallerThanMaxError;
    this.atLeastOneSelected = this.intl.atLeastOneSelectedError;
    this.invalidPassword = this.intl.invalidPasswordError;
    this.invalidLength = this.intl.invalidLengthError;
    this.invalidMinLength = this.intl.invalidMinLengthError;
    this.actualLength = this.intl.actualLength;
    this.invalidCount = this.intl.invalidCount;
    this.invalidMinCount = this.intl.invalidMinCount;
    this.actualCount = this.intl.actualCount;
    this.highlightedProductsAreNotAvailable = this.intl.highlightedProductsAreNotAvailableError;
    this.productsServicesInvalidProducts = this.intl.productsServicesInvalidProductsError;
    this.highlightedCurrencyIsNotAvailable = this.intl.highlightedCurrencyIsNotAvailableError;
    this.highlightedPriceListIsNotAvailable = this.intl.highlightedPriceListIsNotAvailableError;
    this.dayIsNotSelected = this.intl.dayIsNotSelectedError;
    this.itemUnavailable = this.intl.itemUnavailableError;
    this.thisFieldIsRequired = this.intl.thisFieldIsRequiredError;
    this.firstOrLastNameRequired = this.intl.firstOrLastNameRequiredError;
    this.invalidHeaderKey = this.intl.invalidHeaderKeyError;
    this.invalidHeaderValue = this.intl.invalidHeaderValueError;
    this.duplicateHeaders = this.intl.duplicateHeadersError;
    this.invalidRollupNesting = this.intl.invalidRollupNestingError;
    this.requiredDependentEntity = this.intl.requiredDependentEntityError;
  }

  ngOnChanges() {
    if (this.errors) {
      this.errorKeys = Object.keys(this.errors);
    } else {
      this.errorKeys = [];
    }
  }

  ngOnInit() {
  }

}
