<div class="label">
  <label *ngIf="label"
         class="ppl-label"
         [class.is-required]="hasRequiredClass"
         [class.is-bold]="bold || hasChanges"
         [class.disabled]="disabled">{{label}}</label>

  <label *ngIf="labelContent"
         class="ppl-label">
    <ng-container *ngTemplateOutlet="labelContent"></ng-container>
  </label>

  <ppl-icon *ngIf="description"
            pplPopover
            [pplPopoverTooltip]="description"
            pplPopoverTooltipDirection="up"
            pplPopoverTooltipPreserveWhitespace="true"
            [pplPopoverTooltipHoverPreserve]="descriptionHoverPreserve"
            class="description"
            width="12px"
            height="12px"
            svgIcon="info-small-blue"></ppl-icon>

  <ppl-icon *ngIf="hasChanges"
            pplPopover
            pplPopoverTooltip="Undo Changes"
            i18n-pplPopoverTooltip="@@UndoChanges"
            pplPopoverTooltipDirection="up"
            class="form-changes"
            width="14px"
            height="14px"
            svgIcon="undo-action-darkblue"
            (click)="$event.stopPropagation(); undoChanges.emit()"></ppl-icon>

  <div *ngIf="actionTemplate"
       class="label-action">
    <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
  </div>
</div>
<ng-content></ng-content>
<ppl-form-control-errors *ngIf="showErrors && hasErrorClass"
                         [errors]="control ? control.errors : errors"
                         [customErrorMessages]="customErrorMessages"></ppl-form-control-errors>
<span class="notice"
      *ngIf="notice || noticeContent">
  <ng-container *ngIf="notice">
    {{notice}}
  </ng-container>
  <ng-container *ngIf="noticeContent && !notice">
    <ng-template [ngTemplateOutlet]="noticeContent"></ng-template>
  </ng-container>
</span>
