import type { PhoneNumber } from '../../domain/phone-number';
import type { CallingPhoneEditFormData } from '../calling-phone-edit/calling-phone-edit.component';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { convertPhoneNumberToInputPhoneNumber } from '@ppl/components/input-phone-number';
import { PplDialogRef } from '@ppl/ui/dialog';
import { PPL_DIALOG_DATA } from '@ppl/ui/dialog';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type {
  DialogResponse} from '@ppl/utils';
import {
  createDialogCloseResponse,
  createDialogSuccessResponse,
  wrapAPICall
} from '@ppl/utils';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'ppl-calling-phone-edit-dialog',
  templateUrl: './calling-phone-edit-dialog.component.html',
  styleUrls: ['./calling-phone-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallingPhoneEditDialogComponent {

  updating$ = new BehaviorSubject<boolean>(false);

  form: TFormGroup<CallingPhoneEditDialogFormData>;

  get phone() {
    return this.data.phone.phoneNumber;
  }

  get displayForwardingSettings() {
    return this.data.displayForwardingSettings;
  }

  constructor(
    private dialogRef: PplDialogRef<CallingPhoneEditDialogComponent, DialogResponse<CallingPhoneEditDialogResponse>>,
    @Inject(PPL_DIALOG_DATA) private data: CallingPhoneEditDialogData,
    private fb: TFormBuilder
  ) {
    const { name, messageForwardingEmail, callForwardingPhone } = this.data.phone;
    this.form = this.fb.group<CallingPhoneEditDialogFormData>({
      phone: [{
        name,
        messageForwardingEmail,
        callForwardingPhone: convertPhoneNumberToInputPhoneNumber(callForwardingPhone)
      }]
    });
  }

  onSave() {
    wrapAPICall(
      this.data.save(this.form.value.phone),
      this.updating$
    ).subscribe((result) => {
      this.dialogRef.close(createDialogSuccessResponse({ phone: result }));
    });
  }

  onCancel() {
    this.dialogRef.close(createDialogCloseResponse());
  }
}

interface CallingPhoneEditDialogFormData {
  phone: CallingPhoneEditFormData;
}

export interface CallingPhoneEditDialogData {
  phone: PhoneNumber;
  displayForwardingSettings: boolean;
  save: (value: CallingPhoneEditFormData) => Observable<PhoneNumber>;
}

export interface CallingPhoneEditDialogResponse {
  phone: PhoneNumber;
}

