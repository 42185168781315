<div class="options-list">
  <ppl-search *ngIf="displaySearch"
              [value]="''"
              [focusOnMount]="autoFocusSearchInput"
              (valueChange)="onSearch($event)"
              (keyEscapePressed)="keyEscapePressed.emit($event)"></ppl-search>
  <ng-content select="contentAfterSearch"></ng-content>
  <ppl-option-list [categories]="categories"
                   [displayCategoriesSidebar]="displayCategoriesSidebar"
                   [options]="filteredOptions"
                   [value]="autoSelectValue"
                   [optionTemplate]="optionTemplate || defaultOptionTemplate"
                   [displayNoResults]="displayNoResults"
                   [maxContainerHeight]="maxContainerHeight"
                   [optionTemplateRowHeight]="optionTemplateRowHeight"
                   [categoriesSidebarWidth]="categoriesSidebarWidth"
                   (optionSelect)="onOptionSelect($event)"
                   (valueChange)="onValueChange($event)"></ppl-option-list>
</div>

<ng-template #defaultOptionTemplate
             let-option>
  <div class="option">
    <div class="option-label">
      {{option.label}}
    </div>
  </div>
</ng-template>
