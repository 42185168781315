import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';

@Component({
  selector: 'ppl-infinite-scroll-demo',
  templateUrl: './infinite-scroll-demo.component.html',
  styleUrls: ['./infinite-scroll-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteScrollDemoComponent implements OnInit {

  data = Array.from({ length: 50 }, (v, k) => k);

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onLoadMore() {
    const fromIndex = this.data.length;
    setTimeout(() => {
      this.data = [...this.data, ...Array.from({ length: 50 }, (v, k) => k + fromIndex)];
      this.changeDetectorRef.detectChanges();
    }, 1000);
  }
}
