import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { Step } from '@ppl/components/steps';

@Component({
  selector: 'ppl-steps-demo',
  templateUrl: './steps-demo.component.html',
  styleUrls: ['./steps-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepsDemoComponent implements OnInit {

  activeStepIndex = 0;
  steps: Step[] = [
    { name: 'First' },
    { name: 'Second' },
    { name: 'Third' }
  ];

  code = `
    <ppl-steps [activeStepIndex]="activeStepIndex"
               [steps]="steps"></ppl-steps>
  `;

  constructor() { }

  ngOnInit() {
  }

  onNext() {
    this.activeStepIndex = (this.activeStepIndex + 1) % this.steps.length;
  }

}
