import type { PplFormTableItem, PplFormTableItemTree } from '../form-table';
import { PplPopoverDirective } from '../popover';
import { PplSearchComponent } from '../search';
import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  createTrees,
  filterTree,
  flattenTree,
  FormValueControl,
  KEYEVENTCODE_ENTER,
  KEYEVENTCODE_TAB,
  MemoizeLast
} from '@ppl/utils';
import type { PplTreeSelectItem } from './tree-select-item/tree-select-item.component';
import { sortByKey } from '../../../../utils/src/lib/array.utils';

@Component({
  selector: 'ppl-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeSelectComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class TreeSelectComponent {

  @Input() value: PplTreeSelectValue | string;
  @Input() options: PplTreeSelectOption[];
  @Input() placeholder?: string;
  @Input() disabled = false;
  @Input() displayValueLoading = false;
  @Input() displayAll = true;
  @Input() includeSubcategoriesLabel?: string;
  @Input() selectionMode: 'single' | 'multiple' = 'multiple';
  @Input() collapsible = true;
  @Input() filterUnavailableOptions = true;
  @Input() hideUnavailableLabels: boolean;
  @Input() showChildrenOnMatch = false;

  @Output() valueChange = new EventEmitter<PplTreeSelectValue | string>();

  @ViewChild(PplPopoverDirective, { static: true }) treePopover: PplPopoverDirective;
  @ViewChild('selectedValues', { static: false }) selectedValues: ElementRef;
  @ViewChild('search', { static: false }) search: PplSearchComponent;
  @ViewChild('dropdown', { static: true, read: ElementRef }) dropdown: ElementRef;
  @ViewChild('treeContainer', { static: false, read: ElementRef }) treeContainer: ElementRef;

  filter = '';
  includeSubcategories = true;
  focused = false;
  dropdownAlignStart = false;
  dropdownAlignEnd = false;
  dropdownMinWidth = 0;
  dropdownMaxWidth = 0;

  @MemoizeLast<TreeSelectComponent>(['options'])
  get allOptionsValue() {
    return this.options.map(option => option.value);
  }

  @MemoizeLast<TreeSelectComponent>(['value', 'options'])
  get selectedOptions() {
    if ((this.value as PplTreeSelectMultipleValue).all) {
      return this.options;
    }

    const values = (this.value as PplTreeSelectMultipleValue).selectedValues;
    const options = [];

    if (values) {
      values.forEach(value => {
        const match = this.options.find(option => option.value === value);
        if (match) {
          options.push(match);
        }
      });
    }

    return options;
  }

  @MemoizeLast<TreeSelectComponent>(['value', 'options'])
  get selectedOptionValues() {
    return this.selectedOptions.map(option => option.value);
  }

  @MemoizeLast<TreeSelectComponent>(['value', 'options'])
  get selectedOption() {
    return this.options?.find(option => option.value === this.value);
  }

  @MemoizeLast<TreeSelectComponent>(['value', 'options'])
  get selectedLabels() {
    const selectedOptions = this.selectedOptions;
    return selectedOptions.map(selectedOption => selectedOption.label).join(', ');
  }

  @MemoizeLast<TreeSelectComponent>(['filter', 'value', 'options'])
  get availableOptions() {
    let selectedValues: string[];

    if (this.selectionMode === 'multiple') {
      selectedValues = (this.value as PplTreeSelectMultipleValue).all ? this.allOptionsValue : (this.value as PplTreeSelectMultipleValue).selectedValues;
    } else {
      selectedValues = [this.value as string];
    }

    const options = createTrees(transformOptions(this.options, this.selectionMode === 'multiple' ? selectedValues : [this.selectedOption?.value]), createTreeFn);

    return options.filter(option => {
      const filterTreeCondition = (node: any) => {
        return (!node.deleted || selectedValues.includes(node.id))
          && (!this.filterUnavailableOptions || !node.unavailable)
          && (!this.filter || node.name.toLowerCase().includes(this.filter.toLowerCase()));
      };

      if (this.filter && this.showChildrenOnMatch) {
        return customFilterTree(option, filterTreeCondition);
      }

      return filterTree(option, filterTreeCondition);
    });
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const dropdownContains = this.dropdown.nativeElement.contains(event.target);
    const treeContains = this.treeContainer?.nativeElement.contains(event.target);
    if (!dropdownContains && !treeContains) {
      this.closePopover();
      this.focused = false;
    }
  }

  onFocus() {
    if (!this.disabled) {
      this.focused = true;
    }
  }

  onBlur() {
    if (this.focused && !this.treePopover.isOpen) {
      this.focused = false;
    }
  }

  onInputMouseDown() {
    const dropdown = this.dropdown.nativeElement;
    const dropdownRect = dropdown.getBoundingClientRect();

    this.dropdownMinWidth = dropdownRect.width + 2; // +2px due to popover-content "fix" for floating point issues in Chrome
    this.dropdownMaxWidth = this.dropdownMinWidth * 2;

    if (dropdownRect.left < window.innerHeight / 2) {
      this.dropdownAlignEnd = false;
      this.dropdownAlignStart = true;
    } else if (dropdownRect.right > window.innerHeight / 2) {
      this.dropdownAlignEnd = true;
      this.dropdownAlignStart = false;
    }
  }

  onContainerClick() {
    if (!this.disabled && !this.displayValueLoading) {
      if (this.treePopover.isOpen) {
        this.closePopover();
      } else if (!this.treePopover.isOpen) {
        this.openPopover();
      }
      this.selectedValues.nativeElement.focus();
    }
  }

  onContainerKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case KEYEVENTCODE_ENTER:
        if (!this.treePopover.isOpen) {
          event.stopPropagation();
          this.openPopover();
        }
        break;
      case KEYEVENTCODE_TAB:
        if (this.treePopover.isOpen) {
          this.closePopover();
          this.selectedValues.nativeElement.focus();
        }
        break;
    }
  }

  onSearchClick(event: MouseEvent) {
    event.stopPropagation();
    setTimeout(() => {
      this.search.searchInput.nativeElement.focus();
    });
  }

  onSearchValueChange(filter: string) {
    this.filter = filter;
  }

  onSelectAll(checked: boolean) {
    if (checked) {
      this.valueChange.emit({
        selectedValues: this.allOptionsValue,
        all: checked
      });
    } else {
      this.valueChange.emit({
        selectedValues: [],
        all: false
      });
    }
  }

  onTreeToggle(option: PplFormTableItemTree) {
    let selectedValues;
    if (this.selectedOptionValues.includes(option.id)) {
      if (this.includeSubcategories) {
        const flattenedOption = flattenTree(option).map(tree => tree.id);
        selectedValues = this.selectedOptionValues.filter(selectedValue => !flattenedOption.includes(selectedValue));
      } else {
        selectedValues = this.selectedOptionValues.filter(selectedValue => selectedValue !== option.id);
      }
    } else {
      let newValues: string[];
      if (this.includeSubcategories) {
        newValues = this.addToSelected(option);
      } else {
        newValues = [option.id];
      }
      selectedValues = removeDuplicates([...this.selectedOptionValues, ...newValues]);
    }
    const all = selectedValues.length === this.options.length;

    this.valueChange.emit({ all, selectedValues });
  }

  onTreeValueChange(option: PplTreeSelectItem) {
    if (!option.unavailable) {
      this.treePopover.hide();
      this.valueChange.emit(option.id);
    }
  }

  onTreeContainerKeydown(event: KeyboardEvent) {
    switch (event.code) {
      case KEYEVENTCODE_TAB:
        if (this.treePopover.isOpen) {
          this.closePopover();
          setTimeout(() => {
            this.selectedValues.nativeElement.focus();
          });
        }
        break;
    }
  }

  trackTree(index, item: PplFormTableItemTree) {
    return item.id;
  }

  private openPopover() {
    if (!this.disabled) {
      this.filter = '';
      this.treePopover.show();
    }
  }

  private closePopover() {
    this.treePopover.hide();
    this.filter = '';
  }

  private addToSelected(option: PplFormTableItemTree) {
    return flattenTree(option).map(flattenOption => flattenOption.id);
  }

}

export type PplTreeSelectValue = string | PplTreeSelectMultipleValue;

export interface PplTreeSelectMultipleValue {
  all: boolean;
  selectedValues: string[];
}

export interface PplTreeSelectOption extends Pick<PplTreeSelectItem, 'avatarEntity' | 'picture'> {
  label: string;
  value: string;
  parentId: string | null;
  disabled?: boolean;
  deleted?: boolean;
  unavailable?: boolean;
}

function transformOptions(options: PplTreeSelectOption[], selectedValues: string[]): PplFormTableItem[] {
  return options.map(option => {
    const { label, value, parentId, disabled, avatarEntity, picture, unavailable, deleted } = option;
    return {
      id: value,
      name: label,
      parentId,
      disabled,
      checked: selectedValues.includes(value),
      avatarEntity,
      picture,
      unavailable,
      deleted
    };
  }).sort(sortByKey('name'));
}

function removeDuplicates(arr: string[]) {
  const elements = new Set();

  return arr.filter(el => {
    const duplicate = elements.has(el);
    elements.add(el);
    return !duplicate;
  });
}

function createTreeFn(item: any): any {
  const { id, name, checked, disabled, parentId, avatarEntity, picture, unavailable, deleted } = item;
  return {
    id, name, checked, disabled, parentId, avatarEntity, picture, unavailable, deleted, children: []
  };
}

export function customFilterTree(node, condition: (node) => boolean, parentVisible = false) {
  let found = false;
  let childrenFound = false;
  if (condition(node)) {
    found = true;
  }

  if (node.children.length && !parentVisible) {
    [...node.children].forEach(childNode => {
      let childFound = false;
      if (customFilterTree(childNode, condition, found)) {
        childFound = true;
      }
      if (!childFound && !found) {
        node.children.splice(node.children.indexOf(childNode), 1);
      }
      if (!childrenFound) {
        childrenFound = childFound;
      }
    });
  }

  return found || childrenFound;
}
