import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-account-class-demo',
  templateUrl: './account-class-demo.component.html',
  styleUrls: ['./account-class-demo.component.scss']
})
export class AccountClassDemoComponent implements OnInit {
  value = 'NoClass';

  code = `
  <h4>Normal</h4>
  <ppl-input-container>
    <ppl-account-class [value]="value" (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>

  <br>

  <h4>One Value</h4>
  <ppl-input-container>
    <ppl-account-class [showOne]="true" [value]="value" (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>

  <br>

  <h5>Disabled</h5>
  <ppl-input-container>
    <ppl-account-class [value]="value" [disabled]=true (valueChange)="value = $event"></ppl-account-class>
  </ppl-input-container>
  `;

  constructor() {}

  ngOnInit() {}
}
