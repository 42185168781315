<ppl-drawer #drawer
            [position]="position"
            [resizable]="true"
            [open]="drawerOpen"
            [disabled]="false"
            [detailSize]="detailSize"
            [sizeConstraints]="constraints"
            [detail]="detail"
            (openChange)="drawerOpen = $event"
            (sizeChange)="detailSize = $event">
  <div class="content">
    <h2>Drawer</h2>
    <br>
    <h3>Components</h3>
    <h4>ppl-drawer</h4>
    <div>@Input() <b>open</b>: boolean <i>(default true)</i></div>
    <div>@Input() <b>disabled</b>: boolean <i>(default false)</i></div>
    <div>@Input() <b>detail</b>: TemplateRef&lt;and&gt;</div>
    <div>@Input() <b>detailSizeLocalStorageKey</b>: string</div>
    <div>@Input() <b>position</b>: PplPosition</div>
    <div>@Input() <b>resizable</b>: boolean</div>
    <div>@Input() <b>deatilSize</b>: number</div>
    <div>@Input() <b>sizeConstraints</b>: PplResizableSizeConstraints</div>
    <div>@Input() <b>minWindowWidth</b>: number</div>
    <br>
    <div>@Output() <b>openChange</b>: boolean</div>
    <div>@Output() <b>sizeChange</b>: number</div>
    <br>
    <br>

    <h4>Playground</h4>
    <ppl-input-container label="position"
                         style="width: 150px; margin: auto;">
      <ppl-select [value]="position"
                  [options]="positionOptions"
                  (valueChange)="position = $event"></ppl-select>
    </ppl-input-container>
  </div>
</ppl-drawer>

<ng-template #detail>
  <h3>sidebar detail</h3>
</ng-template>
