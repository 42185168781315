export enum PhoneNumberCapability {
  Voice = 'Voice',
  SMS = 'SMS'
}

export enum CallingPhoneTypeEnum {
  Private = 'Private',
  Company = 'Company',
  Personal = 'Personal',
  Unassigned = 'Unassigned'
}

export interface PhoneNumber {
  id: string;
  capability: PhoneNumberCapability[];
  name: string;
  phoneNumber: string;
  messageForwardingEmail: string | null;
  callForwardingPhone: string | null;
  type: CallingPhoneTypeEnum;
}

export interface AvailablePhoneNumber {
  name: string;
  phoneNumber: string;
  price: number;
  region: string | null;
  locality: string | null;
}

export interface AvailablePhoneNumberFilter {
  // ISO-3166-1 country code.
  countryCode: string;
  text?: string;
}

export interface PurchasePhoneInput {
  phoneNumber: string;
  name: string;
  forwardMessagesToEmail: string | null;
  forwardCallsToPhone: string | null;
  type?: string | null;
}

export interface EditPhoneInput {
  id: string;
  name: string;
  forwardMessagesToEmail: string | null;
  forwardCallsToPhone: string | null;
}
