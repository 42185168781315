import { ButtonType } from '../../button';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../../tokens';
import { PplDialogRef } from '../dialog-ref';
import type {
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
,
  ElementRef,
  TemplateRef
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDialogComponent implements OnChanges {

  @Input() actionLabel: 'save' | 'create' | string = 'save';
  @Input() actionVisible = true;
  @Input() actionDisabled = false;
  @Input() actionTooltip?: string;
  @Input() actionsVisible = true;
  @Input() actionButtonType = ButtonType.Button2;
  @Input() cancelLabel: 'cancel' | 'close' = 'cancel';
  @Input() cancelVisible = true;
  @Input() cancelDisabled = false;
  @Input() helpLink?: string;
  @Input() formGroup?: UntypedFormGroup;
  @Input() formGroupNoPadding = false;
  @Input() height?: string;
  @Input() loading = false;
  @Input() maxViewportWidth = this.getMaxViewportSize().width;
  @Input() maxViewportHeight = this.getMaxViewportSize().height;
  @Input() minHeight?: string;
  @Input() newBadge = false;
  @Input() title: string;
  @Input() width?: string;

  @Output() action = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
  @ContentChild('actionsMisc', { static: true }) actionsMiscTemplate: TemplateRef<any>;
  @ContentChild('content', { static: true }) contentTemplate: TemplateRef<any>;

  @MemoizeLast(['minHeight'])
  get minHeightValue() {
    return this.minHeight;
  }

  @MemoizeLast(['width'])
  get maxWidthValue() {
    return (this.width && this.width.endsWith('vw')) ? this.width : this.maxViewportWidth;
  }

  @MemoizeLast(['height'])
  get maxHeightValue() {
    return (this.height && this.height.endsWith('vh')) ? this.height : this.maxViewportHeight;
  }

  @MemoizeLast(['width'])
  get widthValue() {
    return this.width;
  }

  @MemoizeLast(['height'])
  get heightValue() {
    return this.height;
  }

  constructor(
    // dialogRef has to be optional for template use-cases (like Activity Detail)
    @Optional() private dialogRef: PplDialogRef,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES,
    private elementRef: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title) {
      this.elementRef.nativeElement.title = '';
    }
  }

  getMaxViewportSize() {
    if (this.ngUiTheme === 'fabric' || this.ngUiTheme === 'material') {
      return { width: '95vw', height: '85vh' };
    }

    return { width: '80vw', height: '65vh' };
  }

  onSubmit() {
    this.action.emit();
  }

  onCancel() {
    if (this.cancel.observers.length !== 0) {
      this.cancel.emit();
    } else {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    }
  }

}
