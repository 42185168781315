import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CallingConversationItemDirectionEnum, CallingConversationItemStateEnum } from '@ppl/calling-shared';

@Component({
  selector: 'ppl-calling-conversation-item-demo',
  templateUrl: './calling-conversation-item-demo.component.html',
  styleUrls: ['./calling-conversation-item-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallingConversationItemDemoComponent implements OnInit {

  code = `
    <ppl-calling-conversation-item [direction]="CallingConversationItemDirectionEnum.Outgoing"
                                   [data]="data"></ppl-calling-conversation-item>
  `;

  dataOutgoing = {
    message: 'How am I ever going to explain this? Learn about the Force, Luke. Look, I can take you as far as Anchorhead.You can get a transport there to Mos Eisley or wherever you\'re going.You must do what you feel is right, of course.',
    senderName: 'Ben Kenobi',
    senderPicture: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimages4.wikia.nocookie.net%2F__cb20110307195135%2Fstarwars%2Fimages%2F0%2F0b%2FBen_Kenobi.jpg&f=1&nofb=1',
    datetimeSent: new Date().toISOString(),
    direction: CallingConversationItemDirectionEnum.Outgoing,
    state: CallingConversationItemStateEnum.Sent
  };
  dataIncoming = {
    message: 'I am See-Threepio, human-cyborg relations, and this is my counterpart, Artoo-Detoo.',
    senderName: 'Ben Kenobi',
    senderPicture: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimages4.wikia.nocookie.net%2F__cb20110307195135%2Fstarwars%2Fimages%2F0%2F0b%2FBen_Kenobi.jpg&f=1&nofb=1',
    datetimeSent: new Date().toISOString(),
    direction: CallingConversationItemDirectionEnum.Incoming,
    state: CallingConversationItemStateEnum.Sent
  };
  dataOutgoingError = {
    message: 'How am I ever going to explain this? Learn about the Force, Luke. Look, I can take you as far as Anchorhead.You can get a transport there to Mos Eisley or wherever you\'re going.You must do what you feel is right, of course.',
    senderName: 'Ben Kenobi',
    senderPicture: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimages4.wikia.nocookie.net%2F__cb20110307195135%2Fstarwars%2Fimages%2F0%2F0b%2FBen_Kenobi.jpg&f=1&nofb=1',
    datetimeSent: new Date().toISOString(),
    errorMessage: 'Not Sent',
    direction: CallingConversationItemDirectionEnum.Outgoing,
    state: CallingConversationItemStateEnum.Error
  };
  dataIncomingError = {
    message: 'I am See-Threepio, human-cyborg relations, and this is my counterpart, Artoo-Detoo.',
    senderName: 'Ben Kenobi',
    senderPicture: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimages4.wikia.nocookie.net%2F__cb20110307195135%2Fstarwars%2Fimages%2F0%2F0b%2FBen_Kenobi.jpg&f=1&nofb=1',
    datetimeSent: new Date().toISOString(),
    errorMessage: 'Not Delivered',
    direction: CallingConversationItemDirectionEnum.Incoming,
    state: CallingConversationItemStateEnum.Error
  };
  dataIncomingSending = {
    message: 'OK',
    senderName: 'Ben Kenobi',
    senderPicture: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimages4.wikia.nocookie.net%2F__cb20110307195135%2Fstarwars%2Fimages%2F0%2F0b%2FBen_Kenobi.jpg&f=1&nofb=1',
    datetimeSent: new Date().toISOString(),
    direction: CallingConversationItemDirectionEnum.Incoming,
    state: CallingConversationItemStateEnum.Sending
  };

  dataOutgoingSending = {
    message: 'OK',
    senderName: 'Ben Kenobi',
    senderPicture: 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fimages4.wikia.nocookie.net%2F__cb20110307195135%2Fstarwars%2Fimages%2F0%2F0b%2FBen_Kenobi.jpg&f=1&nofb=1',
    datetimeSent: new Date().toISOString(),
    direction: CallingConversationItemDirectionEnum.Outgoing,
    state: CallingConversationItemStateEnum.Sending
  };

  CallingConversationItemDirectionEnum = CallingConversationItemDirectionEnum;

  constructor() { }

  ngOnInit(): void {
  }

}
