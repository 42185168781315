<div class="container demo-content">
  <h2>Toggle List</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-toggle-list</h4>
  <div>@Input() <b>value</b>: string</div>
  <div>@Input() <b>header?</b>: string</div>
  <div>@Input() <b>options</b>: PplToggleListOptions[]</div>

  <br>
  <br>
  <div>@Output() <b>valueChange</b>: string</div>
  <br>
  <br>

  <h4>Example</h4>
    <ppl-toggle-list [value]="value"
                     [header]="'Example'"
                     [options]="options"
                     (valueChange)="value = $event">

    </ppl-toggle-list>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>