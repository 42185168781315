import { PplAttachmentsComponent } from './attachments.component';
import { PplAttachmentsService } from './attachments.service';
import { PplBadgeCountModule } from '../badge-count';
import { PplButtonModule } from '../button';
import { PplDropZoneModule } from '../drop-zone';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplBadgeCountModule,
    PplButtonModule,
    PplDropZoneModule,
    PplInputModule
  ],
  declarations: [
    PplAttachmentsComponent
  ],
  exports: [
    PplAttachmentsComponent
  ],
  providers: [
    PplAttachmentsService
  ]
})
export class PplAttachmentsModule { }

export * from './attachments.component';
export * from './attachments.service';
export * from './attachments.utils';
export * from './model';

