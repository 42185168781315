<div class="selector">
  <ppl-radio-button [checked]="allChecked"
                    [disabled]="disabled"
                    (change)="setAll()">
    {{labelAll}}
  </ppl-radio-button>
  <ppl-radio-button [checked]="selectedChecked"
                    [disabled]="disabled"
                    (change)="setSelected()">
    {{labelSelected}}
  </ppl-radio-button>
</div>

<div *ngIf="showOptions"
     class="options"
     [class.checkboxes]="optionsRendering === 'checkboxes'"
     [class.multi-select]="optionsRendering === 'multi-select'">
  <ng-container [ngSwitch]="optionsRendering">
    <ng-container *ngSwitchCase="'checkboxes'">
      <ppl-checkbox *ngFor="let option of options; trackBy: trackOptions;"
                    [value]="optionChecked(option.value)"
                    [disabled]="disabled"
                    (valueChange)="onOptionChecked(option.value)">
        <span *ngIf="option.color !== undefined"
              class="option-color"
              [style.backgroundColor]="getColorValue(option.color)"></span>
        <span class="option-label"
              [attr.title]="option.label">
          {{option.label}}
        </span>
      </ppl-checkbox>
    </ng-container>
    <ng-container *ngSwitchCase="'multi-select'">
      <ppl-multiple-select [options]="options"
                           [value]="selectedValues"
                           [displayValueLoading]="displayLoading"
                           [displayOptionsLoading]="displayLoading"
                           (valueChange)="onSelectedValuesChange($event)"></ppl-multiple-select>
    </ng-container>
  </ng-container>
</div>
