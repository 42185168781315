import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { PplTextDropdownOption } from '@ppl/ui/text-dropdown';

@Component({
  selector: 'ppl-text-dropdown-demo',
  templateUrl: './text-dropdown-demo.component.html',
  styleUrls: ['./text-dropdown-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextDropdownDemoComponent implements OnInit {

  value = 'All templates';
  options: PplTextDropdownOption[] = [
    { label: 'All templates', value: 'All templates' },
    { label: 'Created by me', value: 'Created by me' },
    { label: 'Text templates only', value: 'Text templates only' },
    { label: 'HTML templates only', value: 'HTML templates only' }
  ];

  code = `
  <ppl-text-dropdown [value]="value"
                     [options]="options"
                     (valueChange)="value = $event"></ppl-text-dropdown>
  `;

  constructor() { }

  ngOnInit() {
  }

}
