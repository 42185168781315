import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { InputPhoneNumber} from '@ppl/components/input-phone-number';
import { inputPhoneNumberRequiredValidator, inputPhoneNumberValidator } from '@ppl/components/input-phone-number';
import { I18nService } from '@ppl/i18n';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';

@Component({
  selector: 'ppl-input-phone-number-demo',
  templateUrl: './input-phone-number-demo.component.html',
  styleUrls: ['./input-phone-number-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputPhoneNumberDemoComponent implements OnInit {
  phoneNumber: InputPhoneNumber = {
    country: null,
    number: '0944132655'
  };

  code = `
    <div [formGroup]="form">
      <ppl-input-container label="Form phone number"
                           [pplFormControl]="form.get('phoneNumber')">
        <ppl-input-phone-number formControlName="phoneNumber"></ppl-input-phone-number>
      </ppl-input-container>
    </div>
  `;

  form: TFormGroup<{ phoneNumber: InputPhoneNumber }>;


  constructor(
    private i18nService: I18nService
  ) { }

  ngOnInit() {
    const formBuilder = new TFormBuilder();
    this.form = formBuilder.group<{ phoneNumber: InputPhoneNumber }>({
      phoneNumber: [this.phoneNumber, [inputPhoneNumberRequiredValidator(), inputPhoneNumberValidator(this.i18nService)]]
    });
  }
}
