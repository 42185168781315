<ppl-icon *ngIf="!minified"
          [svgIcon]="icon"
          width="80px"
          height="80px"></ppl-icon>

<div class="label"
     [class.minified]="minified">
  {{label}}
</div>

<div *ngIf="description && !minified"
     class="description">
  {{description}}
</div>