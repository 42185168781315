import {
  TemplateRef
,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'ppl-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplContentBlockComponent {
  @Input() label: string;
  @Input() icon: string | null;
  @Input() infoMessage?: string;
  @Input() labelTemplate?: TemplateRef<any>;
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() @HostBinding('class.has-content') displayContent = true;
  @Input() @HostBinding('class.flex') flex = false;
  @Input() @HostBinding('style.padding') padding = '0px';
}
