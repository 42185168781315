<ppl-common-grid [columns]="columns"
                 [columnTemplates]="columnTemplates"
                 [actions]="availableActions"
                 [data]="records || []"
                 [selection]="selection"
                 [displayColumnSelector]="displayColumnSelector"
                 [rowHeight]="rowHeight"
                 selectionMode="single"
                 (selectionChange)="selectionChange.emit($event)"
                 (actionClick)="actionClick.emit($event)">
</ppl-common-grid>

<ng-template #capabilityColumnTemplate
             let-value>
  <div class="capability">
    <ppl-icon *ngIf="value.includes(PhoneNumberCapability.Voice)"
              svgIcon="calling-space-settings"
              height="18px"
              width="18px"></ppl-icon>
    <ppl-icon *ngIf="value.includes(PhoneNumberCapability.SMS)"
              svgIcon="color-text-message"
              height="18px"
              width="18px"></ppl-icon>
  </div>
</ng-template>

<ng-template #emailOrDefaultColumnTemplate
             let-value>
  <ng-container *ngTemplateOutlet="valueOrDefault; context: {
    $implicit: value,
    defaultText: ('No Email Address' | t)
  }"></ng-container>
</ng-template>

<ng-template #phoneNumberOrDefaultColumnTemplate
             let-value>
  <ng-container *ngTemplateOutlet="valueOrDefault; context: {
    $implicit: value,
    defaultText: ('No Phone Number' | t)
  }"></ng-container>
</ng-template>

<ng-template #booleanColumnTemplate
             let-value>
  <ng-container *ngIf="value"
                i18n="@@Yes">Yes</ng-container>
  <ng-container *ngIf="!value"
                i18n="@@No">No</ng-container>
</ng-template>

<ng-template #valueOrDefault
             let-value
             let-defaultText="defaultText">
  <span [class.empty]="!value">{{ value || defaultText }}</span>
</ng-template>


<ng-template #phoneNumberColumnTemplate
             let-value
             let-record="record">
  <div>
    <div class="phone-number">
      {{ value }}
    </div>
    <div *ngIf="record.locality || record.region">
      <span *ngIf="record.locality">{{ record.locality }}</span><span *ngIf="record.locality && record.region">, </span><span *ngIf="record.region">{{ record.region }}</span>
    </div>
  </div>
</ng-template>

<ng-template #priceColumnTemplate
             let-value>
  {{ value | pplCurrency:'USD' }}
</ng-template>
