import { PplBaseOption } from '@ppl/domain';
import type {
  OnInit
} from '@angular/core';
import {
  Component,
  Input
,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-multiple-select-option',
  templateUrl: './multiple-select-option.component.html',
  styleUrls: ['./multiple-select-option.component.scss']
})
export class PplMultipleSelectOptionComponent implements OnInit {

  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() option: PplBaseOption;
  @Input() optionTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

}
