<input pplInput
       [disabled]="disabled"
       [value]="value"
       (input)="onValueChange($event.target.value)">
<button *ngIf="showOpenUrl"
        type="button"
        [disabled]="value === '' ? true : null"
        pplButton
        pplButton1
        pplButtonFocusable
        (click)="onButtonClick()">
  <ppl-icon svgIcon="open-url"
            width="18px"
            height="18px"></ppl-icon>
</button>
