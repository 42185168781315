import { PplVirtualScrollForOfDirective } from './virtual-scroll-for-of/virtual-scroll-for-of.directive';
import { PplVirtualScrollStickyHeaderComponent } from './virtual-scroll-sticky-header/virtual-scroll-sticky-header.component';
import { PplVirtualScrollComponent } from './virtual-scroll.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PplVirtualScrollComponent,
    PplVirtualScrollForOfDirective,
    PplVirtualScrollStickyHeaderComponent
  ],
  exports: [
    PplVirtualScrollComponent,
    PplVirtualScrollForOfDirective,
    PplVirtualScrollStickyHeaderComponent
  ]
})
export class PplVirtualScrollModule { }

export * from './virtual-scroll-data-fetcher';
export * from './virtual-scroll-for-of/virtual-scroll-for-of.directive';
export * from './virtual-scroll.component';
