import { AccordionDemoComponent } from './components/accordion-demo/accordion-demo.component';
import { AccountClassDemoComponent } from './components/account-class-demo/account-class-demo.component';
import { AddressbookPictureDemoComponent } from './components/addressbook-picture-demo/addressbook-picture-demo.component';
import { AppContainerDemoComponent } from './components/app-container-demo/app-container-demo.component';
import { AttachmentsDemoComponent } from './components/attachments-demo/attachments-demo.component';
import { AutocompleteDemoComponent } from './components/autocomplete/autocomplete-demo.component';
import { BottomBarDemoComponent } from './components/bottom-bar/bottom-bar-demo.component';
import { ButtonsDemoComponent } from './components/buttons-demo/buttons-demo.component';
import { CarouselDemoComponent } from './components/carousel-demo/carousel-demo.component';
import { CheckboxDemoComponent } from './components/checkbox/checkbox-demo.component';
import { ContainerDemoComponent } from './components/container-demo/container-demo.component';
import { DatepickerDemoComponent } from './components/datepicker/datepicker-demo.component';
import { DialogDemoComponent } from './components/dialog-demo/dialog-demo.component';
import { DrawerDemoComponent } from './components/drawer-demo/drawer-demo.component';
import { EditableDemoComponent } from './components/editable-demo/editable-demo.component';
import { FormBuilderDemoComponent } from './components/form-builder-demo/form-builder-demo.component';
import { FormTableDemoComponent } from './components/form-table-demo/form-table-demo.component';
import { GraphItemDemoComponent } from './components/graph-item-demo/graph-item-demo.component';
import { GraphToggleDemoComponent } from './components/graph-toggle-demo/graph-toggle-demo.component';
import { HomeComponent } from './components/home/home.component';
import { IconsDemoComponent } from './components/icons-demo/icons-demo.component';
import { InfiniteScrollDemoComponent } from './components/infinite-scroll-demo/infinite-scroll-demo.component';
import { InfoBoxDemoComponent } from './components/info-box-demo/info-box-demo.component';
import { InputNumberDemoComponent } from './components/input-number-demo/input-number-demo.component';
import { InputPhoneNumberDemoComponent } from './components/input-phone-number-demo/input-phone-number-demo.component';
import { InputDemoComponent } from './components/input/input-demo.component';
import { ItemsChooserDemoComponent } from './components/items-chooser-demo/items-chooser-demo.component';
import { LinkDemoComponent } from './components/link-demo/link-demo.component';
import { LoadingOverlayDemoComponent } from './components/loading-overlay-demo/loading-overlay-demo.component';
import { MenuDemoComponent } from './components/menu-demo/menu-demo.component';
import { MultipleAutocompleteDemoComponent } from './components/multiple-autocomplete-demo/multiple-autocomplete-demo.component';
import { MultipleSelectDemoComponent } from './components/multiple-select-demo/multiple-select-demo.component';
import { NavBarDemoComponent } from './components/nav-bar-demo/nav-bar-demo.component';
import { PartialSelectDemoComponent } from './components/partial-select-demo/partial-select-demo.component';
import { PopoverDemoComponent } from './components/popover-demo/popover-demo.component';
import { RadioButtonDemoComponent } from './components/radio-button/radio-button-demo.component';
import { RankingDemoComponent } from './components/ranking-demo/ranking-demo.component';
import { RankingPercentageDemoComponent } from './components/ranking-percentage-demo/ranking-percentage-demo.component';
import { ResizableDemoComponent } from './components/resizable-demo/resizable-demo.component';
import { SelectDemoComponent } from './components/select/select-demo.component';
import { SelectorViewDemoComponent } from './components/selector-view-demo/selector-view-demo.component';
import { SlideToggleDemoComponent } from './components/slide-toggle-demo/slide-toggle-demo.component';
import { StepsDemoComponent } from './components/steps-demo/steps-demo.component';
import { TabsDemoComponent } from './components/tabs-demo/tabs-demo.component';
import { TextDropdownDemoComponent } from './components/text-dropdown-demo/text-dropdown-demo.component';
import { ToastDemoComponent } from './components/toast-demo/toast-demo.component';
import { ToggleGroupDemoComponent } from './components/toggle-group-demo/toggle-group-demo.component';
import { ToggleListDemoComponent } from './components/toggle-list-demo/toggle-list-demo.component';
import { TopBarDemoComponent } from './components/top-bar-demo/top-bar-demo.component';
import { TreeSelectDemoComponent } from './components/tree-select-demo/tree-select-demo.component';
import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CallingConversationItemDemoComponent } from './components/calling-conversation-item-demo/calling-conversation-item-demo.component';
import { AdvancedMultiselectCheckboxDemoComponent } from './components/advanced-multiselect-checkbox-demo/advanced-multiselect-checkbox-demo.component';

const COMPONENTS_SHOWCASE_ROUTES: Routes = [
  { path: '', component: HomeComponent },
  { path: 'autocomplete', component: AutocompleteDemoComponent },
  { path: 'carousel', component: CarouselDemoComponent },
  { path: 'accordion', component: AccordionDemoComponent },
  { path: 'checkbox', component: CheckboxDemoComponent },
  { path: 'datepicker', component: DatepickerDemoComponent },
  { path: 'input', component: InputDemoComponent },
  { path: 'radio-button', component: RadioButtonDemoComponent },
  { path: 'select', component: SelectDemoComponent },
  { path: 'multiple-select', component: MultipleSelectDemoComponent },
  { path: 'dialog', component: DialogDemoComponent },
  { path: 'slide-toggle', component: SlideToggleDemoComponent },
  { path: 'bottom-bar', component: BottomBarDemoComponent },
  { path: 'account-class', component: AccountClassDemoComponent },
  { path: 'buttons', component: ButtonsDemoComponent },
  { path: 'icons', component: IconsDemoComponent },
  { path: 'form-builder', component: FormBuilderDemoComponent },
  { path: 'multiple-autocomplete', component: MultipleAutocompleteDemoComponent },
  { path: 'ranking', component: RankingDemoComponent },
  { path: 'top-bar', component: TopBarDemoComponent },
  { path: 'app-container', component: AppContainerDemoComponent },
  { path: 'editable', component: EditableDemoComponent },
  { path: 'popover', component: PopoverDemoComponent },
  { path: 'menu', component: MenuDemoComponent },
  { path: 'addressbook-picture', component: AddressbookPictureDemoComponent },
  { path: 'container', component: ContainerDemoComponent },
  { path: 'infinite-scroll', component: InfiniteScrollDemoComponent },
  { path: 'resizable', component: ResizableDemoComponent },
  { path: 'tabs', component: TabsDemoComponent },
  { path: 'input-number', component: InputNumberDemoComponent },
  { path: 'attachments', component: AttachmentsDemoComponent },
  { path: 'toggle-group', component: ToggleGroupDemoComponent },
  { path: 'ranking-percentage', component: RankingPercentageDemoComponent },
  { path: 'loading-overlay', component: LoadingOverlayDemoComponent },
  { path: 'toast', component: ToastDemoComponent },
  { path: 'info-box', component: InfoBoxDemoComponent },
  { path: 'partial-select', component: PartialSelectDemoComponent },
  { path: 'nav-bar', component: NavBarDemoComponent },
  { path: 'text-dropdown', component: TextDropdownDemoComponent },
  { path: 'items-chooser', component: ItemsChooserDemoComponent },
  { path: 'drawer', component: DrawerDemoComponent },
  { path: 'form-table', component: FormTableDemoComponent },
  { path: 'toggle-list', component: ToggleListDemoComponent },
  { path: 'steps', component: StepsDemoComponent },
  { path: 'input-phone-number', component: InputPhoneNumberDemoComponent },
  { path: 'link', component: LinkDemoComponent },
  { path: 'graph-toggle', component: GraphToggleDemoComponent },
  { path: 'graph-item', component: GraphItemDemoComponent },
  { path: 'tree-select', component: TreeSelectDemoComponent },
  { path: 'selector-view', component: SelectorViewDemoComponent },
  { path: 'calling-conversation-item', component: CallingConversationItemDemoComponent },
  { path: 'multi-checkbox-advanced', component: AdvancedMultiselectCheckboxDemoComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(COMPONENTS_SHOWCASE_ROUTES, {
      enableTracing: false,
      relativeLinkResolution: 'legacy'
    })
  ]
})
export class AppRoutingModule { }
