import {
  SpaceSettingsFragment,
  UserSettingsFragment
} from '@ppl/graphql-user-api';
import { gql } from '@ppl/utils';

const IdentityProviderEntityFragment = gql`
  fragment IdentityProviderEntityFragment on IdentityProviderEntity {
    enabled
    id
    name
    subscriptionId
    type
  }`;


const SpaceFields = gql`
  fragment SpaceFields on Space {
    accessStatus
    appBaseUrl
    graphqlUrl
    appVersion
    clientId
    regionUrl
    restUrl
    socketioUrl
    bccMailto
    id
    isSuspended
    name
    pictureUrl
    subscription {
      domain
      classification
      endDate
      expiredDate
      id
      isTrial
      licensesPurchased
      licensesUsed
      mostDuePaymentDate
      name
      productTier
      startDate
      representativeEmail
      admins {
        id
        email
      }
    }
    idp {
      ...IdentityProviderEntityFragment
    }
    url
    version
    size
    settings {
      ...SpaceSettingsFragment
    }
  }
  ${IdentityProviderEntityFragment}
  ${SpaceSettingsFragment}
`;

export const gqlUserProfile = gql`
  query UserProfile {
    accessControl {
      apps
    }
    profile {
      availableIdentityProviders {
        ...IdentityProviderEntityFragment
      }
      businessFunction
      city
      company
      country
      created
      currentIdp {
        ...IdentityProviderEntityFragment
      }
      defaultIdp {
        ...IdentityProviderEntityFragment
      }
      defaultIdpId
      email
      firstName
      firstname
      id
      intercomUserHash
      isDeleted
      language
      lastName
      lastname
      middleName
      middlename
      modified
      partnerId
      pictureUrl
      stateProvince
      telephone
      timezoneAutodetect
      timezoneName
    }
    settings {
      ...UserSettingsFragment
    }
    spaces {
     ...SpaceFields
    }
    entities {
      subscription {
        getAdministeredByMe {
          totalCount
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
  ${UserSettingsFragment}
  ${IdentityProviderEntityFragment}
  ${SpaceFields}
`;

export const gqlSpaceById = gql`
  query getSpace($spaceId: ID!) {
    space(id: $spaceId) {
      ...SpaceFields
    }
  }
  ${SpaceFields}
`;
