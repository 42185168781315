<div class="container demo-content">
  <h2>Steps</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-steps</h4>
  <div>@Input() <b>activeStepIndex</b>: number</div>
  <div>@Input() <b>steps</b>: Step[]</div>

  <br>
  <br>

  <h4>Example</h4>
  <ppl-steps [activeStepIndex]="activeStepIndex"
             [steps]="steps"></ppl-steps>

  <br>
  <button pplButton
          pplButton1
          (click)="onNext()">Next</button>

  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>