import { CdkTrapFocus } from '@angular/cdk/a11y';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import type {
  AfterViewInit,
  OnDestroy,
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  ViewChild
,
  ChangeDetectorRef,
  Renderer2} from '@angular/core';
import type { PostAnimationFrameHandle} from '@ppl/utils';
import { KEYCODE_ESCAPE, requestPostAnimationFrame } from '@ppl/utils';
import type { Subject } from 'rxjs';

export class PplDialogContainerData {
  afterOpened: Subject<void>;
  autoFocus: boolean | null;
  containerClass?: string;
  enableClose?: boolean;
  close: (value?: any) => void;
  zIndexIncrement?: number;
}

@Component({
  selector: 'ppl-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDialogContainerComponent implements OnInit, AfterViewInit, OnDestroy {

  dialogPreVisible = false;
  dialogVisible = false;
  dialogAfterOpened = false;

  @ViewChild('dialogBackdrop', { static: true }) dialogBackdrop: ElementRef;
  @ViewChild(CdkPortalOutlet, { static: true }) dialogPortal: CdkPortalOutlet;
  @ViewChild('dialog', { read: CdkTrapFocus, static: true }) dialogFocusTrap: CdkTrapFocus;
  @ViewChild('dialog', { read: ElementRef, static: true }) dialogElementRef: ElementRef<HTMLDivElement>;
  @ViewChild('dialogHiddenFocus', { static: false }) dialogHiddenFocus: ElementRef;

  dialogRenderHandles: PostAnimationFrameHandle[] = [];

  @HostBinding('style.z-index') dialogContainerZIndex = 1000;

  constructor(
    @Inject(PplDialogContainerData) public data: PplDialogContainerData,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (this.data.containerClass) {
      this.dialogBackdrop.nativeElement.classList.add(this.data.containerClass);
    }

    if ('zIndexIncrement' in this.data) {
      const newZIndex = this.dialogContainerZIndex + this.data.zIndexIncrement;
      this.dialogContainerZIndex = newZIndex;
      this.renderer.setStyle(this.dialogElementRef.nativeElement, 'z-index', newZIndex + 1);
    } else {
      this.renderer.setStyle(this.dialogElementRef.nativeElement, 'z-index', this.dialogContainerZIndex + 1);
    }
  }

  ngAfterViewInit() {
    // At this point, dialog content should be rendered offscreen
    // 1. Add 'pre-visible' class to scale the dialog before animation starts
    // 2. After that, add 'visible' class to perform actual transition (opacity + transform)
    this.dialogRenderHandles.push(requestPostAnimationFrame(() => {
      this.dialogPreVisible = true;
      this.changeDetectorRef.detectChanges();

      this.dialogRenderHandles.push(requestPostAnimationFrame(() => {
        if (this.data.autoFocus !== null) {
          if (this.data.autoFocus) {
            this.dialogFocusTrap.focusTrap.focusInitialElement();
          } else {
            this.dialogHiddenFocus.nativeElement.focus();
          }
        }

        this.dialogVisible = true;
        this.changeDetectorRef.detectChanges();
      }));
    }));
  }

  ngOnDestroy() {
    this.dialogRenderHandles.forEach(renderHandle => renderHandle.clear());
  }

  @HostListener('window:keyup', ['$event'])
  onKeyEvent(event: KeyboardEvent) {
    if (this.data.enableClose && event.keyCode === KEYCODE_ESCAPE) {
      this.close();
    }
  }

  onTransitionEnd(event: TransitionEvent) {
    if (event.currentTarget === event.target && event.propertyName === 'transform') {
      if (!this.dialogAfterOpened) {
        this.dialogAfterOpened = true;
        this.data.afterOpened.next();
      }
    }
  }

  onBackdropClick() {
    if (this.data.enableClose) {
      this.close();
    }
  }

  close() {
    this.data.close();
  }
}
