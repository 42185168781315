import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-content-title',
  templateUrl: './content-title.component.html',
  styleUrls: ['./content-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplContentTitleComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string | null;
  @Input() infoMessage?: string;
  @Input() labelTemplate?: TemplateRef<any>;
  @Input() actionTemplate?: TemplateRef<any>;
  @Input() @HostBinding('class.flex') flex = false;

  constructor() { }

  ngOnInit(): void {
  }
}
