<div class="container demo-content">
  <h2>Menu</h2>
  <br>

  <h3>Components</h3>
  <h4>ppl-menu</h4>

  <p>
    /** Currently selected value */<br>
    @Input() <b>value</b>: string;
  </p>

  <p>
    /** Put a check in front of a selected option */<br>
    @Input() <b>markSelected</b>: boolean;
  </p>

  <p>
    /** Creates space for icons */<br>
    @Input() <b>hasIcons</b>: boolean;
  </p>

  <p>
    /** Setting the exact width of container */<br>
    @Input() @HostBinding('style.width.px') <b>width</b>: boolean | number = false;
  </p>

  <p>
    /** Emits changed value */<br>
    @Output() <b>valueChanged</b>: EventEmitter&#x3C;string&#x3E;;
  </p>

  <h3>Example</h3>

  <p>Selected value: {{selectedValue}}</p>

  <ppl-menu [value]="selectedValue" [markSelected]="true" [hasIcons]="true" (valueChanged)="selectedValue = $event" [width]="200">
    <ppl-menu-item [value]="'1'">
      <ppl-icon svgIcon="pipeline-view" width="18px" height="16px"></ppl-icon>
      Pipeline View
    </ppl-menu-item>
    <ppl-menu-item [value]="'2'">
      <ppl-icon svgIcon="bubble-view" width="18px" height="18px"></ppl-icon>
      Bubble Chart View
    </ppl-menu-item>
    <ppl-menu-item [value]="'3'">
      <ppl-icon svgIcon="compact-view" width="18px" height="18px"></ppl-icon>
      Compact View
    </ppl-menu-item>
    <ppl-menu-item [value]="'4'">
      <ppl-icon svgIcon="list-view" width="18px" height="18px"></ppl-icon>
      List View
    </ppl-menu-item>
  </ppl-menu>

  <br>
  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
