import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ValidationErrors } from '@angular/forms';
import type { I18nService } from '@ppl/i18n';
import type { TAbstractControl } from '@ppl/ui/form';
import { hasInterpolation, MemoizeLast } from '@ppl/utils';
import { SegmentedMessage } from 'sms-segments-calculator';


@Component({
  selector: 'ppl-calling-text-message-character-counter',
  templateUrl: './calling-text-message-character-counter.component.html',
  styleUrls: ['./calling-text-message-character-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallingTextMessageCharacterCounterComponent {
  @Input() message: string;
  @Input() maxMessageSegments?: number;

  @MemoizeLast<CallingTextMessageCharacterCounterComponent>(['message'])
  get segmentedMessage() {
    return new SegmentedMessage(this.message);
  }

  @MemoizeLast<CallingTextMessageCharacterCounterComponent>(['message'])
  get showCharacterWarning() {
    return hasInterpolation(this.message);
  }

  @MemoizeLast<CallingTextMessageCharacterCounterComponent>(['message'])
  get segmentsCount() {
    return this.segmentedMessage.segmentsCount;
  }

  @MemoizeLast<CallingTextMessageCharacterCounterComponent>(['message'])
  get currentCount() {
    return this.segmentedMessage.numberOfCharacters;
  }

  @MemoizeLast<CallingTextMessageCharacterCounterComponent>(['message'])
  get totalCount() {
    const maxSegmentCharacterCount = getSegmentMaxCharacterCount(this.segmentedMessage);

    if (typeof this.maxMessageSegments === 'undefined') {
      return this.currentCount > maxSegmentCharacterCount
      ? Math.ceil(this.currentCount / maxSegmentCharacterCount) * maxSegmentCharacterCount
      : maxSegmentCharacterCount;
    } else {
      return this.maxMessageSegments > 0
        ? maxSegmentCharacterCount * this.maxMessageSegments
        : maxSegmentCharacterCount;
    }
  }
}


export function getSegmentMaxCharacterCount(segmentedMessage: SegmentedMessage) {
  return segmentedMessage.encodingName === 'GSM-7' ? 160 : 70;
}

export function maxMessageSegmentsValidator(
  i18nService: I18nService,
  maxSegmentCount: number
) {
  return (control: TAbstractControl<string>): ValidationErrors | null => {
    const value = control.value;

    if (!value || hasInterpolation(value)) {
      return null;
    }

    const segmentedMessage = new SegmentedMessage(value);
    const maxSegmentCharacters = getSegmentMaxCharacterCount(segmentedMessage);
    const maxCharacters = maxSegmentCount * maxSegmentCharacters;

    return segmentedMessage.numberOfCharacters > maxCharacters
      ? { custom: i18nService.translate(`Character_count_reached_the_limit,_this_message_can't_be_sent`) }
      : null;
  };
}
