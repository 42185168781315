<div class="container">
  <div *ngIf="!showCharacterWarning"
       class="character-count">{{ currentCount }}/{{ totalCount }} ({{ segmentsCount }})</div>
  <ppl-icon *ngIf="showCharacterWarning"
            svgIcon="warning-orange-full"
            height="16px"
            width="16px"
            pplPopover
            pplPopoverDirection="up"
            pplPopoverTooltip="The total length of a text message will vary, because Personalization or a different character set is used"
            i18n-pplPopoverTooltip="@@The_total_length_of_a_text_message_will_vary,_because_Personalization_or_a_different_character_set_is_used"></ppl-icon>
</div>
