import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplAutocompleteModule } from '../autocomplete';
import { PplAutocompleteSelectComponent } from './autocomplete-select.component';

@NgModule({
  imports: [
    CommonModule,
    PplAutocompleteModule
  ],
  declarations: [
    PplAutocompleteSelectComponent
  ],
  exports: [
    PplAutocompleteSelectComponent
  ]
})
export class PplAutocompleteSelectModule {
}

export * from './autocomplete-select.component';

