import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import type { Observable} from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';

export interface Icon {
  dataURL: string;
  innerHTML: string;
}

export interface Icons {
  [id: string]: Icon;
}

export class IconCacheService {
  setIcons(icons: Icons) {
  }

  getIcons(): Observable<Icons> {
    return of(null);
  }
}

@Injectable()
export class PplIconService {

  icons: { [id: string]: Icon } = {};
  isLoaded$ = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    @Optional() private iconCache: IconCacheService) {
  }

  registerPplIcons(deployUrl: string) {
    if (!this.iconCache) {
      this.fetchIcons(deployUrl);
      return;
    }

    this.iconCache.getIcons().subscribe(icons => {
      if (icons) {
        this.icons = icons;
        this.isLoaded$.next(true);
      } else {
        this.fetchIcons(deployUrl);
      }
    }, () => this.fetchIcons(deployUrl));
  }

  setPplIcons(innerHTML: string) {
    this.parseIcons(innerHTML);

    this.isLoaded$.next(true);
  }

  private fetchIcons(deployUrl: string) {
    this.httpClient.get(`${deployUrl}/assets/icons.svg`, {
      responseType: 'text'
    }).subscribe(response => {
      this.parseIcons(response);

      if (this.iconCache) {
        this.iconCache.setIcons(this.icons);
      }

      this.isLoaded$.next(true);
    });
  }

  private parseIcons(response: string) {
    const result = response.replace(/\<(\/?)div>/g, '');

    result.split('<svg').map(svg => {
      if (svg) {
        const iconName = svg.match(/class="(.+?)"/)[1];
        const innerHTML = '<svg' + svg;

        this.icons[iconName] = {
          innerHTML,
          dataURL: svgToDataURL(innerHTML)
        };
      }
    });
  }

}


export const svgToDataURL = (svg: string) => {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export function getIconSVG(icon: Icon): string {
  const domParser = new DOMParser();
  const doc = domParser.parseFromString(icon.innerHTML, 'image/svg+xml');
  // NOTE(mike): IE11 doesn't have the `forEach` method on `NodeList`, therefore
  // need to convert to Array.
  Array.from(doc.querySelectorAll('style')).forEach(element => element.parentNode.removeChild(element));
  Array.from(doc.querySelectorAll('[fill]')).forEach(element => element.removeAttribute('fill'));
  const iconSVG = doc.querySelector('svg');
  // NOTE(mike): The `XMLSerializer` is a workaround for IE11 which doesn't have `outerHTML`
  const iconSVGAsString = iconSVG.outerHTML || new XMLSerializer().serializeToString(iconSVG);
  return iconSVGAsString;
}
