import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppl-toast-content-demo',
  templateUrl: './toast-content-demo.component.html',
  styleUrls: ['./toast-content-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastContentDemoComponent implements OnInit {
  index: number;

  closeToast$ = new Subject<null>();

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.closeToast$.next();
  }
}
