import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplPopoverModule } from '../popover';
import { PplPopoverTooltipComponent } from './popover-tooltip.component';
import { PplTooltipDirective } from './tooltip.directive';


@NgModule({
    imports: [
        CommonModule,
        PplPopoverModule
    ],
    declarations: [
        PplPopoverTooltipComponent,
        PplTooltipDirective
    ],
    exports: [
        PplPopoverTooltipComponent,
        PplTooltipDirective
    ]
})
export class PplPopoverTooltipModule {
}

export * from './popover-tooltip.component';
export * from './tooltip.directive';
