import { PplMultipleSelectOptionComponent } from './multiple-select-option.component';
import { PplCheckboxModule } from '../checkbox';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';


@NgModule({
  imports: [
    CommonModule,
    PplCheckboxModule,
  ],
  declarations: [
    PplMultipleSelectOptionComponent
  ],
  exports: [
    PplMultipleSelectOptionComponent
  ]
})
export class PplMultipleSelectOptionModule {
}

export * from './multiple-select-option.component';

