import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { PplRankingPercentageComponent } from './ranking-percentage/ranking-percentage.component';
import { PplRankingComponent } from './ranking.component';
import { PplPopoverTooltipModule } from '../popover-tooltip';
import { PplPopoverModule } from '../popover';

const PPL_MODULE_DECLARATIONS = [
  PplRankingComponent,
  PplRankingPercentageComponent
];

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplInputModule,
    PplPopoverModule,
    PplPopoverTooltipModule
  ],
  declarations: [
    ...PPL_MODULE_DECLARATIONS
  ],
  exports: [
    ...PPL_MODULE_DECLARATIONS
  ]
})
export class PplRankingModule {
}

export * from './ranking.component';
