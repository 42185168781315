import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, Route } from '@angular/router';
import { of } from 'rxjs';

@Injectable()
export class OfficeTokenServiceRedirectGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot) {
		window.location.href = route.queryParams.url;

    return of(false);
  }

}

export const OfficeTokenRedirectRoute: Route = {
  path: 'sso-redirect',
  canActivate: [OfficeTokenServiceRedirectGuard],
  component: class {}
};
