import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';
import type { PplTreeSelectItem } from '../tree-select-item/tree-select-item.component';

@Component({
  selector: 'ppl-tree-select-items',
  templateUrl: './tree-select-items.component.html',
  styleUrls: ['./tree-select-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectItemsComponent implements OnInit {
  @Input() items: PplTreeSelectItem[];
  @Input() collapsible: boolean;
  @Input() openedByDefault = false;
  @Input() highlightCategories?: boolean;
  @Input() isRoot = true;
  @Input() searchValue: string;
  @Input() hideUnavailableLabels: boolean;

  @Output() toggle = new EventEmitter<PplTreeSelectItem>();

  constructor() { }

  ngOnInit(): void {
  }

  trackTree(index, item: PplTreeSelectItem) {
    return item.id;
  }
}
