import { ErrorCode } from '../domain/enums/error-code';
import type { ApiError, EntityValidationException, ErrorHandlerService } from '../service/error-handler.service';
import { HttpHeaders } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { getFormattedGraphqlError, onError, TIMEZONE_NAME_OR_OFFSET } from '@ppl/utils';
import type { HttpLink } from 'apollo-angular/http';
import type { GraphQLError } from 'graphql';
import type { EMPTY} from 'rxjs';
import { throwError } from 'rxjs';

export const getApolloLink = (
  httpLink: HttpLink,
  gqlUrl: string,
  errorHandlerService?: ErrorHandlerService,
  token: string = null,
  tokenPrefix = 'PipelinerToken',
  appId?: string,
  customHeaders?: { [key: string]: string }
) => {
  const errorLink = onError(({ networkError, graphQLErrors, response, operation }) => {
    const context = operation.getContext();
    const statusCode = networkError ? (networkError as any).status || (networkError as any).statusCode : null;

    const isUnauthorizedError = statusCode === 401;
    if (isUnauthorizedError) {
      const shouldHandleUnauthorized = !(context && context.doNotHandleUnauthorized);
      if (shouldHandleUnauthorized) {
        errorHandlerService.handleUnauthorizedError(context);
      }
      return;
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((graphqlError: (GraphQLError & { api_error: any })) => console.error(getFormattedGraphqlError(graphqlError)));
      console.log('[GraphQL Query]', operation.query.loc.source.body);
      console.log('[GraphQL Variables]', '\n' + JSON.stringify(operation.variables));
    }
    if (networkError) {
      console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    }

    const error = context.error;
    const errorWhiteList = context && context.errorWhiteList;
    if (errorHandlerService && error !== false) {
      errorHandlerService.handleApiError({
        networkError,
        graphQLErrors,
        response,
        operation,
        errorWhiteList
      });
    }
  });

  let headers = new HttpHeaders({
    'Timezone': TIMEZONE_NAME_OR_OFFSET,
    ...customHeaders ? customHeaders : {}
  });

  if (token) {
    headers = headers.set('Authorization', `${tokenPrefix} ${token}`);
    // triggers processes
    headers = headers.set('pipeliner-revision-flags', `2`);
  }
  if (appId) {
    headers = headers.set('Pipeliner-App-Id', appId);
  }
  headers = headers.set('x-short-wait-locks', `True`);

  const link = httpLink.create({
    uri: operation => {
      return `${gqlUrl}?op=${operation.operationName}`;
    },
    headers,
    withCredentials: !isDevMode()
  });

  return errorLink.concat(link);
};

export function hasAnyErrorCode(context: { graphQLErrors?: GraphQLError[] }) {
  if (!context || !context.graphQLErrors || !context.graphQLErrors.length) {
    return false;
  } else {
    return !!context.graphQLErrors
      .filter((error: any) => !!error.api_error)
      .map((error: any) => error.api_error as ApiError)
      .find(error => !!error.code);
  }
}

export function retrieveAPIErrors(context: { graphQLErrors?: GraphQLError[] }): ApiError[] {
  if (!context || !context.graphQLErrors || !context.graphQLErrors.length) {
    return [];
  } else {
    return context.graphQLErrors
      .filter((error: any) => !!error.api_error && !!(error.api_error as ApiError).code)
      .map((error: any) => (error.api_error as ApiError));
  }
}

export function retrieveErrorCodes(context: { graphQLErrors?: GraphQLError[] }): ErrorCode[] {
  return retrieveAPIErrors(context).map(apiError => apiError.code);
}

type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never;
type ApiErrorsMap = { [V in ApiError['code']]: DiscriminateUnion<ApiError, 'code', V> };

export function retrieveSpecificErrors<K extends ErrorCode>(context: { graphQLErrors?: (GraphQLError & { api_error?: ApiError })[] }, errorCode: K): ApiErrorsMap[K][] {
  if (!context || !context.graphQLErrors || !context.graphQLErrors.length) {
    return [];
  }

  return context.graphQLErrors
    .filter(error => !!error.api_error && error.api_error.code === errorCode)
    .map(error => error.api_error) as ApiErrorsMap[K][];
}

export function retrieveFirstErrorCode(context: { graphQLErrors?: GraphQLError[] }): ErrorCode {
  const errors = retrieveErrorCodes(context);
  return errors && !!errors.length ? errors[0] : null;
}

export function hasErrorCode(context: { graphQLErrors?: GraphQLError[] }, errorCode: ErrorCode) {
  if (!context || !context.graphQLErrors || !context.graphQLErrors.length) {
    return false;
  } else {
    return !!context.graphQLErrors
      .filter((error: any) => !!error.api_error)
      .map((error: any) => error.api_error as ApiError)
      .find(error => error.code === errorCode);
  }
}

export function retrieveFirstError(
  context: { graphQLErrors?: (GraphQLError & { api_error?: ApiError })[] },
  errorCode: ErrorCode
): ApiError | null {
  if (context && context.graphQLErrors && context.graphQLErrors.length) {
    const foundApiError = context.graphQLErrors
      .filter(error => !!error.api_error)
      .map(error => error.api_error as ApiError)
      .find(apiError => apiError.code === errorCode);
    return foundApiError || null;
  } else {
    return null;
  }
}

export function hasEntityValidationError(context: { graphQLErrors?: GraphQLError[] }, errorCode: ErrorCode) {
  const entityValidationError = context
    && context.graphQLErrors
    && context.graphQLErrors.filter((error: any) => !!error.api_error)
      .map((error: any) => error.api_error as ApiError)
      .find(error => String(error.code) === String(ErrorCode.ERROR_ENTITY_VALIDATION));

  if (entityValidationError) {
    return !!(entityValidationError as EntityValidationException).entity_errors
      .map(error => error.code)
      .find(code => String(code) === String(errorCode));
  } else {
    return false;
  }
}

export function hasInvalidConditionErrorCode(context: { graphQLErrors?: GraphQLError[] }) {
  return hasErrorCode(context, ErrorCode.ERROR_FIELD_ACCESS_INVALID_CONDITION);
}

export function hasErrorCodes(context: { graphQLErrors?: GraphQLError[] }, errorCodes: ErrorCode[]) {
  if (!context || !context.graphQLErrors || !context.graphQLErrors.length) {
    return false;
  } else {
    return !!context.graphQLErrors
      .filter((error: any) => !!error.api_error)
      .map((error: any) => error.api_error as ApiError)
      .find(error => errorCodes.includes(error.code));
  }
}

export function getErrorMessage(error: GraphQLError & { api_error?: ApiError }) {
  return error && error.api_error && error.api_error.message
    ? error.api_error.message
    : error.message;
}

export function handleEntityValidationErrors(
  error: { graphQLErrors?: (GraphQLError & { api_error?: ApiError })[] },
  errorHandlers: { [key in ErrorCode]?: () => typeof EMPTY }
): typeof EMPTY {
  if (hasErrorCode(error, ErrorCode.ERROR_ENTITY_VALIDATION)) {
    if (hasEntityValidationError(error, ErrorCode.ERROR_INVALID_EMAIL)) {
      return errorHandlers[ErrorCode.ERROR_INVALID_EMAIL]?.();
    }
  }
  return throwError(error);
}
