<ppl-tabs [collapseTabs]="true">
  <ng-container ppl-tabs-suffix>
    additional stuff
  </ng-container>

  <ppl-tab label="View"
           icon="view">
    <div class="container demo-content">
      <h2>Tabs Component</h2>
      <br>
      <h3>Components</h3>
      <h4>ppl-tabs</h4>
      <div>@Input() <b>routable</b>: boolean <i>default (false)</i> </div>
      <div>@Input() <b>largeIcons</b>: boolean <i>default (false)</i> </div>
      <br>
      <h4>ppl-tab</h4>
      <div>@Input() <b>label</b>: string </div>
      <div>@Input() <b>icon</b>: string </div>
      <div>@Input() <b>name</b>: string // optional, used to manually open a tab if needed</div>
      <div>@Input() <b>selected</b>: boolean <i>default (false)</i></div>
      <div>@Input() <b>tabSwitch</b>: boolean | null <i>default (null)</i></div>
      <br>
      <br>

      <h3>Code Example</h3>
      <ppl-prism [code]="code"></ppl-prism>
    </div>
  </ppl-tab>
  <ppl-tab label="Filter"
           icon="color-filter"
           [tabSwitch]="filterActive">
    <ng-container ppl-tab-divider>
      <ppl-slide-toggle [value]="filterActive"
                        (valueChange)="filterActive = !filterActive"
                        label="Filter"></ppl-slide-toggle>
    </ng-container>
    <div class="container demo-content">
      Filter content
    </div>
  </ppl-tab>
  <ppl-tab label="View 2"
           icon="view"></ppl-tab>
  <ppl-tab label="View 3"
           icon="view"></ppl-tab>
  <ppl-tab label="View 4"
           icon="view"></ppl-tab>
  <ppl-tab label="View 5"
           icon="view"></ppl-tab>
  <ppl-tab label="View 6"
           icon="view"></ppl-tab>
  <ppl-tab label="View 7"
           icon="view"></ppl-tab>
  <ppl-tab label="View 8"
           icon="view"></ppl-tab>
  <ppl-tab label="View 9"
           icon="view"></ppl-tab>
  <ppl-tab label="View 10"
           icon="view"></ppl-tab>
  <ppl-tab label="View 11"
           icon="view"></ppl-tab>
  <ppl-tab label="View 12"
           icon="view"></ppl-tab>
  <ppl-tab label="View 13"
           icon="view"></ppl-tab>
  <ppl-tab label="View 14"
           icon="view"
           [tabSwitch]="true"></ppl-tab>
  <ppl-tab label="View 15"
           icon="view"></ppl-tab>
  <ppl-tab label="View 16"
           icon="view"></ppl-tab>
  <ppl-tab label="View 17"
           icon="view"></ppl-tab>
</ppl-tabs>
