import {
  ChangeDetectorRef,
  TemplateRef
,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, MemoizeLast, uuid } from '@ppl/utils';

@Component({
  selector: 'ppl-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplInputEmailComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplInputEmailComponent {

  @Input() value: string;
  @Input() disabled: boolean;
  @Input() showSendEmail = true;
  @Input() popoverTemplate: TemplateRef<any>;

  @Output() inputBlur = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() emailClicked = new EventEmitter<string>();

  autocompleteId = `input-email-${uuid()}`;

  @MemoizeLast<PplInputEmailComponent>(['value'])
  get valueEmpty() {
    return !(this.value || '').trim();
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  onValueChange(text: string) {
    this.valueChange.emit(text);
  }

  onButtonClick() {
    if (this.value) {
      this.emailClicked.emit(this.value);
    }
  }

}
