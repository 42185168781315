<div class="name">
  {{name}}
</div>

<div *ngFor="let column of columnsWithData; let i = index; trackBy: trackById"
     class="column"
     [class.frozen]="column.frozen"
     [style.left]="column.frozen ? column.offset + 'px' : 'auto'"
     [style.width.px]="column.width">
  <ng-container *ngIf="record[column.id] !== undefined">
    <ng-container *ngIf="column.template; else textColumn">
      <ng-template *ngTemplateOutlet="column.template; context: {
        '\$implicit': record[column.id],
        record: record,
        fieldID: column.id,
        typeOptions: column.typeOptions
      }"></ng-template>
    </ng-container>
    <ng-template #textColumn>
      {{record[column.id]}}
    </ng-template>
  </ng-container>
</div>

<div class="actions-placeholder"></div>

<div class="actions"></div>
