<div class="container demo-content">
  <h2>Accordion</h2>
  <br>
  <div>@Input()
    <b>title:</b> string</div>
  <div>@Input()
    <b>subTitle:</b> string</div>
  <div>@Input()
    <b>icon:</b> string</div>
  <div>@Output() <b>openChange</b>: boolean</div>
  <br>
  <h4>Demo</h4>
  <ppl-accordion icon="overdue-tasks"
                 title="Tasks"
                 [subTitle]="'1 Overdue Tasks'">
    <button pplButton
            pplButton1
            (click)="counter = counter + 1">+</button>
    <button pplButton
            pplButton1
            (click)="counter = counter - 1">-</button>
    <br>
    <ng-container *ngFor="let item of conterArray; trackBy: trackByFn;">
      {{item | json}}
      <br>
    </ng-container>
  </ppl-accordion>
  <br>
  <br>
  <h4>Code Example</h4>
  <p>
    <ppl-prism [code]="code"></ppl-prism>
  </p>
</div>
