import Fuse from 'fuse.js';

/**
 * Fuzzy searches a list of items
 * - also takes into account categories
 * https://fusejs.io/
 * @param list of items to fuzzy search
 * @param term filter using this
 * @param key item label key used for search
 */
export function fuzzySearch<T extends object>(
  { list, term, key = 'label' as never, categoryKey = 'categoryId', shouldSort = true }: {
    list: T[];
    term: string;
    key?: keyof T | (keyof T)[];
    categoryKey?: string
    shouldSort?: boolean
  }) {
  if (!term || term === '') {
    return list;
  }

  function isCategoryOption(option: T, i: number) {
    if (!option[categoryKey]) {
      return false;
    } else {
      return (i !== 0 && !!option[categoryKey] && option[categoryKey] !== list[i - 1][categoryKey]) || (i === 0 && !!option[categoryKey]);
    }
  }

  function search(array: T[]) {
    return new Fuse(array, {
      shouldSort, // sorts by score
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: typeof key === 'string' ? [key] : key
    }).search(term);
  }

  const result: T[] = [];
  let currentCategoryList: T[] = [];
  list.forEach((item, index, array) => { // loop all items, if the next one is a category-starter, append sorted items to result
    currentCategoryList.push(item);

    const nextIndex = index + 1;
    if (!array[nextIndex] || (array[nextIndex] && isCategoryOption(array[nextIndex], nextIndex))) {
      result.push(...search(currentCategoryList));
      currentCategoryList = [];
    }
  });

  return result;
}
