import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppl-calling-purchase-phone-not-allowed-message',
  templateUrl: './calling-purchase-phone-not-allowed-message.component.html',
  styleUrls: ['./calling-purchase-phone-not-allowed-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallingPurchasePhoneNotAllowedMessageComponent {
}
