<div class="graph-icon"
     [class.no-icon]="!iconTemplate">
  <ng-container *ngIf="iconTemplate">
    <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
  </ng-container>
</div>
<div class="graph-content">
  <div class="graph-name">
    {{ name }}

    <ng-content select="graph-name-extra"></ng-content>

    <ppl-icon svgIcon="search-form"
              width="14px"
              height="14px"></ppl-icon>
  </div>
  <ng-content select="graph-content-extra"></ng-content>
</div>
<div class="graph-controls">
  <ppl-graph-toggle #toggle
                    [value]="isToggled"
                    [disabled]="disabled"
                    (valueChange)="onToggle($event)"
                    [attr.title]="''"
                    (click)="$event.stopPropagation()"></ppl-graph-toggle>
</div>