import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input
,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-dialog-overlay',
  templateUrl: './dialog-overlay.component.html',
  styleUrls: ['./dialog-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogOverlayComponent implements OnInit {
  @Input() title?: string;

  @ContentChild('icon', { static: false }) icon: TemplateRef<any>;
  @ContentChild('content', { static: false }) content: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }
}
