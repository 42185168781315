<ppl-icon *ngIf="isActionEnabled()"
          [svgIcon]="action.icon"
          width="20px"
          height="20px"
          [pplPopover]="tooltip"
          [pplPopoverEvent]="action.tooltipEnabled ? 'hover' : null"
          (click)="actionClick.emit({ action: action, record: record }); $event.stopPropagation()"></ppl-icon>

<ng-template #tooltip>
  <ppl-popover-tooltip *ngIf="action.tooltipEnabled">
    {{action.text}}
  </ppl-popover-tooltip>
</ng-template>