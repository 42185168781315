<div class="dialog-title-container"
     [style.max-width]="maxWidthValue"
     [style.width]="widthValue">
  <div class="dialog-title-text">{{title}}</div>
  <ppl-badge *ngIf="newBadge"
             color="#50a98a"
             i18n="@@NEW">NEW</ppl-badge>

  <a *ngIf="helpLink"
     [href]="helpLink"
     target="_blank"
     class="dialog-title-container-help"
     pplButton
     pplButton7="danger"
     tabindex="-1">
    <ppl-icon svgIcon="help-topnavigation"
              width="16px"
              height="16px"></ppl-icon>
  </a>

  <button class="dialog-title-container-close"
          tabindex="-1"
          (click)="onCancel()">
    <ppl-icon *ngIf="ngUiTheme === 'pipeliner'"
              svgIcon="closeline-white"
              width="20px"
              height="20px"></ppl-icon>

    <ppl-icon *ngIf="ngUiTheme === 'fabric'"
              svgIcon="close-gray-sm"
              width="16px"
              height="16px"></ppl-icon>

    <ppl-icon *ngIf="ngUiTheme === 'material'"
              svgIcon="close-24px"
              width="24px"
              height="24px"></ppl-icon>
  </button>
</div>

<ng-container *ngIf="formGroup">
  <div class="dialog-content-container"
       [style.min-height]="minHeightValue"
       [style.max-width]="maxWidthValue"
       [style.max-height]="maxHeightValue"
       [style.width]="widthValue"
       [style.height]="heightValue">
    <ppl-form-group [formGroup]="formGroup"
                    [formActions]="formActions"
                    [noPadding]="formGroupNoPadding"
                    (cancel)="onCancel()"
                    (submit)="onSubmit()">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ppl-form-group>
  </div>

  <div class="dialog-actions-container"
       [style.max-width]="maxWidthValue"
       [style.display]="actionsVisible ? 'flex' : 'none'">
    <ng-container *ngIf="actionsMiscTemplate">
      <ng-template [ngTemplateOutlet]="actionsMiscTemplate"></ng-template>
    </ng-container>

    <ppl-form-actions #formActions
                      [actionLabel]="actionLabel"
                      [actionVisible]="actionVisible"
                      [actionDisabled]="actionDisabled"
                      [actionTooltip]="actionTooltip"
                      [actionButtonType]="actionButtonType"
                      [cancelLabel]="cancelLabel"
                      [cancelVisible]="cancelVisible"
                      [cancelDisabled]="cancelDisabled"
                      [saving]="loading">
      <ng-template #actions
                   let-action="action"
                   let-cancel="cancel">
        <ng-container *ngIf="actionsTemplate">
          <ng-template [ngTemplateOutlet]="actionsTemplate"
                       [ngTemplateOutletContext]="{ action: action, cancel: cancel }"></ng-template>
        </ng-container>
        <ng-container *ngIf="!actionsTemplate">
          <ng-template [ngTemplateOutlet]="action"></ng-template>
          <ng-template [ngTemplateOutlet]="cancel"></ng-template>
        </ng-container>
      </ng-template>
    </ppl-form-actions>
  </div>
</ng-container>

<ng-container *ngIf="!formGroup">
  <div class="dialog-content-container"
       [style.min-height]="minHeightValue"
       [style.max-width]="maxWidthValue"
       [style.max-height]="maxHeightValue"
       [style.width]="widthValue"
       [style.height]="heightValue">
    <ng-container *ngIf="contentTemplate">
      <ng-template [ngTemplateOutlet]="contentTemplate"
                   [ngTemplateOutletContext]="{ formActions: formActionsNoFormGroup }"></ng-template>
    </ng-container>
    <ng-container *ngIf="!contentTemplate">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>
  </div>
  <div class="dialog-actions-container"
       [style.max-width]="maxWidthValue"
       [style.display]="actionsVisible ? 'flex' : 'none'">
    <ng-container *ngIf="actionsMiscTemplate">
      <ng-template [ngTemplateOutlet]="actionsMiscTemplate"></ng-template>
    </ng-container>

    <ppl-form-actions #formActionsNoFormGroup
                      [actionLabel]="actionLabel"
                      [actionVisible]="actionVisible"
                      [actionDisabled]="actionDisabled"
                      [actionTooltip]="actionTooltip"
                      [actionButtonType]="actionButtonType"
                      [cancelLabel]="cancelLabel"
                      [cancelVisible]="cancelVisible"
                      [cancelDisabled]="cancelDisabled"
                      [saving]="loading"
                      (cancel)="onCancel()"
                      (save)="onSubmit()">
      <ng-template #actions
                   let-action="action"
                   let-cancel="cancel">
        <ng-container *ngIf="actionsTemplate">
          <ng-template [ngTemplateOutlet]="actionsTemplate"
                       [ngTemplateOutletContext]="{ action: action, cancel: cancel }"></ng-template>
        </ng-container>
        <ng-container *ngIf="!actionsTemplate">
          <ng-template [ngTemplateOutlet]="action"></ng-template>
          <ng-template [ngTemplateOutlet]="cancel"></ng-template>
        </ng-container>
      </ng-template>
    </ppl-form-actions>
  </div>
</ng-container>

<div *ngIf="!actionsVisible"
     class="dialog-actions-border"></div>

<ppl-loading-overlay *ngIf="loading"
                     [visible]="true"
                     [inverse]="true"></ppl-loading-overlay>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
