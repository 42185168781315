import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import type { ID, Query, Space } from '@ppl/graphql-user-api';
import { patchClient } from '@ppl/utils';
import * as Sentry from '@sentry/browser';
import { AsyncState } from '@ppl/store';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import type { Observable} from 'rxjs';
import { of, throwError, timer } from 'rxjs';
import { map, mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators';
import { getAuthSsoToken, getSpaceById } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { getApolloLink } from '../utils/api-client.utils';
import { gqlSpaceById, gqlUserProfile } from './auth.graphql';
import { ErrorHandlerService } from './error-handler.service';
import { TokenService } from './token/token.service';
import { isIE11 } from '@ppl/ui/form';

const USER_ENDPOINT_NAME = 'userEndpoint';
export const API_USER_GQL_URL = new InjectionToken<string>('ApiUserGqlUrl');
export const APP_URL = new InjectionToken<string>('AppUrl');
export const CUSTOMER_PORTAL_URL = new InjectionToken<string>('CustomerPortalUrl');
export const PIPELINER_TOKEN = new InjectionToken<string>('PipelinerToken');
export const SSO_URL = new InjectionToken<string>('SsoUrl');

@Injectable()
export class AuthService {

  get gqlClient() {
    return this.getGraphqlClient();
  }

  constructor(
    @Inject(API_USER_GQL_URL) private apiUserGqlUrl: string,
    @Inject(APP_URL) private appUrl: string,
    @Inject(PIPELINER_TOKEN) private pipelinerToken: string,
    @Inject(SSO_URL) private ssoUrl: string,
    private store: AuthStore,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private errorHandlerService: ErrorHandlerService,
    @Optional() private tokenService: TokenService
  ) {
  }

  init() {
    this.store.dispatch('Auth_FetchUser', { state: AsyncState.FETCHING });

    const authQuery = (doNotHandleUnauthorized = true) => {
      return this.gqlClient.query<Query>({
        query: gqlUserProfile,
        fetchPolicy: 'no-cache',
        context: {
          doNotHandleUnauthorized
        }
      });
    };

    // This retry stuff is just because MySQL DB replication is slow
    // and API in sydney data center might not have synced session data
    let retries = 0;
    return of(true).pipe(
      switchMap(() => {
        return authQuery(retries !== 2);
      }),
      retryWhen((errors) => {
        return errors.pipe(
          mergeMap((error) => {
            retries++;
            if (retries === 3) {
              return throwError(error);
            }
            return timer(2000);
          })
        );
      }),
      tap(
        ({ data }) => {
          this.store.dispatch('Auth_FetchUser', { state: AsyncState.FETCHED, value: data });
          Sentry.configureScope(scope => {
            scope.setUser({
              id: data.profile.id,
              email: data.profile.email
            });

            try {
              if (isIE11()) {
                throw new Error('IE11 browser detected!');
              }
            } catch (error) {
              Sentry.captureException(error);
            }
          });
        },
        ({ error }) => this.store.dispatch('Auth_FetchUser', { state: AsyncState.ERROR, error: error })
      ),
      map(({ data }) => data.profile.id)
    );
  }

  getSpace(spaceId: ID): Observable<Space | null> {
    const space = this.store.get(getSpaceById(spaceId));
    return space
      ? of(space)
      : this.fetchSpace(spaceId);
  }

  getApiUserGqlUrl() {
    const queryParamsString = location.search.substring(1);
    if (!queryParamsString) {
      return this.apiUserGqlUrl;
    }

    let queryParams = null;
    try {
      queryParams = JSON.parse(`{"${decodeURIComponent(queryParamsString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
    } catch (e) {
    }

    return (queryParams && queryParams.apiUserGqlUrl) ? queryParams.apiUserGqlUrl : this.apiUserGqlUrl;
  }

  getGraphqlClient() {
    if (!this.apollo.use(USER_ENDPOINT_NAME)) {
      const pipelinerToken = this.pipelinerToken || this.store.get(getAuthSsoToken);

      this.apollo.create({
        link: getApolloLink(this.httpLink, this.getApiUserGqlUrl(), this.errorHandlerService, pipelinerToken),
        cache: new InMemoryCache()
      }, USER_ENDPOINT_NAME);
    }

    return patchClient(this.apollo.use(USER_ENDPOINT_NAME));
  }

  signIn(options: SignInOptions = {}) {
    return this.tokenService.getToken().pipe(switchMap(token => {
      if (!token || options.queryForSpace) {
        if (options.checkOnly) {
          return of(false);
        }

        return this.tokenService.signIn({
          appUrl: this.appUrl,
          queryForSpace: options.queryForSpace,
          ssoUrl: this.ssoUrl
        });
      }

      return of(true);
    }));
  }

  signOut() {
    return this.tokenService.deleteToken().pipe(switchMap(() => {
      return this.tokenService.signOut({
        appUrl: this.appUrl,
        ssoUrl: this.ssoUrl
      });
    }));
  }

  private fetchSpace(spaceId: ID) {
    return this.gqlClient.query<Query>({
      query: gqlSpaceById,
      variables: {
        spaceId
      }
    }).pipe(
      map(({ data }) => data.space)
    );
  }
}

export interface SignInOptions {
  checkOnly?: boolean;
  queryForSpace?: boolean;
}
