import { escapeRegExp } from './regex.utils';

export function findStringInText(value: string, text: string) {
  if (value) {
    const regex = new RegExp(escapeRegExp(value), 'gi');
    const matches: { match: boolean, value: string }[] = [];
    let previousIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const token = text.slice(previousIndex, match.index);
      if (token) {
        matches.push({ match: false, value: token });
      }
      matches.push({ match: true, value: text.slice(match.index, regex.lastIndex) });
      previousIndex = regex.lastIndex;
    }

    if (previousIndex < text.length) {
      matches.push({ match: false, value: text.slice(previousIndex) });
    }

    return matches;
  } else {
    return [{ match: false, value: text }];
  }
}
