import type { OnInit  } from '@angular/core';
import { Directive , ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pplIconButton]'
})
export class PplIconButtonDirective implements OnInit {
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'margin-right', '5px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'vertical-align', 'top');
  }
}