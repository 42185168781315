<div class="container demo-content">
  <h2>Graph Item</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-graph-item</h4>

  <div>@Input() <b>name</b>: string</div>
  <div>@Input() <b>disabled</b>: boolean</div>
  <div>@Input() <b>isToggled</b>: boolean</div>
  <div>@Input() <b>title?</b>: string</div>
  <div>@Input() <b>clickable</b>: boolean</div>

  <br>
  <div>@Output() <b>toggled</b></div>
  <div>@Output() <b>clicked</b></div>
  <br>

  <br>
  <br>

  <h4>Example</h4>
  <ppl-graph-item name="First Graph Item"></ppl-graph-item>

  <br>
  <br>

  <h4>Example without icon</h4>
  <ppl-graph-item name="Another Graph Item"
                  title="Show all records"></ppl-graph-item>
  <br>
  <br>

  <h4>Custom content & click & toggle</h4>
  <ppl-graph-item name="Custom Graph Item"
                  title="Show all records"
                  (clicked)="onGraphItemClick()"
                  (toggled)="onGraphItemToggle()">
    <ng-template #iconTemplate>
      <ppl-avatar entity="product"
                  [size]="32"></ppl-avatar>
    </ng-template>
    <div ngProjectAs="graph-content-extra"
         class="custom-content">$36,000.00</div>
  </ppl-graph-item>

  <br>
  <br>

  <h4>Disabled</h4>
  <ppl-graph-item name="Disabled Graph Item"
                  [disabled]="true"
                  (clicked)="onGraphItemClick()">
    <ng-template #iconTemplate>
      <ppl-avatar entity="product"
                  [size]="32"></ppl-avatar>
    </ng-template>
  </ppl-graph-item>

  <br>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>