import type { HttpErrorResponse } from '@angular/common/http';
import type { ApolloError } from '@apollo/client/core';
import type { GraphQLError } from 'graphql';

export function getGraphQLErrors(error: ApolloError) {
  const errors = error.graphQLErrors && error.graphQLErrors.length
    ? error.graphQLErrors
    : null;
  return errors;
}

export class UserAlertError extends Error {
  constructor(public readonly message: string) {
    super(message);
    Object.setPrototypeOf(this, UserAlertError.prototype);
  }
}

// custom error, not reported to user
export class CustomError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

/**
 * this is a specific error response composition, in which case we can say for sure this is a write lock error
 * @param error
 */
export function hasWriteLockError(error: { networkError: HttpErrorResponse; }) {
  return error?.networkError?.error?.errorcode === 500 && error?.networkError?.error?.status === 400;
}

/**
 * this is a specific error response composition, in which case we can say for sure this is a write lock error
 * @param error
 */
export function getWriteLockErrorOperationOrigin(error: { networkError: HttpErrorResponse; }) {
  return error?.networkError?.error?.message?.operation_origin || null;
}

/**
 * checks for recalculation error
 * @param error
 */
export function hasRecalculationError(error: { networkError: HttpErrorResponse; }) {
  return error
    && error.networkError
    && error.networkError.error
    && error.networkError.error.errorcode === 501
    && error.networkError.error.status === 400;
}

/**
 * retrieves recalculation error
 * @param error
 */
export function getRecalculationError(error: { networkError: HttpErrorResponse; }): RecalculationError | null {
  return error
    && error.networkError
    && error.networkError.error
    && error.networkError.error.message
    ? error.networkError.error.message
    : null;
}

export interface RecalculationError {
  operation_name: string;
  trx_id: string;
  log: string;
  progress: string;
}

export function hasErrorCode(context: { graphQLErrors?: GraphQLError[]; }, errorCode: number) {
  if (!context || !context.graphQLErrors || !context.graphQLErrors.length) {
    return false;
  } else {
    return !!context.graphQLErrors
      .filter((error: any) => !!error.api_error)
      .map((error: any) => error.api_error as ApiErrorBase)
      .find(error => error.code === errorCode);
  }
}


export interface ApiErrorBase {
  code: number;
  name: string;
  message: string;
}
