import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '@ppl/ui/icon';
import { ScreenMessageComponent } from './screen-message.component';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule
  ],
  declarations: [
    ScreenMessageComponent
  ],
  exports: [
    ScreenMessageComponent
  ],
  providers: []
})
export class PplScreenMessageModule { }

export * from './screen-message.component';

