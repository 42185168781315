import { getNameFormatted } from '../domain/functions/name';
import { getUserSettingsNameFormat } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { Pipe } from '@angular/core';
import { NameFormat } from '@ppl/graphql-user-api';
import { unsubscribe } from '@ppl/utils';
import type { OnDestroy, PipeTransform } from '@angular/core';
import type { Subscription } from 'rxjs';

@Pipe({
  name: 'pplName',
  pure: false // Cannot use purity for this pipe in virtual scroll (because it reuses objects and thus pipe does not update)
})
export class PplNamePipe implements PipeTransform, OnDestroy {
  nameFormat: NameFormat;
  nameFormatSubscription: Subscription;

  constructor(private store: AuthStore) {
    this.nameFormatSubscription = this.store.select(getUserSettingsNameFormat).subscribe(nameFormat => this.nameFormat = nameFormat);
  }

  ngOnDestroy(): void {
    unsubscribe(this.nameFormatSubscription);
  }

  transform(value: EntityNameData) {
    return getEntityNameFormatted(value, this.nameFormat);
  }
}

export function getEntityNameFormatted(value: EntityNameData, nameFormat: NameFormat = NameFormat.FirstMiddleLast) {
  if (value) {
    const formattedName = getNameFormatted(value, nameFormat);

    if (value.__typename === 'ClientEntity') {
      const clientFormattedName = formattedName || value.email;

      if (value.isDeleted) {
        return clientFormattedName + ' (Inactive)';
      }

      return clientFormattedName;
    }

    return formattedName;
  }

  return '';
}

export interface EntityNameData {
  title?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string | null;
  isDeleted?: boolean;
  __typename?: string;
}
