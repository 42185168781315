import { PplUiIntl } from '../ppl-ui-intl';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';


@Component({
  selector: 'ppl-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplSearchComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplSearchComponent implements OnInit {

  @Input() value = '';
  @Input() alwaysDisplayRemoveButton = false;
  @Input() placeholder: string | null = null; // null is default Search placeholder (translated)
  @Input() focusOnMount = false;
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() valueRemove = new EventEmitter<null>();
  @Output() valueFocus = new EventEmitter();
  @Output() valueBlur = new EventEmitter();
  @Output() keyEscapePressed = new EventEmitter<null>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  get inputValue() {
    return this.value === null ? '' : this.value;
  }

  constructor(
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES,
    private intl: PplUiIntl,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.focusOnMount) {
      this.searchInput.nativeElement.focus();
    }

    if (this.placeholder === null) {
      this.placeholder = this.intl.search;
    }
  }

  onChange(event: KeyboardEvent) {
    this.valueChange.emit((event.target as HTMLInputElement).value);
  }

  clearValue() {
    this.valueChange.emit('');

    if (this.alwaysDisplayRemoveButton) {
      this.valueRemove.emit();
    }
  }

  onKeyEscapePressed() {
    this.keyEscapePressed.emit(null);
  }
}
