<label class="ppl-radio" [class.disabled]="disabled">
  <input #input type="radio"
         [checked]="checked"
         [disabled]="disabled"
         [name]="name"
         [required]="required"
         (change)="_onInputChange($event)"
         (click)="_onInputClick($event)">
  <span class="ppl-radio__check"
        [attr.tabindex]="disabled ? null : 0"
        [pplInputAutofill]="autofill"
        (keydown)="onRadioButtonKeyDown($event)"
        (focus)="checkFocus = true"
        (blur)="checkFocus = false"></span>
  <ng-content></ng-content>
</label>
