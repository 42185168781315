<div class="container demo-content">
  <h2>Autocomplete</h2>

  <ppl-input-container label="Autocomplete Base">
    <ppl-autocomplete [options]="[{ label: 'Option 1', value: '1' }, { label: 'Option 2', value: '2' }]"
                      [value]="value1"
                      (valueChange)="value1 = $event"></ppl-autocomplete>
  </ppl-input-container>

  <br />

  <ppl-input-container label="Autocomplete Picker (static + template + categories)">
    <ppl-autocomplete-picker [options]="pickerStaticOptions"
                             [categories]="pickerStaticCategories"
                             [optionTemplate]="optionTemplate"
                             [optionTemplateRowHeight]="40"
                             (optionSelect)="onPickerOptionSelect($event)"></ppl-autocomplete-picker>

    <ng-template #optionTemplate
                 let-option>
      <div [ngStyle]="{ alignItems: 'center', display: 'flex', height: '40px', padding: '0 5px' }">
        {{option.label}}
      </div>
    </ng-template>
  </ppl-input-container>

  <ppl-input-container label="Autocomplete Picker (dynamic)">
    <ppl-autocomplete-picker [options]="pickerDynamicOptions$ | async"
                             [displayOptionsLoading]="pickerDynamicDisplayLoading"
                             (optionSelect)="onPickerOptionSelect($event)"
                             (optionsRequest)="onPickerOptionsRequest($event)"></ppl-autocomplete-picker>
  </ppl-input-container>

  <br />

  <ppl-input-container label="Autocomplete Select (static)">
    <ppl-autocomplete-select [options]="selectStaticOptions"
                             [value]="selectStaticValue"
                             (valueChange)="selectStaticValue = $event"></ppl-autocomplete-select>
  </ppl-input-container>

  <ppl-input-container label="Autocomplete Select (value loading)">
    <ppl-autocomplete-select [displayValueLoading]="true"></ppl-autocomplete-select>
  </ppl-input-container>

  <ppl-input-container label="Autocomplete Select (disabled)">
    <ppl-autocomplete-select [disabled]="true"></ppl-autocomplete-select>
  </ppl-input-container>
</div>