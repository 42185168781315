<div class="container demo-content">
  <h2>Link</h2>
  <div>PplLink <small>[pplLink]</small></div>

  <br>

  <h4>Demo</h4>

  <ppl-input-container label="Anchor">
    <a pplLink
       href="https://google.com"
       target="_blank">Anchor</a>
  </ppl-input-container>

  <ppl-input-container label="Span">
    <span pplLink
          (click)="alert('Clicked!')">Span Visual</span>
  </ppl-input-container>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
