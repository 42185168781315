<ppl-dialog *ngIf="data.type === 'default'"
            title="Title"
            width="400px">
  Content
</ppl-dialog>

<ppl-dialog-overlay *ngIf="data.type === 'overlay'"
                    title="The network connection is lost!">
  <ng-template #icon>
    <ppl-icon svgIcon="pipeliner-switcher"
              width="96px"
              height="96px"></ppl-icon>
  </ng-template>
  <ng-template #content>
    <p>
      Check your network settings and try again
    </p>
    <p>
      <button pplButton
              pplButton3
              (click)="dialogRef.close()">
        Close
      </button>
    </p>
  </ng-template>
</ppl-dialog-overlay>
