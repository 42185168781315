import { PplAddressbookPictureComponent } from './addressbook-picture.component';
import { PplImageEditorComponent } from './image-editor/image-editor.component';
import { ImageUrlComponent } from './image-url/image-url.component';
import { PplButtonModule } from '../button';
import { PplDialogModule } from '../dialog';
import { PplDropZoneModule } from '../drop-zone';
import { PplFormModule } from '../form';
import { PplIconModule } from '../icon';
import { PplInfoBoxModule } from '../info-box';
import { PplInputModule } from '../input';
import { PplMenuModule } from '../menu';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        PplIconModule,
        PplButtonModule,
        PplPopoverModule,
        PplPopoverDropdownModule,
        PplDialogModule,
        PplMenuModule,
        PplDropZoneModule,
        PplInfoBoxModule,
        PplFormModule,
        PplInputModule,
        ReactiveFormsModule
    ],
    declarations: [
        PplAddressbookPictureComponent,
        PplImageEditorComponent,
        ImageUrlComponent
    ],
    exports: [
        PplAddressbookPictureComponent
    ]
})
export class PplAddressbookModule {
}
