<ppl-dialog width="626px"
            height="655px"
            title="Add new VOIP Number"
            i18n-title="@@Add_new_VOIP_Number"
            [actionVisible]="false"
            [cancelLabel]="cancelLabel"
            (cancel)="onClose()">
  <div class="content">
    <div class="header">
      <ppl-steps [steps]="steps"
                 [activeStepIndex]="activeStepIndex"></ppl-steps>
    </div>
    <!-- STEP: CHOOSE NUMBER -->
    <div class="container"
         [ngSwitch]="step">
      <div *ngSwitchCase="CallingPurchaseWizardStepsEnum.ChooseNumber"
           class="step"
           [formGroup]="chooseNumberForm">
        <ppl-ui-toolbar>
          <div class="country-search">
            <ppl-select class="country-select"
                        formControlName="countryCode"
                        [options]="countriesOptions"></ppl-select>
            <button pplButton
                    pplButton1
                    (click)="onSearchPhone()">
              <ppl-icon pplIconButton
                        svgIcon="search"
                        width="18px"
                        height="18px"></ppl-icon>
              <ng-container i18n="@@Search">Search</ng-container>
            </button>
          </div>
          <ppl-input-container class="phone-search"
                               [hasError]="chooseNumberFormPhonePatternControl.errors">
            <ppl-search formControlName="phonePattern"
                        placeholder="Filter by digits..."
                        i18n-placeholder="@@Filter_by_digits"
                        pplPopover
                        [pplPopoverDisabled]="!chooseNumberFormPhonePatternControl.errors"
                        [pplPopoverTooltip]="phonePatternErrorTooltipTemplate"
                        pplPopoverTooltipType="error"></ppl-search>
          </ppl-input-container>
          <ng-template #phonePatternErrorTooltipTemplate>
            <ppl-form-control-errors [errors]="chooseNumberFormPhonePatternControl.errors"></ppl-form-control-errors>
          </ng-template>
        </ppl-ui-toolbar>
        <ng-container *ngIf="(loading$ | async) === false ; else phoneNumberLoading">
          <ng-container *ngIf="gridData$ | async; let gridData; else emptyGrid">
            <ppl-calling-phone-grid *ngIf="gridData.length; else noResults"
                                    [columns]="phoneColumns"
                                    [selection]="gridSelection$ | async"
                                    [records]="gridData"
                                    [hideActions]="true"
                                    [displayColumnSelector]="false"
                                    [phoneNumberColumnWithLocality]="true"
                                    (selectionChange)="onGridSelectionChange($event)">
            </ppl-calling-phone-grid>
          </ng-container>
          <ng-template #emptyGrid>
            <ppl-screen-message icon="search-phantom"
                                label="Select a region and search"
                                i18n-label="@@Select_a_region_and_search"
                                description="Click the search button to list available phone numbers"
                                i18n-description="@@Click_the_search_button_to_list_available_phone_numbers"></ppl-screen-message>
          </ng-template>
          <ng-template #noResults>
            <ppl-screen-message icon="search-phantom"
                                label="No Results Found"
                                i18n-label="No_Results_Found"
                                description="We couldn't find any numbers that matched your search criteria"
                                i18n-description="@@We_couldn't_find_any_numbers_that_matched_your_search_criteria"></ppl-screen-message>
          </ng-template>
        </ng-container>
        <ng-template #phoneNumberLoading>
          <ppl-loading-overlay [large]="true"
                               text="Searching..."
                               i18n-text="@@Searching..."></ppl-loading-overlay>
        </ng-template>
      </div>

      <div *ngSwitchCase="CallingPurchaseWizardStepsEnum.Configure">
        <ppl-info-box type="warning"
                      text="You can always edit this configuration in User setting"
                      i18n-text="@@You_can_always_edit_this_configuration_in_User_setting"></ppl-info-box>
        <ppl-info-box type="warning"
                      text="After purchase, this number will be billed by Pipeliner. Our sales team will contact your Billing Manager."
                      i18n-text="@@After_purchase,_this_number_will_be_billed_by_Pipeliner._Our_sales_team_will_contact_your_Billing_Manager."></ppl-info-box>
        <ppl-form-group [formGroup]="form"
                        [noPadding]="true">
          <ppl-calling-phone-edit formControlName="phoneConfig"
                                  [phone]="selectedPhone"></ppl-calling-phone-edit>
        </ppl-form-group>
      </div>

      <div *ngSwitchCase="CallingPurchaseWizardStepsEnum.PurchaseAndFinish"
           class="purchase-screen">
        <ng-container *ngIf="(loading$ | async) === false; else purchaseLoading">
          <div *ngIf="purchaseScreenType === CallingPurchaseWizardPurchaseScreenTypeEnum.Success"
               class="purchase-screen-content">
            <ppl-icon svgIcon="ring-thumbs-up-green"
                      width="80px"
                      height="80px"></ppl-icon>
            <div class="purchase-screen-label"
                 i18n="@@Purchase_Success">
              Purchase Success
            </div>
            <div class="purchase-screen-description"
                 i18n="@@You_have_successfully_purchased_VOIP_Phone_number_for_Messaging._You_can_use_this_number_also_for_Outbound_calling_in_Pipeliner">
              You have successfully purchased VOIP Phone number for Messaging. You can use this number also for Outbound calling in Pipeliner
            </div>
          </div>
          <div *ngIf="purchaseScreenType === CallingPurchaseWizardPurchaseScreenTypeEnum.Error"
               class="purchase-screen-content">
            <ppl-icon svgIcon="x-mark-ring-failed"
                      width="80px"
                      height="80px"></ppl-icon>
            <div class="purchase-screen-label"
                 i18n="@@Something_bad_happened">
              Something bad happened
            </div>
            <div class="purchase-screen-description">
              {{ purchaseScreenErrorMessage }}
            </div>
          </div>
        </ng-container>
        <ng-template #purchaseLoading>
          <ppl-loading-overlay [large]="true"
                               text="Purchasing..."
                               i18n-text="@@Purchasing..."></ppl-loading-overlay>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #actionsMisc>
    <button *ngIf="showBackButton"
            pplButton
            pplButton2
            pplButtonDialog
            (click)="onBack()"
            i18n="@@Back">Back</button>

  </ng-template>

  <ng-template #actions
               let-cancel="cancel">
    <button *ngIf="showNextButton"
            pplButton
            pplButton7="info"
            pplButtonDialog
            [disabled]="nextButtonDisabled"
            (click)="onNext()"
            i18n="@@Next">Next</button>

    <button *ngIf="showPurchaseButton"
            pplButton
            pplButton7="info"
            pplButtonDialog
            [disabled]="purchaseButtonDisabled"
            (click)="onPurchase()"
            i18n="@@Purchase_&_Finish">Purchase & Finish</button>

    <ng-template [ngTemplateOutlet]="cancel"></ng-template>

  </ng-template>
</ppl-dialog>
