import { NgZone , Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthStore } from '../../store/auth.state';
import type { TokenService, TokenSignInOptions, TokenSignOutOptions } from './token.service';
import { createLoginToken, createLogoutToken } from './token.utils';

@Injectable()
export class GoogleDocsTokenService implements TokenService {

  constructor(
    private store: AuthStore,
    private ngZone: NgZone
  ) {}

  signIn(options: TokenSignInOptions) {
    return this.callHost<{ returnUrl: string }>('getAuthSignInData').pipe(switchMap(({ returnUrl }) => {
      const ssoToken = createLoginToken({
        appUrl: options.appUrl,
        queryForSpace: options.queryForSpace,
        returnUrl
      });

      this.openWindow(`${options.ssoUrl}/sso/${ssoToken}`);

      return this.waitForResult();
    }));
  }

  signOut(options: TokenSignOutOptions) {
    return this.callHost<{ appSessionId: string, returnUrl: string }>('getAuthSignOutData').pipe(switchMap(({ appSessionId, returnUrl }) => {
      const ssoToken = createLogoutToken({
        appSessionId,
        appUrl: options.appUrl,
        returnUrl
      });

      this.openWindow(`${options.ssoUrl}/sso_logout/${ssoToken}`);

      return this.waitForResult();
    }));
  }

  getToken() {
    return this.callHost<string>('getAuthToken').pipe(tap(token => {
      if (token) {
        this.store.dispatch('Auth_UpdateSsoToken', token);
      }
    })).pipe(map(token => {
      return token || null;
    }));
  }

  setToken(token: string) {
    return this.callHost('setAuthToken', token).pipe(map(() => null));
  }

  deleteToken() {
    return this.callHost('deleteAuthToken').pipe(tap(() => {
      this.store.dispatch('Auth_UpdateSsoToken', null);
    }), map(() => null));
  }

  reloadPage() {
    this.callHost('reloadPage').subscribe();
  }

  private callHost<T>(methodName: string, ...callArgs: any[]) {
    const result$ = new Subject<T>();

    (window as any).google.script.run.withSuccessHandler(value => {
      this.ngZone.run(() => {
        result$.next(value);
      });
    }).withFailureHandler(error => {
      this.ngZone.run(() => {
        result$.error(error);
      });
    })[methodName](...callArgs);

    return result$.asObservable();
  }

  private openWindow(url: string) {
    const parentLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const parentTop = window.screenTop ? window.screenTop : window.screenY;

    const windowSize = 600;
    const windowLeft = parentLeft + (window.outerWidth / 2) - (windowSize / 2);
    const windowTop = parentTop + (window.outerHeight / 2) - (windowSize / 2);

    // eslint:disable-next-line:max-line-length
    window.open(url, '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, height=${windowSize},width=${windowSize},left=${windowLeft},top=${windowTop}`);
  }

  private waitForResult() {
    const result$ = new Subject<boolean>();

    window.addEventListener('message', event => {
      if (event.data.type === 'pipeliner-auth-success') {
        result$.next(true);
      } else if (event.data.type === 'pipeliner-auth-error') {
        result$.error(null);
      }
    });

    return result$;
  }

}
