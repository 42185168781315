import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'ppl-radio-button-demo',
  templateUrl: './radio-button-demo.component.html',
  styleUrls: ['./radio-button-demo.component.scss']
})
export class RadioButtonDemoComponent implements OnInit {
  value = 3;

  code = `
  <ppl-radio-group [value]="value" (valueChange)="value = $event.value" label="Label">
    <br>
    <ppl-radio-button [value]="1">Radio 1</ppl-radio-button>
    <br>
    <ppl-radio-button [value]="2" [disabled]="true">Radio 2</ppl-radio-button>
    <br>
    <ppl-radio-button [value]="3">Radio 3</ppl-radio-button>
    <br>
    <ppl-radio-button [value]="4">Radio 4</ppl-radio-button>
  </ppl-radio-group>
`;

  constructor() {
  }

  ngOnInit() {
  }

}
