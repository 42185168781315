import type {
  OnDestroy,
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef} from '@angular/core';
import type { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

const outerContainerMarginPx = 3;
const accordionEjectionTimeMs = 250;
let accordionIterator = 0;

@Component({
  selector: 'ppl-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplAccordionComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() icon: string;
  @Input() accordionId: string;
  @Input() showContent = true;
  @Input() loading = false;
  @Output() openChange = new EventEmitter<boolean>();
  @Output() contentEjected = new EventEmitter<null>();
  @Output() contentHidden = new EventEmitter<null>();
  @HostBinding('class.hover') hover = false;
  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;

  resizeObserver: ResizeObserver;
  open = false;
  contentVisible = false;

  contentOpenedAction$ = new Subject();
  contentHiddenAction$ = new Subject();
  contentCompletlyEjected$ = new Subject<boolean>();
  contentCompletlyHidden$ = new Subject<boolean>();
  contentOpenedActionSubscription: Subscription;
  contentHiddenActionSubscription: Subscription;
  contentCompletlyEjectedSubscription: Subscription;
  contentCompletlyHiddenSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (!this.accordionId) {
      this.accordionId = (++accordionIterator).toString();
    }
    this.resizeObserver = new ResizeObserver(() => {
      this.changeDetectorRef.detectChanges();
    });
    this.resizeObserver.observe(this.contentContainer.nativeElement);
    this.contentCompletlyEjectedSubscription = this.contentCompletlyEjected$.subscribe(() => this.contentEjected.emit(null));
    this.contentCompletlyHiddenSubscription = this.contentCompletlyHidden$.subscribe(() => {
      this.contentHidden.emit(null);
      this.contentVisible = false;
    });
    this.subscribeToContentOpenAction();
    this.subscribeToContentHiddenAction();
  }

  get maxHeightPx() {
    if (!this.showContent) {
      return 0;
    } else if (this.open && this.contentContainer && this.contentContainer.nativeElement) {
      const element: HTMLElement = this.contentContainer.nativeElement;
      return element.offsetHeight === 0 ? 0 : element.offsetHeight + outerContainerMarginPx;
    } else {
      return 0;
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
    this.unSubscribeToContentOpenAction();
    this.unSubscribeToContentHiddenAction();
    if (this.contentCompletlyEjectedSubscription) {
      this.contentCompletlyEjectedSubscription.unsubscribe();
    }
    if (this.contentCompletlyHiddenSubscription) {
      this.contentCompletlyHiddenSubscription.unsubscribe();
    }
  }

  onHoverChange($event: boolean) {
    if (this.showContent) {
      this.hover = $event;
    }
  }

  toggleOpen() {
    if (!this.showContent) {
      return;
    }
    this.open = !this.open;

    if (this.open) {
      this.contentOpenedAction$.next();
      this.unSubscribeToContentHiddenAction();
      this.subscribeToContentHiddenAction();
      this.contentVisible = true;
    } else {
      this.contentHiddenAction$.next();
      this.unSubscribeToContentOpenAction();
      this.contentCompletlyEjected$.next(false);
      this.subscribeToContentOpenAction();
    }
    this.changeDetectorRef.detectChanges();
    this.openChange.emit(this.open);
  }

  private unSubscribeToContentOpenAction() {
    if (this.contentOpenedActionSubscription) {
      this.contentOpenedActionSubscription.unsubscribe();
    }
  }

  private unSubscribeToContentHiddenAction() {
    if (this.contentHiddenActionSubscription) {
      this.contentHiddenActionSubscription.unsubscribe();
    }
  }

  private subscribeToContentOpenAction() {
    this.contentOpenedActionSubscription = this.contentOpenedAction$.pipe(delay(accordionEjectionTimeMs)).subscribe(() => this.contentCompletlyEjected$.next(true));
  }

  private subscribeToContentHiddenAction() {
    this.contentHiddenActionSubscription = this.contentHiddenAction$.pipe(delay(accordionEjectionTimeMs)).subscribe(() => this.contentCompletlyHidden$.next(true));
  }
}
