export const TranslationsCountries = {
  Country_ZW: $localize`:@@Country_ZW:Zimbabwe`,
  Country_ZM: $localize`:@@Country_ZM:Zambia`,
  Country_ZA: $localize`:@@Country_ZA:South Africa`,
  Country_YT: $localize`:@@Country_YT:Mayotte`,
  Country_YE: $localize`:@@Country_YE:Yemen`,
  Country_WS: $localize`:@@Country_WS:Samoa`,
  Country_WF: $localize`:@@Country_WF:Wallis And Futuna`,
  Country_VU: $localize`:@@Country_VU:Vanuatu`,
  Country_VN: $localize`:@@Country_VN:Vietnam`,
  Country_VI: $localize`:@@Country_VI:Virgin Islands (U.s.)`,
  Country_VG: $localize`:@@Country_VG:Virgin Islands (Brit.)`,
  Country_VE: $localize`:@@Country_VE:Venezuela`,
  Country_VC: $localize`:@@Country_VC:St Vincent & Grenadines`,
  Country_VA: $localize`:@@Country_VA:Vatican City State`,
  Country_UZ: $localize`:@@Country_UZ:Uzbekistan`,
  Country_UY: $localize`:@@Country_UY:Uruguay`,
  Country_US: $localize`:@@Country_US:United States`,
  Country_UM: $localize`:@@Country_UM:U.S. Minor Outlying Islands`,
  Country_UG: $localize`:@@Country_UG:Uganda`,
  Country_UA: $localize`:@@Country_UA:Ukraine`,
  Country_TZ: $localize`:@@Country_TZ:Tanzania`,
  Country_TW: $localize`:@@Country_TW:Taiwan`,
  Country_TV: $localize`:@@Country_TV:Tuvalu`,
  Country_TT: $localize`:@@Country_TT:Trinidad And Tobago`,
  Country_TR: $localize`:@@Country_TR:Turkey`,
  Country_TO: $localize`:@@Country_TO:Tonga`,
  Country_TN: $localize`:@@Country_TN:Tunisia`,
  Country_TM: $localize`:@@Country_TM:Turkmenistan`,
  Country_TL: $localize`:@@Country_TL:Timor-Leste`,
  Country_TK: $localize`:@@Country_TK:Tokelau`,
  Country_TJ: $localize`:@@Country_TJ:Tajikistan`,
  Country_TH: $localize`:@@Country_TH:Thailand`,
  Country_TG: $localize`:@@Country_TG:Togo`,
  Country_TF: $localize`:@@Country_TF:French Southern Terr.`,
  Country_TD: $localize`:@@Country_TD:Chad`,
  Country_TC: $localize`:@@Country_TC:Turks and Caicos Islands`,
  Country_SZ: $localize`:@@Country_SZ:Swaziland`,
  Country_SY: $localize`:@@Country_SY:Syrian Arab Republic`,
  Country_SV: $localize`:@@Country_SV:El Salvador`,
  Country_ST: $localize`:@@Country_ST:São Tomé and Príncipe`,
  Country_SR: $localize`:@@Country_SR:Suriname`,
  Country_SO: $localize`:@@Country_SO:Somalia`,
  Country_SN: $localize`:@@Country_SN:Senegal`,
  Country_SM: $localize`:@@Country_SM:San Marino`,
  Country_SL: $localize`:@@Country_SL:Sierra Leone`,
  Country_SK: $localize`:@@Country_SK:Slovakia`,
  Country_SJ: $localize`:@@Country_SJ:Svalbard and Jan Mayen`,
  Country_SI: $localize`:@@Country_SI:Slovenia`,
  Country_SH: $localize`:@@Country_SH:Saint Helena`,
  Country_SG: $localize`:@@Country_SG:Singapore`,
  Country_SE: $localize`:@@Country_SE:Sweden`,
  Country_SD: $localize`:@@Country_SD:Sudan`,
  Country_SC: $localize`:@@Country_SC:Seychelles`,
  Country_SB: $localize`:@@Country_SB:Solomon Islands`,
  Country_SA: $localize`:@@Country_SA:Saudi Arabia`,
  Country_RW: $localize`:@@Country_RW:Rwanda`,
  Country_RU: $localize`:@@Country_RU:Russian Federation`,
  Country_RS: $localize`:@@Country_RS:Serbia`,
  Country_RO: $localize`:@@Country_RO:Romania`,
  Country_null: $localize`:@@Country_null:N/A`,
  Country_RE: $localize`:@@Country_RE:Reunion`,
  Country_QA: $localize`:@@Country_QA:Qatar`,
  Country_PY: $localize`:@@Country_PY:Paraguay`,
  Country_PW: $localize`:@@Country_PW:Palau`,
  Country_PT: $localize`:@@Country_PT:Portugal`,
  Country_PS: $localize`:@@Country_PS:Palestinian Territory`,
  Country_PR: $localize`:@@Country_PR:Puerto Rico`,
  Country_PN: $localize`:@@Country_PN:Pitcairn`,
  Country_PM: $localize`:@@Country_PM:Saint Pierre and Miquelon`,
  Country_PL: $localize`:@@Country_PL:Poland`,
  Country_PK: $localize`:@@Country_PK:Pakistan`,
  Country_PH: $localize`:@@Country_PH:Philippines`,
  Country_PG: $localize`:@@Country_PG:Papua New Guinea`,
  Country_PF: $localize`:@@Country_PF:French Polynesia`,
  Country_PE: $localize`:@@Country_PE:Peru`,
  Country_PA: $localize`:@@Country_PA:Panama`,
  Country_OM: $localize`:@@Country_OM:Oman`,
  Country_NZ: $localize`:@@Country_NZ:New Zealand`,
  Country_NU: $localize`:@@Country_NU:Niue`,
  Country_NR: $localize`:@@Country_NR:Nauru`,
  Country_NP: $localize`:@@Country_NP:Nepal`,
  Country_NO: $localize`:@@Country_NO:Norway`,
  Country_NL: $localize`:@@Country_NL:Netherlands`,
  Country_NI: $localize`:@@Country_NI:Nicaragua`,
  Country_NG: $localize`:@@Country_NG:Nigeria`,
  Country_NF: $localize`:@@Country_NF:Norfolk Island`,
  Country_NE: $localize`:@@Country_NE:Niger`,
  Country_NC: $localize`:@@Country_NC:New Caledonia`,
  Country_NA: $localize`:@@Country_NA:Namibia`,
  Country_MZ: $localize`:@@Country_MZ:Mozambique`,
  Country_MY: $localize`:@@Country_MY:Malaysia`,
  Country_MX: $localize`:@@Country_MX:Mexico`,
  Country_MW: $localize`:@@Country_MW:Malawi`,
  Country_MV: $localize`:@@Country_MV:Maldives`,
  Country_MU: $localize`:@@Country_MU:Mauritius`,
  Country_MT: $localize`:@@Country_MT:Malta`,
  Country_MS: $localize`:@@Country_MS:Montserrat`,
  Country_MR: $localize`:@@Country_MR:Mauritania`,
  Country_MQ: $localize`:@@Country_MQ:Martinique`,
  Country_MP: $localize`:@@Country_MP:Northern Mariana Islands`,
  Country_MO: $localize`:@@Country_MO:Macao`,
  Country_MN: $localize`:@@Country_MN:Mongolia`,
  Country_MM: $localize`:@@Country_MM:Myanmar`,
  Country_ML: $localize`:@@Country_ML:Mali`,
  Country_MK: $localize`:@@Country_MK:Macedonia`,
  Country_MH: $localize`:@@Country_MH:Marshall Islands`,
  Country_MG: $localize`:@@Country_MG:Madagascar`,
  Country_MF: $localize`:@@Country_MF:Saint Martin`,
  Country_ME: $localize`:@@Country_ME:Montenegro`,
  Country_MD: $localize`:@@Country_MD:Moldova`,
  Country_MC: $localize`:@@Country_MC:Monaco`,
  Country_MA: $localize`:@@Country_MA:Morocco`,
  Country_LY: $localize`:@@Country_LY:Libya`,
  Country_LV: $localize`:@@Country_LV:Latvia`,
  Country_LU: $localize`:@@Country_LU:Luxembourg`,
  Country_LT: $localize`:@@Country_LT:Lithuania`,
  Country_LS: $localize`:@@Country_LS:Lesotho`,
  Country_LR: $localize`:@@Country_LR:Liberia`,
  Country_LK: $localize`:@@Country_LK:Sri Lanka`,
  Country_LI: $localize`:@@Country_LI:Liechtenstein`,
  Country_LC: $localize`:@@Country_LC:Saint Lucia`,
  Country_LB: $localize`:@@Country_LB:Lebanon`,
  Country_LA: $localize`:@@Country_LA:Laos`,
  Country_KZ: $localize`:@@Country_KZ:Kazakhstan`,
  Country_KY: $localize`:@@Country_KY:Cayman Islands`,
  Country_KW: $localize`:@@Country_KW:Kuwait`,
  Country_KR: $localize`:@@Country_KR:South Korea`,
  Country_KP: $localize`:@@Country_KP:North Korea`,
  Country_KN: $localize`:@@Country_KN:Saint Kitts and Nevis`,
  Country_KM: $localize`:@@Country_KM:Comoros`,
  Country_KI: $localize`:@@Country_KI:Kiribati`,
  Country_KH: $localize`:@@Country_KH:Cambodia`,
  Country_KG: $localize`:@@Country_KG:Kyrgyzstan`,
  Country_KE: $localize`:@@Country_KE:Kenya`,
  Country_JP: $localize`:@@Country_JP:Japan`,
  Country_JO: $localize`:@@Country_JO:Jordan`,
  Country_JM: $localize`:@@Country_JM:Jamaica`,
  Country_JE: $localize`:@@Country_JE:Jersey`,
  Country_IT: $localize`:@@Country_IT:Italy`,
  Country_IS: $localize`:@@Country_IS:Iceland`,
  Country_IR: $localize`:@@Country_IR:Iran`,
  Country_IQ: $localize`:@@Country_IQ:Iraq`,
  Country_IO: $localize`:@@Country_IO:British Indian Ocean terr.`,
  Country_IN: $localize`:@@Country_IN:India`,
  Country_IM: $localize`:@@Country_IM:Isle Of Man`,
  Country_IL: $localize`:@@Country_IL:Israel`,
  Country_IE: $localize`:@@Country_IE:Ireland`,
  Country_ID: $localize`:@@Country_ID:Indonesia`,
  Country_HU: $localize`:@@Country_HU:Hungary`,
  Country_HT: $localize`:@@Country_HT:Haiti`,
  Country_HR: $localize`:@@Country_HR:Croatia`,
  Country_HN: $localize`:@@Country_HN:Honduras`,
  Country_HM: $localize`:@@Country_HM:Heard & McDonald Islands`,
  Country_HK: $localize`:@@Country_HK:Hong Kong`,
  Country_GY: $localize`:@@Country_GY:Guyana`,
  Country_GW: $localize`:@@Country_GW:Guinea-Bissau`,
  Country_GU: $localize`:@@Country_GU:Guam`,
  Country_GT: $localize`:@@Country_GT:Guatemala`,
  Country_GS: $localize`:@@Country_GS:South Georgia And The South Sandwich Islands`,
  Country_GR: $localize`:@@Country_GR:Greece`,
  Country_GQ: $localize`:@@Country_GQ:Equatorial Guinea`,
  Country_GP: $localize`:@@Country_GP:Guadeloupe`,
  Country_GN: $localize`:@@Country_GN:Guinea`,
  Country_GM: $localize`:@@Country_GM:Gambia`,
  Country_GL: $localize`:@@Country_GL:Greenland`,
  Country_GI: $localize`:@@Country_GI:Gibraltar`,
  Country_GH: $localize`:@@Country_GH:Ghana`,
  Country_GG: $localize`:@@Country_GG:Guernsey`,
  Country_GF: $localize`:@@Country_GF:French Guiana`,
  Country_GE: $localize`:@@Country_GE:Georgia`,
  Country_GD: $localize`:@@Country_GD:Grenada`,
  Country_GB: $localize`:@@Country_GB:United Kingdom`,
  Country_GA: $localize`:@@Country_GA:Gabon`,
  Country_FR: $localize`:@@Country_FR:France`,
  Country_FO: $localize`:@@Country_FO:Faroe Islands`,
  Country_FM: $localize`:@@Country_FM:Micronesia`,
  Country_FK: $localize`:@@Country_FK:Falkland Islands`,
  Country_FJ: $localize`:@@Country_FJ:Fiji`,
  Country_FI: $localize`:@@Country_FI:Finland`,
  Country_ET: $localize`:@@Country_ET:Ethiopia`,
  Country_ES: $localize`:@@Country_ES:Spain`,
  Country_ER: $localize`:@@Country_ER:Eritrea`,
  Country_EH: $localize`:@@Country_EH:Western Sahara`,
  Country_EG: $localize`:@@Country_EG:Egypt`,
  Country_EE: $localize`:@@Country_EE:Estonia`,
  Country_EC: $localize`:@@Country_EC:Ecuador`,
  Country_DZ: $localize`:@@Country_DZ:Algeria`,
  Country_DO: $localize`:@@Country_DO:Dominican Republic`,
  Country_DM: $localize`:@@Country_DM:Dominica`,
  Country_DK: $localize`:@@Country_DK:Denmark`,
  Country_DJ: $localize`:@@Country_DJ:Djibouti`,
  Country_DE: $localize`:@@Country_DE:Germany`,
  Country_CZ: $localize`:@@Country_CZ:Czech Republic`,
  Country_CY: $localize`:@@Country_CY:Cyprus`,
  Country_CX: $localize`:@@Country_CX:Christmas Island`,
  Country_CV: $localize`:@@Country_CV:Cape Verde`,
  Country_CU: $localize`:@@Country_CU:Cuba`,
  Country_CR: $localize`:@@Country_CR:Costa Rica`,
  Country_CO: $localize`:@@Country_CO:Colombia`,
  Country_CN: $localize`:@@Country_CN:China`,
  Country_CM: $localize`:@@Country_CM:Cameroon`,
  Country_CL: $localize`:@@Country_CL:Chile`,
  Country_CK: $localize`:@@Country_CK:Cook Islands`,
  Country_CI: $localize`:@@Country_CI:Cote D'ivoire`,
  Country_CH: $localize`:@@Country_CH:Switzerland`,
  Country_CG: $localize`:@@Country_CG:Congo`,
  Country_CF: $localize`:@@Country_CF:Central African Republic`,
  Country_CD: $localize`:@@Country_CD:Democratic Republic of the Congo`,
  Country_CC: $localize`:@@Country_CC:Cocos (Keeling) Islands`,
  Country_CA: $localize`:@@Country_CA:Canada`,
  Country_BZ: $localize`:@@Country_BZ:Belize`,
  Country_BY: $localize`:@@Country_BY:Belarus`,
  Country_BW: $localize`:@@Country_BW:Botswana`,
  Country_BV: $localize`:@@Country_BV:Bouvet Island`,
  Country_BT: $localize`:@@Country_BT:Bhutan`,
  Country_BS: $localize`:@@Country_BS:Bahamas`,
  Country_BR: $localize`:@@Country_BR:Brazil`,
  Country_BO: $localize`:@@Country_BO:Bolivia`,
  Country_BN: $localize`:@@Country_BN:Brunei Darussalam`,
  Country_BM: $localize`:@@Country_BM:Bermuda`,
  Country_BL: $localize`:@@Country_BL:Saint Barthélemy`,
  Country_BJ: $localize`:@@Country_BJ:Benin`,
  Country_BI: $localize`:@@Country_BI:Burundi`,
  Country_BH: $localize`:@@Country_BH:Bahrain`,
  Country_BG: $localize`:@@Country_BG:Bulgaria`,
  Country_BF: $localize`:@@Country_BF:Burkina Faso`,
  Country_BE: $localize`:@@Country_BE:Belgium`,
  Country_BD: $localize`:@@Country_BD:Bangladesh`,
  Country_BB: $localize`:@@Country_BB:Barbados`,
  Country_BA: $localize`:@@Country_BA:Bosnia And Herzegovina`,
  Country_AZ: $localize`:@@Country_AZ:Azerbaijan`,
  Country_AX: $localize`:@@Country_AX:Åland Islands`,
  Country_AW: $localize`:@@Country_AW:Aruba`,
  Country_AU: $localize`:@@Country_AU:Australia`,
  Country_AT: $localize`:@@Country_AT:Austria`,
  Country_AS: $localize`:@@Country_AS:American Samoa`,
  Country_AR: $localize`:@@Country_AR:Argentina`,
  Country_AQ: $localize`:@@Country_AQ:Antarctica`,
  Country_AO: $localize`:@@Country_AO:Angola`,
  Country_AN: $localize`:@@Country_AN:Netherlands Antilles`,
  Country_AM: $localize`:@@Country_AM:Armenia`,
  Country_AL: $localize`:@@Country_AL:Albania`,
  Country_AI: $localize`:@@Country_AI:Anguilla`,
  Country_AG: $localize`:@@Country_AG:Antigua And Barbuda`,
  Country_AF: $localize`:@@Country_AF:Afghanistan`,
  Country_AE: $localize`:@@Country_AE:United Arab Emirates`,
  Country_AD: $localize`:@@Country_AD:Andorra`
};
