import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplPopoverErrorComponent } from './popover-error.component';
import { PplPopoverTooltipModule } from '../popover-tooltip';

@NgModule({
    imports: [
        CommonModule,
        PplPopoverTooltipModule
    ],
    declarations: [
        PplPopoverErrorComponent
    ],
    exports: [
        PplPopoverErrorComponent
    ]
})
export class PplPopoverErrorModule {
}

export * from './popover-error.component';
