import { Component } from '@angular/core';
import type { PplMultipleAutocompleteCategory, PplMultipleAutocompleteOption } from '@ppl/ui/multiple-autocomplete';
import { validateEmail } from '@ppl/utils';


@Component({
  selector: 'ppl-multiple-autocomplete-demo',
  templateUrl: './multiple-autocomplete-demo.component.html',
  styleUrls: ['./multiple-autocomplete-demo.component.scss']
})
export class MultipleAutocompleteDemoComponent {

  code = `
  <ppl-input-container label="Basic">
  <ppl-multiple-autocomplete [options]="options"
                             [value]="value"
                             placeholder="Placeholder"
                             (valueChange)="value = $event"></ppl-multiple-autocomplete>
  </ppl-input-container>
  `;

  options = [
    { icon: 'contact', value: '1', label: 'Steve Jobs' },
    { value: '2', label: 'Bill Gates' },
    { value: '3', label: 'Gustav Kazimir Zechenter Laskomersky 1' },
    { value: '4', label: 'Gustav Kazimir Zechenter Laskomersky 2' },
    { value: '5', label: 'Gustav Kazimir Zechenter Laskomersky 3' },
    { value: '6', label: 'Gustav Kazimir Zechenter Laskomersky 4' }
  ];

  value = ['1'];

  options1 = Array.from({ length: 100 }, (value, index) => ({
    value: index.toString(),
    label: `Option ${index}`
  }));

  value1 = [];

  options2 = [
    { value: 'anton.bernolak@gmail.com', label: 'anton.bernolak@gmail.com' },
    { value: 'ludovit.stur@gmail.com', label: 'ludovit.stur@gmail.com' }
  ];

  value2 = ['anton.bernolak@gmail.com'];

  categories: PplMultipleAutocompleteCategory[] = [
    { id: 'konspirator', label: 'Konspirator' },
    { id: 'psychopat', label: 'Psychopat' }
  ];
  options3: PplMultipleAutocompleteOption[] = [
    { value: 'blaha', label: 'Ľuboš Blaha', categoryId: 'konspirator' },
    { value: 'danko', label: 'Andrej Danko', categoryId: 'psychopat' }
  ];
  value3 = ['blaha'];

  emailValidator(value: string) {
    return validateEmail(value);
  }

}
