<div class="conversation-item"
     [class.outgoing]="outgoingConversationItem">
  <!-- NOTE(mike): `entity` is set to `account`, just to show the first letter of name if no picture is present -->
  <ppl-avatar [picture]="data.senderPicture"
              [name]="data.senderPictureName"
              [size]="48"
              [class.avatar-outgoing]="outgoingConversationItem"
              [forceRound]="true"
              entity="account"></ppl-avatar>
  <div class="conversation-item-message">
    <div class="conversation-item-message-content"
         [class.conversation-item-message-content-outgoing]="outgoingConversationItem"
         [class.error]="data.errorMessage">{{ data.message }}</div>
    <div class="conversation-item-message-details"
         [class.conversation-item-message-details-outgoing]="outgoingConversationItem">
      <ng-container *ngIf="isSent || isSending; else errorMessage">
        <ng-container *ngIf="isSent; else sendingMessage">
          <span class="conversation-item-message-details-data-bold">{{ data.senderName }}</span>
          <span class="spacer"> - </span>
          <span class="conversation-item-message-details-data">{{ data.datetimeSent }}</span>
        </ng-container>
        <ng-template #sendingMessage>
          <span class="conversation-item-message-details-data"
                i18n="@@Sending...">Sending...</span>
        </ng-template>
      </ng-container>

      <ng-template #errorMessage>
        <span class="conversation-item-message-details-data">{{ data.errorMessage }}</span>
      </ng-template>
    </div>
  </div>
</div>
