import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component , ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'ppl-buttons-group',
  templateUrl: './buttons-group.component.html',
  styleUrls: ['./buttons-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplButtonsGroupComponent implements OnInit {

  private className = 'btn-group';

  constructor(private renderer: Renderer2,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, this.className);
  }

}
