<div class="button"
     [pplPopover]="actionsPopover"
     pplPopoverAlignStart
     (click)="$event.stopPropagation()">
  <ppl-icon svgIcon="rowline"
            width="8px"
            height="5px"></ppl-icon>
</div>

<ng-template #actionsPopover>
  <ppl-popover-dropdown>
    <ppl-menu [hasIcons]="true">
      <ng-container *ngFor="let action of actions; trackBy: trackById">
        <ppl-menu-item [value]="action.id"
                       pplPopoverClose
                       (click)="actionClick.emit({ action: action, record: record })">
          <ppl-icon [svgIcon]="action.icon"
                    width="18px"
                    height="18px"></ppl-icon>
          {{action.text}}
        </ppl-menu-item>
      </ng-container>
    </ppl-menu>
  </ppl-popover-dropdown>
</ng-template>