import type { OnInit} from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { PplIconService } from '@ppl/ui/icon';

@Component({
  selector: 'ppl-icons-demo',
  templateUrl: './icons-demo.component.html',
  styleUrls: ['./icons-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconsDemoComponent implements OnInit {

  icons: string[];

  @ViewChild('input', { static: true }) input: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef, private iconService: PplIconService) {
  }

  ngOnInit() {
    this.icons = Object.keys(this.iconService.icons);
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup').pipe(
        debounceTime(300),
        map((e) => e.target['value'] as string)
    ).subscribe(searchTerm => {
      this.filterIcons(searchTerm);
      this.changeDetectorRef.detectChanges();
    });
  }

  filterIcons(searchTerm: string) {
    this.icons = Object.keys(this.iconService.icons).filter(name => name.toUpperCase().search(searchTerm.toUpperCase()) !== -1);
  }

  trackIcon(icon: string) {
    return icon;
  }

}
