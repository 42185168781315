import { getUserSettingsLocale } from '../store/auth.selectors';
import { AuthStore } from '../store/auth.state';
import { CurrencyPipe } from '@angular/common';
import type {
  PipeTransform
} from '@angular/core';
import {
  Inject,
  InjectionToken,
  Optional,
  Pipe
} from '@angular/core';
import type { Subscription } from 'rxjs';
import type { ID } from '@ppl/graphql-user-api';

type BaseCurrencySelector = (state: any) => {
  code: string;
  id: ID;
};
export const BASE_CURRENCY_SELECTOR = new InjectionToken<BaseCurrencySelector>('BaseCurrencySelector');

@Pipe({
  name: 'pplCurrency'
})
export class PplCurrencyPipe implements PipeTransform {
  locale: string;
  localeSubscription: Subscription;

  constructor(
    private currencyPipe: CurrencyPipe,
    private store: AuthStore,
    @Optional() @Inject(BASE_CURRENCY_SELECTOR) private baseCurrencySelector: BaseCurrencySelector
  ) {
    this.localeSubscription = this.store.select(getUserSettingsLocale(true)).subscribe(locale => this.locale = locale);
  }

  transform(value: number, currencyCode?: string, currencySymbol: 'code' | 'symbol' | 'symbol-narrow' = 'symbol-narrow', digitsInfo?: string) {
    const baseCurrency = this.baseCurrencySelector && this.store.get(this.baseCurrencySelector) || null;

    if (!currencySymbol) {
      currencySymbol = 'symbol-narrow';
    }

    if (baseCurrency && baseCurrency.code === 'XXX' && (!currencyCode || currencyCode === 'XXX')) {
      return this.currencyPipe.transform(value, '', '');
    }

    if (!baseCurrency && !currencyCode) {
      console.warn('PplCurrencyPipe: Currency code not available in config and not specified as a parameter, defaulting to USD');
    }

    return this.currencyPipe.transform(
      value,
      currencyCode || (baseCurrency && baseCurrency.code) || 'USD',
      currencySymbol,
      digitsInfo,
      this.locale
    );
  }
}
