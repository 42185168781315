import { PplUiToolbarButtonGroupComponent } from './ui-toolbar-button-group/ui-toolbar-button-group.component';
import { PplUiToolbarComponent } from './ui-toolbar.component';
import { PplButtonModule } from '../button';
import { PplIconModule } from '../icon';
import { PplMenuModule } from '../menu';
import { PplPopoverModule } from '../popover';
import { PplPopoverDropdownModule } from '../popover-dropdown';
import { PplSelectModule } from '../select';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplSelectModule,
    PplButtonModule,
    PplPopoverModule,
    PplPopoverDropdownModule,
    PplMenuModule
  ],
  declarations: [
    PplUiToolbarComponent,
    PplUiToolbarButtonGroupComponent
  ],
  exports: [
    PplUiToolbarComponent,
    PplUiToolbarButtonGroupComponent
  ]
})
export class PplUiToolbarModule {
}

export * from './ui-toolbar-button-group/ui-toolbar-button-group.component';
export * from './ui-toolbar.component';

