import { PplSelectComponent } from './select.component';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { PplOptionListWithFilterModule } from '../option-list-with-filter';
import { PplPopoverDropdownModule } from '../popover-dropdown/index';
import { PplPopoverErrorModule } from '../popover-error/index';
import { PplPopoverModule } from '../popover/index';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplInputModule,
    PplPopoverModule,
    PplPopoverDropdownModule,
    PplPopoverErrorModule,
    PplOptionListWithFilterModule
  ],
  declarations: [
    PplSelectComponent
  ],
  exports: [
    PplSelectComponent
  ]
})
export class PplSelectModule {
}

export * from './select.component';
export * from './select.utils';
