<div class="container demo-content">
  <h2>Popover</h2>

  <h3>Module</h3>
  <div>PplPopoverModule</div>
  <br />

  <h3>Directives</h3>
  <h4>pplPopover</h4>
  <div>@Input() <b>pplPopover</b>: TemplateRef</div>
  <div>@Input() <b>pplPopoverAnchorX</b>: 'left' | 'right' (optional)</div>
  <div>@Input() <b>pplPopoverAnchorY</b>: 'top' | 'bottom' (optional)</div>
  <div>@Input() <b>pplPopoverEvent</b>: 'click' | 'hover' (optional)</div>
  <div>@Input() <b>pplPopoverDisabled</b>: boolean (optional)</div>
  <div>@Input() <b>pplPopoverTooltip</b>: string (works only with pplPopover directive!)</div>
  <div>@Input() <b>pplPopoverTooltipPosition</b>: PplPopoverPosition</div>


  <br>

  <h4>PplPopoverCloseDirective <small>pplPopoverClose</small></h4>
  <h4>PplPopoverTooltipDirective <small>pplPopoverTooltip</small></h4>
  <br />

  <h3>Examples</h3>
  <div class="row">
    <div class="col-lg-12">
      <button pplButton
              pplButton1
              [pplPopover]="popoverTest"
              pplPopoverAlignStart>
        Test (click, bottom left)
      </button>
      <br />
      <br />

      <button pplButton
              pplButton1
              [pplPopover]="popoverTest"
              pplPopoverAlignEnd>
        Test (click, top right)
      </button>
      <br />
      <br />

      <button pplButton
              pplButton1
              [pplPopover]="popoverTest"
              pplPopoverAlignStart
              pplPopoverEvent="hover">
        Test (hover)
      </button>
      <br />
      <br />

      <div class="tooltip-container">
        <div pplPopover
             pplPopoverTooltip="Test tooltip directive">
          Tool
        </div>
        <br>

        <div pplPopover
             pplPopoverTooltip="Test tooltip directive with position"
             [pplPopoverTooltipDirection]="direction">
          Tooltip directive with direction
        </div>
        <br>

        <div #tooltip
             [pplPopover]="tooggleTooltip"
             pplPopoverEvent="hover">
          Toogle tooltip
        </div>
      </div>
      <br>
      <br>

    </div>
    <h4>Code Example</h4>
    <ppl-prism [code]="code"></ppl-prism>
  </div>

  <ng-template #popoverTest>
    <div [style.backgroundColor]="'#fff'"
         [style.boxShadow]="'0 0 8px #aaa'"
         [style.borderRadius.px]="4"
         [style.padding.px]="16">
      Popover Test Content
    </div>
  </ng-template>
</div>

<ng-template #tooggleTooltip
             let-popover="popover">
  <ppl-popover-tooltip i18n="@@EmailSenderPickerTooltip"
                       [connectToElement]="tooltip"
                       [popover]="popover">
    Email addresses of contacts
  </ppl-popover-tooltip>
</ng-template>
