<div class="container"
     [class.loading]="loading"
     [class.available]="available"
     [class.disabled]="disabled"
     [pplPopover]="pickerPopover"
     pplPopoverAlignStart
     [pplPopoverDisabled]="disabled || pickerDisabled"
     (pplPopoverToggle)="onPopoverToggle($event)">
  <ng-container *ngIf="available">
    <div class="content"
         [class.disabled]="disabled"
         [class.content-disabled]="contentDisabled"
         (click)="onContentClick()">
      <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: value }"></ng-container>
    </div>

    <div *ngIf="!pickerDisabled"
         class="picker"
         [class.disabled]="disabled">
      <ppl-icon svgIcon="rowline-form"
                width="8px"
                height="8px"></ppl-icon>
    </div>
  </ng-container>

  <ppl-loading-overlay *ngIf="loading"
                       [inverse]="loadingInverse"></ppl-loading-overlay>
</div>

<ng-template #pickerPopover
             let-position="position"
             let-popover="popover">
  <ng-container *ngTemplateOutlet="pickerPopoverTemplate; context: {
    $implicit: value,
    popover: popover,
    data: pickerPopoverData
  }"></ng-container>
</ng-template>
