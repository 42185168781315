import type {
  AfterViewInit,
  OnDestroy} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl } from '@ppl/utils';
import IMask from 'imask/esm/imask';
import 'imask/esm/masked/number';

@Component({
  selector: 'ppl-ranking-percentage',
  templateUrl: './ranking-percentage.component.html',
  styleUrls: ['./ranking-percentage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplRankingPercentageComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplRankingPercentageComponent implements AfterViewInit, OnDestroy {
  @Input() value: number; // <1, 100>
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() prependIcon: string;
  @Input() prependText: string;
  @Output() valueChange = new EventEmitter<number>();
  @ViewChild('valueInput', { static: true }) valueInput: ElementRef;

  mask: IMask.InputMask<any>;

  @HostBinding('class.has-prepend-icon')
  get hasIcon() {
    return !!this.prependIcon;
  }

  get stars() {
    return Math.max(Math.min(Math.ceil(this.value / 100 * 5), 5), 1);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    // configuration: https://imask.js.org/guide.html#masked-number
    this.mask = IMask(this.valueInput.nativeElement, {
      mask: Number,  // enable number mask
      scale: 0,  // digits after point, 0 for integers
      signed: false,  // disallow negative
      thousandsSeparator: '',  // any single char
      min: 1,
      max: 100
    });
  }

  ngOnDestroy(): void {
    this.mask?.destroy();
  }

  onStarsChange($event: number) {
    if (this.disabled) {
      return;
    }
    this.valueChange.emit($event / 5 * 100);
  }

  onInputChange($event: string) {
    if (this.disabled) {
      return;
    }
    if (isNaN(parseFloat($event))) {
      return;
    }
    this.valueChange.emit(+$event);
  }
}
