import type { CallingConversationItemData } from '../calling-conversation-item/calling-conversation-item.component';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import type {
  TSimpleChanges
} from '@ppl/utils';
import {
  notFirstChange,
  trackById,
  Unsubscribe
} from '@ppl/utils';
import type { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'ppl-calling-text-message-list',
  templateUrl: './calling-text-message-list.component.html',
  styleUrls: ['./calling-text-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Unsubscribe()
export class CallingTextMessageListComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @Input() items: CallingConversationItemData[];
  @Input() canLoadMore: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() scrollChange = new EventEmitter<number>();

  @ViewChild('loading', { static: false, read: ElementRef }) loading: ElementRef;
  @ViewChild('messages', { static: true, read: ElementRef }) messages: ElementRef<HTMLDivElement>;

  intersectionObserver: IntersectionObserver;
  scrollChange$ = new Subject<number>();

  trackById = trackById;

  scrollChangeSubscription: Subscription;

  constructor() { }

  ngOnChanges(changes: TSimpleChanges<CallingTextMessageListComponent>) {
    if (notFirstChange(changes.canLoadMore)) {
      if (changes.canLoadMore.currentValue) {
        setTimeout(() => {
          this.registerLoadMoreHandler();
        });
      } else {
        this.unregisterLoadMoreHandler();
      }
    }
  }

  ngOnInit() {
    this.intersectionObserver = new IntersectionObserver(
      this.onLoadMore.bind(this),
      {
        root: this.messages.nativeElement,
        threshold: 0.2
      }
    );

    this.scrollChangeSubscription = this.scrollChange$.pipe(
      debounceTime(50)
    ).subscribe(scrollTop => {
      this.scrollChange.emit(scrollTop);
    });
  }

  ngAfterViewInit() {
    if (this.canLoadMore) {
      this.registerLoadMoreHandler();
    }
  }

  ngOnDestroy() {
    this.unregisterLoadMoreHandler();
  }

  onLoadMore(entries: IntersectionObserverEntry[]) {
    const entry = entries[0];
    if (entry.isIntersecting) {
      this.loadMore.emit();
    }
  }

  onScroll() {
    this.scrollChange$.next(this.messages.nativeElement.scrollTop);
  }

  registerLoadMoreHandler() {
    this.intersectionObserver.observe(this.loading.nativeElement);
  }

  unregisterLoadMoreHandler() {
    this.intersectionObserver.disconnect();
  }

  scrollToBottom() {
    this.messages.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
