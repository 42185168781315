<div class="container demo-content">
  <div>// TODO: icons</div>
  <h2>Buttons</h2>

  <br>

  <h3>Components</h3>
  <h4>ppl-button, button[pplButton], a[pplButton], input[pplButton]</h4>
  <div>@Input() <b>disabled</b>: boolean</div>

  <br>

  <h3>Directives</h3>
  <div>PplButtonSmallDirective <small>pplButtonSmall</small></div>
  <div>PplButtonSmallDirective <small>pplButtonSmall='xs'</small></div>
  <div>PplButtonLargeDirective <small>pplButtonLarge</small></div>
  <div>PplButtonBoldDirective <small>pplButtonBold</small></div>
  <div>PplButtonBlockDirective <small>pplButtonBlock</small></div>
  <div>PplButton1Directive <small>pplButton1</small></div>
  <div>PplButton2Directive <small>pplButton2</small></div>
  <div>PplButton3Directive <small>pplButton3</small></div>
  <div>PplButton4Directive <small>pplButton4</small></div>
  <div>PplButton5Directive <small>pplButton5</small></div>
  <div>PplButton6Directive <small>pplButton6</small></div>
  <div>PplButton7InfoDirective <small>pplButton7='info'</small></div>
  <div>PplButton7WarningDirective <small>pplButton7='warning'</small></div>
  <div>PplButton7DangerDirective <small>pplButton7='danger</small></div>
  <div>PplButton7ErrorDirective <small>pplButton7='error'</small></div>

  <br>

  <h3>Example</h3>
  <p>
    <span class="active"
          (click)="active = !active;">toggle {{active ? 'inactive' : 'active'}}</span>
  </p>

  <div class="row">
    <button pplButton
            [pplButtonActive]="active"
            pplButton1>Default</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButton1>OK</button>
  </div>
  <br>
  <br>

  <button pplButton
          [pplButtonActive]="active"
          pplButton1
          pplButtonBold>Change password</button>
  <br>
  <br>

  <button pplButton
          [pplButtonActive]="active"
          pplButton1
          pplButtonSmall>Change Owner</button>
  <br>
  <br>

  <div class="row">
    <button pplButton
            [pplButtonActive]="active"
            pplButton4
            pplButtonSmall>Qualify</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButton5
            pplButtonSmall>Archive</button>
  </div>
  <br>
  <br>

  <div class="row">
    <button pplButton
            [pplButtonActive]="active"
            pplButton2>Dialog Button</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButton3>Manage</button>
  </div>
  <br>
  <br>

  <div class="row"
       style="width: 100%; max-width: 200px;">
    <button pplButton
            [pplButtonActive]="active"
            pplButton6
            pplButtonBlock>Sign Out</button>
  </div>
  <br>
  <br>

  <ppl-buttons-group>
    <button pplButton
            [pplButtonActive]="active"
            pplButton1>Add Activities</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButton1>Add Document</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButton1>Add Note</button>
  </ppl-buttons-group>
  <br>
  <br>

  <div class="row">
    <button pplButton
            [pplButtonActive]="active"
            pplButtonSmall="xs"
            pplButton7="info">Info</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButtonSmall="xs"
            pplButton7="warning">Warning</button>
    <button pplButton
            [pplButtonActive]="active"
            pplButtonSmall="xs"
            pplButton7="danger">Danger</button>

    <button pplButton
            [pplButtonActive]="active"
            pplButtonSmall="xs"
            pplButton7="error">Error</button>
  </div>
  <br>
  <br>

  <button pplButton
          pplButton1>
    <ppl-icon width="18px"
              height="18px"
              svgIcon="color-add"
              pplIconButton></ppl-icon>
    Button with icon
  </button>
  <br>
  <br>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>