import type { AfterViewInit } from '@angular/core';
import { Directive , ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[pplEditableAutofocus]'
})
export class PplEditableAutofocusDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
}
