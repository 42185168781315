import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import type { PplPosition, PplResizableSizeConstraints } from '@ppl/ui/resizable';
import type { PplSelectOption } from '@ppl/ui/select';

@Component({
  selector: 'ppl-drawer-demo',
  templateUrl: './drawer-demo.component.html',
  styleUrls: ['./drawer-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerDemoComponent implements OnInit {
  drawerOpen = false;
  detailSize = 230;
  position: PplPosition = 'right';
  constraints = constraints;
  positionOptions: PplSelectOption[] = [
    {
      value: 'bottom',
      label: 'bottom'
    },
    {
      value: 'left',
      label: 'left'
    },
    {
      value: 'right',
      label: 'right'
    },
  ];

  constructor() { }

  ngOnInit() {
  }
}

const constraints: PplResizableSizeConstraints = { min: 280, max: 560 };