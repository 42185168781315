import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ppl-calling-conversation-item',
  templateUrl: './calling-conversation-item.component.html',
  styleUrls: ['./calling-conversation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallingConversationItemComponent {

  @Input() data: CallingConversationItemData;

  get outgoingConversationItem() {
    return this.data.direction === CallingConversationItemDirectionEnum.Outgoing;
  }

  get isSent() {
    return this.data.state === CallingConversationItemStateEnum.Sent;
  }

  get isSending() {
    return this.data.state === CallingConversationItemStateEnum.Sending;
  }
}

export enum CallingConversationItemDirectionEnum {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

export enum CallingConversationItemStateEnum {
  Sent = 'Sent',
  Sending = 'Sending',
  Error = 'Error'
}

export interface CallingConversationItemData {
  id: string;
  direction: CallingConversationItemDirectionEnum;
  message: string;
  datetimeSent: string;
  senderName: string;
  senderPicture: string;
  senderPictureName: string;
  errorMessage?: string;
  state: CallingConversationItemStateEnum;
}
