<ng-container *ngIf="!actionsTemplate">
  <ng-template [ngTemplateOutlet]="actionButton"></ng-template>
  <ng-template [ngTemplateOutlet]="cancelButton"></ng-template>
</ng-container>
<ng-container *ngIf="actionsTemplate">
  <ng-template [ngTemplateOutlet]="actionsTemplate"
               [ngTemplateOutletContext]="{
    action: actionButton,
    cancel: cancelButton
  }"></ng-template>
</ng-container>

<ng-template #actionButton>
  <button *ngIf="actionVisible"
          [disabled]="saving || actionDisabled"
          [attr.form]="id"
          type="submit"
          pplButton
          pplButtonFocusable
          pplButtonDialog
          [pplButtonType]="actionButtonType"
          pplPopover
          [pplPopoverTooltip]="actionTooltip"
          [attr.data-test]="'FormActions_Action'"
          (click)="onSave(); save.emit(null)"
          [ngSwitch]="actionLabel">
    <ng-container *ngSwitchCase="'save'"
                  i18n="@@Save">Save</ng-container>
    <ng-container *ngSwitchCase="'saveAs'"
                  i18n="@@Save_As">Save As</ng-container>
    <ng-container *ngSwitchCase="'create'"
                  i18n="@@Create">Create</ng-container>
    <ng-container *ngSwitchCase="'add'"
                  i18n="@@Add">Add</ng-container>
    <ng-container *ngSwitchCase="'remove'"
                  i18n="@@Remove">Remove</ng-container>
    <ng-container *ngSwitchDefault>
      {{actionLabel}}
    </ng-container>
  </button>
</ng-template>

<ng-template #cancelButton>
  <button *ngIf="cancelVisible"
          [disabled]="(cancelDisabledOnSave && saving) || cancelDisabled"
          class="cancel"
          type="button"
          pplButton
          pplButtonFocusable
          pplButtonDialog
          pplButton3
          [attr.data-test]="'FormActions_Cancel'"
          (click)="onCancel(); cancel.emit(null)"
          [ngSwitch]="cancelLabel">
    <ng-container *ngSwitchCase="'close'"
                  i18n="@@Close">Close</ng-container>
    <ng-container *ngSwitchCase="'cancel'"
                  i18n="@@Cancel">Cancel</ng-container>
    <ng-container *ngSwitchCase="'undoChanges'"
                  i18n="@@UndoChanges">Undo Changes</ng-container>
    <ng-container *ngSwitchDefault>
      {{cancelLabel}}
    </ng-container>
  </button>
</ng-template>