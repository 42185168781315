import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplContainerComponent, PplContentComponent, PplSidebarComponent } from './container/container.component';

const components = [
  PplSidebarComponent,
  PplContainerComponent,
  PplContentComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ]
})
export class PplContainerModule {
}

export * from './container/container.component';
