import type { Toast } from '../toast';
import {
  animate,
  style,
  transition,
  trigger
} from '@angular/animations';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppl-toast-content',
  templateUrl: 'toast-content.component.html',
  styleUrls: ['toast-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: '0' }),
        animate('200ms cubic-bezier(.17,.67,.83,.67)', style({ transform: 'translateY(0%)', opacity: '1' }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ height: '0px', opacity: '0' }))
      ])
    ]),
    trigger('hostSlideOut', [
      transition(':leave', [
        animate('200ms ease-out', style({ opacity: '0' }))
      ])
    ])
  ]
})
export class ToastContentComponent implements OnInit {
  @Input() toasts$ = new BehaviorSubject<Toast[]>([]);
  @Output() close = new EventEmitter();

  mouseOver$ = new BehaviorSubject<boolean>(false);

  @HostBinding('@hostSlideOut')
  get hostSlideOut() {
    return true;
  }

  constructor() { }

  ngOnInit() {
  }

  addToast(toast: Toast) {
    this.toasts$.next([...this.toasts$.getValue(), toast]);
  }

  onCloseSingle(toast: Toast) {
    this.toasts$.next(this.toasts$.getValue().filter(foundToast => foundToast !== toast));

    this.checkIfNeedToClose();
  }

  checkIfNeedToClose() {
    if (!this.toasts$.getValue().length) {
      this.close.emit();
    }
  }
}
