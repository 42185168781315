<div class="container demo-content">
  <h2>Select</h2>
  <br>

  <h3>Module</h3>
  <div>PplSelectModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-select</h4>
  <div>@Input()<b> placeholder</b>: string</div>
  <div>@Input()<b> options</b>: PplSelectOption[]</div>
  <div>@Input()<b> selectedValue</b>: string (optional)</div>
  <div>@Input()<b> selectedOption</b>: PplSelectOption (optional)</div>
  <div>@Input()<b> hasEmptyOption</b>: boolean = false</div>
  <div>@Input()<b> emptyOptionLabel</b>: string (optional)</div>
  <div>@Input()<b> itemTemplate</b>: TemplateRef (optional)</div>
  <br>
  <div>@Input()<b> disabled</b>: boolean</div>
  <div>@Input()<b> fill</b>: boolean</div>
  <div>@Input()<b> big</b>: boolean</div>
  <div>@Input()<b> hasIcons</b>: boolean</div>
  <div>@Input()<b> hasColors</b>: boolean</div>

  <br>
  <div>@Output()<b>change</b>: EventEmitter&lt;PplSelectOption&gt;</div>
  <br>
  <div>ViewChildren('dropdownItem')<b> dropdownItems</b>: QueryList&lt;any&gt;</div>
  <br>
  <br>

  <h4>Example</h4>
  <ppl-input-container label="Select">
    <ppl-select [options]="values"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Select Error"
                       hasError="true">
    <ppl-select [options]="values"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Select with second option deleted & selected (not viable to reselect again)">
    <ppl-select [options]="deletedSelectOptions"
                [value]="deletedSelectSelectedValue"
                (valueChange)="deletedSelectSelectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-select [options]="values"
                [value]="selectedValue"
                [disabled]="true"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Readonly">
    <ppl-select [options]="values"
                [value]="selectedValue"
                [readonly]="true"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Filled">
    <ppl-select [options]="values"
                [fill]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="With icons">
    <ppl-select [options]="values"
                [hasIcons]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="With colors">
    <ppl-select [options]="colorValues"
                [hasColors]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Filled with icons">
    <ppl-select [options]="values"
                [fill]="true"
                [hasIcons]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Big filled with icons">
    <ppl-select [options]="values"
                [big]="true"
                [hasIcons]="true"
                [fill]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
