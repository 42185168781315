import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplIconModule } from '../icon';
import { PplToggleListComponent } from './toggle-list.component';

const components = [PplToggleListComponent];

@NgModule({
  imports: [CommonModule, PplIconModule],
  declarations: [...components],
  exports: [...components]
})
export class PplToggleListModule {}

export * from './toggle-list.component';
