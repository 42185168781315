import { CommonModule, I18nPluralPipe } from '@angular/common';
import type { ModuleWithProviders} from '@angular/core';
import { NgModule } from '@angular/core';
import { I18nService } from './i18n.service';
import { TEnumPipe } from './t-enum.pipe';
import { TErrorPipe } from './t-error.pipe';
import { TPipe } from './t.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TPipe,
    TEnumPipe,
    TErrorPipe
  ],
  providers: [
    I18nPluralPipe
  ],
  exports: [
    TPipe,
    TEnumPipe,
    TErrorPipe
  ]
})
export class I18nModule {
  static forRoot(): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [I18nService]
    };
  }
}
