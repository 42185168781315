<div class="container demo-content">
  <h2>Checkboxes</h2>

  <h3>Module</h3>
  <div>PplCheckboxModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-checkbox</h4>
  <div>@Input
    <b>checked</b>: boolean</div>
  <div>@Input
    <b>value</b>: string (optional)</div>
  <div>@Input
    <b>id</b>: string (optional)</div>
  <div>@Input
    <b>required</b>: boolean (optional)</div>
  <div>@Input
    <b>name</b>: string (optional)</div>
  <div>@Input
    <b>disabled</b>: boolean (optional)</div>
  <div>@Output
    <b>change</b>: PplCheckboxChange</div>

  <br>

  <h4>PplCheckboxChange</h4>
  <div>
    <b>source</b>: PplCheckboxComponent</div>
  <div>
    <b>checked</b>: boolean</div>

  <br>

  <h3>Example</h3>

  <ppl-input-container label="Checkboxes">
    <h6>Disabled</h6>
    <ppl-checkbox [disabled]="true"
                  [value]="checkbox1"
                  (valueChange)="checkbox1 = $event.checked">Checkbox 1</ppl-checkbox>
    <br>
    <h6>No modifiers</h6>
    <ppl-checkbox [value]="checkbox2"
                  (valueChange)="change($event.checked)">Checkbox 2</ppl-checkbox>
    <br>
    <h6>Autofill</h6>
    <ppl-checkbox [autofill]="true"
                  [value]="checkbox2"
                  (valueChange)="change($event.checked)">Checkbox 2</ppl-checkbox>
    <br>
    <h6>Readonly</h6>
    <ppl-checkbox [readonly]="true"
                  [value]="checkbox2"
                  (valueChange)="change($event.checked)">Checkbox 2</ppl-checkbox>
    <br>
    <h6>Indeterminate</h6>
    <ppl-checkbox [value]="checkbox3"
                  [indeterminate]="checkbox3Indeterminate"
                  (valueChange)="checkbox3 = $event.checked; checkbox3Indeterminate = $event.indeterminate;">Checkbox 3</ppl-checkbox>
    <br>
    <ppl-checkbox [value]="checkbox3Indeterminate"
                  (valueChange)="checkbox3Indeterminate = $event.checked">
      indeterminate state
    </ppl-checkbox>
  </ppl-input-container>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
