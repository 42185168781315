import { ButtonType } from '../../button/button.directive';
import { PplUiIntl } from '../../ppl-ui-intl';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../../tokens';
import { PplDialogRef , PPL_DIALOG_DATA } from '../dialog-ref';
import {
  TemplateRef
,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import { MemoizeLast } from '@ppl/utils';

@Component({
  selector: 'ppl-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplDialogMessageComponent {

  @Input() actionLabel?: string;
  @Input() actionButtonType?: ButtonType;
  @Input() actionVisible = true;
  @Input() cancelLabel?: string;
  @Input() cancelVisible = true;
  @Input() description?: string;
  @Input() noLabel?: string;
  @Input() noButtonType?: ButtonType;
  @Input() text: string;
  @Input() type: 'alert' | 'confirm' | 'info' | 'yesNo' | 'yesNoCancel' | 'warning';
  @Input() width?: string;
  @Input() yesLabel?: string;

  @Output() action = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ContentChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

  MessageIcons = MessageIcons;
  ButtonType = ButtonType;

  @MemoizeLast(['width'])
  get maxWidthValue() {
    return (this.width && this.width.endsWith('vw')) ? this.width : this.getMaxViewportSize().width;
  }

  @MemoizeLast(['width'])
  get widthValue() {
    return this.width;
  }

  @MemoizeLast<PplDialogMessageComponent>(['actionButtonType'])
  get actionButtonTypeValue() {
    return this.actionButtonType || ButtonType.Button4;
  }

  @MemoizeLast<PplDialogMessageComponent>(['noButtonType'])
  get noButtonTypeValue() {
    return this.noButtonType || ButtonType.Button1;
  }

  constructor(
    @Optional() private dialogRef: PplDialogRef<PplDialogMessageComponent>,
    @Optional() @Inject(PPL_DIALOG_DATA) public data: PplDialogMessageData,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES,
    public intl: PplUiIntl
  ) {
    if (this.data) {
      Object.assign(this, data);
    }
  }

  getMaxViewportSize() {
    if (this.ngUiTheme === 'fabric' || this.ngUiTheme === 'material') {
      return { width: '95vw' };
    }

    return { width: '80vw' };
  }

  onClose(value: any) {
    if (value && this.action.observers.length !== 0) {
      this.action.emit();
    } else if (!value && this.cancel.observers.length !== 0) {
      this.cancel.emit();
    } else if (this.dialogRef) {
      this.dialogRef.close(value);
    }
  }

}

const MessageIcons = {
  alert: 'validation-red-error',
  confirm: 'validation-orange-warning',
  info: 'validation-green-info',
  yesNo: 'validation-orange-warning',
  yesNoCancel: 'validation-orange-warning',
  warning: 'validation-orange-warning',
};

export interface PplDialogMessageData {
  actionLabel?: string;
  actionButtonType?: ButtonType;
  cancelVisible?: boolean;
  cancelLabel?: string;
  description?: string;
  noLabel?: string;
  noButtonType?: ButtonType;
  text: string;
  type: 'alert' | 'confirm' | 'info' | 'yesNo' | 'yesNoCancel' | 'warning';
  yesLabel?: string;
}
