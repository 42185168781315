<div class="container"
     [formGroup]="form">
  <div class="row">
    <ppl-input-container label="Phone Name"
                         i18n-label="@@Phone_Name"
                         pplFormControlName="name">
      <input pplInput
             formControlName="name" />
    </ppl-input-container>
    <ppl-input-container label="Phone Number"
                         i18n-label="@@Phone_Number">
      <ppl-input-phone-number [value]="phoneNumber"
                              [disabled]="true"></ppl-input-phone-number>
    </ppl-input-container>
  </div>
  <ppl-content-block *ngIf="displayForwardingSettings"
                     label="Forwarding"
                     i18n-label="@@Forwarding"
                     [displayContent]="true">
    <div class="row">
      <ppl-input-container label="Message Forwarding to Email"
                           i18n-label="@@Message_Forwarding_to_Email"
                           description="All received messages to the purchased phone number will be forwarded to the following email address"
                           i18n-description="@@All_received_messages_to_the_purchased_phone_number_will_be_forwarded_to_the_following_email_address"
                           pplFormControlName="messageForwardingEmail">
        <input pplInput
               formControlName="messageForwardingEmail" />
      </ppl-input-container>
      <ppl-input-container label="Call Forwarding"
                           i18n-label="@@Call_Forwarding"
                           description="All received calls to the purchased phone number will be forwarded to the following phone number (e.g. personal phone number)"
                           i18n-description="@@All_received_calls_to_the_purchased_phone_number_will_be_forwarded_to_the_following_phone_number_(e.g._personal_phone_number)"
                           pplFormControlName="callForwardingPhone">
        <ppl-input-phone-number formControlName="callForwardingPhone"></ppl-input-phone-number>
      </ppl-input-container>
    </div>
  </ppl-content-block>
</div>
