export interface GeneralLocationInterface {
  address?: string;
  city?: string;
  stateProvince?: string;
  country?: string;
}


export function getLocationFormatted(value: GeneralLocationInterface) {
  return [value.address, value.city, value.stateProvince, value.country]
    .filter(Boolean)
    .join(', ');
}
