import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { trackById } from '@ppl/utils';
import { GridRecord } from '../grid.interfaces';
import type { GridAction, GridActionClickEvent } from '../grid.interfaces';

@Component({
  selector: 'ppl-grid-record-actions-button',
  templateUrl: './grid-record-actions-button.component.html',
  styleUrls: ['./grid-record-actions-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridRecordActionsButtonComponent {

  @Input() actions: GridAction[] = [];
  @Input() record: GridRecord;

  @Output() actionClick = new EventEmitter<GridActionClickEvent>();

  trackById = trackById;

}
