<div #messages
     class="messages"
     (scroll)="onScroll()">
  <div *ngFor="let item of items; trackBy: trackById"
       class="message">
    <ppl-calling-conversation-item [data]="item"></ppl-calling-conversation-item>
  </div>
  <div *ngIf="canLoadMore"
       #loading
       class="message">
    <ppl-loading class="loading"></ppl-loading>
  </div>
</div>
