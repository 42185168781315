import { PplDialogRef , PPL_DIALOG_DATA } from '../../dialog';
import { PplUiIntl } from '../../ppl-ui-intl';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import type { SafeUrl  } from '@angular/platform-browser';
import Cropper from 'cropperjs';

const DEFAULT_CROPPER_OPTIONS = {
  aspectRatio: 1,
  minCropBoxWidth: 62,
  minCropBoxHeight: 62,
  minContainerWidth: 150,
  background: false
};

@Component({
  selector: 'ppl-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplImageEditorComponent implements OnInit {
  imageSrc: SafeUrl;
  cropper: Cropper;
  title: string | null = null; // null is default Image Editor (translated)
  changePhoto = 'Change Photo';
  dragTheYellowCorner = 'Drag the yellow corner';
  inOrderToCropUploadedImage = 'in order to crop uploaded image';
  apply = 'Apply';
  cancel = 'Cancel';

  @ViewChild('image', { static: true }) image: ElementRef;

  constructor(
    public dialogRef: PplDialogRef<PplImageEditorComponent>,
    @Inject(PPL_DIALOG_DATA) public data: PplImageEditorData,
    private changeDetectorRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private intl: PplUiIntl
  ) { }

  ngOnInit() {
    this.initImage(this.data.image, this.data.options || {});
    if (this.title === null) {
      this.title = this.intl.imageEditor;
    }
    this.changePhoto = this.intl.changePhoto;
    this.dragTheYellowCorner = this.intl.dragTheYellowCorner;
    this.inOrderToCropUploadedImage = this.intl.inOrderToCropUploadedImage;
    this.apply = this.intl.apply;
    this.cancel = this.intl.cancel;
  }

  initImage(image, options) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(e.target.result);
      this.changeDetectorRef.detectChanges();
      this.changeDetectorRef.markForCheck();
      if (this.cropper) {
        this.cropper.replace(e.target.result);
      } else {
        this.cropper = new Cropper(this.image.nativeElement, { ...DEFAULT_CROPPER_OPTIONS, ...options });
      }
    };
    reader.readAsDataURL(image);
  }

  onRotate(degrees) {
    this.cropper.rotate(degrees);
  }

  onSave() {
    this.dialogRef.close(this.cropper.getCroppedCanvas({ fillColor: '#fff' }));
  }

  onSelectFile(event) {
    if (!event.target.files[0]) {
      return;
    }
    this.initImage(event.target.files[0], this.data.options || {});
  }
}

export interface PplImageEditorData {
  image: any;
  options: any;
}
