import { PplDatePresetsModule } from '@ppl/ui/date-presets';
import { PplDateInputComponent } from './date-input/date-input.component';
import { PplDatePickerIntl } from './date-picker-intl';
import { PplDatePickerComponent } from './date-picker.component';
import { PplButtonModule } from '../button';
import { PplIconModule } from '../icon';
import { PplInputModule } from '../input';
import { PplPopoverModule } from '../popover';
import { PplSelectModule } from '../select';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule, DatePipe } from '@angular/common';
import type { ModuleWithProviders} from '@angular/core';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplInputModule,
    PplButtonModule,
    PplIconModule,
    PplPopoverModule,
    PplSelectModule,
    PplDatePresetsModule,
    A11yModule
  ],
  declarations: [
    PplDatePickerComponent,
    PplDateInputComponent
  ],
  exports: [
    PplDatePickerComponent,
    PplDateInputComponent
  ],
  providers: [
    DatePipe
  ]
})
export class PplDatePickerModule {
  static forRoot(): ModuleWithProviders<PplDatePickerModule> {
    return {
      ngModule: PplDatePickerModule,
      providers: [
        PplDatePickerIntl
      ]
    };
  }
}

export * from './date-input/date-input.component';
export * from './date-picker-intl';
export * from './date-picker.component';

