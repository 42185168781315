import { ErrorCode } from '../../../auth/src/lib/domain/enums/error-code';
import { I18nPluralPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService<T extends { [K in string]: string } = never> {
  private translateCache: { [id: string]: string } = {};
  private extraMarkers: { [marker: string]: any };

  static translations: { [id: string]: string } = {};

  constructor(
    private pluralPipe: I18nPluralPipe
  ) { }

  registerTranslations(translations: I18nTranslations) {
    I18nService.translations = translations;
  }

  registerExtraMarkers(markers: { [marker: string]: any }) {
    this.extraMarkers = markers;
  }

  translate(translationId: keyof T, markers?: { [marker: string]: string | number }, translations?: I18nTranslations): string;
  translate(translationId: string, markers?: { [marker: string]: string | number }, translations?: I18nTranslations): string {
    const useCache = !(markers || translations);

    if (this.translateCache[translationId] && useCache) {
      return this.translateCache[translationId];
    }

    const id = this.undescorize(translationId);
    let content = (translations && translations[id]) || I18nService.translations[id] || id;

    if (markers || content.includes('%')) {
      const interpolationMarkers = this.extraMarkers
        ? { ...this.extraMarkers, ...markers }
        : markers || {};
      const markerKeys = Object.keys(interpolationMarkers);
      markerKeys.forEach(marker => (content = content.replace(new RegExp(`%${marker}%`, 'g'), this.escapeReplacementString(String(interpolationMarkers[marker])))));
    } else {
      if (useCache) {
        this.translateCache[translationId] = content;
      }
    }

    return content;
  }

  translatePlural(value: number, singularTranslation: string, pluralTranslation: string, zeroTranslation?: string) {
    const pluralMap: { [k: string]: string } = {
      '=0': zeroTranslation ? this.translate(zeroTranslation) : this.translate(pluralTranslation),
      '=1': this.translate(singularTranslation),
      'other': this.translate(pluralTranslation)
    };
    const translation = this.pluralPipe.transform(value, pluralMap);

    return translation;
  }

  translateEnumValue(enumName: string, key: any): string {
    return this.translate(`Enum${enumName}_${key}`);
  }

  translateErrorCode(errorCode: ErrorCode): string {
    return this.translate(`EnumErrorCode_${ErrorCode[errorCode]}`);
  }

  escapeReplacementString(value: string) {
    // NOTE(mike): When using `String.replace` and you are using regexp as a search string
    // then the replacement string can include special replacement patterns
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_string_as_a_parameter
    // This can cause problems in some situations. For example in IE11 when
    // translating a string like `%value% money` and the marker has the value `$0.00`,
    // then without escaping the replacement string, the whole string will be translated
    // as `%value%.00 money`, because IE11 is using `$0` as a special replacement pattern and is
    // replacing it with the matched string, which in our case is the marker itself `%value%`.
    return value.replace(/[$]/g, '$$$&');
  }

  undescorize(text: String): string {
    if (!text) {
      return '';
    }
    return text.replace(/\s/g, '_');
  }

  translationExists(translationId: string) {
    return !!I18nService.translations[this.undescorize(translationId)];
  }
}

interface I18nTranslations {
  [id: string]: string;
}
