<div class="container demo-content">
  <h2>Selector View</h2>
  <br>

  <h3>Component</h3>
  <h4>ppl-selector-view</h4>
  <div>@Input() <b>value</b>: any</div>
  <div>@Input() <b>valueTemplate</b>: TemplateRef&lt;any&gt;</div>
  <div>@Input() <b>pickerPopoverTemplate</b>: TemplateRef&lt;any&gt;</div>
  <div>@Input() <b>loading</b> = false</div>
  <div>@Input() <b>available</b> = true</div>
  <br>
  <div>@Output() <b>contentClick</b>: any</div>
  <div>@Output() <b>pickerClick</b>: SelectorViewPickerEvent</div>
  <br>
  <br>

  <h3>Example</h3>
  <ppl-selector-view [value]="value"
                     [loading]="loading"
                     [valueTemplate]="valueTemplate"
                     [pickerPopoverTemplate]="pickerPopoverTemplate"
                     (contentClick)="onContentClick($event)"
                     (pickerClick)="onPickerClick($event)"></ppl-selector-view>
  <br>
  <br>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>

<ng-template #valueTemplate
             let-value>
  <span>{{ optionsById[value].label }}</span>
</ng-template>

<ng-template #pickerPopoverTemplate
             let-value
             let-popover="popover">
  <ppl-option-list [options]="options"
                   [value]="optionListValue || value"
                   (optionSelect)="onOptionSelect(popover, $event)"
                   (valueChange)="onOptionChange($event)"></ppl-option-list>
</ng-template>