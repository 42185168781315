<div class="container demo-content">
  <div>// TODO: resolve circular dependency</div>
  <h2>Radio buttons</h2>

  <br>

  <h3>Module</h3>
  <div>PplRadioModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-radio-group</h4>
  <div>@Input <b>label</b>: string</div>
  <div>@Input <b>name</b>: string</div>
  <div>@Input <b>value</b>: any</div>
  <div>@Input <b>disabled</b>: boolean</div>
  <div>@Input <b>required</b>: boolean</div>
  <div>@Output <b>change</b>: PplRadioChange</div>

  <br>

  <h4>ppl-radio-button</h4>
  <div>@Input <b>value</b>: any</div>
  <div>@Input <b>name</b>: string</div>
  <div>@Input <b>checked</b>: boolean</div>
  <div>@Input <b>disabled</b>: boolean</div>
  <div>@Input <b>required</b>: boolean</div>
  <div>@Output <b>change</b>: PplRadioChange</div>

  <br>

  <h4>PplRadioChange</h4>
  <div><b>source</b>: PplRadioButtonComponent </div>
  <div><b>value</b>: any</div>

  <br>

  <h3>Example</h3>

  <ppl-radio-group [value]="value"
                   (valueChange)="value = $event.value"
                   label="Label">
    <ppl-radio-button [value]="1">Radio 1</ppl-radio-button><br>
    <ppl-radio-button [value]="3"
                      [autofill]="true">Radio 3 autofilled</ppl-radio-button><br>
    <ppl-radio-button [value]="4">Radio 4</ppl-radio-button>
  </ppl-radio-group>

  Selected value: {{value}}<br>

  <br>

  <ppl-radio-group label="Disabled Radio">
    <ppl-radio-button [value]="'2'"
                      [checked]="true"
                      [disabled]="true">Radio 2</ppl-radio-button>
  </ppl-radio-group>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
