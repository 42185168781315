import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { PplSelectOption } from '@ppl/ui/select';

@Component({
  selector: 'ppl-select-demo',
  templateUrl: './select-demo.component.html',
  styleUrls: ['./select-demo.component.scss']
})
export class SelectDemoComponent implements OnInit {
  values: PplSelectOption[] = [
    { value: 'value-1', label: 'AValue 1', icon: 'task-feed_blue' },
    { value: 'value-2', label: 'AValue 2', icon: 'account-avatar' },
    { value: 'value-3', label: 'AValue 3', icon: 'add-button' },
    { value: 'value-4', label: 'bValue 4', icon: 'activity' },
    { value: 'value-11', label: 'cValue 11', icon: 'add-powerpanel-tab' },
    { value: 'value-12', label: 'dValue 12', icon: 'appointment' },
    { value: 'value-13', label: 'Value 13', icon: 'attach' },
    { value: 'value-14', label: 'Value 14', icon: 'call' },
    { value: 'value-21', label: 'Value 21', icon: 'checkmark' },
    { value: 'value-22', label: 'Value 22', icon: 'closering' },
    { value: 'value-23', label: 'gValue 23', icon: 'colorpicker' },
    { value: 'value-24', label: 'hValue 24', icon: 'documents' },
    { value: 'value-31', label: 'uuValue 31', icon: 'emoji' },
    { value: 'value-32', label: 'iValue 32', icon: 'email' },
    { value: 'value-33', label: 'kkValue 33', icon: 'location' },
    { value: 'value-34', label: 'kValue 34', icon: 'product' },
    { value: 'value-35', label: 'Disabled', disabled: true }
  ];

  colorValues: PplSelectOption[] = [
    { value: 'value-1', label: 'Color 1', color: '#2A4F7E' },
    { value: 'value-2', label: 'Color 2', color: '#2A4F4E' },
    { value: 'value-3', label: 'Color 3', color: 'red' }
  ];

  deletedSelectOptions: PplSelectOption[] = [
    { value: 'value-1', label: 'AValue 1' },
    { value: 'value-2', label: 'AValue 2 deleted', deleted: true },
    { value: 'value-3', label: 'AValue 3' },
    { value: 'value-4', label: 'bValue 4' },
  ];
  deletedSelectSelectedValue = 'value-2';

  selectedValue = 'value-1';

  code = `
  <ppl-input-container label="Select">
    <ppl-select [options]="values"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Select Error"
                       hasError="true">
    <ppl-select [options]="values"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Select with second option deleted & selected (not viable to reselect again)">
    <ppl-select [options]="deletedSelectOptions"
                [value]="deletedSelectSelectedValue"
                (valueChange)="deletedSelectSelectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Disabled">
    <ppl-select [options]="values"
                [value]="selectedValue"
                [disabled]="true"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Readonly">
    <ppl-select [options]="values"
                [value]="selectedValue"
                [readonly]="true"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Filled">
    <ppl-select [options]="values"
                [fill]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="With icons">
    <ppl-select [options]="values"
                [hasIcons]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="With colors">
    <ppl-select [options]="colorValues"
                [hasColors]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Filled with icons">
    <ppl-select [options]="values"
                [fill]="true"
                [hasIcons]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>

  <ppl-input-container label="Big filled with icons">
    <ppl-select [options]="values"
                [big]="true"
                [hasIcons]="true"
                [fill]="true"
                [value]="selectedValue"
                (valueChange)="selectedValue = $event"></ppl-select>
  </ppl-input-container>
`;

  constructor() { }

  ngOnInit() { }
}
