import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { PplPartialSelectOption, PplPartialSelectValue } from '@ppl/ui/partial-select';

@Component({
  selector: 'ppl-partial-select-demo',
  templateUrl: './partial-select-demo.component.html',
  styleUrls: ['./partial-select-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartialSelectDemoComponent implements OnInit {

  value: PplPartialSelectValue = {
    all: true,
    selectedValues: []
  };
  options: PplPartialSelectOption[] = [
    {
      value: 'value1',
      label: 'label1',
      color: 'red'
    },
    {
      value: 'value2',
      label: 'label2',
      color: 'blue'
    },
    {
      value: 'value3',
      label: 'label3',
      color: 'green'
    }
  ];

  code = `
    <ppl-partial-select [value]="value"
                        [options]="options"
                        (valueChange)="value = $event"></ppl-partial-select>
  `;

  constructor() { }

  ngOnInit() {
  }
}
