<div>
  <div class="center">
    {{screenName}}
  </div>

  <div class="left">
  </div>
  <div class="right">
  </div>
</div>
