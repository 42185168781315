import { ElementRef , Component } from '@angular/core';

@Component({
  selector: 'ppl-bottom-bar-submenu-button',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./bottom-bar-submenu-button.component.scss']
})
export class PplBottomBarSubmenuButtonComponent {

  constructor(public elementRef: ElementRef) {}

}
