import { OptionListCategoriesComponent } from './option-list-categories/option-list-categories.component';
import { PplOptionListComponent } from './option-list.component';
import { PplIconModule } from '../icon';
import { PplLoadingModule } from '../loading';
import { PplVirtualScrollModule } from '../virtual-scroll';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule,
    PplIconModule,
    PplLoadingModule,
    PplVirtualScrollModule
  ],
  declarations: [
    PplOptionListComponent,
    OptionListCategoriesComponent
  ],
  exports: [
    PplOptionListComponent
  ]
})
export class PplOptionListModule {
}

export * from './option-list.component';
