<ppl-container [opened]="opened"
               [sidebarWidth]="414"
               [underBreakpointSidebarWidth]="210"
               [breakPoint]="1693"
               [mode]="mode"
               [position]="position"
               (underBreakpointChange)="onUnderBreakpointChange($event)">
  <ppl-sidebar style="background: rgba(0,0,0,0.2)">
    <p style="background: #1c5290; color: #ffffff;">
      sidebar content
    </p>
  </ppl-sidebar>
  <ppl-content>
    <div class="demo-content">
      <h2>Container</h2>
      <br>
      <h3>Components</h3>
      <h4>ppl-container</h4>
      <div>@Input() <b>opened</b>: boolean <i>(default false)</i></div>
      <div>@Input() <b>sidebarWidth</b>: number <i>(default 200)</i></div>
      <div>@Input() <b>underBreakpointSidebarWidth</b>: number <i>(default 210)</i></div>
      <div>@Input() <b>position</b>: 'start' | 'end' <i>(default 'start')</i></div>
      <div>@Input() <b>mode</b>: 'push' | 'over' | 'auto' <i>(default 'push')</i></div>
      <div>@Input() <b>breakPoint</b>: number <i>(default null)</i></div>
      <div>@Input() <b>animate</b>: 'always' | 'breakpoint' <i>(default always)</i></div>
      <br>
      <div>@Output() <b>underBreakpointChange</b>: boolean</div>
      <br>
      <div>@ContentChild(PplContentComponent) <b>content</b>: PplContentComponent</div>
      <div>@ContentChild(PplSidebarComponent) <b>sidebar</b>: PplSidebarComponent</div>
      <br>
      <br>

      <h4>Playground</h4>
      <p style="text-align: center">
        breakpoint: 1693px
        <ng-container *ngIf="mode === 'breakpoint'">
          <br>
          under breakpoint: {{underBreakpoint ? 'Y' : 'N'}}
        </ng-container>
        <br>
        sidebar width: 414px
        <br>
        under breakpoint sidebar width: 210px
        <br>
        <ppl-input-container label="Mode"
                             style="width: 150px; margin: auto;">
          <ppl-select [value]="mode"
                      [options]="modeValues"
                      (valueChange)="mode = $event"></ppl-select>
        </ppl-input-container>
        <br>
        <ppl-input-container label="Position"
                             style="width: 150px; margin: auto;">
          <ppl-select [value]="position"
                      [options]="positionValues"
                      (valueChange)="position = $event"></ppl-select>
        </ppl-input-container>
        <br>
        <button pplButton
                pplButton1
                (click)="onToggleOpened()">
          {{opened ? 'close' : 'open'}} sidebar
        </button>
      </p>
    </div>
  </ppl-content>
</ppl-container>
