import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef,
  NgZone,
  Renderer2,
  TemplateRef} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import type { SafeHtml  } from '@angular/platform-browser';
import { PplVirtualScrollForOfDirective } from './virtual-scroll-for-of/virtual-scroll-for-of.directive';

@Component({
  selector: 'ppl-virtual-scroll',
  templateUrl: 'virtual-scroll.component.html',
  styleUrls: ['virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplVirtualScrollComponent implements OnChanges, OnDestroy, OnInit {

  @Input() columnWidth: number;
  @Input() containerMargin = [0, 0, 0, 0];
  @Input() data: any[]; // Required
  @Input() dataKey?: string;
  @Input() dataTemplate: TemplateRef<any>; // Required
  @Input() dynamicHeight = false;
  @Input() enableHorizontalScroll = false;
  @Input() extraData: any;
  @Input() frozenPanes: VirtualScrollFrozenPane[];
  @Input() margin = [0, 0, 0, 0];
  @Input() minRowHeight: number; // Required
  @Input() rowHeights: number[];
  @Input() scrollToIndex?: number;
  @Input() scrollWidth?: number;
  @Input() @HostBinding('class.scrollbar-hidden') scrollbarHidden = false;
  @Input() @HostBinding('class.scrollbar-on-hover') scrollbarOnHover = false;
  @Input() variableRowHeight = false;

  @Output() containerClick = new EventEmitter<any>();
  @Output() containerDoubleClick = new EventEmitter<any>();
  @Output() startIndexChange = new EventEmitter<number>();
  @Output() virtualScreenChange = new EventEmitter<VirtualScreen>();
  @Output() scrollChange = new EventEmitter<number>();

  @ViewChild('recordsContainer', { static: true }) recordsContainer: ElementRef;
  @ViewChild(PplVirtualScrollForOfDirective, { static: true }) forOfDirective: PplVirtualScrollForOfDirective;

  columnCount = 1;
  internalRowHeight: number;
  frameHandle: number;
  frozenPaneTop: VisibleFrozenPane | null = null;
  frozenPaneTopLeft: VisibleFrozenPane | null = null;
  frozenPanesLeft: VisibleFrozenPane[] = [];
  measureFrameHandle: number;
  initFrameHandle: number;
  overscanStartIndex = 0;
  overscanStopIndex = -1;
  startIndex = 0;
  prevScrollTop = 0;
  rawPrevScrollTop = 0;
  rawPrevScrollLeft = 0;
  resizeHandler: any;
  rowHeightCache: number[] = [];
  scrollHeight = 0;
  visibleRecords: any[] = [];
  clientRectRenderHandle: number;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer
  ) {
    this.onFrame = this.onFrame.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    const minRowHeightChanged = changes.minRowHeight && !changes.minRowHeight.firstChange;
    const rowHeightsChanged = changes.rowHeights && !changes.rowHeights.firstChange;
    const dataChanged = changes.data && !changes.data.firstChange;

    if (minRowHeightChanged || rowHeightsChanged) {
      this.internalRowHeight = this.minRowHeight;

      this.updateScrollHeight();
      this.updateLayout({ forceUpdate: true, finalUpdate: false });
    }

    if (dataChanged) {
      // Update layout only if data are present or previously were present
      const forceUpdate = changes.data.currentValue.length || changes.data.previousValue.length;

      if (forceUpdate) {
        if (this.variableRowHeight && changes.data.currentValue.length !== changes.data.previousValue.length) {
          this.rowHeightCache = Array(changes.data.currentValue.length);
        }

        if (!this.variableRowHeight) {
          this.updateScrollHeight();
        }

        this.updateLayout({ forceUpdate, finalUpdate: false });
      }
    }
  }

  ngOnInit() {
    this.internalRowHeight = this.minRowHeight;

    if (this.enableHorizontalScroll) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'overflow-x', 'auto');
    }

    this.rowHeightCache = Array(this.data.length);
    this.updateScrollHeight();

    // Ensure layout is updated after render
    if (this.data.length) {
      // this.ngZone.onMicrotaskEmpty.asObservable().first().subscribe(() => {
      this.initFrameHandle = requestAnimationFrame(() => {
        if (this.scrollToIndex !== undefined) {
          this.scrollToRecord(this.scrollToIndex);
        }

        // Update layout at initialization only if data are already present
        this.updateLayout({ forceUpdate: true, finalUpdate: false });
      });
    }

    window.addEventListener('resize', this.resizeHandler = this.onResize.bind(this));
  }

  ngOnDestroy() {
    cancelAnimationFrame(this.frameHandle);
    cancelAnimationFrame(this.initFrameHandle);
    cancelAnimationFrame(this.measureFrameHandle);
    cancelAnimationFrame(this.clientRectRenderHandle);

    window.removeEventListener('resize', this.resizeHandler);
  }

  getScroll() {
    return {
      x: this.elementRef.nativeElement.scrollLeft,
      y: this.elementRef.nativeElement.scrollTop
    };
  }

  setScroll(position: { x: number, y: number }) {
    this.elementRef.nativeElement.scrollLeft = position.x;
    this.elementRef.nativeElement.scrollTop = position.y;
  }

  scrollTo(position: number) {
    this.elementRef.nativeElement.scrollTop = position;
  }

  scrollToRecord(index: number) {
    this.elementRef.nativeElement.scrollTop = this.getRecordRect(index).top;
  }

  forceUpdateLayout() {
    this.rowHeightCache = Array(this.data.length);
    this.updateLayout({ forceUpdate: true, finalUpdate: false });
  }

  getRecordRect(index: number) {
    // TODO: Use this method in getRecordStyle somehow to avoid duplication
    const internalRowHeightMargin = this.internalRowHeight + this.margin[0] + this.margin[2];

    let topOffset = index * internalRowHeightMargin;
    let height = internalRowHeightMargin;

    if (this.variableRowHeight || this.rowHeights) {
      const rowHeightCache = this.rowHeights || this.rowHeightCache;
      topOffset = 0;

      for (let rowIndex = 0; rowIndex < index; rowIndex++) {
        topOffset += rowHeightCache[rowIndex] !== undefined
          ? rowHeightCache[rowIndex] + this.margin[0] + this.margin[2]
          : internalRowHeightMargin;
      }

      if (rowHeightCache[index] !== undefined) {
        height = rowHeightCache[index];
      }
    }

    return {
      top: topOffset + this.containerMargin[0] + this.margin[0],
      height: height
    };
  }

  getRecordStyle(index: number) {
    const internalRowHeightMargin = this.internalRowHeight + this.margin[0] + this.margin[2];

    let leftOffset = 0;
    let topOffset = index * internalRowHeightMargin;
    let heightStyle: any = { height: `${this.internalRowHeight}px` };

    if (this.variableRowHeight || this.rowHeights) {
      const rowHeightCache = this.rowHeights || this.rowHeightCache;
      topOffset = 0;

      for (let rowIndex = 0; rowIndex < index; rowIndex++) {
        topOffset += rowHeightCache[rowIndex] !== undefined
          ? rowHeightCache[rowIndex] + this.margin[0] + this.margin[2]
          : internalRowHeightMargin;
      }

      heightStyle = (rowHeightCache[index] !== undefined) ? { height: `${rowHeightCache[index]}px` } : {};
    }

    if (this.columnWidth !== undefined) {
      const columnWidth = this.columnWidth + this.margin[1] + this.margin[3];

      leftOffset = (index % this.columnCount) * columnWidth;
      topOffset = Math.floor(index / this.columnCount) * internalRowHeightMargin;
    }

    return {
      ...heightStyle,
      left: `${leftOffset + this.containerMargin[3] + this.margin[3]}px`,
      position: 'absolute',
      top: `${topOffset + this.containerMargin[0] + this.margin[0]}px`,
      visibility: this.data[index] ? 'visible' : 'hidden',
      width: this.enableHorizontalScroll ? 'auto' : (this.columnWidth !== undefined ? `${this.columnWidth}px` : '100%')
    };
  }

  getRecordIndex(record) {
    return this.visibleRecords.indexOf(record);
  }

  updateScrollHeight() {
    const internalRowHeightMargin = this.internalRowHeight + this.margin[0] + this.margin[2];

    if (this.variableRowHeight || this.rowHeights) {
      const rowHeightCache = this.rowHeights || this.rowHeightCache;
      let scrollHeight = 0;

      for (let rowIndex = 0; rowIndex < rowHeightCache.length; rowIndex++) {
        scrollHeight += rowHeightCache[rowIndex] !== undefined
          ? rowHeightCache[rowIndex] + this.margin[0] + this.margin[2]
          : internalRowHeightMargin;
      }

      this.scrollHeight = scrollHeight + this.containerMargin[0] + this.containerMargin[2];
    } else {
      this.scrollHeight = Math.ceil(this.data.length / this.columnCount) * internalRowHeightMargin + this.containerMargin[0] + this.containerMargin[2];
    }

    // Force scrollbar if scrollWidth is defined, even when no data is present
    if (this.data.length === 0 && this.scrollWidth !== undefined) {
      this.scrollHeight = 1;
    }
  }

  updateLayout({ forceUpdate, finalUpdate } = { forceUpdate: false, finalUpdate: false }) {
    const scrollTop = Math.max(Math.max(this.elementRef.nativeElement.scrollTop, 0) - this.containerMargin[0], 0);

    if (this.frozenPanes && this.frozenPanes.length !== 0) {
      const scrollTopChanged = this.rawPrevScrollTop !== this.elementRef.nativeElement.scrollTop;
      const scrollLeftChanged = this.rawPrevScrollLeft !== this.elementRef.nativeElement.scrollLeft;

      if (scrollTopChanged || scrollLeftChanged) {
        this.rawPrevScrollTop = this.elementRef.nativeElement.scrollTop;
        this.rawPrevScrollLeft = this.elementRef.nativeElement.scrollLeft;

        this.updateFrozenPanes(scrollTopChanged, scrollLeftChanged, this.rawPrevScrollTop, this.rawPrevScrollLeft, this.elementRef.nativeElement.getBoundingClientRect());
      }
    }

    if (!forceUpdate && scrollTop === this.prevScrollTop) {
      return;
    }

    if (this.prevScrollTop !== scrollTop) {
      this.ngZone.run(() => {
        this.scrollChange.emit(scrollTop);
      });
    }

    const clientRect = this.elementRef.nativeElement.getBoundingClientRect() as ClientRect;

    cancelAnimationFrame(this.clientRectRenderHandle);

    if (this.dynamicHeight) {
      // If container height is zero, we should try to wait for layout calculation
      this.clientRectRenderHandle = requestAnimationFrame(() => {
        const clientRectDefer = this.elementRef.nativeElement.getBoundingClientRect() as ClientRect;

        this.updateLayoutInternal(forceUpdate, finalUpdate, clientRectDefer, scrollTop);
      });
    } else {
      this.updateLayoutInternal(forceUpdate, finalUpdate, clientRect, scrollTop);
    }
  }

  updateLayoutInternal(forceUpdate: boolean, finalUpdate: boolean, clientRect: ClientRect, scrollTop: number) {
    const internalRowHeightMargin = this.internalRowHeight + this.margin[0] + this.margin[2];
    const maxRecordIndex = Math.max(this.data.length - 1, 0);

    // Visible records
    if (this.columnWidth !== undefined) {
      const clientWidth = Math.floor(clientRect.width) - MAX_SCROLLBAR_WIDTH - this.containerMargin[1] - this.containerMargin[3];
      const columnWidth = this.columnWidth + this.margin[1] + this.margin[3];

      const columnCount = Math.max(Math.floor(clientWidth / columnWidth), 1);
      if (columnCount !== this.columnCount) {
        this.columnCount = columnCount;
        this.updateScrollHeight();
      }
    }

    let startIndex = Math.min(Math.floor(scrollTop / internalRowHeightMargin) * this.columnCount, maxRecordIndex);
    let screenSize = Math.ceil(Math.floor(clientRect.height) / internalRowHeightMargin) * this.columnCount;
    let stopIndex = Math.min(startIndex + screenSize, maxRecordIndex);

    if (this.variableRowHeight || this.rowHeights) {
      const rowHeightCache = this.rowHeights || this.rowHeightCache;
      let foundStartIndex = false;
      let foundStopIndex = false;
      let rowTop = 0;
      let rowBottom = 0;

      for (let rowIndex = 0; rowIndex < rowHeightCache.length; rowIndex++) {
        rowBottom = rowTop + (rowHeightCache[rowIndex] !== undefined
          ? rowHeightCache[rowIndex] + this.margin[0] + this.margin[2]
          : internalRowHeightMargin);

        if (!foundStartIndex && scrollTop >= rowTop && scrollTop < rowBottom) {
          startIndex = rowIndex;
          foundStartIndex = true;
        }

        if (foundStartIndex && (scrollTop + clientRect.height) >= rowTop && (scrollTop + clientRect.height) <= rowBottom) {
          stopIndex = rowIndex;
          foundStopIndex = true;
          break;
        }

        rowTop = rowBottom;
      }

      if (!foundStartIndex || !foundStopIndex) {
        // Scroll is out of bounds
        startIndex = Math.max(maxRecordIndex - screenSize + 1, 0);
        stopIndex = maxRecordIndex;
      }

      screenSize = Math.min(stopIndex - startIndex + 1, this.data.length);
    }

    if (startIndex !== this.startIndex) {
      this.startIndex = startIndex;
      this.startIndexChange.emit(this.startIndex);
    }

    // Rendered records
    const overscanSize = Math.ceil(screenSize * 0.2) * this.columnCount;
    const overscanStartIndex = Math.max(startIndex - overscanSize, 0);
    const overscanStopIndex = this.data.length ? Math.min(stopIndex + overscanSize, maxRecordIndex) : -1;
    const boundsChanged = overscanStartIndex !== this.overscanStartIndex || overscanStopIndex !== this.overscanStopIndex;

    if (forceUpdate || boundsChanged) {
      this.ngZone.run(() => {
        this.overscanStartIndex = overscanStartIndex;
        this.overscanStopIndex = overscanStopIndex;

        this.visibleRecords = this.data.slice(this.overscanStartIndex, this.overscanStopIndex + 1);

        this.changeDetector.detectChanges();

        if (this.variableRowHeight && !finalUpdate) {
          // Measure next frame (otherwise some CSS styles remain stuck at previous value)
          this.measureFrameHandle = requestAnimationFrame(() => {
            // Measure real record heights and update layout again
            const recordElements = this.recordsContainer.nativeElement.children;

            for (let rowIndex = this.overscanStartIndex; rowIndex <= this.overscanStopIndex; rowIndex++) {
              if (this.rowHeightCache[rowIndex] === undefined) {
                const viewRefIndex = this.forOfDirective.getViewRefIndexByRecord(this.data[rowIndex]);

                if (recordElements[viewRefIndex]) {
                  this.rowHeightCache[rowIndex] = recordElements[viewRefIndex].clientHeight;
                }
              }
            }

            this.updateScrollHeight();
            this.updateLayout({ forceUpdate: true, finalUpdate: true });
          });

          return;
        }

        if (boundsChanged || finalUpdate) {
          // Break infinite loop if updateLayout is invoked from ngOnChanges
          setTimeout(() => {
            this.virtualScreenChange.emit({
              overscanStartIndex,
              overscanStopIndex,
              startIndex,
              stopIndex
            });
          }, 0);
        }
      });
    }

    this.prevScrollTop = scrollTop;
  }

  updateFrozenPanes(topChanged: boolean, leftChanged: boolean, scrollTop: number, scrollLeft: number, elementClientRect: ClientRect) {
    // Determine visible frozen panes
    const leftFrozenPanes: VirtualScrollFrozenPane[] = [];
    let topFrozenPane: VirtualScrollFrozenPane | null = null;

    for (let i = 0; i < this.frozenPanes.length; i++) {
      const frozenPane = this.frozenPanes[i];

      const startRect = this.getRecordRect(frozenPane.startIndex);
      const stopRect = this.getRecordRect(frozenPane.stopIndex);

      const startOffset = startRect.top;
      const stopOffset = stopRect.top + stopRect.height;

      if (frozenPane.topContent && scrollTop >= startOffset && scrollTop <= stopOffset - frozenPane.topHeight) {
        topFrozenPane = frozenPane;
      }

      if (frozenPane.leftContent && scrollTop < stopOffset && scrollTop + elementClientRect.height > startOffset
        && scrollLeft >= frozenPane.startLeft && scrollLeft <= frozenPane.stopLeft - frozenPane.leftWidth) {
        leftFrozenPanes.push(frozenPane);
      }
    }

    // Update template variables
    this.frozenPaneTop = null;
    this.frozenPaneTopLeft = null;

    if (topFrozenPane) {
      this.frozenPaneTop = {
        content: topFrozenPane.topBypassSanitizer ? this.domSanitizer.bypassSecurityTrustHtml(topFrozenPane.topContent) : topFrozenPane.topContent,
        id: topFrozenPane.id,
        left: this.containerMargin[3],
        top: scrollTop
      };
    }

    this.frozenPanesLeft = leftFrozenPanes.map(leftFrozenPane => ({
      content: leftFrozenPane.leftBypassSanitizer ? this.domSanitizer.bypassSecurityTrustHtml(leftFrozenPane.leftContent) : leftFrozenPane.leftContent,
      id: leftFrozenPane.id,
      left: scrollLeft,
      top: this.getRecordRect(leftFrozenPane.startIndex).top
    }));

    if (topFrozenPane) {
      if (leftFrozenPanes.find(leftFrozenPane => leftFrozenPane.id === topFrozenPane.id)) {
        this.frozenPaneTopLeft = {
          content: topFrozenPane.topLeftBypassSanitizer ? this.domSanitizer.bypassSecurityTrustHtml(topFrozenPane.topLeftContent) : topFrozenPane.topLeftContent,
          id: topFrozenPane.id,
          left: scrollLeft,
          top: scrollTop
        };
      }
    }

    this.changeDetector.detectChanges();
  }

  @HostListener('scroll')
  onScroll() {
    cancelAnimationFrame(this.frameHandle);

    this.ngZone.runOutsideAngular(() => {
      this.frameHandle = requestAnimationFrame(this.onFrame);
    });
  }

  @HostListener('click')
  onHostClick() {
    this.containerClick.emit();
  }

  @HostListener('dblclick')
  onHostDoubleClick() {
    this.containerDoubleClick.emit();
  }

  onFrame() {
    this.updateLayout();
  }

  onResize() {
    this.forceUpdateLayout();
  }

  trackFrozenPane(index, pane) {
    return pane.id;
  }

}

const MAX_SCROLLBAR_WIDTH = 17;

export function getDefaultVirtualScreen(value = 0) {
  return {
    overscanStartIndex: value,
    overscanStopIndex: value,
    startIndex: value,
    stopIndex: value
  } as VirtualScreen;
}

interface VisibleFrozenPane {
  content: SafeHtml;
  id: string;
  left: number;
  top: number;
}

export interface VirtualScreen {
  readonly overscanStartIndex: number;
  readonly overscanStopIndex: number;
  readonly startIndex: number;
  readonly stopIndex: number;
}

export interface VirtualScrollFrozenPane {
  readonly id: string;
  readonly leftContent?: any;
  readonly leftBypassSanitizer?: boolean;
  readonly leftWidth?: number;
  readonly startIndex: number; // Row index
  readonly startLeft?: number; // Pixels
  readonly stopIndex: number; // Row index
  readonly stopLeft?: number; // Pixels
  readonly topContent?: any;
  readonly topHeight?: number;
  readonly topBypassSanitizer?: boolean;
  readonly topLeftContent?: any;
  readonly topLeftBypassSanitizer?: boolean;
}
