<div class="container demo-content">
  <h2>App Container</h2>

  <br>

  <h3>Module</h3>
  <div>PplAppContainerModule</div>

  <br>

  <h3>Components</h3>
  <h4>ppl-app-container</h4>
  <div>@Input() <b>topBar</b>: PplTopBarComponent (optional)</div>
  <div>@Input() <b>bottomBar</b>: PplBottomBarComponent (optional)</div>

  <br>

  <h3>Code Example</h3>
  <ppl-prism [code]="code"></ppl-prism>
</div>
