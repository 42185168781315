import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { PplPopoverPosition } from '@ppl/ui/popover';

@Component({
  selector: 'ppl-popover-demo',
  templateUrl: './popover-demo.component.html',
  styleUrls: ['./popover-demo.component.scss']
})
export class PopoverDemoComponent implements OnInit {

  direction = 'up';

  code = `
    <div pplPopover
        pplPopoverTooltip="Test tooltip">
      Test
    </div>

    <div pplPopover
        pplPopoverTooltip="Test tooltip"
        [pplPopoverTooltipPosition]="direction">
      Test
    </div>
  `;

  constructor() { }

  ngOnInit() {

  }

}
