import type { PplAccountClass } from '../account-class';

// main: border
// base: background
export const ACCOUNT_CLASS_COLORS: { [Value in PplAccountClass]: { main: string, base: string } } = {
  NoClass: { main: '#4C7DB0', base: '#EBF7FF' },
  ClassA: { main: '#67A615', base: '#DFF6DD' },
  ClassB: { main: '#FFBB00', base: '#FFF1CC' },
  ClassC: { main: '#F4781F', base: '#FFD19A' },
  ClassD: { main: '#D54135', base: '#FADEDE' },
};
