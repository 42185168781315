import type {
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  Directive,
  EventEmitter,
  Input,
  Output
,
  ElementRef,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[pplInfiniteScroll]'
})
export class PplInfiniteScrollDirective implements OnDestroy, OnInit {
  @Input() pplInfiniteScrollBottomTrigger = 200;

  destroyScroll: () => void;
  destroyResize: () => void;

  @Output() pplInfiniteScrollLoadMore = new EventEmitter<null>();

  lastOutputedWhenHeight = 0;

  get container(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get isElementScrollable() {
    return this.container.offsetHeight !== this.container.scrollHeight;
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {
    this.destroyResize = this.renderer.listen('window', 'resize', () => this.onResize());
    this.destroyScroll = this.renderer.listen(this.container, 'scroll', () => this.onScroll());
  }

  ngOnDestroy(): void {
    this.destroyListener(this.destroyResize);
    this.destroyListener(this.destroyScroll);
  }

  private destroyListener(listener: () => void) {
    if (listener) {
      listener();
    }
  }

  private onScroll() {
    this.checkIfNeedToLoadMore();
  }

  private onResize() {
    this.checkIfNeedToLoadMore();
  }

  private checkIfNeedToLoadMore() {
    const scrolledFromBottom = this.container.scrollHeight - this.container.scrollTop - this.container.offsetHeight;
    // if container is scrollable at all
    const isElementScrollable = this.isElementScrollable;
    // if container is scrolled enough from the bottom
    const scrolledEnoughFromBottom = scrolledFromBottom <= this.pplInfiniteScrollBottomTrigger;
    // if it should be emitted again
    const heightChangedFromLastEmit = this.lastOutputedWhenHeight !== this.container.scrollHeight;
    if (scrolledEnoughFromBottom && heightChangedFromLastEmit && isElementScrollable) {
      this.lastOutputedWhenHeight = this.container.scrollHeight;
      this.pplInfiniteScrollLoadMore.emit(null);
    }
  }
}
