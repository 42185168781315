export enum AccountFieldsEnum {
  AccountIdField = '09f20ecf-1493-0ad1-0ae2-513ae8c1f86c',
  NameField = 'ea0666b6-3b24-0fb1-928b-030c255aa4e7',
  AccountClassField = '233bd001-b54d-0c69-c7de-fbdb4b4334de',
  AccountTypeIdField = 'eb0f9462-7153-0b9c-b54e-f9ff61bb9e9a',
  AddressField = 'ba39aafc-3933-0fc6-f028-2676c3fc58ee',
  AutomatizerCreatedByProcessField = 'b64a0a3e-ef0c-4237-a668-16c41e448d97',
  AutomatizerProcessTriggeredDateField = 'af23c665-987b-43b2-8b9e-08e70c7c6f5b',
  AutomatizerProcessWithScheduledActionField = '4771b488-6255-4272-871a-a2ff3e11f68f',
  AutomatizerProcessWithScheduledActionDateField = '65c95c73-a660-405a-9f87-4d2fe3422022',
  AutomatizerTriggeredByActorField = '92b7c452-b8cc-468b-8975-69c3a68ecd21',
  AutomatizerTriggeredByProcessField = 'cdc81668-dfb4-421d-b874-a9f99c38de44',
  AutomatizerUpdatedByProcessField = 'a20bf10c-bd3e-458d-8986-b0da9cf1730b',
  CityField = '666e08a0-807a-0c00-2120-7ed5b5687585',
  CommentsField = 'cfaec542-dc36-0961-10b7-2c8b740063f8',
  CountryField = '73cb0194-dbf0-02fe-0ea2-c994d1e5e199',
  CreatedByField = 'e0e4eb7b-0f19-07e4-be0c-ba77139a1237',
  CustomerTypeIdField = '0bf80b78-8451-0736-8176-564d47379ee6',
  DocumentsField = '7340b528-7d0f-03c3-152e-0df83dd2991d',
  EditorsField = '9e58cbb7-cdef-087f-86c8-f62c81236da4',
  Email1Field = '71add60f-acc1-0cbe-c621-9330b97fc7b1',
  Email2Field = '25edcc6b-3323-0bd5-dc1a-3468306f91bf',
  Email3Field = '7161c20f-94b9-066d-6c36-2dc9b0580a40',
  Email4Field = 'c2192a7d-c989-0315-342f-7656ddce44a8',
  Email5Field = '7d7e83b1-5ed2-0ded-a46b-83a36f1c95c9',
  EmailSequenceCurrentlyEnrolledInSequenceField = 'c85d7f7c-3b54-48da-b5b9-84f392fe15ac',
  EmailSequenceEnrolledByActorField = '5d5e9c0f-959a-4a9c-9d96-661e2ff10247',
  EmailSequenceEnrolledInSequenceField = '7929d21b-89d6-4b3b-b3d2-e180cc7c8835',
  EmailSequenceLastEnrollmentDateField = '3b6914d8-d0f9-4607-8d4f-5ebfc3e5bd98',
  HasScheduledActivityField = '6970c013-936c-02f8-87ce-15133302ada2',
  HealthCategoryField = 'f92f0678-c8ec-493d-850f-0e732044035f',
  HealthStatusField = 'b1022ee2-b373-4b88-bdc6-53ad73f8664c',
  HomePageField = 'cf357dea-0dfa-0891-4045-62da835bd65e',
  IndustriesIdField = '54d7ecf1-e6a3-02ce-d307-b6fb5a7c34a9',
  IsFavoriteField = 'afe884f6-d3e0-087b-ed89-e8011c5d57b9',
  IsUnsubscribedField = 'ac59b373-d7c6-485f-b332-1c58d639164a',
  KpiField = 'ccda31e9-d115-01bb-147a-e0981d1f1e3e',
  LastContactedDateField = '5a3790c7-0f87-4b44-bcb3-2e33645aeb1d',
  LifetimeValueField = '98b1b76f-87ba-4794-afa4-c768aa6e1ec9',
  LocationField = 'a684b286-6d01-4e19-9066-85af37406440',
  ModifiedByUserField = '0a18e26e-b269-44bf-84da-2d7e6ddc5d9e',
  NotesField = '857855ab-49c0-041d-f93c-09856f747ae9',
  OrgChartSettingsField = 'c58814a8-b4d0-4fcb-a00b-d358d4749b0a',
  OwnerIdField = '5b67c561-0136-0c86-9dc0-0b4c9acb42f5',
  ParentAccountCardField = 'adf3c80a-b70d-0667-20f8-58ab7431be18',
  ParentAccountField = '8d212f4f-3977-01d9-265f-e9639c627b9e',
  ParentAccountRelationTypeIdField = '564f5467-0359-07de-557f-ba30f67d0ef2',
  ParentUnitIdField = 'ef5248d6-afca-0359-e166-845a00f7ddb2',
  Phone1Field = 'eab71ed7-223a-082b-a4a9-b27e732cf415',
  Phone2Field = 'bb802b98-965f-0416-53ba-cf64ce7d861e',
  Phone3Field = '5279fdde-ce30-05f5-b80c-ff40c3bc61d5',
  Phone4Field = 'f26816b2-b240-0149-ecfb-997735ff2b42',
  Phone5Field = '91b75067-76fc-01eb-6656-0a7de8fcdfec',
  PictureIdField = '07346f36-bc31-0b6d-2a3a-93d9b7f7e416',
  ProjectsField = 'ff83e4cb-f977-482c-975f-425ec1fd335f',
  QuickParentAccountNameField = 'b7a82a65-ba12-0646-6300-ed67f33e00ec',
  RecordUrlField = '8c01e311-c03c-4822-abd0-05a034444224',
  RelatedActivityDeferredField = '9ae5f925-639d-072f-cc9e-c30db2352b49',
  RelatedActivityFinishedField = 'dac928bc-6d79-02dc-84d6-2f4ff7163a63',
  RelatedActivityInProgressField = '2a60c39a-73fe-0fd0-f3f2-92001d0465ca',
  RelatedActivityNotStartedField = '2ed6f762-59f8-00e0-a301-6e7e1cf0754c',
  RelatedActivityOverdueField = 'a084efbf-1ca1-0dc0-9102-f910d6c87a79',
  RelatedActivityWaitingField = '968fa267-8d09-0160-e4aa-52380c46044c',
  RelatedContactCountField = '9d80f821-34f3-0bc3-0429-a06b678525e7',
  RelatedOpptyLostField = 'fee70254-6212-0924-877e-3c6ce25aa2d5',
  RelatedOpptyOpenField = '4bda9e94-44b0-0c16-9660-b51a37c3c971',
  RelatedOpptyWonField = 'd3ec1aba-90ee-09d2-1a34-7fadce77e738',
  ShareActorField = '3bdd7db4-671d-0e4f-8a04-fefb31c5e92c',
  ShareModeField = '744b26c5-4ce7-0106-1d73-ccfb9775157b',
  SharingField = '0fe26055-beb4-0e61-c41c-1bc08bc207c1',
  SharingClientsField = 'ffe13ba8-8230-05a7-2f7f-80fb68d2bc27',
  SharingUnitsField = '3a9517c7-0c3c-0b5f-a099-3096f6b81473',
  SocialMediaField = '749e50b7-0c1c-462b-b720-f27978c2f033',
  StateProvinceField = '0ae0c08b-3949-07aa-f597-e22b8626d845',
  TagsField = '020ac21b-8491-4e72-8956-97912d693ca9',
  UnitIdField = '3d5c5a74-6d51-0cb5-d701-3c385f4397cb',
  WatchersField = '97686b06-4cbf-0549-38a1-438f8aa31eb7',
  ZipCodeField = '4f9e0dea-d954-0cfc-aebf-91aec8c377d3',
  CreatedField = '4ec8df8b-5a0d-0b48-6ae6-767206626d0b',
  ModifiedField = 'e3a355fe-f68d-0a32-2226-d114940047e2',
}

export enum AccountDataExRelationFieldsEnum {
  IdField = '0bd69717-691b-4723-a38f-f261c18469e8',
  AccountIdField = 'fbd3a0d0-6e57-409a-97a1-51788ec2a6e3',
  DataIdField = '2461997e-31f7-45e6-980d-269f32b6586d',
  DataSetIdField = '7aef761a-9503-4d67-80b9-2ef34cb38858',
  CreatedField = '3ca91670-a2d9-4fe5-a31f-95f6e52d4dbb',
  ModifiedField = '4f004f03-72c2-4a01-89be-fb26947249ca',
}

export enum AccountKPIFieldsEnum {
  IdField = '1580cf8e-12c1-071e-4277-3050e156b1ff',
  AccountIdField = '96e0c511-4c11-00fd-e94c-d2890319b79a',
  ApplicationIdField = '07b19dd1-edc6-4217-b4e6-9b58e507971e',
  ClientIdField = '9a7e5008-0a95-0b02-b252-745f489744d8',
  KpiField = '5693f820-6c45-07a1-62ca-9df7e0a30678',
  OwnerIdField = 'e970663f-8ea8-4a4c-bfb8-559755bfc168',
  UnitIdField = '4aa40fd7-f520-0dd3-783a-2590898bfeb9',
  CreatedField = '42034f5e-d025-0549-d52e-1fa13d47d334',
  ModifiedField = '138cb603-1c8e-0206-4569-816e4851dd02',
}

export enum AccountRelationFieldsEnum {
  IdField = '54fc4e08-3218-4153-8d43-b6ef5a4282f9',
  Account1IdField = '0ab6e0c3-61c3-45a3-9385-c661cc6052f3',
  Account2IdField = '9d155eab-9265-465e-8a18-b777a5288436',
  LabelField = '3cef7009-41d7-45d9-9e8c-dd3ad251ee20',
  CreatedField = '1bb862b9-5c74-415d-b799-c48d06441ae3',
  ModifiedField = '08efab4c-c4ce-4b0d-96e7-b96b63e60956',
}

export enum AccountRelationLabelFieldsEnum {
  IdField = 'b53aa4bb-8f43-47ed-b70c-2f033ed98983',
  AccountRelationIdField = '5e28bef9-9991-4e31-b970-906bbc03b214',
  AccountRelationTypeIdField = '6571f36c-02bb-4841-a1a8-5121c064278d',
  CreatedField = '5b5434e3-71b6-4c04-8f35-bdf274590c79',
  ModifiedField = 'b7184a7e-6e01-455c-9a4d-9a3cbe290dfc',
}

export enum AccountRelationTypeFieldsEnum {
  IdField = '344569c3-e7a6-4559-b1ad-9584748e9097',
  ColorField = '85a94803-f45a-4a47-967c-593333130920',
  LabelField = '6b492fee-7ec1-44ca-9e74-2d9d11cbf193',
  CreatedField = '90216579-e558-4eec-856f-0ac0428f0940',
  ModifiedField = '53ea758b-6bb7-4c37-82a3-56b3125ac5ea',
}

export enum AccountRoleFieldsEnum {
  IdField = 'f3046732-c6d2-4acd-bb30-e84c2b0deddc',
  LabelField = '6bafec53-c28f-480a-bc9e-8c5d7d89981e',
  ColorField = '98277766-97f0-4eee-b2ee-5bbfbee031ae',
  CreatedField = 'b409c3c5-918b-473f-add9-b50e245dbda3',
  ModifiedField = '16fe8cb5-593a-4f08-a843-b1d1666c08d8',
}

export enum AccountSharingClientRelationFieldsEnum {
  IdField = '942ff01a-b39d-0685-5752-29276e0394bc',
  AccountIdField = '0dcc8aa5-129f-01f6-9bea-09369c037799',
  ClientIdField = 'd437f83c-014a-024a-cc44-01679c0921e6',
  RoleField = '525b25a1-8907-074c-d98f-1120f6b163d1',
  CreatedField = 'a81bb3a9-14f4-0482-092f-f4fa81e12167',
  ModifiedField = '3d1fb94f-30ac-05f9-442a-0d17c8c1128b',
}

export enum AccountSharingSalesUnitRelationFieldsEnum {
  IdField = 'd3c3073b-0848-0483-f79f-472b6ece1dcb',
  AccountIdField = '9e778991-b9ba-0161-5437-efad4cf95905',
  RoleField = 'dcd85573-665c-0d1c-eb0e-580ccfb4337d',
  UnitIdField = '032fbc5e-07ee-0836-c425-19b3e6ba44ba',
  CreatedField = '409a6c0c-d6ab-0974-0f4f-fe87fcec3e99',
  ModifiedField = '90ea3fc0-92b1-0258-27d6-5d0680e675da',
}

export enum AccountSocialRelationFieldsEnum {
  IdField = '1200b186-ec8a-06cd-7c6e-80a9da7357d6',
  AngellistUrlField = '8c41ad41-8179-0422-3651-e057821bb507',
  CrunchbaseUrlField = 'd46acec0-8049-03af-a527-df113b5e71e1',
  DisqusUrlField = 'e01cea8e-415b-0043-f592-8bd2fb98610a',
  FacebookIdField = '8346b3b3-f570-0815-3d80-5f815e932f54',
  FacebookUrlField = '40f9032a-bdfd-0d97-3fda-bf3dbedc5eb8',
  FlickrUrlField = '774a7c51-5cd1-03e0-f3ed-15d6ecbdaf53',
  FoursquareUrlField = 'fed5e2d6-c1e2-05e3-313f-5b77e5ec2601',
  GoogleIdField = '9b7387c0-0882-0e0f-56de-f078d67148bc',
  GooglePlusUrlField = 'f5020db2-a4e4-0449-a3ed-c64576c62b17',
  GravatarUrlField = 'b091eea3-3511-0f34-2b67-f2c1cef9d5da',
  KloutUrlField = '1bb3eaa5-09a2-0823-226d-93c6737099a5',
  LinkedinIdField = '4d88281f-efac-0fb4-53c8-4bdcdea58eb8',
  LinkedinUrlField = '005c6748-8222-015e-81ba-1bdd5ae4cb63',
  PinterestUrlField = '5ef0faba-a295-0901-8a2e-680b9663dab0',
  QuoraUrlField = 'eb3b42e5-1b2b-0d8e-ba5b-49562697eb67',
  RedditUrlField = '1ccd3152-2e01-02e7-795c-8bbaa7aa14bc',
  SkypeUrlField = '442289a0-8530-031a-fd09-d00ffb208f47',
  SlideshareUrlField = 'df6a1c23-5111-0a6c-5d1c-534bc8185731',
  TwitterIdField = '80e1d0d0-0dcb-099b-f391-96649eec491b',
  TwitterUrlField = 'ae6b1d5b-5881-0ceb-1703-a462fb16434d',
  VimeoUrlField = 'a857057a-dfe3-01ae-3116-a9d52ab3766c',
  WordpressUrlField = 'bd52e007-dd7f-0cd3-95ac-7cb1823e109f',
  XingUrlField = '45e9da8c-12d1-0257-cf5e-dd0ab638cd37',
  YahooUrlField = 'f74dc6ba-17f1-0d0f-4691-12c7f24c9a80',
  YelpUrlField = '7b44c1f5-070b-0a65-6c59-42bd4423a8ff',
  YoutubeUrlField = '554d72d9-7252-0442-6dd5-b63c32e6edd0',
  CreatedField = '8241919f-d49c-027d-13d3-6ba007d98daf',
  ModifiedField = '9e6abb8a-1fa7-0381-82ba-43be8916c276',
}

export enum AccountTypeFieldsEnum {
  IdField = '34557674-0155-0955-ce78-0f052c27704d',
  NameField = 'c90b7c65-c485-0d61-cf31-d67e209e6ea4',
  FormEditField = 'd1bcf1c5-2bb1-0e10-6f7b-ad02315111b9',
  IsReadonlyField = '74236b9f-e465-0100-192d-8be68dfeabb2',
  CreatedField = '9dad08bc-2a9d-0f8d-2bf3-8862113aa263',
  ModifiedField = '0ec11ec9-1b60-0b69-aeab-fede0a8fba68',
}

export enum ActivityFieldsEnum {
  IdField = '8fbad2ba-396e-06f6-692f-50081cb804f6',
  SubjectField = '7a49efbd-6108-02f0-fa4f-b5b1c6ff538a',
  AccountRelationsField = '80b335aa-bbd6-06f0-c3b8-4c71b841a1d8',
  ActivityStatusField = '67de2d2a-1f60-0d02-f6d8-09445f44f4e7',
  ActivityTypeIdField = '86a503bb-304d-0b2c-3390-1b34a9b1b75e',
  AttachmentsField = '76e87133-6c94-0f73-8286-ca4535b763b3',
  AutomatizerCreatedByProcessField = '82f5a224-8335-4c64-bd39-6586af9324f5',
  AutomatizerProcessTriggeredDateField = 'd84c838e-9175-4025-8586-cd1c44d2fb71',
  AutomatizerProcessWithScheduledActionField = 'e6ab3f31-94f6-4442-a94a-acb80c4e7f6b',
  AutomatizerProcessWithScheduledActionDateField = 'd28ff944-4307-49e0-b695-7e48b4174979',
  AutomatizerTriggeredByActorField = '560fc787-3a69-461b-9a30-7c6cb21abd6a',
  AutomatizerTriggeredByProcessField = '9bda721b-5940-45ec-9906-c69bed3d2db2',
  AutomatizerUpdatedByProcessField = '198b9551-b32a-485e-94d7-9f4da0fda85e',
  CommentCountField = '78d4c8a0-5e9f-4d30-b6ac-6e806fe55d2a',
  CommentsField = 'f67e18d6-9ffa-01ea-5032-74b8ff6aabd7',
  ContactRelationsField = 'acd080a3-21c7-03b2-154d-39b2f1b8fb16',
  DescriptionField = '27244d6d-0e07-0b2f-2158-9aabf46ca612',
  DurationField = '3d70e7a4-5e50-4bd9-baa7-69320bf9b60e',
  FinishedAtField = 'ed1cde51-052d-02fb-2f12-309e88ea706f',
  HasReminderField = 'a38a4f9a-3233-0375-09a9-ff3dde1feb62',
  KpiField = '4a72a2bb-e673-0a4c-63b1-e7c185e6aef7',
  LastCommentDateField = '5dd00695-c486-0945-c5c6-cfdb19548d28',
  LastCommentOwnerField = 'b72985d1-d323-0136-fd1b-6c8750e82ce8',
  LastCommentSubjectField = '81058495-a06e-06fc-23a9-52a7d8478dc1',
  LeadOpptyRelationsField = '1f9c3c3f-c712-0c79-e51f-9bc2b74746b5',
  LinkedEntityTypesField = 'bb0c2416-e599-0be2-546c-f2d9a0af14ce',
  LinkedItemsField = '81fa3330-9463-02a3-710d-d63143eadfaa',
  ModifiedByUserField = 'b46a1aad-b314-46de-b6a5-e2594ef9209b',
  OwnerIdField = 'eca27f61-7f7b-0d96-979d-1d879fbeaccb',
  PeriodField = 'b8f5bd8b-7a9f-000c-a544-b63dab2c61b2',
  ProjectRelationsField = 'e1e80ee9-af3a-423e-9b27-e3451de52afe',
  QuoteRelationsField = '89d6ca1b-e14d-4895-a8f3-a9a4cbead22a',
  RecordUrlField = '1fbdbb08-6aa3-468b-ba54-8b76b2604457',
  ReminderDateField = 'bb22b90a-e0d2-0134-2228-7ab4b9a1d3df',
  UnitIdField = 'cc8f44f7-6aef-09b5-3de5-981e4670900b',
  CreatedField = 'd63cf720-bf15-096a-4d2f-4fc32738fc01',
  ModifiedField = 'ef3ac776-d279-0d08-c85b-f32f1c53dcea',
}

export enum ActivityCommentFieldsEnum {
  IdField = '8e078ccd-941e-069f-a083-b8c87c9bd86d',
  ActivityIdField = '05bae4f1-f61a-0b24-322a-33c1e0a1384e',
  CommentField = 'a29f4973-69f4-032f-f546-3e07f8501337',
  OwnerIdField = '46e992d4-39d8-04c3-c725-bf7465f2c447',
  CreatedField = 'c83ab66d-b98c-053a-7f91-9d25ebbf9be3',
  ModifiedField = '46569130-5a4f-0070-05db-9c665b0ac6f5',
}

export enum ActivityDataExRelationFieldsEnum {
  IdField = 'b5487316-9e78-42dc-96c7-91dbaf9835ec',
  ActivityIdField = 'bced63ea-610f-4dfb-922c-aaa7214c9260',
  DataIdField = '98a15869-8d57-4247-84a0-14098b3d3cd3',
  DataSetIdField = '42ab6e34-e363-4db1-a54c-e682c464884c',
  CreatedField = '0ceb043f-e9d6-4aef-b10d-4592a4217d5e',
  ModifiedField = '38523f54-9f0b-4d1b-a4ff-be580c5c1cae',
}

export enum ActivityKPIFieldsEnum {
  IdField = '47f9a0d0-4120-0d8e-c1b7-925ced3103d3',
  ActivityIdField = 'f6959021-32ce-04da-5cc4-57a7a2407641',
  ApplicationIdField = '5e8b3161-7623-41c9-ae10-df6ecdf9316b',
  ClientIdField = '64d6c60c-27ac-08d0-5a29-3bb64bb48cc7',
  KpiField = '371f9cd0-888a-0f56-f53c-8d3f74cc5200',
  OwnerIdField = 'b1443049-1ad2-4409-9e18-3f2b79f1bed8',
  UnitIdField = '0c901e13-f31a-0ede-a488-452acc6d800f',
  CreatedField = '9c1bb125-d3f8-0782-c5a5-a6691c13dd5d',
  ModifiedField = '1fcb5e99-1649-0b58-1da7-1dc070786c3e',
}

export enum ActivityRelationFieldsEnum {
  IdField = '8c3f6aba-2ea0-0065-7186-299a6ed0c570',
  AccountIdField = '1730874c-1a8a-0d95-f7ef-1314a871ac42',
  ActivityIdField = '90bccd00-3218-082f-9389-c7f18de020c5',
  ContactIdField = '3946b819-c1c4-0618-3370-75d68a30320c',
  LeadOpptyIdField = 'ce164b5d-44da-0f7f-0bdb-dc9f86e60e85',
  ProjectIdField = '10b840d0-26dd-4c2b-a1f7-d314c0ff7424',
  ProjectObjectiveIdField = 'd342522d-9ffa-44ae-b86c-13ebd05224c1',
  QuoteIdField = 'fb625009-3fcd-4f44-a350-a4f2977dfeca',
  RelationField = '656c7809-f5fd-0eb5-ad4e-47a5f24cac0b',
  SortOrderField = 'fe4ea5cd-6340-4add-b976-e69587e464ec',
  CreatedField = 'dfa0ba5b-cf29-02fd-f25c-22d5114fe953',
  ModifiedField = '8e77fb66-0a71-0b5e-8e52-495739aa1ebb',
}

export enum ActivityTypeFieldsEnum {
  IdField = 'be3404c7-d6e3-0d26-9693-f3a2f0a6ce91',
  NameField = '766c247b-14d8-094e-68ea-2f247d0b87d1',
  FormEditField = 'd7e62f46-fc79-0f94-11a6-dffd19c6f96f',
  IconField = '69bfa65b-1881-0ce6-999c-1925f67424a1',
  IsReadonlyField = '210908d9-677c-05fe-16ee-7e882b68da4a',
  CreatedField = 'b036c9ed-9766-075c-26bf-463bc3a4d349',
  ModifiedField = 'a277ed55-ce13-0492-39f1-b3eb28e4cd92',
}

export enum AllowedAccountTypeFieldsEnum {
  IdField = 'a8c75948-4ce3-4e49-86e5-9004dcfcc4f6',
  AccountTypeIdField = '6f5449be-1e98-4cc2-8a72-cea349956446',
  MasterRightIdField = '1e52ce7f-fd6a-42b4-8d6d-52e3a864e06c',
  CreatedField = 'b09d4351-518e-4af2-9599-2129b672202a',
  ModifiedField = 'f47fbb6d-8a1b-41b6-a4c7-44a777b66884',
}

export enum AllowedCompanyEmailFieldsEnum {
  IdField = '1c479414-dcb9-4dd5-9308-0765ac5e8bbb',
  CompanyEmailIdField = '69483923-bb45-4b58-bed1-ca17743eae5d',
  MasterRightIdField = 'd451b92c-7ed2-4bde-8ac1-54a7be3e3087',
  CreatedField = 'b2bfe9b4-1e9c-4e90-bbcc-eb5830ae9ffc',
  ModifiedField = 'e7d26b8d-d4dc-4f88-b8ce-882dbaf76db7',
}

export enum AllowedCompanyPhoneFieldsEnum {
  IdField = 'ed774ef0-24bd-4acf-9987-f979389f2f2a',
  MasterRightIdField = 'e75aad9b-4cb4-4220-9daf-9fe6ab722e25',
  PhoneIdField = 'be8bbe05-e6e1-4f08-abe6-16345e103290',
  CreatedField = 'c3ba7fbf-5fb3-453b-8328-7bf9ee7a74ba',
  ModifiedField = '22565fa4-05da-45cf-b547-1a62b06f8c19',
}

export enum AllowedContactTypeFieldsEnum {
  IdField = 'decff72c-4f5a-4f81-982f-95c52bdbaeeb',
  ContactTypeIdField = '62b2a4e6-dc28-4eb1-829f-84d58c9801d9',
  MasterRightIdField = '839bdf67-ae8e-4a64-9ba1-c4f0478b4f83',
  CreatedField = '4a062a71-c8b3-45c6-89e7-ee5e30572a27',
  ModifiedField = '1871fc5b-e373-4a97-9d97-dc528179af6f',
}

export enum AllowedLeadTypeFieldsEnum {
  IdField = 'cb952cf1-a7ed-4b61-897b-c5cfa60dc1d6',
  LeadTypeIdField = 'f72c43f3-ccb3-49a8-b987-951afa0650c3',
  MasterRightIdField = '2d912070-5f6f-4099-83d0-2db689adca07',
  CreatedField = '547dbddb-bbca-4354-ac21-4617eb4cf09f',
  ModifiedField = '96cb8155-a14b-4b0c-a57d-d4859bc3b5ca',
}

export enum AllowedPipelineFieldsEnum {
  IdField = '9662dab1-cf42-0c6e-56e3-3bcf235965a3',
  AccessStepDocumentsField = 'f4b336bf-31ba-052d-27c6-cc87daeb2993',
  MasterRightIdField = 'f0bc800a-d21f-0d5c-a489-efa46a499231',
  PipelineIdField = 'eee3ae3d-7f87-0da0-e9fa-2a02b01c6a0f',
  CreatedField = '0c396f97-6425-0002-ed10-719d21061799',
  ModifiedField = 'd3dadff4-fae8-011d-1ce8-7ab2485e1fcd',
}

export enum AllowedQuoteTypeFieldsEnum {
  IdField = '1153d913-54b8-40a8-9bf3-7137d84fc879',
  MasterRightIdField = '67066f06-f355-4111-a85d-a6c9e474b204',
  QuoteTypeIdField = '6b356440-2fa9-41ed-8f13-796c39dfdd2a',
  CreatedField = '393474cf-fdb2-4c3e-b05b-c65f666b26f4',
  ModifiedField = '7cd0c3d7-6c20-49e6-803b-8be714726e39',
}

export enum ApiAccessFieldsEnum {
  IdField = '7514ada3-9e67-41e1-b389-0640d3adebb2',
  CredentialsField = 'e908facc-d999-4c5a-b480-db5bfbfc93a0',
  EmailField = 'a850919e-fa85-4983-8858-635b304dddaa',
  IsActiveField = 'e59c1e36-270b-4a0f-9f12-2a4451b9af8a',
  LastUsedField = 'cc4f74d7-f4b8-4e7b-901b-ba530e4adbec',
  PermissionsField = '2b94fbef-c203-4334-8fb4-b6d908f61fbc',
  TypeField = 'a5078f7d-9d1d-4ef5-bbd1-57dfcf84fdb5',
  CreatedField = 'de8bb43d-4ac5-4692-bc63-634efc24489c',
  ModifiedField = '13a660c3-b4c5-43b9-8b54-e5f372f347cb',
}

export enum ApplicationFieldsEnum {
  IdField = '7960cfac-f4c3-4721-879f-d2198d91f90d',
  NameField = 'bb5fe532-5f79-45aa-b3c2-64a02c9439fd',
  AutomationHubIdField = '2c4e7886-7166-4f11-92ba-bda5a3f88477',
  IsTriggerField = '065592e2-217b-4485-b38f-2f55aa75656c',
  IsVisibleField = 'bd331ac7-a629-48b3-9b2b-99fa8ee188d6',
  UsesImpersonationField = '0e13cb4d-ee07-4e02-ae3f-2ea2e0bd5ea5',
  CreatedField = '1513ac48-5bec-4951-84fc-bdbf59ec93ba',
  ModifiedField = 'b735d91c-f5ca-424f-8c5e-9b7b75829174',
}

export enum AppointmentFieldsEnum {
  IdField = '86783049-14d6-0e2a-8f8e-9dabcddf821d',
  SubjectField = 'c259a875-8ed1-008a-ee56-24f8ca0086ff',
  AccountRelationsField = 'b38d2ee9-c8d7-0f4c-4163-fce021c0ba21',
  ActivityStatusField = '5e0c1a75-f9b5-077c-6389-d8467d5ac597',
  ActivityTypeIdField = 'ab2075f7-a365-0815-02be-2b41a4a7636b',
  AttachmentsField = 'dd433b8f-d44e-01c0-075c-62bfe07046a8',
  AttendeeEmailsField = '0e91d01d-a7c3-499b-af0b-5bd25c9e0f0d',
  AutomatizerCreatedByProcessField = '3875ab7e-bded-4753-9a1e-2829fc4e665c',
  AutomatizerProcessTriggeredDateField = 'aacff0a7-5911-48ce-af31-0b5469da7c26',
  AutomatizerProcessWithScheduledActionField = '37e57163-fc23-4c7e-9fd9-7a73a57671f3',
  AutomatizerProcessWithScheduledActionDateField = '37b27e9b-ad76-436e-acea-84c055fd42f7',
  AutomatizerTriggeredByActorField = '917787e6-cbb0-459a-a206-98af14e76b8d',
  AutomatizerTriggeredByProcessField = '2ed38417-f83b-4b95-8416-d21dbc6b952c',
  AutomatizerUpdatedByProcessField = 'a745bc13-2215-4268-9254-67b18697725d',
  CommentCountField = '02796254-872f-4c53-ac34-0199a6298155',
  CommentsField = '7a0206ed-7903-0b85-8d6f-91a42eb85553',
  ContactRelationsField = '61dbf523-27cd-08bb-bb22-dc7a7a08deca',
  DescriptionField = 'dcbaf936-3331-0711-5fed-d857be6c6b12',
  DurationField = 'c9666a96-bc60-4a4f-bc9c-5b135753bf45',
  EndDateField = 'd97720c9-1fe5-0809-21ff-0c1d92af02ea',
  FinishedAtField = '63166a97-0cc4-03d2-6a14-f7f36d83ff3d',
  HasReminderField = '3cbd0f09-f8c4-0d55-f19f-cec885f69e25',
  InviteeEmailField = '2ea595df-0426-443d-ad12-05e2b08d69dd',
  InviteeFirstNameField = '977d77c1-380b-471f-b3e2-a4a42ccd87da',
  InviteeLastNameField = '8b905c98-24c9-4e7b-9745-2e042eb4cb9c',
  InviteesCanEditField = '1e9c1605-28e9-0bcd-716a-1c0a6e3c5eda',
  InviteesClientsField = '73b5f1ce-2d13-0b13-8609-e7cb9cd15fb1',
  InviteesContactsField = 'c3d0228a-26a7-0de3-ec8d-bc4382ff1849',
  IsConfirmedField = '252262fa-648c-04df-8dc7-a53eb8c6c340',
  IsRecurrenceActiveField = '8c02e669-0ead-4d02-971a-41cdf277c261',
  IsRecurrenceExceptionField = 'e0ce0efa-e9d4-4a70-8aaa-bf71c522ed9b',
  KpiField = 'baa9b352-02a4-061b-293b-196c7895cf9e',
  LastCommentDateField = '1aab2e68-0d80-0231-02c8-07cc11fce190',
  LastCommentOwnerField = '51bcd04a-51aa-0470-c8a9-0ccc4c8bf180',
  LastCommentSubjectField = '7117e4a3-8f2c-0ff8-aebd-44a2d573c54a',
  LeadOpptyRelationsField = '3aef3143-ccda-087e-cc38-0fce31f3bb51',
  LinkedEntityTypesField = 'a7b8c268-7b6e-0943-d9a1-ff120d06f92b',
  LinkedItemsField = 'f94ac937-4b22-0ce3-b4b7-58d9685f3d8b',
  LocationField = '49fb6ab8-b717-05ef-532e-ffb628a4ca47',
  MeetingUrlField = '74b5f1b4-bfa4-4efd-a092-c82be8ab9bff',
  ModifiedByUserField = '1b7438ed-4b75-44d7-9057-10a3f995b29d',
  OccurenceDateField = '2dd383f0-d081-4100-9420-cad985ffabbe',
  OwnerIdField = 'ae68e7c2-7640-0108-6c31-6d02e1f006f6',
  PeriodField = '51b7ad17-29e5-092d-add6-6986330786cf',
  ProjectRelationsField = '31760afb-d2ce-4c4d-a29d-cc1f69f5d776',
  QuoteRelationsField = '0e5e05f0-29a9-44e4-9909-30050e000c0f',
  RecordUrlField = '37e18535-88f2-4d8d-be25-0717063fb90b',
  RecurrenceMasterIdField = '6ff875ca-d00f-4c7f-b1f8-9961ac18b9cb',
  RecurrenceRuleField = '0974129e-cef2-48f4-a65a-13e9f2f774c3',
  RecurrenceTzidField = '734f2408-e359-4c63-a232-ff247e133139',
  ReminderDateField = 'dff37ccb-a377-0ab7-e913-a1db96569609',
  ScheduleIdField = 'ebf401a8-bbdb-4273-bbb1-f0a3d4141724',
  ScheduleTzidField = '55ed33d0-c7f6-4623-976e-bc47fdd6d34a',
  StartDateField = 'f98410bb-4134-0744-0d50-c4a6a0445857',
  UnitIdField = '4b34b05d-7b11-078f-50d0-28942424cc5f',
  CreatedField = 'c9ebcef9-fa45-0ac9-e202-a4212b446f9e',
  ModifiedField = '5ebe2f29-685b-0f7d-f922-54be6c5c0b60',
}

export enum AppointmentClientInviteesRelationFieldsEnum {
  IdField = 'c6caf0f2-6348-0103-1774-fa4319900995',
  AppointmentIdField = 'a3359fa5-5260-08cc-880b-f9e678b91d89',
  ClientIdField = '12ac0b3f-4674-0c74-0363-2949bc76a2c7',
  ResponseField = 'f42cca26-85fe-0817-b4e4-0a154cf2e648',
  CreatedField = '3fa34c37-2c7b-035a-ee07-0982b71c713f',
  ModifiedField = '47df6f27-0514-097c-4489-20daa7141f4c',
}

export enum AppointmentContactInviteesRelationFieldsEnum {
  IdField = 'dcd70d8f-9e0b-003a-bea5-647ac7770ad7',
  FirstNameField = '7401aeb2-af68-4089-8046-1054ebb6fd61',
  LastNameField = '5ddf34ac-cc21-4424-8a17-bae6729daa70',
  AppointmentIdField = 'de85a055-8c0d-0b95-3f42-76e828dc493b',
  ContactIdField = '7c91fba7-0c98-0238-5749-705730a02ed1',
  EmailField = 'a3dc45da-056c-02a1-5aaf-8ed54b31e212',
  InviteeTypeField = '07b0dd65-1d4d-4e5c-b5f4-99c4cb6aaa3b',
  ResponseField = 'b0f0f978-0a1a-091d-7d90-46259d9da0c3',
  CreatedField = '639cd026-ba5c-073e-9522-ec42fb03c770',
  ModifiedField = 'b15c000d-d930-0699-2575-2d2bc04dcaf0',
}

export enum AppointmentInviteeNotifyQueueFieldsEnum {
  IdField = 'cb7e4d87-3743-0c99-9eae-db83db6fbc67',
  AppointmentIdField = 'ce3a7c2a-a0af-06d2-8645-977ad77099a1',
  EmailField = '3fd13d6a-60e3-0a30-aafb-9c2ad2da0573',
  CreatedField = 'ff37dfa3-e7c7-066e-ae43-935adc01322b',
  ModifiedField = 'e32fa248-39cb-0810-9d01-876ff2574603',
}

export enum AppointmentReminderFieldsEnum {
  IdField = '15aa4331-5610-0432-9ec7-6eb91d542e39',
  AppointmentIdField = '31d7aaab-e5d6-02d1-939d-07c927ab394f',
  EndDateOffsetField = '38decb92-2a62-02b2-a26a-b7042d392484',
  OwnerIdField = '21a64faa-dd39-05e2-9322-f3f3525bbdc6',
  SnoozeDateField = '005fae12-662b-0b64-8de4-3f0b3190be04',
  StatusField = '9fff76de-4ab4-0995-802f-f6f50a2f9b53',
  CreatedField = '882a20c8-98e5-0040-bdc8-138ea0e4658c',
  ModifiedField = '191f4ab0-09e3-08d5-8f8d-8c0368dde687',
}

export enum AppointmentScheduleFieldsEnum {
  IdField = 'ac84dbc5-c890-496a-ba18-8ea46b382451',
  NameField = 'a5f78bcb-145e-49dd-bf31-d478d40d3d78',
  AdditionalFieldsField = '484e528a-c560-4714-8c22-6357c22cf0e4',
  AppointmentDescriptionField = '13565b9e-28ce-4b4a-a2d8-63491e074843',
  AppointmentDurationField = '88c3a5f9-6058-4cba-8097-38d7f45c82ce',
  AppointmentInviteesField = 'abf61afd-f5ca-479e-8618-2162136de3d5',
  AppointmentLocationField = '741c64fc-7bb7-4237-b10d-55409332c9f9',
  AppointmentLocationTypeField = '064889ea-b9cc-48c8-b6d1-698299a60f6c',
  AppointmentOwnerIdField = '87a095a6-4b82-428d-853b-dc9d178bc640',
  AppointmentSubjectField = '4c124d15-66fd-4b1d-9743-3511aa033ad2',
  AppointmentSubjectUsesScheduleNameField = '1a60a5b7-a557-49d2-b729-1bb4a48caa67',
  AppointmentTypeIdField = '0ad0b72f-8ab3-477d-82ee-0d69af4d434a',
  AvailabilitySettingsField = '592ae259-9d50-4805-9571-55123174ee51',
  AvailabilityTzidField = 'b3788f69-b090-42cd-81c9-0dd172608321',
  BufferTimeAfterField = '28579bd8-adb1-4290-aace-bdef9cfaedfb',
  BufferTimeBeforeField = 'e32afaa3-8e2f-4bae-8a55-81a7ffb4cf09',
  ConfirmationPageTypeField = '2da8f081-a1ae-4ea5-982a-a1483c7d1349',
  ConfirmationPageUrlField = 'b08a0835-f684-4fee-a078-241aeadfb038',
  IsEnabledField = '956533de-2541-405d-befd-d690a6301759',
  MinimumNoticeTimeField = 'a7092185-c12f-4dcb-bf69-7d2e849e6267',
  OwnerIdField = '3b1b60b8-2e40-4b00-b24f-5e08bbbf1067',
  PlanningDayOffsetField = '6dd3d025-54ca-4909-895a-296c75af54f9',
  PlanningPeriodFromField = '67ad5940-2b7e-4099-840b-953da1199350',
  PlanningPeriodToField = '095a0661-96f2-4d28-a1ee-43881a9a66f2',
  PlanningTypeField = 'f356f094-08a8-4a00-94ad-5b2112da706b',
  ReminderEmailEnabledField = '6801f77d-e633-4025-b317-8ca7be9a5dc8',
  ReminderEmailOffsetField = '8704f268-640f-436a-9e0e-76c87d66d842',
  ReminderEmailOffsetTypeField = '444eba1c-9594-4636-8bd1-27ed015faa17',
  ScheduleNameField = 'd106291b-9f07-4b19-955b-28bfbabdef89',
  TimeIncrementField = 'd2b8aa7c-5c41-4491-96dc-43f7a045a486',
  TypeField = '1ae788ee-1aa3-4b3d-bf0b-c13646c02590',
  UrlField = 'd24da137-cb6d-46c9-ae43-3977797bb2c7',
  CreatedField = '1f9ff2e3-f4f9-4872-860b-2a4874c669ba',
  ModifiedField = 'e80cd889-8355-4128-a23a-3143c257d908',
}

export enum AppointmentTypeFieldsEnum {
  IdField = '84eeef1d-09d2-03ae-c13d-417023f78592',
  NameField = '82276cbc-f2bb-0e73-946a-13e0e936afd1',
  FormEditField = '9106591d-3e2f-0847-ed84-f605f511d363',
  IconField = '8452c4d2-f9e7-0138-781e-573879d1bf4c',
  IsReadonlyField = '3a373f2a-b987-00f8-73a7-5c42c6a52a53',
  CreatedField = 'bb7f4d34-e2d0-0be3-cce9-29e97cd4c5f8',
  ModifiedField = '4c9246f7-2941-05d7-5562-f61aa07aba84',
}

export enum AuditLogFieldsEnum {
  IdField = '3c1c2b41-62a0-431c-a79b-678a93782079',
  ActorEmailField = '0e24fcc1-1bb9-4d7f-98a9-6ac45970c957',
  ActorNameField = 'feaa0a0a-8547-4238-9e46-eb2d01c33bc7',
  ApplicationIdField = '38bb8da0-ec5f-4d18-845d-3015033cbcf5',
  DataField = '6a6d008a-4226-477f-838d-e58b355279c2',
  EnUsDescriptionField = '026ae406-6ab7-4ad0-a0bf-467ef64de9cb',
  TypeField = '6655f51b-faef-40ea-a0c4-63d5a8d7ca3a',
  CreatedField = 'cfbe9f3b-d41a-45d1-bbdb-7dae2c24b62a',
  ModifiedField = '7a245cc8-4660-4138-93c1-5506981ddad3',
}

export enum CallFieldsEnum {
  IdField = '08df4e90-7479-4a55-809a-5d24292022ee',
  AccountIdField = 'b2bb0490-34ee-498f-9cff-5dc5b927a8e1',
  CallerIdField = '08cfa059-58bd-4c34-8a8f-68699a3dbfa8',
  ClientIdField = 'f4a84b0f-b1e9-4742-bc02-c02814cd0287',
  ContactIdField = 'b1496f91-8c5e-4eec-ba75-a2bb516da470',
  LeadOpptyIdField = '0c04bb5a-e009-4904-85a9-da49b1a1d7be',
  OpptyProductRelationIdField = 'a0ef7974-3358-4f86-8b46-6d0633308d03',
  PhoneNumberField = 'd96968ac-b1c6-4af5-bda3-f283978cd5a1',
  ProductIdField = 'fd33502b-0063-443d-ad57-1be510213407',
  RelationNullableField = 'dd54f7f9-9e70-4d1c-ae48-8a90141842d6',
  TwilioCallLogIdField = '1cef7549-f2a8-4f75-9cec-90a7b7762d1e',
  CreatedField = '3a93d2ef-b7fc-42df-b031-a32c2228481c',
  ModifiedField = '9eabf80a-bf39-45f3-bccb-22681071ba80',
}

export enum ChangeLogFieldsEnum {
  IdField = '19e541bc-dd40-4248-b4a0-68adaedc9d25',
  AccountIdField = 'a12cff71-e170-41cb-ae52-6114186768bb',
  ApplicationIdField = 'd904d0b9-39ed-4a72-a559-e9f600060ecb',
  AppointmentIdField = '6860766d-de37-460c-a2d8-e09001706568',
  ClientIdField = '73a56f69-e620-4331-8187-7a6b6484b85f',
  ContactIdField = '87248dff-132c-46ba-8140-449aa5a63abe',
  EntityIdField = '85ecdd0b-e3a0-4d3d-81c7-17666ead0f67',
  EntityTypeField = 'f408aaf8-29ec-47f8-9d93-eccf4fa0b397',
  FieldIdField = '6b6092a3-8912-4713-af62-ff4599982794',
  LeadOpptyIdField = '1e2a2608-c690-49f6-b7eb-764bb5ad69a6',
  NewValueField = '5c9ebee4-8b16-4bcf-b025-ac8be37560ff',
  OldValueField = 'ef832858-979a-4a3e-8324-22b2f0790ea6',
  OpptyProductRelationIdField = '49fb8672-c9b6-467b-88a2-59c883447262',
  ProjectIdField = '795ab042-fb95-4323-857a-ae4d46889b61',
  QuoteIdField = '5ba1a0a9-d0fa-45fc-8f84-6f317adaba64',
  RelationField = '54ac9a1a-71d3-40ac-951e-ef41a6549ef4',
  SourceField = '17506d9b-9a81-4e7e-86e8-40dc870190eb',
  TaskIdField = 'd7fccd59-642b-4873-9229-96da610b0746',
  TransactionIdField = '621418b4-9e40-4bbb-a385-23fa7f4d73e3',
  TypeField = '71cfb2e4-497b-4fc1-8524-9b780016ef4c',
  UserIdField = '80482b5f-a984-4100-a8cf-9f9a010c4074',
  CreatedField = '413ddb0f-92f2-410d-884e-ce19a69d81a4',
  ModifiedField = '3662e532-371e-44d4-b85d-6aa5c89f7d5e',
}

export enum ClientFieldsEnum {
  IdField = '96f68e3c-2530-0eba-091e-4e30a2d6ba93',
  FirstNameField = 'b91290a8-5549-0cd5-2545-1d8e89f9194d',
  MiddleNameField = '7058dac5-fafe-00a6-0322-69812d397a90',
  LastNameField = '57ab64a8-14b2-01de-1ae2-57b22cf5286b',
  AccountFavoriteField = 'ef7ade1e-9a29-04c8-b114-4176b25dbf2b',
  AppointmentFavoriteField = 'ac85f494-7bd5-00ad-8a30-a64c501fd02c',
  ContactFavoriteField = '6e9fe2b1-4db6-0910-bba5-feaf3be6f0e1',
  DefaultUnitIdField = '074a0c36-21af-0487-9d07-3badf2f518e7',
  EmailField = '5199acfd-f672-029e-ec7b-d9da76125786',
  FavoriteField = '66668b2e-5cdb-07ac-a498-2f43fc4a0e5f',
  LastActiveField = 'efb2a948-826a-4dfc-956d-cf55de002ef4',
  LeadOpptyFavoriteField = 'ce458994-3884-0ca5-80ce-cf690fdcaf83',
  MasterRightIdField = '2b8f9c51-d2b4-0f70-5d7a-dd56723d8761',
  MemberUnitsField = 'ad11bbc7-def1-02d6-5e29-182d8266a44e',
  NameField = '5b09b72c-2a4c-0932-6e3a-278e97fe8084',
  PhoneField = 'e2023b3a-0363-4a30-9ea4-183593bf2333',
  PictureUrlField = '2b040caf-ca64-08a3-59ce-9e832ef8b806',
  PositionField = '9a272321-e918-0982-5314-c0e1b7ae48ac',
  ProfileFavoriteField = '850b7123-fc08-0b7d-98e2-f61f80078bf8',
  QuoteFavoriteField = 'a0bfba3a-1e9b-4167-bc07-954cb2c13ecf',
  RegionField = 'a32bbc4a-c535-4f24-949c-75ccefab8096',
  ReportFavoriteField = '8ed7c78b-71b0-07e9-a75c-62be33d6d663',
  SettingField = '19c0aec1-84f7-0622-a699-e3070d1f6268',
  StoryReadField = 'cd87008c-3649-029b-8bb5-ee45a6aa5dce',
  TaskFavoriteField = '39c206f3-1953-04e9-9cb4-ba273e5bc8f7',
  TimezoneField = '78c08b0d-94dc-4d0b-9916-1af4a24f476e',
  CreatedField = '8218752e-163c-0bbe-1c2a-fbd4a1c0d6cd',
  ModifiedField = 'dddd742d-7202-0688-1fcd-4a8e1feecebf',
}

export enum ClientExcludedOpptyFieldsEnum {
  IdField = '3c80e842-24f6-05f0-97ad-c3bfb959eb61',
  IsExcludedField = '8d0c1a7f-5008-0139-82d4-d1e59e1b7ce7',
  OpptyIdField = 'adedff2a-7735-0f92-9df5-c217ea0d826e',
  OwnerIdField = 'd263bfe1-0313-08c2-8764-5bb36577fffe',
  CreatedField = 'f7a3194f-d00f-0990-8beb-8fcad846c6ea',
  ModifiedField = '465ecb1c-7ded-0500-80da-2c39262faef5',
}

export enum ClientFavoriteFieldsEnum {
  IdField = '2e7ecba7-e142-02b9-9145-343eb1db918e',
  AccountIdField = '67c0ea3a-75de-0571-a025-4e079c91bb3a',
  ActivityIdField = 'fbfbe41d-9b41-0f2f-8488-635d7d9d90bd',
  ContactIdField = 'c2165bdd-2af3-0212-b3ee-85ec86e1ebb0',
  LeadOpptyIdField = '696f4dfe-b5ed-06c9-8d88-b78b76646a22',
  OwnerIdField = '2dac4500-51a5-08c0-892f-ac95800a57c3',
  ProfileIdField = '7b199125-74f8-0951-b338-94db97dbd992',
  ProjectIdField = 'a6dababb-94b4-47e7-b047-9200237195a6',
  QuoteIdField = '60b8bcbf-f2cf-475c-a4ff-b5bbe8d204b1',
  RelationField = 'e46f9933-2fc0-065a-aa99-ecf6cbb2beb2',
  ReportIdField = 'f97bbffc-8dc3-005c-92c7-a18be9621b60',
  CreatedField = '41af48dd-0360-0c5f-99c8-41d5b7581bbb',
  ModifiedField = 'abf1f594-41d1-0b84-bc7f-038a4d08fa95',
}

export enum ClientSettingFieldsEnum {
  IdField = '11b1ca99-86df-0bbf-a13a-adbc80c8c6a4',
  NameField = '2c77e362-3033-0e9e-a298-7d86ec8891d7',
  OwnerIdField = '8a2be560-aa98-0984-ae01-f998139052d2',
  ValueField = '4ec972ac-386d-0997-bf61-86f44c3c3be1',
  CreatedField = '04b58bc5-7f48-0679-ab58-49f7793457a5',
  ModifiedField = 'b7b4bee4-785b-0424-bf15-d7084c628662',
}

export enum ClientStoryReadFieldsEnum {
  IdField = '8217f1b6-ee27-0062-8e64-7bc367ca3308',
  ActivityIdField = '040a1fcd-aef0-079e-b0b5-c9b0b67557d0',
  MessageIdField = 'b24fa461-d3bf-0b04-8e52-15a2ea217eb3',
  NoFollowField = '719ed1c9-fb4e-036c-84be-9b3a7d340e64',
  OwnerIdField = 'a4be72de-c5ea-02b0-9bb9-5ec26912a978',
  ReadAtField = '347d5cce-900c-04fd-8395-5ce09bb0200a',
  RelationField = '437f0c75-d6ba-04ad-bc2c-29a3f25b6625',
  CreatedField = '643fa82e-7060-0de6-a064-2449b1ccab1a',
  ModifiedField = '914ac3f4-f51c-0b77-a592-065ab70c39ed',
}

export enum CloudObjectFieldsEnum {
  IdField = '34aaa4a7-98dd-0143-9217-6bf777e7985e',
  ApplicationIdField = '1af02a94-0d60-4fe2-b0f8-5af508d6e3b0',
  CompanyCloudObjectFolderIdField = '1c3d6e8e-e8ba-41d0-a94e-594164b4979f',
  CreatorIdField = '6e854c0d-0638-44a2-8273-22d6b3ad123f',
  FilenameField = 'b53eaa60-9fb7-092d-80a8-4541723097fb',
  IsPublicField = '83fe3536-def9-4284-b891-382e2934a9ef',
  LinkedEntityNameField = '824f17b6-d1de-4dd1-9e59-8b3da11312c4',
  LinkedEntityTypeField = '54247ecd-510e-4b39-82ce-4ae9254e6734',
  MimeTypeField = 'f2437ba2-39aa-09e8-504c-5e53dcd282be',
  ParamsField = '92cc288f-823f-0376-684a-a82b9eca062b',
  SizeField = '4d294268-45d4-01e3-a575-94bd9e5162f8',
  StatusField = '8ba5a917-595b-4927-8338-cf538d381648',
  TemplateEntityNameField = 'ad2088c7-2d9a-4bd9-b3e8-d3e201cc434b',
  TypeField = '1283d18f-d294-4726-93ee-5a8e95f0a754',
  UrlField = '28f69868-83ba-084d-aec3-331392f37917',
  CreatedField = 'f0708437-9957-07d9-95b0-1c1fc689007f',
  ModifiedField = 'e12de279-5200-0d86-a8d0-5f940e55ad01',
}

export enum CloudObjectFolderFieldsEnum {
  IdField = '9ef721d1-c71b-4d2c-9980-6e68638faa2c',
  NameField = '7d459259-5d24-427a-a07b-56f47f0a4ba5',
  EntityTypeField = 'a5a0c7ed-728c-45d6-a50a-74b32cf7cc1e',
  LinkedEntityTypeField = '05672590-2a46-4241-b84d-fb162fe0f045',
  ParentCloudObjectFolderIdField = '675f2a24-f9cb-4952-a7d5-46c3d0219e5c',
  CreatedField = 'f44a7b92-9580-44ab-a97a-713a3d18b446',
  ModifiedField = '19a6cd3e-2808-4eed-8436-97dbb8dd04af',
}

export enum CloudObjectRelationFieldsEnum {
  IdField = '6fc662fe-ec0b-0b2f-67cb-ab1b1801cbd4',
  AccountIdField = '3c799e56-1bc8-047c-b843-c7afe990b91d',
  ActivityIdField = 'a4fdb9f9-ece1-01ec-e1d9-36d99e905909',
  CloudObjectFolderIdField = 'b2d8bdb6-42b0-4e22-8a9a-d72f6140bc93',
  CloudObjectIdField = 'ee0340d6-da82-08e7-3dab-f0b9f3cd99b1',
  ContactIdField = '0db09559-a589-0aad-d2b1-bb9ab9fb7bd0',
  EmailSequenceIdField = 'd11c4bd9-334c-4327-80bc-16e986df5e0f',
  EmailTemplateIdField = 'a32710cb-5532-498f-8e42-05b8204742bb',
  LeadOpptyIdField = '84656bf3-0234-0570-c4c6-9cabb0df7bf9',
  MessageIdField = 'b11b3e29-e96b-0a91-48c0-7f3ffddb9b7e',
  ProcessIdField = 'bec8928c-69cd-4fb5-81db-d8095bd1ad5c',
  ProcessTemplateIdField = '46bd3c59-e66a-44dc-a120-371a20bfc517',
  ProjectIdField = 'cdca255c-3424-4dc2-9db0-115203108e1b',
  QuoteIdField = 'e0611c30-a42f-4ce8-a47c-065d7ff351e4',
  RelationField = '9f13856f-dabd-0fa1-8a27-04b07dec3234',
  ReportScheduleIdField = '7742c7a9-b79d-4003-87e7-b321151c0104',
  CreatedField = 'a528c5ee-b0dc-07f0-0cfc-294610aad545',
  ModifiedField = 'd21effde-3204-0bc8-b296-f18d043744cd',
}

export enum CloudObjectStepRelationFieldsEnum {
  IdField = 'fea323b6-2b48-0604-b47e-17262116eb5b',
  CloudObjectIdField = '07aaf665-78b7-05fc-92b0-bf4aa3db3062',
  StepIdField = '2314596d-85a1-0ece-7b7e-c84023d41080',
  CreatedField = 'a57c439a-53ba-0c92-8ada-3efd1e4d216a',
  ModifiedField = 'e4a23278-a112-044d-9b72-7ad92420a1d4',
}

export enum CompanyEmailFieldsEnum {
  IdField = '9f92d7df-ef24-404f-8a01-da189c797fb1',
  EmailField = '3b0a0dfb-48d1-48ed-9082-785bc6886802',
  EnabledField = '37123dcd-a59f-47d1-8114-204bbdd14da6',
  ErrorCodeField = '0d11e476-6b2b-4da4-878e-90232ae72751',
  NameField = 'c8d33cc5-1a2f-4175-b199-8d88d24de70b',
  PictureUrlField = 'b0c71978-bc56-4b61-a31b-129ac739aed7',
  ServiceTypeField = '6cb364af-e4dc-40d3-bb05-c34b1e6c13dd',
  TokenField = '628c29cf-e928-490f-8b96-a08d3cf2637f',
  CreatedField = 'fcf5c92a-fb17-43d2-82f4-3a7eb26b550d',
  ModifiedField = '85707b04-dc66-435c-b002-bff0c2158e66',
}

export enum ContactFieldsEnum {
  ContactIdField = 'c1e591e1-f028-0d02-fd9f-33e3c91222ac',
  FirstNameField = '8b4278ff-6585-0e51-95f7-bc6a8f8eb229',
  MiddleNameField = 'e2702651-d567-0dd5-aed5-0946b0f0ed51',
  LastNameField = 'adbf0b59-e593-01ab-3758-bb37d421fce9',
  AccountIdField = '052c3515-b420-0524-e379-2f81dcce33c1',
  AddressField = 'e0e91944-ed62-0315-2a13-492347839a76',
  AutomatizerCreatedByProcessField = '182e00c1-e9d0-445c-91de-2cc9c880bda7',
  AutomatizerProcessTriggeredDateField = 'ebc292cb-6a4a-4c8b-9b94-1421059c0bdb',
  AutomatizerProcessWithScheduledActionField = '538fa1e7-5d92-4386-a337-6e0e0595efbb',
  AutomatizerProcessWithScheduledActionDateField = '261c1833-d242-495c-8b02-de5ea1555682',
  AutomatizerTriggeredByActorField = 'fb4fd18b-3cbe-420c-8aaa-2ddbd4750f25',
  AutomatizerTriggeredByProcessField = 'adfe0165-a563-480b-8023-e8d00aaf8826',
  AutomatizerUpdatedByProcessField = 'ca5a2ad8-29d5-4224-a435-ce1fc0723675',
  CityField = 'f3a00eff-99d2-05a0-96d3-ef1ac8f580a5',
  CommentsField = 'd9c85b41-6b43-09be-fa01-10f495a378fd',
  QuickAccountNameField = 'cc9a8b86-830c-0ea8-47f7-25b8c8d54a73',
  ContactTypeIdField = '0214c5fb-63f2-06f7-0f84-9a1ecc214620',
  CountryField = 'f5ca36ea-b33d-0bfc-d727-fc3a95845d1e',
  CreatedByField = '780f755f-5838-0fe3-cabb-8a7a7abbaf69',
  DocumentsField = '28576288-d8a6-00b1-b0bd-22866ed0af72',
  EditorsField = '4fdf59f4-e4b3-085c-9e66-ad0ae082e2ea',
  Email1Field = '7fd75517-a1d9-0982-c7fa-1283a32a394e',
  Email2Field = '6c16721b-b2c9-0329-a6d1-a022e4d5eadb',
  Email3Field = 'd56c8faf-d6d9-0843-9169-766073d22de6',
  Email4Field = '5b2b579e-7717-09a9-1cd7-4ab85cac43b8',
  Email5Field = 'a60d761d-f83b-0443-7adb-77bdd31b7be6',
  EmailSequenceCurrentlyEnrolledInSequenceField = 'da2d4a51-8759-4c0c-b79f-0cf17c293381',
  EmailSequenceEnrolledByActorField = '8eca7b8b-e8f8-4441-aa3d-985fbf9a1073',
  EmailSequenceEnrolledInSequenceField = '0eb46202-1977-4f33-b8ee-927bb2f693ec',
  EmailSequenceLastEnrollmentDateField = '6dab0377-10fe-4cba-b70e-b566c0bbff2c',
  GenderField = '0ba5fb93-2c69-0c43-f4a3-d34ef3ccc231',
  HasScheduledActivityField = '2654f641-8271-01e9-cf84-82aaac9eb522',
  IsFavoriteField = '48bbc566-85c8-0660-175e-7b872a8e932f',
  IsUnsubscribedField = '0c825eab-85ee-4caa-a54b-33d488935f8c',
  KpiField = '708e07f7-99fc-0c71-2d0f-8fcf31ff2f28',
  LastContactedDateField = 'da1951f9-43e2-43ff-9e12-75443b794a0d',
  LocationField = 'f7af41a8-6a7b-428a-bb16-017f78d7c3c6',
  ModifiedByUserField = 'f204c7bd-0b1d-49da-96f0-4782908a2045',
  NameField = '2853409f-dc70-002c-e9ba-c91f4649cb7b',
  NotesField = '0a35eb67-2940-0923-68c1-551d6e537060',
  OwnerIdField = 'f4d3e906-3f95-0e20-3e26-b0808fcc5da8',
  ParentUnitIdField = '8563f953-d645-07e9-14e0-fe2f8122e9c7',
  Phone1Field = '55386cac-86c6-04a2-0bde-aaf2a05ab549',
  Phone2Field = '4a272a34-44d7-0953-4ce7-6ad3f0248d03',
  Phone3Field = '1977c3a5-5683-0adc-e04c-caac220ad643',
  Phone4Field = 'e57c1e03-d85b-03b2-5e33-8c693c558c79',
  Phone5Field = '77e17fc0-89fe-03ce-9584-dc6a196f49fe',
  PictureIdField = 'f7fb03bb-39fc-046a-b2cb-18831d7ced95',
  PositionField = '842213cf-d8b1-094c-6593-4411a7dbbf03',
  PrimaryAccountAccountRolesField = '3b7ff070-6277-43d4-88c3-ee2bbbff9bb4',
  PrimaryAccountRelationshipField = '84163d91-fc02-44e4-a36c-e2dc96d24ddd',
  RecordUrlField = '5ebe564a-8a42-4ee0-b1fd-7438f29b1f36',
  RelatedAccountField = '4d41ffbe-51ca-05dd-b29d-9dcf8bb5afaf',
  RelatedAccountPositionField = '2722af28-6d4e-4cbc-bf47-190dd5173cad',
  RelatedAccountsField = '0ec97d4b-f857-4634-a0c2-a2f19403b946',
  RelatedActivityDeferredField = 'aa0e70d0-e028-06c2-d48b-a49805ca4af3',
  RelatedActivityFinishedField = '075efb97-276d-04f4-0042-94d9d1d47140',
  RelatedActivityInProgressField = '7622a249-8ff4-038b-91a2-b62254dccc20',
  RelatedActivityNotStartedField = '9e470dfc-dd8d-0568-0478-0a994de3edee',
  RelatedActivityOverdueField = 'c52fae92-582e-03fa-8881-6e7548f43917',
  RelatedActivityWaitingField = 'b5e3c646-3094-0109-2e36-19a1656ccdb8',
  RelatedOpptyLostField = 'ffe98560-ce19-0ae7-bab5-067cebf72bb6',
  RelatedOpptyOpenField = 'b658dfdd-9ba0-0ba7-ebbc-2b3061d9cbb0',
  RelatedOpptyWonField = 'c2e38ba2-82d0-03cb-313a-ff8a61de6836',
  ShareActorField = '0f8d8364-5b2d-0ba0-8218-9e619a7145a5',
  ShareModeField = 'debb6edf-ea94-08f6-a064-0e542084cb31',
  SharingField = 'e3d6f764-5e6b-0b47-f4fe-aec222f21d6f',
  SharingClientsField = 'b4720562-7b5f-095b-a041-66df147600bc',
  SharingUnitsField = '6acd04bf-4663-0ec5-789c-f5e7a05f2dec',
  SocialMediaField = 'df68d5d2-a26b-4363-8b62-d2fe99ce3703',
  StateProvinceField = 'ffd08985-ceea-0df0-aad1-33e8b63055e9',
  TagsField = '912e41b7-9899-4fed-a6f0-d917468531e0',
  TitleField = '1ad96453-219c-0de5-0c5e-d6a3ff056458',
  UnitIdField = '9d1896a6-e43f-0366-4250-294cb2aa0c73',
  WatchersField = '34a9a2f5-57a9-066f-b3d4-2901e0c243f8',
  ZipCodeField = 'd49a0ce6-0be2-0fb3-5bcd-0522bc69abf7',
  CreatedField = 'dcbdd62a-9d3d-06fa-562e-04c497b1d049',
  ModifiedField = 'd86c1038-646b-0321-c9b3-4e8d889a5f1b',
}

export enum ContactAccountAccountRoleRelationFieldsEnum {
  IdField = 'ab76d94c-36c7-4308-b874-748a1a20d9fc',
  AccountRoleIdField = '76992b96-38d8-4c16-b1bf-453ebf6b7dee',
  ContactAccountRelationIdField = '295d4068-623a-4b86-a54f-630cd67202b2',
  CreatedField = 'fe5c9bcf-e922-4a99-b6eb-3226284ab583',
  ModifiedField = 'c49ae62e-a0a7-4bdb-911e-8c378ba961c7',
}

export enum ContactAccountRelationFieldsEnum {
  IdField = '25e71291-840a-05e7-4cdb-b2d300bc6aef',
  AccountIdField = '341de4dc-282e-02cc-6238-9b07798b7321',
  CommentField = '5aeacf45-ea42-0314-02a1-6e4fad3dfcd7',
  ContactIdField = '385f11dc-9ebf-0a6b-5cd2-82eea4a6a404',
  IsAssistantField = '966b847b-6c9b-05ac-b6df-72aa73ab8b1a',
  IsPrimaryField = '87980446-5796-0759-28f3-53976277be1f',
  IsSiblingField = 'be9976e1-ab4d-46be-afc1-003fb0d8ece6',
  PositionField = '3ca21241-c12f-02a0-7b9a-368ad5fba0bf',
  RelationshipField = '4e70b2a4-8367-44b4-9933-780ec1821811',
  SalesRolesField = 'c15461e1-d883-4928-adbf-356e5d3b2f6f',
  CreatedField = '0d360348-ac15-0e32-ad7b-9efb66c315d4',
  ModifiedField = '88dddb49-7cb2-05ab-9528-afd88cdab3a4',
}

export enum ContactDataExRelationFieldsEnum {
  IdField = '52cd7735-58b0-465e-a277-a7286e38b9c6',
  ContactIdField = '23667c5f-e2e5-499e-8acb-44033a6f8ccb',
  DataIdField = '1ebceae2-2b4e-4c72-93f2-fa0e190754f6',
  DataSetIdField = 'c7eeda3e-928c-48d1-85a5-f6630a561a65',
  CreatedField = 'aa7c4793-242b-4df8-942d-64c0e18b281b',
  ModifiedField = 'e6958a26-b27e-451e-bfc3-960ddf3b1f26',
}

export enum ContactKPIFieldsEnum {
  IdField = '109caf3f-b097-0f22-f7ab-0aa521a9533d',
  ApplicationIdField = '8f652327-c053-4d52-a58b-1802f175bee6',
  ClientIdField = 'c2382e3f-5d79-0764-8b47-d16b6235c098',
  ContactIdField = '6ed759a0-3fba-0a9c-3934-3660fd4d7822',
  KpiField = '29f01c9b-b11e-0027-532b-8ce0abe411c5',
  OwnerIdField = 'fb47448a-089b-48b0-83b7-b6eb8469e8bc',
  UnitIdField = '10113997-7548-011e-3e19-5c644838a9f6',
  CreatedField = '751bce2f-34ab-0015-6066-a4f854adf91a',
  ModifiedField = 'c4e4bcf4-539e-03fa-2573-8c27b0d8555b',
}

export enum ContactRelationFieldsEnum {
  IdField = '2fa5c350-2e41-4b31-a86f-d51a1daeabb4',
  Contact1IdField = '573c1360-d7ac-4a8d-9bc5-43bc76d0a306',
  Contact2IdField = '66288cf3-e9c5-4ec5-bfad-e0d842e7564e',
  LabelField = '614e9097-85d5-430e-8107-19e0353daf2a',
  CreatedField = 'a8e41762-5423-4ea0-8712-521d2169f8d0',
  ModifiedField = '60b6c026-ea1a-4d75-b1ec-5da94c071d70',
}

export enum ContactRelationLabelFieldsEnum {
  IdField = '5165e0f8-256c-47b8-8979-5570f8796ce0',
  ContactRelationIdField = '7a8d844c-2722-4baf-a831-72295fd5f428',
  ContactRelationTypeIdField = '76a6303e-523a-4d2b-b924-f01ae512d685',
  CreatedField = '66cfcfdc-7182-4c6c-8f43-f88e50d53287',
  ModifiedField = '007fc554-56b7-4314-b74d-915b194ecd25',
}

export enum ContactRelationTypeFieldsEnum {
  IdField = 'bf0228fa-763c-4e3c-96d6-06f442cce674',
  ColorField = '9211d2dc-26d0-4dcf-81a4-9a975e7db4c7',
  LabelField = 'b9b99f75-08df-4f78-bb02-cc5e1353801b',
  CreatedField = '8b9f7ec2-a0ff-4512-82d0-7d891a03ecd0',
  ModifiedField = '9bf23dee-8ec3-4d8a-ab1b-487cf508bad9',
}

export enum ContactSharingClientRelationFieldsEnum {
  IdField = 'a8e794ed-fbdf-0c7f-ec93-76b588e3f773',
  ClientIdField = 'c218708c-b7d5-0ec2-05d5-f96b4647f935',
  ContactIdField = '671e3612-c5c1-0846-f7fd-ea8781c3cbe2',
  RoleField = '1f67efcb-673b-0873-2348-8d65d93f93be',
  CreatedField = 'aecda20e-2865-0270-24cf-7a475301a124',
  ModifiedField = '136e1c6b-3741-01b8-d1b4-300333b80641',
}

export enum ContactSharingSalesUnitRelationFieldsEnum {
  IdField = '031dd82a-185d-05d2-b25d-a0ba97f4cd72',
  ContactIdField = '017057a2-392b-0e0b-7e6b-3a696615ef92',
  RoleField = 'e46bd84d-f16e-0b4a-4897-78efdf07bc09',
  UnitIdField = '83ed4135-1ff8-0806-e0ac-b41592b1c615',
  CreatedField = '4317b567-ec66-01e0-9e06-2be2228dc315',
  ModifiedField = 'dee215d9-3d97-00ed-1dd8-3bdc8f3880b7',
}

export enum ContactSocialRelationFieldsEnum {
  IdField = 'f25a6483-7b57-08f5-3662-a5735e37f0fc',
  AngellistUrlField = '8dcfb6cd-46ce-03bb-8a81-0b1d06f5e661',
  CrunchbaseUrlField = 'f0b0f144-005d-049c-9c89-d93488854963',
  DisqusUrlField = '4b1d0dfe-96c0-06d6-28eb-c6b43bd2b498',
  FacebookIdField = 'd3b6c6e1-3458-0008-28bc-1bcb8418bd17',
  FacebookUrlField = '78dd5c8c-110b-0c65-569a-6253fd4149c8',
  FlickrUrlField = 'cae6877f-d7fc-0326-2fb8-e319a1dbd186',
  FoursquareUrlField = '39b06a4f-596a-0b8a-75db-7a346ee0b0f8',
  GoogleIdField = '12e43922-2e21-0627-ce34-631a10eb78fd',
  GooglePlusUrlField = 'e7af8060-ae3d-0a33-b939-e5c47e26f11e',
  GravatarUrlField = '7bedc4cd-4b29-0e09-7b02-d90096b09e50',
  KloutUrlField = '4903eb6c-e938-0d7a-9f74-19664f7f04d6',
  LinkedinIdField = 'fe4d2c77-e10b-0287-d700-4b4bb3a620a0',
  LinkedinUrlField = 'ab61f9ba-0a57-045d-19c4-b27894f0a403',
  PinterestUrlField = 'f8ed9141-249b-07d4-1dba-de0395bcdced',
  QuoraUrlField = '136dbe6c-8c29-0917-a456-e8846a111268',
  RedditUrlField = '245abd17-af4b-0a26-5fac-388b26cf4f9d',
  SkypeUrlField = '4318aeee-2437-06eb-4849-b497cdc1f8bb',
  SlideshareUrlField = '9d48d2eb-1e54-0fad-fbd3-3c927e10ed4a',
  TwitterIdField = 'd65950e2-7656-0a18-474d-8ab1c1486a17',
  TwitterUrlField = 'b6c12b78-be87-0287-66a6-72d3ffa35cc7',
  VimeoUrlField = '79a495e5-d905-0f65-0d6d-9729c4ff68b0',
  WordpressUrlField = 'bb67a26b-2b05-0d13-8285-eedce2297d32',
  XingUrlField = '1dbe6dee-0932-0716-29a1-025cd1b8ee05',
  YahooUrlField = 'c4c20e84-a61e-0bae-6997-6d2ddde41d3c',
  YelpUrlField = '5f092ccd-f8ee-0243-498a-ea0eca74fcef',
  YoutubeUrlField = '3ccda9be-ab87-02d3-cf4e-99ff80da3bce',
  CreatedField = '27041d89-3ebd-0b35-cc21-424f9f79d45e',
  ModifiedField = 'a5f7faf9-7eb1-0653-fd65-aaa434be42bf',
}

export enum ContactTypeFieldsEnum {
  IdField = 'ccee3c10-eb87-034a-ec86-47533f12f845',
  NameField = 'c7048d36-fa29-0fa4-0c2c-65627f3fede8',
  FormEditField = 'b3e42b9e-36fd-04b7-1a7e-3c358726dfcb',
  IsReadonlyField = '09b1c1bf-264e-083d-59ed-688c0225b9cc',
  CreatedField = 'a9464625-561c-0149-e56b-0aa859bb41d3',
  ModifiedField = '2e695fb8-4f36-0f51-aae6-08241c29d4d8',
}

export enum CurrencyFieldsEnum {
  IdField = '4f33e0c7-3940-0128-afe8-c2ae993e4d11',
  CodeField = '29a255fe-7eb6-05d9-4281-e94edd657f02',
  IsBaseField = '77cad014-277f-0932-2ad7-3b731516d168',
  SymbolField = '48cd613f-3668-0e55-5f02-5f157ec5f10a',
  CreatedField = 'ca0751fe-8c83-0b6e-310d-5265a76561ef',
  ModifiedField = '575c4e4d-c35a-0974-d9a6-dfbe9938a959',
}

export enum CurrencyExchangeRateFieldsEnum {
  IdField = '7a83fe44-0e65-034e-3fb0-6915cdfb2b54',
  ExchangeRateListIdField = 'dd522cc0-6075-070e-4918-38f1306fe600',
  CurrencyIdField = '08f11c34-902c-06fa-db25-7358db205644',
  ExchangeRateField = 'f28055e8-29de-00bc-83d0-6dbd5e647b4d',
  CreatedField = 'fe00afda-8f74-07b3-e01c-2760754c48ef',
  ModifiedField = '3ad15ab0-329c-0ff7-23e2-ba7d94f91d9a',
}

export enum CurrencyExchangeRatesListFieldsEnum {
  IdField = 'eaac70d2-c994-038b-1051-8f5031eb6301',
  ValidFromField = '005b8a6d-d709-08fe-ac1d-8f53ecd1d0bf',
  CreatedField = 'afe118ff-c985-0ae1-5514-59ec836d27a8',
  ModifiedField = '83f03d37-8998-0c11-c8d9-81539c999d7f',
}

export enum CustomEntityRelationFieldsEnum {
  IdField = 'fc843a2f-91fc-47ce-9379-37b0da72e04a',
  EntityFilter1Field = '1ba05021-43c3-4f82-950c-9527bc78c780',
  EntityFilter2Field = 'dc990e85-10df-4afc-8f0f-a7a251ad0752',
  Field1IdField = 'fcebabbe-2d78-46e0-96c8-06345622b62e',
  Field2IdField = '5e05618c-4fc3-40c0-9f9a-b8da018e8634',
  ShowRelated1Field = '5ea32a35-b702-4d27-80a6-bbce1ffc1e77',
  ShowRelated2Field = 'd7db45b4-de26-475a-8fe4-5d8b5714ac7c',
  ShowTab1Field = '83c6964e-c1c6-4a19-87a9-962dcd45713b',
  ShowTab2Field = '593c1e32-425a-4499-b617-f4c179613a35',
  TabName1Field = '33577b9e-2fd7-4442-b1ce-c6f233d62e79',
  TabName2Field = 'ac4282c2-4621-4317-8c42-fb5ab7df4107',
  CreatedField = '584f6632-124a-434e-8247-665cd37e03a2',
  ModifiedField = 'adf0a5c4-b628-4212-995c-6785625dd145',
}

export enum CustomEntityRelationDataFieldsEnum {
  IdField = '46f538ac-5640-4280-832b-8f50907cb158',
  Account1IdField = 'dad1db13-2c90-438d-a56b-1e52eec08303',
  Account2IdField = 'c6e5579c-5813-45d2-abbb-b424e8d790bb',
  Activity1IdField = '307e696e-3a57-4219-bdee-4148cc46fc68',
  Activity2IdField = '3aa48a56-4eea-461f-8654-9b14d3fc44d9',
  Client1IdField = '96c61986-0958-4f04-bed4-68cac1ae73ae',
  Client2IdField = '1d876da9-bb70-4353-9231-340d45286cd7',
  Contact1IdField = '976c5309-1687-4062-b315-14b63f7c1efe',
  Contact2IdField = '3a6b0647-304e-4c98-8290-4c6e9f5114fd',
  CustomEntityRelationIdField = '93aca86e-26db-4bcc-a9c9-40e14ae1953a',
  LeadOppty1IdField = 'dfa6ae82-dfee-4aec-91d1-60eb352eeeb7',
  LeadOppty2IdField = '1a1491e5-506e-4973-a4c5-d919559cfbbe',
  OpptyProductRelation1IdField = '7f13c1ee-512b-4c64-a2d3-7e72b0931558',
  OpptyProductRelation2IdField = '21c27f6b-dde0-4dc1-9c5a-8ba88f930fca',
  Product1IdField = 'd43d0c2f-e7ae-453a-841a-94ad629e01d6',
  Product2IdField = 'c3f25c0c-e287-46f6-a861-5cbbe0a70b44',
  Project1IdField = 'cb90f54a-44f9-4402-b842-9990af754696',
  Project2IdField = 'b32343c3-7ecb-4c56-b58f-5c1b5839cf8d',
  Quote1IdField = '85f523da-eed3-4073-b6ee-cd1b16f276da',
  Quote2IdField = '5090f08b-8507-4d30-aa88-d2d2d3231e5f',
  Relation1Field = 'f0c0047e-4f0f-442e-a06b-9218f11a05f2',
  Relation2Field = '33e73ff0-3fd8-49d8-a36e-abf1ccb17225',
  CreatedField = '71870ba0-05b2-4bbb-910f-f123b987ca09',
  ModifiedField = '95913090-3478-46ec-82d4-0966158a1282',
}

export enum DataFieldsEnum {
  IdField = '9b252427-eb4d-07aa-d765-c751224a8590',
  OptionNameField = 'd7b36f3c-0059-06d9-f259-848da33c0302',
  CalcValueField = '25d71c02-6058-07e6-a32a-e572a74b5ff1',
  DataSetIdField = 'e47e5d0d-c7f4-0196-2fa5-68f0effa2d20',
  SortOrderField = '909c00e0-ce01-0994-e350-68264f81ba42',
  CreatedField = '15dd022d-2057-0710-c88a-2e0129cb25b0',
  ModifiedField = 'f81888e1-ee4a-0e62-b6c2-d405984a0392',
}

export enum DataRelationFieldsEnum {
  IdField = '7d046db2-70d9-0d32-a0e6-6f3d73c22416',
  DataIdField = '57cd8ff2-9306-0c77-a895-675b77379aa8',
  ParentDataIdField = 'aab73ec5-2652-02d5-bba4-82cfff3b5913',
  CreatedField = '8302b69c-c72d-0027-a262-58ba61bb2fd3',
  ModifiedField = '68d27dbf-b47f-0e73-ade7-6254f29e5c3c',
}

export enum EmailFieldsEnum {
  EmailIdField = 'ab836fa8-3113-04cd-a041-8eb17c53af97',
  SubjectField = '227320a5-43ef-0a3d-254a-2daade956f0c',
  AccountRelationsField = '5a362e81-75ad-0df4-28eb-a2bd61f0abfa',
  AdditionalHeadersField = 'e273f1fa-b951-4c2a-9426-f39e5d8e65d8',
  AttachmentsField = 'b8766020-a767-08c0-fd81-bb3daca372ba',
  BodyField = '938fcee8-f5e5-0baa-ac80-baa844296a16',
  BodySummaryField = '507ab3f1-24ee-0d22-d4b9-cd00aaa64f9d',
  CcField = '1231d4f0-17ac-0ff2-0be3-90eb3dadee94',
  CompanyEmailIdField = '2012257b-7371-445e-a0d5-e166db468cb6',
  ContactRelationsField = '2dd2b528-e3b2-08a6-22e0-6c033f1aa107',
  ContentShareModeField = '47ef4228-8092-4c9b-b0f5-e9e1376775a7',
  ContentSharingClientsField = '7578d250-0311-4267-93f1-c641b24a1a1c',
  ContentSharingUnitsField = 'd52deba8-dec9-4d60-9b0e-8214f97cbb0e',
  ContentTypeField = '6ddd31d4-44b3-00a4-f649-a0ca280f6802',
  DateSentField = 'b2fe05a9-a347-0a31-7c82-3067b69c97f1',
  DirectionField = '8403ef32-352a-01ae-2403-80522fa54323',
  ErrorCodeField = '5f37f343-6421-44b5-9958-73228787c794',
  HasAttachmentField = '61613f83-7247-08c6-8062-793deaab489f',
  IsTrackedField = '0fb5134e-a449-4ab9-b869-96945f33c18a',
  LeadOpptyRelationsField = '560aa464-362c-0e30-1f4f-70b03eca443d',
  LinkedItemsField = '9be74245-4365-08fc-5ba1-4b3b348bbf5d',
  OriginalIdField = '623d5fe7-c5a5-434a-906f-9f271d9413c3',
  OwnerIdField = '0649c24e-d47d-45c4-9cdc-cda341063c43',
  ParentEmailIdField = 'a1a19de6-baa9-46e7-8e34-8fc527b9a2a6',
  ProjectRelationsField = 'd9f4849e-1178-4000-af5c-ebd8c36e210f',
  QuoteRelationsField = 'a742c245-9935-4c5c-91e4-52b08c6e6b84',
  RelationField = '0e401f24-bef6-0f21-916b-7a6565fe1995',
  SenderField = 'e13f1d87-f3a3-0dbd-750f-c12120ab40a0',
  SenderAccountIdField = 'e02488e4-b3f1-03ea-ad1c-6ece564f1561',
  SenderClientIdField = 'bb15b696-0ccb-0886-6fa5-26b4d8e9d94f',
  SenderContactIdField = '1d39e240-0935-0451-a98c-67db9623e7f0',
  ShareModeField = '3ed2ff1c-f96f-4c52-9bcb-f94c6b87e281',
  SharingField = 'a19e0532-d48a-015a-46ba-c2381442a099',
  SharingClientsField = 'dc707973-6837-048e-1fea-2e516f903615',
  SharingUnitsField = '7ebc8f56-8335-0dcd-fd31-6a2b8ec50968',
  TemplateIdField = 'e0e79f41-2653-4e9e-a3e6-c07e2c41820c',
  ThreadIdField = '8f803aa0-303a-4833-9211-e60094840a48',
  ToField = '42d3b720-bc97-015d-d024-29200781179b',
  TrackingStatusField = 'd630371f-534a-4524-b32c-c3ee6c8096f2',
  TypeField = '3f1c628d-d1ca-48f3-b562-f1a0f990ee38',
  UidField = '148d132f-dda9-0684-0de1-ef897b8f65ef',
  WatchersField = 'fa03ad5c-2b93-437a-ae45-f4834987df49',
  CreatedField = '7e20b7b7-b6e9-01b7-b1df-a11bc2f71ee2',
  ModifiedField = 'bbe350ca-e19a-0a43-fac0-c222dda33786',
}

export enum EmailContentSharingClientRelationFieldsEnum {
  IdField = 'b2e10f41-1fa1-4b82-a587-5c57f973c4f3',
  ClientIdField = '4e2daef6-413a-451a-8193-54afa3e9c851',
  EmailIdField = '58a56cd7-eaa5-49c8-80e9-ff8dd9371059',
  CreatedField = 'a456249d-c83a-48ab-8d41-843042392bb5',
  ModifiedField = '0b99a17d-a82e-4527-8614-68709c8731da',
}

export enum EmailContentSharingSalesUnitRelationFieldsEnum {
  IdField = '857a0514-bff7-437d-9026-9f50d94fde8d',
  EmailIdField = '0fbc8d37-4c15-4b63-bcad-496d9c0188cd',
  UnitIdField = '85914777-454b-43fe-aa12-5643a2bd0f97',
  CreatedField = '6535d3e8-cf5a-430f-9c76-e1469d283a34',
  ModifiedField = 'e61e2f5d-d609-4370-8b67-505fa670f7cb',
}

export enum EmailEventFieldsEnum {
  IdField = '54a6d16f-b52d-458f-becb-927570284ae9',
  EmailIdField = 'bed739af-aafc-4c78-8f8b-4c6ddf2a62d2',
  TypeField = 'fa2b4281-2ddf-40d8-97e6-cc62dad0cbb8',
  UrlField = '6a352dec-f4c1-4f6d-967d-5ee0a8f82ac4',
  CreatedField = 'dd2352b8-c626-48a1-b360-094944357248',
  ModifiedField = '2b8447db-3019-4a81-aadd-4e197fde828a',
}

export enum EmailSequenceFieldsEnum {
  IdField = 'c866762e-ce44-42d7-88dc-c371c011c12a',
  NameField = '2eaf4f19-cc08-4cf5-a798-cbf77aac6e20',
  TriggerEntityNameField = '323e3623-1b0d-40be-b044-3c036b2477e6',
  ActivityLogsField = '9546f797-e4a5-4bc3-93e4-32b1467e0c12',
  AttachmentsField = '3578858e-c034-47bd-9ab7-f014814f8fa9',
  BounceRateField = '3e0864f7-8998-4e79-a118-f046faaed4bc',
  ClickRateField = 'd85067ed-708b-4143-b539-01ec89eadf7a',
  CreatedCountField = '880d7571-374e-4caf-8a26-c6e907e628dc',
  DeactivationReasonField = 'e52eade5-74dc-41dd-a6aa-8e4f9d252c13',
  DescriptionField = '6f3397f0-fc33-40a9-8966-abd6bf13f8d5',
  DurationField = '91887569-f519-440e-9690-82594f98668d',
  EnrollCountField = 'a9ff2e6a-039e-46af-bb6c-a092ea3119ca',
  EnrolledField = '2fd719b4-beab-4434-b2d1-ac0e5a2a98f0',
  IsEnabledField = '49e1b056-1018-4a7b-a1fd-a19b84be8e34',
  LastEmailSequenceRunField = 'f0e84122-24d2-4ab7-bc52-7908b89dbd81',
  LastSchemaChangeField = '6373c6d4-e244-4c96-8a4a-938a4d83af66',
  LastStatusChangeField = '15161b5d-c4cd-4769-bd79-7fdbce4b5cbb',
  LastStepsStatisticsResetField = '06c18c9b-d060-404f-be49-6d528d80929b',
  OpenRateField = '1e74fe97-ba0e-44ec-a9e0-d1768021076d',
  OwnerIdField = '22cf1fc9-b285-4dae-b5ef-98ffdc2f603e',
  ReplyRateField = '4b03972b-f4dc-4241-889e-aafa9e602b1f',
  SentCountField = 'da4b3b7e-a7b7-4174-9fae-867d04d9bf8e',
  SettingsField = '46de89b3-5c0a-4e00-a77e-505d4939dffb',
  StatusField = '536e3767-fea5-43bb-b550-cc747c63da1a',
  StepCountField = '27a8d28b-5a80-45a4-8cdb-12cd8483fb4d',
  TriggerFieldIdField = '3877cd2c-34e5-4419-a97f-ee02398dac4c',
  TypeField = 'c81e8a84-c52a-4357-b1e4-a6a675e537e4',
  UnsubscribeRateField = 'ed7d1b09-4e8b-4737-97d0-28a686ecd164',
  CreatedField = 'bdcdf95e-467a-456a-9f46-bc80bec0742d',
  ModifiedField = 'b6f57ea9-6589-4a63-b012-6b421643330f',
}

export enum EmailSequenceActivityLogFieldsEnum {
  IdField = 'eef94537-fb3c-4ee0-89a2-b52fedab4e6e',
  ActorIdField = '2bc0ee5c-7439-4ead-8467-e1925e71b724',
  ApplicationIdField = '0e5908c2-685e-48aa-8b22-1c63e796e8a1',
  EmailSequenceIdField = '3c381ca3-6a8a-49cf-b7dc-1efed0abc321',
  EndTimeField = '2f3afe2d-dd7d-490a-aaf2-0a62ebf9524e',
  IsTestRunField = 'a08c7b38-a0ae-4f77-80c0-e202b2f17160',
  LinesField = '87ba8253-0ada-47e6-863e-ae15c5d7127d',
  PeriodField = '750928c6-1293-421b-8f73-e5e3c67bde54',
  StartTimeField = '64f43ef9-0349-4a40-84c1-cc2fe8ff1d13',
  StatusField = 'fd4ee22e-5e93-46be-a6fb-2a533f7bc49a',
  CreatedField = '2d5193c0-406e-4868-9c6b-9a897623bf94',
  ModifiedField = '02c1aba6-632d-4d62-86a0-9c3cf69917d9',
}

export enum EmailSequenceActivityLogLineFieldsEnum {
  IdField = '8de28ce6-8ecc-43c4-86ec-2d15cac468e9',
  DataField = '6e9e7dbd-26c2-4121-8805-b8cfb3af54cb',
  EmailSequenceActivityLogIdField = '1665d0e3-1113-42f4-a552-8ddebcb6ab10',
  IsVisibleField = '6157c021-de82-445a-bea5-f920bdccfead',
  SortOrderField = '7b6561d8-9faf-4ec5-9360-ae5e95191a51',
  StatusField = '0029f621-127a-4525-8487-c6190ae96127',
  CreatedField = 'e9029e40-4398-4fdb-817f-2d73b12a5abf',
  ModifiedField = 'b56652ad-fb1d-4bc0-bca2-11d00f484726',
}

export enum EmailSequenceEnrolledFieldsEnum {
  IdField = '13f59ca3-753d-4a4d-a168-d8000c5be5c0',
  AccountIdField = 'b8fe2917-3a0a-4770-9d19-4078076a429e',
  ActorIdField = 'acf2382c-ede5-4711-8c13-f720b45ccb22',
  ApplicationIdField = '37fc94c2-2990-49b7-9ab8-d42f962f69cb',
  ContactIdField = '6113869c-4888-4879-8777-abf437a96a2e',
  EmailField = 'ea035cd5-fad3-4459-a3e2-fe746405e39a',
  EmailAddressField = 'e741c016-fe65-4493-ae9e-71c016296426',
  EmailSequenceActivityLogIdField = 'e0dff874-c5fd-4df7-bf72-92182e5e2b8f',
  EmailSequenceIdField = '06061060-f9d3-4810-ae51-4f82158451cd',
  EmailSequenceSettingsIdField = '9fcdd9d2-b95c-4231-b60e-83c98d3dfb82',
  EndReasonField = '13189191-3e8c-4b03-872a-bf4c1e43436e',
  EndTimeField = '4288c8fa-9b50-4bf3-b6bf-e8bb7576a8a7',
  EntityIdField = 'b4097801-a3cd-4b59-9f4f-323f13305cfe',
  EntityTypeField = '1806160c-dafb-429d-afbc-b1331951122b',
  ExcludedFromStatsField = '7a94920c-4c5e-4bed-b79a-a0e09731cba9',
  LeadIdField = '7a71b951-b318-4bee-ae13-e8f5c6e3b57c',
  NameField = '95055773-149b-4490-a4a3-8222229501d0',
  OpptyIdField = '08fb5d5c-450f-417d-87cb-dec21a2b2bd7',
  PeriodField = 'eac666b5-0920-47d1-ad75-0a1cfca44f99',
  ProjectIdField = '55b4fa4f-0e70-4a61-bef7-1ae17cae9e8b',
  RelationField = 'b0131d7b-423b-4c2d-aae4-f50a73f8f26c',
  StartTimeField = 'f3c8f00b-77d8-4e77-86b0-7c9760783912',
  StepCountField = '6ec0d5ec-6c9d-4450-bc35-861196c67cfd',
  CreatedField = '39e9a39e-accf-494f-9265-28911f15a2a7',
  ModifiedField = 'bfa2b92b-e89e-4ec5-970b-bb3e34e575e1',
}

export enum EmailSequenceEnrolledStatusFieldsEnum {
  IdField = '31fef18e-ac69-4d70-b699-12934baef119',
  EmailIdField = '0c415aed-9556-4e24-9f09-8866a0213445',
  EmailSequenceEnrolledIdField = '6a896515-d06a-4263-aa74-0a19ecbea2f0',
  ExcludedFromStatsField = '01ee6d90-4922-45b6-aa9f-c3aec819245b',
  LeadOpptyIdField = '6bd2fe59-542c-43c5-aa8e-6f3589aa7d65',
  StepIdField = '730bfdfe-b6e1-43f7-8515-97d9d11f3ca0',
  StepIndexField = '13c828ef-8ff7-4f19-bdb0-ff5ed17bc57d',
  TaskIdField = '53c7c5a2-5751-4480-bb32-7098114bc0f3',
  TextMessageIdField = 'b6935448-3553-43ca-a0af-007508e49eb3',
  CreatedField = 'ef6e80d9-8ede-4f99-9a20-ef1287029e7c',
  ModifiedField = '4b92940f-246d-4481-9690-5cdc4af26cae',
}

export enum EmailSequenceSettingsFieldsEnum {
  IdField = 'c7bac8da-dbbc-47ec-8cc2-f5bf5a32ac39',
  SettingsField = 'd45bda1a-f303-47df-b8e5-e3dddafd7c71',
  CreatedField = '3e2c1fd0-3136-4d7f-ae68-ab7c5fb6f068',
  ModifiedField = 'da01881d-ba0e-4986-b463-1a5d025c0b7b',
}

export enum EmailTemplateFieldsEnum {
  EmailTemplateIdField = '50c3f355-1ba0-4695-a019-8b3803c65793',
  NameField = '1b4ff0f1-c45e-4f12-a128-ff34b2db7ce4',
  AttachmentsField = '7c6ba0ed-6dba-4a2f-a2b7-8f62368940d1',
  BodyField = 'c9599500-7f2e-43da-aa9f-ed152402de9d',
  EmailTemplateFolderIdField = 'bc0f7d1f-2d71-4616-9d84-e15eb6e64848',
  HasAttachmentField = '6839e210-9b24-4be4-ba4a-fa1f528a12f7',
  OwnerIdField = 'e1b685f9-eba1-4d55-9778-144c5a023c96',
  ShareModeField = '9f08c62d-79a3-4d21-8acf-1c6ba57668b6',
  SharingField = '50be8c5f-a3c8-405f-90de-3901bddd0ac0',
  SharingClientsField = 'a48554aa-895f-41ec-8a85-bc98a8af05d7',
  SharingUnitsField = '417b37c3-7af0-4100-8647-30734e3276d0',
  SubjectField = '0f7b3ea1-0626-4ba1-810d-331e19770718',
  TypeField = 'b8cfbfe5-418a-4efb-a37c-330b59c2e4ef',
  CreatedField = 'ee1d137f-0932-4902-8c47-48c51152489b',
  ModifiedField = '89df5a8e-7ed7-4cbe-bc35-b8c7ae977b0a',
}

export enum EmailTemplateFolderFieldsEnum {
  IdField = '1d39b524-353f-4020-b707-7572ab11c6bf',
  NameField = 'fc920754-d7b3-407e-a879-b19c60497214',
  ParentEmailTemplateFolderIdField = '46595718-a911-48c6-8b3d-b40b3e7131cb',
  CreatedField = 'df8d9072-1929-4b49-ac37-69057f6b603d',
  ModifiedField = '48e19d0a-87b6-4b2a-ae49-6efd8a409860',
}

export enum EmailTemplateSharingClientRelationFieldsEnum {
  IdField = 'a7a5b967-5355-4d19-adfd-ed8174a9ffe5',
  ClientIdField = 'bf16841c-2c18-43a3-ab42-c1be92900dd9',
  EmailTemplateIdField = '3b9492cd-92eb-4d21-9099-5bd93320303e',
  CreatedField = 'd76f73d0-a27a-466c-9112-f1b3fb5a651e',
  ModifiedField = '8a28ee17-a111-4e5b-8480-f3d89b70cf0e',
}

export enum EmailTemplateSharingSalesUnitRelationFieldsEnum {
  IdField = '49a66d1d-f78e-469f-8488-700b3b889177',
  EmailTemplateIdField = '812af807-ba78-444e-852c-9ba9d0a7e590',
  UnitIdField = 'ebe661f0-c345-44ab-9eea-a2880eb1762a',
  CreatedField = 'f909c317-81d7-429a-811e-44ebfdce12ee',
  ModifiedField = 'b12e8a8d-455c-4af4-8fee-206ae76ed362',
}

export enum EntityFieldsEnum {
}

export enum EntityFitnessFieldsEnum {
  IdField = 'e3a95bfd-b199-4874-8de0-1995dd6ec251',
  IndicatorsField = 'fefc23eb-ea4e-4ee3-8ff5-51e30266a96d',
  LeadTypeIdField = 'a67d3a14-1e56-415a-9d84-e637d1276cb1',
  OpptyTypeIdField = '6732ca7c-8b54-4c90-bd36-76b97c8cab14',
  TypeRelationField = '467fff55-11a0-45c7-b3f2-0defb90842db',
  CreatedField = '6eeb52ec-5402-4a3d-a248-97f0af49ca89',
  ModifiedField = 'c1372e84-e46c-460b-ab48-3012a2120400',
}

export enum EntityFitnessIndicatorFieldsEnum {
  IdField = '676b6dc5-ea0c-4401-a7ee-b85358950e6f',
  DescriptionField = '906b1a57-6b78-444e-a052-be47c0ab0345',
  DescriptionUseLangField = '11258b79-5396-49ef-890f-15c28b6ad35b',
  EntityFitnessIdField = 'e850170e-eb0f-4d22-9574-022373d51104',
  HowToFixField = 'eaa0c6d0-f81a-40b2-b827-7da5518bb271',
  HowToFixUseLangField = 'ab7f11fc-2a1d-49ae-abd6-0d896497f6fa',
  IsEnabledField = 'f2af7fa9-5d2b-43a3-ad0e-1006fa7e846a',
  NameField = '604ed2e1-a1b0-4be5-b2be-fb6effa2b702',
  NameUseLangField = '1e380363-77d8-4c89-8acd-a3969b2f3490',
  SettingsField = 'e22661c8-5263-4c33-8f90-0bf9b8f1411e',
  TypeField = '7abca322-1bb1-4ce7-9567-4c1488a91205',
  CreatedField = 'a4ea31eb-8aac-4b9c-b6f6-3aa2ee263f82',
  ModifiedField = '52fd889a-3552-4926-b54c-951538a4abe5',
}

export enum EntityHealthFieldsEnum {
  IdField = 'c010e7c7-0b88-4bd1-b509-711912a2869f',
  EntityTypeField = 'a6bb3982-98c6-4778-b73e-b53340166b52',
  IsEnabledField = 'd6c1e53e-d9ab-4907-a71a-ec9f2ac3cdc4',
  LastRecalculationField = '8cdbfc19-9f0e-4f2a-a19e-890f8947b0a7',
  CreatedField = 'f5afcd79-51e5-43ac-b0cb-081aea070b89',
  ModifiedField = '15e44483-c675-477e-b8a0-c7bdf2eebe79',
}

export enum EntityHealthSettingsHistoryFieldsEnum {
  IdField = '7cd0fb7c-2e29-4fae-92b4-5a728914afac',
  EntityHealthIdField = '6d0a4c68-422c-42b2-a06f-4dad5d2db65f',
  SettingsField = '863cf279-51ab-4cf0-87f8-99ce2877ebbf',
  CreatedField = 'ccac3982-838a-42af-8376-751c543f8d96',
  ModifiedField = '3db191e4-8f98-46a7-92f2-d8ef82f05560',
}

export enum EntityScoringFieldsEnum {
  IdField = '9e3cac44-e2c8-49b0-9723-ef821298eab4',
  NameField = '03f5049d-632a-4cc3-9270-79a0f0ee6192',
  EntityTypeField = '36531171-5917-4f49-9397-63a27fde1dd7',
  IsEnabledField = '5462877a-6098-4df7-b6a8-3a1d29a158c2',
  LastRecalculationField = 'f73b2579-b3b9-4912-a7c5-f05abfafe873',
  SettingsField = 'e004dab2-4053-4267-b40f-8c97d9931269',
  SortOrderField = 'e564691e-8b79-4063-8d31-46c648dc29e0',
  UseLangField = '76f3b528-164e-496a-8280-9a1ec5f0ecfc',
  CreatedField = '153371fc-4251-4906-bb97-725819b68152',
  ModifiedField = '28dcf916-f258-49f8-b351-bc2b0fe4084b',
}

export enum EntityTypeFieldsEnum {
  IdField = 'ccee3c10-eb87-034a-ec86-47533f12f845',
  NameField = 'c7048d36-fa29-0fa4-0c2c-65627f3fede8',
  FormEditField = 'b3e42b9e-36fd-04b7-1a7e-3c358726dfcb',
  IsReadonlyField = '09b1c1bf-264e-083d-59ed-688c0225b9cc',
  CreatedField = 'a9464625-561c-0149-e56b-0aa859bb41d3',
  ModifiedField = '2e695fb8-4f36-0f51-aae6-08241c29d4d8',
}

export enum EventLogFieldsEnum {
  IdField = '90db9f07-9f2e-405d-af52-aa295c886585',
  AccountIdField = '30375467-cdfc-47ec-88a2-24c54502619a',
  ActivityIdField = '96052760-85e9-4e12-a95e-b3e5d60c5184',
  ActorClientIdField = '1238c430-442f-4c68-b662-052b513710b8',
  ApplicationIdField = '8102e15c-4d29-4d4b-aac1-e19fd67468be',
  ContactIdField = 'aaf4e0f5-b978-40e9-951e-52d60a337d24',
  CountField = 'b73df289-5f62-4635-993c-99d084888c5c',
  EmailSequenceIdField = '9bde0335-b34f-4668-ab28-7d2a165aa6a8',
  LeadOpptyIdField = 'b12e9659-b8b7-458f-8872-b201fe378d22',
  MessageIdField = 'bdc15ad1-4b1a-4017-883c-817be947d7f8',
  OpptyProductRelationIdField = 'ef325c60-0354-44dd-a6f3-e4d45355019d',
  PreviousOwnerIdField = '53252a2a-8860-410b-922c-64cd04803405',
  ProcessIdField = '9d8e24ae-a1d6-4468-9f86-ca7cbcb420a5',
  ProjectIdField = '9c9168a5-8fb7-49ae-ad67-2b1a31995a77',
  QuoteIdField = 'e3fa58c0-75b3-4516-bbc9-ea877a448129',
  RelationField = '2f219c67-5c7c-4965-8a74-57ca3d8dbfc2',
  TargetAccountIdField = 'ef6208f0-9616-4cdc-99ff-ba55dc093b92',
  TargetActivityIdField = '7c4a1318-04c8-4fad-8ffb-4b18c9a55291',
  TargetClientIdField = '40d3419a-31ca-403b-aaa4-8f757603f5e5',
  TargetCloudObjectIdField = '6d47cbe7-b7bd-4cf3-95f2-d8db81c30524',
  TargetContactIdField = '85bf0955-a5ba-4d88-8b5a-66dbf582d2d1',
  TargetEmailField = '4b71043f-6a70-46d4-8f66-40983f706e0c',
  TargetLeadOpptyIdField = '0a207ddd-5ff1-4612-a366-c1175dabd901',
  TargetMessageIdField = '08800f42-de86-4c03-8cd6-9805e69ab7b6',
  TargetNoteIdField = 'f275073c-21ca-496d-909a-632b1705fb18',
  TargetProjectIdField = '66337df7-bf57-4276-b3d6-4eb371e4deff',
  TargetQuoteIdField = 'c5cb1ab0-0db8-4950-898a-64b4da9d0a8e',
  TargetRelationField = '71ed62e4-68f1-426c-b94c-0196225a161f',
  TargetStepIdField = 'c8784aaa-c0e3-46ff-9fef-bafe590dbede',
  TargetTaskStatusField = '1b402bc2-4164-468d-8f3b-a375f2839b55',
  TargetUnitIdField = '35e80dcb-0766-4265-a794-ad7dd739042b',
  TextMessageIdField = 'f219e2d7-909e-432e-a8fa-69a409cd0fc9',
  TransactionIdField = '4da4911f-3c04-4a0b-891e-5c8364cd11fc',
  TypeField = '095d8983-e289-495d-af99-3741f2a34e4f',
  CreatedField = '95a0de89-1d73-4946-9e57-1b516aa16164',
  ModifiedField = '1dd2d7cc-8ef6-4afd-a025-e1ec5961d374',
}

export enum FieldFieldsEnum {
}

export enum FieldPermissionFieldsEnum {
  IdField = '7c8ab058-2003-0164-ba92-dc6a710d7e0c',
  FieldIdField = 'ac7c77c9-6764-020d-90c8-20f3ea6254ee',
  MasterRightIdField = '12241159-deeb-0c7c-b200-6bb93b716e21',
  PermissionLevelField = '426437be-d71e-09b5-8747-5a59e3f8f630',
  CreatedField = '5f0a8ec3-212e-03e1-9478-1fc2ef2be3f0',
  ModifiedField = '9f541d2e-0774-0ad7-9edc-76894a0a7b4d',
}

export enum FieldSequenceFieldsEnum {
  IdField = '1cdd6a8d-df36-0580-9b89-3ef49e84c055',
  PatternField = '436aa8cf-8c11-0ea9-986f-a01e9893ee5a',
  CreatedField = '1414a951-5de9-0913-bbf1-888fd46c08d1',
  ModifiedField = '8a06833e-03d0-0801-b5ab-52476af9cc59',
}

export enum ForecastFieldsEnum {
  IdField = 'dfd0ee3c-973b-4d07-bf62-a81a7e65bcbd',
  NameField = '4e66f1fd-232f-4ade-b80d-2d66f1532af6',
  DescriptionField = '49d1d0b4-4ecb-496d-9c70-c062e754438c',
  EndDateField = '45fb8f62-5369-4667-b498-0a2d8c080901',
  FieldIdField = 'ac59f558-b980-4b76-adc7-673912e1282b',
  OwnerIdField = '4dec5724-5c02-4965-a744-cbb68ae3a2a7',
  PeriodField = 'e70794a7-7680-4224-9372-6decc6a56cda',
  PipelineIdField = '4dcfa73f-1c74-442a-913d-32859db968f4',
  SettingsField = '4e601272-3922-48fc-a9ff-1ee560b00b04',
  ShareActorField = '869af4b1-c930-45b8-afac-31815c2ac3ae',
  ShareModeField = 'b56424ae-5ba0-471c-81c1-673a0aabf7d6',
  ShareModeParamsField = 'f724f601-fea2-493d-822b-0836a91ecc8f',
  ShareModePublicField = '39c10ede-f649-4c50-9273-46fec38c97e1',
  SharingField = '7a98069d-d692-4edd-8ee4-404a9c18130f',
  SharingClientsField = '47e1f5c6-3173-4822-b957-9d46dcee7d56',
  SharingUnitsField = '62cedfb2-04f6-490f-be87-7f1508c495b6',
  StartDateField = 'e09a5aec-659a-4df0-b33a-f556a2500962',
  TypeField = 'ec9fe24c-426d-4401-9600-b9912fcba4a1',
  WonValueField = '0f418e3a-a45b-4bb4-a5ea-0b0280c6bf24',
  WonValueVsQuotaField = '72db0f7a-76ad-4286-9c4b-b3b4847d7610',
  CreatedField = '457262dc-3f04-4fb2-9575-3a0ecc0c4a9f',
  ModifiedField = '46edc090-6495-42ef-8632-388c8b8d163d',
}

export enum ForecastSharingClientRelationFieldsEnum {
  IdField = '59b5c2b8-3189-4f41-ad0c-ec573d1bd246',
  ClientIdField = '6cf56ecd-a7b0-408c-9cb1-fb4d8a3d5763',
  ForecastIdField = 'e22a34ed-059d-4002-a8bb-bfe5c9553ab3',
  CreatedField = '08235a4a-51a9-4fa6-a28b-5c6a46dfef4b',
  ModifiedField = '9c17487a-6ba6-4395-b8bd-7341d373a6e2',
}

export enum ForecastSharingSalesUnitRelationFieldsEnum {
  IdField = '54de0e93-ffbd-4205-b5ce-7248bed52626',
  ForecastIdField = 'a62d59d6-5112-4a9b-bb56-d393697e35c9',
  UnitIdField = '8bb0868b-be0b-4f5e-95e1-038335ffbc26',
  CreatedField = 'aaf5f703-5d3f-4237-93c1-c1661abbde12',
  ModifiedField = '9714b3c6-4314-4268-82bd-e8e836b62aac',
}

export enum FormViewFieldsEnum {
  IdField = 'ba7f6581-283e-4c14-9672-781d51189951',
  NameField = 'b89c63c2-dba7-46f0-932e-d9fd3d23c04c',
  AccountTypeIdField = '5ac8883a-a36f-4da6-9282-608458642444',
  ContactTypeIdField = '3da6c808-5a40-4276-b0b0-234d5944a0ae',
  HiddenItemIdsField = '36d239ec-2ac1-44a9-96ca-e6ddc242b4a3',
  ItemColorsField = '7492720f-1d0b-4f83-a4ca-d5f98e5b87e7',
  LeadTypeIdField = '79ae211f-b370-49f1-8628-6e000ebf8e82',
  OpptyTypeIdField = '7489ba17-93aa-4b0c-8b66-bb6c5f2ca562',
  OwnerIdField = '5fa5dbca-7b2f-4102-938c-f3e34d2a08c5',
  RelationField = '623d3386-5e76-4ef5-b248-1a1f9706f33b',
  ShareModeField = '579b84d4-8b7c-4601-8149-d169b3d9efee',
  SharingField = '7a15c04d-e39a-41bc-ac70-e40c020c2094',
  SharingClientsField = 'c92682ab-dc5c-43cc-93f1-532654e401e3',
  SharingUnitsField = 'c0c12387-7a8a-4901-a3c9-5c672551f789',
  UseLangField = '63efa755-46a2-4a3a-8389-b635162ec60a',
  CreatedField = '4410e0db-b787-4e89-a262-793129a8ccaf',
  ModifiedField = 'c358c865-3d45-421a-bdd8-85e8d011c27c',
}

export enum FormViewSharingClientRelationFieldsEnum {
  IdField = '238ed908-77dd-4e57-9e45-cc94df234bad',
  ClientIdField = '415cf659-d86e-4cfc-bd80-a444432262fa',
  FormViewIdField = '7fac0141-e8af-40e9-8e1e-3fdc694e9d41',
  CreatedField = '1acc7254-6e24-4197-bd84-f8d6a9cc5489',
  ModifiedField = 'c52d048c-2496-47ae-b382-94eb7fd86244',
}

export enum FormViewSharingSalesUnitRelationFieldsEnum {
  IdField = '6559fa34-7ae5-4072-8d62-8447a7ec1a92',
  FormViewIdField = 'f9d3d9b0-0b68-46b4-b918-60eaae45b53b',
  UnitIdField = '554e70a3-4d11-402f-add6-b3bbb1c538ff',
  CreatedField = '45d807ae-e46c-4013-9b17-a07375a7c511',
  ModifiedField = 'dba13825-806e-468c-bbea-fc7caaa0fb7e',
}

export enum GhostContactFieldsEnum {
  IdField = '272dcb49-a704-44c1-9638-2465f6aa1d45',
  NameField = '39067788-b821-4207-a274-a64cdfcc3d61',
  PositionField = '486e37c0-3acc-41f2-a924-1a4e55cd2ad5',
  CreatedField = 'e27de15f-bcec-4c03-81f3-9d1e572ecbd4',
  ModifiedField = '71c0a061-edd5-435d-adae-595e82d3fd3a',
}

export enum GhostContactAccountAccountRoleRelationFieldsEnum {
  IdField = '9df8e1fb-5146-47aa-9921-22dd9c3ced97',
  AccountRoleIdField = '5409f6ca-a3c5-4330-8ad3-560c8a5c8b64',
  GhostContactAccountRelationIdField = '9fb892da-8bff-4c34-ac54-1d6d439c294a',
  CreatedField = '8713f384-a131-4168-9666-4dfd0199a791',
  ModifiedField = 'f95e6dc4-bf9b-4503-8a00-41a4de91a0a3',
}

export enum GhostContactAccountRelationFieldsEnum {
  IdField = 'd2b5a99a-35e7-4ae2-996e-18939dd7e668',
  AccountIdField = '7bed33b5-9fd3-456a-afcd-c5d09e2c7643',
  CommentField = 'ee442ec8-b099-405c-b913-15036da1f4b7',
  GhostContactIdField = '6088d92f-212f-4ba2-9e02-108adc9ac7cd',
  IsAssistantField = 'ff90aa7b-ddbc-4ffd-8f36-ae94ced460e6',
  IsSiblingField = 'f3fa4f51-bf51-4c2c-bd8b-4b48c0a6953f',
  PositionField = 'a30c2e3c-6e39-42f5-b034-1e38da774f87',
  CreatedField = '18d45442-ac22-47a4-91e4-158bfabe53dc',
  ModifiedField = 'd2ef78c9-3933-4e65-9b55-ffc2e2670aa3',
}

export enum GhostLeadOpptyContactRelationFieldsEnum {
  IdField = 'dead5e94-3a74-4358-93d5-5d76702a55b3',
  CommentField = 'b419d058-5298-4ebd-9ed1-dfff2024f5a1',
  GhostContactIdField = 'd9270c3d-25c2-46e0-b23d-0aa97d0b6d8f',
  LeadOpptyContactGroupRelationIdField = 'f5c6c7d8-a881-4116-a563-5e023a060b49',
  LeadOpptyIdField = '61fdd834-53a3-4ab1-b39b-489efa6889bf',
  PositionInGroupField = '05c9d7aa-bb74-4735-9f56-6c1ad890a4d4',
  CreatedField = '48142197-3f30-450e-8c92-90ad7da92663',
  ModifiedField = 'd91c01a4-c623-493e-b3f8-b5cbcd7f15da',
}

export enum GhostLeadOpptyContactSalesRoleRelationFieldsEnum {
  IdField = '08bb0dd5-3da9-4cb9-8310-33ea6ab96613',
  GhostLeadOpptyContactRelationIdField = '620c5635-62ab-4735-9cc5-dae140a0fd4a',
  SalesRoleIdField = 'b6074b07-f9ee-4afc-a380-8016fe8c2609',
  CreatedField = 'b469b32a-3965-4b18-b9c7-31900dafbce9',
  ModifiedField = 'bc0ed2f2-b5bf-4027-b72e-71361fe347d4',
}

export enum ImportMappingMaskFieldsEnum {
  IdField = '8cdf0c59-989d-4b02-b7db-30744310c974',
  EntityTypeField = '2be2f743-e578-418f-b4bc-617635eb52e0',
  MappingField = 'aca25da3-c505-408c-ac39-863dd0aa709d',
  NameField = 'd225a5d0-69cb-464f-8b60-370d942d60ac',
  OwnerIdField = 'aa62ef77-d74a-411b-bb04-9d958d1847bc',
  ShareModeField = 'a0f87b3d-1a3d-4c56-883e-f938ddfeafc4',
  SharingField = '9936a477-005b-4d33-bd84-77248f14755a',
  SharingClientsField = '45048f5e-9b21-4256-b4ad-34a4d1b8052a',
  SharingUnitsField = '01867dc4-3587-4f72-8a13-c412bdf0333e',
  CreatedField = 'd6efd867-94b8-4c10-a46f-615dbe32f870',
  ModifiedField = 'ce379998-4b4d-4e4a-9978-f4ac771db7d8',
}

export enum ImportMappingMaskSharingClientRelationFieldsEnum {
  IdField = 'c86b7594-a901-481d-93a2-a036175bee01',
  ClientIdField = '742e66e5-0d5b-4a63-aea6-4966d04c5cb4',
  ImportMappingMaskIdField = 'ea4a985f-fbbb-4dd2-ade9-9d5c2df0c811',
  CreatedField = '4a608390-6a90-424e-8524-dce9a2a76932',
  ModifiedField = 'febeb77e-b2c7-4ed8-891b-008271adfb9e',
}

export enum ImportMappingMaskSharingSalesUnitRelationFieldsEnum {
  IdField = '27a615c7-223f-4b4f-a6be-2cb4356eefe8',
  ImportMappingMaskIdField = '6b3eab73-001e-4d27-8d9c-809684810ee7',
  UnitIdField = '5a2bd7d2-e67f-48bd-ae44-91b7136ad250',
  CreatedField = '47111c2c-d875-4957-a0f0-9259d0c863a4',
  ModifiedField = '07d81890-e42b-425a-8c0c-ee5df096071d',
}

export enum IntegrationDataFieldsEnum {
  IdField = '24c1f635-0996-47a1-a0a8-8fae167cf1ad',
  IntegrationField = 'f19a21fa-5126-4e4b-8a78-6bb30f41532e',
  IsSensitiveField = '659a4e6a-6ada-4c2e-838a-9468ba191008',
  KeyField = '8efbc1b0-19f9-40a1-af71-f909158d3be9',
  OwnerIdField = '64b1af0d-ca96-447b-b69d-947d5e614f1f',
  ValueField = '9ad5d0a3-eb73-43d7-8d08-9b2b779c9837',
  CreatedField = '7a55a61d-bc70-4a41-9b1c-d39e11601718',
  ModifiedField = 'f12fb633-a45f-4d15-8035-c033954cc5f5',
}

export enum InterfacePreviewFieldsEnum {
  IdField = '3620eff6-83cb-4ece-b941-99123e5db633',
  AccountTypeIdField = 'd5cbe1b5-a050-41c5-8efe-d7c8c5a9a94c',
  AppointmentTypeIdField = '4495a359-07bb-4e96-886b-85ac3683cd9d',
  ChartsField = '238b7c22-a0f6-47da-be11-c6b0b9dbc124',
  ContactTypeIdField = 'eb8b1693-8db8-4938-a549-50ec276cd072',
  DetailTabsField = 'd2df5824-6b10-4ec9-abb6-78e8863b03a3',
  DetailWidgetsField = '479ebdb2-d390-4b42-af71-011b3bdef202',
  FieldIdsField = 'ac616f1b-0a88-4f0c-ab67-a682b0bc8fa9',
  LeadTypeIdField = 'c38e2e5f-2a0e-4ee4-b953-9517854b3903',
  OpptyTypeIdField = '4bfb6fb6-39fd-4841-be3a-95b15b393617',
  ProjectTypeIdField = 'c8d7fef8-4260-4a07-bb54-1e95b4a00452',
  QuoteTypeIdField = '030146ed-7a66-42be-b6fd-6d7c0190576e',
  RelationField = '147b2dde-1603-4453-b706-f69b59e6e529',
  TaskTypeIdField = 'd0a6f53d-ca1e-45b5-aa4e-eaa1d183ef8c',
  CreatedField = 'b18e2615-6494-4af0-aba4-c7c434ce93c5',
  ModifiedField = 'af53feb6-6741-4501-a121-f61d74292592',
}

export enum LeadFieldsEnum {
  LeadIdField = '60ad3809-cea8-0c49-d2eb-0cff2e450322',
  NameField = '6ce4ee9c-979f-0b06-e378-e6d87f4812fe',
  AccountField = '216c397e-4727-0cda-b901-53a97fc6d7e7',
  AccountCardField = '47b5eb70-ab0d-0627-a0be-799aa1a9594a',
  AgeMetadataField = 'e055df12-e238-4f83-b86f-4035bfa810a7',
  AutomatizerCreatedByProcessField = 'cf2169bb-289d-4c8e-9676-53412b52083c',
  AutomatizerProcessTriggeredDateField = '36e3b2d6-6d6f-4463-b82b-15f4942965b4',
  AutomatizerProcessWithScheduledActionField = 'c63cce5f-cb18-416e-b436-cab1265cf0bc',
  AutomatizerProcessWithScheduledActionDateField = '56eaa06d-491f-49e0-8e16-7717cf21c753',
  AutomatizerTriggeredByActorField = 'f59679b8-b7f2-4490-8043-57e63d298c56',
  AutomatizerTriggeredByProcessField = 'e5a4b3dd-6509-4ec8-a7a1-220dd63038a1',
  AutomatizerUpdatedByProcessField = '8b6949ce-c49d-4f63-bd5d-9ee615b56e1e',
  ContactField = '5633313c-f394-0610-7acd-2eeb9af50697',
  ContactCardField = 'a420b140-2592-09c9-7f03-a5248d3def6b',
  CreatedByField = '50891edb-68f0-0aef-0711-f785da0bf7d9',
  DaysInQueueField = '64114130-d2e1-0297-23af-86df8485f66f',
  DescriptionField = 'f6acf340-de38-04a6-9fc0-66d499ebd74d',
  DocumentsField = '402d0d5e-3c25-06b5-2173-060c47f0edb6',
  EditorsField = '5704c57f-a931-02d1-dc57-b848605d3db2',
  EmailSequenceCurrentlyEnrolledInSequenceField = '558d7ac4-0e58-48f6-94e5-d065cc63da69',
  EmailSequenceEnrolledByActorField = '0804be0e-c991-4ca4-8084-d332348b7908',
  EmailSequenceEnrolledInSequenceField = 'd17090a3-6cd0-4d6f-bdef-798067ffb58f',
  EmailSequenceLastEnrollmentDateField = 'd160805e-4a0a-48a7-8b58-b8549094e617',
  FitnessField = 'ebf80a56-8d4d-07ee-bc5e-585fd3ab6cf5',
  FitnessArchivedIndicatorsField = 'a60eae85-1aff-4bdb-9533-a848c53ec2c4',
  FitnessIndicatorsField = 'c0879b89-b1a3-4456-ac4d-67298256ccc8',
  HasScheduledActivityField = 'de1c20bf-2cbc-0ca2-d6c3-414c8ed2ed52',
  IsArchivedField = 'c594eef0-5a2d-0aa4-2147-69d3a04f4ec5',
  IsFavoriteField = '9603658a-9bc7-0f8a-3089-c2754da58a9a',
  KpiField = '53f7d428-bcb3-0041-e672-74c65a30f098',
  LabelFlagField = 'c6eebc33-f648-07a5-0e27-826a01084132',
  LeadProcessField = '8857c7a5-1053-4634-883f-2208d13f9c9a',
  LeadStatusField = '4c1eabdc-445e-0706-e214-d32489d88706',
  LeadTypeIdField = '4a905e57-4195-02c4-59ad-dcef192d7f47',
  LostDateField = '83e9bcfc-06b6-4a7b-9df3-65afb3a150e6',
  ModifiedByUserField = 'be070625-f5d5-4922-9e45-ca27e393f464',
  NotesField = '52023bf1-8662-0775-72e1-7591c427893f',
  OpptyLeadCreatedField = '67674aa2-fb98-4a39-b280-afe50f5c5f93',
  OrgChartSettingsField = 'bb855e66-7a5d-4e8f-a9ca-c4b669e11bd2',
  OwnerIdField = 'aad26f77-0018-0a7f-aa5b-0227bb4bb9e7',
  ParentUnitIdField = '77dede9b-9e41-071f-60ff-56c01b3e9fa4',
  QualifiedAtField = 'b9ec89a9-9748-028e-8c26-5834dead2f2a',
  QualifiedByField = '99a422ca-3aa1-0ae5-0323-dff864710b1c',
  QuickAccountEmailField = 'bcc83dde-ecfd-029f-321b-35a5c2b219e8',
  QuickAccountNameField = '86debbf5-033d-06b5-fecc-7ce2a36218e0',
  QuickAccountPhoneField = 'f73026b9-dfd4-0ee4-6a09-d2e8d57e1bf2',
  QuickContactNameField = '2c5df70e-9b21-0718-cfaa-0b98c0bd721f',
  QuickEmailField = 'ded50055-caff-0b31-8624-84cf893152e0',
  QuickPhoneField = '1ee750b4-c1f7-003d-ed42-5df163edba7d',
  RankingField = 'bb8a1859-7915-0203-8e5f-82fc1017675d',
  ReadyToMoveField = 'f7eacb66-b806-4df3-9031-95a7e01ac51b',
  ReasonOfCloseDescriptionField = '775eeafd-0591-4f47-8834-03856c8d0bd9',
  ReasonOfCloseIdField = 'db32a4af-ff51-40f5-95c1-bd771a3a9849',
  RecordUrlField = 'de67f2f2-7aca-44f7-b20b-e61cefcb4af3',
  RelatedAccountEmailField = '14daa080-1067-4c90-ba38-e9daf164213a',
  RelatedAccountPhoneField = '5b0c72e8-752c-4381-971b-0c523a1f877c',
  RelatedAccountsField = '9d7c17c2-d362-4646-9158-01a5d51c2ea1',
  RelatedActivityDeferredField = '854e4ee5-c148-0653-e0b2-8ff1fdda5f46',
  RelatedActivityFinishedField = 'bf3aef91-0a21-0e23-734c-b1e370132686',
  RelatedActivityInProgressField = 'bc1d6206-0390-0d70-9f87-1b6798b90ea9',
  RelatedActivityNotStartedField = 'b1f3f2ed-cd50-0ca4-aa22-e451aae8cd80',
  RelatedActivityOverdueField = '7475fdc1-a2b6-05d6-1557-c7a75a1dc481',
  RelatedActivityWaitingField = '521aaa53-15f8-0ee9-6f98-8a7872935f45',
  RelatedContactEmailField = 'e0edf600-5263-45a7-bba3-cd1f003477b9',
  RelatedContactPhoneField = 'de13ac7a-b567-478f-bae9-c5fd650be619',
  RelatedContactsField = '4598dcdd-e43b-4f15-bd94-6c38b173dc1b',
  SalesCycleLengthField = '9474ee75-8016-447e-9be3-489842921966',
  SalesStepActivitiesField = 'bde9b14d-c903-457f-b802-f91e71cf24d7',
  ScoringCategoryField = '57347b7d-f37a-4c78-a457-b7bda0d92006',
  ScoringPercentileField = '24d099ac-5b79-4bce-951a-c6e20c3040eb',
  ScoringStateField = '2a86fc5a-93b4-440f-80f1-ab8eee935c52',
  ScoringStatusField = '205727fa-1c92-4c62-8bba-c41edcac6f04',
  ShareActorField = '9908ea6c-1d7b-0fce-bc34-4258375c72e5',
  ShareModeField = '50a52582-2e24-0f5b-9a36-9d8bef5f9ba9',
  SharingField = '3fc4c8a1-d1ca-0e03-ce54-5359cb34197f',
  SharingClientsField = '8fd55f47-c997-05c4-36ad-71ca7f5f0077',
  SharingUnitsField = '5ab60dc0-e4fa-0025-e5aa-6878dca90c7f',
  StepChecklistActivityRelationsField = 'd0fe8b11-6ac9-0380-ac77-857f05514cdf',
  StepIdField = '77503494-2d3e-43eb-bd4c-6201d96fadb6',
  TagsField = 'd74306ba-a731-4684-8258-20b34ded2ac1',
  UnitIdField = 'cbe742e8-2aa2-0bee-fc84-ee9b1ed82667',
  VelocityField = '2b929f29-32a3-49db-90b3-c99dba571579',
  WatchersField = 'd50b03d0-72ca-04ce-691e-7544f41dd1f0',
  CreatedField = 'fa8bf2a2-c768-0e39-eca3-1221a496708b',
  ModifiedField = 'a3b129f4-a708-02e7-be82-d2ed27f79522',
}

export enum LeadOpptyFieldsEnum {
  OpptyIdField = 'd22a6e0f-7a5a-09d9-16c5-deb1fde83544',
  NameField = '992f3eee-2bb5-0118-a9f4-2f20a9e9c908',
  AccountField = 'f5e02f03-1b6b-0519-9e5e-a7287f5dd776',
  AccountCardField = '02626789-b040-011d-2257-c76ca967c633',
  AgeMetadataField = 'ad301430-ae1d-4286-b51e-1865352d8522',
  AutomatizerCreatedByProcessField = 'c4f1479f-8861-4226-9001-c1d5fc898281',
  AutomatizerProcessTriggeredDateField = 'c93437b6-e5a9-42cd-ae1a-e08d8fc1ad47',
  AutomatizerProcessWithScheduledActionField = 'a03a908a-00dd-4b11-abcd-ad463cac3550',
  AutomatizerProcessWithScheduledActionDateField = 'e1ba0812-683c-41bc-b2af-4ead289814d8',
  AutomatizerTriggeredByActorField = '31926528-2696-4e48-ac8f-006cabe78dbc',
  AutomatizerTriggeredByProcessField = 'd82dcaaa-9235-46f4-a5ed-601cb74f8444',
  AutomatizerUpdatedByProcessField = '85f1d35d-b297-478d-aae5-217b734b847c',
  ContactField = '47d1b3d2-b9c9-0b09-81a0-eecacfe12632',
  ContactCardField = 'c4cdb6da-cea3-0b81-4d23-b53d13e9adc6',
  DescriptionField = 'b445f1dc-3bd4-02ad-dc36-18b962d27dd3',
  DocumentsField = 'a734337b-e2c8-0a4e-3817-5552f3a57314',
  EditorsField = '550d5fcf-3b67-0167-09ec-aadf648616a6',
  EmailSequenceCurrentlyEnrolledInSequenceField = '49522018-0130-4ea7-a084-0bfa0af58a53',
  EmailSequenceEnrolledByActorField = '9a579b52-e816-4389-bb34-651fa97eeda9',
  EmailSequenceEnrolledInSequenceField = '3619da05-0dc8-47eb-9bd4-a2641b080091',
  EmailSequenceLastEnrollmentDateField = '4fec0e47-6dc4-4b71-8b52-b2b4d0c7cf3b',
  FitnessField = 'b22fc6c7-b862-0736-8619-ec1e2d928221',
  HasScheduledActivityField = '15d02ee2-d1af-0f94-0caa-11a1e98aab69',
  IsArchivedField = 'b4b20d77-f16c-0554-2b4d-0bc2b361aa4e',
  IsFavoriteField = '3cb37831-f5ec-0346-7320-ce274046c16a',
  KpiField = 'd48e0531-e2e1-02f2-8c28-404feb0ead97',
  LabelFlagField = 'd55e3ec5-618b-0415-e795-4bf1b9fe99be',
  LostDateField = '5cf85b21-6716-4f21-9a44-778d5dedfda3',
  ModifiedByUserField = 'd264ef3d-92d6-4700-9819-952bc637b143',
  NotesField = '50aabb32-d27c-0d92-0694-dd4c30e228cc',
  OpptyLeadCreatedField = 'f1b9f544-5783-0335-1a40-76f43cf50f7a',
  OrgChartSettingsField = '0024a8e2-0cdc-4049-80bf-f4439a79c636',
  OwnerIdField = '80037c9c-d34a-0a26-88c7-b2c146634db1',
  ParentUnitIdField = 'cb1ba183-e5c8-0a98-1bf0-b27bbbbc3649',
  QuickAccountEmailField = 'a49d5e27-0759-0435-1369-7976e06b79bc',
  QuickAccountNameField = 'b0950d34-d1eb-0672-68ca-9aa34fcc5bbd',
  QuickAccountPhoneField = 'a1d8fc0c-dee9-0267-7fe6-48dd0903df49',
  QuickContactNameField = '0df3d503-a488-0211-3dec-b0cf54f7f283',
  QuickEmailField = '9238186f-5116-0ce8-7944-11900515487e',
  QuickPhoneField = '99a905f6-325d-0b9e-aa6c-3401876feb12',
  RankingField = '9b459c89-ce08-0db6-4037-972e10c56364',
  ReadyToMoveField = '6503f9f4-7495-4bc2-ab7e-b83051c92d6c',
  ReasonOfCloseDescriptionField = '2c4dd5ae-239f-033b-f6c7-0f5db4b6570d',
  RecordUrlField = '465129b9-900d-4c27-9b25-384339f6d704',
  RelatedAccountEmailField = 'e926f3ae-518d-4f45-a7a1-e941c6e62870',
  RelatedAccountPhoneField = '6b58f5b7-6a5f-4bb6-a3df-210f1dc4e221',
  RelatedAccountsField = 'cd01bc86-ec85-40b1-a127-48088eafe101',
  RelatedActivityDeferredField = '304b365d-b1ba-0412-b2d1-93f46c1ef69c',
  RelatedActivityFinishedField = '0e0a435a-8fc0-0fba-6fa0-0717426d9196',
  RelatedActivityInProgressField = '1b9b7511-47e4-091b-2deb-19d2b52ed96a',
  RelatedActivityNotStartedField = '75d0e7be-fb16-06a9-ddb5-729b34559479',
  RelatedActivityOverdueField = '88356ba4-8e39-0f78-08e9-0668adb8c578',
  RelatedActivityWaitingField = 'b6e546e3-3a90-095a-86fe-d83411331e45',
  RelatedContactEmailField = 'd93d4828-1076-425d-a3b9-be092e4a099c',
  RelatedContactPhoneField = '5a967bd8-53e7-4b57-acde-0a70f28f281f',
  RelatedContactsField = '9199228a-e824-4e55-bc49-2931412d55ae',
  SalesCycleLengthField = '6f7ea9fe-52fc-4d3b-a34c-a2103df056cf',
  ShareActorField = '2b2d0ca3-714f-0beb-b035-cd5c21e314cc',
  ShareModeField = '03ed69a7-6f07-0b1c-73be-b123ef6d64a6',
  SharingField = '393e44b6-a210-01ce-ca7a-bd6ced12820e',
  SharingClientsField = 'a6658f3d-4882-0b56-f0a9-d15e051b4329',
  SharingUnitsField = '6af5f964-8209-01fd-f12a-b7627378e846',
  StepChecklistActivityRelationsField = 'b725ee10-b102-0e0b-8977-209d4ed433ba',
  StepIdField = '48809eda-fc9b-0906-df64-54898423b8d5',
  UnitIdField = '6cd0d888-60cd-0917-ad07-7a2f23dfbea0',
  VelocityField = '16b8bc28-558a-4975-ae0a-721f7763f103',
  WatchersField = '8df91d65-9b56-0df7-dd23-1b6afad269d9',
  CreatedField = '7fe1cd99-9698-091f-7a8f-8930ccb559d4',
  ModifiedField = '429fca59-29a1-0735-cfb9-88bb47d9a580',
}

export enum LeadOpptyAccountRelationFieldsEnum {
  IdField = 'b8693ddb-4ecc-0fa8-0670-39464d299513',
  AccountIdField = '38117f1f-4955-0abd-2adb-626a2744f1ba',
  IsPrimaryField = 'ae490b71-42ac-0ce7-5a0a-eaf69ffd7007',
  LeadOpptyIdField = '4f913ce5-de0a-0120-5a8d-57194d9ad65f',
  CreatedField = 'b2434764-2f37-02c1-d3bf-c0673d58cc41',
  ModifiedField = '2623de59-9f7a-0e65-6936-eaeb56b215e3',
}

export enum LeadOpptyContactGroupRelationFieldsEnum {
  IdField = '1f94d0c8-9075-4850-bb7e-259cc76a6aea',
  NameField = '21857ace-dfc3-4017-be5f-329ee903c7aa',
  ColorField = 'd8132689-4e4f-4d55-9064-c65bcd3da93d',
  GroupPositionField = '3172037e-b343-4e74-bf07-5fe0f2a42c2b',
  LeadOpptyIdField = '3deb4a49-b7cc-43f3-8400-9e9e06cf1626',
  CreatedField = '847662d4-c0c2-4df2-a378-23e3c3ee5279',
  ModifiedField = '88906fb2-813e-434d-ab21-1b5f9a95054f',
}

export enum LeadOpptyContactRelationFieldsEnum {
  IdField = '1e152127-8d47-00b4-d380-a6f3ae5a2622',
  CommentField = '1363d15e-3803-081e-a223-64915e2dd61b',
  ContactIdField = '5057faf5-d01a-0cd0-2255-48b1fbb6c05b',
  IsPrimaryField = 'f4c1a738-f217-01c5-c4ce-eb98abf84bc7',
  LeadOpptyContactGroupRelationIdField = 'eb5f700b-ec6c-4e9e-bf9c-a3497f9efbfd',
  LeadOpptyIdField = 'a3f2938e-9072-0ece-a4c7-98393c7e6a02',
  PositionInGroupField = '4c961fa5-07eb-4c01-bd32-20f743b1a4d4',
  RelationshipField = 'c7480043-cadc-4d23-9fda-1a7b41538386',
  SalesRoleGroupField = 'be9b1801-292b-0ec0-624c-53d2e6681356',
  SalesRolesField = 'c0145ff9-629f-0b28-d074-7c6dab36f92d',
  CreatedField = 'd9be7709-376c-0b84-bab0-2d8e8c29b46f',
  ModifiedField = 'b859eddb-7599-0467-e5c9-9d170304bb77',
}

export enum LeadOpptyContactSalesRoleRelationFieldsEnum {
  IdField = '21911c70-15b6-03e9-47a5-aa1d2e9b27ef',
  LeadOpptyContactRelationIdField = '86b10233-f30d-0e92-89e4-0112620a850f',
  SalesRoleIdField = '8d899879-ecf9-0044-7d62-f2136e197c4a',
  CreatedField = '955c7fb4-79fd-0555-ee96-4171df050b35',
  ModifiedField = '70ede05e-ab83-0841-4a82-680a0b777fd0',
}

export enum LeadOpptyDataExRelationFieldsEnum {
  IdField = '3594a722-6c84-440c-8805-6ce40ff2356a',
  DataIdField = 'fc7876f9-a684-4efb-bb4d-575693495a1d',
  DataSetIdField = '10d7aa53-1f7c-44b5-8879-248e8f174c52',
  LeadOpptyIdField = '3cd3cb01-b4b3-4320-8fe6-fe9dfb09390f',
  CreatedField = '4a74bf18-34dc-4f7f-854b-8e554f1bb8b4',
  ModifiedField = '268e3a0e-5d34-48e4-bbcd-6dc1fbc2b5ac',
}

export enum LeadOpptyKPIFieldsEnum {
  IdField = 'b32b13a3-cac5-0964-fac6-4a650600f114',
  ApplicationIdField = '6c720096-908d-403f-ba48-4b38569b4152',
  ClientIdField = 'd974b967-673b-04bd-5a7b-6c3733152f9f',
  KpiField = '1c243d50-ec33-0e8e-cf6d-20711a21d329',
  LeadOpptyIdField = '361da417-c42d-097f-0e83-47b45c49a497',
  OwnerIdField = '0a990a9a-c9a3-47ec-8fb0-9630c95d6210',
  UnitIdField = 'f53ccd37-f9ef-0b49-f575-44c02563ead8',
  CreatedField = 'a6b28c16-2d5d-09b3-c290-bc4e4a89dece',
  ModifiedField = 'b8ea3c15-0e10-06b1-01db-d71d1c06a1e3',
}

export enum LeadOpptySharingClientRelationFieldsEnum {
  IdField = '09eb83c6-6ed3-06d5-4ca9-c22a2484be40',
  ClientIdField = 'f30053f0-8233-0f37-7246-85fef3c72725',
  LeadOpptyIdField = '6c75debf-f7ab-07f7-8de1-3afb6ac6fc70',
  RoleField = 'fe84d5f6-433e-0208-525c-0273228af767',
  CreatedField = 'e0128223-9469-058c-6983-f68ce193bf63',
  ModifiedField = '8e187d7f-52ab-03bc-e5a0-f4893ff50081',
}

export enum LeadOpptySharingSalesUnitRelationFieldsEnum {
  IdField = '0daf26ff-6e2e-07e6-fb98-b89f85d98c2b',
  LeadOpptyIdField = 'd962cc83-8bbd-0422-6c8f-dd5038aed1e0',
  RoleField = 'b515a3fb-4733-0c9f-ea0b-edf3dac12f5f',
  UnitIdField = 'd6753987-8500-0355-9ade-6adb5200d8de',
  CreatedField = '098cd69e-afa2-0638-2616-032c150f5cf4',
  ModifiedField = '81da62c8-8bd7-0034-ffbf-8cb409be17f3',
}

export enum LeadOpptyStepCheckListRelationFieldsEnum {
  IdField = 'ee4e3691-5332-0262-aa95-3d89e12c23e7',
  ActivityIdField = 'b4a47487-f9c1-00ed-fa70-3c4e35831f0d',
  CloudObjectIdField = '13920fa7-7b27-46a8-a413-d41b0583faa6',
  EmailIdField = '7855f94e-1104-449c-ab76-3163af928416',
  ItemCheckedField = 'f8517f89-4b53-0612-8246-aa4caa5d95aa',
  LeadOpptyIdField = 'a1ece265-79f2-0b67-395e-81caffc25e09',
  NoteIdField = '2c13e56a-92f0-4577-b978-5fd38cb8be02',
  QuoteIdField = 'd6d9d6fa-b1da-4321-a0d8-95affbfdd1e0',
  RelationField = '9df86b24-f2d4-440b-8bcd-081f729288a3',
  StepChecklistIdField = '940426af-7aa7-0d92-6d26-9bf3372abd67',
  CreatedField = '97c2552f-fd44-0738-9e1c-de0f33e6f459',
  ModifiedField = '1134e7d2-5f2f-00cd-02ad-187720aee29f',
}

export enum LeadOpptyTypeFieldsEnum {
  IdField = '836cc84d-7a42-07bf-1a59-35ef4a6b333f',
  NameField = 'eec2dc72-1cad-0a29-7b29-5a9fab1bca32',
  FormEditField = '7f769aba-88b5-0b56-3e1e-662b8116e8b9',
  IsReadonlyField = 'd4a7cc56-b018-0369-71f1-a6e480e035f0',
  CreatedField = '6f364bd3-6900-0739-29dc-9d4e82d3e1b6',
  ModifiedField = 'b64bf180-4bc8-0a27-e7a4-2ac017b1be1c',
}

export enum LeadProcessFieldsEnum {
  IdField = 'b47b905e-c771-4546-b932-942d0391969b',
  NameField = 'c6a035c2-e41a-42a1-8a1f-971ad0f5901c',
  ColorField = '3449d04e-9c9c-43d9-89e7-38e92beaa5c5',
  CreatedField = 'fe2e980e-2b7f-4745-8de7-9e545ab94a50',
  ModifiedField = '7d69e829-c380-4e5a-96bc-8f3dcfddf4d7',
}

export enum LeadTypeFieldsEnum {
  IdField = 'fd97653c-1281-080e-94b4-cd8bd37dcee2',
  NameField = 'a8b613e7-4717-0865-594f-2ecdf439f9aa',
  FormEditField = '49ee4ccf-232b-01b0-d54b-8d6cbfe646c3',
  FormLostField = '9ec284bb-a41c-451e-8fda-493428bae4ea',
  FormLostEnabledField = '433a9b9f-eda5-4546-b7c3-b2c36f5a3454',
  IsReadonlyField = '8dbc021f-1c3f-070f-5a43-d956e16ba315',
  LeadProcessIdField = '16d783ed-64ba-4716-b9bb-19b7e615559c',
  CreatedField = '04e555da-140d-0e53-641d-0c397e0d086e',
  ModifiedField = '64748ef1-c8e0-0588-4584-c53f77b36e25',
}

export enum MasterRightFieldsEnum {
  IdField = '664b5e90-0ecd-0c98-8262-911eefbb3eea',
  NameField = '81f492c0-0c2a-0b50-6403-24903e49c926',
  AccessAccountRelationTypesField = '93ad2f85-788c-0ce0-9bd7-cb39af933f02',
  AccessAccountsField = '0614b8d0-207f-0b29-542d-b2fbc867856c',
  AccessAppointmentSchedulerField = 'ed15fe3b-ba26-45d7-b764-94c3bac360fa',
  AccessAppointmentSchedulerManagementField = '5adfddf9-e589-4fa7-b65e-cb04c44979f0',
  AccessArchiveField = '40e1450a-d4e2-0f51-4bb0-7bbf9366fe48',
  AccessAuditField = 'cf729c9a-2ed9-0884-a7f5-ad0f3a8e1a20',
  AccessAuditLogField = 'abeeea3a-54ef-4cc3-bf86-d45d0f4c82d5',
  AccessBulkUpdateField = 'ddcedc05-f049-43bb-976a-9a8c1cf75e00',
  AccessCallingField = '20904465-c297-436d-ace0-91e47b5750b9',
  AccessCallingCompanyPhoneField = 'e7925b46-1498-4c34-961f-8f2ed4327c8a',
  AccessCallingPurchasePhoneField = '31778d55-c09b-404e-ae75-11ee81418444',
  AccessCompanyEmailsField = '4409de2e-50ce-4366-876a-24fa2de9af1f',
  AccessContactsField = 'cd047e90-2e64-0291-f6b2-efaf0d2fdeae',
  AccessDocumentManagementField = '7cb8fdc0-cc34-40e8-b8ff-cd8679a93041',
  AccessDocumentManagementCompanyField = '909d0edf-6dc0-4207-b085-30868910681d',
  AccessDocumentManagementRecordField = '1cf6e04d-4246-4fd9-81c0-c06dfe105513',
  AccessEntityAccountField = 'e9b445a9-0388-0ad7-b122-9ca63b2809ef',
  AccessEntityAccountDeleteField = '4a23ce23-ca19-0567-8342-bdb60a56b396',
  AccessEntityAccountDirectionField = '8d8238da-df52-0564-34db-1b4f8d05f966',
  AccessEntityAccountPrivateField = '6cc71c29-c1bd-0710-8458-7e07d6fedbd2',
  AccessEntityAccountTagCreateField = '384bfd1c-4008-4a60-88dd-138994f7b91e',
  AccessEntityAccountTypesField = '25ac386e-b56a-4dfd-9749-e3d16e140cd8',
  AccessEntityAppointmentField = 'f5bbd895-2d5a-0400-b2e0-e807531cf4d1',
  AccessEntityAppointmentDeleteField = '308ae135-7f78-47e6-ba78-9224ccf3cae3',
  AccessEntityAppointmentDirectionField = '089d7cb9-d2ec-095d-a989-74fd6659e6a5',
  AccessEntityContactField = 'c6d51c60-78db-0deb-d7ae-0ae71d8d2936',
  AccessEntityContactDeleteField = '0cea6e9e-e03a-0721-8ba2-7333baff62ea',
  AccessEntityContactDirectionField = '9339bfe6-e1de-00d1-f4a9-28cedd575a87',
  AccessEntityContactPrivateField = '5359000a-7325-0a87-f140-8762f41bb721',
  AccessEntityContactTagCreateField = '0ed062e2-e08f-4cb8-a1cf-2eb0fb354741',
  AccessEntityContactTypesField = '9e2bcc45-bd5e-42d5-825e-35554feabd81',
  AccessEntityDashboardField = '45da1c3a-e41e-4859-b0a9-72548cef631f',
  AccessEntityDashboardDeleteNotOwnedField = '31938734-470a-4061-a177-60c38e123475',
  AccessEntityDashboardDeleteOwnedField = 'ccba482b-0c20-4a04-9bd6-5c61a7f57a71',
  AccessEntityDashboardSharingCustomField = 'f103544d-232b-4a16-b994-26343e8312f6',
  AccessEntityDashboardSharingPublicField = '4e2f304c-226c-4740-80a5-64f8b40c2e6a',
  AccessEntityEmailContentShareModeField = '02c0d2ec-43b0-41bd-845b-66d29600a35f',
  AccessEntityEmailDeleteNotOwnedField = '96aef1fa-a36e-4cff-8e5c-ad71a29f49cd',
  AccessEntityEmailSequenceField = 'e7ea7036-23d2-4663-83d0-809c3dc77939',
  AccessEntityEmailTemplateField = '32858f2a-9041-48ca-a2b4-9445b429c341',
  AccessEntityEmailTemplateCreateDeleteFolderField = 'b3bb55b0-35a4-4b27-ab37-9ecb23e36ab1',
  AccessEntityEmailTemplateDeleteNotOwnedField = '067c0ea1-e57f-45d5-8bba-84e1ab5063b3',
  AccessEntityEmailTemplateDeleteOwnedField = '7af4e2a4-096c-42d8-9e13-9229064f3cd6',
  AccessEntityEmailTemplateSharingCustomField = '64b68feb-0541-4d1d-ac2b-844844370aa5',
  AccessEntityEmailTemplateSharingPublicField = 'eb230b26-1127-4d68-847c-51c5eee4bef6',
  AccessEntityForecastField = '36d7ca0f-ac5c-40b3-a57c-8eb5dff7bf25',
  AccessEntityForecastDeleteNotOwnedField = '7d02384d-c545-4e75-a90d-9ebcb718b5c2',
  AccessEntityForecastDeleteOwnedField = '49b8875f-a150-4d5c-b43a-afb4626e55c1',
  AccessEntityForecastSharingCustomField = '4c53bf20-7299-4df5-9088-666dc225ee0a',
  AccessEntityForecastSharingPublicField = 'bbdd1f64-b635-4970-b48b-d3f6dfe3ab41',
  AccessEntityFormViewField = '63686198-61dd-4193-9793-f7d969b09e53',
  AccessEntityFormViewDeleteNotOwnedField = '508bd78c-842a-46ea-9fbe-dfb3406137e3',
  AccessEntityFormViewDeleteOwnedField = 'b24329c2-5164-46bf-a937-3d99ac00c4ef',
  AccessEntityFormViewSharingCustomField = 'ab95e213-fcbe-47a2-bf27-e70e413bf5ff',
  AccessEntityFormViewSharingPublicField = '3542f76d-097f-4a78-80e4-85cc6b81c73d',
  AccessEntityLeadField = '6afcf903-b5cc-081c-3429-85f94b7eca5e',
  AccessEntityLeadDeleteField = 'dd297d71-2fa2-0116-82bd-1b53dccc4c33',
  AccessEntityLeadDirectionField = '380b537f-23f5-0021-2444-dfeebd464817',
  AccessEntityLeadPrivateField = 'accf6c9f-3ed1-0f66-44ad-41826b50ee37',
  AccessEntityLeadTagCreateField = '7bd0ca03-b127-4fca-be03-70fcc6259c60',
  AccessEntityLeadTypesField = 'e664dcf1-32b3-4f89-b62d-948d436c0166',
  AccessEntityOpptyField = '8380de28-5ea9-07c0-32bf-2466521cd40b',
  AccessEntityOpptyDeleteField = '491f69e2-d9be-0f1a-9031-26dccfa21f7d',
  AccessEntityOpptyDirectionField = 'cd768404-fda3-094f-21c7-26ea4fc2c89b',
  AccessEntityOpptyPrivateField = '092ce427-40c1-0e9b-97ad-2f9765b43a8b',
  AccessEntityOpptyTagCreateField = 'c4a784d0-8fb4-4cf9-9daa-773d8fd63105',
  AccessEntityPipelineField = '152dba97-7c0d-0d12-200b-b3d49ef84882',
  AccessEntityProcessField = '89dc17b0-7d3d-4326-86e5-9dc3681a8dac',
  AccessEntityProfileField = 'bba668fe-324a-4f13-825d-d5347cc85c25',
  AccessEntityProfileDefaultScreenProfilesField = 'b33fe551-4703-4a32-92d6-d8f03dd75c66',
  AccessEntityProfileDeleteNotOwnedField = '26ca9349-eafd-43e7-ae0e-f4b8676cd32a',
  AccessEntityProfileDeleteOwnedField = '9482f6f2-125b-4e18-b41d-2bc63e912cd8',
  AccessEntityProfileSharingCustomField = '46dbdbf5-ea12-4f62-b08d-4f196b66e72e',
  AccessEntityProfileSharingPublicField = '5d94ab85-f95d-46d1-ba7f-392e893af20c',
  AccessEntityProjectField = 'b691c914-8345-49e9-97e8-210275fce150',
  AccessEntityProjectDeleteField = '4d5704a5-7757-4191-a832-2aa758cc8af5',
  AccessEntityProjectDirectionField = '42734b52-0507-43c4-a2f4-b268ee487c15',
  AccessEntityProjectPrivateField = 'fde02563-b61a-4b23-a6e9-01a23d96326c',
  AccessEntityProjectTagCreateField = 'a1b58cce-9812-4df6-9ff9-6beae4b05da3',
  AccessEntityQuoteField = '62c0a2e3-a921-419d-9103-fb3a9fdef67d',
  AccessEntityQuoteDeleteField = '94aa48bb-dd1e-4ba3-abc2-c51299381373',
  AccessEntityQuoteDirectionField = '56ae6019-722a-4382-9740-71fcf4bf09ff',
  AccessEntityQuoteTypesField = 'c88d192d-fe90-4802-84a8-4f055de3ec4e',
  AccessEntityReportField = '43d17a2f-c2e7-45f3-b6d1-f49a1a869b84',
  AccessEntityReportCreateDeleteFolderField = '89fd33c9-82b6-49c2-ab0a-1f4a766a2afc',
  AccessEntityReportDeleteNotOwnedField = '7a586523-74ed-484a-b4bd-5effb61261d9',
  AccessEntityReportDeleteOwnedField = '6477227d-9b3d-43c7-8bbc-a865e6a28894',
  AccessEntityReportSharingCustomField = 'c00e192a-cc65-4145-9183-8e1fe996c190',
  AccessEntityReportSharingPublicField = '61dfd3e0-8cfa-4b16-a6af-c681136d7eea',
  AccessEntityTargetField = '9f619a32-efe7-4ffd-8017-817c13602cd3',
  AccessEntityTargetDeleteNotOwnedField = 'f7dff79c-6b4e-4c6a-b6ef-3506fd94c160',
  AccessEntityTargetDeleteOwnedField = 'efc997e9-e40d-4054-96c4-318b1affa668',
  AccessEntityTaskField = 'beb092a1-b1c3-042d-b200-2e6e219fbb6b',
  AccessEntityTaskDeleteField = 'db520276-71d6-4959-b675-3abd3f3b55b3',
  AccessEntityTaskDirectionField = 'b88b53a2-9c5a-0346-9019-9c6ad6592114',
  AccessEntityTaskUnassignedField = '27db344b-d612-046b-b4e6-9e2878ec6107',
  AccessExportField = '9eaeb91f-0666-0dbe-3584-210fd19c8b90',
  AccessFeedsField = 'b0ff3087-2507-050c-8aba-1804b6c45ac9',
  AccessForeignCurrenciesField = 'fea9031c-e34a-0f75-d73a-d3ad723c7f2c',
  AccessFormAndFieldsField = '3493934a-5017-04d6-55b6-a285e5a3a15c',
  AccessGlobalSettingsField = '3f9e4f56-cb68-0f17-b7d6-9a09c68bfb2d',
  AccessHomeScreenField = 'fbc2f3af-d1b6-01a6-9792-eb6b9164d36d',
  AccessImportField = 'fc244369-5e58-4c2e-8d1d-13deaad0d269',
  AccessInsightsField = '20d97f43-b72d-004d-fe87-f8eec4450503',
  AccessLeadsField = 'aed32411-ce40-0be3-b5bc-fca4d57434a0',
  AccessOpportunityField = 'a95982fc-b1b7-0a51-9a48-162343c79a82',
  AccessPipelineConfigurationField = '6e14130d-0a1f-0a9c-3a5f-6fe8f4536787',
  AccessPrintField = '171102c4-1e1c-0698-d0fa-183dbd9f285f',
  AccessProductsServicesField = '0f792f96-2687-017e-6725-424f03f2c876',
  AccessProjectsField = '3ade955f-0025-449a-af56-059a5952fb60',
  AccessQuotesField = '1de6f775-2fc7-4ff3-83a3-be0e244526a2',
  AccessSalesRolesField = '49463cd6-2790-0f76-c865-cfa13024e61f',
  AccessSecurityField = '9dc5e4a8-93d8-0fe1-49f1-8a1696a62314',
  AccessUnitsField = '30bb9b8b-6099-033d-f79c-88dbe74ef8d4',
  AccessUserAndRolesField = '98e40ae3-03e7-0fd6-94e3-c3655d1f2afa',
  CreatedField = 'b4769fed-3474-097d-5665-c7a8b5008bca',
  ModifiedField = 'e8ab53c8-1c5a-063f-79db-a32e52d8d0e3',
}

export enum MemoFieldsEnum {
  MemoIdField = 'f8769ca7-207d-0a07-373c-79418347990e',
  SubjectField = '156af896-db64-0511-a6a3-6a401afb7cfc',
  AccountRelationsField = '0430bc46-ee1d-0650-e14c-a8a3617fdc4b',
  AttachmentsField = '2d59251f-0cfc-067a-c2a1-8f6c40e604f8',
  CommentsField = 'e819a6d7-fe92-0ea4-4cec-436fa4af9039',
  ContactRelationsField = '7a4b22b8-f84f-0493-3f21-09e7b85fcba5',
  HasAttachmentField = 'd08500a5-6804-05fe-bcb7-c930b09f6b36',
  LastCommentDateField = '16b2a374-63b6-07da-a231-22efceb4ae3e',
  LastCommentOwnerField = '3eca9c17-8865-0287-40a5-00c7e7814e5e',
  LastCommentSubjectField = '8cbea6ab-8acb-0328-0bd7-daccfb85dc9a',
  LeadOpptyRelationsField = 'be6b3bd2-bf90-0167-3774-3baaa8e7117f',
  LinkedItemsField = 'b8ed3b5a-bed2-05d5-208a-850408a4af3a',
  OwnerIdField = '44c2cac2-040d-033d-45da-976b14bfc321',
  ProjectRelationsField = '77e1f726-2d60-4d37-850a-ae2fbfa45183',
  QuoteRelationsField = '574d47d3-64f8-4817-a06f-ec90ad2f9441',
  SharingField = 'b315a28c-a89a-04cd-f804-e4c3f274455a',
  SharingClientsField = '5d2e2340-fc00-01ef-f242-4e86fba0fe88',
  SharingUnitsField = '9898422f-2ec3-0ac7-a3f4-7f6ed7a49fce',
  CreatedField = '1e092f3d-b4e3-0e7b-619e-dff806210982',
  ModifiedField = '5595251e-247e-0249-0fe9-533e10807df8',
}

export enum MemoCommentFieldsEnum {
  IdField = 'e024b41e-a0a9-019b-9726-17cfe5e435e0',
  CommentField = '9bea9779-fd40-0511-2a47-4e80a3ebcafd',
  MemoIdField = '83e769d9-b1e5-07d7-5974-8ae987a5a0b4',
  OwnerIdField = 'd76cce0c-2be0-0910-bd82-a3b009e6c6ca',
  CreatedField = '256de0f4-a789-0bd4-fef6-cdf72c3a5f4e',
  ModifiedField = 'c9588af5-2e31-0c18-fc23-ed4db0710af7',
}

export enum MessageFieldsEnum {
  MemoIdField = 'f8769ca7-207d-0a07-373c-79418347990e',
  SubjectField = '156af896-db64-0511-a6a3-6a401afb7cfc',
  AccountRelationsField = '0430bc46-ee1d-0650-e14c-a8a3617fdc4b',
  AttachmentsField = '2d59251f-0cfc-067a-c2a1-8f6c40e604f8',
  ContactRelationsField = '7a4b22b8-f84f-0493-3f21-09e7b85fcba5',
  HasAttachmentField = 'd08500a5-6804-05fe-bcb7-c930b09f6b36',
  LeadOpptyRelationsField = 'be6b3bd2-bf90-0167-3774-3baaa8e7117f',
  LinkedItemsField = 'b8ed3b5a-bed2-05d5-208a-850408a4af3a',
  OwnerIdField = '44c2cac2-040d-033d-45da-976b14bfc321',
  ProjectRelationsField = '77e1f726-2d60-4d37-850a-ae2fbfa45183',
  SharingField = 'b315a28c-a89a-04cd-f804-e4c3f274455a',
  SharingClientsField = '5d2e2340-fc00-01ef-f242-4e86fba0fe88',
  SharingUnitsField = '9898422f-2ec3-0ac7-a3f4-7f6ed7a49fce',
  CreatedField = '1e092f3d-b4e3-0e7b-619e-dff806210982',
  ModifiedField = '5595251e-247e-0249-0fe9-533e10807df8',
}

export enum MessageRelationFieldsEnum {
  IdField = 'd7cc48d7-a805-0b35-6428-036cd698157f',
  AccountIdField = 'f5a2fb5c-6e6f-03dc-d18b-c9af4e1dca7a',
  ContactIdField = '3c4c632c-9c2b-005c-d318-b3f2f54ff72e',
  LeadOpptyIdField = '2889d264-7929-0958-9d4b-743212219b9e',
  MessageIdField = 'c19dc4e9-d488-0da9-5422-6629503fae59',
  ProjectIdField = 'e869948b-2b3d-484e-bf28-0d490cfef88b',
  QuoteIdField = '2b89e601-72b5-4ae3-9030-3a6d9136cd9e',
  RelationField = '7898b511-d31d-0066-963d-5f9d51055520',
  CreatedField = '0f28be86-372c-0a1e-9249-85efbde2d566',
  ModifiedField = 'a0bf1345-e43f-03af-ca2d-5a35d0939617',
}

export enum MessageSharingClientRelationFieldsEnum {
  IdField = 'c7ba01f8-0b2d-0e1d-8c2c-7dd83b951ea8',
  ClientIdField = 'd43f20a8-f93c-0648-7bee-78f31bad5796',
  MessageIdField = '23b15009-c786-0dab-7afd-76e51e99e472',
  CreatedField = '99207e28-7f1e-0539-7825-09da8dcfe83a',
  ModifiedField = '8f6965a6-db0e-086a-2973-63b4c1ddb0e3',
}

export enum MessageSharingSalesUnitRelationFieldsEnum {
  IdField = '1c566612-e2f6-02d1-116c-e103f28b48ed',
  MessageIdField = '775c8a64-628f-0614-806f-4b5d74e2f188',
  UnitIdField = 'c864f805-45c9-0b64-ce07-b394ce6cdc49',
  CreatedField = 'edbb0ab5-443e-0561-22dd-e03112db37d7',
  ModifiedField = '81045edc-03bd-045b-6154-182e1e8d3809',
}

export enum NoteFieldsEnum {
  IdField = '6f239406-3fee-0d99-4834-993685f887f1',
  AccountIdField = '60afbe36-759c-0b04-2b23-94caa58e50c1',
  ContactIdField = 'c01495e8-9510-0791-b86a-90a00c31db17',
  LeadOpptyIdField = 'e136bf86-7ae6-08fe-f617-7125704f07bb',
  NoteField = '3c73f573-d852-0f2f-f834-1a84242d9d39',
  OwnerIdField = '200398aa-3921-0557-9bda-bb8f3e529a57',
  ProjectIdField = '34ce7f68-1c4e-4102-b61c-7e7a63d6b966',
  QuoteIdField = 'c9fb0afa-b732-4fc4-b79a-2cda5d3c3c4d',
  RelationField = '43f3f460-8564-0898-827c-df0472a61627',
  CreatedField = 'aa83074f-7956-0c29-9b20-afc4beca7215',
  ModifiedField = 'e82df726-64c8-0c64-f0d2-e75909ef6cad',
}

export enum OpportunityFieldsEnum {
  OpptyIdField = 'd22a6e0f-7a5a-09d9-16c5-deb1fde83544',
  NameField = '992f3eee-2bb5-0118-a9f4-2f20a9e9c908',
  AccountField = 'f5e02f03-1b6b-0519-9e5e-a7287f5dd776',
  AccountCardField = '02626789-b040-011d-2257-c76ca967c633',
  ActiveQuoteIdField = '3fee475f-eb73-4902-a2c6-37052aab156d',
  AgeMetadataField = 'ad301430-ae1d-4286-b51e-1865352d8522',
  AutomatizerCreatedByProcessField = 'c4f1479f-8861-4226-9001-c1d5fc898281',
  AutomatizerProcessTriggeredDateField = 'c93437b6-e5a9-42cd-ae1a-e08d8fc1ad47',
  AutomatizerProcessWithScheduledActionField = 'a03a908a-00dd-4b11-abcd-ad463cac3550',
  AutomatizerProcessWithScheduledActionDateField = 'e1ba0812-683c-41bc-b2af-4ead289814d8',
  AutomatizerTriggeredByActorField = '31926528-2696-4e48-ac8f-006cabe78dbc',
  AutomatizerTriggeredByProcessField = 'd82dcaaa-9235-46f4-a5ed-601cb74f8444',
  AutomatizerUpdatedByProcessField = '85f1d35d-b297-478d-aae5-217b734b847c',
  BalancedValueField = 'a2c307f8-9266-089c-4095-6c7e0fd942ca',
  ClosingDateField = 'c35548c7-3712-0596-811e-a9bf13f934f5',
  ContactField = '47d1b3d2-b9c9-0b09-81a0-eecacfe12632',
  ContactCardField = 'c4cdb6da-cea3-0b81-4d23-b53d13e9adc6',
  CreatedQualifiedByField = '06d99b83-29e2-06f0-661a-ca9f660a5017',
  CurrencyIdField = '53524d53-b017-069c-a9bc-245f9fc4dc85',
  DescriptionField = 'b445f1dc-3bd4-02ad-dc36-18b962d27dd3',
  DocumentsField = 'a734337b-e2c8-0a4e-3817-5552f3a57314',
  DueInSalesStepField = '0b7835ac-f3de-0d6f-ca40-11a8433e2a47',
  EditorsField = '550d5fcf-3b67-0167-09ec-aadf648616a6',
  EmailSequenceCurrentlyEnrolledInSequenceField = '49522018-0130-4ea7-a084-0bfa0af58a53',
  EmailSequenceEnrolledByActorField = '9a579b52-e816-4389-bb34-651fa97eeda9',
  EmailSequenceEnrolledInSequenceField = '3619da05-0dc8-47eb-9bd4-a2641b080091',
  EmailSequenceLastEnrollmentDateField = '4fec0e47-6dc4-4b71-8b52-b2b4d0c7cf3b',
  FitnessField = 'b22fc6c7-b862-0736-8619-ec1e2d928221',
  FitnessArchivedIndicatorsField = 'b0660ff6-dd14-4699-9d0e-d0083d1074b3',
  FitnessIndicatorsField = '6c8626ad-2407-4e02-a68a-e8a9dc757b6b',
  HasRecurrenceField = '78793413-2df6-09ca-396a-b2cc6bf01d4d',
  HasScheduledActivityField = '15d02ee2-d1af-0f94-0caa-11a1e98aab69',
  IsArchivedField = 'b4b20d77-f16c-0554-2b4d-0bc2b361aa4e',
  IsExcludedField = '465d43fe-fe3f-0c98-bb7a-8aba4f8dfc35',
  IsFavoriteField = '3cb37831-f5ec-0346-7320-ce274046c16a',
  ValueAutoCalculateField = '856742c7-f87f-0691-13b0-72bfa06d0881',
  IsWithinCompareTargetField = 'f4da72f8-cbc5-4e29-bdd0-8db46c0b42b3',
  IsWithinTargetField = '7575aaa2-bfd8-4319-94bd-0e5ba5614aa5',
  KpiField = 'd48e0531-e2e1-02f2-8c28-404feb0ead97',
  LabelFlagField = 'd55e3ec5-618b-0415-e795-4bf1b9fe99be',
  LastMovedDatetimeField = '63c47cab-6509-49f4-af2c-91c316e0fa56',
  LostDateField = '5cf85b21-6716-4f21-9a44-778d5dedfda3',
  LostWonByField = 'ffa20de9-a64f-0dd7-d4da-e1f5cae3fb09',
  ModifiedByUserField = 'd264ef3d-92d6-4700-9819-952bc637b143',
  NotesField = '50aabb32-d27c-0d92-0694-dd4c30e228cc',
  BaseValueField = '7ea49e73-3ef8-0679-0d26-0db813b7d540',
  OpptyLeadCreatedField = 'f1b9f544-5783-0335-1a40-76f43cf50f7a',
  OpptyLeadCreatedByField = '43d4c37b-4be2-0ac7-12c7-5931967a72c8',
  OpptyStatusField = '0e81bc72-ee7d-08ff-1e31-84a13e78c85e',
  OpptyTypeIdField = 'a432184a-eead-03a3-7aa4-4f22d75b80ce',
  OrgChartSettingsField = '0024a8e2-0cdc-4049-80bf-f4439a79c636',
  OwnerIdField = '80037c9c-d34a-0a26-88c7-b2c146634db1',
  ParentUnitIdField = 'cb1ba183-e5c8-0a98-1bf0-b27bbbbc3649',
  PipelineField = '2d9c9b89-3310-025d-aa3e-cc5000f21a49',
  PreviousStepIdField = '81c8ca33-9437-4bf4-8a27-29238d0eb678',
  ProductCountField = '2660ac8f-82d3-00a8-a712-a51591ad52e7',
  ProductCurrencyIdField = 'bcaf0f9e-ecf3-0390-efbd-8e8d7a2cb5de',
  ProductDifferenceField = '17702c41-c5cb-0a1b-bdb0-51ed248b372d',
  ProductDifferencePercentField = '4e2d234d-7644-0cf7-4348-8be50d0f8eb9',
  ProductIdField = 'af214f02-07b7-0094-f29d-1ef65b789d6b',
  ProductPriceListIdField = 'ef1b80f0-609a-00cd-f969-27ce69c42566',
  ProductSectionsField = '951b7413-5178-41a4-aaa4-998bc99e9ef3',
  ProductValueSumField = 'af29ab07-d923-0eab-fa84-34c96552a13b',
  ProductsField = 'a7c4333b-1be7-0b3b-405f-a3bfdde75c83',
  QuickAccountEmailField = 'a49d5e27-0759-0435-1369-7976e06b79bc',
  QuickAccountNameField = 'b0950d34-d1eb-0672-68ca-9aa34fcc5bbd',
  QuickAccountPhoneField = 'a1d8fc0c-dee9-0267-7fe6-48dd0903df49',
  QuickContactNameField = '0df3d503-a488-0211-3dec-b0cf54f7f283',
  QuickEmailField = '9238186f-5116-0ce8-7944-11900515487e',
  QuickPhoneField = '99a905f6-325d-0b9e-aa6c-3401876feb12',
  QuoteRelationsField = '53589378-fe79-476a-bdd4-dac17bae8cb4',
  RankedValueField = 'b19b95ec-59d0-0978-d646-80b03e4c8230',
  RankingField = '9b459c89-ce08-0db6-4037-972e10c56364',
  ReadyToMoveField = '6503f9f4-7495-4bc2-ab7e-b83051c92d6c',
  RealClosingDateField = '1d978b31-91e3-04e3-b3ad-ab9db4bb5af1',
  RealValueField = 'b1203736-4295-023d-a098-d49de5a7b8f3',
  ReasonOfCloseDescriptionField = '2c4dd5ae-239f-033b-f6c7-0f5db4b6570d',
  ReasonOfCloseIdField = '47e36efa-89fa-05a5-15b2-6ceb8d462fbd',
  RecordUrlField = '465129b9-900d-4c27-9b25-384339f6d704',
  RelatedAccountEmailField = 'e926f3ae-518d-4f45-a7a1-e941c6e62870',
  RelatedAccountPhoneField = '6b58f5b7-6a5f-4bb6-a3df-210f1dc4e221',
  RelatedAccountsField = 'cd01bc86-ec85-40b1-a127-48088eafe101',
  RelatedActivityDeferredField = '304b365d-b1ba-0412-b2d1-93f46c1ef69c',
  RelatedActivityFinishedField = '0e0a435a-8fc0-0fba-6fa0-0717426d9196',
  RelatedActivityInProgressField = '1b9b7511-47e4-091b-2deb-19d2b52ed96a',
  RelatedActivityNotStartedField = '75d0e7be-fb16-06a9-ddb5-729b34559479',
  RelatedActivityOverdueField = '88356ba4-8e39-0f78-08e9-0668adb8c578',
  RelatedActivityWaitingField = 'b6e546e3-3a90-095a-86fe-d83411331e45',
  RelatedContactEmailField = 'd93d4828-1076-425d-a3b9-be092e4a099c',
  RelatedContactPhoneField = '5a967bd8-53e7-4b57-acde-0a70f28f281f',
  RelatedContactsField = '9199228a-e824-4e55-bc49-2931412d55ae',
  RelatedProductsField = 'ccfd4957-acef-489e-9bdd-6270a75a32a9',
  SalesCycleLengthField = '6f7ea9fe-52fc-4d3b-a34c-a2103df056cf',
  SalesRoleGroupField = '0b623289-e389-021d-8fe7-47574df0d7e6',
  SalesStepActivitiesField = 'f3b8a084-cb6a-4f5d-8d97-81096a735401',
  ScheduledRevenueField = 'fe54ff36-2674-4ae0-86d6-decc9005ff69',
  ScheduledRevenueDurationField = 'bdfd81ff-1038-4d0d-9505-baa612260529',
  ScheduledRevenueRecognizedField = 'e374e8a7-af1d-4e1d-9905-3b4c178b6d71',
  ScheduledRevenueTargetField = '057b9762-227d-4590-9cb9-ff5f0df3ba69',
  ShareActorField = '2b2d0ca3-714f-0beb-b035-cd5c21e314cc',
  ShareModeField = '03ed69a7-6f07-0b1c-73be-b123ef6d64a6',
  SharingField = '393e44b6-a210-01ce-ca7a-bd6ced12820e',
  SharingClientsField = 'a6658f3d-4882-0b56-f0a9-d15e051b4329',
  SharingUnitsField = '6af5f964-8209-01fd-f12a-b7627378e846',
  StepChecklistActivityRelationsField = 'b725ee10-b102-0e0b-8977-209d4ed433ba',
  StepIdField = '48809eda-fc9b-0906-df64-54898423b8d5',
  StepProbabilityField = 'ada6c491-cf74-07d6-db52-23937a4da349',
  TagsField = 'ea14d9fd-411b-4e94-835e-fe2725f06d1b',
  TargetContributionField = '19da7977-4ebf-06d3-86fb-88d9e57f1b0a',
  TargetCountField = '8cafd1c7-ebd5-02ee-0b08-ddf21c402078',
  TimeInSalesStepField = 'dddadf98-d93c-05c4-5c0e-1aecb612c764',
  TimeframeField = 'aaa122b2-b619-0aa3-1983-a6f25b620a05',
  UnitIdField = '6cd0d888-60cd-0917-ad07-7a2f23dfbea0',
  UnweightedValueField = '56beb3c7-a893-0ccf-8ab0-8f8af635d0b7',
  ValueField = '6da56d81-0d6b-0c3c-301e-6dded539d3b9',
  OpportunityValueForeignField = '8061c994-9fc4-0c9a-733a-b55dcbdb0b58',
  VelocityField = '16b8bc28-558a-4975-ae0a-721f7763f103',
  WasQualifiedField = 'bddc4ec2-0f6d-0af6-b411-257be1a24136',
  WatchersField = '8df91d65-9b56-0df7-dd23-1b6afad269d9',
  WeightedValueField = 'ffda4bac-8e33-0a8d-0d0c-53a991e8ac47',
  WonDateField = '3a94c20d-bf40-459b-86d2-110815a26706',
  CreatedField = '7fe1cd99-9698-091f-7a8f-8930ccb559d4',
  ModifiedField = '429fca59-29a1-0735-cfb9-88bb47d9a580',
}

export enum OpportunityTypeFieldsEnum {
  IdField = '836cc84d-7a42-07bf-1a59-35ef4a6b333f',
  NameField = 'eec2dc72-1cad-0a29-7b29-5a9fab1bca32',
  FormEditField = '7f769aba-88b5-0b56-3e1e-662b8116e8b9',
  FormLostField = '9d34fea7-81b1-03fc-18e6-889a8386a094',
  FormQualifyField = '05c57c2d-f798-4937-b5c8-7a8b3294ff94',
  FormWonField = '2cf22071-6028-4dbd-beb0-6b14728df283',
  IsReadonlyField = 'd4a7cc56-b018-0369-71f1-a6e480e035f0',
  PipelineIdField = '1f73217d-6879-0774-967a-cf3474179d59',
  CreatedField = '6f364bd3-6900-0739-29dc-9d4e82d3e1b6',
  ModifiedField = 'b64bf180-4bc8-0a27-e7a4-2ac017b1be1c',
}

export enum OpptyProductRelationFieldsEnum {
  IdField = '618aff52-31c3-03a8-0c96-a73cf31c6108',
  AmountField = '4820c523-2ae8-0a50-18cc-c4776ae8345d',
  CommentField = '2e51990a-48e4-0d62-a122-7b94beace5d1',
  DiscountPercentageField = '9b5f9e05-cf53-4e70-aa1e-3f9e42c3284f',
  DiscountValueField = 'd57c6369-415d-411b-b204-3ef71fa51f2c',
  ModifiedByUserField = '139cec58-9c74-4400-a4c4-5e56dd844dcb',
  OpptyIdField = 'e6b63312-90de-06a3-4fc3-5ca9a76046b0',
  OpptyProductRelationTypeIdField = 'b0c97a77-29e7-4bb3-b984-5ba2ec27336c',
  PairedOpptyProductRelationIdField = 'b3321218-1633-4130-9883-1ce66c84ac39',
  PriceField = '0497de63-1e6f-0592-30ab-eb0ddefa3a69',
  ProductCardField = '25dd8717-b004-48c8-be3e-35a3c2631a52',
  ProductIdField = 'ed85a048-11fc-0b3d-facf-6d67c15db778',
  QuantityField = '1e1f5950-5d5b-0233-fee0-c79cd96cc789',
  QuoteIdField = '0f71540e-5ad0-4fa5-9528-153855abf226',
  RelationField = '7bac742b-662a-4daf-957b-568b2e4c7479',
  SectionIdField = 'c806b7d6-a3e5-483a-ab83-dd1552812e46',
  SortOrderField = '967cd023-dc28-400b-9504-15c40a1e19d1',
  ValueDifferenceField = 'ca141fc7-80a9-0f62-f530-3e94b45747a9',
  CreatedField = '1d7f2cdd-7a72-0c8d-b41a-b11ea4fea18f',
  ModifiedField = '80053d8c-fbe9-0f37-c9db-71ac65ea46d3',
}

export enum OpptyProductRelationDataExRelationFieldsEnum {
  IdField = 'ffe765e0-8b55-4bf7-bd19-9ab5e2059ceb',
  DataIdField = 'b6ed8f9c-2ef9-407e-b5cc-2dcbb29b001f',
  DataSetIdField = '45ba5231-dc35-4381-928d-c6b2a50db2c0',
  OpptyProductRelationIdField = '3a930b12-bb38-4bcf-9004-3fa0b0bbb038',
  CreatedField = '841b596e-ec22-47cd-a964-47e447325d4a',
  ModifiedField = '1628801f-4faa-4dbe-9637-2f0020bb0310',
}

export enum OpptyProductRelationRevenueScheduleFieldsEnum {
  IdField = '5a0daacd-9ae5-41df-8a01-2b9aedee459f',
  CancelationDateField = 'f7d7bf13-9d54-463c-9d6f-6694d1bbe2c4',
  PeriodCountField = '74302668-9765-4ee4-a0c9-9a9e01e2f925',
  PeriodTypeField = '9f6d8698-2ecc-4a94-a8ff-d9da24147985',
  StartDateField = 'eb50b004-ba35-4703-90ab-87379e301f2d',
  CreatedField = '1105657c-1e03-4fa8-b9c2-636d2199e2df',
  ModifiedField = '0f5bce4b-d1db-4bdc-a239-c4476c4e28a5',
}

export enum OpptyProductRelationRevenueSchedulePeriodFieldsEnum {
  IdField = '80653825-a3c5-4a72-8840-8c68f6f8b13d',
  DateField = 'e0a67086-38d8-4c47-a05a-fee4157c44b3',
  OpptyProductRelationRevenueScheduleIdField = '476ad747-19d2-449b-9ceb-7e38875bd788',
  TransactionIdField = 'e332b235-cfdc-479f-b8cd-7a2db4bf537f',
  ValueField = '14a1bdeb-e6f4-4d72-9670-99b89d0b3c6f',
  CreatedField = '005ed02d-a928-44c9-b7e5-aa71aeb739b6',
  ModifiedField = '733abc57-d0c9-40cf-9de9-56a391bbb235',
}

export enum OpptyProductRelationTypeFieldsEnum {
  IdField = 'bbea01c8-50d8-4022-9e70-6bf440137bb8',
  NameField = '5f4cda84-fe7f-4699-a8c5-c746dc3c09d2',
  FormEditField = '2081b8cb-c935-4917-811f-09440dea4137',
  IsReadonlyField = '80cb515e-4e76-4ee4-a856-7a8918fde7ed',
  CreatedField = 'f8f5cec4-92e6-407e-b3e0-ba755f291c13',
  ModifiedField = 'd80a2676-b732-4e33-88d9-d84b50f5a8aa',
}

export enum OpptyRecurrenceFieldsEnum {
  IdField = 'be921f84-b62c-0760-97bf-566346bb35e4',
  ConditionField = '311ddfda-9e23-0024-1add-baf512ac42a8',
  DayField = '6bb7e8c1-4df1-0536-4bee-3a6a349000b8',
  DayOfWeekField = '6d5b3643-d8cb-0fdc-4aa4-02e6fb71bec6',
  EndDateField = 'cd64e206-53b3-0712-ba27-b445ab2b5ba8',
  MonthField = '8f28e007-ebc5-0ca0-0a47-53c5a8d7a01c',
  OccurEveryField = '00a72605-b706-0322-1231-b2c58075fdfc',
  OccurrencesCountField = '45bace7c-96e2-08c8-ac26-4c7ccc58025a',
  OpptyIdField = 'c4db1a51-a082-065c-85c7-9b407633104e',
  StartDateField = '64809c66-198f-0519-319d-c62f07929ac5',
  StepIdField = 'ce26cd28-2864-08f2-4e23-dcba9bd67020',
  TypeField = '800aef0d-e9be-0806-0a08-4cabaa9e9644',
  WeekField = '6f487124-0956-08c4-a909-faa4f3a754bc',
  CreatedField = '46f88744-5011-0a7b-c94d-0b580ef0c574',
  ModifiedField = 'bd51851f-57bf-077b-47fa-6fdddf1a68c4',
}

export enum OpptyRevenueScheduleFieldsEnum {
  IdField = '55ce8e08-b3ec-4993-994d-430ed5872af8',
  CancelationDateField = 'ad8ddf19-46ec-483d-8162-161a4a66e505',
  PeriodCountField = '1a7223b9-35c2-4d2e-a69d-077a0796d602',
  PeriodTypeField = 'd33f5dbd-3ac9-48f8-9c39-9eeb63e9d22c',
  StartDateField = 'd497d4e5-e565-4e2f-9f13-fd5c060dc38c',
  CreatedField = '133ea6da-e66f-4cd2-8efe-0b36b92764cf',
  ModifiedField = '3a4eb952-0395-4c26-88c8-9c2b796faf5e',
}

export enum OpptyRevenueSchedulePeriodFieldsEnum {
  IdField = '7c30bc53-2028-491a-8124-af0a986c6b1f',
  DateField = '787f5cc3-55e5-4d85-8dce-5c0002eee859',
  OpptyRevenueScheduleIdField = '86fa2f28-0ba9-4a43-b8fe-c8cf09aa0cdd',
  TransactionIdField = '23e97696-6ffc-4d66-9a78-ef375a7d747c',
  ValueField = '4a1dc577-6fde-4ce7-b414-043f4f5e6bd1',
  CreatedField = 'aa48c453-7cc0-40e7-872a-489138e2a02f',
  ModifiedField = '60086120-50ad-4e18-bd0f-cfc5a9374ee2',
}

export enum ParentAccountRelationTypeFieldsEnum {
  IdField = '009cc9d0-1749-06af-bd9a-278eaf8222b3',
  ChildLabelField = 'fb49b5f7-7836-066d-1872-86831b96f30f',
  ParentLabelField = '12ae5a92-bfdf-0c3f-6a15-c85923157e6f',
  ParentLabelNameField = 'deb8352d-49c8-0312-90e7-3e12c1065a9b',
  CreatedField = 'd2d48bfc-adf0-0a5b-d5b6-1cd3c45d904e',
  ModifiedField = '6d0866e2-0e1b-0cad-2063-cd0da80d8875',
}

export enum PhoneFieldsEnum {
  IdField = '5876f81e-911e-45d1-8fd8-3dab0482ea93',
  NameField = 'fb97622e-8a27-4e5a-bd19-5cc5bd6803af',
  OwnerIdField = '649bc3f6-f57c-4a4d-b2d3-1c009f0e0ad1',
  PhoneNumberField = '26cd3ae2-76ca-4dae-89c7-b9c4d37e3390',
  TwilioCallerSidField = 'c5bcb847-54cc-4553-945a-b61de35a3921',
  TypeField = '820763c5-ffab-4579-ab7b-48a079032305',
  CreatedField = '4796c305-825a-4ce5-a466-3af472efdfa8',
  ModifiedField = 'bde14144-af5e-45cd-8a90-ba5a44bf05df',
}

export enum PipelineFieldsEnum {
  IdField = '13b9e99c-cb7b-059e-fd7a-d803dfdfdae3',
  NameField = 'f879797f-6c3e-0b98-f432-edbc62d0ca69',
  ColorField = '71ab0c3e-7fdf-0aca-fae7-1ae883a1f65f',
  CreatedField = '5c207b10-a4e9-059c-f0fc-7b53156a49f7',
  ModifiedField = '449f2b9c-ffcf-008c-6d67-8cf5a5145de8',
}

export enum ProcessFieldsEnum {
  IdField = '44ff5811-ae53-4bca-b5f7-039fc3ca6065',
  TriggerEntityNameField = '86ab0668-b571-4ee6-94f2-0a5e30858b77',
  NameField = 'd4e54a79-acd1-4197-85e6-4882d37b0f42',
  ActivityField = 'd7cfcff0-9a1e-4a06-b843-36c6ecfbd25b',
  ActivityLogsField = '9bdff6c0-f113-4dba-bac7-daa16fe7f160',
  AttachmentsField = '03f210ba-947e-4aa3-a8d7-6a32413c23e7',
  DeactivationReasonField = '1d646676-8472-46d4-8f91-2501dd49a177',
  DescriptionField = 'dd905ad2-f466-444c-90f3-5cb775234556',
  IsEnabledField = '52fe71ef-07b3-4d0b-a8a5-785e23f1540d',
  LastProcessRunField = 'dde77f49-437f-43ae-95e6-66a26b3da37f',
  LastSchemaChangeField = '74185890-cd64-43fc-86be-b89d237d3a34',
  LastStatusChangeField = '13586ab8-c13e-4ecb-bbf0-6edf8ced58d2',
  OwnerIdField = '37c711f8-af05-4473-a549-3d0f4a6ab5ea',
  SettingsField = 'c78c7d82-91b6-488e-bd2b-5771a44f797d',
  StatusField = '808258e9-3baa-4aec-9319-3d91028c66d8',
  TriggerTypeField = 'a162fa60-91e1-48ed-8cb2-7dc18eec9fa5',
  TypeField = '5d912152-cc49-4ed5-a740-49c618bc05f4',
  CreatedField = 'd3d285d0-b1cd-4bd0-a563-27f7384ae769',
  ModifiedField = 'b7f7474e-35e0-4bcf-8ec4-ae709e0d801e',
}

export enum ProcessActivityLogFieldsEnum {
  IdField = '31cecfbf-0ea8-41d1-a550-6d517627e8e3',
  ActorIdField = '2f922780-e66c-4413-a6b5-9b5c4247c571',
  ApplicationIdField = '095539f7-cb42-44b3-ab1b-21c955708881',
  CpuRunTimeField = '1c7a3052-a4e7-40bc-be79-3af4f4817cdc',
  EndTimeField = '1392224d-b551-417f-b366-0742e78e65bd',
  IsTestRunField = '771e097f-aefd-4ce4-b276-5722b2ff1fb0',
  LinesField = '3e0ba317-9304-4c89-a30e-b83d42d335d4',
  ParentProcessIdsField = '91d6ff42-0b96-42b3-8fed-27e6ebf85152',
  PeriodField = 'f0aa73d2-a555-4a47-813a-33ebe5e000fe',
  ProcessIdField = 'd65e3c8d-c492-4d28-9493-ca488bfed01f',
  RunIdField = '77ac4e8e-c4c8-418a-b8c5-b1b7578f12ca',
  StartTimeField = '38887aa4-da51-4733-af54-a6f372fc4d3f',
  StatusField = '9d61f8b0-e55f-453c-96a6-8aa0eedf9050',
  CreatedField = 'f52b2f8a-e298-407e-bf14-92ad749b233c',
  ModifiedField = '48ffdac4-9b5a-401a-a6da-3dc377b79e7b',
}

export enum ProcessActivityLogLineFieldsEnum {
  IdField = 'a37ea445-6f6c-4105-a63f-398f77da7c9e',
  DataField = 'cf383d78-1cc2-48e3-82e3-8eed06c8e5d7',
  ExecutedAtField = '12b84a32-011c-4cbf-96a0-f1898b3dadaa',
  IsVisibleField = '0de08015-09f0-4b20-be00-2228603f3379',
  ProcessActivityLogIdField = '980d22b8-b091-4250-83eb-e4f7a4453db4',
  SortOrderField = '396b0651-40d3-4aa6-805c-ad6451b0dca5',
  StatusField = '6eebc157-5f11-4ed0-aaba-67d172442a23',
  CreatedField = '27dec05b-1c0b-4b37-a1c6-1cf9a672182e',
  ModifiedField = 'd9cc6041-9ae8-4547-af88-f5177580c5ee',
}

export enum ProcessTemplateFieldsEnum {
  IdField = '36a5ae9d-de32-4ec1-9181-46f0e6733e7f',
  NameField = 'a7142fcc-1a37-4330-999a-7969e0187e8d',
  AttachmentsField = 'c8ea72c7-7f8a-4952-9db1-69728d40ce13',
  DescriptionField = 'bbf05f41-0eb0-4ab5-94da-4ee8f065ce84',
  IsRecommendedField = '8a9f268b-f099-49f5-8b83-4290889fcb37',
  SettingsField = '87ee1ff1-7b30-4eb1-8864-b699d0241a68',
  TriggerTypeField = 'c102d73e-a906-4261-80b6-a5d183f4111b',
  CreatedField = '56dbd7d2-3a72-498c-8d93-814361eb2cbd',
  ModifiedField = '15902587-81ad-4638-8883-026a20fc357c',
}

export enum ProductFieldsEnum {
  IdField = 'eedd1ad2-e76c-0363-8e33-45e57e0529b8',
  AllowedPipelinesField = 'b68223ca-8443-06c7-5048-04e71cb27669',
  DescriptionField = 'f6abbb0e-ea43-00d7-2d37-0930ddc1c956',
  NameField = '0eb702aa-a2d2-059c-1ad8-13bd007e3835',
  PictureIdField = '42aa1889-5ebe-4fd6-918e-a5f45f8c5aee',
  PipelinesField = '12ad991d-40d3-0e4b-9d83-f4b3c7e66ef8',
  ProductCategoryIdField = '05db80cb-75de-01e2-c081-e159710ff70f',
  ProductPipelinesField = '7122741d-c365-0e5a-9aac-cc3a4ed77761',
  ProductTypeIdField = 'be85dff2-159f-0a35-a88a-f7a13bc4ae97',
  SkuField = '5720522d-597e-099c-e73e-4eb4acc55dc1',
  UnitSymbolField = '399b36b8-727a-058a-c0a7-c479bc8df811',
  CreatedField = '5934b221-fda9-0ce5-06ec-0d500296a6d1',
  ModifiedField = 'dd18b0c5-2df3-0be3-754a-5630d1f675df',
}

export enum ProductCategoryFieldsEnum {
  IdField = 'd9d0fd30-92c2-0f22-3718-7e3e04401cdd',
  NameField = '96b84647-6081-053a-ec6d-8583c718b1ab',
  ParentIdField = '9b76813c-ab92-0520-8977-a4659254b0a1',
  CreatedField = 'f3643709-298a-0692-3394-affb6f142b81',
  ModifiedField = 'ed990611-e252-0fde-c672-265105f068d7',
}

export enum ProductDataExRelationFieldsEnum {
  IdField = 'a19c6f68-621e-477e-98ca-0bf9bf530913',
  DataIdField = 'd8cea47c-c8e2-4834-b9bb-00b38f1b48da',
  DataSetIdField = '05eb513c-7dbb-4af1-8d86-14d2a1cb7715',
  ProductIdField = '59aa2809-2029-48e9-bc02-994e97dc1af5',
  CreatedField = '2335eff0-d30e-41e1-bb61-985858c6f9d8',
  ModifiedField = 'b9504b9b-ed51-4b6b-9f1a-eadea031f25c',
}

export enum ProductPipelineRelationFieldsEnum {
  IdField = '278d493d-9e00-0afb-3e75-f2284df51856',
  PipelineIdField = '9342ab93-1ebc-0ba8-77ca-23f342c14a23',
  ProductIdField = '4d140837-7cf9-0545-662e-c1cba7c3660d',
  CreatedField = 'c6296331-8dc4-01fe-ade8-af98d931fa20',
  ModifiedField = '5eb242d8-bdff-0cdd-b23e-b49da29b71a9',
}

export enum ProductPriceListFieldsEnum {
  IdField = 'd67517d0-cbd6-0d1e-b9a9-a20b660028d1',
  NameField = '8e6b8798-9ad2-477c-9426-f70405deaa25',
  EndDateField = 'a5576ff8-5e69-0a32-15af-c192b29fb92d',
  PeriodField = '41c94cd5-4b52-0edd-6566-5113d4aa814e',
  StartDateField = 'e914b4b2-f364-046b-a1e9-d4f5aa25dd91',
  CreatedField = 'a9fd6f86-2007-0793-b8d8-ea84a4c970be',
  ModifiedField = '1d8ece59-95e9-0a0b-042d-e37b50deeca6',
}

export enum ProductPriceListPriceFieldsEnum {
  IdField = 'd5f4d16b-41fb-0af0-2323-3402f7016f1e',
  CurrencyIdField = 'e1d66daa-5735-0a69-1f54-104f5bf303a4',
  PriceField = '6e76eb8d-dc45-09cc-94b4-c8fb33b8baf7',
  ProductIdField = '199525f8-fa75-012b-4270-a61bac154816',
  ProductPriceListIdField = 'd4df8a9e-8eab-013c-6ab5-1f71682ed33e',
  CreatedField = '834dbf88-07d2-0197-a3a4-d488ce0a3f05',
  ModifiedField = '4ab35a83-a8c2-0086-c948-3282b2f69f84',
}

export enum ProductTypeFieldsEnum {
  IdField = 'db7a7a7c-6838-0aaa-841f-078b652076a5',
  NameField = '6c34e312-72fd-053a-aea9-bdfb8dc0ab42',
  FormEditField = 'fe0f79ed-ee55-080a-845b-cf006b0f3277',
  IsReadonlyField = 'd5cba6ce-68c7-0478-ae00-304b0a8b109e',
  OpptyProductRelationTypeIdField = 'bb28425e-ca01-4b13-960e-334156234e45',
  CreatedField = '339a7510-f2b0-0b23-ae70-b4aa2bf29a41',
  ModifiedField = '511d9a7e-f9cd-045e-a82f-2f160da23467',
}

export enum ProfileFieldsEnum {
  IdField = '4e60586a-479c-09bd-66f3-983d835929dd',
  NameField = 'c971327c-0465-0942-6926-40cf4ed0999c',
  OwnerIdField = '7e4ddb35-00f8-0c92-fba9-7ddd58c40df3',
  EntityField = '2b6e90c8-e386-005f-3606-a821f05e9db5',
  SettingsField = '387470fc-e502-04ea-2d21-090e2e2d011d',
  ShareActorField = '23042eaf-7ba5-0086-b5bc-c6061f82f23a',
  ShareModeField = 'a6cf4761-ff68-0d73-890d-4de8ddb6198a',
  SharingField = '45be59fb-2888-028a-4dce-7cc0db369ff7',
  SharingClientsField = '7fc8e24c-4a8a-0b82-22cb-643b0ec1dfbe',
  SharingUnitsField = 'ceec034c-a704-0713-1f28-8c9ff832475e',
  UseLangField = '679e799e-5b64-0013-747f-727b7b52f2f1',
  CreatedField = '6d90eb7b-6cc1-0194-7daa-da9c3d0ea6d2',
  ModifiedField = '6bb8ffe4-91fc-0613-ac70-0364a24a5603',
}

export enum ProfileDraftFieldsEnum {
  IdField = '56f61a03-7672-479b-b78b-fa8391168edf',
  OwnerIdField = '4e80b90e-e1c4-457e-a4b3-1c71458f5aa0',
  ScreenTypeField = 'f6fec969-1fd4-4632-8495-a5b78428492a',
  SettingsField = '9738e8fa-3786-43e5-9b49-e13283ec619c',
  CreatedField = 'a89ce6fc-ad9b-4c4f-9a1f-9d7fcba11b31',
  ModifiedField = '1a8f0f01-5d66-4ffc-ae0b-529ef968bfe2',
}

export enum ProfileSharingClientRelationFieldsEnum {
  IdField = '39a61d54-e1a7-0dbc-2920-c304fb04facb',
  ClientIdField = '611453a0-9ea4-0809-41f7-6d25a0d4543a',
  ProfileIdField = '57af1219-930e-03e4-f448-fc5af56ec768',
  CreatedField = 'eb599354-1a94-071d-fb95-f8dc936d70ce',
  ModifiedField = '7c408398-c942-0f8f-72eb-29d00cc975f2',
}

export enum ProfileSharingSalesUnitRelationFieldsEnum {
  IdField = '9f788300-5f57-08d1-c183-7422a73b6241',
  ProfileIdField = '8d3d0948-1a67-088a-52e7-f8bfddb675f5',
  UnitIdField = 'e27c5219-510f-0b1d-bc87-180a8ae83162',
  CreatedField = '6a92821c-e739-0266-2176-f2ca65ce8f1c',
  ModifiedField = 'cf272270-6a94-0d51-add4-b630fa78f1be',
}

export enum ProjectFieldsEnum {
  ProjectIdField = '9689501a-e71d-4b6b-8195-f3873bde6021',
  NameField = 'c92f4336-ffc5-42c9-b43a-8904b7874e51',
  AccountCardField = '7004a9cb-ea9f-492d-830f-31d6c2e7fd6b',
  AccountField = 'f8dda97c-d59d-4f62-a7a2-c3eb87444ba3',
  RevenueGoalBaseValueField = 'b3456661-0290-4a28-ac53-fb0173eccbf4',
  CompletedDateField = '2c687e25-0178-4d98-a48c-d1ec9f659526',
  CompletedObjectiveCountField = '399163ed-8741-4aa1-b65d-6563a1951c4d',
  CompletionRateField = '0956776c-4f15-4878-849c-2cd4d98da356',
  ContactRelationsField = '0e63f864-fdc6-4933-8e22-afa591a73e96',
  RevenueGoalCurrencyIdField = '2a055e89-0aed-4115-a5b0-243ad129f8f2',
  DescriptionField = '8810bbb9-f43b-471c-bc07-67ada2e74eed',
  DocumentsField = '8c746853-e430-4f50-ac87-13243309422d',
  EditorsField = 'b9efec00-c850-481b-adfe-533ae5f4c86b',
  EmailSequenceCurrentlyEnrolledInSequenceField = 'e63d37ec-600f-4c95-acea-35f395c7e79d',
  EmailSequenceEnrolledByActorField = '16ca4985-2d4b-44dc-bea0-4b8a60b8903b',
  EmailSequenceEnrolledInSequenceField = '5affd211-46bf-4985-a784-6db86637d937',
  EmailSequenceLastEnrollmentDateField = 'db170f5d-0c28-4da5-a48c-05277cbc3efe',
  EndDateField = '8c95c2bc-fc1f-44ca-8c2a-6c4e89493817',
  IsCompletedField = '95ae652a-a20b-4022-b6c0-ef4b87c26858',
  IsFavoriteField = 'e65f93dd-93f7-4d1a-a6fa-9f58bc8cca29',
  ModifiedByUserField = 'd3d83dfb-7c85-4724-b3f9-361342ec9574',
  NotesField = '5b89195e-8161-4b34-9449-2e20adfb69ae',
  OwnerIdField = 'cc745b85-b1f7-4434-b64d-f0d12f5a2e2e',
  PeriodField = '83f7f600-e626-404e-9870-e009590367eb',
  ProjectTypeIdField = 'af2a4acc-6ceb-40ec-b43d-7fc1b169c663',
  RelatedActivityFinishedField = '9976e0b2-cb6d-4d1b-9707-b21294dfe042',
  RelatedActivityInProgressField = '054b35f1-3cf9-4e2c-8aa6-9081aef0e7f8',
  RelatedActivityNotStartedField = '45424019-379b-4306-80bf-42764dd6b84f',
  RelatedActivityOverdueField = 'd9aecc7e-c2a6-4aea-adb2-1b5dc750f8a1',
  RevenueAchievedField = 'e6e92693-ed19-4e0e-afab-195ecb97911c',
  RevenueGoalField = '9bf12b84-9407-41c2-aebf-20faa4b43c2d',
  ShareModeField = 'bee0b686-aa06-4fd5-bf32-5a214a0f7114',
  SharingField = '4778ba0e-5a9f-491c-94f6-80033fee6890',
  SharingClientsField = '25023a58-c253-4e75-8469-c06609dc110d',
  SharingUnitsField = '8afef534-6497-4d0a-9280-ef5fe9316449',
  StakeholdersField = '1e42edfb-b069-4a5b-ad8b-82d96ce1715d',
  StartDateField = 'b19438eb-b657-4c78-855b-b59bc99152d6',
  StatusField = '059d3490-49d6-446f-b23d-e90ed1729361',
  TagsField = 'afac815e-0dc7-4704-a9ea-ca1248c0b0ba',
  TotalObjectiveCountField = '65e50da2-8c9c-47ad-a463-c45e8ced3296',
  UnitIdField = 'b00c0c87-6e91-4672-b219-cb1d062ec230',
  RevenueGoalValueForeignField = 'a5851cbd-ee8b-45d9-834d-7695f0a4086b',
  WatchersField = '951e84bd-b9c9-43d0-b9fe-5a1d247e7732',
  WonRevenueField = '42c7ac3a-94cb-4ff6-b868-4cd460d98723',
  CreatedField = '14eec1b8-be79-44e0-93c1-5ddee5858bc5',
  ModifiedField = '2e6d0612-5b63-4fec-9db5-90b39d40874b',
}

export enum ProjectContactRelationFieldsEnum {
  IdField = 'be0cf097-8365-445b-8c09-56d16952662a',
  ContactIdField = 'fbc092cc-087d-4db7-9521-c7f7a5f1600b',
  IsPrimaryField = '9dc6bfaa-b04d-40ed-b2e2-e969a76db122',
  ProjectIdField = 'da6a2e1a-0b20-402c-8a0a-42940da85810',
  CreatedField = '28d2fc3a-5f74-498a-9c22-03c9bec21187',
  ModifiedField = 'd9f25304-4e49-446c-b5f1-88d534e9aacb',
}

export enum ProjectDataExRelationFieldsEnum {
  IdField = '2392548f-9690-4414-863f-08ed5c184fe2',
  DataIdField = '45fc977f-b9b7-4ba2-9209-19b55599b166',
  DataSetIdField = 'baa435d1-111e-48f6-9108-b4d7c2308b0b',
  ProjectIdField = 'b4358ffd-61f2-4fdf-b33d-3c58bac234ce',
  CreatedField = '0ed641c2-2ac5-4286-a2ba-e30dc1d122fc',
  ModifiedField = 'dfa15d63-8519-494f-b39d-f564813ef14c',
}

export enum ProjectKPIFieldsEnum {
  IdField = '8e019e5c-b96b-452b-b550-b15d68549580',
  ApplicationIdField = 'bee5d1f5-9627-42d8-a682-55d20cab5fde',
  ClientIdField = 'ff9035aa-e04e-4fde-91e3-c64d30bde469',
  KpiField = 'c5dff161-bdab-491f-8612-692f1013d91e',
  OwnerIdField = '23c82734-4597-4d76-a5f1-aa18e008bfa4',
  ProjectIdField = '6a9a6d41-2e47-492a-bee7-e204e7279738',
  UnitIdField = '160b990a-9852-4884-aecd-a4ac6f0baf1a',
  CreatedField = '82640154-75bb-405c-811f-4ca40a1fdfd7',
  ModifiedField = '91feaefd-af7f-4444-9f39-706c0b65d67c',
}

export enum ProjectObjectiveFieldsEnum {
  IdField = '5e4301a0-ee00-48ed-83b3-f87e4db6297f',
  NameField = 'a1d74249-5d4c-498c-8e42-a9e28317207c',
  ProjectIdField = 'f3301489-fb95-4c20-9d52-6aa6a43d060a',
  SortOrderField = '58ed1ff9-5ba5-4958-ad8f-1780e4b16af4',
  CreatedField = 'd45943de-1fe6-43ad-9da6-85e6868de81c',
  ModifiedField = 'f508fc47-ff8f-4aa7-907f-1bfc149c9dba',
}

export enum ProjectSharingClientRelationFieldsEnum {
  IdField = '19f3584b-5b76-4823-ba37-75b6749ce584',
  ClientIdField = '85dab62d-cbf8-40a1-a47f-4ebf9f7c34fd',
  ProjectIdField = 'eb74592d-1649-435f-921e-4308c9175e3f',
  RoleField = '013a4023-1cb3-48ec-930a-e8b55917f5cd',
  CreatedField = 'e89d2491-b5a5-416e-8e73-7dd9b6faab62',
  ModifiedField = 'f3e656c1-d932-4ec6-b6b8-ff3ad89d598c',
}

export enum ProjectSharingSalesUnitRelationFieldsEnum {
  IdField = 'da20861c-ec61-4524-a68f-c7e37a6aba0a',
  ProjectIdField = '12709d9e-c05f-4c5c-9bee-ac665b1423ba',
  RoleField = '3eb4b7d8-756d-498e-bf8e-4f5dcc7d9b9a',
  UnitIdField = '665da176-8036-4b4f-92a7-247b56be2862',
  CreatedField = 'ac9b9b35-f372-4082-bbf5-48c7e63d5664',
  ModifiedField = '07462781-10c5-4fe1-83ae-cfc580959282',
}

export enum ProjectTypeFieldsEnum {
  IdField = 'acd89eb8-70c1-45ad-878d-e6a3df7bd82b',
  NameField = '523d19ca-4da5-4f23-94e8-d7f793266440',
  FormEditField = '0735aaf8-4e6e-496a-8cdf-e1cff52086c2',
  IconField = '8b22c797-073a-41b1-a9eb-b07edacdf800',
  IsReadonlyField = '48ffa27f-d1b6-4ee5-8524-1269d8e3d460',
  CreatedField = 'ca2e7b46-4f0b-4f1b-b259-d12eda0a640c',
  ModifiedField = 'ee79f2b3-2c17-4cc8-9767-8234a6fc689d',
}

export enum QuoteFieldsEnum {
  QuoteIdField = 'baeef7a0-c373-4988-b87d-5673e36ade50',
  NameField = '17f80f64-f488-46a6-b33f-5cea68b3168c',
  AccountField = '83468d98-4e03-4fac-92b2-c2324f453e9c',
  AccountCardField = '5c760c8b-2a0a-4e33-bc26-d514076574cd',
  AutomatizerCreatedByProcessField = 'fddd34e9-e057-42db-b71c-2d8aafb27d34',
  AutomatizerProcessTriggeredDateField = 'c7dc20d4-8c52-434e-8efe-856113fe031d',
  AutomatizerProcessWithScheduledActionField = '5560c71d-8156-4855-8a24-2cd92d9183bf',
  AutomatizerProcessWithScheduledActionDateField = '6a0e9b33-2796-4395-8d22-d75cfc033906',
  AutomatizerTriggeredByActorField = '9ceaac5d-13a3-461a-9909-5d7ae2a411f4',
  AutomatizerTriggeredByProcessField = 'e9119da9-bc56-402c-97c4-8450f9e3f740',
  AutomatizerUpdatedByProcessField = 'e1e4ad10-0d30-42ae-aa02-861b03de5a98',
  BaseValueField = '0bed9ebb-0158-4b15-b173-4461fa0615e9',
  ContactField = '431badba-6de4-4aab-88e8-309bbb28d7a7',
  ContactCardField = '6ff8069e-6cc3-4995-abe0-ce907960bae5',
  CurrencyIdField = '06119833-7ef5-47c0-86d2-35e5ccae0293',
  DescriptionField = '2acd35c0-3c9c-4abc-b283-e72872243e4e',
  DocumentsField = 'b8334585-5fd1-4463-b88f-4471f543d5f3',
  EditorsField = '86c35a81-2b3d-4f0a-a2a1-712b1f982b65',
  ExpirationDateField = '3a653e4d-5570-4e34-9e13-f465b04bcec6',
  IsArchivedField = '42d0b9fd-af0e-4ce1-984b-85f6593cabb1',
  IsFavoriteField = '0ed4dd66-0ca2-42b7-b162-884012b5d11d',
  KpiField = '31528c13-1f17-450d-9535-a748002c5cac',
  ModifiedByUserField = '86d4f6fd-d373-4fbd-8f5d-c9bf57f72a68',
  NotesField = 'd5569abd-c306-40c2-aaa5-b982518fc7a6',
  OpptyCardField = '4e4e10a8-ffa0-4e60-8ce7-bf0608a1e26c',
  OpptyIdField = 'a143053c-4b20-4cb3-a7b5-f22420a4f821',
  OwnerIdField = '424ea64b-7894-40e1-b14b-0e5f7989d39c',
  ProductCurrencyIdField = '86d08336-5ec5-41c5-b085-76d3aea41a49',
  ProductIdField = '8db16c30-0a13-4236-a511-8abf7731d937',
  ProductPriceListIdField = '49a01564-8b28-4a2a-a662-a605365bde2f',
  ProductSectionsField = '6f203ea4-bb60-42bc-9c5f-30f32500eb66',
  ProductsField = '29ea1b41-19d4-4446-a242-528a207bec38',
  QuickAccountEmailField = '072bc414-756e-4a55-ae47-39b5415e6434',
  QuickAccountNameField = '14e722e7-0a31-4939-bc84-b7edbc1e54d5',
  QuickAccountPhoneField = 'e4519ded-5b39-4dbe-8bcf-b9d14ea91380',
  QuickContactNameField = 'c91fcc84-771d-493d-90b4-41be23549eca',
  QuickEmailField = 'c4368c77-dc1d-4be7-aa0c-0daeab00baf0',
  QuickPhoneField = 'e33138a9-f7d9-4015-ad8f-cd480b3e636e',
  QuoteNumberField = '0edf70a1-9fbf-4cc0-9762-8d91bb846ee7',
  QuoteProcessField = 'a04b5e38-2dfc-4e35-b9de-97f77d3529cc',
  QuoteTypeIdField = '74858d86-7a35-4cbe-a94e-6ce58b759d50',
  ReadyToMoveField = 'b7e38805-ae7d-4f42-9803-a9210938ce8b',
  ReasonOfCloseDescriptionField = '014aa4f0-dd45-45b4-b485-0d06afa352e0',
  ReasonOfCloseIdField = 'd17a6500-26a7-49a7-86d5-4c5d3387b30b',
  RecordUrlField = '92224589-b30d-4fba-82df-9b363e17e889',
  ShareModeField = '7cb82d39-1146-4d82-9e85-43e437f30086',
  SharingField = '293846a4-ca71-4416-ad20-ed432f1efefe',
  SharingClientsField = '991d5b6e-8968-46c7-8634-468e2a326138',
  SharingUnitsField = '58a3395e-f0ad-49be-a773-a77d1e5854d7',
  StatusField = '5ca42787-4bd2-4955-be4c-864e4ad6b22a',
  StepChecklistActivityRelationsField = 'f60fea0d-7dbb-4db0-bddd-d4399da1c573',
  StepIdField = 'bc929f70-cd74-4ddf-8a27-3300bcb0191f',
  TotalAmountField = '58e8a204-e124-4274-950b-193de27db4b3',
  UnitIdField = 'd6cbd945-cb64-48a1-a100-47d9c2d8ae76',
  QuoteValueForeignField = 'cfb76232-578c-4afc-9f90-a56db335173f',
  VersionField = 'e982bd56-ba5d-4dc7-8f31-a580db3a0456',
  WatchersField = '082ad3fd-c127-4e6c-b99f-1a90331cd3ae',
  CreatedField = '1d263108-3fb5-47d1-a866-8928aa450f90',
  ModifiedField = '9b891a54-f45b-4a38-9fad-327120e70f8f',
}

export enum QuoteAccountRelationFieldsEnum {
  IdField = '45340b48-98f7-497b-aea1-e8c2eb8509b7',
  AccountIdField = '78df10a2-dab0-4b11-95e0-8c52cbc456b1',
  IsPrimaryField = '9b12c240-5af5-4665-b311-b607ff3d35cf',
  QuoteIdField = '6c31a3e8-b903-4db6-a293-3039aa58a520',
  CreatedField = '15ef0ae2-fbd3-49f3-90b1-10546b4a9dea',
  ModifiedField = '9a672f25-7050-4d33-8e3f-58b42a2e02bd',
}

export enum QuoteContactRelationFieldsEnum {
  IdField = '9f514edb-e820-4c4d-a469-bdb48c1731f2',
  ContactIdField = '647982ab-863a-4cce-b73a-f91b26aa90de',
  IsPrimaryField = '925f8932-f94b-4b8a-8555-5741d803e341',
  QuoteIdField = '778fe502-93fa-40de-b066-6aebecdc5cf8',
  CreatedField = '2ebc1246-b8bb-4c20-9069-0469701a0ba4',
  ModifiedField = 'de67d420-6dbe-412a-ac6f-b4a0dd397512',
}

export enum QuoteDataExRelationFieldsEnum {
  IdField = '2da6694f-fa36-4720-ad2c-55dc45fc078f',
  DataIdField = '1918090a-0f0f-438b-932d-9abe845a6313',
  DataSetIdField = '9ee13bd7-57fb-47b9-83ca-c64ddc26ee4f',
  QuoteIdField = '54011ec4-c39b-4c6e-b5ed-5f8cbcd803f4',
  CreatedField = 'f50b1aba-7860-4408-aab4-4f164c66b02d',
  ModifiedField = 'c062df09-9347-49e3-9b22-92c2e6fbe351',
}

export enum QuoteKPIFieldsEnum {
  IdField = 'e07e9bfc-6c96-4047-aef0-b19d5d81daad',
  ApplicationIdField = 'c17e98aa-1090-4a5a-8acb-6a1412dd4ec9',
  ClientIdField = '4e8106d6-3021-444a-ba4c-978251ed6021',
  KpiField = 'ccef72de-133f-4735-8d19-13624d153c6c',
  OwnerIdField = '4501c585-5f80-49f8-afe7-84acb8f22b8c',
  QuoteIdField = '193a8805-8831-4646-8527-0c916064eb14',
  UnitIdField = '2f2e6ca1-505a-4654-a650-468c30086e67',
  CreatedField = 'eb96b692-523c-4a00-a723-d574f3587e9a',
  ModifiedField = 'a26fa652-16bd-481d-89ca-f88e55d897f7',
}

export enum QuoteProcessFieldsEnum {
  IdField = '70adbfa3-dbdf-410f-8c19-6176a9822363',
  NameField = '7858ea14-eb1a-4756-bf30-b579bb55b702',
  ColorField = 'd2f6ecc7-4339-4c9b-b20d-9bb7bd86cc79',
  CreatedField = '696fb9d2-0602-4034-8702-e297ffc20a45',
  ModifiedField = 'b1e70c70-6675-41ba-9db0-33bf4420f475',
}

export enum QuoteSharingClientRelationFieldsEnum {
  IdField = '6146925b-8bdf-427d-84f7-5cde60bd5854',
  ClientIdField = 'cf106d43-4355-4ea0-bdc4-c93d94082365',
  QuoteIdField = 'b4eebcd7-1b22-4d89-8fca-56d4481d0dd5',
  RoleField = '76d65485-cb44-4674-80b3-3cd8ccc003d4',
  CreatedField = '4110bd7c-9ee5-4809-bd21-804ff1cbfa05',
  ModifiedField = 'c7313b8e-3d13-4906-a41d-0eab8dcee755',
}

export enum QuoteSharingSalesUnitRelationFieldsEnum {
  IdField = 'bd4e9818-7ce5-4451-befd-894e1a07e69d',
  QuoteIdField = '5aea889a-7620-4b83-bfb9-5846d05a845d',
  RoleField = '2b9b806c-1551-4931-92c9-cfdac07a5491',
  UnitIdField = 'c011b473-6a05-4002-aa55-4875dc2c5a21',
  CreatedField = '5f67e1a9-7052-4f59-87e0-a96a9359430e',
  ModifiedField = 'f8f92ba8-675f-4338-af33-cdc9f16ed504',
}

export enum QuoteTypeFieldsEnum {
  IdField = 'ef1f4934-0799-4a10-9852-fc896593e62c',
  NameField = 'f17ab9f9-f81e-4b5e-a086-9f10e89c5781',
  FormEditField = 'a6a53725-aa5b-42d1-a84d-02f9633dc518',
  FormLostField = '1ea327a0-4e8a-4d10-9468-f3f6c17c6fda',
  FormLostEnabledField = 'c9226f31-b302-4161-9888-239177d9e2a3',
  IsReadonlyField = 'e3aea7a8-a445-4be8-9ca3-11bc32bfe1d9',
  QuoteProcessIdField = '5f181b41-82e0-4084-8834-67bcbea36e91',
  CreatedField = 'c02e973d-4366-4de6-8942-9cdd93b12b69',
  ModifiedField = 'e29fd476-2342-461b-a67f-23b8ca436070',
}

export enum ReportFieldsEnum {
  IdField = '51022e25-0fa7-0a0d-ec4a-87bccffaf1e9',
  NameField = 'a0769b95-07dd-09f8-50ac-d16ceba93055',
  AccessLinkField = 'ab0cd849-2e89-47c6-bb2e-55b4c9433551',
  DescriptionField = '20ac81f4-2d13-4da5-86c6-fbaf4cfbac4c',
  OwnerIdField = '4b81a8d7-c1e8-027c-4857-32492713a2a1',
  ReportsFolderIdField = 'bb0aedf5-24d0-0447-3d49-fd89c396056f',
  SettingsField = '31738290-f73e-0deb-c310-a10e6b1ab68b',
  ShareActorField = 'b5f5291b-5633-0586-8c33-8ff224f6a83c',
  ShareModeField = '17634fa8-9cea-02af-2c50-e08fc7a45c7b',
  ShareModeParamsField = '4b531465-9f10-0945-a2d4-24024613afb1',
  SharingField = '50f56dd3-1bbb-0588-cf78-be57801feaae',
  SharingClientsField = '6f4eb73e-9016-0e41-5841-9ee68bb3e9d3',
  SharingUnitsField = 'fe960f35-659c-07d8-87ed-70a401856e93',
  TypeField = '841ca84d-e6f8-0860-9e3e-35e69646d108',
  CreatedField = '351fbc28-5af7-05b0-3f63-43b7ea3c2d42',
  ModifiedField = '943f32f1-5c9f-06b0-f9c7-b6d1f416bd69',
}

export enum ReportFolderFieldsEnum {
  IdField = '7fb602c9-323f-04fe-9d1d-0aab5de6c7ae',
  NameField = '52017b36-f5f7-046b-9bc9-eed51e62001d',
  ParentReportFolderIdField = 'b79f012b-500d-00bc-c0ac-d71502794b6a',
  CreatedField = 'd300ebbc-986e-08b1-f7c4-9dbba4bbc856',
  ModifiedField = '168b5f19-c188-045b-61af-a5df0420d8fc',
}

export enum ReportScheduleFieldsEnum {
  IdField = '5f0e14b1-4b78-4bd3-ad83-49d154a2a5ec',
  DocumentsField = 'bd65963d-3d49-42ad-8866-c034021c48c3',
  OwnerIdField = 'b6bc465c-7f29-4135-9163-5046c69d7add',
  ReportIdField = '8cfa57bb-02ee-4b3f-aac2-b8d8553f583b',
  SettingsField = 'bc454df0-d6f4-43ec-981e-94ab01a1f92b',
  CreatedField = '439eb5cf-4921-4e59-99c8-5cd77aa30cbe',
  ModifiedField = 'e3280377-4cf0-4744-9d97-50528f8a5753',
}

export enum ReportSharingClientRelationFieldsEnum {
  IdField = '4b8c9ec5-c9b2-00df-e588-d20e560a70cb',
  ClientIdField = '0b3eddd7-7dbc-0632-b8fb-57698cbaa66f',
  ReportIdField = '73ffe5aa-2395-091d-bc05-0ca5564907b6',
  CreatedField = 'be27daab-243a-059d-a211-3e298a7b9e6c',
  ModifiedField = '50fa78ec-eaf0-052b-f5df-23e76e8f2a6f',
}

export enum ReportSharingSalesUnitRelationFieldsEnum {
  IdField = '82d6db6d-629f-079c-c285-7e238c360099',
  ReportIdField = '8ff9bb22-8ec1-0281-4555-655ea5a714a5',
  UnitIdField = '12f39544-fb95-0c6a-edda-ea90162eba00',
  CreatedField = '19c7f4f5-ed34-0f98-dc91-eff44c8809ad',
  ModifiedField = '89ee2b68-51bc-090f-d9f3-2942dc52ace4',
}

export enum SalesRoleFieldsEnum {
  IdField = 'c29ff427-4a27-0a9e-54c2-76202285bb19',
  NameField = 'b5aead3a-7831-03b0-9cfd-6799a447d3a5',
  ColorCodeField = '296468c7-846c-0ff3-3308-798556edd824',
  CreatedField = 'f577b0ed-0d4c-0890-c00c-21077a6e073b',
  ModifiedField = '25013577-cb7d-029e-33ae-58e2b250bbd6',
}

export enum SalesUnitFieldsEnum {
  IdField = 'ed655b9c-e4df-0836-58ae-7f86a870850e',
  NameField = '2fa7cdea-432e-0c3b-390e-aa6bf64d4a79',
  ParentIdField = '03fa7e00-ba99-0d92-9e7c-d8f95bf8640c',
  PictureIdField = '853948b4-0610-02db-d8e6-e2dcdea7200d',
  CreatedField = 'b9005487-8c55-0b8a-f55b-bb84a07cd0c8',
  ModifiedField = 'b6ddae70-e4c5-0d7e-309f-2a8a07db724f',
}

export enum SalesUnitClientRelationFieldsEnum {
  IdField = '6a3362ce-074d-0ccc-c7ee-f8d8f27b5918',
  ClientIdField = '6a5bc636-f161-0fbb-d49c-a59f6b4edea9',
  UnitIdField = 'cc581cef-8d43-06cb-9507-1a4eb2b1f42b',
  UnitRoleField = 'c2b74403-a6bb-02ec-41cd-7cb293c0068f',
  CreatedField = '1bd0f824-5280-028c-7d62-a7e2342c55f6',
  ModifiedField = 'caafb3ff-04da-0d2c-48be-7b9d2f8443a2',
}

export enum SpaceSettingFieldsEnum {
  IdField = 'f7e39df5-5fa1-4a97-90a3-c53f05976751',
  NameField = 'cd01eae7-e249-411c-856e-3054ae32f08f',
  ValueField = 'a761e23d-4f64-4938-8e80-a6e16a1b94de',
  CreatedField = '368e53b8-e7cd-4c63-bc4e-af95abb02fe0',
  ModifiedField = '8e48bb1a-f533-4371-a950-f1213296e107',
}

export enum StepFieldsEnum {
  IdField = '3f04c827-5310-0b9a-ba73-de1b73f1dc6f',
  NameField = '63f7f426-9729-0fed-7037-f4a9d569d2a0',
  DescriptionField = '19741696-4d60-4d19-a972-7776e3f20bfb',
  DocumentsField = '17f39422-18b5-055b-8710-0ff69124cd68',
  HasReadonlyFormField = '8db34458-183a-4c3e-aa56-258a914aec5f',
  HasVersionIncrementField = 'f68578b8-e777-4b19-8af1-d323ff90a080',
  LeadProcessIdField = 'd70bfdf0-c4a9-4edc-9439-1220ed9c1c2d',
  StepPercentField = 'a76123a0-9837-035a-f18f-72c8106c6ab5',
  PipelineIdField = '1e2f1cf1-efae-04bf-6202-8845f25ec4c3',
  QuoteProcessIdField = '814dcdc9-330f-4036-880d-8eaa636fb049',
  RelationField = '6411768b-b8dc-4808-a81b-6cd2523ed617',
  SortOrderField = '91ff3df9-9637-08e3-ddff-7c89cef8d9bc',
  StepChecklistsField = '2624e4b3-1676-48ec-a5d9-dd5bb9f5d600',
  TimeframeField = '70ae40bb-5b97-0fee-f3ac-c9bb459f6a6b',
  CreatedField = '203e573c-776f-0464-7de5-ab5ad8184fb8',
  ModifiedField = 'a6a22631-ebb9-0fff-bfb5-6c17d7d64396',
}

export enum StepChecklistFieldsEnum {
  IdField = '7d14aab1-1230-091b-27a0-52c38cb5918c',
  ActivityTypeIdField = '03b2fcc9-7bdc-02e5-4724-2fe3f4e966e2',
  CloudObjectFormatField = '3c46d9fb-b8ac-40a9-aa6b-7057b4e14a46',
  CloudObjectTemplateIdField = '2e7ba324-f689-4133-8041-e84e80b39643',
  DescriptionField = '7726cf92-eee6-41ec-a258-0ba3fb9489e1',
  EmailSequenceIdField = 'ad453584-fbc1-46cf-a0a6-299da6adb9e9',
  FieldIdField = '8c320d7a-097d-0211-3277-4ae1d584dc2a',
  ItemTypeField = 'ef07989f-8aac-04e7-2b9f-845a2daec9bf',
  ItemValidationField = 'bb4698c4-f6c8-4aad-9fa1-843cebe600c9',
  LabelField = 'ef5ef8af-7090-048f-e27a-784fbf0f809b',
  LabelUseLangField = '5baaa4f0-1cdc-4a39-a2da-388fe177d907',
  ProcessIdField = '83310d56-595c-4466-a01f-f0260e26f165',
  SendEmailFieldIdField = '6e3c269b-25f9-4d57-b73b-7b2b80b1def3',
  SendEmailTemplateIdField = 'c145b3e2-9566-43a7-9ef9-18b271845cdd',
  SortOrderField = '8b6f77b9-f677-0c86-ad60-f3f35392b5f9',
  StepIdField = '18d7fa8b-4401-0e61-dc6b-4dd0bbbf2a54',
  VisibilityConditionField = 'f5557f35-bca1-4d18-8dcc-04fb91e59c6d',
  VisibilityTypeField = '9eb82250-782f-4c21-b288-66b551cbfdf9',
  CreatedField = 'f37a337b-445b-0500-6aff-a98e1dbe1b6e',
  ModifiedField = '9702baef-39f7-0902-eb84-6c420a6dd5bf',
}

export enum StepChecklistSalesRoleRelationFieldsEnum {
  IdField = '2568f1e7-6108-40fd-ac42-7c827617b944',
  SalesRoleIdField = '3b17ce51-f936-4333-91a7-20ac19dbdec3',
  StepChecklistIdField = '4f83bfee-73fd-4ebb-acdc-f5b0f86883d1',
  CreatedField = '50f685df-248f-4259-a382-919cbecb3bf8',
  ModifiedField = 'eccc9b6f-7252-4877-8601-83e5f23a1d01',
}

export enum TagFieldsEnum {
  IdField = 'c4208bdc-170d-451e-bc4c-ce12d4d93e1d',
  NameField = '2b4dc28c-9899-4173-9e98-6dc4e45e6ad1',
  ColorField = '4b5c685c-ff43-493a-8675-ba3aa7847618',
  CreatorIdField = '337d0d10-475b-45db-bac5-cf7c8b72d35b',
  SupportedEntitiesField = '81a62504-ce9a-45eb-85ac-f311fab8be76',
  UseLangField = '77beea9c-c9f8-409a-a0c7-2cde5a065591',
  CreatedField = '92519cd0-bae4-4b9a-b1a8-bfc9b35d6df9',
  ModifiedField = '94b2ce85-38d2-4d17-874f-5efe92998f5b',
}

export enum TagRelationFieldsEnum {
  IdField = 'b299ff2c-0165-48f4-935c-5a17de1abe90',
  AccountIdField = '229517ee-b480-42f2-a71e-bf21fc84af2f',
  ContactIdField = '4eb9ce39-65f2-4e99-9b6d-78a7249d3ddb',
  LeadIdField = 'ce2e680e-7114-4657-9067-f855e589d041',
  OpptyIdField = 'be395c4c-3b1c-491e-bd24-4f41fd10d257',
  ProjectIdField = '34bd57b3-ec2f-4cf8-9deb-6e8c2f10d976',
  RelationField = '29976366-7ff7-4b3b-a08f-058ba8e91983',
  TagIdField = 'ab2906a9-5943-430c-805b-c7c8129e08ff',
  CreatedField = 'aaa45fa3-db29-46fb-83c6-9ce31625c362',
  ModifiedField = '24a3aeaf-d45b-474e-89d5-61cf26dca61a',
}

export enum TargetFieldsEnum {
  IdField = 'e568cffd-43f8-4a2d-aa80-1a53289e783c',
  NameField = 'df2b5c18-cfe5-4df2-a3f4-be140b46726f',
  CalculationTypeField = '21a634cd-5086-4c31-bb24-fedb59448a8f',
  CurrentTargetValueField = 'd5bd5e09-f79b-4b2d-952f-9904262dd1e4',
  DaysLeftField = 'be766a65-ffe8-4d86-94b9-66be104e8ef4',
  DescriptionField = '5730be6d-9818-4069-b81a-9a8bbc464323',
  EstimatedTargetField = '2b2d4b83-3b13-4248-8c86-40f92fb02c59',
  FinishedAtField = 'ab870da2-4741-4d5e-8c2d-07526df4c2d0',
  FinishedDateField = 'e82699f0-860c-4329-b09e-f5dc39bfe20d',
  GroupTargetField = 'fb4c3d69-c7be-43bf-9c36-b9d64e1f2cfa',
  IsActiveField = '8c55d012-0024-4384-9c9d-2e59e32d739c',
  OwnerIdField = '21eb7cf0-ff64-4578-ac1b-63419061e1c6',
  PeriodField = '79005a8e-633a-4236-876c-b1627fce047e',
  PeriodFromField = 'd50d4c08-5839-47fa-9c15-e4264bf61669',
  PeriodToField = '93fc93c8-863d-4476-9fd0-d1e39f8e6363',
  SettingsField = 'fc0333d5-3e5e-4587-9f5a-ded329ce0887',
  StatusField = 'ae3dbb53-8d91-4650-ac89-bba136d696fe',
  TargetField = 'b6fdad4f-8e2d-440a-9966-73f0e7e177f1',
  TargetClientIdField = 'c7738a35-de2c-4482-8ed1-41aa5bb60953',
  TargetsField = '9bd93172-21c4-4be9-9621-f3c6b2c5d2b1',
  TypeField = '75b0f255-5133-44df-96a3-2d3ea9268534',
  CreatedField = 'b7e0c47c-6dbc-4f12-854e-d8e79ba47c87',
  ModifiedField = 'ab563501-026e-4ba0-99ef-5ab852209e34',
}

export enum TargetRelationFieldsEnum {
  IdField = '23ff0afb-25e4-4328-890e-a0fe7aabfed2',
  GroupTargetIdField = 'e01ff592-d9fa-4679-906a-48a177f8b3c7',
  UserTargetIdField = '318bf6be-471a-4c30-b282-7a6890f42cca',
  CreatedField = '446c81f1-83c7-49ad-bf72-b2048a26be95',
  ModifiedField = '33c92cb1-d2cb-40ed-b03f-8c7265775237',
}

export enum TaskFieldsEnum {
  IdField = '8fbad2ba-396e-06f6-692f-50081cb804f6',
  SubjectField = '7a49efbd-6108-02f0-fa4f-b5b1c6ff538a',
  AccountRelationsField = '80b335aa-bbd6-06f0-c3b8-4c71b841a1d8',
  ActivityStatusField = '67de2d2a-1f60-0d02-f6d8-09445f44f4e7',
  ActivityTypeIdField = '86a503bb-304d-0b2c-3390-1b34a9b1b75e',
  AttachmentsField = '76e87133-6c94-0f73-8286-ca4535b763b3',
  AutomatizerCreatedByProcessField = '82f5a224-8335-4c64-bd39-6586af9324f5',
  AutomatizerProcessTriggeredDateField = 'd84c838e-9175-4025-8586-cd1c44d2fb71',
  AutomatizerProcessWithScheduledActionField = 'e6ab3f31-94f6-4442-a94a-acb80c4e7f6b',
  AutomatizerProcessWithScheduledActionDateField = 'd28ff944-4307-49e0-b695-7e48b4174979',
  AutomatizerTriggeredByActorField = '560fc787-3a69-461b-9a30-7c6cb21abd6a',
  AutomatizerTriggeredByProcessField = '9bda721b-5940-45ec-9906-c69bed3d2db2',
  AutomatizerUpdatedByProcessField = '198b9551-b32a-485e-94d7-9f4da0fda85e',
  CallDurationField = '12ec7aea-2069-4e85-a11d-672093e3d22b',
  CallOutcomeIdField = 'a7e90685-5831-4f29-b94d-e3edc9797b55',
  CommentCountField = '78d4c8a0-5e9f-4d30-b6ac-6e806fe55d2a',
  CommentsField = 'f67e18d6-9ffa-01ea-5032-74b8ff6aabd7',
  ContactRelationsField = 'acd080a3-21c7-03b2-154d-39b2f1b8fb16',
  DescriptionField = '27244d6d-0e07-0b2f-2158-9aabf46ca612',
  DueDateField = '7a6f5a95-d99a-012e-2d42-cc89fa301092',
  DurationField = '3d70e7a4-5e50-4bd9-baa7-69320bf9b60e',
  EditorsField = 'b442029e-344e-0574-fb07-5e35f5ad88d5',
  FinishedAtField = 'ed1cde51-052d-02fb-2f12-309e88ea706f',
  HasRecurrenceField = '1993cffd-a1a1-0c04-5e84-a0fb73534b76',
  HasReminderField = 'a38a4f9a-3233-0375-09a9-ff3dde1feb62',
  IsOverdueField = 'ed31f0ba-31b2-0985-d1bb-6f403dc30514',
  KpiField = '4a72a2bb-e673-0a4c-63b1-e7c185e6aef7',
  LastCommentDateField = '5dd00695-c486-0945-c5c6-cfdb19548d28',
  LastCommentOwnerField = 'b72985d1-d323-0136-fd1b-6c8750e82ce8',
  LastCommentSubjectField = '81058495-a06e-06fc-23a9-52a7d8478dc1',
  LeadOpptyRelationsField = '1f9c3c3f-c712-0c79-e51f-9bc2b74746b5',
  LinkedEntityTypesField = 'bb0c2416-e599-0be2-546c-f2d9a0af14ce',
  LinkedItemsField = '81fa3330-9463-02a3-710d-d63143eadfaa',
  ModifiedByUserField = 'b46a1aad-b314-46de-b6a5-e2594ef9209b',
  OwnerIdField = 'eca27f61-7f7b-0d96-979d-1d879fbeaccb',
  PeriodField = 'b8f5bd8b-7a9f-000c-a544-b63dab2c61b2',
  PriorityField = '594f1404-d7e5-0f9c-00ff-9fdf5a5ef03b',
  ProjectRelationsField = 'e1e80ee9-af3a-423e-9b27-e3451de52afe',
  QuoteRelationsField = '89d6ca1b-e14d-4895-a8f3-a9a4cbead22a',
  RecordUrlField = '1fbdbb08-6aa3-468b-ba54-8b76b2604457',
  ReminderDateField = 'bb22b90a-e0d2-0134-2228-7ab4b9a1d3df',
  ShareActorField = '1c6a6d9a-b741-03ad-ab7f-c78cb7124b61',
  ShareModeField = '813d20ac-4298-0a3f-0249-c4e836cb8739',
  SharingField = '041fdd04-678b-04cb-5074-5f0eb70fe44c',
  SharingClientsField = 'b0218ee1-abaf-0a50-795a-ebf87e656c77',
  SharingUnitsField = '146192f1-538c-0d27-341c-fed345b81c49',
  TaskStartDateField = 'f323278b-425b-4ede-bd15-90c5081e436b',
  StatusField = 'd3baa17e-b292-03ea-336f-c37d729fc3f0',
  UnitIdField = 'cc8f44f7-6aef-09b5-3de5-981e4670900b',
  WatchersField = '7462580a-470a-0979-d8e9-ef923a04381e',
  CreatedField = 'd63cf720-bf15-096a-4d2f-4fc32738fc01',
  ModifiedField = 'ef3ac776-d279-0d08-c85b-f32f1c53dcea',
}

export enum TaskRecurrenceFieldsEnum {
  IdField = '6f824ed1-2070-0264-6e81-b260dd916999',
  DayField = '32927542-2368-04c0-e089-dea66a6bc23b',
  DayOfWeekField = '28854b8e-83db-033f-934f-8bf916c16531',
  EndDateField = '60306288-fe0b-06e9-26aa-907c4d434ceb',
  MonthField = '634835e0-6a6c-0fcf-ef12-828bcceed731',
  OccurEveryField = '33b6ec69-1d0e-0fec-5cf0-79aa42388a4b',
  OccurrencesCountField = '3f159a39-6760-000e-d43b-5b0ae5351fb9',
  StartDateField = '080e7621-e77a-09be-08e4-5913f51e2686',
  TaskIdField = 'c75a069e-6f38-01cf-d7a2-b4187dbe3b90',
  TypeField = 'd64bf27f-0f05-0f0f-718d-a1e8c3b4d6e9',
  WeekField = 'd4b371a5-a65c-00f3-516c-15c54d2fbcc6',
  CreatedField = 'e59e992f-9908-0108-0395-ad590e9f8955',
  ModifiedField = '8f30f17c-76ed-00c8-a218-a4764a4e62cb',
}

export enum TaskReminderFieldsEnum {
  IdField = 'b4dece32-0667-0424-4772-5ab609cb5c16',
  OwnerIdField = '739a8871-2b5e-09a4-fc13-b45f5939af47',
  SetDateField = '94045aa0-a615-0355-fc23-8fd2639b7dde',
  StatusField = '998605eb-b51a-01c4-4e52-b9c37e68afbe',
  TaskIdField = '593c5ecb-e87f-0843-f65a-b4428590dab3',
  CreatedField = '9028c26d-041d-0136-e329-ffc59551181c',
  ModifiedField = 'eff5adca-8373-02cc-8da1-4f2c6d0d801c',
}

export enum TaskSharingClientRelationFieldsEnum {
  IdField = '62381dac-049b-081b-b262-4621bb3e7308',
  ClientIdField = '21dab86e-6db1-0e77-296a-365998b7d104',
  RoleField = '2ed88cf2-951b-0676-c573-b9cbed6c6b09',
  TaskIdField = '851e8a60-aa2d-02ef-2b37-99a3a1d66114',
  CreatedField = '667cab00-a96f-0256-e776-f4a09ecaecb3',
  ModifiedField = 'b83edf9f-09ad-0f79-a291-ab72c8e234f5',
}

export enum TaskSharingSalesUnitRelationFieldsEnum {
  IdField = 'f980f43e-9218-0071-be14-339ac1a1977b',
  RoleField = 'fc61d08e-465b-00ec-4dec-ab4c7e389f45',
  TaskIdField = 'd5e097c7-a81c-0e5c-5517-be0021dafa76',
  UnitIdField = 'cbc6f57e-c9e3-023a-7a49-0d898b66108b',
  CreatedField = 'e95c8d91-7692-0338-d18e-ff64e57fc626',
  ModifiedField = 'c43ecda8-e57d-0ae5-054c-58787e60f201',
}

export enum TaskTypeFieldsEnum {
  IdField = 'be3404c7-d6e3-0d26-9693-f3a2f0a6ce91',
  NameField = '766c247b-14d8-094e-68ea-2f247d0b87d1',
  FormEditField = 'd7e62f46-fc79-0f94-11a6-dffd19c6f96f',
  IconField = '69bfa65b-1881-0ce6-999c-1925f67424a1',
  IsReadonlyField = '210908d9-677c-05fe-16ee-7e882b68da4a',
  CreatedField = 'b036c9ed-9766-075c-26bf-463bc3a4d349',
  ModifiedField = 'a277ed55-ce13-0492-39f1-b3eb28e4cd92',
}

export enum TextMessageFieldsEnum {
  IdField = '03467e92-557c-4c64-bd8c-21667f4878d8',
  ContentField = 'ded9c99d-5b84-49fc-8c70-d4c9c3a0eeaf',
  DatetimeSentField = '905a32ed-10ba-4dee-a5b1-89a18a44133d',
  DirectionField = '84fffc98-6029-4385-bbe0-33d0d503e692',
  MessageSidField = 'bdb11b88-cba6-48bd-8cfc-456a5df2a123',
  StatusField = 'd6de41a1-21e6-4617-8031-ab5e67e4c5b1',
  TextMessageConversationIdField = 'f7905d49-c4fb-4699-9e80-59fc4055e290',
  CreatedField = '1551ce37-ff2f-449f-9a47-4aac287d9bca',
  ModifiedField = '64fa5632-3452-4457-809d-39d6d9ac23ef',
}

export enum TextMessageConversationFieldsEnum {
  IdField = 'd34296b6-1ef7-481e-9606-f88c4f2f7a8e',
  AccountRelationsField = '6ddf6967-a13c-47f1-a745-0d2570c8502a',
  ContactRelationsField = '47589133-dc2a-4db6-9b08-0f2dff5e9b59',
  LeadOpptyRelationsField = '5e087e4c-7733-4b24-b0dc-99994706efea',
  LinkedItemsField = 'ad80d656-5ba7-4df2-b10e-c7f0d65f0f79',
  MessagesField = 'f1ce4fc3-3c5e-4dbb-8d3f-a9cf103c0996',
  PhoneIdField = '3a7e0b6c-f249-4275-899c-434e5e3c2ca4',
  ProjectRelationsField = '88172252-b615-4d48-ae22-5e103c434e20',
  QuoteRelationsField = '3962f20a-b8b7-42e8-a6f7-a468022ff340',
  RecipientAccountIdField = 'b045da4e-ce11-4b89-b2df-c258ae7a65f1',
  RecipientContactIdField = '897ba02b-d4ce-45b5-9454-d131e71603f0',
  RecipientPhoneNumberField = '8d9ca9e0-e6ae-4fab-a2e4-2d9def20b2e9',
  RecipientRelationField = '2beb0cbe-f9dd-4cde-82fe-5aca051751c1',
  CreatedField = 'ab4a9053-e3d8-4c0a-bac3-a9da8697e8ee',
  ModifiedField = '49ba7013-53bc-4609-bf2d-07c4dcc5cffa',
}

export enum TextMessageConversationRelationsFieldsEnum {
  IdField = 'c05cc358-03e9-4716-99bd-e083c66ab68f',
  AccountIdField = '381b8c74-d69d-4ec5-a279-4d2cab380398',
  ContactIdField = '7b95899d-3f5f-4ab0-a7c2-22258d042b2e',
  LeadOpptyIdField = '47099c02-cafe-4a82-87d4-4c7441938964',
  ProjectIdField = '917af50c-c393-4fef-a9d7-336251a72035',
  QuoteIdField = '8127dfe2-7507-402d-8a8a-49cf2215f933',
  RelationField = 'e544be6d-401c-401e-b2d8-f93678b8b81d',
  TextMessageConversationIdField = '7b945202-a74c-4304-b21d-f70bec025963',
  CreatedField = 'e2dfda5c-f83b-4607-b21b-c332d71f7a90',
  ModifiedField = '405db5c7-921b-4436-8e91-aa91efce0f02',
}

export enum TimeframeFieldsEnum {
  IdField = '125ca367-2c50-057c-9736-b13f06c77f6b',
  LeadOpptyIdField = '0e3ccfba-42c0-0e70-9390-03cb389c33d3',
  StepIdField = '7ed9ac01-d797-031a-cb78-c2dec7b4beed',
  TimeframeField = '861bf55d-d6c9-0d93-05de-b7087b002201',
  CreatedField = 'bdd047e0-df11-0df5-2dbf-37907cbb6c05',
  ModifiedField = '6c0f3693-8808-0929-1e39-e8b0d4cc7521',
}

export enum TranslationFieldsEnum {
  IdField = '54caa16f-d951-49ec-8136-a9f8fd275fe4',
  AccountTypeIdField = 'dfb3f3a5-d912-4ff3-9cc0-34ab951a0231',
  AppointmentTypeIdField = 'ec67eca5-8288-4097-84fc-ee7a2cbe5a53',
  ContactTypeIdField = 'cf6e1e15-df2d-4e4a-a742-46d1319fd445',
  DataIdField = 'ed1dda99-c3ce-42ed-8049-527e1efcf172',
  DeDeField = '71a674c9-8445-4ffd-b0da-64582160ac83',
  EnUsField = '07e69034-d21e-4f32-8904-1d64bf3a91d6',
  FieldIdField = 'cc2d0726-6b3a-41f8-bfcd-aa5ee38d2183',
  FormTypeField = '69bcfd38-bccb-4bd1-91ab-5bc8e7b8c045',
  LabelTypeField = 'a85d079a-e18d-4f71-8a5b-8bde5cdacc22',
  LeadTypeIdField = 'ffb863b9-188c-4031-91ab-4f3550bb2230',
  OpptyProductRelationTypeIdField = '3e87584d-f356-4884-ab21-3b6da5e36065',
  OpptyTypeIdField = '114b7062-2996-48db-b627-13c54a82014e',
  ProductTypeIdField = '243d2a7c-b2a0-4ce7-bea9-a50e3ae38ed6',
  ProjectTypeIdField = 'f603192c-04cc-4963-817c-18390e7c4846',
  TaskTypeIdField = 'c89d9eb6-92c3-4021-8075-ce268641f45e',
  CreatedField = '357cc9a0-9863-42d9-bf84-e2055fb0b690',
  ModifiedField = 'de40741a-56d2-4785-88d7-11becb92f32a',
}

export enum UnsubscribedEmailFieldsEnum {
  IdField = 'a7c6dd77-3724-41ea-bf64-4114035ab4ce',
  EmailField = 'cf44c7d9-42e3-4167-9133-362adbf72383',
  CreatedField = '842138a0-b01a-467a-82a4-1cf4fd8d7682',
  ModifiedField = '3823cc33-48e3-4ff3-895d-258e91a154a2',
}

export enum UnsubscribedPhoneFieldsEnum {
  IdField = 'bd4cc928-858e-406b-ba23-2ddf216cf59d',
  PhoneField = '230a6701-798d-4a80-8a21-fcf5d70383d2',
  CreatedField = 'f5b2b73d-6442-4a9d-9282-9935bbf1d9aa',
  ModifiedField = 'df4796ca-27c9-4617-a03c-2f39b82735eb',
}

export enum WebhookFieldsEnum {
  IdField = 'c40b3f69-6f31-4d32-9fec-746956502d29',
  ApplicationIdField = '71e97e53-62b0-44a4-8b64-8d62b2f23318',
  ClientIdField = 'd6f508f3-912e-460a-bbb6-482f1648e4c4',
  EventsField = '5ca3413f-90ff-4ac0-b562-9c528ae60975',
  InsecureSslField = '97677fa1-7fd5-448d-a37d-f469c5cab4d9',
  OptionsField = '85c6d974-b73e-43ac-88ff-a46a71dc633e',
  SignatureField = '3c77604a-d2a3-4b3c-81dc-ad9135f6d021',
  UrlField = 'e8c85e88-ddb3-4d22-b5c8-44bf4a15a4af',
  CreatedField = '2815cc77-48c5-42c1-a061-0991e90cd4ac',
  ModifiedField = '0e7cbb5f-a1ea-4f91-b072-9bb979c6c907',
}

export enum WebhookEventFieldsEnum {
  IdField = '323c9b50-74b7-4e83-8d94-381864b8a8a7',
  EventField = '62365425-8eaa-4335-8fec-34b389d5e47a',
  WebhookIdField = '6d4e2310-7f84-4232-a505-e569d7e62138',
  CreatedField = 'e8a0378f-5872-4169-a094-81dc449fb1e6',
  ModifiedField = 'f1c8d70a-e310-4f2e-a538-85e16881be3e',
}

export enum WebhookQueueFieldsEnum {
  IdField = '4baba8c9-61fb-4fbf-a9ec-33af436fb2d8',
  EventField = 'a1f6d65e-8a18-4145-9f68-329c4daa895a',
  FidField = '6ae98266-c7de-40da-8b5a-871cad672608',
  TableNameField = '5424997e-b467-4b6f-80d4-143fb8568fdd',
  CreatedField = '2b02b4a9-85db-4d09-afa2-fcec8da44526',
  ModifiedField = '52c08738-868d-4f5b-aa80-e1e0bee9ae9a',
}

export enum WebresourceFieldsEnum {
  IdField = '28e70286-7c27-0ea7-bf25-c4e7e69cd4e3',
  NameField = '25ac0871-2201-0030-9955-2f527b62daa1',
  DisplayParamField = '8541c338-52f8-0f18-6e50-a2b7a617dccb',
  EntityField = '773768be-ed8a-07b8-42d2-ffaddcb131a7',
  RequestMethodField = 'c3abc877-b091-038f-ab7f-7b1caf21138c',
  UrlField = '9d03292e-46a8-0573-29f7-eefc9572393f',
  CreatedField = 'f91a4845-6273-02ae-0570-b1c95ea1c991',
  ModifiedField = '52024788-6b4a-04e7-8c83-10e020ae9850',
}
