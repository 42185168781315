<ng-content></ng-content>

<div *ngIf="toolbarActions"
     class="actions">
  <ng-template [ngTemplateOutlet]="toolbarActions"></ng-template>
</div>

<div *ngIf="toolbarControls"
     class="controls">
  <ng-template [ngTemplateOutlet]="toolbarControls"></ng-template>
</div>