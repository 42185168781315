import type { PplOptionListOption } from '../option-list';
import { PplOptionListWithFilterComponent } from '../option-list-with-filter';
import { PplPopoverDirective } from '../popover/popover.directive';
import { PplUiIntl } from '../ppl-ui-intl';
import { NG_UI_THEMES, PIPELINER_NG_UI_THEME } from '../tokens';
import type {
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef,
  ElementRef,
  TemplateRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  FormValueControl,
  KEYCODE_DOWN,
  KEYCODE_ENTER,
  KEYCODE_UP,
  MemoizeLast
} from '@ppl/utils';

@Component({
  selector: 'ppl-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplSelectComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplSelectComponent implements OnInit {
  @Input() placeholder = '';
  @Input() options: PplSelectOption[] = [];
  @Input() categories?: PplSelectCategory[];
  @Input() displayCategoriesSidebar?: boolean;
  @Input() displaySearch = true;
  @Input() value?: string;
  @Input() hasEmptyOption = false;
  @Input() emptyOptionLabel?: string;
  @Input() itemTemplate?: TemplateRef<any>;
  @Input() selectedItemTemplate?: TemplateRef<any>;
  @Input() maxContainerHeight = 141;
  @Input() optionTemplateRowHeight = 28;
  @Input() forceAllOptionsVisible?: boolean;
  @Input() popoverWidth?: number;
  @Input() categoriesSidebarWidth?: number;
  @Input() calculateDropdownWidth = false;
  @Input()
  @HostBinding('class.ppl-input-container--error')
  error = false;
  @Input()
  @HostBinding('class.disabled')
  disabled = false;
  @Input()
  @HostBinding('class.fill')
  fill = false;
  @Input()
  @HostBinding('class.big')
  big = false;
  @HostBinding('class.has-icons')
  @Input()
  hasIcons = false;
  @HostBinding('class.has-colors')
  @Input()
  hasColors = false;
  @Input()
  @HostBinding('class.readonly')
  readonly = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('selectInputContainer', { static: true, read: PplPopoverDirective }) popoverDirective: PplPopoverDirective;
  @ViewChild('selectInputContainer', { static: true }) selectInputContainer: ElementRef;
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild(PplOptionListWithFilterComponent, { static: false }) optionList: PplOptionListWithFilterComponent;

  search = '';
  focused: boolean;
  opened: boolean;

  searchPlaceholder = this.intl.startTyping;

  minItemsToShowSearch = 5;

  dropdownMinWidth = 0;
  dropdownMaxWidth = 0;
  dropdownAlignStart = false;
  dropdownAlignEnd = false;

  get isDisabled() {
    return this.disabled || (this.options && !this.options.length);
  }

  @MemoizeLast<PplSelectComponent>(['options', 'hasEmptyOption', 'emptyOptionLabel'])
  get normalizedOptions() {
    const options = (this.options || []).filter(option => !option.deleted);

    if (this.hasEmptyOption) {
      options.unshift(this.getEmptyOption());
    }
    return options;
  }

  @MemoizeLast<PplSelectComponent>(['options', 'value', 'hasEmptyOption', 'emptyOptionLabel'])
  get selectedOption(): PplSelectOption {
    const selectedOption = this.options.find(option => option.value === this.value);

    if (selectedOption) {
      return selectedOption;
    } else {
      return this.getEmptyOption();
    }
  }

  @MemoizeLast<PplSelectComponent>(['options', 'maxContainerHeight', 'optionTemplateRowHeight', 'forceAllOptionsVisible'])
  get optionListMaxContainerHeight() {
    if (this.forceAllOptionsVisible) {
      return this.options.length * this.optionTemplateRowHeight + 1;
    }

    return this.maxContainerHeight;
  }

  @MemoizeLast<PplSelectComponent>(['itemTemplate', 'selectedItemTemplate'])
  get normalizedSelectedItemTemplate() {
    return this.selectedItemTemplate || this.itemTemplate;
  }

  @MemoizeLast<PplSelectComponent>(['calculateDropdownWidth', 'options'])
  get calculatedOptionTextWidth() {
    if (!this.calculateDropdownWidth || !ctx) {
      return null;
    } else {
      // 12: label container padding + label span margin
      return Math.max(...this.options.map(option => Math.ceil(ctx.measureText(option.label).width))) + 12;
    }
  }

  constructor(
    public changeDetectorRef: ChangeDetectorRef, // don't remove
    private intl: PplUiIntl,
    @Inject(PIPELINER_NG_UI_THEME) public ngUiTheme: NG_UI_THEMES
  ) { }

  ngOnInit() {
  }

  hide() {
    if (this.popoverDirective) {
      this.popoverDirective.hide();
    }
  }

  onBlur() {
    this.focused = false;
    this.search = '';
  }

  onFocus() {
    if (this.disabled || this.readonly) {
      return;
    }
    this.focused = true;
  }

  focus() {
    this.input.nativeElement.focus();
  }

  calculateDropdownAlignment() {
    const container = this.selectInputContainer.nativeElement;
    const containerRect = container.getBoundingClientRect();

    this.dropdownMinWidth = (this.calculatedOptionTextWidth || containerRect.width) + 2; // +2px due to popover-content "fix" for floating point issues in Chrome
    this.dropdownMaxWidth = this.dropdownMinWidth * 2;

    if (containerRect.left < window.innerWidth / 2) {
      this.dropdownAlignStart = true;
      this.dropdownAlignEnd = false;
    } else if (containerRect.right > window.innerHeight / 2) {
      this.dropdownAlignStart = false;
      this.dropdownAlignEnd = true;
    }
  }

  onValueInputKeyDown($event: KeyboardEvent) {
    if (this.disabled) {
      return;
    }

    switch ($event.keyCode) {
      case KEYCODE_ENTER:
      case KEYCODE_UP:
      case KEYCODE_DOWN:
        $event.stopPropagation();
        $event.stopImmediatePropagation();

        if (this.popoverDirective.isOpen) {
          this.optionList?.list?.onKeyDown($event);
        } else {
          this.popoverDirective.show();
        }
        break;
    }
  }

  onKeyEscapePressed() {
    if (this.popoverDirective.isOpen) {
      this.popoverDirective.hide();
      this.focus();
    }
  }

  hasOptionIconDimensions(option: PplSelectOption) {
    return !(typeof option.icon === 'string');
  }

  onOptionSelect($event: PplOptionListOption) {
    if (!($event as PplSelectOption).disabled) {
      this.valueChange.emit($event.value);
      this.hide();
      this.focus();
    }
  }

  private getEmptyOption(): PplSelectOption {
    const providedEmptyOption = this.options.find(foundOption => foundOption.value === null);

    if (providedEmptyOption) {
      return providedEmptyOption;
    } else {
      return {
        ...emptyOption,
        label: this.emptyOptionLabel ? this.emptyOptionLabel : (emptyOption.label || this.placeholder)
      };
    }
  }
}

const emptyOption: PplSelectOption = {
  value: null,
  label: ''
};

export type PplSelectOptionIcon =
  | string
  | {
    name: string;
    width: string;
    height: string;
  };

export interface PplSelectOption<T = string, D = any> {
  value: T;
  label: string;
  labelTooltip?: string;
  categoryId?: string;
  icon?: PplSelectOptionIcon;
  color?: string;
  disabled?: boolean;
  deleted?: boolean;
  data?: D;
}

export interface PplSelectCategory {
  id: string;
  label: string;
}

const canvas = document.createElement('canvas');
let ctx: CanvasRenderingContext2D = null;

if (canvas && canvas.getContext) {
  ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = `bold 14px ${getComputedStyle(document.body).fontFamily}`;
  }
}
