import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ElementRef,
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';

import { notFirstChange, trackById } from '@ppl/utils';
import type { GridAvailableColumn, GridColumn, GridInternalColumnData } from '../grid.interfaces';

@Component({
  selector: 'ppl-grid-footer',
  templateUrl: './grid-footer.component.html',
  styleUrls: ['./grid-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridFooterComponent implements OnChanges {

  @Input() columnsWithData: (GridColumn & GridAvailableColumn & GridInternalColumnData)[];
  @Input() record: { [id: string]: any };
  @Input() name: string;
  @Input() scrollLeft: number;

  trackById = trackById;

  constructor(
    private elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (notFirstChange(changes.scrollLeft)) {
      this.elementRef.nativeElement.scrollLeft = this.scrollLeft;
    }
  }

}
