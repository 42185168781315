import { ReportExpiredComponent } from './components/report-expired/report-expired.component';
import { SpaceAccessForbiddenComponent } from './components/space-access-forbidden/space-access-forbidden.component';
import { UnauthorizedDialogComponent } from './components/unauthorized-dialog/unauthorized-dialog.component';
import { PplCurrencyPipe } from './pipes/currency.pipe';
import { PplDateTimeRangePipe } from './pipes/date-time-range.pipe';
import { PplDateTimePipe } from './pipes/date-time.pipe';
import { PplDatePipe } from './pipes/date.pipe';
import { PplDecimalPipe } from './pipes/decimal.pipe';
import { PplFileSizePipe } from './pipes/file-size.pipe';
import { PplLocationPipe } from './pipes/location.pipe';
import { PplNamePipe } from './pipes/name.pipe';
import { PplTimeAgoPipe } from './pipes/time-ago.pipe';
import { AuthService } from './service/auth.service';
import { ErrorHandlerService } from './service/error-handler.service';
import { LocaleService } from './service/locale.service';
import { GoogleDocsTokenService } from './service/token/google-docs-token.service';
import { OfficeTokenServiceGuard } from './service/token/office-token-guard.service';
import { OfficeTokenServiceRedirectGuard } from './service/token/office-token-redirect-guard.service';
import { OfficeTokenService } from './service/token/office-token.service';
import { RedirectTokenServiceGuard } from './service/token/redirect-token-guard.service';
import { RedirectTokenService } from './service/token/redirect-token.service';
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  DecimalPipe
} from '@angular/common';
import { NgModule } from '@angular/core';
import { PplButtonModule } from '@ppl/ui/button';
import { PplDialogModule } from '@ppl/ui/dialog';
import { PplIconModule } from '@ppl/ui/icon';
import { ApolloModule } from 'apollo-angular';
import { PplDateRangePipe } from './pipes/date-range.pipe';

const moduleComponents = [
  ReportExpiredComponent,
  SpaceAccessForbiddenComponent,
  UnauthorizedDialogComponent
];

const modulePipes = [
  PplNamePipe,
  PplDateTimePipe,
  PplDatePipe,
  PplDecimalPipe,
  PplCurrencyPipe,
  PplLocationPipe,
  PplFileSizePipe,
  PplDateRangePipe,
  PplDateTimeRangePipe,
  PplTimeAgoPipe
];


@NgModule({
    imports: [
        CommonModule,
        ApolloModule,
        PplIconModule,
        PplDialogModule,
        PplButtonModule
    ],
    declarations: [
        ...moduleComponents,
        ...modulePipes
    ],
    providers: [
        AuthService,
        LocaleService,
        ErrorHandlerService,
        GoogleDocsTokenService,
        RedirectTokenService,
        RedirectTokenServiceGuard,
        OfficeTokenService,
        OfficeTokenServiceGuard,
        OfficeTokenServiceRedirectGuard,
        DatePipe,
        DecimalPipe,
        CurrencyPipe,
        ...modulePipes
    ],
    exports: [
        ...moduleComponents,
        ...modulePipes
    ]
})
export class AuthModule {
}
