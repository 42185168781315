import type { AfterViewInit} from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import * as Prism from 'prismjs';

@Component({
  selector: 'ppl-prism',
  templateUrl: './prism.component.html',
  styleUrls: ['./prism.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrismComponent implements AfterViewInit {
  @Input() code = '';
  @Input() language = 'markup';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    Prism.highlightElement(this.elementRef.nativeElement.querySelector('code'));
  }
}
