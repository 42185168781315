import { InputPhoneNumberComponent } from './input-phone-number/input-phone-number.component';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PplInputModule } from '@ppl/ui/input';
import { PplLoadingModule } from '@ppl/ui/loading';

@NgModule({
  imports: [
    CommonModule,
    PplInputModule,
    PplLoadingModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  declarations: [
    InputPhoneNumberComponent
  ],
  exports: [
    InputPhoneNumberComponent
  ]
})
export class PplComponentsInputPhoneNumberModule { }
