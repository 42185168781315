<label class="ppl-checkbox"
       [class.disabled]="disabled"
       [class.readonly]="readonly"
       [class.block]="block">
  <input #input
         type="checkbox"
         [id]="inputId"
         [required]="required"
         [checked]="value"
         [value]="value"
         [disabled]="disabled || readonly"
         [name]="name"
         [tabIndex]="tabIndex"
         (change)="_onInteractionEvent($event)"
         (click)="_onInputClick($event)">
  <span [pplInputAutofill]="autofill"
        (keydown)="onCheckboxKeyDown($event)"
        #check
        class="check"
        [class.indeterminate]="indeterminate"
        [attr.tabindex]="readonly || disabled ? null : tabIndex"
        (focus)="checkFocus = true"
        (blur)="checkFocus = false">
    <ppl-icon *ngIf="!indeterminate"
              [states]="[
      {name: 'disabled', active: disabled },
      {name: 'autofill', active: !disabled && autofill },
      {name: 'readonly', active: !disabled && readonly }
    ]"
              svgIcon="checkmark-form"
              width="11px"
              height="11px"></ppl-icon>
  </span>
  <ng-content></ng-content>
</label>
