function memoizeFn({
  func,
  functionName,
  resolver,
  instanceProperties,
  strategy
}: { func: Function, functionName: string, resolver?: any, instanceProperties?: string[], strategy: 'last' | 'any' }) {
  if (typeof func !== 'function' || (resolver != null && typeof resolver !== 'function')) {
    throw new TypeError('Expected a function');
  }

  const memoized = function (...args: any[]) {
    switch (strategy) {
      case 'any':
        const key = JSON.stringify(getCacheKeys(this, resolver, instanceProperties, args));

        const cache = memoized.cache;

        // if chache for these parameters exists, return it
        if (cache.has(key)) {
          return cache.get(key);
        } else {
          // otherwise calculate, set & return it
          const result = func.apply(this, args);
          memoized.cache = cache.set(key, result) || cache;
          return result;
        }

      case 'last':
        const resolvedArguments = resolver
          ? resolveInstanceProperties(this, instanceProperties)
            .reduce((prev, [prop, value]) => ([...prev, value]), [])
          : args;

        const lastArgs: any[] | null = this ? this[getLastArgsKey(functionName)] : memoized.lastArgs;

        if (lastArgs
          && lastArgs.length === resolvedArguments.length
          && lastArgs.every((arg, argIndex) => arg === resolvedArguments[argIndex])
        ) {
          return this ? this[getLastArgsValueKey(functionName)] : memoized.lastArgsValue;
        } else {
          // otherwise calculate, set & return it
          const result = func.apply(this, args);
          if (this) {
            this[getLastArgsKey(functionName)] = resolvedArguments;
            this[getLastArgsValueKey(functionName)] = result;
          } else {
            memoized.lastArgs = resolvedArguments;
            memoized.lastArgsValue = result;
          }
          return result;
        }
    }
  };

  // strategy = 'any' props
  memoized.cache = new (memoizeFn.Cache || Map);

  // strategy = 'last' props
  memoized.lastArgs = null as (any[] | null);
  memoized.lastArgsValue = null as any;

  return memoized;
}

memoizeFn.Cache = Map;

function getLastArgsKey(functionName: string) {
  return `__memo_${functionName}_lastArgs`;
}

function getLastArgsValueKey(functionName: string) {
  return `__memo_${functionName}_lastArgsValue`;
}

function getCacheKeys(self: any, resolver: any, getCacheProperties: string[], args: any[]) {
  return resolver
    // case of a class getter
    ? resolveInstanceProperties(self, getCacheProperties)
      .reduce((prev, [prop, value]) => ({ ...prev, [prop]: value }), {})
    // case of a function
    : args;
}

function resolveInstanceProperties(self: any, getCacheProperties: string[]) {
  return !getCacheProperties && [] || getCacheProperties
    .map(prop => [prop, self[prop]])
    .filter(([prop, value]) => value !== undefined);
}

function memoizeInit<C extends any = any>(
  descriptor: PropertyDescriptor,
  functionName: string,
  getCacheProperties: (keyof C)[],
  strategy: 'any' | 'last'
) {
  if (descriptor.get) {
    descriptor.get = memoizeFn({
      func: descriptor.get,
      functionName,
      resolver: function <T>(this: T): T { return this; },
      instanceProperties: getCacheProperties as string[],
      strategy
    });
  } else {
    descriptor.value = memoizeFn({
      func: descriptor.value,
      functionName,
      strategy
    });
  }
}

/**
 * Remembers all possible combinations of input args & their results of a func/getter
 */
export function Memoize<C extends any = any>(getCacheProperties: (keyof C)[] = []) {
  return function (target: any, functionName: string, descriptor: PropertyDescriptor) {
    memoizeInit<C>(descriptor, functionName, getCacheProperties, 'any');
  };
}

/**
 * Remembers only last combination of input args & the result it has yielded
 * in case of an instance func/getter, this memorizing is per-instance
 */
export function MemoizeLast<C extends any = any>(getCacheProperties: (keyof C)[] = []) {
  return function (target: any, functionName: string, descriptor: PropertyDescriptor) {
    memoizeInit<C>(descriptor, functionName, getCacheProperties, 'last');
  };
}
