import { ExternalFilter } from '../autocomplete/external-filter';
import { fuzzySearch } from '../autocomplete/fuzzy-search';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { MemoizeLast } from '@ppl/utils';
import type {
  OnDestroy
} from '@angular/core';
import type { PplAutocompleteOption, PplAutocompleteOptionsRequest } from '../autocomplete';

@Component({
  selector: 'ppl-autocomplete-picker',
  templateUrl: './autocomplete-picker.component.html',
  styleUrls: ['./autocomplete-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplAutocompletePickerComponent implements OnDestroy {

  @Input() options: PplAutocompleteOption[];

  @Input() categories?: any[];
  @Input() displayCategoriesSidebar?: boolean;
  @Input() freeValue = false;
  @Input() freeValueValidator?: (value: string) => boolean;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() optionTemplateRowHeight?: number;
  @Input() displayCreateOption?: string;
  @Input() displayOptionsLoading = false;
  @Input() displayValueLoading = false;
  @Input() forceDisplayClearValue = false;
  @Input() autoFocusOnInit = false;
  @Input() disabled = false;
  @Input() placeholder?: string;
  @Input() maxContainerHeight?: number;
  @Input() openOnFocus = true;
  @Input() popoverAlignStart = true;
  @Input() popoverAlignEnd = true;
  @Input() popoverWidth?: number;
  @Input() minPopoverWidth?: number;
  @Input() categoriesSidebarWidth?: number;

  @Output() optionSelect = new EventEmitter<string>();
  @Output() optionCreate = new EventEmitter<string>();
  @Output() optionsRequest = new EventEmitter<PplAutocompleteOptionsRequest>();
  @Output() listClose = new EventEmitter();
  @Output() clearValueClick = new EventEmitter();

  filter = '';
  externalFilter: ExternalFilter;

  get availableOptions() {
    if (this.isExternalFilter()) {
      return this.options;
    } else {
      return this.availableOptionsFiltered;
    }
  }

  @MemoizeLast(['options', 'filter'])
  get availableOptionsFiltered() {
    return fuzzySearch({
      list: this.options,
      term: this.filter
    });
  }

  ngOnDestroy() {
    if (this.externalFilter) {
      this.externalFilter.dispose();
    }
  }

  onListOpen() {
    if (this.isExternalFilter()) {
      this.externalFilter = new ExternalFilter({
        onChange: () => { },
        onOptionsRequest: event => {
          this.optionsRequest.emit(event);
        }
      });
    }
  }

  onListClose() {
    this.filter = '';

    if (this.externalFilter) {
      this.externalFilter.dispose();
      this.externalFilter = null;
    }

    this.listClose.emit();
  }

  onListScrollEnd() {
    if (this.externalFilter && !this.displayOptionsLoading) {
      this.externalFilter.listEnd(this.options[this.options.length - 1]);
    }
  }

  onOptionSelect(option: PplAutocompleteOption) {
    this.optionSelect.emit(option.value);
  }

  onOptionCreate(value: string) {
    this.optionCreate.emit(value);
  }

  onClearValueClick() {
    this.clearValueClick.emit();
  }

  onValueChange(value: string) {
    this.filter = value;

    if (this.externalFilter) {
      this.externalFilter.next(value);
    }
  }

  onValueSubmit(value: string) {
    if (this.freeValue && value) {
      this.optionSelect.emit(value);
    }
  }

  isExternalFilter() {
    return this.optionsRequest.observers.length !== 0;
  }

}
