import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PplDropZoneDirective } from './drop-zone.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PplDropZoneDirective
    ],
    exports: [
        PplDropZoneDirective
    ]
})
export class PplDropZoneModule {
}

export * from './drop-zone.directive';
