import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ppl-bottom-bar-submenu',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./bottom-bar-submenu.component.scss']
})
export class PplBottomBarSubmenuComponent {

  @Input() @HostBinding('class.connector-right') connectorRight = false;

}
