<ng-container *ngIf="errors">
  <ng-container *ngFor="let key of errorKeys">
    <ng-container *ngIf="customErrorMessages && customErrorMessages[key]; else preDefinedErrorMessages">
      <ppl-input-error>{{customErrorMessages[key]}}</ppl-input-error>
    </ng-container>

    <ng-template #preDefinedErrorMessages>
      <ng-container [ngSwitch]="key">
        <ppl-input-error *ngSwitchCase="'required'">
          <ng-container *ngIf="errors[key].minItems; else required">
            Check at least {{errors[key].minItems}} option(s).
          </ng-container>
          <ng-template #required>
            {{thisFieldIsRequired}}
          </ng-template>
        </ppl-input-error>
        <ppl-input-error *ngSwitchCase="'email'">{{invalidEmailFormat}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'url'">{{invalidUrlFormat}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'secureUrl'">{{invalidSecureUrlFormat}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'invalidOption'">{{invalidOptionSelected}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'ownerUnitMembership'">{{ownerNotHaveAccessSalesUnit}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'userNotMemberOfSalesUnit'">{{userNotMemberOfSalesUnit}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'min'">{{invalidMinimalValue}} {{errors[key].min}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'minDateValue'">{{invalidMinDate}} {{errors[key] }}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'maxDateValue'">{{invalidMaxDate}} {{errors[key]}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'range'">{{invalidValueRange}} {{errors[key][0]}}, {{errors[key][1]}}&gt;</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'logic'">{{invalidFieldValue}} {{errors[key]}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'pattern'">{{invalidRequiredPattern}} {{errors[key].requiredPattern}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'integer'">{{invalintIntegerValue}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'number'">{{invalidNumberValue}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'minMaxValue'">{{minHasToBeSmallerThanMax}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'atLeastOneOption'">{{atLeastOneSelected}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'password'">
          {{invalidPassword}}
        </ppl-input-error>
        <ppl-input-error *ngSwitchCase="'maxlength'">{{invalidLength}} {{errors[key].requiredLength}}, {{actualLength}} {{errors[key].actualLength}}.</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'minlength'">{{invalidMinLength}} {{errors[key].requiredLength}}, {{actualLength}} {{errors[key].actualLength}}.</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'maxItemCount'">{{invalidCount}} {{errors[key].requiredLength}}, {{actualCount}} {{errors[key].actualLength}}.</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'minItemCount'">{{invalidMinCount}} {{errors[key].requiredLength}}, {{actualCount}} {{errors[key].actualLength}}.</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'custom'">{{errors[key]}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'productsServicesPipelineUnavailable'">{{highlightedProductsAreNotAvailable}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'productsServicesInvalidProducts'">{{productsServicesInvalidProducts}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'productsServicesInvalidApiProducts'">{{productsServicesInvalidProducts}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'productsServicesCurrencyUnavailable'">{{highlightedCurrencyIsNotAvailable}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'productsServicesPriceListUnavailable'">{{highlightedPriceListIsNotAvailable}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'dayIsNotSelected'">{{dayIsNotSelected}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'firstOrLastNameRequired'">{{firstOrLastNameRequired}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'invalidHeaderKey'">{{invalidHeaderKey}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'invalidHeaderValue'">{{invalidHeaderValue}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'duplicateHeaders'">{{duplicateHeaders}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'invalidRollupNesting'">{{invalidRollupNesting}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'requiredDependentEntity'">{{requiredDependentEntity}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'invalidPageLinkFormat'">{{invalidPageLinkFormat}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'takenPageLink'">{{takenPageLink}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'processInfiniteLoop'">{{processInfiniteLoop}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'processDeleted'">{{processDeleted}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'filesUploading'">{{filesUploading}}</ppl-input-error>
        <ppl-input-error *ngSwitchCase="'itemUnavailable'">{{itemUnavailable}}</ppl-input-error>
        <ppl-input-error *ngSwitchDefault>{{invalidFieldValue}} {{errors[key] | json}}</ppl-input-error>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
