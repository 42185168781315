import { fuzzySearch } from '../autocomplete';
import { PplPopoverDirective } from '../popover';
import { PplUiIntl } from '../ppl-ui-intl';
import type {
  OnChanges,
  OnInit} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
,
  ChangeDetectorRef} from '@angular/core';
import type { PplBaseOption } from '@ppl/domain';
import type {
  TSimpleChanges
} from '@ppl/utils';
import {
  FormValueControl,
  getFormControlProvider,
  MemoizeLast,
  normalizeToObject,
  sortByKey
} from '@ppl/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ppl-multi-checkbox-advanced',
  templateUrl: './multi-checkbox-advanced.component.html',
  styleUrls: ['./multi-checkbox-advanced.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    getFormControlProvider(() => PplMultiCheckboxAdvancedComponent)
  ]
})
@FormValueControl()
export class PplMultiCheckboxAdvancedComponent implements OnChanges, OnInit {
  @Input() value: string[] | null;
  @Input() options: PplBaseOption[];
  @Input() initialValue?: string[] | null;
  @Input() hasChanges = false;
  @Input() placeholder?: string;
  @Input() calculateDropdownWidth = false;
  @Input() maxContainerHeight?: number;
  @Input() displayOptionsLoading = false;

  @Input()
  @HostBinding('class.ppl-input-container--error')
  error = false;

  @Input()
  disabled = false;

  @Input()
  readonly = false;

  @Output() valueChange = new EventEmitter<string[]>();

  @ViewChild('multiCheckboxContainer', { static: true, read: PplPopoverDirective }) popoverDirective: PplPopoverDirective;
  @ViewChild('multiCheckboxContainer', { static: true }) selectInputContainer: ElementRef;
  @ViewChild('optionsContainer', { static: false, read: ElementRef }) optionsContainer: ElementRef;

  availableOptions: PplBaseOption[] = [];
  listSelectedIndex = -1;
  focused: boolean;
  searchValue = '';
  opened: boolean;

  dropdownMinWidth = 0;
  dropdownMaxWidth = 0;
  dropdownAlignStart = false;
  dropdownAlignEnd = false;

  selectedOptions: PplBaseOption[] = [];
  optionsByValue: { [value: string]: PplBaseOption };

  renderedOptions$ = new BehaviorSubject<MultiCheckboxAdvancedRenderedOptions[]>(null);

  @MemoizeLast<PplMultiCheckboxAdvancedComponent>(['value', 'hasChanges', 'initialValue', 'options'])
  get changes() {
    if (!this.hasChanges) {
      return [];
    } else {
      const initialValue = this.initialValue || [];

      const newItems = (this.value || []).filter(id => !initialValue.includes(id));
      const removedItems = (this.initialValue || []).filter(id => !this.value.includes(id));

      return [...newItems, ...removedItems];
    }
  }

  @MemoizeLast<PplMultiCheckboxAdvancedComponent>(['calculateDropdownWidth', 'options'])
  get calculatedOptionTextWidth() {
    if (!this.calculateDropdownWidth || !ctx) {
      return null;
    } else {
      // 12: label container padding + label span margin
      return Math.max(...this.options.map(option => Math.ceil(ctx.measureText(option.label).width))) + 12;
    }
  }

  get showOptions() {
    return !!this.renderedOptions$.getValue() && !!this.options.length;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private intl: PplUiIntl,
  ) { }

  ngOnChanges(changes: TSimpleChanges<PplMultiCheckboxAdvancedComponent>) {
    if (changes.options) {
      this.updateAvailableOptions();
      this.createOptionsMap();

      if (this.value) {
        this.updateRenderedOptions();
      }
    }
    if (changes.value) {
      this.updateRenderedOptions();
    }
  }

  ngOnInit() {
    this.addPlaceholder();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (
      !this.selectInputContainer.nativeElement.contains(event.target) &&
      (this.optionsContainer && !this.optionsContainer.nativeElement.contains(event.target))
    ) {
      this.closePopover();
    }
  }

  onCheck(selectedValue: string, checked: boolean) {
    if (checked) {
      this.valueChange.emit([...(this.value || []), selectedValue]);
    } else {
      this.valueChange.emit(this.value.filter(value => value !== selectedValue));
    }
  }

  onContainerClick(event: MouseEvent) {
    if (!this.disabled) {
      if (this.popoverDirective.isOpen) {
        this.closePopover();
      } else if (!this.popoverDirective.isOpen) {
        this.openPopover();
      }
    }
  }

  onInputFocus() {
    if (!this.readonly) {
      this.focused = true;
    }
  }

  onInputBlur() {
    this.focused = false;
    this.searchValue = '';
  }

  onOptionSelect(option: PplBaseOption) {
    const currentValues = this.value ? this.value : [];
    const values = currentValues.includes(option.value)
      ? currentValues.filter(value => value !== option.value)
      : [...currentValues, option.value];
    this.updateValue(values);
  }

  onOptionValueChange(value: string) {
    this.listSelectedIndex = this.getOptionIndexByValue(value);
  }

  getOptionIndexByValue(value: string) {
    return this.availableOptions.findIndex(option => option.value === value);
  }

  onOptionClear(event: MouseEvent) {
    this.updateValue([]);
  }

  onOptionRemove(option: PplBaseOption) {
    const values = this.value.filter(value => value !== option.value);
    this.updateValue(values);
  }

  onOptionSelectAll(event: MouseEvent) {
    const values = this.availableOptions.map(option => option.value);
    this.updateValue(values);
  }

  onSearchValueChange(event: any) {
    const inputText = event.target.value;
    this.listSelectedIndex = (this.options.length !== 0) ? 0 : -1;
    this.searchValue = inputText;
    this.updateAvailableOptions();
  }

  updateAvailableOptions() {
    const selectedValues = this.value ? this.value : [];
    const options = [...this.options].filter(option => !option.deleted || selectedValues.includes(option.value));

    this.availableOptions = fuzzySearch({ list: options, term: this.searchValue });

    this.listSelectedIndex = (this.availableOptions.length !== 0)
      ? Math.min(Math.max(this.listSelectedIndex, 0), this.availableOptions.length - 1)
      : -1;
  }

  isChecked(option: PplBaseOption) {
    const values = this.value;
    return values && values.includes(option.value);
  }

  openPopover() {
    this.popoverDirective.show();
  }

  closePopover() {
    this.popoverDirective.hide();
  }

  calculateDropdownAlignment() {
    const container = this.selectInputContainer.nativeElement;
    const containerRect = container.getBoundingClientRect();

    this.dropdownMinWidth = (this.calculatedOptionTextWidth || containerRect.width) + 2; // +2px due to popover-content "fix" for floating point issues in Chrome
    this.dropdownMaxWidth = this.dropdownMinWidth * 2;

    if (containerRect.left < window.innerWidth / 2) {
      this.dropdownAlignStart = true;
      this.dropdownAlignEnd = false;
    } else if (containerRect.right > window.innerHeight / 2) {
      this.dropdownAlignStart = false;
      this.dropdownAlignEnd = true;
    }
  }

  trackByFn(index: number, item: PplBaseOption) {
    return item.value;
  }

  private addPlaceholder() {
    if (this.placeholder === undefined) {
      this.placeholder = this.intl.clickToSelect;
    }
  }

  private updateRenderedOptions() {
    if (this.initialValue) {
      const renderedOptions = [];
      const options = Array.from(new Set([...this.value, ...this.initialValue]));

      options.forEach(option => {
        const initialValue = this.initialValue.includes(option);
        const selectedValue = this.value.includes(option);
        const optionData = this.optionsByValue[option];
        const added = !initialValue && selectedValue;
        const removed = initialValue && !selectedValue;
        renderedOptions.push({
          ...optionData,
          added,
          removed
        });
      });
      this.renderedOptions$.next(renderedOptions.sort(sortByKey('label')));
    } else {
      this.renderedOptions$.next(this.value ? this.value.map(value => this.optionsByValue[value]).sort(sortByKey('label')) : []);
    }
    this.changeDetectorRef.detectChanges();
  }

  private updateValue(values: string[]) {
    this.valueChange.emit(values);
  }

  private createOptionsMap() {
    this.optionsByValue = normalizeToObject(this.options, 'value');
  }
}

const canvas = document.createElement('canvas');
let ctx: CanvasRenderingContext2D = null;

if (canvas && canvas.getContext) {
  ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.font = `bold 14px ${getComputedStyle(document.body).fontFamily}`;
  }
}

interface MultiCheckboxAdvancedRenderedOptions extends PplBaseOption {
  added?: boolean;
  removed?: boolean;
}
