import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-info-box-demo',
  templateUrl: './info-box-demo.component.html',
  styleUrls: ['./info-box-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBoxDemoComponent implements OnInit {

  text = 'Drag the yellow corner in order to crop uploaded image';

  code = `
      <ppl-info-box type="info"
                    [text]="text"
                    buttonText="undo"></ppl-info-box>

      <ppl-info-box type="warning"
                    [text]="text"
                      buttonText="undo"></ppl-info-box>

      <ppl-info-box type="danger"
                    [text]="text"
                    buttonText="undo"></ppl-info-box>

      <ppl-info-box type="error"
                    [text]="text"
                    buttonText="undo"
                    enableClose="true">
        <ng-template #textTemplate>
          <ng-container>You can populate contact data to account field(s)</ng-container>
        </ng-template>
        <ng-template #actionsTemplate>
          <button pplButton
                  pplButtonSmall
                  pplButton7="error">Populate</button>
        </ng-template>
      </ppl-info-box>
  `;

  drawerOpen = true;

  constructor() { }

  ngOnInit() {
  }

}
