import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import type { PplAccountClass } from '@ppl/ui/account-class';
import type { TFormGroup } from '@ppl/ui/form';
import { TFormBuilder } from '@ppl/ui/form';
import type { PplPartialSelectOption, PplPartialSelectValue } from '@ppl/ui/partial-select';
import { partialSelectValidator } from '@ppl/ui/partial-select';
import type { PplSelectOption } from '@ppl/ui/select';
import { pplSlideToggleValidator } from '@ppl/ui/slide-toggle';
import type { PplTreeSelectOption } from '@ppl/ui/tree-select';
import { PplValidators } from '@ppl/utils';

@Component({
  selector: 'ppl-form-builder-demo',
  templateUrl: './form-builder-demo.component.html',
  styleUrls: ['./form-builder-demo.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormBuilderDemoComponent implements OnInit {
  deletedSelectOptions: PplSelectOption[] = [
    { value: 'value-1', label: 'AValue 1' },
    { value: 'value-2', label: 'AValue 2 deleted', deleted: true },
    { value: 'value-3', label: 'AValue 3' },
    { value: 'value-4', label: 'bValue 4' },
  ];

  form: TFormGroup<FormDefaults>;

  options: PplPartialSelectOption[] = [
    {
      value: 'value1',
      label: 'label1',
      color: 'red'
    },
    {
      value: 'value2',
      label: 'label2',
      color: 'blue'
    },
    {
      value: 'value3',
      label: 'label3',
      color: 'green'
    }
  ];

  treeOptions: PplTreeSelectOption[] = [
    {
      value: 'main1',
      label: 'main1',
      parentId: null,
      disabled: false,
    },
    {
      value: 'main1-sub1',
      label: 'main1-sub1',
      parentId: 'main1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub1',
      label: 'main1-sub1-sub1',
      parentId: 'main1-sub1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub1-sub1',
      label: 'main1-sub1-sub1-sub1',
      parentId: 'main1-sub1-sub1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub2',
      label: 'main1-sub1-sub2',
      parentId: 'main1-sub1',
      disabled: false,
    },
    {
      value: 'main1-sub1-sub2-sub1',
      label: 'main1-sub1-sub2-sub1',
      parentId: 'main1-sub1-sub2',
      disabled: false,
    },
    {
      value: 'main1-sub2',
      label: 'main1-sub2',
      parentId: 'main1',
      disabled: false,
    },
    {
      value: 'main1-sub2-sub2',
      label: 'main1-sub2-sub2',
      parentId: 'main1-sub2',
      disabled: false,
    },
    {
      value: 'main1-sub2-sub2-sub2',
      label: 'main1-sub2-sub2-sub2',
      parentId: 'main1-sub2-sub2',
      disabled: false,
    },
    {
      value: 'main2',
      label: 'main2',
      parentId: null,
      disabled: false,
    },
    {
      value: 'main2-sub1',
      label: 'main2-sub1',
      parentId: 'main2',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub1',
      label: 'main2-sub1-sub1',
      parentId: 'main2-sub1',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub1-sub1',
      label: 'main2-sub1-sub1-sub1',
      parentId: 'main2-sub1-sub1',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub2',
      label: 'main2-sub1-sub2',
      parentId: 'main2-sub1',
      disabled: false,
    },
    {
      value: 'main2-sub1-sub2-sub1',
      label: 'main2-sub1-sub2-sub1',
      parentId: 'main2-sub1-sub2',
      disabled: false,
    },
    {
      value: 'main2-sub2',
      label: 'main2-sub2',
      parentId: 'main2',
      disabled: false,
    },
    {
      value: 'main2-sub2-sub2',
      label: 'main2-sub2-sub2',
      parentId: 'main2-sub2',
      disabled: false,
    },
    {
      value: 'main2-sub2-sub2-sub2',
      label: 'main2-sub2-sub2-sub2',
      parentId: 'main2-sub2-sub2',
      disabled: false,
    }
  ];

  readonly formDefaults: FormDefaults = {
    inputNumber: '',
    inputUrl: '',
    inputEmail: '',
    inputPhone: '',
    select: 'value-2',
    radioGroup: '',
    datePicker: '',
    multipleAutocomplete: [],
    ranking: 1,
    rankingPercentage: 50,
    accountClass: 'NoClass' as PplAccountClass,
    slideToggle: false,
    partialSelect: {
      all: true,
      selectedValues: []
    } as PplPartialSelectValue,
    treeSelect: []
  };

  get formRawValue() {
    return JSON.stringify(this.form.getRawValue(), undefined, 2);
  }

  constructor(private formBuilder: TFormBuilder) {
    this.form = this.formBuilder.group<FormDefaults>({
      select: [this.formDefaults.select, PplValidators.nonDeletedSelectOption(this.deletedSelectOptions)],
      inputNumber: [this.formDefaults.inputNumber, Validators.required],
      inputUrl: [this.formDefaults.inputUrl, Validators.required],
      inputEmail: [this.formDefaults.inputEmail, Validators.required],
      inputPhone: [this.formDefaults.inputPhone, Validators.required],
      radioGroup: [this.formDefaults.radioGroup, Validators.required],
      datePicker: [this.formDefaults.datePicker, Validators.required],
      multipleAutocomplete: [this.formDefaults.multipleAutocomplete, Validators.required],
      ranking: [this.formDefaults.ranking, Validators.required],
      rankingPercentage: [this.formDefaults.rankingPercentage],
      accountClass: [this.formDefaults.accountClass, Validators.required],
      slideToggle: [this.formDefaults.slideToggle, pplSlideToggleValidator],
      partialSelect: [this.formDefaults.partialSelect, partialSelectValidator],
      treeSelect: [this.formDefaults.treeSelect, Validators.required]
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    alert('submitted');
  }

  onReset() {
    this.form.reset({
      ...this.formDefaults
    });
    this.form.markAsPristine();
  }

  onAccountCreate(item) {
    alert('onAccountCreate(), created item is in console.');
    console.log(item);
  }

  onAccountOpen(item) {
    alert('onAccountOpen(), open item is in console.');
    console.log(item);
  }
}

interface FormDefaults {
  inputNumber: string;
  inputUrl: string;
  inputEmail: string;
  inputPhone: string;
  select: string;
  radioGroup: string;
  datePicker: string;
  multipleAutocomplete: [];
  ranking: 1;
  rankingPercentage: 50;
  accountClass: PplAccountClass;
  slideToggle: boolean;
  partialSelect: PplPartialSelectValue;
  treeSelect: [];
}
