import { ChangeDetectorRef, TemplateRef , ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueControl, uuid } from '@ppl/utils';

@Component({
  selector: 'ppl-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplInputPhoneComponent),
      multi: true
    }
  ]
})
@FormValueControl()
export class PplInputPhoneComponent {

  @Input() value: string;
  @Input() disabled: boolean;
  @Input() disableCall = false;
  @Input() showInitiateCall = true;
  @Input() popoverTemplate: TemplateRef<any>;

  @Output() inputBlur = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() initiateCall = new EventEmitter<string>();

  autocompleteId = `input-phone-${uuid()}`;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onValueChange(text: string) {
    this.valueChange.emit(text);
  }
}
