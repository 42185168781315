<ppl-ui-toolbar>
  <ppl-ui-toolbar-button-group [buttons]="toolbarButtons$ | async"
                               (buttonClick)="onToolbarButtonClick($event)"></ppl-ui-toolbar-button-group>
  <ng-content select="toolbar-extra"></ng-content>
</ppl-ui-toolbar>
<div class="grid">
  <ppl-calling-phone-grid [records]="gridData$ | async"
                          [selection]="gridSelection$ | async"
                          [columns]="columns"
                          [customColumnTemplates]="customColumnTemplates"
                          [actions]="gridActions"
                          (selectionChange)="onGridSelectionChange($event)"
                          (actionClick)="onGridActionClick($event)"></ppl-calling-phone-grid>
  <ppl-loading-overlay *ngIf="loading || (fetching$ | async) || (deleting$ | async)"
                       [large]="true"></ppl-loading-overlay>
</div>
