import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PplBadgeComponent } from './badge.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PplBadgeComponent],
  exports: [PplBadgeComponent]
})
export class PplBadgeModule {}

export * from './badge.component';
