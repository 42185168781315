import { AnimationThreeDotsComponent } from './components/animation-three-dots/animation-three-dots.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

const COMPONENTS = [
  AnimationThreeDotsComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...COMPONENTS
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class PplComponentsAnimationsModule { }
