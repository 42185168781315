<ppl-autocomplete [options]="availableOptions"
                  [value]="filter"
                  [categories]="categories"
                  [displayCategoriesSidebar]="displayCategoriesSidebar"
                  [icon]="freeValue ? 'search' : 'dropdown'"
                  [optionTemplate]="optionTemplate"
                  [optionTemplateRowHeight]="optionTemplateRowHeight"
                  [displayCreateOption]="displayCreateOption"
                  [displayValueLoading]="displayValueLoading"
                  [displayOptionsLoading]="displayOptionsLoading"
                  [autoFocusOnInit]="autoFocusOnInit"
                  [forceDisplayClearValue]="forceDisplayClearValue"
                  [disabled]="disabled"
                  [maxContainerHeight]="maxContainerHeight"
                  [readonly]="readonly"
                  [placeholder]="placeholder"
                  [popoverDirection]="popoverDirection"
                  (listOpen)="onListOpen()"
                  (listClose)="onListClose()"
                  (optionSelect)="onOptionSelect($event)"
                  (optionCreate)="onOptionCreate($event)"
                  (listScrollEnd)="onListScrollEnd()"
                  (clearValueClick)="onClearValueClick()"
                  (valueChange)="onValueChange($event)"></ppl-autocomplete>
