import { PplFormTableItemTree } from '../form-table-item';
import type {
  OnInit
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'ppl-form-table-tree',
  templateUrl: 'form-table-tree.component.html',
  styleUrls: ['form-table-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplFormTableTreeComponent implements OnInit {
  @Input() level = 0;
  @Input() item: PplFormTableItemTree;
  @Input() openedByDefault = false;
  @Input() forceOpened?: boolean;
  @Input() highlightCategories?: boolean;
  @Input() readonly = false;
  @Input() actionTemplate?: TemplateRef<any>;

  @Output() toggle: EventEmitter<PplFormTableItemTree> = new EventEmitter();

  isOpened: boolean;

  ngOnInit() {
    this.isOpened = this.forceOpened ? this.forceOpened : this.openedByDefault;
  }

  get levels() {
    if (this.level < 2) {
      return [];
    }
    const arr: number[] = [];
    for (let i = 1; i < this.level; i++) {
      arr.push(i);
    }
    return arr;
  }

  get highlighted() {
    return this.highlightCategories ? this.item.children.length > 0 : false;
  }

  toggleOpened(event: MouseEvent) {
    event.preventDefault();
    this.isOpened = !this.isOpened;
  }

  trackTree(index, item: PplFormTableItemTree) {
    return item.id;
  }
}
