import type { PplFormTableItem, PplFormTableItemTree } from './form-table-item';
import { flattenTree, listToTree } from './tree-utils';
import type { PplCheckboxChange } from '../checkbox/checkbox.component';
import type {
  OnChanges,
  OnDestroy
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
,
  ChangeDetectorRef,
  TemplateRef
} from '@angular/core';
import type { ControlValueAccessor} from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export enum PplFormTableCheckedState {
  None,
  Some,
  All
}

@Component({
  selector: 'ppl-form-table',
  templateUrl: 'form-table.component.html',
  styleUrls: ['form-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PplFormTableComponent),
      multi: true
    }
  ]
})
export class PplFormTableComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() title: string;
  @Input() hasSubItemsSlider = false;
  @Input() items: PplFormTableItem[];
  @Input() disabled = false;
  @Input() openedByDefault = false;
  @Input() includeSubunitsLabel = '';
  @Input() displaySearch = false;
  @Input() readonly = false;
  @Input() actionTemplate: TemplateRef<any>;

  @Output() changeItems: EventEmitter<PplFormTableItem[]> = new EventEmitter();

  checkedState: PplFormTableCheckedState = PplFormTableCheckedState.None;
  shouldIncludeSubItems = false;
  itemsTree: PplFormTableItemTree[];

  searchText = '';

  onTouched: any;
  onChange: any;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnChanges(changes) {
    if (changes && changes.items && changes.items.currentValue) {
      this.updateTree();
    }
  }

  ngOnDestroy() {
    this.writeValue = () => { };
    this.setDisabledState = () => { };
  }

  get hasCheckboxMiddleState() {
    return this.checkedState === PplFormTableCheckedState.Some;
  }

  get isCheckboxChecked() {
    return this.checkedState === PplFormTableCheckedState.All;
  }

  get hasSingleLevel() {
    return !!this.items.every(item => item.parentId === null);
  }

  updateCheckedState() {
    if (!this.items.length) {
      this.checkedState = PplFormTableCheckedState.None;
      return this.checkedState;
    }
    if (this.items.filter(item => item.checked === true).length === this.items.length) {
      this.checkedState = PplFormTableCheckedState.All;
      return this.checkedState;
    }
    if (this.items.filter(item => item.checked === false).length === this.items.length) {
      this.checkedState = PplFormTableCheckedState.None;
      return this.checkedState;
    }
    this.checkedState = PplFormTableCheckedState.Some;
    return this.checkedState;
  }

  toggle(item: PplFormTableItemTree) {
    const items: PplFormTableItem[] = [...this.items];
    const updatedItem = items.find(o => o.id === item.id) || item;
    updatedItem.checked = !item.checked;
    if (this.shouldIncludeSubItems || !this.hasSubItemsSlider) {
      flattenTree(item, 'children').forEach(childrenItem => {
        const foundItem = items.find(filteredItem => filteredItem.id === childrenItem.id) || childrenItem;
        if (!foundItem.disabled) {
          foundItem.checked = updatedItem.checked;
        }
      });
    }
    this.items = items;
    this.changeItems.emit(items);
    this.triggerOnChange();
  }

  toggleAll(event: PplCheckboxChange) {
    const items: PplFormTableItem[] = [...this.items];
    const checked = this.checkedState !== PplFormTableCheckedState.All;

    items.forEach(item => {
      if (!item.disabled) {
        item.checked = checked;
      }
    });

    this.items = items;
    this.changeItems.emit(items);
    this.triggerOnChange();
  }

  onSearchChange(searchText: string) {
    this.searchText = searchText;
    this.updateTree();
  }

  trackTree(index, item: PplFormTableItemTree) {
    return item.id;
  }

  // Value accessors
  writeValue(ids: string[]): void {
    this.items = this.items.map(item => ({
      ...item,
      checked: ids.includes(item.id)
    }));
    this.updateTree();
    this.changeDetector.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetector.detectChanges();
  }

  private triggerOnChange() {
    if (this.onChange) {
      const ids = this.items.filter(item => item.checked).map(item => item.id);
      this.onChange(ids);
      this.updateTree();
    }
  }

  private updateTree() {
    this.itemsTree = listToTree(this.items);

    if (this.searchText) {
      this.itemsTree = this.itemsTree.filter(item => {
        return item.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    }

    this.updateCheckedState();
  }
}
