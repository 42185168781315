<ppl-dialog-message type="alert">
  <ng-container i18n="@@Pipeliner_session_has_expired._Reload_Pipeliner_to_continue_working.">Pipeliner session has expired. Reload Pipeliner to continue working.</ng-container>

  <ng-template #actions>
    <button type="button"
            pplButton
            pplButton4
            pplButtonSmall
            pplButtonDialog
            i18n="@@Reload"
            (click)="onReload()">Reload</button>

    <button type="button"
            pplButton
            pplButton1
            pplButtonSmall
            pplButtonDialog
            i18n="@@Cancel"
            (click)="onCancel()">Cancel</button>
  </ng-template>
</ppl-dialog-message>