export interface PplAttachment {
  id?: string; // optional, can be used for whatever
  name: string;
  description?: string;
  content: string; // url or base64
  file?: File;
  mimeType: string;
  uploadStatus?: PplAttachmentUploadStatusEnum;
}

export enum PplAttachmentUploadStatusEnum {
  Idle = 'Idle',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Error = 'Error',
  Cancelled = 'Cancelled'
}
