import type { PplButtonComponent } from '../../button/button.component';
import type { PplPopoverControl, PplPopoverDirective } from '../../popover';
import type { ComponentType } from '@angular/cdk/portal';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  OnInit,
  TemplateRef} from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  InjectionToken,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewChildren
,
  ChangeDetectorRef,
  NgZone,
  QueryList} from '@angular/core';
import { notFirstChange } from '@ppl/utils';


export interface ToolbarButtonOption {
  id: string;
  text: string;
  icon?: string;
  enabled: boolean;
  disableOnly?: boolean;
  children?: ToolbarButtonOption[];
  childrenComponent?: ComponentType<any>;
  childrenTemplate?: TemplateRef<any>;
  childrenComponentData?: any;
  childrenEmptyLabel?: string;
  active?: boolean;
  data?: any;
  template?: TemplateRef<any>;
  title?: string;
  visible?: boolean;
}

@Component({
  selector: 'ppl-ui-toolbar-button-group',
  templateUrl: './ui-toolbar-button-group.component.html',
  styleUrls: ['./ui-toolbar-button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PplUiToolbarButtonGroupComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @Input() buttons: ToolbarButtonOption[];

  @Input() @HostBinding('class.shrinkable') shrinkable = true;

  @ViewChild('container', { static: false, read: ElementRef }) buttonsContainer: ElementRef;
  @ViewChildren('buttonAnchor') buttonsAnchor: QueryList<PplButtonComponent>;

  @Output() buttonClick = new EventEmitter<ToolbarButtonOption>();

  hiddenButtonOptions: ToolbarButtonOption[];

  openMenuItemIds = new Set<string>();
  openPopovers = new Set<PplPopoverDirective>();

  buttonsGroup: ToolbarButtonOption[];

  resizeObserver: ResizeObserver;

  get showHiddenButton() {
    return this.hiddenButtonOptions && this.shrinkable && this.hiddenButtonOptions.length > 0;
  }

  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes) {
    if (changes.buttons && notFirstChange(changes.buttons)) {
      this.updateButtonsData(true);
    }
  }

  ngOnInit() {
    this.updateButtonsData();
  }

  ngAfterViewInit() {
    if (this.shrinkable) {
      this.resizeObserver = new ResizeObserver(() => {
        this.ngZone.run(() => {
          this.updateButtonsVisibility();
        });
      });

      this.resizeObserver.observe(this.buttonsContainer.nativeElement);
    }
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
  }

  hasMenuIcons(buttons: ToolbarButtonOption[]) {
    return buttons.some(button => button.icon);
  }

  hasMenuChildren(buttons: ToolbarButtonOption[]) {
    return buttons.some(button => button.children || button.childrenComponent);
  }

  getInjector(button: ToolbarButtonOption) {
    return Injector.create([{
      provide: PPL_TOOLBAR_BUTTON_DATA,
      useValue: {
        close: () => {
          Array.from(this.openPopovers).forEach(openPopover => openPopover.hide());
        },
        value: button.childrenComponentData
      }
    }], this.injector);
  }

  onButtonClick(button: ToolbarButtonOption) {
    Array.from(this.openPopovers).forEach(openPopover => openPopover.hide());

    if (!button.children && !button.childrenComponent) {
      this.buttonClick.emit(button);
    }
  }

  onButtonPopoverToggle(event: { visible: boolean, popover: PplPopoverDirective }) {
    if (event.visible) {
      this.openPopovers.add(event.popover);
    } else {
      this.openPopovers.delete(event.popover);
    }
  }

  onPopoverToggle(button: ToolbarButtonOption, parentPopover: PplPopoverControl, event: { visible: boolean, popover: PplPopoverDirective }) {
    if (event.visible) {
      this.openMenuItemIds.add(button.id);
      this.openPopovers.add(event.popover);

      parentPopover.togglePreventClose(true);
    } else {
      this.openMenuItemIds.delete(button.id);
      this.openPopovers.delete(event.popover);

      parentPopover.togglePreventClose(false);
    }
  }

  trackById(index: number, item: ToolbarButtonOption) {
    return item.id || index;
  }

  private updateButtonsVisibility() {
    this.changeDetectorRef.detectChanges();
    let endWidth = 0;
    const hiddenButtonOptions: ToolbarButtonOption[] = [];
    const buttons = this.buttonsAnchor.toArray();

    if (!this.buttonsContainer && buttons.length === 0) {
      return;
    }

    const container = this.buttonsContainer.nativeElement;
    const clientWidth = container.clientWidth - OWERFLOW_BUTTON_WIDTH;

    this.buttonsGroup.forEach((button, i) => {
      if (buttons[i]) {
        const buttonWidth = buttons[i].elementRef.nativeElement.offsetWidth;
        endWidth += buttonWidth;
        const visible = endWidth <= clientWidth;

        if (!visible && i > 0) {
          hiddenButtonOptions.push(button);
        }

        button.visible = visible;
      } else {
        button.visible = false;
        hiddenButtonOptions.push(button);
      }

    });

    this.hiddenButtonOptions = hiddenButtonOptions;
    this.changeDetectorRef.detectChanges();
  }

  private updateButtonsData(updateVisibility = false) {
    this.buttonsGroup = this.buttons.filter(button => button.enabled || button.disableOnly).map(button => ({
      ...button,
      visible: true,
    }));

    if (updateVisibility && this.shrinkable) {
      this.updateButtonsVisibility();
    }
  }
}

export const PPL_TOOLBAR_BUTTON_DATA = new InjectionToken<{}>('PPL_TOOLBAR_BUTTON_DATA');

const OWERFLOW_BUTTON_WIDTH = 45;
