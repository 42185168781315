<div class="container demo-content">
  <h2>Toggle group</h2>
  <br>

  <h3>Components</h3>
  <h4>ppl-attachments</h4>
  <div>@Input() <b>selectedValue</b>: PplToggleGroupValue = null</div>
  <div>@Input() <b>options</b>: PplToggleGroupOption[]</div>
  <div>@Input() <b>selection</b>: PplToggleGroupOptionSelection = 'multiple'</div>
  <div>@Input() <b>fullWidth</b> = false</div>
  <div>@Input() <b>disabled</b> = false</div>
  <div>@Input() <b>hasIcons</b> = false</div>
  <div>@Input() <b>hasColors</b> = false</div>
  <br>
  <div>@Output() <b>selectedValueChange</b>: PplToggleGroupValue</div>
  <br>
  <br>

  <h4>Example</h4>

  <ppl-input-container label="Multiple options (days)">
    <ppl-toggle-group [options]="options"
                      selection="multiple"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option (days)">
    <ppl-toggle-group [options]="options"
                      selection="one"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option disabled (days)">
    <ppl-toggle-group [options]="options"
                      selection="one"
                      [disabled]="true"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option color status">
    <ppl-toggle-group [options]="colorOptions"
                      selection="one"></ppl-toggle-group>
  </ppl-input-container>

  <ppl-input-container label="One option color status disabled">
    <ppl-toggle-group [options]="colorOptions"
                      selection="one"
                      [disabled]="true"></ppl-toggle-group>
  </ppl-input-container>

  <br>
  <h4>Code Example</h4>
  <ppl-prism [code]="code"></ppl-prism>
</div>
