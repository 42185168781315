import type { OnInit} from '@angular/core';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ppl-accordion-demo',
  templateUrl: './accordion-demo.component.html',
  styleUrls: ['./accordion-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionDemoComponent implements OnInit {
  counter = 0;

  code = `
    <ppl-accordion icon="overdue-tasks" title="Tasks" subTitle="1 Overdue Tasks">
      content
    </ppl-accordion>
  `;

  constructor() { }

  ngOnInit() { }

  get conterArray() {
    return Array.from({ length: this.counter }, (v, k) => ({
      index: k
    }));
  }

  trackByFn(index, item) {
    return item.index;
  }
}
