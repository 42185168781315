import type { Router, RouterEvent } from '@angular/router';
import { NavigationStart } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

export enum DialogResult {
  ERROR,
  CLOSE,
  SUCCESS
}

export interface DialogResponse<D = any> {
  result: DialogResult;
  data?: D;
  error?: any;
}

export function createDialogErrorResponse(error: any) {
  const response: DialogResponse = {
    result: DialogResult.ERROR,
    error
  };
  return response;
}

export function createDialogSuccessResponse<D>(data?: D) {
  const response: DialogResponse<D> = {
    result: DialogResult.SUCCESS
  };
  if (typeof data !== 'undefined') {
    response.data = data;
  }
  return response;
}

export function createDialogCloseResponse() {
  const response: DialogResponse = {
    result: DialogResult.CLOSE
  };
  return response;
}


export function closeDialogOnNavigationStart<T>(
  router: Router,
  dialogRef: { close: () => void }
) {
  return router.events.pipe(
    filter((event: RouterEvent) => {
      return event instanceof NavigationStart;
    })
  ).subscribe((event: RouterEvent) => {
    dialogRef.close();
  });
}

export function handleDialogSuccessResponse<T>() {
  return switchMap((response: DialogResponse<T>) => {
    return response?.result === DialogResult.SUCCESS
      ? of(response.data)
      : EMPTY;
  });
}
